import ODataTable from '../../components/ODataTable'
import { createColumns } from './config'
import odataTableEventsMixin from '@/mixin/odataTableEventsMixin'
import { DxSelectBox } from 'devextreme-vue/select-box'
import { DxTagBox } from 'devextreme-vue/tag-box'
import { DxPopup } from 'devextreme-vue/popup'
import EpisodeService from '@/api/episode'
import AuthorsByMediaService from '@/api/authorsByMedia'
import { confirm } from 'devextreme/ui/dialog'
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator'

export default {
  name: 'AuthorsByMedia',
  components: {
    ODataTable,
    DxSelectBox,
    DxTagBox,
    DxPopup,
    DxValidator,
    DxRequiredRule
  },
  mixins: [odataTableEventsMixin],
  props: {
    data: {
      type: Object,
      default: () => undefined
    }
  },
  data() {
    return {
      filter: [],
      isAddMediaDialogOpen: false,
      selectedCategory: null,
      selectedEpisodeID: [],
      selectedEpisodes: [],
      selectedSite: null,
      batchDeleteEpisodeIds: [],
      onSaveLoading: false
    }
  },
  computed: {
    columns() {
      if (this.data && this.data.ID) {
        return createColumns({
          ID: this.data.ID,
          selectedEpisodeID: this.selectedEpisodeID
        })
      }
      return createColumns({ selectedEpisodeID: this.selectedEpisodeID })
    },
    AuthorID() {
      return this.data && this.data.ID ? this.data.ID : undefined
    }
  },
  watch: {
    async selectedCategory(selectedCategory) {
      if (selectedCategory !== null) {
        const { value } = await EpisodeService.fetchMediasByCategoryId(
          selectedCategory
        )
        this.selectedEpisodeID = value
          .filter(val => {
            return val.MediaID !== null
          })
          .map(val => {
            return val.MediaID
          })
      } else {
        this.selectedEpisodeID = []
      }
    }
  },
  created() {
    if (this.data) {
      this.filter = ['AuthorsID', 'eq', `${this.data.ID}`]
    }
  },
  methods: {
    handleAddMediaDialogOpen() {
      this.isAddMediaDialogOpen = true
    },
    handleAddMediaDialogClose() {
      this.isAddMediaDialogOpen = false
      this.selectedCategory = null
      this.selectedEpisodeID = []
      this.selectedSite = null
      this.selectedEpisodes = []
      this.onSaveLoading = false
    },
    handleCategoryChange(selectedCategoryValue) {
      this.selectedEpisodes = []
      this.selectedCategory = selectedCategoryValue.value
    },
    onEpisodeValueChange(select) {
      this.selectedEpisodes = select.value
    },
    handleSiteChange(selectedSite) {
      this.selectedSite = selectedSite.value
    },
    async handlerChatQuestionMedia() {
      try {
        if (
          this.selectedEpisodes.length &&
          this.AuthorID &&
          this.selectedSite
        ) {
          this.onSaveLoading = true
          this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
          await Promise.all(
            this.selectedEpisodes.map(MediaID =>
              AuthorsByMediaService.create({
                MediaID,
                AuthorsID: this.AuthorID,
                SiteID: this.selectedSite
              })
            )
          )
          await this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.refresh()
          this.handleAddMediaDialogClose()
        } else if (this.selectedEpisodes.length && this.AuthorID) {
          this.selectedSite = ''
        } else {
          this.selectedSite = ''
          this.selectedEpisodes = []
        }
      } catch (error) {
        this.$message({
          message: error.message,
          type: 'error'
        })
      } finally {
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
      }
    },
    handleSelectionChange({ selectedRowKeys }) {
      this.batchDeleteEpisodeIds = selectedRowKeys
    },
    async handleBatchDelete() {
      const result = await confirm(this.$t('AuthorsByMedia.isConfirmed'))
      if (result && this.batchDeleteEpisodeIds) {
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
        await Promise.all(
          this.batchDeleteEpisodeIds.map(ID => AuthorsByMediaService.delete(ID))
        )
        await this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.refresh()
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
      }
    }
  }
}
