import axios from 'axios'

const headers = {
  Accept: 'application/json',
  'api-key': process.env.VUE_APP_BREVO_API_KEY
}

export default class UpdateSendListService {
  static async fetchSendLists() {
    const response = await axios.get(
      `${process.env.VUE_APP_BREVO_API_BASE_URL}/contacts/lists`,
      {
        params: { limit: 10, offset: 0, sort: 'desc' },
        headers: headers
      }
    )
    return response.data.lists
  }

  static async fetchAllSendLists(limit = 50, offset = 0) {
    let allLists = []
    let endReached = false
    let currentOffset = offset

    while (!endReached) {
      const response = await axios.get(
        `${process.env.VUE_APP_BREVO_API_BASE_URL}/contacts/lists`,
        {
          params: { limit: limit, offset: currentOffset, sort: 'desc' },
          headers: headers
        }
      )

      const lists = response.data.lists
      allLists = [...allLists, ...lists]

      currentOffset += limit

      if (currentOffset >= response.data.count) {
        endReached = true
      }
    }
    return allLists
  }

  static async createList({ name, folderId }) {
    const response = await axios.post(
      `${process.env.VUE_APP_BREVO_API_BASE_URL}/contacts/lists`,
      {
        name,
        folderId
      },
      {
        headers: headers
      }
    )
    return response
  }

  static async CreateFolder(name) {
    const response = await axios.post(
      `${process.env.VUE_APP_BREVO_API_BASE_URL}/contacts/folders`,
      {
        name
      },
      {
        headers: headers
      }
    )
    return response.data.id
  }

  static async getContactsByList(listId) {
    const response = await axios.get(
      `${process.env.VUE_APP_BREVO_API_BASE_URL}/contacts/lists/${listId}/contacts`,
      {
        headers: headers
      }
    )
    return response.data.contacts
  }

  static async getContacts() {
    const response = await axios.get(
      `${process.env.VUE_APP_BREVO_API_BASE_URL}/contacts?limit=50&offset=0&sort=desc`,
      {
        headers: headers
      }
    )
    return response.data.contacts
  }

  static async getContactDetailsByEmail(email) {
    const response = await axios.get(
      `${process.env.VUE_APP_BREVO_API_BASE_URL}/contacts/${email}`,
      {
        headers: headers
      }
    )
    return response
  }

  static async addContactsToList(contact) {
    const response = await axios.post(
      `${process.env.VUE_APP_BREVO_API_BASE_URL}/contacts`,
      contact,
      {
        headers: {
          ...headers,
          'content-type': 'application/json'
        }
      }
    )
    return response.data
  }

  static async updateContact({
    FIRSTNAME,
    LASTNAME,
    email,
    listIds,
    LOGO,
    SITE,
    SSO,
    SIGNATUR,
    PASSWORD,
    UID
  }) {
    const url = `${process.env.VUE_APP_BREVO_API_BASE_URL}/contacts/${email}`
    await axios.put(
      url,
      {
        listIds,
        attributes: {
          LOGO,
          FIRSTNAME,
          LASTNAME,
          SITE,
          SSO,
          SIGNATUR,
          PASSWORD,
          UID
        },
        updateEnabled: true
      },
      {
        headers: headers
      }
    )
  }

  static async contactBatchUpdate(contacts) {
    const response = await axios.post(
      `${process.env.VUE_APP_BREVO_API_BASE_URL}/contacts/batch`,
      {
        contacts
      },
      {
        headers: headers
      }
    )
    return response
  }
}
