<template>
  <el-form class="container">
    <el-row type="flex" :gutter="10">
      <el-form-item :label="$t('mediaReports.dateRange')">
        <el-col :xs="24" :sm="6">
          <el-date-picker
            v-model="startDate"
            :placeholder="`${$t('mediaReports.startDatePlaceholder')}`"
            :format="filters.dateFormat"
            @change="handleStartDateChange"
          />
        </el-col>
        <el-col :xs="24" :sm="6">
          <el-date-picker
            v-model="endDate"
            :placeholder="`${$t('mediaReports.endDatePlaceholder')}`"
            :picker-options="endDatePickerOptions"
            :format="filters.dateFormat"
            @change="handleEndDateChange"
          />
        </el-col>
      </el-form-item>
    </el-row>
    <el-row type="flex" :gutter="10">
      <el-form-item :label="$t('mediaReports.customer')">
        <el-col :xs="24" :sm="6">
          <el-select
            v-model="filters.account"
            filterable
            :placeholder="`${$t('select')} ${$t(
              'manageUsers.selectType.account'
            )}`"
            :loading="areAccountsLoading"
          >
            <el-option label="All Accounts" value="" />
            <el-option
              v-for="item in accounts"
              :key="item.option.value"
              :label="item.option.title"
              :value="item.option.value"
            >
              {{ item.option.title }}
            </el-option>
          </el-select>
        </el-col>
        <el-col :xs="24" :sm="6">
          <el-select
            v-model="filters.subAccount"
            filterable
            :placeholder="`${$t('select')} ${$t(
              'manageUsers.selectType.subAccount'
            )}`"
            :loading="areAccountsLoading"
            :disabled="isSubAccountTypeDisabled"
          >
            <el-option
              v-for="item in subAccounts"
              :key="item.option.value"
              :label="item.option.title"
              :value="item.option.value"
            >
              {{ item.option.title }}
            </el-option>
          </el-select>
        </el-col>
        <el-col :xs="24" :sm="6">
          <el-select
            v-model="filters.tagId"
            filterable
            :placeholder="`${$t('select')} ${$t(
              'tag.selectTag'
            )}`"
            :loading="areUserTagsLoading"
          >
            <el-option v-if="tags.length" label="All" value="" />
            <el-option
              v-for="item in tags"
              :key="item.ID"
              :label="item.TagName"
              :value="item.ID"
            >
              {{ item.TagName }}
            </el-option>
          </el-select>
        </el-col>
      </el-form-item>
    </el-row>
    <el-row type="flex" :gutter="10">
      <el-form-item :label="$t('mediaReports.educations')">
        <el-col :xs="24" :sm="6">
          <el-select
            v-model="filters.categoryID"
            filterable
            :placeholder="`${$t('select')} ${$t('category')}`"
            :loading="areCategoriesLoading"
          >
            <el-option v-if="categories.length" label="All Categories" value="" />
            <el-option
              v-for="category in categories"
              :key="category.ID"
              :label="category.Name"
              :value="category.ID"
            />
          </el-select>
        </el-col>
        <el-col :xs="24" :sm="6">
          <el-select
            v-model="filters.episodeID"
            filterable
            :placeholder="`${$t('select')} ${$t('media')}`"
            :loading="areEpisodesLoading"
          >
            <el-option v-if="episodes.length" label="All" value="" />
            <el-option
              v-for="episode in episodes"
              :key="episode.ID"
              :label="episode.Title"
              :value="episode.ID"
            />
          </el-select>
        </el-col>
      </el-form-item>
    </el-row>
    <el-row type="flex" :gutter="10" />
    <el-row>
      <el-button
        type="primary"
        :disabled="!filtersMedia.length"
        @click="filterMediaReports"
      >{{ $t("mediaReports.filter") }}</el-button>
    </el-row>
  </el-form>
</template>
<script>
import mixin from './mixin'
export default {
  mixins: [mixin]
}
</script>

<style scoped>
::v-deep .el-date-editor.el-input{
   width: 100%;
}
.container {
  gap: 10px;
}
.el-form-item{
  display: flex;
  flex-direction: column;
  width: 100%;
}
::v-deep .el-form-item__label{
  display: inline-block;
  text-align: start !important;
  padding-left: 5px !important;
}

</style>
