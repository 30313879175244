var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column-flex" }, [
    _c(
      "span",
      { staticClass: "margin-left-md" },
      [
        _c(
          "el-checkbox",
          {
            staticClass: "no-deactivation",
            model: {
              value: _vm.isNoDeactivationEnabled,
              callback: function($$v) {
                _vm.isNoDeactivationEnabled = $$v
              },
              expression: "isNoDeactivationEnabled"
            }
          },
          [_vm._v(_vm._s(_vm.$t("manageUsers.deactivateUser.noDeactivation")))]
        ),
        _vm._v(
          " " +
            _vm._s(_vm.$t("manageUsers.deactivateUser.absentUsersCount")) +
            " " +
            _vm._s(": " + _vm.absentUsers.length) +
            " "
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "margin-left-md table" },
      [
        _c(
          "el-table",
          {
            ref: "deactivateCustomers",
            attrs: { data: _vm.absentUsers, height: "250" },
            on: { "selection-change": _vm.handleSelectionChange }
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "55" }
            }),
            _vm._l(_vm.headings, function(column) {
              return _c("el-table-column", {
                key: column.value,
                staticClass: "overflow-wrap",
                attrs: {
                  label: column.label,
                  prop: column.value,
                  width: 150,
                  "show-overflow-tooltip": true
                }
              })
            })
          ],
          2
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "action-btn" },
      [
        _c(
          "el-button",
          {
            staticClass: "margin-left-md self-start",
            attrs: {
              disabled: !_vm.selectedUsers.length,
              loading: _vm.isLoading && _vm.hasDeactivateUserBeenClicked,
              type: "primary"
            },
            on: { click: _vm.handleDeactivateClick }
          },
          [_vm._v(_vm._s(_vm.$t("manageUsers.deactivateUser.stepLabel")))]
        ),
        _c(
          "el-button",
          {
            staticClass: "margin-left-md self-start",
            attrs: {
              loading: _vm.isLoading && !_vm.hasDeactivateUserBeenClicked,
              type: "primary"
            },
            on: { click: _vm.handleGenerateReportClick }
          },
          [_vm._v(_vm._s(_vm.$t("manageUsers.matchField.generateReport")))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }