import { Column } from './column'
import { Columns } from './columns'
import { FileParser } from './fileParser'

export class ImportedUsers {
  importedUsers;

  isEMailImport;

  isExtIdImport;

  shouldTransformToHtml;
  constructor(importedUsers = []) {
    this.importedUsers = importedUsers
    this.shouldTransformToHtml = true
    this.isExtIdImport = false
    this.isEMailImport = false
  }

  get headings() {
    return new Columns(Object.keys({ ...this.importedUsers[0] }))
  }

  isValid(includeSubAccount = false) {
    return this.createValidations(includeSubAccount).every(
      validation => validation.isValid
    )
  }

  createValidations(includeSubAccount = false) {
    const requiredValidations = this.headings.createRequiredFields({
      includeSubAccount,
      includeExtId: this.isEMailImport
    })
    const extIdValidations = this.hasProvidedExtId
      ? [
        {
          field: 'ExtIdUnique',
          value: 'extIdColumnValuesNotUnique',
          isValid: this.validateUnique('ExtID')
        }
      ]
      : []
    return [...requiredValidations, ...extIdValidations]
  }

  get titleType() {
    return this.importedUsers.length === 1 ? 'singular' : 'plural'
  }

  get usersWithInvalidEmails() {
    const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    return this.importedUsers.filter(item => !validEmailRegex.test(item.EMail))
  }

  compare(user) {
    return (
      this.contains(user.EMail?.toLowerCase()) ||
      this.containsExtIDs(user.ExtID?.toLowerCase())
    )
  }

  get emails() {
    return this.importedUsers.map(({ EMail }) => EMail?.toLowerCase())
  }

  get extIds() {
    return this.importedUsers.map(({ ExtID }) => ExtID?.toLowerCase())
  }

  contains(email) {
    return this.emails.includes(email)
  }

  containsExtIDs(extdId) {
    return this.extIds.includes(extdId)
  }

  filterSelectedUserForDeactivation(isNoDeactivationEnabled = true) {
    return this.importedUsers.filter(importedUser =>
      isNoDeactivationEnabled ? !importedUser.NoDeactivation : true
    )
  }

  createCustomerMatchParms(account) {
    return this.importedUsers.map(({ EMail, SubAccount, ExtID }) =>
      !account
        ? this.isCheckForImportingExtIdSelected
          ? ExtID || EMail
          : EMail
        : {
          ...(this.isCheckForImportingExtIdSelected ? { ExtID } : { EMail }),
          Account: account.Account,
          ...(SubAccount && { SubAccount }),
          ...(account.SubAccount && {
            SubAccount: account.SubAccount
          })
        }
    )
  }

  compareUserAccount(account, importedUser) {
    const accounts = [account.ID.toString(), account.Account]
    const subAccounts = [account.ID.toString(), account.SubAccount]

    const isAccountMatched = accounts.includes(
      importedUser.Account.toString().trim()
    )
    const isSubAccountMatched =
      importedUser.SubAccount &&
      subAccounts.includes(importedUser.SubAccount.toString().trim())

    return account.SubAccount
      ? isAccountMatched && isSubAccountMatched
      : isAccountMatched
  }

  createGenerateReportParams({
    selectedAccountInfo,
    deactivatedUsers = [],
    isDeactivateImportType,
    existingUsers = new ImportedUsers(),
    isNoDeactivationEnabled
  }) {
    if (isDeactivateImportType) {
      return this.importedUsers.map(importedUser => importedUser.column_1)
    }
    const deactivatedUsersWithDisabledflag = deactivatedUsers

      .filter(deactivatedUser =>
        isNoDeactivationEnabled ? !deactivatedUser.NoDeactivation : true
      )

      .map(deactivatedUser => ({ ...deactivatedUser, RealCustomer: false }))
    const consolidatedImportUsers = [
      ...this.importedUsers,
      ...deactivatedUsersWithDisabledflag
    ]
    return consolidatedImportUsers.map(({ RealCustomer = true, ...user }) => ({
      ...Object.entries(user).reduce(
        (userAttributes, [key, value]) => ({
          ...userAttributes,
          ...(!Column.isCustomColumn(key) && {
            [key]: Column.isNoDeactivation(key)
              ? Column.parseNoDeactivationValue(value)
              : value || null
          })
        }),
        {}
      ),
      ...(selectedAccountInfo && {
        Account: selectedAccountInfo.Account
      }),
      RealCustomer,
      ForceUpdate: selectedAccountInfo
        ? true
        : existingUsers.contains(user.EMail?.toLowerCase()) ||
          existingUsers.containsExtIDs(user.ExtID?.toLowerCase())
    }))
  }

  toHtml() {
    return this.shouldTransformToHtml
      ? `<table class="table">${this.importedUsers

        .map(column => `<tr>${ImportedUsers.createColumnHtml(column)}</tr>`)
        .join('')}</table>`
      : ''
  }

  getColumnValues(column) {
    return this.importedUsers.map(importedUser => importedUser[column])
  }

  getNonEmptyColumnValues(column) {
    return this.importedUsers.filter(importedUser => importedUser[column])
  }

  isValidAccountMatch({ column, selectedAccount }) {
    const accounts = this.getColumnValues(column)
    return accounts.every(
      account =>
        account.trim().toLowerCase() === selectedAccount.trim().toLowerCase()
    )
  }

  isValidSubAccountMatch({ selectedSubAccounts = [], column }) {
    const subAccounts = this.getColumnValues(column)

    return subAccounts.every(subAccount =>
      selectedSubAccounts

        .map(account => account.trim().toLowerCase())
        .includes(subAccount.trim().toLowerCase())
    )
  }

  validateNonEmpty(column) {
    return this.getColumnValues(column).every(value => {
      return (
        !!value?.trim() &&
        !['null', 'undefined'].includes(value?.trim().toLowerCase())
      )
    })
  }

  validateUnique(column) {
    const values = this.getColumnValues(column)
    return new Set(values.filter(value => value.trim())).size === values.length
  }

  getColumnValuesWithRowNumber(column) {
    return this.getColumnValues(column).map((value, index) => ({
      row: index + 1,
      value
    }))
  }

  getUnMatchedAccountsWithRowNumber({ column, selectedAccount }) {
    const accounts = this.getColumnValuesWithRowNumber(column)
    return accounts.filter(
      account =>
        account.value.trim().toLowerCase() !==
        selectedAccount.trim().toLowerCase()
    )
  }

  getUnMatchedSubAccountsWithRowNumber({ selectedSubAccounts = [], column }) {
    const subAccounts = this.getColumnValuesWithRowNumber(column)
    return subAccounts.filter(
      subAccount =>
        !selectedSubAccounts

          .map(account => account.trim().toLowerCase())
          .includes(subAccount.value.trim().toLowerCase())
    )
  }

  updateColumn({ column, value }) {
    this.importedUsers = this.importedUsers.map(importedUser =>
      Object.keys(importedUser).reduce(
        (user, field, index) => ({
          ...user,
          [Column.updateColumnName({
            existingColumn: field,
            columnToBeUpdated: column,
            updatedValue: value,
            index
          })]: importedUser[field]
        }),
        {}
      )
    )
  }

  updatePartial({ email, overrideAttributes }) {
    this.importedUsers = this.importedUsers.map(user =>
      user.EMail === email ? { ...user, ...overrideAttributes } : user
    )
  }

  static createColumnHtml(column) {
    return Object.values(column)
      .map(cell => `<td class="td">${cell}</td>`)
      .join('')
  }
  static createFromFile(fileText = '') {
    return new ImportedUsers(new FileParser(fileText).parse())
  }

  get hasProvidedExtId() {
    return this.headings.has('ExtID')
  }

  get isCheckForImportingExtIdSelected() {
    return this.isExtIdImport
  }

  setExtIdImportField(value) {
    this.isExtIdImport = value
    if (this.isEMailImport) {
      this.isExtIdImport = false
    }
  }

  setEMailImportField(value) {
    this.isEMailImport = value
    if (value) {
      this.isExtIdImport = false
    } else {
      this.isExtIdImport = this.hasProvidedExtId
    }
  }

  get isValidEmail() {
    const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    return validEmailRegex.test(Object.values(this.importedUsers[0])[0])
  }

  generateDebugParams(editedUsersData) {
    return {
      Info: editedUsersData,
      AddedDate: new Date().toISOString()
    }
  }
}
