var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom",
        title: "Filters",
        width: "200",
        trigger: "click"
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "el-row",
            { staticClass: "filter-item" },
            [
              _c("span", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.$t("contentPart.position")))
              ]),
              _c("content-part-select", {
                attrs: {
                  placeholder:
                    _vm.$t("search") + " " + _vm.$t("contentPart.position"),
                  options: _vm.positions,
                  "show-all": "",
                  selected: _vm.filters.positionSearch,
                  size: "mini",
                  "prop-name": "Position",
                  "prop-value": "Position"
                },
                on: {
                  "update:selected": function($event) {
                    return _vm.$set(_vm.filters, "positionSearch", $event)
                  },
                  change: function($event) {
                    return _vm.setFilters({ positionSearch: $event })
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "filter-item" },
            [
              _c("span", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.$t("contentPart.tag")))
              ]),
              _c("content-part-select", {
                attrs: {
                  placeholder:
                    _vm.$t("search") + " " + _vm.$t("contentPart.tag"),
                  options: _vm.tags,
                  "show-all": "",
                  selected: _vm.filters.tagSearch,
                  size: "mini",
                  "prop-name": "Tag",
                  "prop-value": "Tag"
                },
                on: {
                  "update:selected": function($event) {
                    return _vm.$set(_vm.filters, "tagSearch", $event)
                  },
                  change: function($event) {
                    return _vm.setFilters({ tagSearch: $event })
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("el-button", {
        staticClass: "filter-button",
        attrs: { slot: "reference", type: "text", icon: "el-icon-s-operation" },
        slot: "reference"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }