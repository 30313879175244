import Store from '@/models/odataStore'
export const createColumns = ({ sites, siteId }) => {
  const addEdit = [
    {
      field: 'MediaID',
      width: 400,
      lookup: {
        store: new Store({
          resource: 'Media',
          filter: ['IsActive', '=', 'true']
        }),
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'ID',
      displayExpr: 'Title',
      required: true,
      requiredMessage: 'medias.column.title.requiredMessage'
    },
    {
      field: 'SortOrder',
      width: 200
    },
    {
      label: 'mediasByCategory.column.siteId',
      calculateCellValue: () => siteId,
      lookup: sites
    }
  ]

  return {
    addEdit,
    all: [
      {
        field: 'ID',
        width: 110
      },
      ...addEdit
    ]
  }
}
