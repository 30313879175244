<template>
  <el-row class="column-flex">
    <el-col :span="24">
      <div class="title-container">
        <p>{{ filteredDownloadables.length }}</p>
        <p>{{ $t('manageDownloadables.generateReport.updatedDownloadables') }}</p>
      </div>
    </el-col>
    <el-col :span="24">
      <el-row>
        <el-col v-for="downloadable in filteredDownloadables" :key="downloadable[idColumn]" :span="24">
          <p class="subtitle">{{ downloadable[idColumn] }}</p>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import { DownloadableColumn } from '@/models/downloadableColumn'
const { mapState } = createNamespacedHelpers('importedDownloadables')
export default {
  name: 'GenerateReport',
  computed: {
    ...mapState({
      downloadables: state => state.importedDownloadables.importedUsers,
      headings: state => state.headings,
      allDownloadableIds: state => state.allDownloadableIds
    }),
    idColumn() {
      return DownloadableColumn.idColumn(this.headings)
    },
    filteredDownloadables() {
      return this.downloadables.filter(el => this.allDownloadableIds.includes(Number(el[this.idColumn])))
    }
  }
}
</script>

  <style lang="scss" src="./style.scss" scoped />
