var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c(
        "el-main",
        { staticClass: "dx-viewport" },
        [
          _c("MasterDetail", {
            attrs: { data: _vm.detail, items: _vm.reflectionData },
            scopedSlots: _vm._u([
              {
                key: "custom-item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("div", { staticClass: "date-container" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatDate(item.editorOptions.value)) +
                          " "
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }