<template>
  <el-main class="container">
    <el-row type="flex" :gutter="40">
      <el-col :xs="24" :sm="6">
        <el-select v-model="filters.account" filterable :placeholder="$t('reflections.account')">
          <el-option
            v-for="item in accounts"
            :key="item.ID"
            :label="item.option.title"
            :value="item.option.value"
          />
        </el-select>
      </el-col>
      <el-col :xs="24" :sm="6">
        <el-select
          v-model="filters.selectedCategory"
          filterable
          :placeholder="`${$t('select')} ${$t('category')}`"
        >
          <el-option label="All" value="" />
          <el-option
            v-for="category in categories"
            :key="category.ID"
            :label="category.Name"
            :value="category.ID"
          />
        </el-select>
      </el-col>
      <el-col :xs="24" :sm="6">
        <el-select
          v-model="filters.selectedEpisode"
          filterable
          :placeholder="`${$t('select')} ${$t('media')}`"
        >
          <el-option v-if="episodes.length" label="All" value="" />
          <el-option
            v-for="episode in episodes"
            :key="episode.ID"
            :label="episode.Title"
            :value="episode.ID"
          />
        </el-select>
      </el-col>
      <el-col :xs="24" :sm="6">
        <el-select v-model="filters.isApproved" :placeholder="`${$t('reflections.approve')} / ${$t('reflections.reject')}`">
          <el-option
            v-for="option in options"
            :key="option.value"
            :label="$t(option.label)"
            :value="option.value"
          />
        </el-select>
      </el-col>
    </el-row>
    <el-row type="flex" :gutter="40">
      <el-col :xs="24" :sm="6">
        <el-date-picker
          v-model="filters.startDate"
          :placeholder="`${$t('mediaReports.startDatePlaceholder')}`"
          :format="dateFormat"
        />
      </el-col>
      <el-col :xs="24" :sm="6">
        <el-date-picker
          v-model="filters.endDate"
          :placeholder="`${$t('mediaReports.endDatePlaceholder')}`"
          :picker-options="endDatePickerOptions"
          :format="dateFormat"
        />
      </el-col>
    </el-row>
    <el-row type="flex" :gutter="40" justify="end">
      <el-col :sm="6" class="flex-end">
        <el-button type="primary" :disabled="!filters.hasFilter" @click="handlerReportsFilter">{{ $t('reflections.apply') }}</el-button>
        <el-button type="danger" :disabled="!filters.hasFilter" @click="handleClearFilters">{{ $t('reflections.clear') }}</el-button>
      </el-col>
    </el-row>

  </el-main>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import { ReflectionFilters } from '@/models/ReflectionFilters'
const {
  mapState: mapAccountsState,
  mapActions: mapAccountsActions
} = createNamespacedHelpers('user/accounts')
const {
  mapState: mapReflectionState
} = createNamespacedHelpers('reflection')
const {
  mapState: mapAppState,
  mapActions: mapAppActions
} = createNamespacedHelpers('app')
export default {
  name: 'ReportFilters',
  components: {},
  data() {
    return {
      dateFormat: 'dd/MM/yyyy',
      endDatePickerOptions: {
        disabledDate: (time) => {
          this.reflectionFilter(time)
        }
      },
      options: ReflectionFilters.options
    }
  },
  computed: {
    ...mapAccountsState({
      accounts: (state) => state.accounts,
      areAccountsLoading: (state) => state.isLoading
    }),
    ...mapReflectionState({
      filters: (state) => state.filters
    }),
    ...mapAppState({
      categories: (state) => state.categories,
      episodes: (state) => state.medias
    })
  },
  async created() {
    await Promise.all([
      this.fetchAccounts(),
      this.fetchCategories(),
      this.fetchMedias()
    ])
  },
  methods: {
    ...mapAccountsActions(['fetchAccounts']),
    ...mapAppActions([
      'fetchCategories',
      'fetchMedias'
    ]),
    handlerReportsFilter() {
      this.$emit('filter')
    },
    handleClearFilters() {
      this.filters.reset()
      this.$emit('closeFilter')
    },
    reflectionFilter(time) {
      return this.filters.startDate ? time.getTime() < this.filters.startDate.getTime() : false
    }
  }
}
</script>
<style scoped>
.el-date-editor.el-input{
  width: 100%;
}
.flex-end{
  display: flex !important;
  justify-content: flex-end !important;
}
</style>
