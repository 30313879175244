var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ODataTable", {
    ref: "oDataTableComponentRef",
    attrs: {
      columns: _vm.columns,
      "add-edit-columns": _vm.columns,
      resource: "Sites",
      "total-label": _vm.$t("sites.total"),
      "file-name": _vm.$t("sites.fileName"),
      "add-row-button-label": _vm.$t("sites.add"),
      title: _vm.$t("sites.title"),
      "should-show-delete-button": false,
      "should-render-master-detail": true,
      "export-formats": _vm.exportFormats
    },
    on: {
      rowInit: _vm.handleRowInit,
      rowInserted: _vm.handleRowInserted,
      editorPreparing: _vm.handleEditorPreparing,
      rowUpdating: _vm.handleRowUpdating,
      rowExpanded: _vm.handleRowExpanded
    },
    scopedSlots: _vm._u([
      {
        key: "details",
        fn: function(ref) {
          var data = ref.data
          return [_c("Detail", { attrs: { data: data } })]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }