<template>
  <div class="app-container">
    <el-container>
      <el-row type="flex" class="container">
        <el-col :xs="8" :sm="6" class="col">
          <el-row type="flex" align="middle">
            <el-col class="header">
              <span class="heading">Content Part</span>
            </el-col>
            <el-col :span="4" class="text-end header">
              <builder-filters />
            </el-col>
          </el-row>
          <el-divider class="divider" />
          <el-row>
            <el-col>
              <el-input
                v-model="nameSearch"
                class="search"
                size="mini"
                clearable=""
                suffix-icon="el-icon-search"
                :placeholder="$t('search')"
                @input="setFilters({ nameSearch: $event })"
              />
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <builder-filter-tags />
            </el-col>
          </el-row>
          <el-row type="flex" class="content-part">
            <el-col v-loading="isLoading">
              <el-col
                v-for="contentPart in contentPartListForBuilder"
                :id="contentPart.ID"
                :key="contentPart.ID"
                draggable
                :span="12"
                @dragstart.native="handleDragStart(contentPart.ID,$event)"
                @dragend.native="handleDragEnd"
              >
                <builder-content-tiles :data="contentPart" />
              </el-col>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="16" :sm="18" class="col">
          <el-row type="flex" align="middle">
            <el-col :xs="24" :sm="8" class="header">
              <span class="heading truncate-text">{{
                config.currentPageName || $t("builder")
              }}</span>
              <span
                v-if="config.isActive"
                class="header-button badge success"
              >{{ $t("active") }}</span>
              <span v-else class="header-button badge warning">{{
                $t("draft")
              }}</span>
            </el-col>
            <el-col :xs="24" :sm="16" class="text-end header header-items">
              <el-checkbox class="header-button">
                {{ $t("contentPart.showOnlyWhenLoggedIn") }}
              </el-checkbox>
              <el-button
                v-if="isPreviewChanged"
                size="small"
                class="header-button new-tab"
                @click="saveAndPreview"
              >
                {{ `${$t("save")} & ${$t("preview")}` }}
              </el-button>
              <el-button
                v-else
                size="small"
                class="header-button new-tab"
                @click="showPreview"
              >
                {{ $t("preview") }}
              </el-button>
              <el-dropdown
                trigger="click"
                class="header-dropdown"
                size="mini"
                split-button
                type="danger"
                @click="clearPreviewItems"
              >
                {{ $t("clear") }}
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-button
                      class="dropdown-item"
                      type="text"
                      size="medium"
                      @click="resetPreview"
                    >
                      {{ $t("reset") }}
                    </el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button
                v-if="config.isActive"
                v-loading.fullscreen.lock="isBuilderLoading"
                :element-loading-text="$t('builderMessage')"
                type="warning"
                size="small"
                class="header-button"
                @click="savePreview(false)"
              >
                {{ $t("editPageMakeInactiveButton") }}
              </el-button>
              <el-button
                v-else
                v-loading.fullscreen.lock="isBuilderLoading"
                :element-loading-text="$t('builderMessage')"
                type="success"
                size="small"
                class="header-button"
                @click="savePreview(true)"
              >
                {{ $t("editPageMakeActiveButton") }}
              </el-button>
              <el-button
                v-loading.fullscreen.lock="isBuilderLoading"
                :disabled="!isPreviewChanged"
                :element-loading-text="$t('builderMessage')"
                type="primary"
                size="small"
                class="header-button"
                @click="savePreview(config.isActive)"
              >
                {{ $t("save") }}
              </el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-divider class="divider" />
          </el-row>
          <div class="builder" @drop="handleDrop" @dragover="handleOver">
            <el-row
              v-if="!previewItems.length"
              class="builder"
              type="flex"
              align="middle"
              justify="center"
            >
              <el-col class="text-center">
                <h5 class="text-grey">{{ $t("dropHere") }}</h5>
              </el-col>
            </el-row>
            <el-row v-else type="flex" align="middle">
              <el-col>
                <div
                  v-for="(previewItem, index) in previewItems"
                  :key="index"
                >
                  <el-row v-if="showOptions" justify="center" type="flex">
                    <el-col
                      v-for="action in actions"
                      :key="action.icon"
                      :span="1"
                    >
                      <el-button
                        :type="action.type"
                        class="action-buttons"
                        :icon="action.icon"
                        @click="action.handler(index)"
                      />
                    </el-col>
                  </el-row>
                  <el-row v-loading="isEditorLoading" class="editor overlay">
                    <editor
                      v-model="previewItem.MainContent"
                      :api-key="apiKey"
                      :init="tinyMCEConfig"
                      @onInit="handleEditorActivate"
                    />
                    <div
                      class="child"
                      :data-item-index="index"
                      :class="{'highlight-item':dropItemIndex === index}"
                      @click="toggleOptionsVisibility"
                    />
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-container>
    <content-part-dialog-box
      :visible.sync="dialogVisible"
      :item.sync="contentPartBlock"
      dialog-type="edit"
      @update="updatePreviewItems($event)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import Editor from '@tinymce/tinymce-vue'
import ContentPartDialogBox from '@/components/ContentPartDialogBox'
import BuilderFilters from '@/components/BuilderFilters'
import BuilderFilterTags from '@/components/BuilderFilterTags'
import BuilderContentTiles from '@/components/BuilderContentTiles'
import { BUILDER_MODES } from '@/utils/constants'

import { tinyMCEConfig, TINYMCE_API_KEY, PREVIEW_URL } from './config'

const contentPartStore = 'contentPart/'
const contentPartBuilderStore = 'contentPartBuilder/'
const contentPartPagesStore = 'contentPartPages/'
const pagesRoute = '/administer/pages'

export default {
  name: 'Builder',
  components: {
    Editor,
    ContentPartDialogBox,
    BuilderFilters,
    BuilderFilterTags,
    BuilderContentTiles
  },
  data() {
    return {
      contentPartBlock: {},
      tinyMCEConfig,
      apiKey: TINYMCE_API_KEY,
      isEditorLoading: true,
      dialogVisible: false,
      showOptions: false,
      isBuilderLoading: false,
      nameSearch: '',
      dropItemIndex: null,
      actions: [
        {
          type: 'info',
          icon: 'el-icon-arrow-up',
          handler: this.movePreviewItemUp
        },
        {
          type: 'warning',
          icon: 'el-icon-edit',
          handler: this.editPreviewItem
        },
        {
          type: 'danger',
          icon: 'el-icon-delete',
          handler: this.deletePreviewItem
        },
        {
          type: 'info',
          icon: 'el-icon-arrow-down',
          handler: this.movePreviewItemDown
        }
      ]
    }
  },
  computed: {
    ...mapGetters(contentPartBuilderStore, ['isPreviewChanged']),
    ...mapGetters(['contentPartListForBuilder', 'contentPartRow']),
    ...mapState(contentPartBuilderStore, [
      'isLoading',
      'previewItems',
      'config'
    ]),
    ...mapState(contentPartPagesStore, ['pages']),
    ...mapState(contentPartStore, ['medias', 'templates']),
    ...mapState({ isContentPartList: (state) => state.contentPart.totalItems })
  },
  created() {
    if (this.config.currentPage > 0) this.fetchContentPart()
    else this.$router.push(pagesRoute)
    this.setMedia()
    this.setTemplates()
  },
  mounted() {
    if (this.config.mode === BUILDER_MODES.EDIT) {
      this.fetchBlockContent()
      this.fetchBlocks()
    }
  },
  methods: {
    ...mapActions(contentPartStore, [
      'setContentPart',
      'setMedia',
      'setTemplates'
    ]),
    ...mapActions(contentPartPagesStore, ['updatePage']),
    ...mapActions(contentPartBuilderStore, [
      'toggleIsLoading',
      'addPreviewItem',
      'movePreviewItemUp',
      'movePreviewItemDown',
      'deletePreviewItem',
      'updatePreviewItems',
      'clearPreviewItems',
      'fetchBlockContent',
      'fetchBlocks',
      'saveBlocks',
      'resetPreview',
      'setConfig',
      'setFilters',
      'renderMediaTemplate'
    ]),
    async fetchContentPart() {
      if (!this.isContentPartList) {
        await this.setContentPart()
        this.toggleIsLoading()
      } else if (this.isLoading) {
        this.toggleIsLoading()
      }
    },
    handleDrop(event) {
      event.preventDefault()
      const { itemIndex = this.previewItems.length } = event.target.dataset
      const ID = event.dataTransfer.getData('ID')
      this.addPreviewItem({ data: this.contentPartRow(parseInt(ID)), itemIndex })
    },
    handleDragStart(id, event) {
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('ID', id)
    },
    handleDragEnd() {
      this.dropItemIndex = null
    },
    handleOver(event) {
      event.preventDefault()
      const { itemIndex } = event.target.dataset
      this.dropItemIndex = Number(itemIndex)
    },
    editPreviewItem(index) {
      this.contentPartBlock = this.previewItems[index]
      this.dialogVisible = true
    },
    handleEditorActivate() {
      this.isEditorLoading = false
    },
    toggleOptionsVisibility() {
      this.showOptions = !this.showOptions
    },
    async savePreview(isPublish) {
      this.isBuilderLoading = true
      const page = this.pages.find((el) => el.ID === this.config.currentPage)
      if (page) {
        page.IsActive = isPublish
        await this.updatePage(page)
        await this.saveBlocks()
        this.setConfig({ isActive: isPublish })
      } else {
        this.$router.push(pagesRoute)
      }
      this.isBuilderLoading = false
    },
    showPreview() {
      window.open(
        `${PREVIEW_URL}${this.config.currentPage}/${this.config.siteID}`,
        '_blank'
      )
    },
    async saveAndPreview() {
      await this.savePreview(this.config.isActive)
      this.showPreview()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/mixin.scss";
$base-spacing: 5px;
$success: #67c23a;
$warning: #e6a23c;
$danger: #f56c6c;
$border-color: #ebeef5;
$grey: #b9babd;

.container {
  width: 100%;
  flex-direction: row;
}
.col {
  border: 1px solid $border-color;
  height: 85vh;
}
.divider {
  margin: 0px;
}
.button-list {
  margin: $base-spacing 0px $base-spacing $base-spacing * 2;
}
.content-part {
  @include scrollBar;
  max-height: 70vh;
  align-self: flex-start;
  overflow: auto;
}
.builder {
  @include scrollBar;
  height: 75vh;
  align-self: flex-start;
  overflow: auto;
}
.text-center {
  text-align: center;
}
.text-end {
  justify-content: flex-end;
}
.text-grey {
  color: $grey;
}
.header {
  margin: $base-spacing * 2 $base-spacing * 2;
  display: flex;
  align-items: center;

  .header-button {
    padding: 7px 12px;
    font-size: 12px;
  }
  &.header-items {
    @include scrollBar;
    overflow: auto;
  }
  .header-dropdown {
    margin: 0px $base-spacing * 2;
  }
}
.action-buttons {
  box-shadow: $base-spacing/5 $base-spacing/5 $base-spacing;
  padding: $base-spacing;
  margin: $base-spacing;
}
.editor {
  width: 100%;

  ::v-deep .tox.tox-tinymce {
    border: 0px;
  }
}
.overlay {
  position: relative;

  .child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
}
.new-tab {
  :after {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
    margin: 0px $base-spacing/2 0px $base-spacing;
  }
}
.heading {
  font-weight: bold;
  margin: auto $base-spacing * 2;
}
.badge {
  cursor: default;
  margin: 0px $base-spacing;
  border-radius: $base-spacing * 4;

  &.success {
    color: #fff;
    background-color: $success;
    border-color: $success;
  }
  &.warning {
    color: #fff;
    background-color: $warning;
    border-color: $warning;
  }
}
.search {
  padding: $base-spacing * 2 $base-spacing;
  border: none;
}
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-item {
  padding: $base-spacing * 2 $base-spacing * 4;
  color: $danger;
}

.highlight-item {
  background-color: #DCDCDC;

}
</style>
