var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-flex" },
    [
      _c(
        "div",
        { staticClass: "margin-left-md table" },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.usersWithInvalidEmails, height: "250" } },
            _vm._l(_vm.headings, function(heading) {
              return _c("el-table-column", {
                key: heading.column,
                attrs: { label: heading.column, prop: heading.column },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          heading.isEmail
                            ? _c(
                                "div",
                                { staticClass: "column" },
                                [
                                  scope.row.isEditModeEnabled
                                    ? _c("el-input", {
                                        staticClass: "margin-right-sm",
                                        attrs: {
                                          placeholder: "Enter valid email",
                                          value: scope.row[heading.column],
                                          clearable: ""
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.handleValueChange(
                                              scope.row.EMail,
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  scope.row.isEditModeEnabled
                                    ? _c("el-button", {
                                        attrs: {
                                          circle: "",
                                          icon: "el-icon-check"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleSaveClick(
                                              scope.row.EMail
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  !scope.row.isEditModeEnabled
                                    ? [
                                        _vm._v(
                                          _vm._s(scope.row[heading.column])
                                        )
                                      ]
                                    : _vm._e(),
                                  !scope.row.isEditModeEnabled
                                    ? _c("el-button", {
                                        attrs: {
                                          type: "primary",
                                          circle: "",
                                          icon: "el-icon-edit"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleEditClick(
                                              scope.row.EMail
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                2
                              )
                            : [_vm._v(_vm._s(scope.row[heading.column]))]
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "margin-left-md self-start",
          attrs: { loading: _vm.isLoading, type: "primary" },
          on: { click: _vm.handleGenerateReportClick }
        },
        [_vm._v(_vm._s(_vm.$t("manageUsers.matchField.generateReport")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }