<template>
  <div class="column-flex">
    <el-col
      v-for="item in validations"
      :key="item.field"
      :span="16"
      class="validation"
      :class="{
        success: item.isValid,
        error: !item.isValid,
      }"
    ><i :class="item.isValid ? 'el-icon-check' : 'el-icon-close'" />
      {{ $t(`errors.${item.value}`) }}</el-col>
    <div class="checbox-wrapper">
      <el-checkbox v-if="isExtIdImportVisible" v-model="isAllowedEMailImport" class="extid-import-field">{{
        $t("manageUsers.matchField.extIdImport")
      }}</el-checkbox>
    </div>
    <div class="margin-left-md table">
      <el-table :data="users.importedUsers" height="250">
        <el-table-column
          v-for="column in headings"
          :key="column.label"
          :prop="column.label"
          :show-overflow-tooltip="true"
          class="overflow-wrap"
        >
          <!-- eslint-disable -->
          <template slot="header" slot-scope="scope">
            <!-- eslint-enable -->
            <el-select
              filterable
              :value="column.value"
              placeholder="Not Recognized"
              :loading="isLoading"
              @change="handleFieldSelect(column.label, $event)"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-button
      :loading="isLoading"
      type="primary"
      :disabled="disableGenerateReportButton"
      class="margin-left-md self-start"
      @click="handleGenerateReportClick"
    >{{ $t("manageUsers.matchField.generateReport") }}</el-button>
  </div>
</template>
<script>
import { MUTATION_TYPES } from '@/store/mutations'
import { createNamespacedHelpers } from 'vuex'
import { invalidEmailFoundEvent, nextClickEvent } from './config'
import { Column } from '@/models/column'
import { Account } from '@/models/account'
import store from '@/store'

const { mapState, mapMutations, mapActions } =
  createNamespacedHelpers('user/importedUsers')
const { mapState: mapAccountsState } = createNamespacedHelpers('user/accounts')
export default {
  name: 'MatchFields',
  computed: {
    ...mapAccountsState({
      selectedAccount: (state) => state.selectedAccount,
      isSubAccountRequired: (state) =>
        Account.isFullAccountMatch(state.selectedSubAccount) ||
        !!state.selectedSubAccount,
      selectedSubAccounts: (state) =>
        Account.isFullAccountMatch(state.selectedSubAccount)
          ? state.selectedAccountInfo.allSubAccounts
          : [state.selectedSubAccount]
    }),
    ...mapState({
      options: (state) => state.columns.toOptions(),
      columns: state => state.columns,
      validations: function(state) {
        return state.importedUsers.createValidations(this.isSubAccountRequired)
      },
      isLoading: (state) => state.isLoading,
      users: (state) => state.importedUsers,
      headings: (state) => state.importedUsers.headings.toOptions(),
      usersWithInvalidEmails: (state) =>
        state.importedUsers.usersWithInvalidEmails,
      disableGenerateReportButton: function(state) {
        return !state.importedUsers.isValid(
          this.isSubAccountRequired
        )
      }
    }),
    isExtIdImportVisible() {
      return store.state.user.user.isUserAllowedToImportExtId
    },
    isAllowedEMailImport: {
      get() {
        return this.users.isEMailImport
      },
      set(newValue) {
        this.users.setEMailImportField(newValue)
      }
    }
  },
  async created() {
    await this.fetchColumns()
  },
  methods: {
    ...mapMutations({
      matchColumn: MUTATION_TYPES.USER.IMPORTED_USERS.MATCH_COLUMN,
      setInvalidUsers: MUTATION_TYPES.USER.IMPORTED_USERS.SET_INVALID_USERS,
      setExtIdSelected: MUTATION_TYPES.USER.IMPORTED_USERS.SET_EXTID_SELECTED
    }),
    ...mapActions(['fetchColumns']),
    handleFieldSelect(column, value) {
      if (this.selectedAccount) {
        if (
          Column.isAccount(value) &&
          !this.users.isValidAccountMatch({
            column,
            selectedAccount: this.selectedAccount
          })
        ) {
          const unMatchedAccounts =
            this.users.getUnMatchedAccountsWithRowNumber({
              column,
              selectedAccount: this.selectedAccount
            })
          return this.$message.error({
            dangerouslyUseHTMLString: true,
            message: `<p class="text-error">${this.$t('manageUsers.matchField.accountMismatch')}</p>
            ${this.parseMismatchValuesToHtml(unMatchedAccounts)}
            `
          })
        }
        if (
          Column.isSubAccount(value) &&
          !this.users.isValidSubAccountMatch({
            column,
            selectedSubAccounts: this.selectedSubAccounts
          })
        ) {
          const unMatchedSubAccounts =
            this.users.getUnMatchedSubAccountsWithRowNumber({
              column,
              selectedSubAccounts: this.selectedSubAccounts
            })
          return this.$message.error({
            dangerouslyUseHTMLString: true,
            message: `<p class="text-error">${this.$t('manageUsers.matchField.subAccountMismatch')}</p>
            ${this.parseMismatchValuesToHtml(unMatchedSubAccounts)}
            `
          })
        }
      }
      if (Column.isExtId(value)) {
        if (!this.users.validateNonEmpty(column)) {
          return this.$message.error({
            dangerouslyUseHTMLString: true,
            message: `<p class="text-error">${this.$t('manageUsers.matchField.extIdColumnPartiallyFilled')}</p>
            `
          })
        }
        this.users.setExtIdImportField(true)
      }

      if (Column.isExtId(column)) {
        this.users.setExtIdImportField(false)
      }

      this.matchColumn({ column, value })
    },
    handleGenerateReportClick() {
      if (!this.usersWithInvalidEmails.length) {
        this.$emit(nextClickEvent)
      } else {
        this.setInvalidUsers()
        this.$emit(invalidEmailFoundEvent)
      }
    },
    parseMismatchValuesToHtml(mismatchedValues = []) {
      return ` <ul class="mismatch">
                       ${mismatchedValues.map(
    (mismatchedValue) =>
      `<li class="mismatch-item"> Row ${mismatchedValue.row} : ${
        mismatchedValue.value || 'Not specified'
      } </li>`
  ).join('')}
                    </ul>`
    }
  }
}
</script>

<style lang="scss" scoped>
$base-spacing: 5px;
.table {
  width: 70vw;
  overflow: scroll;
}
.margin-left-md {
  margin: $base-spacing * 5;
}

.self-start {
  align-self: flex-start;
}
.validation {
  margin-left: $base-spacing * 5;
}

.column-flex {
  display: flex;
  flex-direction: column;
}
.success {
  color: #28a745;
}
.error {
  color: #dc3545;
}
.mismatch {
  max-height: $base-spacing * 40;
  overflow: auto;
}
.mismatch-item{
  padding: $base-spacing;
}
.extid-import-field {
  display: flex;
  justify-content: flex-end;
  padding-right: $base-spacing * 5;
}
::v-deep .el-checkbox__label {
  line-height: 13px !important;
}
.el-checkbox.extid-import-field {
  width: 150px;
}
.checbox-wrapper {
  display: flex;
  justify-content: flex-end;
}
</style>
