<template>
  <div class="chat-assistant-container">
    <div class="chat-assistants-grid">
      <div
        v-for="(assistant, index) in assistants"
        :key="assistant.ref"
        class="assistant-item"
      >
        <div>
          <span class="assistant-name">{{ assistant.value[index].name }}</span>
        </div>
        <el-select
          :ref="assistant.ref"
          class="threadDropDown"
          :value="selectedThreadId[assistant.ref]"
          filterable
          :placeholder="$t('selectThread')"
          size="large"
          :no-data-text="$t('noThreads')"
          @change="handleChange(assistant.ref, $event)"
        >
          <el-option
            v-for="item in threads[
              parseInt(assistant.ref.replace(/\D/g, ''), 10) - 1
            ]"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
        <deep-chat
          :ref="assistant.ref"
          class="deep-chat-bot"
          :directConnection="directConnection(assistant.value[index].id)"
          :initialMessages="initialMessages[assistant.ref]"
          demo="true"
          stream="true"
          gifs='{"button": {"position": "dropup-menu"}}'
          audio='{"button": {"position": "dropup-menu"}}'
          camera='{"button": {"position": "dropup-menu"}}'
          mixed-files='{"button": {"position": "inside-left"}}'
          microphone='{"button": {"position": "outside-right"}}'
          @new-message="handleNewMessage(assistant.ref, $event)"
          @render="onComponentRender"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DeepChat from 'deep-chat'
import AssistantService from '@/api/assistants'
import { Message } from 'element-ui'

export default {
  name: 'CompareAssistants',
  components: {
    DeepChat
  },
  props: {
    selectedAssistants: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      noOfassistants: 1,
      selectedThreadId: {},
      assistants: [{
        ref: 'deepChatRef_1',
        value: this.selectedAssistants
      }],
      initialMessages: {},
      index: -1,
      threads: []
    }
  },
  methods: {
    onComponentRender() {
      if (this.assistants.length <= this.selectedAssistants.length) {
        const assistantsRefs = this.assistants.map(assistant => assistant.ref)
        const assistant = this.selectedAssistants.find((assistant, index) =>
          assistantsRefs.slice(-1)[0].includes(index + 1)
        )

        if (assistant.metadata?.payload?.threadIds?.length) {
          this.threads.push(JSON.parse(assistant.metadata?.payload?.threadIds))
        } else {
          this.threads.push([])
        }
      }
      if (this.assistants.length < this.selectedAssistants.length) {
        this.assistants.push({
          ref: `deepChatRef_${this.assistants.length + 1}`,
          value: this.selectedAssistants
        })
      }
    },
    handleChange(assistant, newThreadId) {
      this.selectedThreadId = {
        ...this.selectedThreadId,
        [assistant]: newThreadId
      }
      this.setMessages(assistant)
    },
    async setMessages(assistant) {
      const messagesToShow = await this.retrieveMessages(assistant)
      let response = messagesToShow?.data?.reverse()
      if (response?.length) {
        response = response.map((data, i) => {
          if (i % 2 !== 0) {
            return {
              text: `${data.content[0].text.value}`,
              role: 'ai'
            }
          }
          return {
            text: `${data.content[0].text.value}`,
            role: 'user'
          }
        })

        this.initialMessages = {
          ...this.initialMessages,
          [assistant]: JSON.stringify(response)
        }
      }
    },
    async retrieveMessages(assistant) {
      try {
        const response = await AssistantService.retrieveThreadMessages(
          this.selectedThreadId[assistant]
        )
        return response
      } catch (error) {
        Message({
          type: 'error',
          message: error.message
        })
      }
      return []
    },
    directConnection(assistantId) {
      return `{
        "openAI": {
          "key": "${process.env.VUE_APP_OPENAI_API_KEY}",
          "assistant": {"assistant_id": "${assistantId}"}
        }
      }`
    },
    handleNewMessage(assistant, event) {
      event.target._activeService.sessionId = this.selectedThreadId[assistant]
    }
  }
}
</script>

<style>
.chat-assistant-container {
  display: flex;
  flex-direction: column;
}

.chat-assistants-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  flex-direction: row;
  justify-content: center;
}

.assistant-item {
  margin-bottom: 16px;
}

.threadDropDown {
  margin: 10px 0px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.clone-button {
  display: flex;
  justify-content: center;
}

.deep-chat-bot {
  border-radius: 8px;
  max-width: 100%;
}

.assistant-name {
  display: flex;
  flex-wrap: wrap;
  word-break: break-all;
    text-wrap: wrap;
    overflow: hidden;
    width: 320px;
    height: 40px
}
</style>
