import {
  createResource,
  deleteResource,
  fectchResource
} from '@/utils/httpRequests'
export default class MenuService {
  static fetchAll() {
    return fectchResource(
      `${process.env.VUE_APP_ODATA_API}/AdminMenu?$filter=IsActive eq true`
    )
  }

  static fetchByUserId(userId) {
    return fectchResource(
      `${process.env.VUE_APP_CONTENTPART_API}/adminmenu/${userId}`
    )
  }

  static disable(data) {
    return createResource({
      url: `${process.env.VUE_APP_ODATA_API}/AdminMenuDisabled`,
      data
    })
  }

  static enable(id) {
    return deleteResource(
      `${process.env.VUE_APP_ODATA_API}/AdminMenuDisabled(${id})`
    )
  }

  static fetchDisabledByUserId(userId) {
    return fectchResource(
      `${process.env.VUE_APP_ODATA_API}/AdminMenuDisabled?$filter=CustomersID eq ${userId}`
    )
  }
}
