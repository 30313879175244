<template>
  <el-main class="container">
    <el-row type="flex" :gutter="10">
      <el-col :xs="24" :sm="6">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="right"
          :start-placeholder="`${$t('mediaReports.startDatePlaceholder')}`"
          :end-placeholder="`${$t('mediaReports.endDatePlaceholder')}`"
          :format="dateFormat"
        />
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="6">
        <NumberInput
          :value="selectedFilters.startCustomerID"
          :placeholder="`${$t('invertedMediaReports.startCustomer')}`"
          @change="handleStartCustomerChange"
        />
      </el-col>
      <el-col :xs="24" :sm="6">
        <NumberInput
          :value="selectedFilters.endCustomerID"
          :placeholder="`${$t('invertedMediaReports.endCustomer')}`"
          @change="handleEndCustomerChange"
        />
      </el-col>
    </el-row>
    <el-row type="flex" :gutter="10">
      <el-col :xs="24" :sm="6">
        <el-select
          v-model="selectedFilters.startCategoryID"
          filterable
          :placeholder="`${$t('invertedMediaReports.startCategory')}`"
          :loading="areCategoriesLoading"
        >
          <el-option
            v-for="category in categories"
            :key="category.ID"
            :label="category.Name"
            :value="category.ID"
          />
        </el-select>
      </el-col>
      <el-col :xs="24" :sm="6">
        <el-select
          v-model="selectedFilters.endCategoryID"
          filterable
          :placeholder="`${$t('invertedMediaReports.endCategory')}`"
          :loading="areCategoriesLoading"
        >
          <el-option
            v-for="category in endCategories"
            :key="category.ID"
            :label="category.Name"
            :value="category.ID"
          />
        </el-select>
      </el-col>
      <el-col :xs="24" :sm="6">
        <el-select
          v-model="selectedFilters.startMediaID"
          filterable
          :placeholder="`${$t('invertedMediaReports.startMedia')}`"
          :loading="areEpisodesLoading"
        >
          <el-option
            v-for="episode in episodes"
            :key="episode.ID"
            :label="episode.Title"
            :value="episode.ID"
          />
        </el-select>
      </el-col>
      <el-col :xs="24" :sm="6">
        <el-select
          v-model="selectedFilters.endMediaID"
          filterable
          :placeholder="`${$t('invertedMediaReports.endMedia')}`"
          :loading="areEpisodesLoading"
        >
          <el-option
            v-for="episode in endMedias"
            :key="episode.ID"
            :label="episode.Title"
            :value="episode.ID"
          />
        </el-select>
      </el-col>
    </el-row>
    <el-row>
      <el-button
        type="primary"
        :disabled="isFilterDisabled"
        @click="filter"
      >{{ $t("mediaReports.filter") }}
      </el-button>
    </el-row>
  </el-main>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import NumberInput from '@/components/NumberInput/index.vue'

const {
  mapState: mapContentPartState,
  mapActions: mapContentPartActions,
  mapMutations: mapContentPartMutations
} = createNamespacedHelpers('contentPart')
import { MUTATION_TYPE } from '@/store/modules/contentPart'

export default {
  name: 'InvertedMediaReportsFilters',
  components: {
    NumberInput
  },
  data() {
    return {
      dateRange: [],
      selectedMediaReports: [],
      dateFormat: 'dd/MM/yyyy',
      selectedFilters: {
        startCategoryID: '',
        endCategoryID: '',
        startMediaID: '',
        endMediaID: '',
        startCustomerID: '',
        endCustomerID: ''
      }
    }
  },

  computed: {
    ...mapContentPartState({
      categories: (state) => state.categories,
      areCategoriesLoading: (state) => state.dialogBoxConfig.isCategoryLoading,
      episodes: (state) => state.medias,
      areEpisodesLoading: function(state) {
        return !!this.selectedCategory && state.dialogBoxConfig.isMediaLoading
      }
    }),
    filterParams() {
      return {
        startDate: this.dateRange?.[0]?.toString()?.slice(0, 15),
        endDate: this.dateRange?.[1]?.toString()?.slice(0, 15),
        ...this.selectedFilters
      }
    },
    isFilterDisabled() {
      return Object.values(this.filterParams).some((value) => !value)
    },
    endCategories() {
      return this.categories.filter(
        (item) => item.ID !== this.selectedFilters.startCategoryID
      )
    },
    endMedias() {
      return this.episodes.filter(
        (item) => item.ID !== this.selectedFilters.startMediaID
      )
    }
  },
  watch: {
    'selectedFilters.startCategoryID'(newCategory) {
      this.setContentPart({ medias: [] })
      if (newCategory) {
        this.selectedFilters.endCategoryID = ''
        this.selectedFilters.startMediaID = ''
        this.selectedFilters.endMediaID = ''
        this.setMedia(newCategory)
      }
    }
  },
  async created() {
    this.setDialogBoxConfig({
      isCategoryLoading: true
    })
    await this.setCategories()
    this.setDialogBoxConfig({
      isCategoryLoading: false
    })
  },
  methods: {
    ...mapContentPartMutations({
      setContentPart: MUTATION_TYPE.SET_CONTENT_PART
    }),

    ...mapContentPartActions([
      'setCategories',
      'setDialogBoxConfig',
      'setMedia'
    ]),
    filter() {
      this.$emit('filter', this.filterParams)
    },
    handleStartCustomerChange(event) {
      this.selectedFilters.startCustomerID = event
    },

    handleEndCustomerChange(event) {
      this.selectedFilters.endCustomerID = event
    }
  }
}
</script>
