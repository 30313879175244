import { MediaReportsFilters } from '@/models/mediaReportsFilters'
export class MediaReportFilterV2 extends MediaReportsFilters {
  constructor({
    customerIds = [],
    tagId = '',
    account = '',
    subAccount = '',
    siteId = '',
    categoryID = '',
    episodeID = '',
    startDate = null,
    endDate = null,
    dateFormat = 'dd/MM/yyyy',
    mediaIds = []
  } = {}) {
    super({
      customerIds,
      tagId,
      account,
      subAccount,
      siteId,
      categoryID,
      episodeID,
      startDate,
      endDate,
      dateFormat,
      mediaIds
    })
  }

  get isFilterDisabled() {
    return (
      this.startDate &&
      this.endDate &&
      (this.categoryID || this.account || this.episodeID || this.tagId)
    )
  }

  getFilteredEpisodes({ mediasForCategoryAndSite, allMedias }) {
    return this.categoryID && this.siteId
      ? mediasForCategoryAndSite
      : this.categoryID
        ? allMedias.filter(media => this.mediaIds.includes(media.ID))
        : this.siteId
          ? []
          : allMedias
  }

  clearCategoryAndEpisode() {
    this.categoryID = ''
    this.episodeID = ''
  }

  clearFiltersV2() {
    this.account = ''
    this.subAccount = ''
    this.categoryID = ''
    this.episodeID = ''
    this.startDate = ''
    this.endDate = ''
    this.siteId = ''
    this.mediaIds = []
  }

  parse() {
    const baseFilter = [
      ...(this.startDate && this.endDate
        ? [
          ['LastUpdate', 'ge', this.startDate?.toISOString()],
          ['LastUpdate', 'le', this.endDate?.toISOString()]
        ]
        : []),
      ...(this.customerIds?.length
        ? [
          [
            `CustomerID in (${this.customerIds
              .map(item => `'${item}'`)
              .join(',')})`
          ]
        ]
        : []),
      ...(this.account ? [['Account', '=', `'${this.account}'`]] : []),
      ...(this.subAccount ? [['SubAccount', '=', `'${this.subAccount}'`]] : []),
      ...(this.episodeID ? [['MediaID', '=', `${this.episodeID}`]] : []),
      ...(this?.mediaIds?.length
        ? [['MediaID', 'in', `(${this.mediaIds.filter(Boolean).join(',')})`]]
        : [])
    ].reduce(
      (filter, element, index) => [
        ...filter,
        ...(index ? ['and'] : []),
        element
      ],
      []
    )

    return baseFilter
  }
}
