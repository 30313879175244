<template>
  <content-part-select
    :placeholder="$t('contentPart.site')"
    :options="contentPartSites"
    :selected="site"
    size="mini"
    class="select-width"
    @change="handleChange"
  />
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ContentPartSelect from '@/components/ContentPartSelect'

const contentPartStore = 'contentPart'
const appStore = 'app'

export default {
  name: 'SiteSwitcher',
  components: {
    ContentPartSelect
  },
  computed: {
    ...mapGetters(['contentPartSites']),
    ...mapState(appStore, ['siteID']),
    site() {
      return this.siteID > 0 ? this.siteID : ''
    }
  },
  methods: {
    ...mapActions(contentPartStore, ['setSites']),
    ...mapActions(appStore, ['setSiteID']),
    handleChange(event) {
      this.setSiteID(event)
      this.$emit('change', event)
    }
  }
}
</script>

<style lang="scss" scoped>
.select-width {
  width: 100%;
}
</style>
