const { CorsTester } = require('@/models/corsTester')
import { checkResource } from '@/utils/httpRequests'
import { MUTATION_TYPES as BASE_MUTATION_TYPES } from '../mutations'
import { Message } from 'element-ui'
import i18n from '@/i18n'
const MUTATION_TYPES = BASE_MUTATION_TYPES.CORS_TESTER
const state = {
  corsTester: new CorsTester(),
  loading: false
}
const mutations = {
  [MUTATION_TYPES.SET_LOADING]: (state, loading) => {
    state.loading = loading
  }
}
const actions = {
  async checkAction({ commit, state }) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      await checkResource(state.corsTester.toParams())

      Message({
        message: i18n.t('corsTester.successMessage'),
        type: 'success'
      })
    } catch (err) {
      Message({
        message: err,
        type: 'error'
      })
    } finally {
      commit(MUTATION_TYPES.SET_LOADING, false)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
