var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "div-center" }, [
    _c("div", { staticClass: "inner-div" }, [
      _c("h1", { staticClass: "title-container" }, [
        _vm._v(_vm._s(_vm.$t("updatedResultSendList.result")))
      ]),
      _c(
        "div",
        { staticClass: "margin-bottom" },
        [
          _vm.UpdatedUser.length
            ? _c("h2", [
                _c("span", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm.UpdatedUser.length))
                ]),
                _vm._v(_vm._s(_vm.$t("updatedResultSendList.existinglist")))
              ])
            : _vm._e(),
          _vm._l(_vm.UpdatedUser, function(contact) {
            return _c("p", { key: contact.id, staticClass: "subtitle" }, [
              _c("span", { staticClass: "dot" }),
              _vm._v(" " + _vm._s(contact.email) + " ")
            ])
          })
        ],
        2
      ),
      _c(
        "div",
        [
          _vm.newCreatedUser.length
            ? _c("h2", [
                _c("span", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm.newCreatedUser.length))
                ]),
                _vm._v(_vm._s(_vm.$t("updatedResultSendList.newlist")))
              ])
            : _vm._e(),
          _vm._l(_vm.newCreatedUser, function(contact) {
            return _c("p", { key: contact.id, staticClass: "subtitle" }, [
              _c("span", { staticClass: "dot" }),
              _vm._v(" " + _vm._s(contact.email) + " ")
            ])
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }