import ODataStore from 'devextreme/data/odata/store'
import { Deferred } from 'devextreme/core/utils/deferred'
import { StoreConfig } from './odataStoreConfig'
export default class Store extends ODataStore {
  items;
  constructor(options = new StoreConfig(), ConfigDI = StoreConfig) {
    const config = new ConfigDI(options)
    super(config)
    this.config = config
    this.items = []
    this.on('loaded', this.setItems)
  }

  byKey(key) {
    const deferredObj = Deferred()

    const item = this.items.find(item => item[this.key()] === key)
    if (!item) {
      return super.byKey(key)
    }
    deferredObj.resolve(item)
    return deferredObj.promise()
  }

  setItems(items) {
    this.items = items
  }
}
