<template>
  <div class="chat-assistant-container">
    <div class="chat-assistants-grid">
      <div
        v-for="assistant in assistants"
        :key="assistant"
        class="assistant-item"
      >
        <deep-chat
          :ref="assistant"
          class="deep-chat-bot"
          :directConnection="directConnection"
          gifs='{"button": {"position": "dropup-menu"}}'
          audio='{"button": {"position": "dropup-menu"}}'
          camera='{"button": {"position": "dropup-menu"}}'
          mixed-files='{"button": {"position": "inside-left"}}'
          microphone='{"button": {"position": "outside-right"}}'
          @new-message="handleNewMessage(assistant)"
        />
        <div class="button-container">
          <el-button type="primary" @click="clearMessages(assistant)">{{
            $t("clear")
          }}</el-button>
          <el-button
            :disabled="!areMultipleChatAssistants"
            type="danger"
            @click="deleteAssistant(assistant)"
          >{{ $t("delete") }}</el-button>
        </div>
      </div>
    </div>
    <div class="clone-button">
      <el-button icon="el-icon-plus" @click="cloneAssistant" />
    </div>
  </div>
</template>

<script>
import DeepChat from 'deep-chat'
import { createNamespacedHelpers } from 'vuex'
import { ChatAssistant } from '@/models/chatAssistant'
import { ChatAssistantService } from '@/api/chatAssistant'
import AssistantService from '@/api/assistants'
const { mapState: mapUserState } = createNamespacedHelpers('user')
export default {
  name: 'ChatAssistant',
  components: {
    DeepChat
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: true
    },
    assistant: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      noOfassistants: 1,
      assistants: ['deepChatRef_1']
    }
  },
  computed: {
    directConnection() {
      return `{
        "openAI": {
          "key": "${process.env.VUE_APP_OPENAI_API_KEY}",
          "assistant": {"assistant_id": "${this.assistant.id}"}
        }
      }`
    },
    areMultipleChatAssistants() {
      return this.assistants.length > 1
    },
    ...mapUserState({
      loggedInUser: state => state.user.ID
    }),
    assistantThreads() {
      if (this?.assistant?.metadata?.payload?.threadIds?.length) {
        return JSON.parse(this?.assistant?.metadata?.payload?.threadIds)
      }
      return []
    }
  },
  watch: {
    isDialogOpen(newVal) {
      if (!newVal) {
        this.assistants = ['deepChatRef_1']
        this.noOfassistants = 1
      }
    }
  },
  methods: {
    clearMessages(assistant) {
      this.$refs[assistant][0].clearMessages()
    },
    deleteAssistant(assistantToDelete) {
      if (this.areMultipleChatAssistants) {
        this.assistants = this.assistants.filter(
          assistant => assistant !== assistantToDelete
        )
      }
    },
    cloneAssistant() {
      this.noOfassistants += 1
      this.assistants.push('deepChatRef_' + this.noOfassistants)
    },
    async handleNewMessage(assistant) {
      const messages = this.$refs[assistant][0].getMessages()
      const assistantId = this.$refs[assistant][0]._activeService.rawBody.assistant_id
      const userMessages = messages.filter(message => message.role === 'user')
      const aiMessages = messages.filter(message => message.role === 'ai')
      const userQuery = userMessages[userMessages?.length - 1]?.text
      const assistantResponse = aiMessages[aiMessages?.length - 1]?.text
      const threadId = userMessages[userMessages?.length - 1]?._sessionId
      const data = {
        assistantId,
        userQuery,
        assistantResponse,
        threadId,
        customerId: this.loggedInUser
      }
      if (assistantResponse) {
        await ChatAssistantService.createCopy(ChatAssistant.createParams(data))
        if (!this.assistantThreads.includes(threadId)) {
          this.assistantThreads.push(threadId)
          await AssistantService.update({
            id: assistantId,
            payload: {
              metadata: {
                'threadIds': JSON.stringify(this.assistantThreads)
              }
            }
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.chat-assistant-container {
  display: flex;
  flex-direction: column;
}

.chat-assistants-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  flex-direction: row;
  justify-content: center;
}

.assistant-item {
  margin-bottom: 16px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.clone-button {
  display: flex;
  justify-content: center;
}

.deep-chat-bot {
  border-radius: 8px;
  max-width: 100%;
}
</style>
