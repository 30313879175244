<template>
  <div class="menu-container">
    <div v-for="menu in menus" :key="menu.ID">
      <span>
        <DxCheckBox
          v-model="menu.value"
          @value-changed="handleValueChanged(menu.ID)"
        />
        {{ menu.Name }}
      </span>
      <div class="sub-menu-container">
        <MenuManager :menus="menu.children" />
      </div>
    </div>
  </div>
</template>

<script>
import DxCheckBox from 'devextreme-vue/check-box'
import { createNamespacedHelpers } from 'vuex'
import { MUTATION_TYPES } from '@/store/mutations'

const { mapMutations } = createNamespacedHelpers('app')

export default {
  name: 'MenuManager',
  components: { DxCheckBox },
  props: {
    menus: {
      type: [Object, Array],
      default: () => []
    }
  },
  methods: {
    ...mapMutations({
      toggleSelection: MUTATION_TYPES.APP.TOGGLE_MENU_SELECTION
    }),
    customizeItem(item) {
      if (!item.template) {
        item.template = 'form-item'
      }
    },
    handleValueChanged(id) {
      this.toggleSelection(id)
    }
  }
}
</script>

<style scoped lang="scss">
.menu-container {
  padding: 5px;
}
.sub-menu-container {
  padding-left: 32.5px;
}

.dx-checkbox.dx-widget {
  margin: 10px;
}
</style>
