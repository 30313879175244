import i18n from '@/i18n'
import ContentPartService from '@/api/contentPart'
import generateFilename from '@/utils/filename'

const uploadImage = async(blobInfo, success, failure, progress) => {
  const formData = new FormData()
  formData.append('file', blobInfo.blob())
  const filename = generateFilename(blobInfo.filename())
  formData.append('filename', filename)

  const onUploadProgress = event => {
    progress((event.loaded / event.total) * 100)
  }

  try {
    const response = await ContentPartService.createCMSFile({
      data: formData,
      onUploadProgress
    })
    success(`${IMAGE_CONTENT_URL}${response}`)
  } catch (error) {
    failure(error.message)
  }
}

const CONTENT_PART_MODEL = {
  ID: {
    type: Number,
    default: 0
  },
  Name: {
    type: String,
    default: ''
  },
  MainContent: {
    type: String,
    default: ''
  },
  Tag: {
    type: String,
    default: ''
  },
  SortOrder: {
    type: Number,
    default: 0
  },
  Position: {
    type: String,
    default: ''
  },
  MainPicture: {
    type: String,
    default: ''
  },
  SiteID: {
    type: Number,
    default: 0
  },
  ContentPartTemplateID: {
    type: String,
    default: ''
  },
  PreviewImage: {
    type: String,
    default: ''
  }
}

const DIALOG_TYPES = {
  EDIT: 'edit',
  CREATE: 'create',
  CLONE: 'clone'
}

const SELECT_MEDIA_MESSAGE = `<h1>${i18n.t('errors.selectMedia')}</h1>`

const validateName = (rule, value, callback) => {
  if (!value) {
    callback(new Error(i18n.t('errors.inputName')))
  } else if (value.length < 3) {
    callback(new Error(i18n.t('errors.minNameLength')))
  } else {
    callback()
  }
}

const validateMainContent = (rule, value, callback) => {
  if (!value) {
    callback(new Error(i18n.t('errors.inputMainContent')))
  } else if (value === SELECT_MEDIA_MESSAGE) {
    callback(new Error(i18n.t('errors.selectMedia')))
  } else {
    callback()
  }
}

const validateSelect = ({ rule, value, callback, type }) => {
  if (!value) {
    callback(new Error(i18n.t(`errors.select${type}`)))
  } else {
    callback()
  }
}

const validators = {
  validateTemplate: (rule, value, callback) =>
    validateSelect({ rule, value, callback, type: 'Template' }),

  validatePosition: (rule, value, callback) =>
    validateSelect({ rule, value, callback, type: 'Position' }),

  validateTag: (rule, value, callback) =>
    validateSelect({ rule, value, callback, type: 'Tag' }),
  validateName,
  validateMainContent
}

const IMAGE_CONTENT_URL = process.env.VUE_APP_IMAGE_CONTENT_URL

const fontSizes = Array.from({ length: 25 }, (_, i) => `${i + 8}px`).join(' ')
const tinyMCEConfig = {
  fontsize_formats: `${fontSizes} 34px 36px 38px 40px 44px 48px 54px 60px 72px 75px 80px`,
  content_css: [
    '/app.effaba9.css',
    '/template-styles.css',
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    '/editor-styles.css'
  ],
  content_style:
    "@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,600i,700&amp;display=swap')",
  height: 500,
  menubar: false,
  plugins: [
    'advlist autolink lists link image preview',
    'searchreplace visualblocks fullscreen',
    'insertdatetime table help wordcount',
    'code preview',
    'autoresize'
  ],
  font_formats:
    "Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; OpenSans='Open Sans'; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings",
  toolbar:
    'undo redo | link image | fontselect formatselect fontsizeselect forecolor | ' +
    'bold italic | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | preview ' +
    'code preview' +
    'removeformat | fullscreen | help',
  branding: false,
  images_upload_handler: uploadImage,
  file_picker_types: 'image',
  resize: true
}

const TINYMCE_API_KEY = process.env.VUE_APP_TINYMCE_API_KEY

const createFormData = file => {
  const data = new FormData()
  data.append('file', file)
  const filename = generateFilename(file.name)
  data.append('filename', filename)
  return data
}

const parseDataURLtoFile = (dataurl, filename) => {
  const items = dataurl.split(',')
  const mime = items[0].match(/:(.*?);/)[1]
  const becryptedFileAsString = atob(items[1])
  let becryptedFileLength = becryptedFileAsString.length
  const u8array = new Uint8Array(becryptedFileLength)

  while (becryptedFileLength--) {
    u8array[becryptedFileLength] = becryptedFileAsString.charCodeAt(
      becryptedFileLength
    )
  }

  return new File([u8array], filename, { type: mime })
}

export {
  DIALOG_TYPES,
  CONTENT_PART_MODEL,
  validators,
  tinyMCEConfig,
  TINYMCE_API_KEY,
  IMAGE_CONTENT_URL,
  SELECT_MEDIA_MESSAGE,
  parseDataURLtoFile,
  createFormData
}
