import { DateUtils } from '@/utils/date'
import Store from '@/models/odataStore'
const dateField = 'DateAdded'
const idField = 'ID'
export const disabledColumns = [dateField, idField]
export const createColumns = () => {
  const addEdit = [
    {
      field: 'CategoryID',
      width: '100%',
      label: 'mediasByCategory.column.categoryID',
      lookup: {
        store: new Store({ resource: 'Categories' }),
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'ID',
      displayExpr: 'Name'
    },
    {
      field: 'SiteId',
      width: '100%',
      label: 'mediasByCategory.column.siteId',
      lookup: {
        store: new Store({ resource: 'Sites' }),
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'ID',
      displayExpr: 'SiteName'
    },
    {
      field: dateField,
      width: '100%',
      dataType: 'date',
      format: DateUtils.odataDateFormat,
      sort: 'desc'
    }
  ]

  return {
    addEdit,
    all: [
      {
        field: idField,
        width: '100%',
        visible: false
      },
      ...addEdit
    ]
  }
}
