<template>
  <ODataTable
    ref="oDataTableComponentRef"
    :columns="columns.all"
    :add-edit-columns="columns.addEdit"
    resource="AdminMenu"
    :total-label="$t('menus.total')"
    :file-name="$t('menus.fileName')"
    :add-row-button-label="$t('menus.add')"
    :title="$t('menus.title')"
    @rowSaved="handleRowSaved"
  />
</template>

<script>
import ODataTable from '../../../components/ODataTable'
import { createColumns } from './config'
import { createNamespacedHelpers } from 'vuex'
const {
  mapState: mapAppState
} = createNamespacedHelpers('app')

const { mapActions } = createNamespacedHelpers('user')
export default {
  name: 'ManageMenus',
  components: {
    ODataTable
  },
  computed: {
    ...mapAppState({
      menus: state => state.menus.toOptions()
    }),
    columns() {
      return createColumns({
        menus: [{ label: 'Empty', value: null }, ...this.menus]
      })
    }
  },
  methods: {
    ...mapActions(['getInfo']),
    async handleRowSaved() {
      this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
      try {
        await this.getInfo()
      } catch (error) {
        this.$message({
          type: 'error',
          message: error.message
        })
      } finally {
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
      }
    }
  }
}
</script>
