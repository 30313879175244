import { MUTATION_TYPES as BASE_MUTATION_TYPES } from '../mutations'
import { ImportedDownloadables } from '@/models/importedDownloadables'
import { Message } from 'element-ui'
import ExternalMediaService from '@/api/downloadable'
import { PaginatedResponse } from '@/models/paginatedResponse'

const MUTATION_TYPES = BASE_MUTATION_TYPES.IMPORTED_DOWNLOADBALES

const state = {
  importedDownloadables: {
    importedUsers: []
  },
  downloadableIds: [],
  headings: [],
  allDownloadableIds: []
}

const mutations = {
  [MUTATION_TYPES.SET_DOWNLOADABLES]: (state, downloadables = []) => {
    state.importedDownloadables = downloadables
  },
  [MUTATION_TYPES.SET_HEADINGS]: (state, headings = []) => {
    state.headings = headings
  },
  [MUTATION_TYPES.SET_TRANSFORM_TO_HTML_STATE]: (
    state,
    shouldTransformToHtml = false
  ) => {
    state.importedDownloadables.shouldTransformToHtml = shouldTransformToHtml
  },
  [MUTATION_TYPES.SET_ALL_DOWNLOADABLE_IDS]: (
    state,
    allDownloadableIds = []
  ) => {
    state.allDownloadableIds = allDownloadableIds
  }
}

const actions = {
  async generateDownloadableReport({ state, commit }, headings) {
    try {
      const response = await ExternalMediaService.getAll()
      const paginatedResponse = new PaginatedResponse(response)

      while (paginatedResponse.hasMore) {
        await paginatedResponse.next()
      }
      commit(
        MUTATION_TYPES.SET_ALL_DOWNLOADABLE_IDS,
        paginatedResponse.items.map(el => el.ID)
      )
      await ImportedDownloadables.updateDownloadables(
        state.importedDownloadables.importedUsers,
        headings,
        state.allDownloadableIds
      )
    } catch (error) {
      Message({
        type: 'error',
        message: error.message
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
