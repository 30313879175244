<template>
  <el-main class="container">
    <ODataTable
      ref="oDataTableComponentRef"
      :key="filter"
      :columns="columns"
      resource="viewMediaReportDone"
      :total-label="$t('mediaReports.total')"
      :file-name="$t('mediaReports.fileName')"
      :title="$t('mediaReports.title')"
      :should-show-add-button="false"
      :should-show-edit-button="false"
      :should-show-delete-button="false"
      :show-data="showData"
      :row-key="rowKey"
      :filter="filter"
      @clearFilters="handleFiltersClear"
    >
      <template #custom-filters>
        <media-reports-filters
          ref="mediaReportFiltersRef"
          @filter="filterMediaReports"
        />
      </template>
    </ODataTable>
  </el-main>
</template>
<script>
import ODataTable from '../../components/ODataTable'
import MediaReportsFilters from './filters.vue'

import { columns, rowKey } from './config'

export default {
  name: 'MediaReports',
  components: {
    ODataTable,
    MediaReportsFilters
  },
  data() {
    return {
      showData: false,
      columns,
      rowKey,
      filter: []
    }
  },

  methods: {
    filterMediaReports(filters) {
      if (!this.showData) {
        this.filter = filters
        this.showData = true
        return null
      }
      this.filter = filters
    },
    handleFiltersClear() {
      this.$refs.mediaReportFiltersRef.reset()
      this.showData = false
    }
  }
}
</script>
