<template>
  <div class="chat-assistant-container">
    <div class="chat-assistants-grid">
      <div class="assistant-item">
        <div v-if="!assistantThreads.length && !sessionId" class="empty-threads-message">
          <span>{{ $t("noThreads") }}</span>
        </div>
        <div v-if="assistantThreads.length">
          <el-select
            v-if="!sessionId"
            class="threadDropDown"
            :value="selectedThreadId"
            filterable
            placeholder="Select Thread"
            size="large"
            @change="handleChange"
          >
            <el-option
              v-for="item in threads"
              :key="item.id"
              :label="item.value"
              :value="item.value"
            />
          </el-select>
          <deep-chat
            ref="chat-history-ref"
            class="deep-chat-bot"
            :directConnection="directConnection"
            :initialMessages="initialMessages"
            demo="true"
            stream="true"
            gifs='{"button": {"position": "dropup-menu"}}'
            audio='{"button": {"position": "dropup-menu"}}'
            camera='{"button": {"position": "dropup-menu"}}'
            mixed-files='{"button": {"position": "inside-left"}}'
            microphone='{"button": {"position": "outside-right"}}'
            @new-message="handleNewMessage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeepChat from 'deep-chat'
import mixin from './mixin'

export default {
  name: 'ChatHistory',
  components: {
    DeepChat
  },
  mixins: [mixin],
  props: {
    assistant: {
      type: Object,
      default: null
    },
    customerData: {
      type: Array,
      default: () => []
    },
    sessionId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedThreadId: '',
      assistants: ['deepChatRef_1'],
      initialMessages: [],
      index: -1
    }
  }
}
</script>

<style>
.chat-assistant-container {
  display: flex;
  flex-direction: column;
}

.chat-assistants-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  flex-direction: row;
  justify-content: center;
}

.assistant-item {
  margin-bottom: 16px;
}

.threadDropDown {
  margin: 10px 0px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.clone-button {
  display: flex;
  justify-content: center;
}

.deep-chat-bot {
  border-radius: 8px;
  max-width: 100%;
}

.empty-threads-message {
  text-align: center;
  color: red;
}
</style>
