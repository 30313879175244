import { Question } from '@/models/question'
import { TEST_TYPE } from '@/models/test'
export class ReflectionFilters {
  account;

  customerIDs;

  endDate;

  isApplied;

  isApproved;

  questionType;

  reviewModeEnabled;

  selectedCategory;

  selectedEpisode;

  startDate;
  constructor({
    account = '',
    isApproved = null,
    selectedCategory = '',
    selectedEpisode = '',
    customerIDs = [],
    startDate = null,
    endDate = null,
    reviewModeEnabled = false,
    questionType = TEST_TYPE.REFLECTION,
    isApplied = false
  } = {}) {
    this.account = account
    this.isApproved = isApproved
    this.selectedCategory = selectedCategory
    this.selectedEpisode = selectedEpisode
    this.customerIDs = customerIDs
    this.startDate = startDate
    this.endDate = endDate
    this.reviewModeEnabled = reviewModeEnabled
    this.questionType = questionType
    this.isApplied = isApplied
  }
  static get options() {
    return [
      {
        value: null,
        label: 'reflections.all'
      },
      {
        value: true,
        label: 'reflections.approve'
      },
      {
        value: false,
        label: 'reflections.reject'
      }
    ]
  }

  get filters() {
    const baseFilter = Question.getFiltersByQuestionType(this.questionType)
    const reportsFilters = [
      ...(this.startDate && this.endDate
        ? [
          ['DateAdded', 'ge', this.startDate?.toISOString()],
          ['DateAdded', 'le', this.endDate?.toISOString()]
        ]
        : []),
      ...(this.selectedEpisode
        ? [['MediaID', 'eq', this.selectedEpisode]]
        : []),
      ...(this.isApproved !== null
        ? [['IsApproved', 'eq', this.isApproved]]
        : this.reviewModeEnabled && this.isApplied
          ? [['IsApproved', 'ne', this.isApproved]]
          : [])
    ].reduce(
      (filter, element, index) => [
        ...filter,
        ...(index ? ['and'] : []),
        element
      ],
      []
    )
    if (this.reviewModeEnabled) {
      const reviewBaseFilter = [
        ...baseFilter,
        'and',
        [['bIsShared', 'eq', true]]
      ]
      if (reportsFilters.length) {
        return [...reviewBaseFilter, 'and', reportsFilters]
      }
      return [...reviewBaseFilter]
    } else {
      if (reportsFilters.length) {
        return [baseFilter, 'and', reportsFilters]
      }
      return baseFilter
    }
  }

  get hasFilter() {
    const hasValue = [
      this.account,
      this.selectedCategory,
      this.selectedEpisode,
      this.isApproved !== null,
      this.startDate,
      this.endDate,
      this.reviewModeEnabled
    ].some(item => item)
    return hasValue
  }

  reset() {
    this.account = ''
    this.isApproved = null
    this.selectedCategory = ''
    this.clearEpisode()
    this.startDate = null
    this.endDate = null
    this.isApplied = false
  }

  clearEpisode() {
    this.selectedEpisode = ''
  }

  static getIds(items) {
    return items.map(item => item.ID)
  }
}
