<template>
  <ODataTable
    :columns="columns.all"
    :add-edit-columns="columns.addEdit"
    :custom-delete-message="$t('assistants.metaContents.confirmDeleteMessage')"
    :total-label="$t('assistants.metaContents.total')"
    :title="$t('assistants.metaContents.title')"
    :is-static-data-source="true"
    :static-data="metaContents"
    :add-row-button-label="$t('assistants.metaContents.create')"
    :should-show-delete-button="false"
    :should-show-edit-button="false"
  />
</template>
<script>
import ODataTable from '@/components/ODataTable'
import { columns } from './config'
import { AssistantMetaContentsStore } from './store'
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations } = createNamespacedHelpers('assistant')
import { MUTATION_TYPES } from '@/store/mutations'

export default {
  name: 'AssistantMetaContents',
  components: {
    ODataTable

  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      columns
    }
  },
  computed: {
    ...mapState({
      assistantMetaContents: state => state.assistant.metadata.assistantMetaContents
    }),
    metaContents() {
      return new AssistantMetaContentsStore(this.assistantMetaContents)
    }
  },
  created() {
    this.initialise(this.data)
  },
  methods: {
    ...mapMutations({
      initialise: MUTATION_TYPES.ASSISTANT.SET_ASSISTANT
    })
  }
}
</script>

