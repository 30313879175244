import TagService from '@/api/tag'
import { Tag } from '@/models/tag'
import { MUTATION_TYPES as BASE_MUTATION_TYPES } from '../mutations'
import { PaginatedResponse } from '@/models/paginatedResponse'
import MediaReportV2 from '@/api/reportsV2'
import { Message } from 'element-ui'
import { MediaReportFilterFactory } from '@/models/mediaReportFilterFactory'

const MUTATION_TYPES = BASE_MUTATION_TYPES.MEDIA_REPORTS_V2
const state = {
  filters: new MediaReportFilterFactory(),
  tags: [],
  isLoading: false
}
const mutations = {
  [MUTATION_TYPES.SET_TAGS]: (state, tags = []) => {
    state.tags = Tag.fromList(tags)
    state.isLoading = false
  },

  [MUTATION_TYPES.SET_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  },

  [MUTATION_TYPES.SET_CUSTOMER_ID]: (state, payload) => {
    state.filters.updatePartial(payload)
  },

  [MUTATION_TYPES.INITIALISE]: (state, isInverted) => {
    state.filters = new MediaReportFilterFactory(isInverted)
  }
}
const actions = {
  async fetchUsersByTagId({ commit, state }) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      const response = await TagService.fetchUsersByTagId(state.filters.tagId)
      const paginatedResponse = new PaginatedResponse(response)
      while (paginatedResponse.hasMore) {
        await paginatedResponse.next()
      }
      commit(MUTATION_TYPES.SET_CUSTOMER_ID, paginatedResponse.items)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(MUTATION_TYPES.SET_LOADING, false)
    }
  },
  async fetchTagsByAccountAndSubAccount({ commit }, { account, subAccount }) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      const response = await TagService.getByAccountAndSubAccount({
        account,
        subAccount
      })
      commit(MUTATION_TYPES.SET_TAGS, response)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(MUTATION_TYPES.SET_LOADING, false)
    }
  },
  async fetchMediaReport(_, filters) {
    try {
      const response = await MediaReportV2.getAll(filters)
      return Promise.resolve(response.value)
    } catch (error) {
      Message({
        message: error.message,
        type: 'error'
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
