import { Column } from './column'

export class FileParser {
  fileElement;

  constructor(fileElement) {
    this.isHTML = FileParser.isHTML(fileElement)

    if (this.isHTML) {
      const element = document.createElement('div')
      element.innerHTML = fileElement
      this.fileElement = element
    } else {
      this.fileElement = fileElement
    }
  }

  static getCellDelimiter(rows = []) {
    const isSemicolonSeparated = rows.every(row => row.includes(';'))

    const isCommaSeparated = rows.every(row => row.includes(','))
    return isSemicolonSeparated
      ? ';'
      : isCommaSeparated
        ? ','
        : String.fromCharCode(9)
  }

  static isHTML(fileElement = '') {
    return /(<([^>]+)>)/i.test(fileElement)
  }

  static creatColumnsForRow(columns = []) {
    return columns.reduce(
      (column, cell, index) => ({
        ...column,

        [Column.createColumnName(index)]: cell.trim()
      }),
      {}
    )
  }

  static sortByNumberOfColumns(rows) {
    return rows.sort((firstRow, secondRow) => {
      return (
        this.filterNonEmptyColumns(secondRow).length -
        this.filterNonEmptyColumns(firstRow).length
      )
    })
  }

  static filterNonEmptyColumns(row) {
    return Object.values(row).filter(column => column)
  }

  computeColumnsFromRows(rows = []) {
    if (this.isHTML) {
      return FileParser.computeColumnsFromTableRows(rows)
    }

    const delimiter = FileParser.getCellDelimiter(rows)
    return rows.map(row =>
      FileParser.creatColumnsForRow(row.trim().split(delimiter))
    )
  }

  get rowDelimiter() {
    return this.fileElement?.includes('\n') ? '\n' : String.fromCharCode(13)
  }

  splitIntoRows() {
    if (this.isHTML) {
      return this.splitIntoTableRows()
    }

    return this.fileElement
      .replace(new RegExp('\t\t', 'g'), '\t')
      .trim()
      .split(this.rowDelimiter)
  }

  static computeColumnsFromTableRows(rows = []) {
    return rows.map(row => {
      const cells = row.getElementsByTagName('td')

      return this.creatColumnsForRow([...cells].map(cell => cell.innerHTML))
    })
  }

  splitIntoTableRows() {
    return [...this.fileElement.getElementsByTagName('tr')]
  }

  parse() {
    const rows = this.splitIntoRows()
    const rowsWithColumns = this.computeColumnsFromRows(rows)
    return FileParser.sortByNumberOfColumns(rowsWithColumns)
  }
}
