<template>
  <el-main v-loading="isPanelLoading" class="container">
    <ODataTable
      ref="oDataTableComponentRef"
      :key="resource+JSON.stringify(filter)"
      :columns="columns"
      :resource="resource"
      :total-label="$t('invertedMediaReports.total')"
      :file-name="$t('invertedMediaReports.fileName')"
      :title="$t('invertedMediaReports.title')"
      :should-show-add-button="false"
      :should-show-edit-button="false"
      :should-show-delete-button="false"
      :show-data="showData"
      :filter="filter"
      method="post"
      @clearFilters="handleFiltersClear"
    >
      <template #custom-filters>
        <media-reports-filters
          ref="mediaReportFiltersRef"
          @filter="filterMediaReports"
        />
      </template>
    </ODataTable>
  </el-main>
</template>
<script>
import ODataTable from '../../components/ODataTable'
import MediaReportsFilters from '../MediaReportV2/filters.vue'
import { columns, rowKey } from './config'
import { createNamespacedHelpers } from 'vuex'
import { MUTATION_TYPES } from '@/store/mutations'

const {
  mapState: mapMediaReportsState,
  mapMutations: mapMediaReportsMutations
} = createNamespacedHelpers('mediaReportsV2')

export default {
  name: 'InvertedMediaReports',
  components: {
    ODataTable,
    MediaReportsFilters
  },
  data() {
    return {
      showData: false,
      columns,
      rowKey,
      filter: [],
      resource: ''
    }
  },
  computed: {
    ...mapMediaReportsState({
      filters: state => state.filters,
      isTagLoading: state => state.isLoading
    })
  },

  created() {
    this.initialiseFilter(true)
    this.filters.clearFiltersV2()
  },

  methods: {
    ...mapMediaReportsMutations({
      initialiseFilter: MUTATION_TYPES.MEDIA_REPORTS_V2.INITIALISE
    }),
    filterMediaReports(filters) {
      if (!this.showData) {
        this.resource = this.filters.resource()
        this.filter = filters
        this.showData = true
        return null
      }
      this.resource = this.filters.resource()
      this.filter = filters
    },

    handleFiltersClear() {
      this.showData = true
    }
  }
}
</script>
<style lang="scss">
.el-message__closeBtn {
  color: #999;
}

.el-message__icon {
  display: none;
}
</style>
