import store from '@/store'
import { Deferred } from 'devextreme/core/utils/deferred'
import ArrayStore from 'devextreme/data/array_store'

export class AssistantMetaContentsStore extends ArrayStore {
  constructor(data) {
    super({
      key: 'index',
      data
    })
  }

  insert(tool) {
    store.state.assistant.assistant.metadata.add(tool)
    const promise = store.dispatch('assistants/update')

    return this.toPromise(promise, () =>
      store.state.assistant.assistant.metadata.removeFirst()
    )
  }

  update(id, values) {
    const prevMetadata = store.state.assistant.assistant.metadata.findByIndex(
      id
    )
    store.state.assistant.assistant.metadata.update(id, values)

    const promise = store.dispatch('assistants/update')

    return this.toPromise(promise, () =>
      store.state.assistant.assistant.metadata.update(id, prevMetadata)
    )
  }

  remove(id) {
    const prevMetadata = store.state.assistant.assistant.metadata.findByIndex(
      id
    )

    store.state.assistant.assistant.metadata.remove(id)

    const promise = store.dispatch('assistants/update')

    return this.toPromise(promise, () =>
      store.state.assistant.assistant.metadata.addAtIndex(id, prevMetadata)
    )
  }

  toPromise(promise, onError) {
    const deffered = Deferred()
    promise
      .then(item => deffered.resolve(item))
      .catch(error => {
        deffered.reject(error.response?.data?.error?.message)
        setTimeout(onError, 3000)
      })

    return deffered.promise()
  }
}
