<template>
  <div>
    <ResizableModal
      :is-dialog-open="isDialogOpen"
      @updateIsDialogOpen="handleUpdateIsDialogOpen"
    >
      <template v-slot:ChatAssistantDefaults="slotprops">
        <ChatAssistantDefaults v-if="isDialogOpen" :width="slotprops.width" :height="slotprops.height" :assistant-default="assistantDefault" />
      </template>
    </ResizableModal>
    <ODataTable
      ref="oDataTableComponentRef"
      :key="filter"
      :columns="columns.all"
      :add-edit-columns="columns.addEdit"
      resource="AssistantDefaults"
      :action-buttons-width="120"
      :total-label="$t('assistantDefaults.total')"
      :file-name="$t('assistantDefaults.fileName')"
      :add-row-button-label="$t('assistantDefaults.add')"
      :title="$t('assistantDefaults.title')"
      :filter="filter"
      :should-show-add-button="!filter.length"
      @rowInit="handleRowInit"
      @editorPreparing="handleEditorPreparing"
      @rowInserted="handleFormClose"
      @editingStart="handleEditingStart"
      @editingCancelling="handleFormClose"
    >
      <template #custom-buttons>
        <DxButton icon="copy" :on-click="handleClone" />
        <DxButton icon="comment" :on-click="handleChat" />
      </template>
    </ODataTable>
  </div>
</template>

<script>
import ODataTable from '../../components/ODataTable'
import { createColumns, disabledColumns, categoryField } from './config'
import { createNamespacedHelpers } from 'vuex'
import { DxButton } from 'devextreme-vue/data-grid'
import { confirm } from 'devextreme/ui/dialog'
import ChatAssistantDefaults from '../../components/ChatAssistantDefaults'
import ResizableModal from '../../components/ResizableModal'
const { mapState: mapAppState, mapActions: mapAppActions } = createNamespacedHelpers('app')
const { mapActions: mapAssistantDefaultsActions } = createNamespacedHelpers('assistantDefaults')
export default {
  name: 'AssistantDefaults',
  components: {
    DxButton,
    ODataTable,
    ChatAssistantDefaults,
    ResizableModal
  },
  data() {
    return {
      isDialogOpen: false,
      disabledColumns,
      assistantDefault: {},
      selectedCategoryId: null,
      mediaIdsForSelectedCategory: [],
      medias: [],
      assistantDefaultId: undefined,
      filter: []
    }
  },
  computed: {
    ...mapAppState({
      allMedias: state => state.medias.map(media => media.option),
      categories: (state) => state.categories.map(category => category.option),
      selectedMediaIds: state => state.selectedMediaIds
    }),
    columns() {
      return createColumns({
        categories: this.categories,
        medias: this.medias
      })
    }
  },
  async mounted() {
    this?.$refs?.oDataTableComponentRef?.$refs?.oDataTableRef?.instance?.beginCustomLoading()
    this.assistantDefaultId = this.$router.currentRoute?.query?.id
    if (this.assistantDefaultId !== undefined) {
      this.filter = ['ID', '=', this.assistantDefaultId]
    }
    await Promise.all([this.fetchCategories(), this.fetchMedias()])
    this.medias = this.allMedias
    this?.$refs?.oDataTableComponentRef?.$refs?.oDataTableRef?.instance?.endCustomLoading()
  },
  methods: {
    ...mapAssistantDefaultsActions(['clone']),
    ...mapAppActions(['fetchMediasByCategoryId', 'fetchMedias', 'fetchCategories']),
    async handleClone(event) {
      const result = await confirm(this.$t('cloneRow'))
      if (result) {
        try {
          this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
          await this.clone(event.row.data)
          await this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.refresh()
        } finally {
          this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
        }
      }
    },
    async handleEditorPreparing(event) {
      this.medias = this.allMedias
      if (event.isOnForm) {
        event.editorOptions.disabled = disabledColumns.includes(event.dataField)
        if (event.dataField === categoryField) {
          event.editorOptions.onValueChanged = (e) => {
            event.setValue(e.value)
            this.handleCategorySelect(e.value)
          }
        }
      }
    },
    handleChat(event) {
      this.assistantDefault = event.row.data
      this.isDialogOpen = true
    },
    handleDialogClose() {
      this.isDialogOpen = false
    },
    handleFormClose() {
      this.medias = this.allMedias
    },
    handleEditingStart(event) {
      if (event.data.CategoryID) {
        this.handleCategorySelect(event.data.CategoryID)
      }
    },
    async handleCategorySelect(categoryId) {
      this.mediaIdsForSelectedCategory = await this.fetchMediasByCategoryId(categoryId)
      this.medias = this.allMedias.filter(media => this.mediaIdsForSelectedCategory.includes(media.value))
      this.selectedCategoryId = categoryId
    },
    handleRowInit(event) {
      event.data.DateAdded = new Date().toISOString()
      this.medias = this.allMedias
    },
    handleUpdateIsDialogOpen(value) {
      this.isDialogOpen = value
    }
  }
}
</script>
