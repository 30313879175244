<template>
  <el-container>
    <el-main class="dx-viewport">
      <DxTabPanel v-if="detail">
        <DxItem :title="$t('profile')" template="profile-tab" />
        <template #profile-tab>
          <MasterDetail :data="detail" :items="profileItems">
            <template #custom-item="{ item }">
              <div class="date-container">
                {{ formatDate(item.editorOptions.value) }}
              </div>
            </template>
          </MasterDetail>
        </template>
        <DxItem :title="$t('organisation')" template="address-tab" />
        <template #address-tab>
          <MasterDetail :data="detail" :items="addressItems" />
        </template>
        <DxItem :title="$t('tags.title')" template="tags-tab" />
        <template #tags-tab>
          <MasterDetail :data="detail" :items="tagItems">
            <template #custom-item="{ item }">
              <div class="tag-container">
                <div v-for="(tag, index) in item.editorOptions.value" :key="index" class="tag">
                  {{ tag }}
                </div>
              </div>
            </template>
          </MasterDetail>
        </template>
      </DxTabPanel>
    </el-main>
  </el-container>
</template>

<script>
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel'
import MasterDetail from '../../../components/masterDetail'
import { DateUtils } from '@/utils/date'
import { dateColumns } from '../config'

export default {
  name: 'Detail',
  components: { DxTabPanel, DxItem, MasterDetail },
  props: {
    detail: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      profileItems: [
        'Company',
        'Name',
        'EMail',
        'AltEmail',
        'MobileNo',
        'UserName',
        'Reference',
        'SiteID',
        'AddedDate',
        'LastUpdate',
        'LastLoginDate',
        'bAccessAll',
        'NoDeactivation',
        'Account',
        'SubAccount'
      ].map((dataField) => ({
        dataField,
        ...(dateColumns.includes(dataField) && { template: 'custom-item' }),
        ...(dataField === 'SiteID' && {
          template: 'lookup-value',
          label: {
            text: this.$t('courseCampaign.portal')
          },
          editorOptions: {
            dataSource: this.detail.sites,
            displayExpr: 'Name',
            valueExpr: 'ID'
          }
        }),
        ...(dataField === 'Name' && { label: { text: this.$t('name') }})
      })),
      addressItems: ['Address', 'Postal_Address', 'Postal_Code'].map((dataField) => ({ dataField })),
      tagItems: ['assignedTags'].map((dataField) => ({
        dataField,
        template: 'custom-item'
      }))
    }
  },
  methods: {
    formatDate(date) {
      return DateUtils.formatToShortDateTime(date)
    }
  }
}
</script>

<style lang="scss">
$base-length: 10px;
$base-breadth: 20px;
$base-spacing: 5px;

.tag-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: $base-spacing * 3;
}

.tag {
  margin-top: $base-spacing;
  padding: 0 $base-spacing * 5;
  height: $base-length * 5;
  width: $base-breadth * 10;
  font-size: 1em;
  line-height: $base-length * 5;
  border-radius: $base-length * 3;
  background-color: #f1f1f1;
  text-align: center;
}
</style>
