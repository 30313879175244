import ContentPartService from '@/api/contentPart'
import { MUTATION_TYPES as BASE_MUTATION_TYPES } from '@/store/mutations'
import { Message } from 'element-ui'
import { Site } from '@/models/site'
import SiteService from '@/api/site'
import { PaginatedResponse } from '@/models/paginatedResponse'

const MUTATION_TYPES = BASE_MUTATION_TYPES.SHARED.SITE

const initialState = () => ({
  sites: []
})

const mutations = {
  [MUTATION_TYPES.SET_SITES]: (state, sites) => {
    state.sites = sites
  }
}

const actions = {
  async fetchAll({ commit }) {
    try {
      const response = await ContentPartService.fetchContentPartSites()
      const sites = Site.fromList(response)
      commit(MUTATION_TYPES.SET_SITES, sites)
      return Promise.resolve()
    } catch (error) {
      Message({
        type: 'error',
        message: error.message
      })
      return Promise.reject()
    }
  },

  async fetchGridData() {
    try {
      const response = await SiteService.getAll()
      const paginatedResponse = new PaginatedResponse(response)
      while (paginatedResponse.hasMore) {
        await paginatedResponse.next()
      }
      return Promise.resolve(paginatedResponse.items)
    } catch (error) {
      Message({
        type: 'error',
        message: error.message
      })
      return Promise.reject()
    }
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions
}
