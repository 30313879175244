<template>
  <div>
    <AttributeSelection :selected-users="allUserData" />
    <ODataTable
      ref="sendListRef"
      :columns="columns"
      :add-edit-columns="addEditColumns"
      :should-show-delete-button="false"
      :total-label="$t('updateSendList.total')"
      :file-name="$t('updateSendList.fileName')"
      :should-show-add-button="false"
      :is-static-data-source="true"
      :static-data="staticData"
      @selectionChange="handleSelectionChange"
      @rowSaving="handlesaving"
    />
  </div>
</template>

<script>
import ODataTable from '@/components/ODataTable'
import { columns, addEditColumns } from './config.js'
import { createNamespacedHelpers } from 'vuex'
import { ROUTE_PATH } from '@/router'
import AttributeSelection from '@/components/attributeSelection'
import CustomerService from '@/api/customer'
import { MUTATION_TYPES } from '@/store/mutations'
const { mapState: mapAppState, mapMutations: mapAppMutations } = createNamespacedHelpers('app')
export default {
  name: 'UpdateSendList',
  components: {
    ODataTable,
    AttributeSelection
  },
  data() {
    return {
      columns,
      addEditColumns,
      listId: '',
      allUserData: [],
      selectAllRowsFlag: true
    }
  },
  computed: {

    ...mapAppState({
      selectedCustomers: (state) => state.selectedCustomers
    }),
    staticData() {
      return this.selectedCustomers.customers
    }
  },

  created() {
    if (!this.selectedCustomers.customers.length) {
      this.$router.push(ROUTE_PATH.CUSTOMERS)
    }
  },
  mounted() {
     this.$refs?.sendListRef?.$refs?.oDataTableRef?.instance?.beginCustomLoading()
     this.handleSelectAll()
     this.$refs?.sendListRef?.$refs?.oDataTableRef?.instance?.endCustomLoading()
  },
  methods: {
    ...mapAppMutations({
      updateCustomers: MUTATION_TYPES.APP.UPDATE_CUSTOMERS
    }),

    handleSelectAll() {
      setTimeout(() => {
        this.$refs.sendListRef.$refs.oDataTableRef.instance.selectAll()
      }, 2000)
    },

    handleRefresh() {
      this.$refs.sendListRef.$refs.oDataTableRef.instance.refresh()
    },

    handleSelectionChange({ selectedRowsData }) {
      if (selectedRowsData) this.allUserData = selectedRowsData
    },

    async handlesaving(event) {
      this.$refs?.sendListRef?.$refs?.oDataTableRef?.instance?.beginCustomLoading()
      const { data, key } = event.changes[0]
      await CustomerService.updateCustomerByID({ updatedCustomerData: data, ID: key.ID })
      this.updateCustomers({ ID: key.ID, data })
      this.handleRefresh()
      this.$refs?.sendListRef?.$refs?.oDataTableRef?.instance?.endCustomLoading()
    }
  }
}
</script>

<style>
.el-header {
  margin: 10px;
}

.el-main.fields-section {
  margin-top: 20px;
  padding-bottom: 0 !important;
}

.app-container {
  padding-top: 0 !important;
}
</style>
