export const models = [
  {
    label: 'gpt-3.5-turbo-1106',
    value: 'gpt-3.5-turbo-1106'
  },
  {
    label: 'gpt-4-0613',
    value: 'gpt-4-0613'
  },
  {
    label: 'gpt-4-32k-0613',
    value: 'gpt-4-32k-0613'
  },
  {
    label: 'gpt-4-0314',
    value: 'gpt-4-0314'
  },
  {
    label: 'gpt-4-32k-0314',
    value: 'gpt-4-32k-0314'
  },
  {
    label: 'gpt-4-1106-preview',
    value: 'gpt-4-1106-preview'
  },
  {
    label: 'gpt-4-vision-preview',
    value: 'gpt-4-vision-preview'
  },
  {
    label: 'gpt-4',
    value: 'gpt-4'
  },
  {
    label: 'gpt-4-32k',
    value: 'gpt-4-32k'
  },
  {
    label: 'gpt-4-0613',
    value: 'gpt-4-0613'
  },
  {
    label: 'gpt-4-32k-0613',
    value: 'gpt-4-32k-0613'
  },
  {
    label: 'gpt-3.5-turbo-1106',
    value: 'gpt-3.5-turbo-1106'
  },
  {
    label: 'gpt-3.5-turbo',
    value: 'gpt-3.5-turbo'
  },
  {
    label: 'gpt-3.5-turbo-16k',
    value: 'gpt-3.5-turbo-16k'
  },
  {
    label: 'gpt-3.5-turbo-instruct',
    value: 'gpt-3.5-turbo-instruct'
  },
  {
    label: 'gpt-3.5-turbo-0613',
    value: 'gpt-3.5-turbo-0613'
  },
  {
    label: 'gpt-3.5-turbo-16k-0613',
    value: 'gpt-3.5-turbo-16k-0613'
  },
  {
    label: 'gpt-3.5-turbo-0301',
    value: 'gpt-3.5-turbo-0301'
  },
  {
    label: 'text-davinci-003',
    value: 'text-davinci-003'
  },
  {
    label: 'text-davinci-002',
    value: 'text-davinci-002'
  },
  {
    label: 'code-davinci-002',
    value: 'code-davinci-002'
  },
  {
    label: 'dall-e-3',
    value: 'dall-e-3'
  },
  {
    label: 'dall-e-2',
    value: 'dall-e-2'
  },
  {
    label: 'tts-1',
    value: 'tts-1'
  },
  {
    label: 'tts-1-hd',
    value: 'tts-1-hd'
  },
  {
    label: 'text-moderation-latest',
    value: 'text-moderation-latest'
  },
  {
    label: 'text-moderation-stable',
    value: 'text-moderation-stable'
  },
  {
    label: 'babbage-002',
    value: 'babbage-002'
  },
  {
    label: 'davinci-002',
    value: 'davinci-002'
  },
  {
    label: 'text-curie-001',
    value: 'text-curie-001'
  },
  {
    label: 'text-babbage-001',
    value: 'text-babbage-001'
  },
  {
    label: 'text-ada-001',
    value: 'text-ada-001'
  },
  {
    label: 'davinci',
    value: 'davinci'
  },
  {
    label: 'curie',
    value: 'curie'
  },
  {
    label: 'babbage',
    value: 'babbage'
  },
  {
    label: 'ada',
    value: 'ada'
  }
]

export const TOOL_TYPES = [
  {
    label: 'Code Interpreter',
    value: 'code_interpreter'
  },
  {
    label: 'Retrieval',
    value: 'retrieval'
  },
  {
    label: 'Function',
    value: 'function'
  }
]

export const defaultToolsData = [{ type: 'function', properties: {}}]

export const defaultMetaDataValue = {
  key1: 'value1',
  key2: 'value2',
  key3: 'value3',
  key4: 'value4'
}
