import UpdateSendListService from '@/api/updateSendList'
import { DateUtils } from '@/utils/date'

export const disabledColumns = ['AddedDate']

export const getContactsData = listID =>
  UpdateSendListService.getContactsByList(listID)

export const createColumns = () => {
  const addEdit = [
    {
      field: 'email',
      width: 100,
      label: 'EMAIL'
    },
    {
      field: 'emailBlacklisted',
      width: 100,
      label: 'EMAILBLACKLISTED',
      dataType: 'boolean',
      alignment: 'center'
    },
    {
      field: 'smsBlacklisted',
      width: 100,
      label: 'SMSBLACKLISTED',
      dataType: 'boolean',
      alignment: 'center'
    },
    {
      field: 'createdAt',
      width: 100,
      label: 'CREATEDAT',
      dataType: 'date',
      format: DateUtils.odataDateFormat
    },
    {
      field: 'modifiedAt',
      width: 100,
      label: 'MODIFIEDAT',
      dataType: 'date',
      format: DateUtils.odataDateFormat
    },
    {
      field: 'listUnsubscribed',
      width: 100,
      label: 'LISTUNSUBCRIBED'
    },
    {
      field: 'attributes.LASTUPDATE',
      width: 100,
      label: 'LASTUPDATE'
    },
    {
      field: 'attributes.FIRSTNAME',
      width: 100,
      label: 'FIRSTNAME'
    },
    {
      field: 'attributes.ACCOUNT',
      width: 100,
      label: 'ACCOUNT'
    },
    {
      field: 'attributes.SUBACCOUNT',
      width: 100,
      label: 'SUBACCOUNT'
    },
    {
      field: 'attributes.LASTLOGINDATE',
      width: 100,
      label: 'LASTLOGINDATE'
    },
    {
      field: 'attributes.EXT_ID',
      width: 100,
      label: 'EXT_ID'
    },
    {
      field: 'attributes.CUSTOMERS_ADDEDDATE',
      width: 100,
      label: 'CUSTOMERS_ADDEDDATE'
    },
    {
      field: 'attributes.LASTNAME',
      width: 100,
      label: 'LASTNAME'
    },
    {
      field: 'attributes.BEFATTNING',
      width: 100,
      label: 'BEFATTNING'
    },
    {
      field: 'attributes.PASSWORD',
      width: 100,
      label: 'PASSWORD'
    },
    {
      field: 'attributes.UTSKICK',
      width: 100,
      label: 'UTSKICK'
    },
    {
      field: 'attributes.UTSKICK2',
      width: 100,
      label: 'UTSKICK2'
    },
    {
      field: 'attributes.SIGNATUR',
      width: 100,
      label: 'SIGNATUR'
    },
    {
      field: 'attributes.SHORTSIGNATUR',
      width: 100,
      label: 'SHORTSIGNATUR'
    },
    {
      field: 'attributes.LOGO',
      width: 100,
      label: 'LOGO'
    },
    {
      field: 'attributes.SITE',
      width: 100,
      label: 'SITE'
    },
    {
      field: 'attributes.UID',
      width: 100,
      label: 'UID'
    },
    {
      field: 'attributes.KUND',
      width: 100,
      label: 'KUND'
    },
    {
      field: 'attributes.SSO',
      width: 100,
      label: 'SSO',
      dataType: 'boolean',
      alignment: 'center'
    }
  ]
  return {
    addEdit,
    all: [
      {
        field: 'id',
        width: '100%'
      },
      ...addEdit
    ]
  }
}
