import TagService from '@/api/tag'
import { Tag } from '@/models/tag'
import { MUTATION_TYPES as BASE_MUTATION_TYPES } from '../mutations'
import { MediaReportsFilters } from '@/models/mediaReportsFilters'
import { PaginatedResponse } from '@/models/paginatedResponse'

const MUTATION_TYPES = BASE_MUTATION_TYPES.MEDIA_REPORTS
const state = {
  filters: new MediaReportsFilters(),
  tags: [],
  isLoading: false
}
const mutations = {
  [MUTATION_TYPES.SET_TAGS]: (state, tags = []) => {
    state.tags = Tag.fromList(tags)
    state.isLoading = false
  },

  [MUTATION_TYPES.SET_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  },

  [MUTATION_TYPES.SET_CUSTOMER_ID]: (state, payload) => {
    state.filters.updatePartial(payload)
  }
}
const actions = {
  async fetchUsersTagId({ commit, state }) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      const response = await TagService.fetchUsersByTagId(
        state.filters.tagId ||
          state.filtersV2.tagId ||
          state.invertedFiltersV2.tagId
      )
      const paginatedResponse = new PaginatedResponse(response)
      while (paginatedResponse.hasMore) {
        await paginatedResponse.next()
      }
      commit(MUTATION_TYPES.SET_CUSTOMER_ID, paginatedResponse.items)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(MUTATION_TYPES.SET_LOADING, false)
    }
  },
  async fetchTagsByAccountAndSubAccount({ commit }, { account, subAccount }) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      const response = await TagService.getByAccountAndSubAccount({
        account,
        subAccount
      })
      commit(MUTATION_TYPES.SET_TAGS, response)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(MUTATION_TYPES.SET_LOADING, false)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
