var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            [
              _c(
                "el-row",
                { attrs: { type: "flex" } },
                [
                  _c("el-col", { staticClass: "left-align" }, [
                    _c("div", { staticClass: "heading" }, [
                      _vm._v(
                        " " +
                          _vm._s("" + _vm.$t(_vm.testSection + ".title")) +
                          " "
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-main",
            { staticClass: "dx-viewport" },
            [
              _c("add-edit-questions-and-their-answers", {
                attrs: { "is-editing": _vm.isEditing },
                on: { nextClick: _vm.goToNextStep }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }