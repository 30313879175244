var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ODataTable", {
    ref: "oDataTableComponentRef",
    attrs: {
      columns: _vm.columns,
      "is-static-data-source": true,
      "static-data": _vm.mediaReports,
      "total-label": _vm.$t("mediaReports.total"),
      "file-name": _vm.$t("invertedMediaReports.fileName"),
      title: _vm.$t("invertedMediaReports.title"),
      "should-show-add-button": false,
      "should-show-edit-button": false,
      "should-show-delete-button": false
    },
    scopedSlots: _vm._u([
      {
        key: "custom-filters",
        fn: function() {
          return [
            _c("inverted-media-reports-filters", { on: { filter: _vm.filter } })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }