import { DateUtils } from '@/utils/date'

export const disabledColumns = ['CreatedAtDate']

export const createColumns = () => {
  const addEdit = [
    {
      field: 'CampaignName',
      minWidth: 100,
      width: 150
    },
    {
      field: 'Subject',
      minWidth: 100,
      width: 150
    },
    {
      field: 'PreviewText',
      minWidth: 100,
      width: 150
    },
    {
      field: 'Introduction',
      width: 150,
      minWidth: 100
    },
    {
      field: 'CreatedByAdminID',
      width: 150,
      minWidth: 100,
      dataType: 'number'
    },
    {
      field: disabledColumns[0],
      width: 150,
      minWidth: 100,
      dataType: 'date',
      alignment: 'center',
      format: DateUtils.odataDateFormat
    }
  ]
  return {
    addEdit,
    all: [
      {
        field: 'ID',
        minWidth: 100,
        width: 150
      },
      ...addEdit
    ]
  }
}
