export class Option {
  constructor(option = {}) {
    Object.assign(this, option)
  }

  get format() {
    return {
      label: this.label,
      value: this.value
    }
  }

  static fromList(options = []) {
    return options.map(option => new Option(option))
  }
}
