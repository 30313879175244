export const createColumns = ({ sites }) => {
  const dropDownColumns = [
    {
      field: 'SiteID',
      width: 110,
      lookup: sites,
      required: true,
      requiredMessage: 'categories.column.siteId.requiredMessage'
    }
  ]

  const nameColumns = [
    {
      field: 'SubCategoryName',
      label: 'subCategories.column.name.label',
      width: 550,
      required: true,
      requiredMessage: 'subCategories.column.name.requiredMessage'
    }
  ]

  const addEditColumns = ['SortOrder', 'IsActive'].map(field => ({
    field,
    width: 110
  }))

  const addEdit = [...dropDownColumns, ...nameColumns, ...addEditColumns]

  return {
    addEdit,
    all: [
      { field: 'ID', width: 110 },
      { field: 'CategoriesID', width: 110, visible: false },
      ...addEdit
    ]
  }
}
