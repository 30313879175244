var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    { staticClass: "container" },
    [
      _c(
        "el-row",
        { attrs: { type: "flex", gutter: 40 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 6 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: _vm.$t("reflections.account")
                  },
                  model: {
                    value: _vm.filters.account,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "account", $$v)
                    },
                    expression: "filters.account"
                  }
                },
                _vm._l(_vm.accounts, function(item) {
                  return _c("el-option", {
                    key: item.ID,
                    attrs: {
                      label: item.option.title,
                      value: item.option.value
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 6 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: _vm.$t("select") + " " + _vm.$t("category")
                  },
                  model: {
                    value: _vm.filters.selectedCategory,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "selectedCategory", $$v)
                    },
                    expression: "filters.selectedCategory"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "All", value: "" } }),
                  _vm._l(_vm.categories, function(category) {
                    return _c("el-option", {
                      key: category.ID,
                      attrs: { label: category.Name, value: category.ID }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 6 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: _vm.$t("select") + " " + _vm.$t("media")
                  },
                  model: {
                    value: _vm.filters.selectedEpisode,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "selectedEpisode", $$v)
                    },
                    expression: "filters.selectedEpisode"
                  }
                },
                [
                  _vm.episodes.length
                    ? _c("el-option", { attrs: { label: "All", value: "" } })
                    : _vm._e(),
                  _vm._l(_vm.episodes, function(episode) {
                    return _c("el-option", {
                      key: episode.ID,
                      attrs: { label: episode.Title, value: episode.ID }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 6 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder:
                      _vm.$t("reflections.approve") +
                      " / " +
                      _vm.$t("reflections.reject")
                  },
                  model: {
                    value: _vm.filters.isApproved,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "isApproved", $$v)
                    },
                    expression: "filters.isApproved"
                  }
                },
                _vm._l(_vm.options, function(option) {
                  return _c("el-option", {
                    key: option.value,
                    attrs: { label: _vm.$t(option.label), value: option.value }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { type: "flex", gutter: 40 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 6 } },
            [
              _c("el-date-picker", {
                attrs: {
                  placeholder: "" + _vm.$t("mediaReports.startDatePlaceholder"),
                  format: _vm.dateFormat
                },
                model: {
                  value: _vm.filters.startDate,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "startDate", $$v)
                  },
                  expression: "filters.startDate"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 6 } },
            [
              _c("el-date-picker", {
                attrs: {
                  placeholder: "" + _vm.$t("mediaReports.endDatePlaceholder"),
                  "picker-options": _vm.endDatePickerOptions,
                  format: _vm.dateFormat
                },
                model: {
                  value: _vm.filters.endDate,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "endDate", $$v)
                  },
                  expression: "filters.endDate"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { type: "flex", gutter: 40, justify: "end" } },
        [
          _c(
            "el-col",
            { staticClass: "flex-end", attrs: { sm: 6 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: !_vm.filters.hasFilter },
                  on: { click: _vm.handlerReportsFilter }
                },
                [_vm._v(_vm._s(_vm.$t("reflections.apply")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", disabled: !_vm.filters.hasFilter },
                  on: { click: _vm.handleClearFilters }
                },
                [_vm._v(_vm._s(_vm.$t("reflections.clear")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }