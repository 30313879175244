var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ODataTable", {
    attrs: {
      columns: _vm.columns.all,
      "add-edit-columns": _vm.columns.addEdit,
      "custom-delete-message": _vm.$t(
        "assistants.metaContents.confirmDeleteMessage"
      ),
      "total-label": _vm.$t("assistants.metaContents.total"),
      title: _vm.$t("assistants.metaContents.title"),
      "is-static-data-source": true,
      "static-data": _vm.metaContents,
      "add-row-button-label": _vm.$t("assistants.metaContents.create"),
      "should-show-delete-button": false,
      "should-show-edit-button": false
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }