<template>
  <div v-loading="isLoading">
    <div class="panel__top">
      <div class="panel__basic-actions" />
    </div>
    <div id="main-container" />
    <div>
      <div id="blocks-wrap">
        <div v-if="showBlocks" class="filters-container">
          <div class="builder-filters-container">
            <h2>{{ $t("contentBlocks") }}</h2>
            <div class="builder-filters">
              <builder-filters />
            </div>
          </div>
          <div>
            <builder-filter-tags />
          </div>
          <div>
            <el-input
              v-model="nameSearch"
              class="search"
              size="mini"
              suffix-icon="el-icon-search"
              :placeholder="$t('search')"
              @input="setFilters({ nameSearch: $event })"
            />
          </div>
        </div>
        <div v-if="showBlocks">
          <div v-for="contentPartForBuilder in contentPartListForBuilder" :key="contentPartForBuilder.ID">
            <builder-content-tiles
              v-if="blockToShow(contentPartForBuilder)"
              :data="contentPartForBuilder"
              draggable
              @dragstart="onDragStart(contentPartForBuilder)"
              @dragend="onDragStop"
            />
          </div>
        </div>
        <div v-if="!contentPartListForBuilder.length && showBlocks" class="center">
          {{ $t("errors.noContentBlocks") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import grapesjs from 'grapesjs'
import { grapesJsConfig } from './config'
import { mapGetters } from 'vuex'
import { createNamespacedHelpers } from 'vuex'
import BuilderFilters from '@/components/BuilderFilters'
import BuilderFilterTags from '@/components/BuilderFilterTags'
import BuilderContentTiles from '@/components/BuilderContentTiles'
import { ContentPartX } from '@/models/contentPartX'
import ContentPartService from '../../api/contentPart'
const { mapActions: mapContentPartActions } =
  createNamespacedHelpers('contentPart')
const { mapActions: mapContentPartBuilderActions } =
  createNamespacedHelpers('contentPartBuilder')
const { mapState: mapAppState } =
  createNamespacedHelpers('app')
export default {
  name: 'PageBuilder',
  components: {
    BuilderFilters,
    BuilderFilterTags,
    BuilderContentTiles
  },
  data() {
    return {
      nameSearch: '',
      showBlocks: false,
      editor: null,
      isLoading: false,
      droppedBlockIndex: null,
      contentPartToLoad: null
    }
  },
  computed: {
    ...mapGetters(['contentPartListForBuilder']),
    ...mapAppState({
      siteId: state => state.siteID
    })
  },
  watch: {
    contentPartListForBuilder(contentParts) {
      this.isLoading = true
      this.editor.DomComponents.clear()
      this.contentPartToLoad = contentParts?.find(contentPart => contentPart.SortOrder === 1)
      this.editor.addComponents({ classes: ['gjs-dashed'], content: this.contentPartToLoad.MainContent, attributes: { contenteditable: true }})
      this.isLoading = false
    }
  },
  async created() {
    this.isLoading = true
    try {
      await this.setContentPart()
    } finally {
      this.isLoading = false
    }
  },
  mounted() {
    this.editor = grapesjs.init(grapesJsConfig)
    this.editor.DomComponents.clear()
    this.editor.on('block:custom', this.handleBlocks)
    this.editor.on('sorter:drag:end', this.onDragStop)
    this.editor.Panels.addPanel({
      el: '.panel__top'
    })
    this.editor.Panels.addPanel({
      id: 'basic-actions',
      el: '.panel__basic-actions',
      buttons: [
        {
          id: 'save',
          className: 'el-button header-button new-tab el-button--success el-button--small',
          label: this.$t('save'),
          command: this.handleSave
        }
      ]
    })
  },
  destroyed() {
    this.editor.off('block:custom', this.handleBlocks)
  },
  methods: {
    ...mapContentPartActions(['setContentPart']),
    ...mapContentPartBuilderActions(['setFilters']),
    handleBlocks(props) {
      this.showBlocks = true
      this.dragStart = props.dragStart
      this.dragStop = props.dragStop
      const container = props.container
      const node = document.getElementById('blocks-wrap')
      container && container.appendChild(node)
    },
    onDragStart(block) {
      this.dragStart(block)
    },
    async onDragStop({ modelToDrop, validResult }) {
      const targetId = validResult.trg.id
      const targetElement = validResult.trg.parentElement.children
      if (targetId === 'iyk1') {
        this.droppedBlockIndex = ContentPartX.filterNotNullElements([...targetElement[1].children]).length
      } else {
        this.droppedBlockIndex = ContentPartX.findIndexById(ContentPartX.filterNotNullElements([...targetElement]), targetId)
      }
      this.editor.addComponents({ classes: ['gjs-dashed'], content: modelToDrop.MainContent, attributes: { contenteditable: true }}, { at: this.droppedBlockIndex })
    },
    blockToShow(contentPart) {
      return contentPart.SortOrder !== 1
    },
    async handleSave() {
      this.isLoading = true
      try {
        const mainContent = this.editor.Canvas.getBody().querySelector('div[data-gjs-highlightable="true"]').outerHTML
        await ContentPartService.updateContentPart({ ...this.contentPartToLoad, MainContent: mainContent })
      } catch (error) {
        this.$message({ type: 'error', message: error.message })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .gjs-one-bg {
  background-color: white !important;
  color: black !important;
}
::v-deep .gjs-four-color {
  color: #007BFF !important;
}
.filters-container {
  padding: 5px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1000;
}
.tags {
  position: relative;
  left: 0;
}
.builder-filters-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.builder-filters {
  margin-top: 12.5px;
}
::v-deep #main-container {
  max-height: calc(100vh - 50px) !important;
}
::v-deep .panel__top {
  padding: 0;
  margin-left: 300px;
  margin-top: 2.5px;
  width: 100%;
  flex-direction: row;
  display: flex;
  position: initial;
  justify-content: center;
}
::v-deep .panel__top,
.gjs-pn-panel,
.gjs-pn-panel-top,
.gjs-one-bg,
.gjs-two-color {
  z-index: 10 !important;
  padding-bottom: 0.5px !important;
}
::v-deep .center {
  height: 500px !important;
  text-align: center;
  display: flex;
  justify-content: center;
}
</style>
