import {
  fectchResource,
  createResource,
  updateResource,
  deleteResource
} from '@/utils/httpRequests'

const baseURLPage = `${process.env.VUE_APP_CONTENTPART_API}/content/contentpartpage`
const baseURLBlock = `${process.env.VUE_APP_CONTENTPART_API}/content/contentpartx`

class PageService {
  static fetchPages = (
    options = { SiteID: 1, Tag: null, Name: null, Position: null }
  ) =>
    fectchResource(
      `${baseURLPage}/${options.SiteID}/${options.Tag}/${options.Name}/${options.Position}`
    );

  static fetchPage = contentPartPageID =>
    fectchResource(`${baseURLPage}/${contentPartPageID}`);

  static createPage = data => createResource({ url: baseURLPage, data });

  static updatePage = data => updateResource({ url: baseURLPage, data });

  static deletePage = contentPartPageID =>
    deleteResource(`${baseURLPage}?contentPartPageID=${contentPartPageID}`);

  static fetchBlocksForPage = contentPartPageID =>
    fectchResource(`${baseURLBlock}page/${contentPartPageID}`);

  static fetchBlockContentsForPage = (contentPartPageID, SiteID = 1) =>
    fectchResource(`${baseURLPage}parts/${contentPartPageID}/${SiteID}`);

  static fetchBlock = contentPartBlockID =>
    fectchResource(`${baseURLBlock}/${contentPartBlockID}`);

  static createBlocks = data => createResource({ url: baseURLBlock, data });

  static updateBlocks = data => updateResource({ url: baseURLBlock, data });

  static deleteBlock = contentPartBlockID =>
    deleteResource(`${baseURLBlock}?contentpartpagexid=${contentPartBlockID}`);
}

export default PageService
