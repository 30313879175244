import {
  createResource,
  fectchResource,
  deleteResource
} from '@/utils/httpRequests'

export default class AssistantService {
  static create(data) {
    return createResource({
      url: `${process.env.VUE_APP_CHAT_FORM_URL}/assistants`,
      data
    })
  }

  static update(data) {
    return createResource({
      url: `${process.env.VUE_APP_CHAT_FORM_URL}/assistants/${data.id}`,
      data: data.payload
    })
  }

  static async fetchAll(after) {
    const queryParams = ['limit=100', ...(after ? [`after=${after}`] : [])]
    return await fectchResource(
      `${process.env.VUE_APP_CHAT_FORM_URL}/assistants?${queryParams.join('&')}`
    )
  }

  static fetchById(assistant_id) {
    return fectchResource(
      `${process.env.VUE_APP_CHAT_FORM_URL}/assistants/${assistant_id}`
    )
  }

  static delete(assistant_id) {
    return deleteResource(
      `${process.env.VUE_APP_CHAT_FORM_URL}/assistants/${assistant_id}`
    )
  }

  static createAssistantFile(data, id) {
    return createResource({
      url: `${process.env.VUE_APP_CHAT_FORM_URL}/assistants/${id}/files`,
      data
    })
  }

  static fetchAllUploadedFiles() {
    return fectchResource(`${process.env.VUE_APP_CHAT_FORM_URL}/files`)
  }

  static async uploadFileOpenAi(data) {
    return await createResource({
      url: `${process.env.VUE_APP_CHAT_FORM_URL}/files`,
      data
    })
  }

  static async deleteUploadedFile(id) {
    return await deleteResource(
      `${process.env.VUE_APP_CHAT_FORM_URL}/files/${id}`
    )
  }

  static async fetchAllFilesForAssistant(id) {
    return await fectchResource(
      `${process.env.VUE_APP_CHAT_FORM_URL}/assistants/${id}/files`
    )
  }

  static async deleteAssistantFile({ assistantId, fileId }) {
    return await deleteResource(
      `${process.env.VUE_APP_CHAT_FORM_URL}/assistants/${assistantId}/files/${fileId}`
    )
  }

  static async retrieveThreadMessages(threadId) {
    return await fectchResource(
      `${process.env.VUE_APP_CHAT_FORM_URL}/threads/${threadId}/messages`
    )
  }
}
