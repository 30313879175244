export class Customer {
  FirstName;

  ID;

  LastName;

  Name;
  constructor(customer = {}) {
    Object.assign(this, customer)
    this.Name = `${this.FirstName} ${this.LastName}`
  }

  get option() {
    return {
      label: `${this.FirstName} ${this.LastName}`,
      value: this.ID
    }
  }

  static fromList(customers = []) {
    return customers.map(customer => new Customer(customer))
  }
}
