export class AssistantPaginatedResponse {
  constructor({ data, last_id, has_more, fetchNext }) {
    this.items = data
    this.lastId = last_id
    this.hasMore = has_more
    this.fetchNext = fetchNext
  }

  async next() {
    const { data, last_id, has_more } = await this.fetchNext(this.lastId)
    this.items = [...this.items, ...data]
    this.lastId = last_id
    this.hasMore = has_more
  }
}
