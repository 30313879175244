import store from '@/store'
import { MUTATION_TYPES } from '@/store/mutations'
import { Deferred } from 'devextreme/core/utils/deferred'
import ArrayStore from 'devextreme/data/array_store'

export class AssistantStore extends ArrayStore {
  constructor(data) {
    super({
      data,
      key: 'id'
    })
  }

  insert(params) {
    store.commit(`assistant/${MUTATION_TYPES.ASSISTANT.SET_ASSISTANT}`, params.data || params)
    const promise = store.dispatch('assistants/create', params.rowIndex)

    return this.toPromise(promise)
  }

  update(id, values) {
    store.commit(`assistant/${MUTATION_TYPES.ASSISTANT.SET_ASSISTANT}`, {
      id,
      ...values
    })
    const promise = store.dispatch('assistants/update')

    return this.toPromise(promise)
  }

  remove(id) {
    const promise = store.dispatch('assistants/delete', id)
    return this.toPromise(promise)
  }

  toPromise(promise) {
    const deffered = Deferred()
    promise
      .then(item => deffered.resolve(item))
      .catch(error => deffered.reject(error.response?.data?.error?.message))

    return deffered.promise()
  }
}
