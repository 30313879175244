var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ResizableModal", {
        attrs: { "is-dialog-open": _vm.isDialogOpen },
        on: { updateIsDialogOpen: _vm.handleUpdateIsDialogOpen },
        scopedSlots: _vm._u([
          {
            key: "ChatAssistantDefaults",
            fn: function(slotprops) {
              return [
                _vm.isDialogOpen
                  ? _c("ChatAssistantDefaults", {
                      attrs: {
                        width: slotprops.width,
                        height: slotprops.height,
                        "assistant-default": _vm.assistantDefault
                      }
                    })
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("ODataTable", {
        key: _vm.filter,
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns.all,
          "add-edit-columns": _vm.columns.addEdit,
          resource: "AssistantDefaults",
          "action-buttons-width": 120,
          "total-label": _vm.$t("assistantDefaults.total"),
          "file-name": _vm.$t("assistantDefaults.fileName"),
          "add-row-button-label": _vm.$t("assistantDefaults.add"),
          title: _vm.$t("assistantDefaults.title"),
          filter: _vm.filter,
          "should-show-add-button": !_vm.filter.length
        },
        on: {
          rowInit: _vm.handleRowInit,
          editorPreparing: _vm.handleEditorPreparing,
          rowInserted: _vm.handleFormClose,
          editingStart: _vm.handleEditingStart,
          editingCancelling: _vm.handleFormClose
        },
        scopedSlots: _vm._u([
          {
            key: "custom-buttons",
            fn: function() {
              return [
                _c("DxButton", {
                  attrs: { icon: "copy", "on-click": _vm.handleClone }
                }),
                _c("DxButton", {
                  attrs: { icon: "comment", "on-click": _vm.handleChat }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }