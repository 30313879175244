var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "steps-container" },
      [
        _c("h1", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("manageUsers.title")))
        ]),
        _c(
          "el-breadcrumb",
          { attrs: { "separator-class": "el-icon-arrow-right" } },
          _vm._l(_vm.steps, function(step, index) {
            return _c(
              "el-breadcrumb-item",
              {
                key: step.value,
                class: { "selected-step": _vm.isActiveStep(step.value) }
              },
              [
                _c(
                  "el-link",
                  {
                    attrs: {
                      underline: false,
                      disabled: _vm.lastTraversedStep < index
                    },
                    on: {
                      click: function($event) {
                        return _vm.navigateToStep(step.value)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(step.label) + " ")]
                )
              ],
              1
            )
          }),
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { class: { section: true, "generate-report": _vm.isGenerateReportStep } },
      [
        _c("div"),
        _c(
          "div",
          [
            !_vm.isAssignTagStep
              ? _c(
                  "h2",
                  {
                    staticClass: "subtitle ",
                    class: { "no-bottom-margin": _vm.isDeactiveUserStep }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.stepTitle)) + " ")]
                )
              : _vm._e(),
            _vm.isDeactiveUserStep
              ? _c("h4", { staticClass: "step-subtitle" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("manageUsers." + _vm.activeStep + ".subtitle")
                      ) +
                      " "
                  )
                ])
              : _vm._e(),
            _c(_vm.activeStepComponent, {
              tag: "component",
              attrs: { users: _vm.customerIds },
              on: {
                onNextClick: _vm.handleNextClick,
                onInvalidEmailFound: _vm.handleInvalidEmail
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "action-buttons self-start" },
          [
            _vm.isGenerateReportStep
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.handleCourseCampaignClick }
                  },
                  [_vm._v(_vm._s(_vm.$t("manageUsers.createCampaign")))]
                )
              : _vm._e(),
            _vm.isGenerateReportStep
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      disabled: _vm.disabledUpdateSendList
                    },
                    on: { click: _vm.handleUpdateSendListClick }
                  },
                  [_vm._v(_vm._s(_vm.$t("manageUsers.updateSendList")))]
                )
              : _vm._e(),
            _vm.isMixedTypeImport && _vm.isGenerateReportStep
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.handleNextClick }
                  },
                  [_vm._v(_vm._s(_vm.$t("manageUsers.assignTag.stepLabel")))]
                )
              : _vm._e(),
            _vm.isGenerateReportStep
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-download",
                      disabled: !_vm.newlyAddedUsers.length
                    },
                    on: { click: _vm.exportNewUsersToExcel }
                  },
                  [_vm._v(_vm._s(_vm.$t("manageUsers.generateReport.export")))]
                )
              : _vm._e()
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }