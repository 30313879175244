<template>
  <div>

    <ODataTable
      :key="selectedSendList"
      ref="sendListRef"
      row-key="id"
      :columns="columns.all"
      :add-edit-columns="columns.addEdit"
      :should-show-delete-button="false"
      :is-custom-data-source="true"
      :custom-store-base-url="customStoreBaseUrl"
      dynamic-resource-expression="ContactsFromList"
      :resource="resource"
      :lookup-filter="false"
      :title="$t('contact.title')"
      :total-label="$t('contact.total')"
      :file-name="$t('contact.fileName')"
      :should-show-add-button="false"
      :should-show-edit-button="false"
    >
      <template #custom-toolbar-before>
        <el-select
          v-model="selectedSendList"
          class="send-list"
          filterable
          :loading="!sendLists.length"
          placeholder="Select Send List"
          size="large"
        >
          <el-option v-for="item in sendLists" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </template>
    </ODataTable>
  </div>
</template>

<script>
import ODataTable from '@/components/ODataTable'
import { createColumns } from './config.js'
import UpdateSendListService from '@/api/updateSendList'

export default {
  name: 'Contacts',
  components: {
    ODataTable
  },
  data() {
    return {
      staticData: [],
      sendLists: [],
      selectedSendList: null,
      customStoreBaseUrl: process.env.VUE_APP_BREVO_API_BASE_URL
    }
  },
  computed: {
    columns() {
      return createColumns()
    },
    resource() {
      if (!this.selectedSendList) {
        return ''
      }
      return `contacts/lists/${this.selectedSendList}/contacts`
    }
  },

  created() {
    this.handleFetchSendLists()
  },
  methods: {

    async handleFetchSendLists() {
      this.sendLists = await UpdateSendListService.fetchAllSendLists()
    }
  }
}
</script>
<style>
 .DxSelectBoxCss{
  border: 1px solid grey !important;
  border-radius: 5px;
  height: 3rem;
  background-color: white !important;
 }
</style>

