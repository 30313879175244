var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container py-none" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            { staticClass: "py-none" },
            [
              _c(
                "el-form",
                {
                  ref: "formRef",
                  attrs: {
                    model: _vm.courseCampaign,
                    "label-position": "top",
                    size: "small",
                    rules: _vm.rules
                  }
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "m-container" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "column", attrs: { xs: 24, sm: 15 } },
                        [
                          _c(
                            "el-row",
                            { staticClass: "center", attrs: { type: "flex" } },
                            [
                              _c(
                                "el-col",
                                { staticClass: "m-title", attrs: { span: 4 } },
                                [_vm._v(_vm._s(_vm.$t("name")) + " :")]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 20 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "name" } },
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          staticClass: "center gap-md",
                                          attrs: { type: "flex" }
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 10 } },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    filterable: "",
                                                    placeholder: _vm.$t(
                                                      "courseCampaign.choosePrograms.accountPlaceholder"
                                                    ),
                                                    size: "small"
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.handleCourseCampaignName
                                                  },
                                                  model: {
                                                    value: _vm.customerAccount,
                                                    callback: function($$v) {
                                                      _vm.customerAccount = $$v
                                                    },
                                                    expression:
                                                      "customerAccount"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.customerAccounts,
                                                  function(item) {
                                                    return _c("el-option", {
                                                      key: item.ID,
                                                      attrs: {
                                                        label: item.Account,
                                                        value: item.Account
                                                      }
                                                    })
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 10 } },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "courseCampaign.choosePrograms.campaignPlaceholder"
                                                  ),
                                                  size: "small"
                                                },
                                                on: {
                                                  input:
                                                    _vm.handleCourseCampaignName
                                                },
                                                model: {
                                                  value: _vm.campaignName,
                                                  callback: function($$v) {
                                                    _vm.campaignName = $$v
                                                  },
                                                  expression: "campaignName"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c("el-col", { attrs: { span: 4 } }, [
                                            _c(
                                              "span",
                                              { staticClass: "primary" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.courseCampaign
                                                      .formattedAddedDate
                                                  )
                                                )
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            {
                              staticClass: "center py-2",
                              attrs: { type: "flex" }
                            },
                            [
                              _c(
                                "el-col",
                                { staticClass: "m-title", attrs: { span: 4 } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("customers.total")) + " :"
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { staticClass: "primary", attrs: { span: 20 } },
                                [_vm._v(_vm._s(_vm.total))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            {
                              staticClass: "center py-2",
                              attrs: { type: "flex" }
                            },
                            [
                              _c(
                                "el-col",
                                { staticClass: "m-title", attrs: { span: 4 } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("courseCampaign.filters")) +
                                      " :"
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { staticClass: "primary", attrs: { span: 20 } },
                                [_vm._v(_vm._s(_vm.courseCampaign.filterUsed))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "column", attrs: { xs: 24 } },
                        [
                          _c(
                            "el-row",
                            {
                              staticClass: "center",
                              attrs: { type: "flex", gutter: 20 }
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 4 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("courseCampaign.portal"),
                                        prop: "siteId"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "select-width",
                                          attrs: {
                                            "allow-create": false,
                                            filterable: true,
                                            placeholder: _vm.$t(
                                              "courseCampaign.portal"
                                            )
                                          },
                                          model: {
                                            value: _vm.courseCampaign.siteId,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.courseCampaign,
                                                "siteId",
                                                $$v
                                              )
                                            },
                                            expression: "courseCampaign.siteId"
                                          }
                                        },
                                        _vm._l(_vm.sites, function(site) {
                                          return _c("el-option", {
                                            key: site.ID,
                                            attrs: {
                                              label: site.Name,
                                              value: site.ID
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 4 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("courseCampaign.program"),
                                        prop: "programId"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "select-width",
                                          attrs: {
                                            "allow-create": false,
                                            filterable: true,
                                            placeholder: _vm.$t(
                                              "courseCampaign.program"
                                            ),
                                            loading: _vm.areProgramsLoading
                                          },
                                          model: {
                                            value: _vm.courseCampaign.programId,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.courseCampaign,
                                                "programId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "courseCampaign.programId"
                                          }
                                        },
                                        _vm._l(_vm.programs, function(program) {
                                          return _c("el-option", {
                                            key: program.ID,
                                            attrs: {
                                              label: program.Name,
                                              value: program.ID
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  staticClass: "flex-end",
                                  attrs: { span: 16 }
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "courseCampaign.deadline"
                                        ),
                                        prop: "deadline"
                                      }
                                    },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "date",
                                          placeholder: _vm.$t(
                                            "courseCampaign.deadline"
                                          )
                                        },
                                        model: {
                                          value: _vm.courseCampaign.deadline,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.courseCampaign,
                                              "deadline",
                                              $$v
                                            )
                                          },
                                          expression: "courseCampaign.deadline"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          attrs: {
                            type: "flex",
                            align: "center",
                            justify: "space-between"
                          }
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("courseCampaign.episodes"),
                                    prop: "episodes"
                                  }
                                },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.areEpisodesLoading,
                                          expression: "areEpisodesLoading"
                                        }
                                      ],
                                      ref: "episodeListRef",
                                      staticClass: "episode m-border",
                                      attrs: { data: _vm.episodes },
                                      on: {
                                        "select-all": _vm.handleSelectionChange,
                                        select: _vm.handleSelectionChange
                                      }
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          type: "selection",
                                          width: "55"
                                        }
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "Title",
                                          label: _vm.$t(
                                            "courseCampaign.episodes"
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-table",
                                {
                                  ref: "selectedEpisodeListRef",
                                  staticClass: "episode m-border",
                                  attrs: { data: _vm.courseCampaign.episodes },
                                  on: {
                                    "select-all":
                                      _vm.handleSelectedEpisodeChange,
                                    select: _vm.handleSelectedEpisodeChange
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { type: "selection", width: "55" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "Title",
                                      label: _vm.$t(
                                        "courseCampaign.selectedEpisodes"
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }