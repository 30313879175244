export class MenuList {
  menus;

  groupedMenus;
  constructor(menus = []) {
    this.menus = menus
    this.groupedMenus = this.group()
  }

  get parents() {
    const parents = this.menus.filter(menu => menu.isParent)
    return MenuList.sort(parents)
  }

  get ids() {
    return this.menus.map(menu => menu.ID)
  }

  filterChildrenById(id) {
    return this.menus.filter(menu => menu.isChild(id))
  }

  toMenus() {
    return this.parents.map(menu => {
      menu.setChildren(this.filterChildrenById(menu.ID))

      return menu.createSection()
    })
  }

  group() {
    return this.parents

      .map(menu => menu.groupChildren(this.menus))
      .sort(
        (menu, secondMenu) => secondMenu.children.length - menu.children.length
      )
  }

  findById(id) {
    return this.menus.find(menu => menu.compare(id))
  }

  toggleParent(id) {
    if (id) {
      const menu = this.findById(id)
      if (menu) {
        menu.changeSelectedValueOnChildToggle()
        this.toggleParent(menu.ParentAdminMenuID)
      }
    }
  }

  toggleSelection(id) {
    const menu = this.findById(id)
    if (!menu.isIntermediate) {
      menu.toggleChildren()
      this.toggleParent(menu.ParentAdminMenuID)
    }
  }

  toOptions() {
    return this.menus.map(menu => menu.option)
  }

  static checkUrlExists(menus, url) {
    return menus.some(menu => {
      if (menu.URL === url && menu.children.length) {
        return !!this.checkUrlExists(menu.children, '/')
      }
      if (menu.URL === url) {
        return true
      }
      return !!this.checkUrlExists(menu.children, url.replace(menu.URL, ''))
    })
  }

  has(url = '') {
    return MenuList.checkUrlExists(this.groupedMenus, url)
  }

  markIdsSelected(ids = []) {
    this.menus.forEach(menu => {
      if (ids.includes(menu.ID)) {
        const children = this.filterChildrenById(menu.ID)
        if (!children.length) {
          menu.setValue(true)
          this.toggleParent(menu.ParentAdminMenuID)
        }
      }
    })
  }

  get selectedIds() {
    return this.menus

      .filter(menu => [undefined, true].includes(menu.value))

      .map(menu => menu.ID)
  }

  clear() {
    this.menus.forEach(menu => menu.setValue(false))
  }

  static findMenuByUrl(menus = [], url) {
    const menu = menus.find(menu => {
      if (menu.URL === url && menu.children.length) {
        return !!this.findMenuByUrl(menu.children, '/')
      }
      if (menu.URL === url) {
        return true
      }
      return !!this.findMenuByUrl(menu.children, url.replace(menu.URL, ''))
    })

    if (!menu || menu.URL === url) {
      return menu
    }

    return this.findMenuByUrl(menu.children, url.replace(menu.URL, ''))
  }

  findByUrl(url) {
    return MenuList.findMenuByUrl(this.groupedMenus, url)
  }

  static sort(menus = []) {
    return menus.sort(
      (firstMenu, secondMenu) => firstMenu.SortOrder - secondMenu.SortOrder
    )
  }
}
