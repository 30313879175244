import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */

import Layout from '@/layout'
import { STEP } from '@/views/manageUsers/config'
import { canManageAdmins } from '@/guards'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
  roles: ['admin','editor']    control the page roles (you can set multiple roles)
  title: 'title'               the name show in sidebar and breadcrumb (recommend set)
  icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
  breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
  activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const ROUTE_PATH = Object.freeze({
  CUSTOMERS: '/users',
  USER_MEDIA: 'user-media',
  KNOWLEDGE_TEST: '/follow-up/knowledge-test',
  REFLECTION_QUESTIONS: '/follow-up/reflection-questions',
  CONTENT_PART_PAGES: '/administer/pages',
  REFLECTIONS: '/follow-up/reflections',
  CREATE_CAMPAIGN: '/users/course-campaign/new',
  MEDIAREPORTV2: '/users/media-reports-v2',
  SENDLIST: '/users/update-send-list'
})

export const SUPER_ADMIN_ONLY_ACCESS_PATH = [
  {
    path: '/debug'
  }
]

export class Route {
  constructor({
    name = '',
    path = '',
    component,
    meta = {},
    onClick = () => {},
    hidden = true,
    children = [],
    props
  }) {
    this.name = name
    this.path = path
    this.component = component
    this.meta = meta
    this.onClick = onClick.bind(this, null)
    this.hidden = hidden
    this.children = children
    if (props) {
      this.props = props
    }
  }
}
export const constantRoutes = [
  {
    path: '/login',

    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/forgot-password',
    component: () => import('@/views/forgotPassword'),
    hidden: true
  },
  {
    path: '/404',

    component: () => import('@/views/404'),
    hidden: true
  },

  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',

        component: () => import('@/views/dashboard/index'),
        meta: { title: 'dashboard', icon: 'dashboard' }
      }
    ]
  },

  {
    path: '/download',
    component: Layout,
    redirect: '/download',
    children: [
      {
        path: '',
        name: 'Download',

        component: () => import('@/views/download/index'),
        meta: { title: 'download.title', icon: 'download' }
      }
    ]
  },
  {
    path: '/debug',
    component: Layout,
    redirect: '/debug',
    beforeEnter: canManageAdmins,
    children: [
      {
        path: '',
        name: 'debug',
        component: () => import('@/views/debug/index'),
        meta: { title: 'debug.title', icon: 'el-icon-set-up' }
      }
    ]
  },
  {
    path: ROUTE_PATH.CUSTOMERS,
    component: Layout,
    redirect: '/users/update',
    meta: { title: 'users', icon: 'user' },
    children: [
      new Route({
        path: 'update',
        hidden: false,
        name: 'Manage Users',

        component: () => import('@/views/manageUsers'),
        meta: {
          title: 'manageUsers.title',
          icon: 'el-icon-edit-outline',
          activeStep: null
        },
        onClick: function() {
          this.meta.activeStep = null
          this.meta.activeStep = STEP.SELECT_TYPE
        }
      }),
      new Route({
        path: 'media-reports',
        hidden: false,
        name: 'Media Reports',

        component: () => import('@/views/mediaReports'),
        meta: {
          title: 'mediaReports.title',
          icon: 'el-icon-files',
          activeStep: null
        }
      }),
      new Route({
        path: 'inverted-media-reports',
        hidden: false,
        name: 'invertedMediaReports.title',

        component: () => import('@/views/invertedMediaReports'),
        meta: {
          title: 'invertedMediaReports.title',
          icon: 'el-icon-files',
          activeStep: null
        }
      }),
      new Route({
        path: 'media-reports-v2',
        hidden: false,
        name: 'mediaReportsV2.title',
        component: () => import('@/views/MediaReportV2'),
        meta: {
          title: 'mediaReportsV2.title',
          icon: 'el-icon-files'
        }
      }),
      new Route({
        path: 'inverted-media-reports-v2',
        name: 'invertedMediaReports.title',
        component: () => import('@/views/invertedMediaReportsV2'),
        meta: {
          title: 'invertedMediaReports.title',
          icon: 'el-icon-files'
        }
      }),
      {
        path: ROUTE_PATH.USER_MEDIA,
        name: 'User Media',

        component: () => import('@/views/userMedia'),
        meta: {
          title: 'userMedia.title',
          icon: 'el-icon-film',
          activeStep: null
        }
      },
      {
        path: '',
        name: 'Users',

        component: () => import('@/views/customers'),
        meta: { title: 'customers.title', icon: 'user' }
      },
      new Route({
        path: 'course-campaign',
        name: 'Create Course Campaign',

        component: () => import('@/views/courseCampaign'),
        hidden: true,
        props: true,
        children: [
          new Route({
            path: 'new',
            name: 'Create Course Campaign',

            component: () => import('@/views/courseCampaign'),
            meta: {
              title: 'Course Campaign',
              icon: 'user',
              activeMenu: ROUTE_PATH.CUSTOMERS
            },
            hidden: true
          })
        ]
      }),
      new Route({
        path: 'customers/:id/histories',
        name: 'customers.histories.title',

        component: () => import('@/views/customers/updateHistories'),
        meta: { title: 'customers.title', icon: 'user' },
        children: [
          new Route({
            path: '',
            name: 'customers.histories.title',

            component: () => import('@/views/customers/updateHistories'),
            meta: {
              title: 'customers.histories.name',
              icon: 'user',
              activeMenu: ROUTE_PATH.CUSTOMERS
            }
          })
        ]
      }),
      new Route({
        path: 'customers/:id/chat-history-table',
        name: 'Chat History',
        component: () => import('@/views/customers/chatHistory'),
        meta: { title: 'customers.title', icon: 'user' },
        children: [
          new Route({
            path: '',
            name: 'Chat History',
            component: () => import('@/views/customers/chatHistory'),
            meta: {
              title: 'Chat History',
              icon: 'user',
              activeMenu: ROUTE_PATH.CUSTOMERS
            }
          })
        ]
      }),
      new Route({
        path: ROUTE_PATH.SENDLIST,
        name: 'updateSendList.title',
        component: () => import('@/views/customers/updateSendList'),
        meta: { title: 'updateSendList.title' },
        children: [
          new Route({
            path: '',
            name: 'updateSendList.title',
            component: () => import('@/views/customers/updateSendList'),
            meta: {
              title: 'updateSendList.title',
              activeMenu: ROUTE_PATH.CUSTOMERS
            }
          })
        ]
      }),
      new Route({
        path: 'updated-result',
        name: 'updatedResultSendList.title',
        component: () =>
          import('@/views/customers/updateSendList/UpdatedResult'),
        meta: { title: 'updatedResultSendList.title' },
        children: [
          new Route({
            path: '',
            name: 'updatedResultSendList.title',
            component: () =>
              import('@/views/customers/updateSendList/UpdatedResult'),
            meta: {
              title: 'updatedResultSendList.title',
              activeMenu: ROUTE_PATH.CUSTOMERS
            }
          })
        ]
      }),

      new Route({
        path: 'customers-account',
        name: 'customersAccount.title',
        component: () => import('@/views/CustomersAccount'),
        meta: { title: 'customersAccount.title', icon: 'el-icon-info' }
      })
    ]
  },
  {
    path: '/administer',
    component: Layout,
    meta: { title: 'administer', icon: 'el-icon-user' },
    redirect: '/administer/content-blocks',
    children: [
      {
        path: 'content-blocks',

        component: () => import('@/views/ContentPart'),
        name: 'ContentPart',
        meta: { title: 'contentBlocks', icon: 'el-icon-menu' }
      },
      {
        path: 'builder',
        hidden: true,

        component: () => import('@/views/Builder'),
        name: 'Builder',
        meta: {
          title: 'builder',
          icon: 'table',
          activeMenu: ROUTE_PATH.CONTENT_PART_PAGES
        }
      },
      {
        path: 'pages',

        component: () => import('@/views/ContentPartPages'),
        name: 'Content Part Pages',
        meta: { title: 'pages', icon: 'el-icon-notebook-2' }
      }
    ]
  },
  {
    path: '/categories',
    component: Layout,
    meta: { title: 'categories.title', icon: 'el-icon-s-unfold' },
    redirect: '/categories',
    children: [
      {
        path: '',

        component: () => import('@/views/categories'),
        name: 'Categories',
        meta: { title: 'categories.title', icon: 'el-icon-s-unfold' }
      },
      {
        path: 'medias',

        component: () => import('@/views/medias'),
        name: 'Media',
        meta: { title: 'medias.title', icon: 'el-icon-film' }
      },
      {
        path: 'downloadable',

        component: () => import('@/views/downloadable'),
        name: 'downloadable',
        meta: { title: 'downloadable.title', icon: 'el-icon-download' }
      },
      {
        path: 'downloadable-update',
        component: () => import('@/views/manageDownloadables'),
        name: 'downloadable.update.title',
        meta: { title: 'downloadable.update.title', icon: 'el-icon-edit' }
      },
      {
        path: 'authors',

        component: () => import('@/views/authors'),
        name: 'authors.title',
        meta: { title: 'authors.title', icon: 'el-icon-info' }
      },
      {
        path: 'authors-by-media',

        component: () => import('@/views/AuthorsByMedia'),
        name: 'AuthorsByMedia.title',
        meta: { title: 'AuthorsByMedia.title', icon: 'el-icon-info' }
      }
    ]
  },
  {
    path: '/follow-up',
    component: Layout,
    meta: { title: 'followUp.title', icon: 'el-icon-setting' },
    redirect: '/follow-up/knowledge-test',
    children: [
      {
        path: 'knowledge-test',

        component: () => import('@/views/tests'),
        name: 'Knowledge Test',
        redirect: '/follow-up/knowledge-test',
        children: [
          {
            path: '',

            component: () => import('@/views/tests/list'),
            name: 'Knowledge Test',
            meta: { title: 'knowledgeTest.title', icon: 'el-icon-edit' }
          },
          {
            path: 'new',
            hidden: true,

            component: () => import('@/views/tests/addEdit'),
            name: 'Create',
            meta: {
              title: 'test.create.title',
              icon: 'el-icon-edit',
              activeMenu: ROUTE_PATH.KNOWLEDGE_TEST
            }
          },
          {
            path: ':id',
            hidden: true,

            component: () => import('@/views/tests/addEdit'),
            name: 'Edit',
            meta: {
              title: 'test.edit.title',
              icon: 'el-icon-edit',
              activeMenu: ROUTE_PATH.KNOWLEDGE_TEST
            }
          }
        ]
      },
      {
        path: 'reflection-questions',

        component: () => import('@/views/tests'),
        name: 'Reflection Questions',
        redirect: '/follow-up/reflection-questions',
        children: [
          {
            path: '',

            component: () => import('@/views/tests/list'),
            name: 'Reflection Questions',
            meta: { title: 'reflectionQuestions.title', icon: 'el-icon-edit' }
          },
          {
            path: 'new',
            hidden: true,

            component: () => import('@/views/tests/addEdit'),
            name: 'Create',
            meta: {
              title: 'test.create.title',
              icon: 'el-icon-edit',
              activeMenu: ROUTE_PATH.REFLECTION_QUESTIONS
            }
          },
          {
            path: ':id',
            hidden: true,

            component: () => import('@/views/tests/addEdit'),
            name: 'Edit',
            meta: {
              title: 'test.create.title',
              icon: 'el-icon-edit',
              activeMenu: ROUTE_PATH.REFLECTION_QUESTIONS
            }
          }
        ]
      },
      {
        path: 'reflections',

        component: () => import('@/views/tests'),
        name: 'Reflection',
        redirect: '/follow-up/reflections',
        children: [
          {
            path: '',

            component: () => import('@/views/reflection'),
            name: 'Tests',
            meta: { title: 'reviewReflections.title', icon: 'el-icon-edit' }
          },
          {
            path: 'review',
            hidden: true,

            component: () => import('@/views/reviewReflection'),
            name: 'ReviewReflection',
            meta: {
              title: 'reviewReflections.title',
              icon: 'el-icon-edit',
              activeMenu: ROUTE_PATH.REFLECTIONS
            }
          }
        ]
      },
      {
        path: 'goals',
        component: () => import('@/views/tests'),
        name: 'Goals',
        redirect: '/follow-up/goals',
        children: [
          {
            path: '',
            component: () => import('@/views/Goals'),
            name: 'goals.title',
            meta: { title: 'goals.title', icon: 'el-icon-info' }
          }
        ]
      },
      {
        path: 'answers-to-knowledge-test',

        component: () => import('@/views/AnswersknowledgeTest'),
        name: 'AnswersToKnowledgeTest',
        meta: { title: 'answersToKnowledgeTest.title', icon: 'el-icon-rank' }
      }
    ]
  },
  {
    path: '/portals',
    component: Layout,
    meta: { title: 'portals.title', icon: 'table' },
    redirect: 'sites',
    children: [
      {
        path: 'sites',

        component: () => import('@/views/sites'),
        name: 'Sites',
        meta: { title: 'sites.title', icon: 'el-icon-info' }
      },
      {
        path: 'disabled-categories',

        component: () => import('@/views/DisabledCategory'),
        name: 'disabledCategory.title',
        meta: { title: 'disabledCategory.title', icon: 'el-icon-info' }
      },
      {
        path: 'disabled-media-accounts',

        component: () => import('@/views/DisabledMediaAccounts'),
        name: 'disabledMediaAccounts.title',
        meta: { title: 'disabledMediaAccounts.title', icon: 'el-icon-info' }
      },
      {
        path: 'disabled-medias',

        component: () => import('@/views/DisabledMedias'),
        name: 'disabledMedias.title',
        meta: { title: 'disabledMedias.title', icon: 'el-icon-info' }
      },
      {
        path: 'disabled-category-accounts',

        component: () => import('@/views/DisabledCategoryAccounts'),
        name: 'disabledCategoryAccounts.title',
        meta: { title: 'disabledCategoryAccounts.title', icon: 'el-icon-info' }
      },
      {
        path: 'disabled-media-exceptions',

        component: () => import('@/views/disabledMediaExceptions'),
        name: 'disabledMediaExceptions.title',
        meta: { title: 'disabledMediaExceptions.title', icon: 'el-icon-info' }
      }
    ]
  },
  {
    path: '/tags',
    component: Layout,
    meta: { title: 'tags.title', icon: 'table' },
    children: [
      {
        path: '',

        component: () => import('@/views/Tags'),
        name: 'tags.title',
        meta: { title: 'tags.title', icon: 'el-icon-info' }
      }
    ]
  },
  {
    path: '/tags-by-customers',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/TagsByCustomers'),
        name: 'tagsByCustomers.title',
        meta: { title: 'tagsByCustomers.title' }
      }
    ]
  },
  {
    path: '/chat-bot',
    component: Layout,
    meta: { title: 'openAichatBot.title', icon: 'table' },
    redirect: 'assistants',
    children: [
      {
        path: 'assistants',
        component: () => import('@/views/Assistants'),
        name: 'assistants.title',
        meta: { title: 'assistants.title', icon: 'el-icon-chat-round' }
      },
      {
        path: 'assistant-defaults',
        component: () => import('@/views/AssistantDefaults'),
        name: 'assistantDefaults.title',
        meta: { title: 'assistantDefaults.title', icon: 'el-icon-info' }
      },
      {
        path: 'chat-questions',
        component: () => import('@/views/ChatQuestions'),
        name: 'chatQuestions.title',
        meta: { title: 'chatQuestions.title', icon: 'table' }
      },
      {
        path: 'chat-questions-by-media',
        component: () => import('@/views/ChatQuestionsByMedia'),
        name: 'ChatQuestionsByMedia.title',
        meta: { title: 'ChatQuestionsByMedia.title', icon: 'table' }
      },
      {
        path: 'sources',
        component: () => import('@/views/sources'),
        name: 'sources.title',
        meta: { title: 'sources.title', icon: 'el-icon-coin' }
      }
    ]
  },
  {
    path: '/campaigns',
    component: Layout,
    meta: { title: 'campaigns.title', icon: 'el-icon-bell' },
    redirect: 'contacts',
    children: [
      {
        path: 'contacts',
        name: 'contact.title',
        component: () => import('@/views/customers/contacts'),
        meta: { title: 'contact.title', icon: 'user' }
      },
      {
        path: 'campaign-defaults',
        name: 'campaignDefaults.title',
        component: () => import('@/views/CampaignDefaults'),
        meta: { title: 'campaignDefaults.title', icon: 'el-icon-info' }
      }
    ]
  },
  {
    path: '/manage',
    component: Layout,
    hidden: true,
    meta: { title: 'manage' },
    redirect: 'menus',
    beforeEnter: canManageAdmins,
    children: [
      {
        path: 'menus',

        component: () => import('@/views/Manage/Menus'),
        name: 'menus.name',
        meta: { title: 'menus.name' }
      },
      {
        path: 'admins',

        component: () => import('@/views/Manage/Admins'),
        name: 'admins.name',
        meta: { title: 'admins.name' }
      }
    ]
  },
  {
    path: '/shared',
    component: Layout,
    meta: { title: 'shared.title', icon: 'table' },
    children: [
      {
        path: '',

        component: () => import('@/views/Shared'),
        name: 'shared.title',
        meta: { title: 'shared.title', icon: 'el-icon-files' }
      }
    ]
  },
  {
    path: '/cors-tester',
    component: Layout,
    meta: { title: 'corsTester.title', icon: 'service' },
    children: [
      {
        path: '',

        component: () => import('@/views/CorsTester'),
        name: 'corsTester.title',
        meta: { title: 'corsTester.title', icon: 'el-icon-service' }
      }
    ]
  },
  {
    path: '/page-builder',
    component: Layout,
    meta: { title: 'pageBuilder.title', icon: 'table' },
    children: [
      {
        path: '',

        component: () => import('@/views/PageBuilder'),
        name: 'pageBuilder.title',
        meta: { title: 'pageBuilder.title', icon: 'el-icon-edit' }
      }
    ]
  },
  {
    path: '/assigned-co-workers-courses',
    component: Layout,
    meta: { title: 'assignedCoWorkersCourses.title', icon: 'table' },
    children: [
      {
        path: '',

        component: () => import('@/views/CoWorkersCourseAssigned'),
        name: 'assignedCoWorkersCourses.title',
        meta: { title: 'assignedCoWorkersCourses.title', icon: 'el-icon-files' }
      }
    ]
  },
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support

    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()

  router.matcher = newRouter.matcher // reset router
}

export default router
