import i18n from '@/i18n'

const CONTENT_PART_MODEL = {
  ID: {
    type: Number,
    default: 0
  },
  Name: {
    type: String,
    default: ''
  },
  MainContent: {
    type: String,
    default: ''
  },
  Tag: {
    type: String,
    default: ''
  },
  SortOrder: {
    type: Number,
    default: 0
  },
  Position: {
    type: String,
    default: ''
  },
  MainPicture: {
    type: String,
    default: ''
  },
  SiteID: {
    type: Number,
    default: 0
  },
  ContentPartTemplateID: {
    type: String,
    default: ''
  }
}

const SELECT_MEDIA_MESSAGE = `<h1>${i18n.t('errors.selectMedia')}</h1>`

const TEMPLATE_TYPES = {
  HTML: 'HTML',
  MEDIA: 'MEDIA',
  TEXT: 'TEXT'
}

export { CONTENT_PART_MODEL, SELECT_MEDIA_MESSAGE, TEMPLATE_TYPES }
