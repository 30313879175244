var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-flex" },
    [
      _c(
        "div",
        { staticClass: "margin-left-md match-table" },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.importedDownloadables, height: "250" } },
            _vm._l(_vm.headings.options, function(column, index) {
              return _c("el-table-column", {
                key: column.label,
                staticClass: "overflow-wrap",
                attrs: { prop: column.label, "show-overflow-tooltip": true },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                value: column.value,
                                placeholder: _vm.$t("notRecognized"),
                                loading: _vm.isLoading
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleFieldSelect(
                                    column.label,
                                    $event,
                                    index
                                  )
                                }
                              }
                            },
                            _vm._l(_vm.options.options, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "margin-left-md self-start",
          attrs: {
            type: "primary",
            loading: _vm.isGenerateReportLoading,
            disabled: _vm.headings.isDisabled
          },
          on: { click: _vm.handleGenerateReportClick }
        },
        [
          _vm._v(
            _vm._s(_vm.$t("manageDownloadables.matchField.generateReport"))
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }