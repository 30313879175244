export class Column {
  column;

  constructor(column) {
    this.column = column
  }

  get isEmail() {
    return this.column === 'EMail'
  }

  isEqual(field) {
    return [this.column?.value, this.column].includes(field)
  }

  get hasSearchTerm() {
    return !!this.column?.searchTerm
  }

  createODataFilterParams() {
    return {
      [this.column?.value]: this.column?.type
        ? {
          eq: {
            type: this.column?.type,
            value: this.column?.searchTerm
          }
        }
        : {
          contains: this.column?.searchTerm
        }
    }
  }

  updatePartial(updateAttributes) {
    Object.assign(this.column, updateAttributes)
  }

  static createColumnName(index = 0) {
    return `column_${index + 1}`
  }

  static updateColumnName({
    existingColumn,
    columnToBeUpdated,
    updatedValue,
    index
  }) {
    return existingColumn === columnToBeUpdated
      ? updatedValue
      : existingColumn === updatedValue
        ? this.createColumnName(index)
        : existingColumn
  }

  static isCustomColumn(column = '') {
    return column.includes('column')
  }

  static isAccount(column) {
    return column === 'Account'
  }

  static isSubAccount(column) {
    return column === 'SubAccount'
  }

  static isExtId(column) {
    return column === 'ExtID'
  }

  static isNoDeactivation(column) {
    return column === 'NoDeactivation'
  }

  static parseNoDeactivationValue(value = '') {
    const valueInLowerCase =
      typeof value === 'string' ? value.toLowerCase() : value

    if (['sant', 'true', '1'].includes(valueInLowerCase)) {
      return 1
    }

    if (['false', '0', '', 'null'].includes(valueInLowerCase)) {
      return 0
    }

    return value
  }
}
