export class DxButtonItemOptions {
  constructor(visible = false, width = '150px') {
    this._visible = visible
    this.width = width
  }

  get icon() {
    return this._visible ? 'chevronup' : 'chevrondown'
  }

  get text() {
    return this._visible ? 'Show less' : 'Show more'
  }

  getButtonOptions() {
    return {
      icon: this.icon,
      text: this.text,
      width: this.width,
      onClick: this.onClick.bind(this)
    }
  }

  onClick() {
    this._visible = !this._visible
  }
}
