var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-flex" },
    [
      _vm._l(_vm.validations, function(item) {
        return _c(
          "el-col",
          {
            key: item.field,
            staticClass: "validation",
            class: {
              success: item.isValid,
              error: !item.isValid
            },
            attrs: { span: 16 }
          },
          [
            _c("i", {
              class: item.isValid ? "el-icon-check" : "el-icon-close"
            }),
            _vm._v(" " + _vm._s(_vm.$t("errors." + item.value)))
          ]
        )
      }),
      _c(
        "div",
        { staticClass: "checbox-wrapper" },
        [
          _vm.isExtIdImportVisible
            ? _c(
                "el-checkbox",
                {
                  staticClass: "extid-import-field",
                  model: {
                    value: _vm.isAllowedEMailImport,
                    callback: function($$v) {
                      _vm.isAllowedEMailImport = $$v
                    },
                    expression: "isAllowedEMailImport"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("manageUsers.matchField.extIdImport")))]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "margin-left-md table" },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.users.importedUsers, height: "250" } },
            _vm._l(_vm.headings, function(column) {
              return _c("el-table-column", {
                key: column.label,
                staticClass: "overflow-wrap",
                attrs: { prop: column.label, "show-overflow-tooltip": true },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                value: column.value,
                                placeholder: "Not Recognized",
                                loading: _vm.isLoading
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleFieldSelect(
                                    column.label,
                                    $event
                                  )
                                }
                              }
                            },
                            _vm._l(_vm.options, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "margin-left-md self-start",
          attrs: {
            loading: _vm.isLoading,
            type: "primary",
            disabled: _vm.disableGenerateReportButton
          },
          on: { click: _vm.handleGenerateReportClick }
        },
        [_vm._v(_vm._s(_vm.$t("manageUsers.matchField.generateReport")))]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }