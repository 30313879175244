<template>
  <div class="chat-assistant-container">
    <div class="chat-assistants-grid">
      <div class="assistant-item">
        <deep-chat
          :ref="assistants[0]"
          :style="chatBotStyle"
          demo="true"
          stream="true"
          :textInput="textInputStyle"
          gifs='{"button": {"position": "dropup-menu"}}'
          audio='{"button": {"position": "dropup-menu"}}'
          camera='{"button": {"position": "dropup-menu"}}'
          mixed-files='{"button": {"position": "inside-left"}}'
          microphone='{"button": {"position": "outside-right"}}'
        />
      </div>
    </div>
  </div>
</template>

<script>
import DeepChat from 'deep-chat'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapUserState } = createNamespacedHelpers('user')
import chatAssistant from '/src/mixin/chatAssistant'
export default {
  name: 'ChatAssistantDefaults',
  components: {
    DeepChat
  },
  mixins: [chatAssistant],
  props: {
    assistantDefault: {
      type: Object,
      default: null
    },
    width: {
      type: Number,
      default: 340
    },
    height: {
      type: Number,
      default: 260
    }
  },
  data() {
    return {
      assistants: ['deepChatRef_1'],
      sessionID: ''
    }
  },
  computed: {
    ...mapUserState({
      loggedInUser: state => state.user.ID,
      user: state => state.user
    }),
    textInputStyle() {
      return `{
    "styles": {
      "text": {"color": "black"}
    },
    "placeholder": {
      "text": "${this.assistantDefault.StartChatText ||
        process.env.VUE_APP_CHAT_FIRST_QUESTION}", "style": {"color": "gray"}}
    }`
    },
    chatBotStyle() {
      return {
        'border-radius': '8px',
        width: `${this.width * 0.9}px`,
        height: `${this.height * 0.8}px`
      }
    }
  },
  async mounted() {
    const chatElementRef = this.$refs[this.assistants[0]]
    if (chatElementRef) {
      this.setChat(chatElementRef)
    }
  }
}
</script>

<style>
.chat-assistant-container {
  display: flex;
  flex-direction: column;
}

.chat-assistants-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  flex-direction: row;
  justify-content: center;
}

.assistant-item {
  margin-bottom: 16px;
}

.threadDropDown {
  margin: 10px 0px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.clone-button {
  display: flex;
  justify-content: center;
}

div[aria-label="Chat Interface"] {
  width: 400px !important;
}
</style>
