import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import app from './modules/app'
import settings from './modules/settings'
import user from './modules/user/index'
import contentPart from './modules/contentPart'
import contentPartBuilder from './modules/contentPartBuilder'
import contentPartPages from './modules/contentPartPages'
import customer from './modules/customer'
import files from './modules/files'
import courseCampaign from './modules/courseCampaign'
import tags from './modules/tags'
import test from './modules/test'
import admin from './modules/admin'
import assistants from './modules/assistants'
import assistant from './modules/assistant'
import corsTester from './modules/corsTester'
import reflection from './modules/reflection'
import mediaReports from './modules/mediaReports'
import importedDownloadables from './modules/importedDownloadables'
import shared from './modules/shared/index'
import mediaReportsV2 from './modules/mediaReportsV2'
import goals from './modules/goals'
import assistantDefaults from './modules/assistantDefaults'
import chatQuestionsByMedia from './modules/chatQuestionsByMedia'
import downloadable from './modules/downloadable'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    settings,
    user,
    contentPart,
    contentPartBuilder,
    contentPartPages,
    customer,
    courseCampaign,
    files,
    tags,
    test,
    admin,
    assistants,
    assistant,
    corsTester,
    reflection,
    mediaReports,
    importedDownloadables,
    shared,
    mediaReportsV2,
    goals,
    assistantDefaults,
    chatQuestionsByMedia,
    downloadable
  },
  getters
})

export default store
