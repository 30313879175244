import { MenuList } from './sectionList'

export class Menu {
  ID;

  Icon;

  InfoText;

  IsHidden;

  Name;

  ParentAdminMenuID;

  URL;

  children;

  value;
  constructor(menu = {}) {
    Object.assign(this, menu)
    this.value = false
  }

  get option() {
    return {
      label: this.Name,
      value: this.ID
    }
  }

  get isParent() {
    return !this.ParentAdminMenuID
  }

  isChild(id) {
    return this.ParentAdminMenuID === id
  }

  setChildren(children = []) {
    this.children = MenuList.sort(children)
  }

  get isPublic() {
    return ['/login'].includes(this.URL)
  }

  createChildSection() {
    return {
      path: this.URL.replace('/', ''),
      name: this.Name,
      meta: { title: this.InfoText, icon: this.Icon },
      hidden: this.IsHidden
    }
  }

  get alias() {
    return {
      '/dashboard': '/'
    }
  }

  groupChildren(menus = []) {
    this.setChildren(menus.filter(menu => menu.isChild(this.ID)))

    this.children.map(child => child.groupChildren(menus))
    return this
  }

  createSection() {
    const alias = this.alias[this.URL]
    return {
      path: this.URL,
      hidden: this.IsHidden,
      ...(alias && {
        alias
      }),
      name: this.Name,
      ...(!this.isPublic && {
        redirect: this.URL,
        ...(this.children.length && {
          meta: { title: this.InfoText, icon: this.Icon }
        }),
        children: [
          ...(!this.children.length
            ? [
              {
                path: '',
                name: this.Name,
                meta: { title: this.InfoText, icon: this.Icon },
                hidden: this.IsHidden
              }
            ]
            : this.children.map(child => child.createChildSection()))
        ]
      })
    }
  }

  setValue(value) {
    this.value = value
  }

  toggleChildren() {
    this.children.forEach(child => {
      child.setValue(this.value)
      child.toggleChildren()
    })
  }

  get areAllChildrenSelected() {
    return this.children.every(menu => menu.value)
  }

  get areAllChildrenUnSelected() {
    return this.children.every(menu => menu.value === false)
  }

  get isIntermediate() {
    return this.value === undefined
  }

  changeSelectedValueOnChildToggle() {
    this.setValue(undefined)

    if (this.areAllChildrenSelected) {
      this.setValue(true)
    }
    if (this.areAllChildrenUnSelected) {
      this.setValue(false)
    }
  }

  compare(id) {
    return this.ID === id
  }

  static fromList(menus = []) {
    return new MenuList(menus.map(menu => new Menu(menu)))
  }
}
