<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isPortrait: false,
      isMobile: false
    }
  },
  mounted() {
    this.applyLandscapeMode()
    window.addEventListener('resize', this.applyLandscapeMode)
    window.addEventListener('orientationchange', this.applyLandscapeMode)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.applyLandscapeMode)
    window.removeEventListener('orientationchange', this.applyLandscapeMode)
  },
  methods: {
    isMobileDevice() {
      return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|Opera Mini|IEMobile|WPDesktop|webOS|Mobile|Tablet|Symbian|Windows Phone|Fennec/.test(
        navigator.userAgent
      )
    },
    applyLandscapeMode() {
      const content = document.querySelector('#app')
      const isPortrait = window.innerHeight > window.innerWidth

      if (isPortrait && this.isMobileDevice()) {
        content.style.transform = 'rotate(90deg)'
        content.style.width = `${window.innerHeight}px`
        content.style.height = `${window.innerWidth}px`
        content.style.position = 'absolute'
        content.style.top = '50%'
        content.style.left = '50%'
        content.style.transformOrigin = 'top left'
        content.style.transform += ' translate(-50%, -50%)'
      } else {
        content.style.transform = ''
        content.style.width = ''
        content.style.height = ''
        content.style.position = ''
        content.style.top = ''
        content.style.left = ''
        content.style.transformOrigin = ''
      }
    }
  }
}
</script>
