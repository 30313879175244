import ExternalMediaService from '@/api/downloadable'
import i18n from '@/i18n'
import { DownloadableColumn } from './downloadableColumn'
import { DownloadableColumns } from './downloadableColumns'
import { ImportedUsers } from './importedUsers'
import { Option } from './option'

export class ImportedDownloadables extends ImportedUsers {
  static headings() {
    return Option.fromList([
      { label: 'column_1', value: '' },
      { label: 'column_2', value: '' },
      { label: 'column_3', value: '' },
      { label: 'column_4', value: '' }
    ])
  }

  static options() {
    return Option.fromList([
      {
        label: i18n.t('id'),
        value: 'ID'
      },
      {
        label: i18n.t('mediaId'),
        value: 'MediaId'
      },
      {
        label: i18n.t('description.title'),
        value: 'Description'
      },
      {
        label: i18n.t('fileName'),
        value: 'FileName'
      }
    ])
  }

  static validateMedia(column, importedDownloadables) {
    const values = importedDownloadables.map(
      importedDownloadable => importedDownloadable[column]
    )

    return values.every(value => Number.isInteger(Number(value)))
  }

  static async updateDownloadables(
    importedDownloadables,
    headings,
    allDownloadablesIds
  ) {
    try {
      const idColumn = headings.findLabelByValue('ID')
      importedDownloadables
        .filter(el => allDownloadablesIds.includes(Number(el[idColumn])))
        .forEach(async el => {
          const params = DownloadableColumns.generateParams(el, headings)
          const idColumn = DownloadableColumn.idColumn(headings)
          await ExternalMediaService.update(params, el[idColumn])
        })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
