import { MUTATION_TYPES as BASE_MUTATION_TYPES } from '../mutations'
import { ReflectionFilters } from '@/models/ReflectionFilters'
import CustomerService from '@/api/customer'
import { PaginatedResponse } from '@/models/paginatedResponse'
import { Message } from 'element-ui'
const MUTATION_TYPES = BASE_MUTATION_TYPES.REFLECTION

const state = {
  filters: new ReflectionFilters({}),
  reflectionIds: '',
  isLoading: false
}

const mutations = {
  [MUTATION_TYPES.SET_CUSTOMER_IDS]: (state, payload) => {
    state.filters.customerIDs = ReflectionFilters.getIds(payload)
  },

  [MUTATION_TYPES.SET_REFLECTION_IDS]: (state, payload) => {
    state.reflectionIds = ReflectionFilters.getIds(payload)
  },

  [MUTATION_TYPES.REMOVE_CUSTOMER_IDS]: (state, payload) => {
    state.reflectionIds = payload
  }
}
const actions = {
  async fetchCustomersByAccount({ commit, state }, { account, subAccount }) {
    state.isLoading = true
    try {
      const customers = await CustomerService.getByAccount({
        account,
        subAccount
      })
      const paginatedResponse = new PaginatedResponse(customers)
      while (paginatedResponse.hasMore) {
        await paginatedResponse.next()
      }
      commit(MUTATION_TYPES.SET_CUSTOMER_IDS, paginatedResponse.items)
      return Promise.resolve(paginatedResponse.items)
    } catch (error) {
      Message({
        message: error.message,
        type: 'error'
      })
    } finally {
      state.isLoading = false
    }
  },

  clearReflectionIds({ commit }) {
    commit(MUTATION_TYPES.REMOVE_CUSTOMER_IDS, [])
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
