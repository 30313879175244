<template>
  <vue-final-modal
    ref="myForm"
    v-model="isDialogOpen"
    :resize="true"
    :styles="styleModal"
    :content-style="contentStyleModal"
    :lock-scroll="false"
    :hide-overlay="false"
    :click-to-close="false"
    :esc-to-close="true"
    :z-index-base="1000000000"
    :z-index="false"
    :focus-retain="true"
    :fit-parent="true"
    :drag="true"
    drag-selector=".el-dialog__header"
    :keep-changed-style="false"
    :resize-directions="['t', 'tr', 'r', 'br', 'b', 'bl', 'l', 'tl']"
    @resize:move="handleResize"
  >
    <div class="el-dialog__header">
      <span class="el-dialog__title">{{ $t('chatInterface') }}</span>
      <button type="button" aria-label="Close" class="el-dialog__headerbtn">
        <i class="el-dialog__close el-icon el-icon-close" @click="handleDialogClose" />
      </button>
    </div>
    <slot name="ChatAssistantDefaults" :width="modalWidth" :height="modalHeight" />
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
export default {
  name: 'ResizableModal',
  components: {
    VueFinalModal
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentWidth: 340,
      currentHeight: 380
    }
  },
  computed: {
    modalWidth() {
      return this.currentWidth
    },
    modalHeight() {
      return this.currentHeight
    },
    styleModal() {
      return {
        display: 'flex',
        'justify-content': 'center',
        height: '100vh',
        'align-items': 'center'
      }
    },
    contentStyleModal() {
      return {
        padding: '20px !important',
        border: '2px solid #e2e8f0',
        'border-radius': '0.25rem',
        background: '#fff',
        color: '#606266',
        'font-size': '14px',
        'word-break': 'break-all',
        'box-sizing': 'border-box',
        position: 'relative',
        'max-width': '100vw',
        'min-height': '424px',
        'max-height': '100vh',
        'min-width': '360px'
      }
    }
  },
  methods: {
    handleDialogClose() {
      this.$emit('updateIsDialogOpen', false)
    },
    handleResize() {
      this.currentWidth = this.$refs.myForm.$refs.vfmContent.offsetWidth - 20
      this.currentHeight = this.$refs.myForm.$refs.vfmContent.offsetHeight - 40
    }
  }
}
</script>
<style scoped>
::v-deep.el-dialog {
  max-width: 710px;
  min-width: 340px;
}
@media (max-width: 750px) {
  ::v-deep.el-dialog {
    width: 360px;
  }
}
.el-dialog__header {
  cursor: grab;
}
</style>
