import { BrevoTransactionItemList } from '@/models/brevoTransaction'

export default class BrevoService {
  static async fetchListInSteps({ url, params, responseKey }) {
    let list = []
    let count = 0
    const transactionItems = new BrevoTransactionItemList({
      url,
      responseKey,
      options: params
    })
    while (transactionItems.hasMore) {
      const response = await transactionItems.next()
      list = [...list, ...response?.data?.[responseKey]]
      count = response?.data?.count
    }

    return { list, count }
  }

  static async fetchContactsOfSendList({
    id,
    options = {
      sort: 'desc',
      limit: 50,
      offset: 0
    }
  }) {
    const url = `contacts/lists/${id}/contacts`
    const params = options
    const contactsData = await BrevoService.fetchListInSteps({
      url,
      params,
      responseKey: 'contacts'
    })
    const { list: contacts, count } = contactsData
    return { contacts, count }
  }

  static async fetchEmailTemplates({ sort = 'desc', limit = 50, offset = 0 }) {
    const url = `smtp/templates`
    const params = { limit, offset, sort }
    const templatesData = await BrevoService.fetchListInSteps({
      url,
      params,
      responseKey: 'templates'
    })
    const { list: templates, count } = templatesData

    return { templates, count }
  }

  static async fetchAllSenders({ sort = 'desc', limit = 50, offset = 0 }) {
    const url = `senders`
    const params = { limit, offset, sort }
    const SenderData = await BrevoService.fetchListInSteps({
      url,
      params,
      responseKey: 'senders'
    })
    const { list: senders } = SenderData
    return senders
  }
}
