import Store from '@/models/odataStore'
const siteIdColumn = 'SiteID'

export const addEditColumns = [
  'FirstName',
  'LastName',
  'EMail',
  'PassWord',
  'UID',
  'Account',
  'ExtID',
  'LastLoginDate',
  'Company',
  'SubAccount',
  'AddedDate',
  siteIdColumn
].map(field => ({
  field,
  width: '100%',
  alignment: 'center',
  ...(field === siteIdColumn && {
    label: 'site',
    lookup: {
      store: new Store({ resource: 'Sites' }),
      paginate: true,
      pageSize: 50
    },
    valueExpr: 'ID',
    displayExpr: 'sSiteURL'
  })
}))

export const columns = [
  { field: 'ID', width: '100%', alignment: 'center' },
  ...addEditColumns
]
