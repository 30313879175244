const REPORT_RESULT_STATUS = {
  invalidEmails: 'invalidEmails',
  newCustomers: 'newCustomers',
  updatedCustomers: 'updatedCustomers',
  reactivatedCustomers: 'reactivatedCustomers',
  inactivatedCustomers: 'inactivatedCustomers',
  nonexistingCustomers: 'nonexistingCustomers',
  alreadyInactivatedCustomers: 'alreadyInactivatedCustomers',
  notUpdatedUsers: 'notUpdatedUsers'
}

export class Report {
  emails;

  extIds;

  status;

  totalEmails;

  users;
  constructor({ status, users = [] }) {
    const emails = users.map(user => user.EMail || user)
    const extIds = users.map(user => user.ExtID || user)
    this.status = status
    this.emails = emails
    this.extIds = extIds
    this.totalEmails = emails.length
    this.users = users
  }

  get isNewImport() {
    return this.status === REPORT_RESULT_STATUS.newCustomers
  }
  get isExistingCustomerImport() {
    return this.status === REPORT_RESULT_STATUS.updatedCustomers
  }

  static generate(result) {
    return Object.entries(REPORT_RESULT_STATUS)
      .map(
        ([field, status]) => new Report({ status, users: result[field] || [] })
      )
      .filter(report => !!report.emails.length)
  }

  get isAllowedToAssignCourseCampaign() {
    return this.isNewImport || this.isExistingCustomerImport
  }

  static getUsersToAssignCourseCampaignFromReports(reports = []) {
    return reports.flatMap(report =>
      report.isAllowedToAssignCourseCampaign ? report.users : []
    )
  }

  static getUpdateSendListUsers(reports = []) {
    return reports.flatMap(report => report.users)
  }
}
