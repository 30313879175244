var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ODataTable", {
    ref: "oDataTableComponentRef",
    attrs: {
      columns: _vm.columns,
      "add-edit-columns": _vm.addEditColumns,
      resource: "Tag",
      "total-label": _vm.$t("tags.total"),
      "file-name": _vm.$t("tags.fileName"),
      "add-row-button-label": _vm.$t("tags.add"),
      title: _vm.$t("tags.title"),
      "should-show-delete-button": false
    },
    on: {
      rowInit: _vm.handleRowInit,
      editorPreparing: _vm.handleEditorPreparing,
      rowInserted: _vm.handleRowInserted
    },
    scopedSlots: _vm._u([
      {
        key: "custom-buttons",
        fn: function() {
          return [
            _c("DxButton", {
              attrs: { icon: "trash", "on-click": _vm.handleDelete }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }