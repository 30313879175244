var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ODataTable", {
    ref: "oDataTableComponentRef",
    attrs: {
      columns: _vm.columns.all,
      "add-edit-columns": _vm.columns.addEdit,
      resource: "MediaByCategory",
      "total-label": _vm.$t("mediasByCategory.total"),
      "file-name": _vm.$t("mediasByCategory.fileName"),
      "add-row-button-label": _vm.$t(_vm.addRowButtonLabel),
      title: _vm.$t(_vm.title),
      filter: _vm.filter,
      "has-parent": !_vm.shouldShowTitle,
      "should-show-add-button": _vm.shouldShowAddButton,
      "should-show-edit-button": _vm.shouldShowEditButton,
      "should-show-delete-button": _vm.shouldShowDeleteButton
    },
    on: { rowInit: _vm.handleRowInit },
    scopedSlots: _vm._u(
      [
        {
          key: "toggle-button",
          fn: function(ref) {
            var data = ref.data
            return [_vm._t("toggle-button", null, { data: data })]
          }
        },
        _vm.shouldShowToggleButton
          ? {
              key: "custom-buttons",
              fn: function() {
                return [
                  _c("DxButton", {
                    attrs: { template: "custom-button-template" }
                  })
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }