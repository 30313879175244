import { DateUtils } from '@/utils/date'

export const disabledColumns = ['AddedDate']
export const createColumns = () => {
  const addEdit = [
    {
      field: 'Name',
      width: 200,
      minWidth: 150,
      label: 'Name'
    },
    {
      field: 'Rating',
      width: 200,
      minWidth: 150,
      label: 'Rating',
      dataType: 'number'
    },
    {
      field: 'IsActive',
      width: 200,
      minWidth: 150,
      label: 'isActive',
      dataType: 'boolean'
    },
    {
      field: 'SortOrder',
      width: 200,
      minWidth: 150,
      label: 'SortOrder',
      dataType: 'number'
    },
    {
      field: 'QuestionText',
      width: 200,
      minWidth: 150,
      label: 'QuestionText',
      editorName: 'dxTextArea',
      editorOptions: {
        minHeight: '250px',
        autoResizeEnabled: true
      }
    },
    {
      field: 'DefaultAnswer',
      width: 200,
      minWidth: 150,
      label: 'DefaultAnswer',
      editorName: 'dxTextArea',
      editorOptions: {
        minHeight: '250px',
        autoResizeEnabled: true
      }
    },
    {
      field: disabledColumns[0],
      width: 200,
      minWidth: 150,
      label: 'AddedDate',
      format: DateUtils.odataDateFormat
    }
  ]
  return {
    addEdit,
    all: [
      {
        field: 'ID',
        minWidth: 150,
        width: 200
      },
      ...addEdit
    ]
  }
}
