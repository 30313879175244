var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container py-none" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c(
                "el-form",
                {
                  ref: "formRef",
                  attrs: {
                    "label-position": "top",
                    size: "small",
                    model: _vm.formModal,
                    rules: _vm.rules
                  }
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "m-container" },
                    _vm._l(_vm.formModal.campaignData, function(
                      campaign,
                      index
                    ) {
                      return _c(
                        "el-col",
                        {
                          key: index,
                          staticClass: "column m-border bg-gray-light",
                          attrs: { xs: 24, sm: 16 }
                        },
                        [
                          _c(
                            "el-row",
                            { staticClass: "center", attrs: { type: "flex" } },
                            [
                              _c(
                                "el-col",
                                { staticClass: "m-title", attrs: { span: 8 } },
                                [_vm._v(_vm._s(_vm.$t("name")) + " :")]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 16 } },
                                [
                                  _c(
                                    "el-row",
                                    {
                                      staticClass: "center gap-md",
                                      attrs: { type: "flex" }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(campaign.name) + " :")
                                      ]),
                                      _c("span", { staticClass: "primary" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.courseCampaign
                                              .formattedAddedDate
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "center", attrs: { type: "flex" } },
                            [
                              _c(
                                "el-col",
                                { staticClass: "m-title", attrs: { span: 8 } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "courseCampaign.specifyDetails.account"
                                      )
                                    ) + " :"
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { staticClass: "primary", attrs: { span: 16 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "customerAccount" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            placeholder: _vm.$t(
                                              "courseCampaign.choosePrograms.accountPlaceholder"
                                            ),
                                            size: "medium"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.handleCustomerAccount(
                                                $event,
                                                index
                                              )
                                            }
                                          },
                                          model: {
                                            value: campaign.customerAccount,
                                            callback: function($$v) {
                                              _vm.$set(
                                                campaign,
                                                "customerAccount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "campaign.customerAccount"
                                          }
                                        },
                                        _vm._l(_vm.customerAccounts, function(
                                          item
                                        ) {
                                          return _c("el-option", {
                                            key: item.ID,
                                            attrs: {
                                              label: item.Account,
                                              value: item.ID
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "center", attrs: { type: "flex" } },
                            [
                              _c(
                                "el-col",
                                { staticClass: "m-title", attrs: { span: 8 } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "courseCampaign.specifyDetails.totalContacts"
                                      )
                                    ) + " :"
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { staticClass: "primary", attrs: { span: 16 } },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.isLoading,
                                          expression: "isLoading"
                                        }
                                      ],
                                      staticClass: "loader-box"
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(campaign.recipients) + " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "center", attrs: { type: "flex" } },
                            [
                              _c(
                                "el-col",
                                { staticClass: "m-title", attrs: { span: 8 } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "courseCampaign.specifyDetails.sendList"
                                      )
                                    ) + " :"
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { staticClass: "primary", attrs: { span: 16 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "campaignData." +
                                          index +
                                          ".sendListId"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "send-list",
                                          attrs: {
                                            filterable: "",
                                            loading: !_vm.sendLists.length,
                                            placeholder: _vm.$t(
                                              "updateSendList.selectSendList"
                                            ),
                                            size: "medium"
                                          },
                                          on: {
                                            change: function(sendListId) {
                                              return _vm.handleRecipients(
                                                sendListId,
                                                index
                                              )
                                            }
                                          },
                                          model: {
                                            value: campaign.sendListId,
                                            callback: function($$v) {
                                              _vm.$set(
                                                campaign,
                                                "sendListId",
                                                $$v
                                              )
                                            },
                                            expression: "campaign.sendListId"
                                          }
                                        },
                                        _vm._l(_vm.sendLists, function(item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.name,
                                              value: item.id
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "center", attrs: { type: "flex" } },
                            [
                              _c(
                                "el-col",
                                { staticClass: "m-title", attrs: { span: 8 } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "courseCampaign.specifyDetails.campaignDefaults"
                                      )
                                    ) + " :"
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { staticClass: "primary", attrs: { span: 16 } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "send-list",
                                      attrs: {
                                        filterable: "",
                                        loading: !_vm.campaignDefaultList,
                                        placeholder: _vm.$t(
                                          "courseCampaign.specifyDetails.campaignDefaults"
                                        ),
                                        size: "medium"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.handleCampaignDefault(
                                            $event,
                                            index
                                          )
                                        }
                                      },
                                      model: {
                                        value: campaign.campaignDefault,
                                        callback: function($$v) {
                                          _vm.$set(
                                            campaign,
                                            "campaignDefault",
                                            $$v
                                          )
                                        },
                                        expression: "campaign.campaignDefault"
                                      }
                                    },
                                    _vm._l(_vm.campaignDefaultList, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: item.ID,
                                        attrs: {
                                          label: item.CampaignName,
                                          value: item.ID
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "center", attrs: { type: "flex" } },
                            [
                              _c(
                                "el-col",
                                { staticClass: "m-title", attrs: { span: 8 } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "courseCampaign.specifyDetails.email"
                                      )
                                    ) + " :"
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { staticClass: "primary", attrs: { span: 16 } },
                                [
                                  _c("span", { staticClass: "m-title" }, [
                                    _vm._v(_vm._s(index + 1) + ".")
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { type: "flex" } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: campaign.specifyReceiver,
                                    callback: function($$v) {
                                      _vm.$set(campaign, "specifyReceiver", $$v)
                                    },
                                    expression: "campaign.specifyReceiver"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "courseCampaign.specifyDetails.specifyToField"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("el-row", { staticClass: "mx-title" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("courseCampaign.specifyDetails.receiver")
                              ) + " :"
                            )
                          ]),
                          _c(
                            "el-row",
                            { attrs: { gutter: 15 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { model: campaign.sender } },
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "paddingField",
                                              attrs: { span: 12 }
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass: "mb-none",
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "courseCampaign.specifyDetails.details.name"
                                                    ),
                                                    prop:
                                                      "campaignData." +
                                                      index +
                                                      ".sender.name"
                                                  }
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder: _vm.$t(
                                                        "courseCampaign.specifyDetails.details.name"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        campaign.sender.name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          campaign.sender,
                                                          "name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "campaign.sender.name"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "paddingFieldl",
                                              attrs: { span: 12 }
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass: "mb-none",
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "courseCampaign.specifyDetails.details.email"
                                                    ),
                                                    prop:
                                                      "campaignData." +
                                                      index +
                                                      ".sender.email"
                                                  }
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      type: "email",
                                                      placeholder: _vm.$t(
                                                        "courseCampaign.specifyDetails.details.email"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        campaign.sender.email,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          campaign.sender,
                                                          "email",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "campaign.sender.email"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("el-row", { staticClass: "m-title" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("courseCampaign.specifyDetails.content")
                              ) + " : "
                            )
                          ]),
                          _c(
                            "el-row",
                            { attrs: { gutter: 15 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb-none",
                                      attrs: {
                                        label: _vm.$t(
                                          "courseCampaign.specifyDetails.details.subject"
                                        ),
                                        prop:
                                          "campaignData." + index + ".subject"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "courseCampaign.specifyDetails.details.subject"
                                          )
                                        },
                                        model: {
                                          value: campaign.subject,
                                          callback: function($$v) {
                                            _vm.$set(campaign, "subject", $$v)
                                          },
                                          expression: "campaign.subject"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb-none",
                                      attrs: {
                                        label: _vm.$t(
                                          "courseCampaign.specifyDetails.details.previewText"
                                        ),
                                        prop: "previewText"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          size: "medium",
                                          placeholder: _vm.$t(
                                            "courseCampaign.specifyDetails.details.previewText"
                                          )
                                        },
                                        model: {
                                          value: campaign.previewText,
                                          callback: function($$v) {
                                            _vm.$set(
                                              campaign,
                                              "previewText",
                                              $$v
                                            )
                                          },
                                          expression: "campaign.previewText"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 15 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb-none",
                                      attrs: {
                                        label: _vm.$t(
                                          "courseCampaign.specifyDetails.details.ePostmail"
                                        ),
                                        prop:
                                          "campaignData." +
                                          index +
                                          ".templateId"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "send-list",
                                          attrs: {
                                            filterable: "",
                                            loading: !_vm.emailTemplates.length,
                                            placeholder: _vm.$t(
                                              "courseCampaign.specifyDetails.details.ePostmail"
                                            ),
                                            size: "medium"
                                          },
                                          model: {
                                            value: campaign.templateId,
                                            callback: function($$v) {
                                              _vm.$set(
                                                campaign,
                                                "templateId",
                                                $$v
                                              )
                                            },
                                            expression: "campaign.templateId"
                                          }
                                        },
                                        _vm._l(_vm.emailTemplates, function(
                                          item
                                        ) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.name,
                                              value: item.id
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 15 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb-none",
                                      attrs: {
                                        label: _vm.$t(
                                          "courseCampaign.specifyDetails.details.introText"
                                        ),
                                        prop: "introText"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          rows: 4,
                                          type: "textarea",
                                          placeholder: _vm.$t(
                                            "courseCampaign.specifyDetails.details.introText"
                                          )
                                        },
                                        model: {
                                          value: campaign.introText,
                                          callback: function($$v) {
                                            _vm.$set(campaign, "introText", $$v)
                                          },
                                          expression: "campaign.introText"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            {
                              staticClass: "action-box",
                              attrs: { gutter: 15 }
                            },
                            [
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "div",
                                  { staticClass: "campaign-action-btns" },
                                  [
                                    index ===
                                    _vm.formModal.campaignData.length - 1
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "box-item",
                                            attrs: {
                                              effect: "dark",
                                              content: "Add new email",
                                              placement: "top-start"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "justify-center",
                                                attrs: {
                                                  type: "primary",
                                                  size: "medium"
                                                },
                                                on: {
                                                  click: _vm.addNewCampaign
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-plus fw-900"
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "box-item",
                                        attrs: {
                                          effect: "dark",
                                          content: "Delete email",
                                          placement: "top-start"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "justify-center",
                                            attrs: {
                                              type: "danger",
                                              size: "medium",
                                              disabled:
                                                _vm.formModal.campaignData
                                                  .length === 1
                                            },
                                            on: {
                                              click: function() {
                                                return _vm.deleteCampaign(index)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-delete fw-900"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }