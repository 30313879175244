export class Answer {
  createdAt;

  feedback;

  id;

  isActive;

  isCorrect;

  order;

  questionId;

  response;
  constructor({
    isCorrect = false,
    order = 0,
    response = '',
    feedback = '',
    createdAt = new Date(),
    isActive = true,
    questionId = '',
    id = ''
  }) {
    this.isCorrect = isCorrect
    this.order = order
    this.response = response
    this.feedback = feedback
    this.createdAt = createdAt
    this.isActive = isActive
    this.questionId = questionId
    this.id = id
  }
  get isProvided() {
    return !!this.response
  }
  static fromList(answers = []) {
    return Answer.sort(answers.map(answer => new Answer(answer)))
  }

  setCorrect(isCorrect) {
    this.isCorrect = isCorrect
  }

  updatePartial(updateParams) {
    Object.assign(this, updateParams)
  }
  get payload() {
    return {
      IsActive: this.isActive,
      bIsCorrect: this.isCorrect,
      DateAdded: this.createdAt,
      tTestQuestionID: this.questionId,
      Name: this.response,
      AnswerResponseText: this.feedback,
      SortOrder: this.order
    }
  }

  static fromApi(answer) {
    return new Answer({
      id: answer.ID,
      response: answer.Name,
      isActive: answer.IsActive,
      feedback: answer.AnswerResponseText,
      questionId: answer.tTestQuestionID,
      order: answer.SortOrder,
      createdAt: answer.DateAdded,
      isCorrect: answer.bIsCorrect
    })
  }
  static fromApiList(answers = []) {
    return Answer.sort(answers.map(answer => this.fromApi(answer)))
  }

  static sort(answers) {
    return answers.sort(
      (answer, nextAnswer) => answer.order - nextAnswer.order
    )
  }
}
