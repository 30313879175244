import { DateUtils } from '@/utils/date'

export class CourseCampaign {
  dateAdded;

  deadline;

  episodes;

  filterUsed;

  name;

  preview;

  programId;

  senderEmail;

  senderName;

  siteId;

  subject;

  emailTemplateId;

  customerAccount;

  totalRecipient;

  sendList;

  campaignDefault;

  introText;

  courseCampaigns;

  constructor({
    name = '',
    dateAdded = new Date().toISOString(),
    deadline = '',
    siteId = '',
    programId = '',
    senderName = '',
    senderEmail = '',
    subject = '',
    preview = '',
    episodes = [],
    filterUsed = '',
    templateId = '',
    customerAccount = '',
    totalRecipient = 0,
    sendList = '',
    campaignDefault = '',
    introText = '',
    courseCampaigns = []
  } = {}) {
    this.name = name
    this.dateAdded = dateAdded
    this.deadline = deadline
    this.siteId = siteId
    this.programId = programId
    this.episodes = episodes
    this.senderName = senderName
    this.senderEmail = senderEmail
    this.subject = subject
    this.preview = preview
    this.filterUsed = filterUsed
    this.templateId = templateId
    this.customerAccount = customerAccount
    this.totalRecipient = totalRecipient
    this.sendList = sendList
    this.campaignDefault = campaignDefault
    this.introText = introText

    this.courseCampaigns = courseCampaigns
  }

  get formattedAddedDate() {
    return DateUtils.formatToShortDate(this.dateAdded)
  }

  getRefreshedEpisodes(newEpisodes = []) {
    const currentProgramExcludingEpisodes = this.episodes.filter(
      episode => episode.CategoryID !== this.programId
    )
    const mergedEpisodes = [
      ...currentProgramExcludingEpisodes,
      ...newEpisodes.map(newEpisode => ({
        ...newEpisode,
        CategoryID: this.programId,
        SiteID: this.siteId
      }))
    ]
    return mergedEpisodes.reduce(
      (uniqueEpisodes, episode) => [
        ...uniqueEpisodes,
        ...(uniqueEpisodes.find(
          uniqueEpisode => uniqueEpisode.ID === episode.ID
        )
          ? []
          : [episode])
      ],
      []
    )
  }
  createAssignEpisodesParams(customers = []) {
    return customers.reduce(
      (assignedEpisodes, customer) => [
        ...assignedEpisodes,

        ...this.episodes.map(episode => ({
          SiteID: episode.SiteID,
          MediaID: episode.ID,
          CategoryID: episode.CategoryID,

          CustomerID: customer.ID,
          ExpiryDate: this.deadline,
          WasAdded: true,
          AlreadyExisted: true
        }))
      ],
      []
    )
  }

  createAddParams() {
    return {
      Name: this.name,
      DateAdded: this.dateAdded,
      SendAtDate: this.dateAdded,
      FromName: this.senderName,
      FromEmail: this.senderEmail,
      Subject: this.subject,
      PreviewText: this.preview,
      DeadLine: this.deadline,
      FilterUsed: this.filterUsed,
      EmailTemplateId: this.emailTemplateId,
      CustomerAccount: this.customerAccount,
      TotalRecipient: this.totalRecipient,
      SendList: this.sendList,
      CampaignDefault: this.campaignDefault,
      IntroText: this.introText,
      Provider: this.provider
    }
  }

  createCourseCampaign() {
    return (
      this.courseCampaigns.map(campaign => ({
        recipients: {
          listIds: [campaign.sendListId]
        },
        name: campaign.name,
        previewText: campaign.previewText,
        subject: campaign.subject,
        sender: campaign.sender,
        templateId: campaign?.templateId?.id ?? campaign?.templateId,
        params: {
          Ingress: campaign.introText
        }
      })) || []
    )
  }

  handleDefaultFutureDate() {
    return (
      this.courseCampaigns.map(campaign => ({
        ...campaign,
        scheduledDate: campaign.scheduledDate || this.getFutureDate
      })) || []
    )
  }

  createCampaignTemplate() {
    return {
      name: null,
      customerAccount: null,
      specifyReceiver: true,
      sendListId: null,
      campaignDefault: null,
      recipients: 0,
      templateId: null,
      subject: null,
      previewText: null,
      sender: {
        name: null,
        email: null
      },
      introText: null
    }
  }

  handleFailedCampaigns(failedIndices) {
    this.courseCampaigns = this.courseCampaigns.filter((_, index) => {
      return failedIndices.includes(index)
    })
  }

  updatePartial(params) {
    Object.assign(this, params)
  }
}
