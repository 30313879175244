var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      key: _vm.dialogVisible,
      attrs: {
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: "75%",
        "before-close": _vm.handleDialogClose
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        opened: _vm.handleDialogOpen
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "formRef",
          attrs: {
            model: _vm.contentPart,
            rules: _vm.rules,
            "label-position": "top",
            size: "small"
          }
        },
        [
          _c("content-part-form", {
            attrs: { "content-part": _vm.contentPart },
            on: {
              "update:contentPart": function($event) {
                _vm.contentPart = $event
              },
              "update:content-part": function($event) {
                _vm.contentPart = $event
              }
            }
          }),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.isEditorLoading,
                      expression: "isEditorLoading"
                    }
                  ],
                  attrs: {
                    "element-loading-text": _vm.editorLoadingMessage,
                    label: _vm.$t("contentPart.mainContent"),
                    prop: "MainContent"
                  }
                },
                [
                  _c("editor", {
                    attrs: {
                      "api-key": _vm.TINYMCE_API_KEY,
                      init: _vm.tinyMCEConfig,
                      disabled: _vm.dialogBoxConfig.disableEditor
                    },
                    on: {
                      onInit: _vm.handleEditorActive,
                      onDrop: _vm.handleDrop
                    },
                    model: {
                      value: _vm.contentPart.MainContent,
                      callback: function($$v) {
                        _vm.$set(_vm.contentPart, "MainContent", $$v)
                      },
                      expression: "contentPart.MainContent"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleDialogClose } }, [
            _vm._v(_vm._s(_vm.$t("cancel")))
          ]),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading.fullscreen.lock",
                  value: _vm.isContentPartDialogLoading,
                  expression: "isContentPartDialogLoading",
                  modifiers: { fullscreen: true, lock: true }
                }
              ],
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.onSubmit("formRef")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("save")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }