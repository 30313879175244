import { DateUtils } from '@/utils/date'

export class File {
  CreationTime;

  Fullname;

  LastAccessTime;

  Length;

  UploadedDate;

  UploadedUserID;

  constructor(file) {
    Object.assign(this, file)
    this.Fullname = file.Fullname.split('\\').join('\\\\')
    if (this.UploadedDate === '0001-01-01T00:00:00') {
      this.UploadedDate = null
    }
  }

  get formattedCreationTime() {
    return DateUtils.formatToShortDateTime(this.CreationTime)
  }

  get formattedLastAccessTime() {
    return DateUtils.formatToShortDateTime(this.LastAccessTime)
  }

  get sizeInBytes() {
    return Number(this.Length)
  }

  static fromList(files = []) {
    return this.sort(files.map(file => new File(file)))
  }

  get asHierarchy() {
    return {
      name: this.Fullname,
      isDirectory: false,
      size: this.Length,
      uploadedUserId: this.UploadedUserID
    }
  }

  static createFileParams({ name, size }) {
    return {
      CreationTime: new Date(),
      Fullname: name,
      LastAccessTime: new Date(),
      Length: size
    }
  }

  static sort(files = []) {
    return files.sort(
      (firstFile, secondFile) =>
        new Date(secondFile.CreationTime) - new Date(firstFile.CreationTime)
    )
  }
}
