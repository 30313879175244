<template>
  <div>
    <ODataTable
      ref="oDataTableComponentRef"
      :columns="columns.all"
      :add-edit-columns="columns.addEdit"
      resource="tDisabledMediaException"
      :total-label="$t('disabledMediaExceptions.total')"
      :file-name="$t('disabledMediaExceptions.fileName')"
      :add-row-button-label="$t('disabledMediaExceptions.add')"
      :title="$t('disabledMediaExceptions.title')"
      @rowInit="handleRowInit"
      @editorPreparing="handleEditorPreparing"
    />
  </div>
</template>
<script>
import ODataTable from '../../components/ODataTable'
import { createColumns, disabledColumns } from './config'

export default {
  name: 'DisabledMediaExceptions',
  components: {
    ODataTable
  },
  computed: {
    columns() {
      return createColumns()
    }
  },
  methods: {
    handleRowInit(event) {
      event.data.AddedDate = new Date().toISOString()
    },
    handleEditorPreparing(event) {
      if (event.isOnForm) {
        event.editorOptions.disabled = disabledColumns.includes(event.dataField)
      }
    }
  }
}
</script>
