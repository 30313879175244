import { fectchResource } from '@/utils/httpRequests'

const login = data =>
  fectchResource(
    `${process.env.VUE_APP_CONTENTPART_API}/loginuser/${data.username}/${data.password}/1`
  )

const getInfo = () =>
  fectchResource(`${process.env.VUE_APP_CONTENTPART_API}/me`)

const logout = () =>
  fectchResource(`${process.env.VUE_APP_CONTENTPART_API}/logout`)

const verify = data => {
  return fectchResource(
    `${process.env.VUE_APP_CONTENTPART_API}/loginuserA/${data.username}/${data.password}/${data.loginCode}/1`
  )
}

const sendForgotPasswordLink = email => {
  return fectchResource(
    `${process.env.VUE_APP_CONTENTPART_API}/sendreminder/${email}/${process.env.VUE_APP_SEND_PASSWORD_SITE_ID}`
  )
}

export { login, getInfo, logout, verify, sendForgotPasswordLink }
