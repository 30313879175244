const activeField = 'IsActive'
export const disabledColumns = ['ID']
export const fileTypeColumns = ['sLogoURL']
export const toggleColumns = [activeField, 'UseSSO', 'AllowSSO']
export const requiredColumns = ['SiteName']
export const SITES = {
  FOLDER: 'site'
}
export const createColumns = siteId => {
  const columns = [
    ...disabledColumns,
    ...requiredColumns,
    'sSiteURL',
    ...fileTypeColumns,
    ...toggleColumns
  ].map(field => ({
    field,
    width: '100%',
    ...(disabledColumns.includes(field) && { label: 'SiteID' }),
    ...(fileTypeColumns.includes(field) && {
      editCellTemplate: 'file-item',
      type: SITES.FOLDER
    }),
    ...(toggleColumns.includes(field) && {
      editCellTemplate: 'checkbox-item',
      ...(field === activeField && { disabledIds: [siteId] })
    }),
    ...(requiredColumns.includes(field) && {
      required: true,
      requiredMessage: 'sites.column.siteName.requiredMessage'
    })
  }))

  return columns
}
