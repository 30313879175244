var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ODataTable", {
    attrs: {
      columns: _vm.columns.all,
      "add-edit-columns": _vm.columns.addEdit,
      "custom-delete-message": _vm.$t("assistants.tools.confirmDeleteMessage"),
      "total-label": _vm.$t("assistants.tools.total"),
      title: _vm.$t("assistants.tools.title"),
      "is-static-data-source": true,
      "static-data": _vm.tools,
      "add-row-button-label": _vm.$t("assistants.tools.create"),
      "is-custom-form": true,
      "should-show-add-button": _vm.canAdd,
      "column-count": 2
    },
    on: { editorPreparing: _vm.handleEditorPreparing },
    scopedSlots: _vm._u([
      {
        key: "custom-form",
        fn: function() {
          return [
            _vm._l(_vm.columns.types, function(column) {
              return _c("DxSimpleItem", {
                key: "add_edit_" + column.field,
                attrs: { "data-field": column.field }
              })
            }),
            _vm.isFunctionType
              ? _c(
                  "DxGroupItem",
                  {
                    attrs: {
                      column: "function",
                      caption: _vm.$t("assistants.tools.functionDetails")
                    }
                  },
                  _vm._l(_vm.columns.functions, function(column) {
                    return _c("DxSimpleItem", {
                      key: "add_edit_" + column.field,
                      attrs: {
                        "data-field": column.field,
                        "editor-type": column.editorName,
                        "editor-options": column.editorOptions
                      }
                    })
                  }),
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }