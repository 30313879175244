export const createColumns = ({ medias, sites }) => {
  const fieldLookupConfig = {
    SiteID: {
      label: 'Site',
      lookup: {
        store: sites,
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'ID',
      displayExpr: 'Name'
    },
    MediaID: {
      label: 'Media',
      lookup: {
        store: medias,
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'ID',
      displayExpr: 'Title'
    }
  }

  const addEdit = [
    'UserID',
    'MediaID',
    'SiteID',
    'AddedDate',
    'GoalDate',
    'GoalText',
    'UserReminded',
    'Completed'
  ].map(field => ({
    field,
    width: '100%',
    ...(field === 'SiteID' && fieldLookupConfig['SiteID']),
    ...(field === 'MediaID' && fieldLookupConfig['MediaID'])
  }))

  return {
    all: [
      {
        field: 'ID',
        width: '100%'
      },
      ...addEdit
    ],
    addEdit
  }
}
