<template>
  <el-container>
    <el-main class="dx-viewport">
      <MasterDetail :data="detail" :items="answersToKnowledgeTestData">
        <template #custom-item="{ item }">
          <div class="date-container">
            {{ formatDate(item.editorOptions.value) }}
          </div>
        </template>
      </MasterDetail>
    </el-main>
  </el-container>
</template>

<script>
import MasterDetail from '../../../components/masterDetail'
import { DateUtils } from '@/utils/date'
import { detailsColumns, dateColumnsName } from '../config'
export default {
  name: 'Detail',
  components: { MasterDetail },
  props: {
    detail: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      answersToKnowledgeTestData: [
        ...detailsColumns
      ].map((dataField) => ({ dataField, ...(dateColumnsName.includes(dataField) && { template: 'custom-item' }) }))
    }
  },
  methods: {
    formatDate(date) {
      return DateUtils.formatToShortDateTime(date)
    }
  }
}
</script>
