var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            { staticClass: "py-none" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { sm: 18, xs: 24 } },
                    _vm._l(_vm.courseCampaigns, function(
                      courseCampaign,
                      index
                    ) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "m-border-dark bg-gray-light"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "bg-gray-dark m-border-dark px-3 bottom-plane"
                            },
                            [
                              _c(
                                "el-row",
                                {
                                  staticClass: "center py-3",
                                  attrs: { type: "flex" }
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "m-title text-white",
                                      attrs: { span: 4 }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "courseCampaign.overview.details.email"
                                          ) +
                                            " " +
                                            (index + 1)
                                        ) + ". "
                                      )
                                    ]
                                  ),
                                  _c("el-col", { attrs: { span: 20 } })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "px-3" },
                            [
                              _c(
                                "el-row",
                                {
                                  staticClass: "center py-3",
                                  attrs: { type: "flex" }
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "m-title",
                                      attrs: { span: 4 }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "courseCampaign.overview.details.name"
                                          )
                                        ) + " :"
                                      )
                                    ]
                                  ),
                                  _c("el-col", { attrs: { span: 20 } }, [
                                    _vm._v(
                                      _vm._s(courseCampaign.name || null) + " "
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                {
                                  staticClass: "center py-3",
                                  attrs: { type: "flex" }
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "m-title",
                                      attrs: { span: 4 }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "courseCampaign.overview.details.recipients"
                                          )
                                        ) + " :"
                                      )
                                    ]
                                  ),
                                  _c("el-col", { attrs: { span: 20 } }, [
                                    _vm._v(
                                      _vm._s(
                                        courseCampaign.recipients || null
                                      ) + " "
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                {
                                  staticClass: "center py-3",
                                  attrs: { type: "flex" }
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "m-title",
                                      attrs: { span: 4 }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "courseCampaign.overview.details.ePostmail"
                                          )
                                        ) + " :"
                                      )
                                    ]
                                  ),
                                  _c("el-col", { attrs: { span: 20 } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getTemplate(
                                          courseCampaign.templateId
                                        )
                                      ) + " "
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                {
                                  staticClass: "center py-3",
                                  attrs: { type: "flex" }
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "m-title",
                                      attrs: { span: 4 }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "courseCampaign.overview.details.senderName"
                                          )
                                        ) + " :"
                                      )
                                    ]
                                  ),
                                  _c("el-col", { attrs: { span: 20 } }, [
                                    _vm._v(
                                      _vm._s(
                                        courseCampaign.sender.name || null
                                      ) + " "
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                {
                                  staticClass: "center py-3",
                                  attrs: { type: "flex" }
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "m-title",
                                      attrs: { span: 4 }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "courseCampaign.overview.details.senderAddress"
                                          )
                                        ) + " : "
                                      )
                                    ]
                                  ),
                                  _c("el-col", { attrs: { span: 20 } }, [
                                    _vm._v(
                                      _vm._s(
                                        courseCampaign.sender.email || null
                                      ) + " "
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                {
                                  staticClass: "center py-3",
                                  attrs: { type: "flex" }
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "m-title",
                                      attrs: { span: 4 }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "courseCampaign.overview.details.subject"
                                          )
                                        ) + " :"
                                      )
                                    ]
                                  ),
                                  _c("el-col", { attrs: { span: 20 } }, [
                                    _vm._v(
                                      _vm._s(courseCampaign.subject || null) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                {
                                  staticClass: "center py-3",
                                  attrs: { type: "flex" }
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "m-title",
                                      attrs: { span: 4 }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "courseCampaign.overview.details.previewText"
                                          )
                                        ) + " :"
                                      )
                                    ]
                                  ),
                                  _c("el-col", { attrs: { span: 20 } }, [
                                    _vm._v(
                                      _vm._s(
                                        courseCampaign.previewText || null
                                      ) + " "
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                {
                                  staticClass: "center py-3",
                                  attrs: { type: "flex" }
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "m-title",
                                      attrs: { span: 4 }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "courseCampaign.overview.details.introText"
                                          )
                                        ) + " :"
                                      )
                                    ]
                                  ),
                                  _c("el-col", { attrs: { span: 20 } }, [
                                    _vm._v(
                                      _vm._s(courseCampaign.introText || null) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }