<template>
  <div class="app-container">
    <el-dialog
      v-if="selectedReflection"
      :visible.sync="showDetails"
      @close="handleDetailsDialogClose"
    >
      <Detail :detail="{...selectedReflection, selectedCustomer: [...selectedCustomer]}" />
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span class="margin-bottom">{{ $t('reviewReflections.AdminComment') }}</span>
      <el-input
        v-model="comment"
        type="textarea"
        :rows="2"
        placeholder="Please Enter comment"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="dialogVisible = false">{{ $t('reviewReflections.cancel') }}</el-button>
        <el-button type="primary" @click="handleSaveClick">{{ $t('reviewReflections.save') }}</el-button>
      </span>
    </el-dialog>
    <ODataTable
      ref="oDataTableComponentRef"
      :key="filter"
      :columns="columns"
      :add-edit-columns="addEditColumns"
      resource="tTestCustomerAnswerPortal"
      :total-label="$t('reviewReflections.total')"
      :should-show-edit-button="true"
      :should-show-add-button="false"
      :should-show-delete-button="false"
      :file-name="$t('reviewReflections.fileName')"
      :add-row-button-label="$t('reviewReflections.add')"
      :title="$t('reviewReflections.title')"
      :filter="filter"
      :action-buttons-width="100"
      @rowInit="handleRowInit"
      @rowUpdating="handleRowUpdating"
      @selectionChange="handleSelectionChange"
      @editingStart="handleEditingStart"
    >
      <template #custom-buttons>
        <DxButton icon="check" :on-click="handleApprove" :visible="isPendingOrApproved" />
        <DxButton icon="remove" :on-click="handleRejected" :visible="isPendingOrRejected" />
        <DxButton icon="info" :on-click="handleViewClick" :visible="isApprovedOrRejected" />
      </template>
      <template #custom-toolbar-before>
        <div>
          <el-button v-if="selectedReflections.length" v-model="approveAll" :loading="isBulkApprovalLoading" type="primary" @click="handleApproveAll">{{ $t('reviewReflections.approveAll') }}</el-button>
          <el-button v-if="selectedReflections.length" v-model="rejectAll" :loading="isBulkRejectionLoading" type="danger" @click="handleRejectAll">{{ $t('reviewReflections.rejectAll') }}</el-button>
        </div>
      </template>
      <template #custom-toolbar-after>
        <DxProgressBar
          v-if="isLoading"
          :min="0"
          :max="100"
          :value="percentage"
          width="100%"
        />
      </template>
    </ODataTable>
  </div>
</template>
<script>
import mixin from './mixin'
export default {
  mixins: [mixin]
}
</script>
<style lang="scss" scoped>
::v-deep .dx-icon-remove::before {
  color: red !important;
}
::v-deep .el-button--primary.is-plain {
  margin: 10px
}
::v-deep .margin-bottom {
  margin-bottom: 20px !important;
  display: inline-block !important;
}

::v-deep .el-dialog__body{
  padding-bottom:  10px !important;
}

::v-deep .dx-datagrid .dx-link {
    &:first-child {
      color: rgb(103, 194, 58);
    }
  }

::v-deep .dx-scrollable-content > .dx-datagrid-content > table[role="presentation"] > tbody[role="presentation"] > tr[role="row"] > td[aria-colindex="3"] {
  display: inline-block;
  white-space: pre-line;
  overflow-y: auto;
  height: 75px !important;
  width: 100%;
  border-right: 1px solid #e0e0e0;
  }

::v-deep .dx-scrollable-content > .dx-datagrid-content > table[role="presentation"] > tbody[role="presentation"] > tr[role="row"] > td[aria-colindex="4"] {
  display: inline-block;
  width: 100%;
  white-space: pre-line;
  overflow-y: auto;
  height: 75px !important;
  }

  ::v-deep .dx-scrollable-content > .dx-datagrid-content > table[role="presentation"] > tbody[role="presentation"] > tr[role="row"] > td[aria-colindex="5"]{
    visibility: hidden !important;
    border: none;
  }

  ::v-deep .dx-scrollable-content > .dx-datagrid-content > table[role="presentation"] > tbody[role="presentation"] > tr[role="row"] > td[aria-colindex="2"] {
    border-top: 1px solid #e0e0e0;
  }

</style>
