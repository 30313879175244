import axios from 'axios'

export class BrevoTransactionItemList {
  url;
  items = [];
  totalCount = 1;
  itemsPerPage = 0;
  responseKey;
  options = {
    limit: 50,
    sort: 'desc',
    offset: 0
  };
  headers = {
    Accept: 'application/json',
    'api-key': process.env.VUE_APP_BREVO_API_KEY
  };
  constructor({ url = '', responseKey, options }) {
    this.url = url
    this.options = options
    this.responseKey = responseKey
  }

  get hasMore() {
    return this.itemsPerPage < this.totalCount
  }

  async next() {
    const response = await axios.get(
      `${process.env.VUE_APP_BREVO_API_BASE_URL}/${this.url}`,
      {
        params: { ...this.options, offset: this.itemsPerPage },
        headers: this.headers
      }
    )
    this.totalCount = response?.data?.count
    this.itemsPerPage += this.options?.limit
    return response
  }
}
