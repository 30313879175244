var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chat-assistant-container" }, [
    _c("div", { staticClass: "chat-assistants-grid" }, [
      _c("div", { staticClass: "assistant-item" }, [
        !_vm.assistantThreads.length && !_vm.sessionId
          ? _c("div", { staticClass: "empty-threads-message" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("noThreads")))])
            ])
          : _vm._e(),
        _vm.assistantThreads.length
          ? _c(
              "div",
              [
                !_vm.sessionId
                  ? _c(
                      "el-select",
                      {
                        staticClass: "threadDropDown",
                        attrs: {
                          value: _vm.selectedThreadId,
                          filterable: "",
                          placeholder: "Select Thread",
                          size: "large"
                        },
                        on: { change: _vm.handleChange }
                      },
                      _vm._l(_vm.threads, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.value, value: item.value }
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                _c("deep-chat", {
                  ref: "chat-history-ref",
                  staticClass: "deep-chat-bot",
                  attrs: {
                    directConnection: _vm.directConnection,
                    initialMessages: _vm.initialMessages,
                    demo: "true",
                    stream: "true",
                    gifs: '{"button": {"position": "dropup-menu"}}',
                    audio: '{"button": {"position": "dropup-menu"}}',
                    camera: '{"button": {"position": "dropup-menu"}}',
                    "mixed-files": '{"button": {"position": "inside-left"}}',
                    microphone: '{"button": {"position": "outside-right"}}'
                  },
                  on: { "new-message": _vm.handleNewMessage }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }