import { compareFilters } from '@/utils/list'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { exportDataGrid } from 'devextreme/excel_exporter'
import {
  DxDataGrid,
  DxFilterRow,
  DxEditing,
  DxExport,
  DxSelection,
  DxColumn,
  DxButton,
  DxMasterDetail,
  DxLookup,
  DxRequiredRule,
  DxForm,
  DxTexts,
  DxStateStoring,
  DxPager
} from 'devextreme-vue/data-grid'
import { DxSimpleItem, DxEmailRule } from 'devextreme-vue/form'

import FileUploader from '@/components/FileUploader'

import checkboxItem from '@/components/checkboxItem'
import { Message } from 'element-ui'
import { DxButton as ButtonWithText } from 'devextreme-vue/button'
import i18n from '@/i18n'
import Store from '@/models/odataStore'
import DxTextArea from 'devextreme-vue/text-area'
import { EXPORT_FORMAT } from '@/utils/exportFormat'
import CustomDataStore from '@/models/customStore'

const emailField = 'email'

export default {
  name: 'ODataTable',
  components: {
    DxDataGrid,
    DxFilterRow,
    DxEditing,
    DxExport,
    DxSelection,
    DxColumn,
    DxButton,
    DxMasterDetail,
    DxLookup,
    ButtonWithText,
    FileUploader,
    checkboxItem,
    DxRequiredRule,
    DxForm,
    DxSimpleItem,
    DxTexts,
    DxStateStoring,
    DxPager,
    DxEmailRule,
    DxTextArea
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    addEditColumns: {
      type: Array,
      default: () => []
    },
    resource: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    fileName: {
      type: String,
      default: ''
    },
    totalLabel: {
      type: String,
      default: ''
    },
    addRowButtonLabel: {
      type: String,
      default: ''
    },
    shouldRenderMasterDetail: {
      type: Boolean,
      default: false
    },
    shouldShowAddButton: {
      type: Boolean,
      default: true
    },
    filter: {
      type: Array,
      default: () => []
    },
    hasParent: {
      type: Boolean,
      default: false
    },
    isCustomAdd: {
      type: Boolean,
      default: false
    },
    showData: {
      type: Boolean,
      default: true
    },
    isCustomEdit: {
      type: Boolean,
      default: false
    },
    shouldShowDeleteButton: {
      type: Boolean,
      default: true
    },
    shouldShowEditButton: {
      type: Boolean,
      default: true
    },
    isStaticDataSource: {
      type: Boolean,
      default: false
    },
    staticData: {
      type: Array | Object,
      default: () => []
    },
    groupBy: {
      type: String,
      default: ''
    },
    allowColumnResizing: {
      type: Boolean,
      default: true
    },
    actionButtonsWidth: {
      type: Number,
      default: 80
    },
    aliasResource: {
      type: String,
      default: ''
    },
    customDeleteMessage: {
      type: String,
      default: ''
    },
    onLoaded: {
      type: Function,
      default: null
    },
    isEditDeleteButtonVisible: {
      type: Function,
      default: null
    },
    isCustomForm: {
      type: Boolean,
      default: false
    },
    rowKey: {
      type: String,
      default: 'ID'
    },
    isStateStored: {
      type: Boolean,
      default: false
    },
    columnCount: {
      type: Number,
      default: 1
    },
    isCustomDataSource: {
      type: Boolean,
      default: false
    },
    customStoreBaseUrl: {
      type: String,
      default: ''
    },
    lookupFilter: {
      type: Boolean,
      default: true
    },
    dynamicResourceExpression: {
      type: String,
      default: ''
    },
    showCustomToolbarBeforeButton: {
      type: Boolean,
      default: false
    },
    method: {
      type: String,
      default: ''
    },
    orderBy: {
      type: String,
      default: ''
    },
    exportFormats: {
      validator: function(values) {
        return values.every(value =>
          Object.values(EXPORT_FORMAT).includes(value)
        )
      },
      default: () => [EXPORT_FORMAT.EXCEL]
    }
  },
  data() {
    return {
      editorOptions: {
        valueChangeEvent: 'keyup'
      },
      dataSource: {
        store: this.createDataSource(),

        select: this.columns.map(column => column.field),

        columns: this.columns,
        paginate: true,
        pageSize: 50,
        allowedPageSizes: [50, 100, 150, 200]
      },
      total: 0,
      filters: null,
      selectedItems: [],
      allowSelectAll: false
    }
  },
  computed: {
    hasFilters() {
      if (this.aliasResource) {
        return true
      }

      return !!this.filters
    }
  },
  watch: {
    columns(newColumns) {
      this.dataSource.columns = newColumns
    },
    filters() {
      if (this.$refs.oDataTableRef && this.selectedItems.length) {
        this.$refs.oDataTableRef.instance.deselectAll()
      }
    },

    staticData(staticData) {
      this.dataSource = {
        ...this.dataSource,
        store: staticData
      }
    }
  },
  methods: {
    createDataSource() {
      if (this.isStaticDataSource) {
        return this.staticData
      } else if (this.isCustomDataSource) {
        if (this.resource === '') {
          return []
        }
        return new CustomDataStore({
          resource: this.resource,
          baseUrl: this.customStoreBaseUrl,
          key: this.rowKey,
          dynamicResourceExpression: this.dynamicResourceExpression
        })
      } else {
        return new Store({
          resource: this.resource,
          key: this.rowKey,
          aliasResource: this.aliasResource,
          method: this.method,
          ...(this.groupBy && { groupBy: this.groupBy }),
          ...(this.filter.length && { filter: this.filter }),
          ...(this.onLoaded && {
            onLoaded: this.onLoaded
          }),
          ...(this.orderBy && { orderBy: this.orderBy })
        })
      }
    },
    rowExpanded(e) {
      this.$emit('rowExpanded', e)
      const index = e.component.getRowIndexByKey(e.key)
      const row = e.component.getRowElement(index)
      if (row.length) {
        row[0].classList.add('dx-customHeader-row')
      }
    },

    handelRowCollapsed(e) {
      const index = e.component.getRowIndexByKey(e.key)
      const row = e.component.getRowElement(index)
      if (row.length) {
        row[0].classList.remove('dx-customHeader-row')
      }
    },
    addRow() {
      if (this.isCustomAdd) {
        this.$emit('addClick')
      } else {
        this.$refs.oDataTableRef.instance.addRow()
      }
    },

    handleToolbarPreparing(event) {
      event.toolbarOptions.items.push(
        ...[
          ...(this.shouldShowAddButton
            ? [
              {
                location: 'before',
                width: '80%',
                template: 'add'
              }
            ]
            : [
              {
                location: 'before',
                width: '80%',
                locateInMenu: 'auto',
                template: 'custom-toolbar-before'
              }
            ]),
          ...(this.showCustomToolbarBeforeButton
            ? [
              {
                location: 'before',
                width: '80%',
                locateInMenu: 'auto',
                template: 'custom-toolbar-before'
              }
            ]
            : []),
          {
            location: 'before',
            width: '80%',
            locateInMenu: 'auto',
            template: 'custom-toggle-before'
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            template: 'total'
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            template: 'clear-filters'
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            template: 'custom-toolbar-after'
          }
        ]
      )
    },

    handleRowInit(event) {
      this.$emit('rowInit', event)
    },

    handleEditorPreparing(event) {
      if (event.dataType === 'number') {
        event.editorOptions.step = 0
      }
      this.$emit('editorPreparing', event)
    },

    handleLookupPopupOpen(event) {
      const element = event.component._input()
      if (element.length) {
        element[0].select()
      }
    },
    handleDataErrorOccurred(event) {
      this.$emit('onDataErrorOccurred', event)
    },
    handleEditClick(event) {
      this.$emit('edit', event)
    },
    handleRowUpdated(event) {
      this.$emit('rowUpdated', event)
      Message({
        message: i18n.t('updateSuccess'),
        type: 'success'
      })
    },

    handleRowInserted(event) {
      this.$emit('rowInserted', event)
      if (!event.key) {
        return
      }
      Message({
        message: i18n.t('createSuccess'),
        type: 'success'
      })
    },
    handleRowRemoved(event) {
      this.$emit('rowRemoved', event)

      Message({
        message: i18n.t('deleteSuccess'),
        type: 'success'
      })
    },
    handleFiltersClear() {
      this.$refs.oDataTableRef.instance.clearFilter()

      this.$emit('clearFilters')
    },

    handleSelectionChange(data) {
      this.selectedItems = data.selectedRowsData

      this.$emit('selectionChange', data)
    },

    handleContentReady(event) {
      this.$emit('contentReady', event)

      this.total = event.component.totalCount()
      const filters = event.component.getCombinedFilter()
      const isDefaultFilter = compareFilters({
        sourceFilters: filters,

        targetFilters: this.filter
      })

      this.filters = filters

      if (isDefaultFilter && this.filter) {
        this.filters = null
      }

      this.allowSelectAll = !!this.aliasResource || !!this.filters
    },

    handleCellClick(event) {
      this.$emit('cell-click', event)
    },

    handleRowUpdating(event) {
      this.$emit('rowUpdating', event)
    },

    handleEditingStart(event) {
      this.$emit('editingStart', event)
    },

    handleCellPrepared(event) {
      this.$emit('cellPrepared', event)
    },
    handleEditingCancelling(event) {
      this.$emit('editingCancelling', event)
    },
    handleRowInserting(event) {
      this.$emit('rowInserting', event)
    },
    handleFileToBeDeleted(fileToBeDeleted) {
      this.$emit('fileToBeDeleted', fileToBeDeleted)
    },
    handleFileUploaded({ fileToBeUploaded, newlyUploadedFile }) {
      this.$emit('fileUploaded', { fileToBeUploaded, newlyUploadedFile })
    },
    handleRowSaved(event) {
      this.$emit('rowSaved', event)
    },
    handleRowSaving(event) {
      this.$emit('rowSaving', event)
    },
    isFieldEmail(field) {
      return field?.replace(/[-\s]+/g, '').toLowerCase() === emailField
    },
    handleExporting(event) {
      const format = this.exportFormats[0]

      if (!Object.values(EXPORT_FORMAT).includes(format)) {
        return
      }

      const isSelectedExport =
        event.component._controllers.export._selectionOnly

      const totalCount = isSelectedExport
        ? event.component.getSelectedRowKeys().length
        : event.component.totalCount()

      if (totalCount > process.env.VUE_APP_EXPORT_WARNING_LIMIT) {
        this.$message({
          type: 'warning',
          message: this.$t('exportWarningMessage', {
            max_limit: process.env.VUE_APP_EXPORT_WARNING_LIMIT
          })
        })
      }
      const fileName = this.fileName
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet(fileName)

      exportDataGrid({
        component: event.component,
        worksheet,
        encodeExecutableContent: true
      }).then(function() {
        workbook[format].writeBuffer().then(buffer => {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            `${fileName}.${format}`
          )
        })
      })

      event.cancel = true
    }
  }
}
