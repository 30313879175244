const optionTypes = {
  templates: 'templates',
  sites: 'sites'
}

const filterList = (list, filters) => {
  return list.filter(el => {
    return filters

      .filter(filter => !!filter.value)

      .every(filterParam => {
        const currentValue = el[filterParam.name]
        if (currentValue) {
          return filterParam.isText
            ? currentValue
              .toLowerCase()
              .includes(filterParam.value.toLowerCase())
            : currentValue === filterParam.value
        }
        return false
      })
  })
}

const getContentPartOptions = (state, optionType) => {
  return state.contentPart[optionType]
}

const getFilteredList = state => filters => {
  let { list } = state.contentPart
  list = filterList(list, filters)
  return list
}

const filterPages = state => filters => {
  let list = state.contentPartPages.pages
  list = filterList(list, filters)
  return list
}

const getFilteredListForBuilder = state => {
  let { filters } = state.contentPartBuilder
  filters = [
    {
      name: 'Name',
      isText: true,
      value: filters.nameSearch
    },
    {
      name: 'Position',
      isText: false,
      value: filters.positionSearch
    },
    {
      name: 'Tag',
      isText: false,
      value: filters.tagSearch
    }
  ]
  const { list } = state.contentPart
  return filterList(list, filters)
}

const getUpdatedcustomerIds = state => {
  const users = state.user.importedUsers.reports

    .map(item => item.users)

    .flatMap(user => user)

  const customerIds = users.map(item => item.ID)
  return customerIds
}

const getters = {
  sidebar: state => state.app.sidebar,

  device: state => state.app.device,

  token: state => state.user.token,

  name: state => state.user.user.name,
  contentPartList: getFilteredList,

  contentPartTemplates: state =>
    getContentPartOptions(state, optionTypes.templates),

  contentPartSites: state => getContentPartOptions(state, optionTypes.sites),

  contentPartRow: state => ID =>
    state.contentPart.list.find(el => el.ID === ID),

  totalContentParts: state => Name =>
    state.contentPart.list.filter(el => el.Name === Name).length,

  siteName: (state, getters) => SiteID =>
    getters.contentPartSites.find(el => el.ID === SiteID)?.Name || SiteID,

  siteUrl: (state, getters) => SiteID =>
    getters.contentPartSites.find(el => el.ID === SiteID)?.sSiteURL || SiteID,

  templateName: (state, getters) => TemplateID =>
    getters.contentPartTemplates.find(el => el.ID === TemplateID)?.Name ||
    TemplateID,

  contentPartTemplate: state => templateID =>
    state.contentPart.templates.find(el => el.ID === templateID),
  contentPartPages: filterPages,
  contentPartListForBuilder: getFilteredListForBuilder,
  customerIds: getUpdatedcustomerIds,

  files: state => showDownloadedOnly =>
    showDownloadedOnly
      ? state.files.files.filter(file => file.DownloadedAdminID)
      : state.files.files.filter(file => !file.DownloadedAdminID),

  downloadable: state => state.downloadable.downloadable
}
export default getters
