var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ODataTable", {
    key: _vm.filter,
    ref: "oDataTableComponentRef",
    attrs: {
      columns: _vm.columns,
      "add-edit-columns": _vm.addEdit,
      resource: "CustomersAccount",
      "total-label": _vm.$t("customersAccount.total"),
      "file-name": _vm.$t("customersAccount.fileName"),
      "add-row-button-label": _vm.$t("customersAccount.add"),
      title: _vm.$t("customersAccount.title"),
      "should-show-delete-button": false,
      "show-custom-toolbar-before-button": true,
      filter: _vm.filter
    },
    on: {
      rowInit: _vm.handleRowInit,
      rowUpdating: _vm.handleRowUpdating,
      editorPreparing: _vm.handleEditorPreparing
    },
    scopedSlots: _vm._u([
      {
        key: "custom-toolbar-before",
        fn: function() {
          return [
            _c("el-switch", {
              staticClass: "margin",
              attrs: { "inactive-text": "Show active" },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.showActive,
                callback: function($$v) {
                  _vm.showActive = $$v
                },
                expression: "showActive"
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "custom-buttons",
        fn: function() {
          return [
            _c("DxButton", {
              attrs: { icon: "trash", "on-click": _vm.handleDelete }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }