<template>
  <div class="column-flex">
    <div class="margin-left-md match-table">
      <el-table :data="importedDownloadables" height="250">
        <el-table-column
          v-for="(column, index) in headings.options"
          :key="column.label"
          :prop="column.label"
          :show-overflow-tooltip="true"
          class="overflow-wrap"
        >
          <!-- eslint-disable -->
          <template slot="header" slot-scope="scope">
            <!-- eslint-enable -->
            <el-select
              filterable
              :value="column.value"
              :placeholder="$t('notRecognized')"
              :loading="isLoading"
              @change="handleFieldSelect(column.label, $event, index)"
            >
              <el-option
                v-for="item in options.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-button
      type="primary"
      :loading="isGenerateReportLoading"
      :disabled="headings.isDisabled"
      class="margin-left-md self-start"
      @click="handleGenerateReportClick"
    >{{ $t("manageDownloadables.matchField.generateReport") }}</el-button>
  </div>
</template>
<script>
import { MUTATION_TYPES } from '@/store/mutations'
import { createNamespacedHelpers } from 'vuex'
import { nextClickEvent } from './config'
import { DownloadableColumn } from '@/models/downloadableColumn'
import { DownloadableColumns } from '@/models/downloadableColumns'
import { ImportedDownloadables } from '@/models/importedDownloadables'
import { DownloadableOptionList } from '@/models/downloadableOptionList'
import { STEP } from './config'
const { mapState } =
    createNamespacedHelpers('user/importedUsers')
const { mapState: mapDownloadableState, mapMutations: mapDownloadableMutations, mapActions: mapDownloadableActions } =
    createNamespacedHelpers('importedDownloadables')
export default {
  name: 'MatchFields',
  data() {
    return {
      headings: new DownloadableOptionList(ImportedDownloadables.headings()),
      isGenerateReportLoading: false,
      options: new DownloadableOptionList(ImportedDownloadables.options())
    }
  },
  computed: {
    ...mapDownloadableState({
      importedDownloadables: state => state.importedDownloadables.importedUsers
    }),
    ...mapState({
      isLoading: (state) => state.isLoading
    })
  },
  created() {
    this.headings.options.forEach(heading => {
      heading.value = ''
    })
  },
  methods: {
    ...mapDownloadableMutations({
      setHeadings: MUTATION_TYPES.IMPORTED_DOWNLOADBALES.SET_HEADINGS
    }),
    ...mapDownloadableActions(['generateDownloadableReport']),
    handleFieldSelect(column, value, index) {
      if (!DownloadableColumn.handleFieldSelect(column, value, this.importedDownloadables)) {
        return
      }
      DownloadableColumns.setColumnValues(this.headings, value, index)
    },
    async handleGenerateReportClick() {
      this.isGenerateReportLoading = true
      try {
        await this.generateDownloadableReport(this.headings)
      } catch (error) {
        this.$message({
          type: 'error',
          message: error.message
        })
        return
      }
      this.setHeadings(this.headings)
      this.$emit(nextClickEvent, STEP.GENERATE_REPORT)
      this.isGenerateReportLoading = false
    }
  }
}
</script>

  <style lang="scss" src="./style.scss" scoped />
