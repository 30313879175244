import { createResource } from '@/utils/httpRequests'
export default class CourseCampaignService {
  /**
   * @deprecated Create with iknow api
   */
  static _create(data) {
    return createResource({
      url: `${process.env.VUE_APP_CONTENTPART_API}/campaign/add`,
      data
    })
  }

  /**
   * @function {} Create email campaign with Brevo
   */
  static create(data) {
    return createResource({
      url: `${process.env.VUE_APP_BREVO_API_BASE_URL}/emailCampaigns`,
      data,
      reqType: 'brevo'
    })
  }
}
