var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chat-assistant-container" }, [
    _c(
      "div",
      { staticClass: "chat-assistants-grid" },
      _vm._l(_vm.assistants, function(assistant) {
        return _c(
          "div",
          { key: assistant, staticClass: "assistant-item" },
          [
            _c("deep-chat", {
              ref: assistant,
              refInFor: true,
              staticClass: "deep-chat-bot",
              attrs: {
                directConnection: _vm.directConnection,
                gifs: '{"button": {"position": "dropup-menu"}}',
                audio: '{"button": {"position": "dropup-menu"}}',
                camera: '{"button": {"position": "dropup-menu"}}',
                "mixed-files": '{"button": {"position": "inside-left"}}',
                microphone: '{"button": {"position": "outside-right"}}'
              },
              on: {
                "new-message": function($event) {
                  return _vm.handleNewMessage(assistant)
                }
              }
            }),
            _c(
              "div",
              { staticClass: "button-container" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.clearMessages(assistant)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("clear")))]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      disabled: !_vm.areMultipleChatAssistants,
                      type: "danger"
                    },
                    on: {
                      click: function($event) {
                        return _vm.deleteAssistant(assistant)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("delete")))]
                )
              ],
              1
            )
          ],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "clone-button" },
      [
        _c("el-button", {
          attrs: { icon: "el-icon-plus" },
          on: { click: _vm.cloneAssistant }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }