var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("UserDetail", {
        attrs: {
          "selected-customer": _vm.selectedCustomer,
          "assigned-tags": _vm.assignedTags,
          sites: _vm.sites,
          "show-details": _vm.showDetails
        },
        on: { detailsDialogClose: _vm.handleDetailsDialogClose }
      }),
      _c("ODataTable", {
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns,
          "add-edit-columns": _vm.columns,
          "total-label": _vm.$t("download.total"),
          "file-name": _vm.$t("download.fileName"),
          title: _vm.$t("download.title"),
          "is-static-data-source": true,
          "static-data": _vm.filteredFiles,
          "should-show-delete-button": false,
          "should-show-edit-button": false,
          "should-show-select-box": false,
          "should-show-add-button": false,
          "action-buttons-width": 100
        },
        scopedSlots: _vm._u([
          {
            key: "custom-buttons",
            fn: function() {
              return [
                _c("DxButton", {
                  attrs: {
                    icon: "download",
                    "on-click": _vm.handleDownloadClick
                  }
                }),
                _c("DxButton", {
                  attrs: {
                    icon: "info",
                    "on-click": _vm.showUploadedUserDetail,
                    visible: _vm.hasUploadedUser
                  }
                }),
                _c("DxButton", {
                  attrs: {
                    icon: "card",
                    "on-click": _vm.showDownloadedUserDetail,
                    visible: _vm.hasDownloadedUser
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "custom-toolbar-before",
            fn: function() {
              return [
                _c("DxCheckBox", {
                  attrs: {
                    text: _vm.$t("download.checkboxTitle"),
                    value: _vm.showDownloadedOnly
                  },
                  on: { "value-changed": _vm.toggleDownloadedOnly }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }