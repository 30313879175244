<template>
  <el-dialog
    v-if="selectedCustomer"
    :visible.sync="isDialogOpen"
    :title="selectedCustomerFullName"
    @close="handleDetailsDialogClose"
  >
    <Details :detail="{...selectedCustomer, assignedTags, sites}" />
  </el-dialog>
</template>

<script>
import Details from '@/views/customers/detail'
export default {
  name: 'UserDetail',
  components: {
    Details
  },
  props: {
    selectedCustomer: {
      type: Object,
      default: () => null
    },
    assignedTags: {
      type: Array,
      default: () => []
    },
    sites: {
      type: Array,
      default: () => []
    },
    showDetails: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectedCustomerFullName() {
      return [this.selectedCustomer?.FirstName, this.selectedCustomer?.LastName]
        .filter((item) => !!item)
        .join(' ')
    },
    isDialogOpen() {
      return this.showDetails
    }
  },
  methods: {
    handleDetailsDialogClose() {
      this.$emit('detailsDialogClose')
    }
  }
}
</script>
