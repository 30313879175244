import { REVIEW_REFLECTIONS } from '../views/reviewReflection/config'

export const getFieldLabel = ({ isApproved, dataField }) => {
  switch (dataField) {
    case REVIEW_REFLECTIONS.DATE_APPROVED:
      if (isApproved) {
        return 'dateApproved'
      }
      return 'dateRejected'
    case REVIEW_REFLECTIONS.ADMIN_ID:
      if (isApproved) {
        return 'approvedBy'
      }
      return 'rejectedBy'
    case REVIEW_REFLECTIONS.APPROVAL_COMMENT:
      if (isApproved) {
        return 'approval'
      }
      return 'rejection'
  }
}
