<template>
  <DxForm
    :form-data="data"
    :items="items"
    :col-count="3"
    :customize-item="customizeItem"
    class="address-form form-container"
  >
    <template #form-item="{ data: item }">
      <span>
        {{ item.editorOptions.value }}
      </span>
    </template>
    <template #custom-item="{ data: item }">
      <slot name="custom-item" :item="item" />
    </template>
    <template #lookup-value="{ data: item }">
      <span>
        {{ getLookUpValue(item) }}
      </span>
    </template>
  </DxForm>
</template>

<script>
import { DxForm } from 'devextreme-vue/form'
import { findLookUpValue } from '@/utils/list'

export default {
  name: 'MasterDetail',
  components: { DxForm },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    customizeItem(item) {
      if (!item.template) {
        item.template = 'form-item'
      }
    },
    getLookUpValue(item) {
      const { dataSource, displayExpr, valueExpr, value } = item.editorOptions
      return findLookUpValue({ items: dataSource, compareField: valueExpr, returnField: displayExpr, compareValue: value }) || item.editorOptions.value
    }
  }
}
</script>

<style scoped lang="scss">
.form-container {
  padding: 20px;
}

.address-form label {
  font-weight: bold;
}

</style>
