import { Menu } from './section'

export class User {
  AdminLevel;

  FirstName;

  ID;

  IsAdmin;

  LastName;

  disabledMenus;

  menus;
  constructor(user = {}) {
    Object.assign(this, user)

    const disabledMenus = user.disabledMenus || []
    const disabledMenuIds = disabledMenus.map(
      disabledMenu => disabledMenu.AdminMenuID
    )

    const menus = (user.menus || []).filter(
      menu => menu.IsActive && !disabledMenuIds.includes(menu.ID)
    )

    this.menus = Menu.fromList(menus)
    this.disabledMenus = disabledMenus
  }

  get name() {
    return [this.FirstName, this.LastName].filter(char => !!char).join(' ')
  }

  get isSuperAdmin() {
    return this.IsAdmin && this.AdminLevel >= 2
  }

  canAccess(url) {
    return this.menus.has(url)
  }

  get canDeleteCustomer() {
    return this.IsAdmin && this.AdminLevel >= 5
  }

  createMenuParams(selectedMenuIds = []) {
    const enabledMenuIds = selectedMenuIds.filter(
      id => !this.menus.findById(id)
    )
    const disabledMenuIds = this.menus.ids.filter(
      id => !selectedMenuIds.includes(id)
    )
    const enabled = this.disabledMenus

      .filter(disabledMenu => enabledMenuIds.includes(disabledMenu.AdminMenuID))

      .map(disabledMenu => disabledMenu.ID)

    const disabled = disabledMenuIds.map(id => ({
      AdminMenuID: id,
      CustomersID: this.ID
    }))

    return {
      enabled,
      disabled
    }
  }

  get isUserAllowedToImportExtId() {
    return this.IsAdmin && this.AdminLevel >= 5
  }

  static isValidEmail(email = '') {
    return /^[^\s@]+@[^\s@]+.[^\s@]+$/.test(email)
  }
}
