var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": _vm.$t("loading"),
            border: "",
            fit: "",
            size: "medium",
            "highlight-current-row": "",
            height: "75vh"
          },
          on: { "row-dblclick": _vm.editContentPart }
        },
        [
          _c(
            "el-table-column",
            {
              attrs: { label: _vm.$t("name"), sortable: "", "sort-by": "Name" }
            },
            [
              _c("el-table-column", {
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          staticClass: "search-box",
                          attrs: {
                            clearable: "",
                            size: "mini",
                            placeholder: _vm.$t("search")
                          },
                          model: {
                            value: _vm.filters.name,
                            callback: function($$v) {
                              _vm.$set(_vm.filters, "name", $$v)
                            },
                            expression: "filters.name"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(" " + _vm._s(scope.row.Name) + " ")]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                label: _vm.$t("contentPart.position"),
                width: "180",
                align: "center",
                sortable: "",
                "sort-by": "Position"
              }
            },
            [
              _c("el-table-column", {
                attrs: { width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function(scope) {
                      return [
                        _c("content-part-select", {
                          attrs: {
                            placeholder:
                              _vm.$t("search") +
                              " " +
                              _vm.$t("contentPart.position"),
                            options: _vm.positions,
                            "show-all": "",
                            selected: _vm.filters.position,
                            "prop-name": "Position",
                            "prop-value": "Position",
                            size: "mini"
                          },
                          on: {
                            "update:selected": function($event) {
                              return _vm.$set(_vm.filters, "position", $event)
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(" " + _vm._s(scope.row.Position) + " ")]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                label: _vm.$t("contentPart.tag"),
                width: "180",
                align: "center",
                sortable: "",
                "sort-by": "Tag"
              }
            },
            [
              _c("el-table-column", {
                attrs: { width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function(scope) {
                      return [
                        _c("content-part-select", {
                          attrs: {
                            placeholder:
                              _vm.$t("search") +
                              " " +
                              _vm.$t("contentPart.tag"),
                            options: _vm.tags,
                            "show-all": "",
                            selected: _vm.filters.tag,
                            "prop-name": "Tag",
                            "prop-value": "Tag",
                            size: "mini"
                          },
                          on: {
                            "update:selected": function($event) {
                              return _vm.$set(_vm.filters, "tag", $event)
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(" " + _vm._s(scope.row.Tag) + " ")]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                label: _vm.$t("contentPart.priority"),
                width: "100",
                align: "center",
                sortable: "",
                "sort-by": "SortOrder"
              }
            },
            [
              _c("el-table-column", {
                attrs: { width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(" " + _vm._s(scope.row.SortOrder) + " ")]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                label: _vm.$t("contentPart.template"),
                width: "180",
                sortable: "",
                "sort-by": "ContentPartTemplateID"
              }
            },
            [
              _c("el-table-column", {
                attrs: { width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function(scope) {
                      return [
                        _c("content-part-select", {
                          attrs: {
                            placeholder:
                              _vm.$t("search") +
                              " " +
                              _vm.$t("contentPart.template"),
                            options: _vm.contentPartTemplates,
                            "show-all": "",
                            selected: _vm.filters.template,
                            size: "mini"
                          },
                          on: {
                            "update:selected": function($event) {
                              return _vm.$set(_vm.filters, "template", $event)
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.templateName(scope.row.ContentPartTemplateID)
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: { label: _vm.$t("actions"), width: "130", align: "center" }
            },
            [
              _c("el-table-column", {
                attrs: { width: "130", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-button", {
                          attrs: {
                            type: "text",
                            icon: "el-icon-document-copy"
                          },
                          on: {
                            click: function($event) {
                              return _vm.cloneContentPart(scope.row)
                            }
                          }
                        }),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handlePreview(scope.row)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-view text-success"
                            })
                          ]
                        ),
                        _c("el-button", {
                          attrs: { type: "text", icon: "el-icon-edit-outline" },
                          on: {
                            click: function($event) {
                              return _vm.editContentPart(scope.row)
                            }
                          }
                        }),
                        _c("delete-row-button", {
                          attrs: {
                            "selected-row": scope.row.ID,
                            "delete-action": "contentPart/deleteContentPartRow"
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }