<template>
  <el-container>
    <el-main v-loading="areQuestionsAndTheirAnswersLoading" class="el-main-container">
      <el-row class="margin-y-sm">
        <el-col :span="24" class="right-align">
          <el-button
            type="primary"
            size="medium"
            :loading="isLoading"
            :disabled="
              !(isEditing
                ? test.haveProvidedQuestions
                : test.haveProvidedQuestionsAndMedia)
            "
            @click="handleNextClick"
          >{{ isEditing ? $t("test.edit.save") : $t("test.create.chooseType.next")
          }}</el-button>
        </el-col>
      </el-row>
      <el-row v-if="!isEditing" class="margin-y-sm">
        <el-col :span="8">
          <el-select v-model="test.siteId" :placeholder="$t('test.create.createQuestions.sitePlaceholder')">
            <el-option v-for="site in sites" :key="site.ID" :label="site.Name" :value="site.ID" />
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select
            v-model="test.programId"
            class="margin-left-sm"
            collapse-tags
            :placeholder="$t('test.create.createQuestions.programPlaceholder')"
            :loading="areProgramsLoading"
          >
            <el-option v-for="program in programs" :key="program.ID" :label="program.Name" :value="program.ID" />
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select
            v-model="test.episodeId"
            class="margin-left-sm"
            :placeholder="$t('test.create.createQuestions.episodePlaceholder')"
          >
            <el-option v-for="episode in episodes" :key="episode.ID" :label="episode.Title" :value="episode.ID" />
          </el-select>
        </el-col>
      </el-row>
      <el-row class="margin-y-md">
        <vuedraggable v-model="test.questions" @start="handleDragStart" @end="handleDragEnd">
          <el-col
            v-for="(question, questionIndex) in test.questions"
            :key="questionIndex"
            :span="test.canDeleteQuestion ? 23 : 24"
          >
            <el-row type="flex" :gutter="20" class="center" justify="space-between">
              <el-col :span="3">
                <span class="question-color">{{
                  `${$t(
                    "test.create.specifyAnswers.question"
                  )} ${questionIndex + 1}`
                }}:</span>
              </el-col>
              <el-col :span="20">
                <el-collapse v-model="expandedQuestions" @change="handleCollapse">
                  <el-collapse-item :name="questionIndex" :disabled="question.isFreeText">
                    <template slot="title">
                      <el-input
                        v-model="question.name"
                        :placeholder="$t('test.create.createQuestions.questionPlaceholder')"
                        class="question-input-width-size"
                        @click.native="handleCollapse"
                        @keyup.native="handleCollapse"
                      />
                      <DxButton icon="dragvertical" :class="{'is-dragging': isDragging}" class="drag-button" />
                    </template>
                    <el-row
                      v-for="(answer, answerIndex) in question.answers"
                      :key="`${questionIndex}_${answerIndex}`"
                      type="flex"
                      :gutter="20"
                      class="margin-y-sm margin-left-md"
                    >
                      <el-col :span="2">
                        {{ $t("test.create.specifyAnswers.correct") }}
                        <el-radio
                          v-model="question.correctAnswerIndex"
                          :label="answerIndex"
                          class="margin-y-sm column correct-radio-label"
                          size="medium"
                          @change="markCorrectAnswer(questionIndex, answerIndex)"
                        />
                      </el-col>
                      <el-col :span="2">
                        <el-row>
                          {{ $t("test.create.specifyAnswers.order") }}
                        </el-row>
                        <el-row :gutter="15" class="margin-y-sm">
                          <el-col>
                            <el-button
                              v-if="answerIndex"
                              class="arrow-button"
                              size="small"
                              icon="el-icon-arrow-up"
                              @click="handleUpArrowClick(questionIndex, answerIndex)"
                            />
                          </el-col>
                          <el-col class="margin-y-sm">
                            <el-button
                              v-if="question.checkIfDownSortAllowedForIndex(answerIndex)"
                              class="arrow-button"
                              size="small"
                              icon="el-icon-arrow-down"
                              @click="handleDownArrowClick(questionIndex, answerIndex)"
                            />
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="20">
                        {{ $t("test.create.specifyAnswers.response") }}
                        <el-input
                          v-model="answer.response"
                          :placeholder="$t('test.create.specifyAnswers.response')"
                          type="textarea"
                          :rows="1"
                          class="margin-y-sm"
                        />
                        <el-row>
                          <el-col class="font-weight" :span="24">
                            {{ $t("test.create.specifyAnswers.feedback") }}
                          </el-col>
                          <el-col :span="24">
                            <el-input
                              v-model="answer.feedback"
                              :placeholder="$t('test.create.specifyAnswers.feedback')"
                              type="textarea"
                              :rows="1"
                              class="margin-top-xs margin-bottom-sm"
                            />
                          </el-col>
                          <div v-if="isEditing">
                            <el-col class="font-weight" :span="24">
                              {{ $t("test.edit.specifyAnswers.toggle.label") }}
                            </el-col>
                            <el-col :span="24">
                              <el-switch v-model="answer.isActive" :value="answer.isActive" />
                            </el-col>
                          </div>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-collapse-item>
                </el-collapse>
              </el-col>

              <el-col :span="1" class="align-self-start">
                <el-row type="flex" :gutter="10" class="actions-container" justify="center" align="center">
                  <el-col v-if="test.canDeleteQuestion && !isEditing">
                    <i class="el-icon-delete" @click="handleRemoveQuestion(questionIndex)" />
                  </el-col>
                  <el-col v-if="isEditing">
                    <el-switch v-model="question.isActive" :value="question.isActive" />
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </vuedraggable>
        <el-button v-if="!isEditing" class="margin-y-md" type="primary" size="medium" @click="handleQuestionAdd">{{
          $t("test.create.createQuestions.addMore") }}</el-button>
      </el-row>
    </el-main>
  </el-container>
</template>
<script>
import { STEP } from './config'
import { createNamespacedHelpers } from 'vuex'
import { ROUTE_PATH } from '@/router/index'
import vuedraggable from 'vuedraggable'
import { DxButton } from 'devextreme-vue/button'
const { mapState, mapActions } = createNamespacedHelpers('test')
const { mapState: mapContentPartState } = createNamespacedHelpers(
  'contentPart'
)
export default {
  name: 'AddEditQuestionsAndTheirAnswers',
  components: {
    vuedraggable,
    DxButton
  },
  props: {
    isEditing: Boolean
  },
  data() {
    return {
      expandedQuestionIndexes: null,
      activeStep: STEP.CHOOSE_QUESTION_ANSWER,
      isDragging: false
    }
  },
  computed: {
    ...mapState({
      test: state => state.test,
      programs: state => state.programs,
      episodes: state => state.episodes,
      areProgramsLoading: state => state.areProgramsLoading,
      areEpisodesLoading: state => state.areEpisodesLoading,
      areQuestionsAndTheirAnswersLoading: state => state.areQuestionsAndTheirAnswersLoading,
      isLoading: state => state.isLoading
    }),
    ...mapContentPartState({
      sites: state => state.sites
    }),
    expandedQuestions: {
      get: function() {
        if (this.expandedQuestionIndexes) {
          return this.expandedQuestionIndexes
        }

        if (!this.test.questions[0]?.isFreeText) {
          return [0]
        }

        return []
      },
      set: function(expandedQuestionIndexes) {
        this.expandedQuestionIndexes = expandedQuestionIndexes
      }
    },
    currentRoute() {
      return this.$router.history.current
    }
  },
  watch: {
    'test.siteId': {
      handler(siteId) {
        this.setSiteId(siteId)
      },
      deep: true
    },
    'test.programId': {
      handler(programId) {
        this.setProgramId(programId)
      },
      deep: true
    }
  },
  async created() {
    if (this.currentRoute.fullPath.includes(ROUTE_PATH.KNOWLEDGE_TEST)) {
      this.test.setType('mcq')
    } else {
      this.test.setType('reflection')
    }
    if (this.isEditing) {
      const { id } = this.currentRoute.params
      this.fetchQuestionsAndTheirAnswersBySectionId({ id, isFreeText: this.test.isFreeText })
    }
  },
  methods: {
    ...mapActions([
      'setSiteId',
      'setProgramId',
      'fetchQuestionsAndTheirAnswersBySectionId'
    ]),
    handleNextClick() {
      if (this.test.allQuestionsHaveAnswers) {
        this.$emit('nextClick')
      } else {
        this.$message({ type: 'error', message: this.$t('test.noCorrectAnswerSelectedError') })
      }
    },
    handleQuestionAdd() {
      this.test.addQuestion()
    },
    handleRemoveQuestion(index) {
      this.test.removeQuestion(index)
    },
    markCorrectAnswer(questionIndex, answerIndex) {
      this.test.setCorrectAnswerForQuestion(questionIndex, answerIndex)
    },
    handleCollapse(event) {
      event.stopPropagation?.()
    },
    handleUpArrowClick(questionIndex, answerIndex) {
      this.test.questions[questionIndex].sortAnswers(answerIndex, answerIndex - 1)
    },
    handleDownArrowClick(questionIndex, answerIndex) {
      this.test.questions[questionIndex].sortAnswers(answerIndex, answerIndex + 1)
    },
    handleDragStart() {
      this.isDragging = true
    },
    handleDragEnd() {
      this.isDragging = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/styles/common.scss";

.margin-y-sm {
  margin: $base-spacing * 3 0px;
}

.margin-left-sm {
  margin-left: $base-spacing * 4;
}

.margin-bottom-sm {
  margin-bottom: $base-spacing * 3
}

.question-input-width-size {
  width: 86%;
}

.align-self-start {
  align-self: start;
}

.actions-container {
  height: 50px;
  align-items: center;
}

::v-deep .correct-radio-label .el-radio__label {
  display: none;
}

.el-main-container {
  padding: 0 $base-spacing * 4 !important;
}

.drag-button {
  cursor: grab;
  display: inline-block;
}

.is-dragging {
  cursor: grabbing !important;
}

::v-deep .dx-icon-dragvertical::before {
  color: #bbb !important;
}

::v-deep .dx-button.dx-state-hover {
  background-color: #fff !important;
}
::v-deep .dx-button.dx-state-focused {
  background-color: #fff !important;
}
</style>
