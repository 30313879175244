<template>
  <div class="app-media-container">
    <ODataTable
      :columns="columns.allColumns"
      :add-edit-columns="addEditColumns"
      resource="Media"
      :is-custom-form="true"
      :total-label="$t('medias.total')"
      :file-name="$t('medias.fileName')"
      :add-row-button-label="$t('medias.add')"
      :title="$t('medias.title')"
      @rowInit="handleRowInit"
      @editorPreparing="handleEditorPreparing"
      @editingStart="handleEditingStart"
    >
      <template #custom-form>
        <DxTabbedItem>
          <DxTab
            :title="$t('info.title')"
          >
            <DxSimpleItem
              v-for="column in columns.infoColumns"
              :key="`add_edit_${column.field}`"
              :data-field="column.field"
            />

            <DxButtonItem
              :button-options="dxButtonOptions.getButtonOptions()"
            />

            <DxGroupItem :visible="dxButtonOptions._visible">
              <DxSimpleItem
                v-for="column in columns.hiddenInfoColumns"
                :key="`add_edit_${column.field}`"
                :data-field="column.field"
              />
            </DxGroupItem>
          </DxTab>
          <DxTab :title="$t('medias.description')">
            <DxSimpleItem
              v-for="column in columns.metaColumns"
              :key="`add_edit_${column.field}`"
              :data-field="column.field"
              editor-type="dxTextArea"
            />
          </DxTab>
        </DxTabbedItem>
      </template>
    </ODataTable>
  </div>
</template>
<script>
import { DxSimpleItem, DxTabbedItem, DxTab, DxButtonItem, DxGroupItem } from 'devextreme-vue/form'
import ODataTable from '../../components/ODataTable'
import { createColumns, disabledColumns, assistantIdField, ishiddenField, deactivateAtDateField } from './config'
import { v4 as guidGenerator } from 'uuid'
import DxTextArea from 'devextreme-vue/text-area'
import { createNamespacedHelpers } from 'vuex'
import { DxButtonItemOptions } from '@/models/DxButtonItemOptions'
const { mapActions: mapAssistantActions, mapState: mapAssistantState } = createNamespacedHelpers('assistants')

export default {
  name: 'Media',
  components: {
    ODataTable,
    DxSimpleItem,
    DxTabbedItem,
    DxButtonItem,
    DxGroupItem,
    DxTab,
    // eslint-disable-next-line vue/no-unused-components
    DxTextArea
  },
  data() {
    return {
      addEditColumns: [],
      dxButtonOptions: new DxButtonItemOptions()
    }
  },
  computed: {
    ...mapAssistantState({
      assistants: state => state.assistants.assistants.map(assistant => assistant.option)
    }),
    columns() {
      return createColumns({
        assistants: this.assistants
      })
    }
  },
  async created() {
    await this.fetchAll()
  },
  methods: {
    ...mapAssistantActions(['fetchAll']),
    handleRowInit(event) {
      event.data.DataAdded = new Date().toISOString()
      this.infoColumnsToShow = this.infoColumnsToShow.filter(column => column.field !== ishiddenField && column.field !== deactivateAtDateField)
      this.addEditColumns = this.infoColumnsToShow
      event.data.MediaGUID = guidGenerator()
    },
    handleEditorPreparing(event) {
      if (event.isOnForm) {
        if (event.dataField === assistantIdField) {
          if (!this.assistants.map(assistant => assistant.value).includes(event.editorOptions.value)) {
            event.editorOptions.value = ''
          }
        }
        event.editorOptions.disabled = disabledColumns.includes(
          event.dataField
        )
      }
    },
    handleEditingStart() {
      this.infoColumnsToShow = this.columns.infoColumns
      this.addEditColumns = this.columns.allColumns
    }
  }
}
</script>
<style lang="scss">
body:has(.app-media-container){
  .dx-item-content.dx-box-item-content {
    display: flex !important;
    flex-direction: column !important;
  }
}
</style>
