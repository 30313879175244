import { getToken } from '@/utils/auth'
import { HTTP_METHOD } from '@/utils/constants'

export class CorsTester {
  constructor({
    url = '',
    body = {},
    token = `Bearer ${getToken()}`,
    method = HTTP_METHOD.GET
  } = {}) {
    this.url = url
    this.body = body
    this.token = token
    this.method = method
  }

  get isBodyVisible() {
    const methodsRequireBody = [
      HTTP_METHOD.PUT,
      HTTP_METHOD.PATCH,
      HTTP_METHOD.POST
    ]
    return methodsRequireBody.includes(this.method)
  }

  get headers() {
    const headers = { Authorization: this.token }
    return headers
  }

  parseBody() {
    let parsedBody
    try {
      parsedBody = JSON.parse(this.body)
    } catch (error) {
      parsedBody = this.body
    }
    return parsedBody
  }

  static isValidUrl(url) {
    try {
      new URL(url)
      return true
    } catch (err) {
      return false
    }
  }

  toParams() {
    return {
      url: this.url,
      method: this.method,
      body: this.parseBody(),
      headers: this.headers
    }
  }
}
