import { fectchResource } from '@/utils/httpRequests'

const customStoreBaseUrlConfigMapper = {
  [process.env.VUE_APP_BREVO_API_BASE_URL]: {
    baseUrl: process.env.VUE_APP_BREVO_API_BASE_URL,
    header: {
      Accept: 'application/json',
      'api-key': process.env.VUE_APP_BREVO_API_KEY
    },
    getSortValue(option) {
      if (!option.sort) {
        option.sort = 'desc'
      } else {
        option.sort = option.sort[0].desc ? 'asc' : 'desc'
      }
      return option
    },
    '/contacts/lists': {
      paramNames: {
        limit: 'take',
        offset: 'skip'
      },
      fieldNames: {
        data: 'lists',
        totalCount: 'count'
      }
    },
    ContactsFromList: {
      paramNames: {
        limit: 'take',
        offset: 'skip',
        sort: 'sort'
      },
      fieldNames: {
        data: 'contacts',
        totalCount: 'count',
        summary: 'summary',
        groupCount: 'groupCount'
      }
    }
  }
}
export class CustomStoreConfig {
  constructor({
    key = 'id',
    resource,
    baseUrl,
    dynamicResourceExpression,
    dropDown = false
  } = {}) {
    this.key = key
    this.baseUrl = baseUrl
    this.resource = resource
    this.dropDown = dropDown

    const configData = this.#getBaseUrlConfig(this.baseUrl)

    this.header = configData.header
    this.urlValue = this.url

    this.paramsValues = configData[resource]
      ? configData[resource].paramNames
      : configData[dynamicResourceExpression].paramNames
    this.fieldNames = configData[resource]
      ? configData[resource].fieldNames
      : configData[dynamicResourceExpression].fieldNames

    this.defaultsParams = {
      take: 50,
      skip: 0,
      sort: 'desc'
    }

    this.sortValue = configData.getSortValue
      ? configData.getSortValue.bind(this)
      : undefined
    this.getQueryString = this.getQueryString.bind(this)
    this.getDataforTable = this.getDataforTable.bind(this)
  }

  get url() {
    return `${this.baseUrl}/${this.resource}`
  }

  #getBaseUrlConfig(baseUrl) {
    return customStoreBaseUrlConfigMapper[baseUrl]
  }

  getQueryString({ option, params }) {
    return Object.entries(params)
      .filter(([key, value]) => option[value])
      .map(([key, value]) => `${key}=${option[value]}`)
      .join('&')
  }

  async getDataforTable(option) {
    const fields = this.fieldNames

    option = this.sortValue ? this.sortValue(option) : option

    let queryString = ''

    if (option.take && option.skip !== undefined) {
      queryString = this.getQueryString({
        option,
        params: this.paramsValues
      })
    } else {
      queryString = this.getQueryString({
        option: this.defaultsParams,
        params: this.paramsValues
      })
    }

    const result = await fectchResource(`${this.urlValue}?${queryString}`, {
      headers: this.header
    })

    let data = result[fields.data]

    if (!option.take && !option.skip && !this.dropDown) {
      const total = result[fields.totalCount]
      this.defaultsParams.skip = this.defaultsParams.take
      this.defaultsParams.take = 50

      while (total > this.defaultsParams.skip) {
        queryString = this.getQueryString({
          option: this.defaultsParams,
          params: this.paramsValues
        })

        const result = await fectchResource(`${this.urlValue}?${queryString}`, {
          headers: this.header
        })

        data = [...data, ...result[fields.data]]

        this.defaultsParams.skip += 50
      }
    }

    this.defaultsParams.take = 50
    this.defaultsParams.skip = 0
    return {
      data: data,
      totalCount: result[fields.totalCount],
      summary: result[fields.summary],
      groupCount: result[fields.groupCount]
    }
  }

  async load(option) {
    return await this.customConfig.getDataforTable(option)
  }
}
