import { fectchResource } from '@/utils/httpRequests'

export default class InvertedMediaReportService {
  static getAll(params = {}) {
    const paramsAsQuery = Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    return fectchResource(
      `${process.env.VUE_APP_CONTENTPART_API}/export/mediareportinv?${paramsAsQuery}`
    )
  }
}
