import { createResource, fectchResource } from '@/utils/httpRequests'
export default class TestService {
  static create(data) {
    return createResource({
      url: `${process.env.VUE_APP_ODATA_API}/tTest`,
      data
    })
  }

  static fetchBySiteId(siteId) {
    return fectchResource(
      `${process.env.VUE_APP_ODATA_API}/tTest?$filter=SitesID eq ${siteId}`
    )
  }
}
