<template>
  <div>
    <ODataTable
      ref="oDataTableComponentRef"
      :key="filter"
      :columns="columns.all"
      :add-edit-columns="columns.addEdit"
      resource="ExternalMedia"
      :total-label="$t('downloadable.total')"
      :file-name="$t('downloadable.fileName')"
      :add-row-button-label="$t('downloadable.add')"
      :title="$t('downloadable.title')"
      :filter="filter"
      @editingStart="handleEditingStart"
      @editorPreparing="handleEditorPreparing"
      @editingCancelling="setDefaultValue"
      @rowInserting="handleRowInserting"
      @rowSaving="handleSaving"
      @rowUpdating="handleUpdating"
      @rowSaved="setDefaultValue"
    >
      <template #custom-toolbar-after>
        <el-select
          v-model="categoryId"
          filterable
          :placeholder="`${$t('select')} ${$t('category')}`"
        >
          <el-option v-if="categories.length" label="All" value="" />
          <el-option
            v-for="category in categories"
            :key="category.value"
            :label="category.label"
            :value="category.value"
          />
        </el-select>
      </template>
    </ODataTable>
  </div>
</template>
<script>
import ODataTable from '../../components/ODataTable'
import { FOLDER_NAME_FIELD, columns } from './config'
import { createNamespacedHelpers } from 'vuex'
import { MUTATION_TYPES } from '@/store/mutations'
import store from '@/store'
const { mapState: mapDownloadableState } = createNamespacedHelpers('downloadable')
const { mapMutations: mapDownloadableMutations } = createNamespacedHelpers('downloadable')

const {
  mapState: mapAppState,
  mapActions: mapAppActions
} = createNamespacedHelpers('app')

export default {
  name: 'Downloadable',
  components: {
    ODataTable
  },
  data() {
    return {
      categoryId: '',
      filter: [],
      mediaIdsForSelectedCategory: [],
      columns
    }
  },
  computed: {
    ...mapAppState({
      medias: state => state.medias.map(media => media.option),
      categories: state => state.categories.map(category => category.option)
    }),
    filteredMedias() {
      return this.medias
        .filter(media => this.mediaIdsForSelectedCategory.includes(media.value))
        .map(media => media.value)
    },
    ...mapDownloadableState({
      downloadable: state => state.downloadable
    })
  },
  watch: {
    async categoryId(newCategory) {
      if (!newCategory) {
        this.filter = []
        return
      }
      this.mediaIdsForSelectedCategory = await this.fetchMediasByCategoryId(
        newCategory
      )
      if (this.filteredMedias.length) {
        this.filter = [['MediaID', 'in', `(${this.filteredMedias.join(',')})`]]
      } else {
        this.filter = []
        this.$message({
          type: 'error',
          message: this.$t('errors.noMediaForCategory')
        })
      }
    }
  },
  async mounted() {
    this?.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
    await Promise.all([this.fetchMedias(), this.fetchCategories()])
    this?.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
  },
  methods: {
    ...mapAppActions([
      'fetchMedias',
      'fetchCategories',
      'fetchMediasByCategoryId'
    ]),
    ...mapDownloadableMutations({
      updateDownloadable: MUTATION_TYPES.DOWNLOADABLE.SET_DOWNLOADABLE,
      setFolderNameToOld: MUTATION_TYPES.DOWNLOADABLE.SET_FOLDER_NAME_TO_OLD,
      resetDownloadable: MUTATION_TYPES.DOWNLOADABLE.RESET_DOWNLOADABLE
    }),
    setDefaultValue() {
      this.resetDownloadable()
      this?.$refs.oDataTableComponentRef.$refs?.dxFileUploaderRef.toggleUploadByFileUploader()
    },

    handleEditorPreparing(event) {
      if (
        event.dataField === FOLDER_NAME_FIELD
      ) {
        if (this.downloadable.disableFolderField) {
          event.editorOptions.disabled = true
        }
        event.editorOptions.value = this.downloadable.folder
      }
    },
    handleEditingStart(event) {
      if (event.data.FileName) {
        const fileName = event.data.FileName.split('/')
        const folder = fileName.slice(0, -1)

        this.updateDownloadable({
          folder: folder.join('/'),
          disableFolderField: true
        })
      }
      this.updateDownloadable({
        id: event.data.ID
      })
    },
    handleRowInserting(event) {
      this?.$refs.oDataTableComponentRef.$refs?.dxFileUploaderRef?.uploadFile()
      const fileName = this?.$refs.oDataTableComponentRef.$refs?.dxFileUploaderRef?.fileManager?.filename || event.data.FileName || null
      event.data.FileName = fileName && `${this.downloadable.fileNameUpdate}${fileName}`
    },
    handleUpdating(event) {
      const fileName = this?.$refs.oDataTableComponentRef.$refs?.dxFileUploaderRef?.fileManager?.filename || store.downloadable.fileUploadedFolderName || event.data.FileName || null
      event.newData.FileName = fileName && `${this.downloadable.fileNameUpdate}${fileName}`
    },

    async handleSaving(event) {
      this.updateDownloadable({
        hidePreview: true
      })
      event.component.beginCustomLoading()
      if (store.getters.downloadable.updateFile) {
        this.$refs.oDataTableComponentRef.$refs.dxFileUploaderRef.uploadFile()
        const fileName = this?.$refs.oDataTableComponentRef.$refs?.dxFileUploaderRef?.fileManager?.filename || null
        event.changes[0] = {
          data: { ...event.changes[0]?.data, FileName: `${this.downloadable.fileNameUpdate}${fileName}` },
          key: store.getters.downloadable.id,
          type: 'update'
        }
      }
      event.component.refresh()
      event.component.endCustomLoading()
    }
  }
}
</script>
