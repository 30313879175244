var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 15 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, md: 8, lg: 8 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("contentPart.template"),
                    prop: "ContentPartTemplateID"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      ref: "selectBoxRef",
                      staticClass: "select-width",
                      attrs: { size: "small" },
                      on: { change: _vm.checkContentOverride },
                      model: {
                        value: _vm.contentPart.ContentPartTemplateID,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.contentPart,
                            "ContentPartTemplateID",
                            $$v
                          )
                        },
                        expression: "contentPart.ContentPartTemplateID"
                      }
                    },
                    _vm._l(_vm.templatesByTemplateType, function(
                      templates,
                      name
                    ) {
                      return _c(
                        "el-option-group",
                        { key: name, attrs: { label: name } },
                        _vm._l(templates, function(template) {
                          return _c("el-option", {
                            key: template.ID,
                            attrs: { label: template.Name, value: template.ID }
                          })
                        }),
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, md: 8, lg: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("name"), prop: "Name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("name") },
                    model: {
                      value: _vm.contentPart.Name,
                      callback: function($$v) {
                        _vm.$set(_vm.contentPart, "Name", $$v)
                      },
                      expression: "contentPart.Name"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, md: 8, lg: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("site"), prop: "SiteId" } },
                [
                  _c(
                    "el-select",
                    {
                      ref: "selectBoxRef",
                      staticClass: "select-width",
                      attrs: {
                        "allow-create": false,
                        size: "small",
                        filterable: true
                      },
                      model: {
                        value: _vm.contentPart.SiteID,
                        callback: function($$v) {
                          _vm.$set(_vm.contentPart, "SiteID", $$v)
                        },
                        expression: "contentPart.SiteID"
                      }
                    },
                    _vm._l(_vm.sites, function(site) {
                      return _c("el-option", {
                        key: site.ID,
                        attrs: { label: site.Name, value: site.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 15 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, md: 8, lg: 8 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("contentPart.position"),
                    prop: "Position"
                  }
                },
                [
                  _c("content-part-select", {
                    staticClass: "select-width",
                    attrs: {
                      placeholder:
                        _vm.$t("select") + " " + _vm.$t("contentPart.position"),
                      selected: _vm.contentPart.Position,
                      options: _vm.positions,
                      "allow-create": true,
                      "prop-value": "Position",
                      "prop-name": "Position",
                      size: "small"
                    },
                    on: {
                      "update:selected": function($event) {
                        return _vm.$set(_vm.contentPart, "Position", $event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, md: 8, lg: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("contentPart.tag"), prop: "Tag" } },
                [
                  _c("content-part-select", {
                    staticClass: "select-width",
                    attrs: {
                      placeholder:
                        _vm.$t("select") + " " + _vm.$t("contentPart.tag"),
                      selected: _vm.contentPart.Tag,
                      options: _vm.tags,
                      "allow-create": true,
                      "prop-value": "Tag",
                      "prop-name": "Tag",
                      size: "small"
                    },
                    on: {
                      "update:selected": function($event) {
                        return _vm.$set(_vm.contentPart, "Tag", $event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, md: 8, lg: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("contentPart.priority") } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "Number",
                      placeholder: _vm.$t("contentPart.priority")
                    },
                    model: {
                      value: _vm.contentPart.SortOrder,
                      callback: function($$v) {
                        _vm.$set(_vm.contentPart, "SortOrder", $$v)
                      },
                      expression: "contentPart.SortOrder"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 15 } },
        [
          _vm.dialogBoxConfig.showCategory
            ? _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("category") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "select-width",
                          attrs: {
                            loading: _vm.dialogBoxConfig.isCategoryLoading,
                            placeholder:
                              _vm.$t("select") + " " + _vm.$t("category"),
                            filterable: "",
                            size: "small"
                          },
                          on: { change: _vm.handleCategoryChange },
                          model: {
                            value: _vm.selectedCategory,
                            callback: function($$v) {
                              _vm.selectedCategory = $$v
                            },
                            expression: "selectedCategory"
                          }
                        },
                        _vm._l(_vm.categories, function(category) {
                          return _c("el-option", {
                            key: category.ID,
                            attrs: { label: category.Name, value: category.ID }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.dialogBoxConfig.showMedia
            ? _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("media") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "select-width",
                          attrs: {
                            loading: _vm.dialogBoxConfig.isMediaLoading,
                            placeholder:
                              _vm.$t("select") + " " + _vm.$t("media"),
                            filterable: "",
                            size: "small"
                          },
                          on: { change: _vm.handleMediaChange },
                          model: {
                            value: _vm.selectedMedia,
                            callback: function($$v) {
                              _vm.selectedMedia = $$v
                            },
                            expression: "selectedMedia"
                          }
                        },
                        _vm._l(_vm.medias, function(media) {
                          return _c("el-option", {
                            key: media.ID,
                            attrs: { label: media.Title, value: media.ID }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }