<template>
  <el-button type="text" @click="deleteRow">
    <i class="el-icon-delete text-danger" />
  </el-button>
</template>

<script>
const ContentPart = 'contentPart.'

export default {
  name: 'DeleteRowButton',
  props: {
    selectedRow: {
      type: [Number, String],
      default: -1
    },
    deleteAction: {
      type: String,
      default: ''
    }
  },
  computed: {
    isValidRow() {
      return this.selectedRow !== -1
    },
    isValidDeleteAction() {
      return !!this.deleteAction
    }
  },
  created() {
    if (!this.isValidDeleteAction) {
      throw new Error('Please provide prop deleteAction')
    }
    if (!this.isValidRow) {
      throw new Error('Please provide prop selectedRow')
    }
  },
  methods: {
    async deleteRow() {
      if (!this.isValidRow || !this.isValidDeleteAction) return

      const row = this.selectedRow
      if (!row) {
        return this.$alert(
          this.$t(`${ContentPart}deleteSelectRowMsg`),
          this.$t(`${ContentPart}deleteSelectRowTitle`),
          {
            confirmButtonText: this.$t('ok'),
            callback: () => {}
          }
        )
      }

      try {
        await this.$confirm(
          `${this.$t('deleteWarning')}. ${this.$t('continue')}?`,
          this.$t('warning'),
          {
            confirmButtonText: this.$t('delete'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }
        )

        await this.$store.dispatch(this.deleteAction, this.selectedRow)
        this.$message({ type: 'success', message: this.$t('deleteSuccess') })
      } catch (error) {
        // Called when a user clicks cancel on the dialog box.
        // For now there is nothing to do here when a user press cancel
        // on the dialog box. So, leaving it empty here otherwise it will
        // throw error in the console.
      }
    }
  }
}
</script>

<style lang='scss' scoped>
$danger: #f56c6c;

.text-danger {
  color: $danger;
}
</style>
