<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" width="50%" :before-close="handleDialogClose" @opened="handleDialogOpen">
    <el-form ref="pageFormRef" :model="page" :rules="rules" label-position="top" size="small">
      <el-row :gutter="15">
        <el-col :sm="24" :md="12">
          <el-form-item :label="$t('name')" prop="PageName">
            <el-input v-model="page.PageName" />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12">
          <el-form-item :label="$t('site')" prop="SiteId">
            <content-part-select
              :placeholder="`${$t('select')} ${$t('contentPart.site')}`"
              :selected.sync="page.SiteID"
              :options="sites"
              :allow-create="false"
              size="small"
              class="select-options"
              prop-value="ID"
              prop-name="Name"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="15">
        <el-col :sm="24" :md="12">
          <el-form-item :label="$t('contentPart.position')" prop="Position">
            <content-part-select
              :placeholder="`${$t('select')} ${$t('contentPart.position')}`"
              :selected.sync="page.Position"
              :options="positions"
              :allow-create="true"
              size="small"
              class="select-options"
              prop-value="Position"
              prop-name="Position"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12">
          <el-form-item :label="$t('contentPart.tag')" prop="Tag">
            <content-part-select
              :placeholder="`${$t('select')} ${$t('contentPart.tag')}`"
              :selected.sync="page.Tag"
              :options="tags"
              :allow-create="true"
              size="small"
              class="select-options"
              prop-value="Tag"
              prop-name="Tag"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleDialogClose">{{ $t('cancel') }}</el-button>
      <el-button
        v-if="canEdit"
        type="primary"
        @click="onSubmit(true, page.IsActive)"
      >{{ `${$t('editPage')}` }}</el-button>
      <el-button
        v-loading.fullscreen.lock="isPageDialogLoading"
        type="primary"
        :disabled="isSaveDisabled"
        @click="onSubmit(false, page.IsActive)"
      >{{ `${$t('save')}` }}</el-button>
      <el-button
        v-if="dialogType === DIALOG_TYPES.EDIT && !page.IsActive"
        v-loading.fullscreen.lock="isPageDialogLoading"
        type="success"
        @click="onSubmit(false, true)"
      >{{ `${$t('editPageMakeActiveButton')}` }}</el-button>
      <el-button
        v-if="dialogType === DIALOG_TYPES.EDIT && page.IsActive"
        v-loading.fullscreen.lock="isPageDialogLoading"
        type="warning"
        @click="onSubmit()"
      >{{ `${$t('editPageMakeInactiveButton')}` }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import ContentPartSelect from '@/components/ContentPartSelect'
import { BUILDER_MODES } from '@/utils/constants'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapUserState } =
  createNamespacedHelpers('user')
import {
  DIALOG_TYPES,
  PAGE_MODEL,
  validators
} from './config'

const contentPartPagesStore = 'contentPartPages'
const contentPartBuilderStore = 'contentPartBuilder'
const contentPartStore = 'contentPart'
const appStore = 'app'
const CONTENT_PART_BUILDER_ROUTE = '/administer/builder'
const PAGE_FORM_NAME = 'pageFormRef'

export default {
  name: 'PageDialogBox',
  components: {
    ContentPartSelect
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    item: PAGE_MODEL,
    dialogType: {
      type: String,
      default: DIALOG_TYPES.CREATE
    }
  },
  data() {
    return {
      originalItem: {},
      page: this.item,
      DIALOG_TYPES,
      rules: {
        PageName: [
          { validator: validators.validateName, trigger: 'blur' }
        ],
        Position: [
          { validator: validators.validatePosition, trigger: 'change' }
        ],
        Tag: [
          { validator: validators.validateTag, trigger: 'change' }
        ],
        SiteID: [
          { validator: validators.validateSite, trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters([
      'contentPartSites'
    ]),
    ...mapState(contentPartStore, ['tags', 'positions', 'sites']),
    ...mapState(contentPartPagesStore, ['isPageDialogLoading']),
    ...mapState(appStore, ['siteID']),
    dialogVisible: {
      get: function() {
        return this.visible
      },
      set: function(val) {
        this.$emit('update:visible', val)
      }
    },
    title() {
      return `${this.$t('editPageModalTitle')}`
    },
    isSaveDisabled() {
      if (!this.page.PageName?.trim() || !this.page.Position || !this.page.Tag || !this.page.SiteID) {
        return true
      }
      if ((this.page.PageName && this.originalItem.PageName !== this.page.PageName?.trim()) ||
      (this.page.Position && this.originalItem.Position !== this.page.Position) ||
      (this.page.SiteID && this.originalItem.SiteID !== this.page.SiteID) ||
      (this.page.Tag && this.originalItem.Tag !== this.page.Tag)) {
        return false
      }
      return true
    },
    ...mapUserState({
      canEdit: state => state.user.canAccess(CONTENT_PART_BUILDER_ROUTE)
    })
  },
  watch: {
    item(val) {
      this.page = val
      this.originalItem = {
        PageName: val.PageName,
        Position: val.Position,
        Tag: val.Tag,
        SiteID: val.SiteID
      }
    }
  },
  created() {
    if (!this.tags.length || !this.positions.length) {
      this.setTags()
      this.setPositions()
    }
    if (this.isPageDialogLoading) {
      this.toggleIsPageDialogLoading()
    }
  },
  methods: {
    ...mapActions(contentPartPagesStore, ['createPage', 'updatePage', 'toggleIsPageDialogLoading']),
    ...mapActions(contentPartBuilderStore, ['setConfig']),
    ...mapActions(contentPartStore, ['setTags', 'setPositions']),
    handleDialogOpen() {
      this.dialogVisible = true
      if (this.dialogType === DIALOG_TYPES.CREATE) {
        this.$refs['pageFormRef'].resetFields()
        this.page = {}
      }
    },
    handleDialogClose() {
      this.dialogVisible = false
    },
    async onSubmit(showBuilder = false, isPublish = false) {
      this.toggleIsPageDialogLoading()
      const formName = PAGE_FORM_NAME
      let isActive = isPublish
      const dialogTypeAction = {
        [DIALOG_TYPES.CREATE]: this.createContentPartPage,
        [DIALOG_TYPES.EDIT]: this.updateContentPartPage
      }
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (this.dialogType === DIALOG_TYPES.CREATE) isActive = false
          await dialogTypeAction[this.dialogType](isActive)
          if (showBuilder) {
            this.setConfig({
              currentPage: this.page.ID,
              siteID: this.page.SiteID,
              mode: BUILDER_MODES.EDIT,
              isActive: this.page.IsActive,
              currentPageName: this.page.PageName
            })
            this.$router.push(CONTENT_PART_BUILDER_ROUTE)
          }
        } else {
          this.toggleIsPageDialogLoading()
        }
      })
    },
    async updateContentPartPage(isPublish) {
      this.page.IsActive = isPublish
      await this.updatePage(this.page)
      this.dialogVisible = false
      this.toggleIsPageDialogLoading()
      this.$emit('update', this.page)
    },
    async createContentPartPage(isPublish) {
      this.page.IsActive = isPublish
      this.page.SortOrder = 0
      this.page.AddedDate = new Date()
      this.page.SiteID = this.siteID
      const page = await this.createPage(this.page)
      this.page = { ...page }
      this.dialogVisible = false
      this.toggleIsPageDialogLoading()
      this.$emit('update', this.page)
    }
  }
}
</script>

<style lang="scss" scoped>
.select-options {
  width: 100%;
}
</style>
