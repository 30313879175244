export class GoalFilters {
  constructor({ selectedSite = '', selectedEpisode = '', UserID = 0 } = {}) {
    this.selectedSite = selectedSite
    this.selectedEpisode = selectedEpisode
    this.UserID = UserID
  }

  get hasFilter() {
    return this.selectedSite
  }

  getFilter() {
    const goalsFilter = [
      ...(this.selectedSite ? [['SiteID', '=', `${this.selectedSite}`]] : []),
      ...(this.selectedEpisode
        ? [['MediaID', '=', `${this.selectedEpisode}`]]
        : [])
    ].reduce(
      (filter, element, index) => [
        ...filter,
        ...(index ? ['and'] : []),
        element
      ],
      []
    )

    return goalsFilter
  }

  clearFilters() {
    this.selectedSite = ''
    this.selectedEpisode = ''
  }
}
