import Store from '@/models/odataStore'

const siteIdColumn = 'SiteID'
export const dateColumns = ['AddedDate']

export const columns = [
  'ID',
  'FirstName',
  'LastName',
  'EMail',
  'PassWord',
  'PrenumerationType',
  'RealCustomer',
  'Account',
  'SubAccount',
  'FullAccount',
  'Company',
  'Address',
  'Postal_Address',
  'Postal_Code',
  'Reference',
  'AddedDate',
  'UID',
  'SiteID',
  'bAccessAll',
  'AltEmail',
  'UserName',
  'tTestGroupID',
  'CurrentQuestiondID',
  'CurrentSectionID',
  'CurrentTestID',
  'CurrentPage',
  'PersonID',
  'DeactivationDate',
  'LastLoginDate',
  'LastUpdate',
  'MobileNo',
  'LoggedIn',
  'IsAdmin',
  'Note',
  'Note2',
  'AltID',
  'NoDeactivation',
  'SecurePassword',
  'SignalUID',
  'AdminLevel',
  'ExtID'
].map(field => ({
  field,
  width: 100,
  ...(dateColumns.includes(field) && {
    dataType: 'date'
  }),
  ...(field === siteIdColumn && {
    label: 'SiteID',
    lookup: {
      store: new Store({ resource: 'Sites' }),
      paginate: true,
      pageSize: 50
    },
    valueExpr: 'ID',
    displayExpr: 'SiteName'
  })
}))
