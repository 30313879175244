var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-media-container" },
    [
      _c("ODataTable", {
        attrs: {
          columns: _vm.columns.allColumns,
          "add-edit-columns": _vm.addEditColumns,
          resource: "Media",
          "is-custom-form": true,
          "total-label": _vm.$t("medias.total"),
          "file-name": _vm.$t("medias.fileName"),
          "add-row-button-label": _vm.$t("medias.add"),
          title: _vm.$t("medias.title")
        },
        on: {
          rowInit: _vm.handleRowInit,
          editorPreparing: _vm.handleEditorPreparing,
          editingStart: _vm.handleEditingStart
        },
        scopedSlots: _vm._u([
          {
            key: "custom-form",
            fn: function() {
              return [
                _c(
                  "DxTabbedItem",
                  [
                    _c(
                      "DxTab",
                      { attrs: { title: _vm.$t("info.title") } },
                      [
                        _vm._l(_vm.columns.infoColumns, function(column) {
                          return _c("DxSimpleItem", {
                            key: "add_edit_" + column.field,
                            attrs: { "data-field": column.field }
                          })
                        }),
                        _c("DxButtonItem", {
                          attrs: {
                            "button-options": _vm.dxButtonOptions.getButtonOptions()
                          }
                        }),
                        _c(
                          "DxGroupItem",
                          { attrs: { visible: _vm.dxButtonOptions._visible } },
                          _vm._l(_vm.columns.hiddenInfoColumns, function(
                            column
                          ) {
                            return _c("DxSimpleItem", {
                              key: "add_edit_" + column.field,
                              attrs: { "data-field": column.field }
                            })
                          }),
                          1
                        )
                      ],
                      2
                    ),
                    _c(
                      "DxTab",
                      { attrs: { title: _vm.$t("medias.description") } },
                      _vm._l(_vm.columns.metaColumns, function(column) {
                        return _c("DxSimpleItem", {
                          key: "add_edit_" + column.field,
                          attrs: {
                            "data-field": column.field,
                            "editor-type": "dxTextArea"
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }