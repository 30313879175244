export class Site {
  ID;

  Name;

  sSiteURL;

  constructor(site) {
    this.ID = site.ID
    this.Name = site.SiteName
    this.sSiteURL = site.sSiteURL
  }

  get toOption() {
    return {
      label: this.Name,
      value: this.ID
    }
  }

  static findMissingId(sites = []) {
    const siteWithAvailableNextId = sites.find(
      (site, index) => sites[index + 1]?.ID - site.ID > 1
    )

    return siteWithAvailableNextId?.ID + 1
  }

  static fromList(sites = []) {
    return sites
      .map(site => new Site(site))
      .sort((a, b) => a?.Name?.localeCompare(b?.Name))
  }

  updatePartial(updatedSite = {}) {
    Object.assign(this, updatedSite)
  }
}
