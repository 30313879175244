var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ]
    },
    [
      _vm._m(0),
      _c("div", { attrs: { id: "main-container" } }),
      _c("div", [
        _c("div", { attrs: { id: "blocks-wrap" } }, [
          _vm.showBlocks
            ? _c("div", { staticClass: "filters-container" }, [
                _c("div", { staticClass: "builder-filters-container" }, [
                  _c("h2", [_vm._v(_vm._s(_vm.$t("contentBlocks")))]),
                  _c(
                    "div",
                    { staticClass: "builder-filters" },
                    [_c("builder-filters")],
                    1
                  )
                ]),
                _c("div", [_c("builder-filter-tags")], 1),
                _c(
                  "div",
                  [
                    _c("el-input", {
                      staticClass: "search",
                      attrs: {
                        size: "mini",
                        "suffix-icon": "el-icon-search",
                        placeholder: _vm.$t("search")
                      },
                      on: {
                        input: function($event) {
                          return _vm.setFilters({ nameSearch: $event })
                        }
                      },
                      model: {
                        value: _vm.nameSearch,
                        callback: function($$v) {
                          _vm.nameSearch = $$v
                        },
                        expression: "nameSearch"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.showBlocks
            ? _c(
                "div",
                _vm._l(_vm.contentPartListForBuilder, function(
                  contentPartForBuilder
                ) {
                  return _c(
                    "div",
                    { key: contentPartForBuilder.ID },
                    [
                      _vm.blockToShow(contentPartForBuilder)
                        ? _c("builder-content-tiles", {
                            attrs: {
                              data: contentPartForBuilder,
                              draggable: ""
                            },
                            on: {
                              dragstart: function($event) {
                                return _vm.onDragStart(contentPartForBuilder)
                              },
                              dragend: _vm.onDragStop
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          !_vm.contentPartListForBuilder.length && _vm.showBlocks
            ? _c("div", { staticClass: "center" }, [
                _vm._v(" " + _vm._s(_vm.$t("errors.noContentBlocks")) + " ")
              ])
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel__top" }, [
      _c("div", { staticClass: "panel__basic-actions" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }