<template>
  <el-main v-loading="isPanelLoading" class="container">
    <ODataTable
      ref="oDataTableComponentRef"
      :key="filter"
      :columns="columns"
      :total-label="$t('mediaReportsV2.total')"
      :file-name="$t('mediaReportsV2.fileName')"
      :title="$t(`${translationSection}.title`)"
      :should-show-add-button="false"
      :should-show-edit-button="false"
      :should-show-delete-button="false"
      resource="viewMediaReportDone"
      :show-data="showData"
      :filter="filter"
      row-key="CustomerID"
      @clearFilters="handleFiltersClear"
    >
      <template #custom-filters>
        <media-reports-filters
          ref="mediaReportFiltersRef"
          @filter="filterMediaReports"
          @panelLoading="handlePanelLoading"
          @clearFilteredData="handleClearFilteredData"
        />
      </template>
    </ODataTable>
  </el-main>
</template>
<script>
import ODataTable from '../../components/ODataTable'
import MediaReportsFilters from './filters.vue'
import { columns, rowKey } from './config'
import { createNamespacedHelpers } from 'vuex'
import router, { ROUTE_PATH } from '@/router'
import { MUTATION_TYPES } from '@/store/mutations'

const {
  mapState: mapMediaReportsState,
  mapMutations: mapMediaReportsMutations
} = createNamespacedHelpers('mediaReportsV2')

const {
  mapState: mapReflectionState
} = createNamespacedHelpers('reflection')

export default {
  name: 'MediaReports',
  components: {
    ODataTable,
    MediaReportsFilters
  },
  data() {
    return {
      showData: false,
      columns,
      rowKey,
      filter: [],
      filteredData: [],
      isPanelLoading: false
    }
  },
  computed: {
    ...mapMediaReportsState({
      filters: state => state.filters,
      isTagLoading: state => state.isLoading
    }),
    ...mapReflectionState({
      isLoading: state => state.isLoading
    }),
    isMediaReportV2() {
      return router.currentRoute.fullPath === ROUTE_PATH.MEDIAREPORTV2
    },
    translationSection() {
      return this.isMediaReportV2
        ? 'mediaReportsV2'
        : 'invertedMediaReports'
    }
  },
  created() {
    this.initialiseFilter(!this.isMediaReportV2)
    this.filters.clearFiltersV2()
  },
  methods: {
    ...mapMediaReportsMutations({
      initialiseFilter: MUTATION_TYPES.MEDIA_REPORTS_V2.INITIALISE
    }),
    filterMediaReports(filter) {
      this.filter = filter || []
      this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
      this.showData = true
      this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
    },
    handleFiltersClear() {
      this.showData = true
    },
    handlePanelLoading(value) {
      this.isPanelLoading = value
    },
    handleClearFilteredData() {
      this.filteredData = []
    }
  }
}
</script>
<style lang="scss">
.el-message__closeBtn {
  color: #999
}

.el-message__icon {
  display: none
}
</style>
