import { deleteResource, fectchResource } from '@/utils/httpRequests'

export default class CustomersAccountService {
  static delete(id) {
    return deleteResource(
      `${process.env.VUE_APP_ODATA_API}/customersaccount?key=${id}`
    )
  }

  static getAllCustomerAccount() {
    return fectchResource(`${process.env.VUE_APP_ODATA_API}/CustomersAccount`)
  }
}
