import i18n from '@/i18n'
import { CorsTester } from '@/models/corsTester'

export const validateUrl = (rule, value, callback) => {
  if (!value.trim()) {
    callback(new Error(i18n.t('corsTester.pleaseEnterUrl')))
  } else if (!CorsTester.isValidUrl(value)) {
    callback(new Error(i18n.t('corsTester.pleaseEnterValidUrl')))
  } else {
    callback()
  }
}
