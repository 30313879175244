<template>
  <ODataTable
    ref="oDataTableComponentRef"
    :columns="columns"
    :add-edit-columns="addEditColumns"
    resource="Authors"
    :total-label="$t('authors.total')"
    :file-name="$t('authors.fileName')"
    :add-row-button-label="$t('authors.add')"
    :title="$t('authors.title')"
    :should-render-master-detail="true"
    @rowInit="handleRowInit"
    @rowRemoved="handleRowRemoved"
    @editorPreparing="handleEditorPreparing"
    @editingStart="handleEditingStart"
  >
    <template #details="{ data }">
      <AuthorsByMedia :data="data" />
    </template>
  </ODataTable>
</template>
<script>
import ODataTable from '../../components/ODataTable'
import { columns, addEditColumns, idColumnField } from './config'
import AuthorsByMedia from '../AuthorsByMedia'
import AuthorsByMediaService from '@/api/authorsByMedia'

export default {
  name: 'Authors',
  components: {
    ODataTable,
    AuthorsByMedia
  },
  data() {
    return {
      columns,
      addEditColumns
    }
  },
  methods: {
    handleRowInit() {
      this.addEditColumns = addEditColumns
    },
    handleEditorPreparing(event) {
      if (event.isOnForm) {
        event.editorOptions.disabled = idColumnField === event.dataField
      }
    },
    handleEditingStart() {
      this.addEditColumns = columns
    },
    async handleRowRemoved(event) {
      if (event.data.ID) {
        const { value } = await AuthorsByMediaService.getById({ id: event.data.ID })

        await Promise.all(value.map(({ ID }) => AuthorsByMediaService.delete(ID)))
      }
    }
  }
}
</script>
