var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column-flex" }, [
    _c(
      "div",
      [
        _vm._l(_vm.reports, function(report, index) {
          return [
            _c("div", { key: index }, [
              _c("div", { staticClass: "title-container" }, [
                _c("p", [_vm._v(_vm._s(report.totalEmails))]),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("manageUsers.generateReport." + report.status)
                    )
                  )
                ])
              ]),
              !_vm.isDeactivatedImport
                ? _c(
                    "div",
                    _vm._l(report.users, function(user) {
                      return _c(
                        "p",
                        { key: _vm.userKey(user), staticClass: "subtitle" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.importedUsers
                                  .isCheckForImportingExtIdSelected
                                  ? user.EMail + " - " + user.ExtID
                                  : user.EMail
                              ) +
                              " "
                          )
                        ]
                      )
                    }),
                    0
                  )
                : _c("div", [
                    !_vm.importedUsers.isCheckForImportingExtIdSelected
                      ? _c(
                          "div",
                          _vm._l(report.emails, function(email) {
                            return _c(
                              "p",
                              { key: email, staticClass: "subtitle" },
                              [_vm._v(" " + _vm._s(email) + " ")]
                            )
                          }),
                          0
                        )
                      : _c(
                          "div",
                          _vm._l(report.extIds, function(extId) {
                            return _c(
                              "p",
                              { key: extId, staticClass: "subtitle" },
                              [_vm._v(" " + _vm._s(extId) + " ")]
                            )
                          }),
                          0
                        )
                  ])
            ])
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }