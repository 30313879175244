import { InvertedMediaReportFiltersV2 } from './invertedMediaReportFiltersV2'
import { MediaReportFilterV2 } from './mediaReportsFilterV2'

export class MediaReportFilterFactory {
  constructor(isInverted = false) {
    if (isInverted) {
      return new InvertedMediaReportFiltersV2()
    }
    return new MediaReportFilterV2()
  }
}
