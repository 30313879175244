<template>
  <div>
    <ODataTable
      ref="oDataTableComponentRef"
      :columns="columns.all"
      :add-edit-columns="columns.addEdit"
      resource="ChatQuestion"
      :action-buttons-width="90"
      :total-label="$t('chatQuestions.total')"
      :file-name="$t('chatQuestions.fileName')"
      :add-row-button-label="$t('chatQuestions.add')"
      :title="$t('chatQuestions.title')"
      :should-render-master-detail="true"
      @rowInit="handleRowInit"
      @rowRemoved="handleRowRemoved"
      @editorPreparing="handleEditorPreparing"
    >
      <template #details="{ data }">
        <chatQuestionsByMedia :data="data" />
      </template>
    </ODataTable>
  </div>
</template>

<script>
import chatQuestionsByMedia from '@/views/ChatQuestionsByMedia'
import ODataTable from '../../components/ODataTable'
import { createColumns, disabledColumns } from './config'
import { createNamespacedHelpers } from 'vuex'
import ChatQuestionByMediaService from '@/api/chatQuestionsByMedia'
import odataTableEventsMixin from '@/mixin/odataTableEventsMixin'
const { mapState, mapActions } = createNamespacedHelpers(
  'chatQuestionsByMedia'
)
export default {
  name: 'ChatQuestions',
  components: {
    ODataTable,
    chatQuestionsByMedia
  },
  mixins: [odataTableEventsMixin],
  computed: {
    ...mapState({
      filters: state => state.filters
    }),
    columns() {
      return createColumns()
    },
    disabledColumns() {
      return disabledColumns
    }
  },
  methods: {
    ...mapActions([
      'fetchAll'
    ]),
    handleRowInit(event) {
      event.data.AddedDate = new Date().toISOString()
    },
    async handleRowRemoved(event) {
      if (event.data.ID) {
        const { value } = await ChatQuestionByMediaService.getById({ id: event.data.ID })

        await Promise.all(value.map(({ ID }) => ChatQuestionByMediaService.delete(ID)))
      }
    }
  }
}
</script>
