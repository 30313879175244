export const nextClickEvent = 'onNextClick'

export const invalidEmailFoundEvent = 'onInvalidEmailFound'

export const STEP = Object.freeze({
  SELECT_TYPE: 'selectType',
  IMPORT_CSV: 'importCsv',
  MATCH_COLUMN: 'matchField',
  VALIDATE_EMAILS: 'validateEmails',
  DEACTIVATE_USER: 'deactivateUser',
  GENERATE_REPORT: 'generateReport',
  ASSIGN_TAG: 'assignTag'
})
