export const dateColumns = ['timeUsed']
export const columns = [
  'Account',
  'Address',
  'CustomerID',
  'EMail',
  'FirstName',
  'ID',
  'IsMobile',
  'LastName',
  'MainCategoryID',
  'MediaID',
  'PassWord',
  'Postal_Address',
  'Postal_Code',
  'RealCustomer',
  'Reference',
  'Title',
  ...dateColumns
].map(field => ({
  field,
  width: 100,
  ...(dateColumns.includes(field) && {
    dataType: 'date'
  })
}))
