var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-header", { staticClass: "main-title" }, [
        _vm._v(_vm._s(_vm.$t("corsTester.title")))
      ]),
      _c(
        "el-form",
        { ref: "formRef", attrs: { model: _vm.corsTester, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("corsTester.urlLabel"), prop: "url" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.corsTester.url,
                  callback: function($$v) {
                    _vm.$set(_vm.corsTester, "url", $$v)
                  },
                  expression: "corsTester.url"
                }
              })
            ],
            1
          ),
          _vm.corsTester.isBodyVisible
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("corsTester.bodyLabel") } },
                [
                  _c("el-input", {
                    attrs: { rows: 5, type: "textarea" },
                    model: {
                      value: _vm.corsTester.body,
                      callback: function($$v) {
                        _vm.$set(_vm.corsTester, "body", $$v)
                      },
                      expression: "corsTester.body"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-select",
            {
              attrs: { placeholder: _vm.$t("corsTester.methodLabel") },
              model: {
                value: _vm.corsTester.method,
                callback: function($$v) {
                  _vm.$set(_vm.corsTester, "method", $$v)
                },
                expression: "corsTester.method"
              }
            },
            _vm._l(_vm.methodOptions, function(option) {
              return _c("el-option", {
                key: option.value,
                attrs: { label: option, value: option }
              })
            }),
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("corsTester.bearerTokenLabel") } },
            [
              _c("el-input", {
                attrs: { rows: 10, type: "textarea" },
                model: {
                  value: _vm.corsTester.token,
                  callback: function($$v) {
                    _vm.$set(_vm.corsTester, "token", $$v)
                  },
                  expression: "corsTester.token"
                }
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleFormSubmit }
            },
            [_vm._v(_vm._s(_vm.$t("corsTester.testButtonText")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }