<template>
  <div class="app-container py-none">
    <el-container>
      <el-main class="py-none">
        <el-row class="m-container">
          <el-col :xs="24" :sm="12" class="column">
            <el-row type="flex" class="center">
              <el-col :span="4" class="m-title">{{ $t("name") }} :</el-col>
              <el-col :span="20">
                <el-row type="flex" class="center gap-md">
                  <span>{{ courseCampaign.name }} :</span>
                  <span class="primary">{{
                    courseCampaign.formattedAddedDate
                  }}</span>
                </el-row>
              </el-col>
            </el-row>
            <el-row type="flex" class="center">
              <el-col
                :span="4"
                class="m-title"
              >{{ $t("customers.total") }} :</el-col>
              <el-col :span="20" class="primary">{{ total }}</el-col>
            </el-row>
            <el-row type="flex" class="center">
              <el-col
                :span="4"
                class="m-title"
              >{{ $t("courseCampaign.filters") }} :</el-col>
              <el-col :span="20" class="primary">{{
                courseCampaign.filterUsed
              }}</el-col>
            </el-row>
          </el-col>

          <el-row type="flex" class="my-2" justify="space-between">
            <el-col :span="10" class="dx-viewport">
              <el-table :data="customers" :max-height="340" class="m-border">
                <el-table-column show-overflow-tooltip prop="EMail" label="Email" />
                <el-table-column prop="FirstName" label="First Name" />
                <el-table-column prop="LastName" label="Last Name" />
              </el-table>
            </el-col>

            <el-col :span="8">
              <el-table :data="courseCampaign.episodes" class="episode m-border">
                <el-table-column
                  prop="Title"
                  :label="$t('courseCampaign.episodes')"
                />
              </el-table>
            </el-col>
          </el-row>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('courseCampaign')

export default {
  name: 'AssignedPrograms',
  computed: {
    ...mapState({
      courseCampaign: (state) => state.courseCampaign,
      total: (state) => state.selectedCustomers.total,
      customers: (state) => state.selectedCustomers.customers
    })
  }
}
</script>

<style lang="scss" scoped>
.episode {
  width: 100%;
  max-height: 300px;
  overflow: auto;
}

.py-none {
  padding-top: 0%;
  padding-bottom: 0%;
}

.m-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.m-title {
  font-size: 14px;
  font-weight: 700;
  color: #606266;
}

.m-border {
  border: 1px solid #cfd0d1;
  border-radius: 5px;
}
</style>
