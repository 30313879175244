import i18n from '@/i18n'
import { User } from '@/models/user'

export const validateEmail = (rule, value, callback) => {
  if (!value) {
    callback(new Error(i18n.t('forgotPassword.emailRequiredError')))
  } else if (!User.isValidEmail(value)) {
    callback(new Error(i18n.t('forgotPassword.invalidEmailError')))
  } else {
    callback()
  }
}
