import { createResource, fectchResource } from '@/utils/httpRequests'

export default class AccountService {
  static getAll() {
    return fectchResource(
      `${process.env.VUE_APP_CONTENTPART_API}/user/account`
    )
  }

  static getAccountMatchType({ account, subAccount, isExtId }) {
    if (subAccount) {
      return !isExtId
        ? 'customermatchfullaccount'
        : 'customermatchfullaccountEI'
    }

    if (account) {
      return !isExtId ? 'customermatchaccount' : 'customermatchaccountEI'
    }

    return !isExtId ? 'customermatch' : 'customermatchEI'
  }

  static getSampleCustomer() {
    return fectchResource(`${process.env.VUE_APP_CONTENTPART_API}/user/sample`)
  }

  static matchCustomers({ data, matchType }) {
    return createResource({
      url: `${process.env.VUE_APP_CONTENTPART_API}/user/${matchType}`,
      data
    })
  }

  static updateUser(data, isExtId) {
    const url = isExtId ? 'updateuserEI' : 'updateuser'
    return createResource({
      url: `${process.env.VUE_APP_CONTENTPART_API}/user/${url}`,
      data
    })
  }

  static deactivateUser(data, isExtId) {
    const url = isExtId ? 'deactivateEI' : 'deactivate'
    return createResource({
      url: `${process.env.VUE_APP_CONTENTPART_API}/user/${url}`,
      data
    })
  }
}
