import AssistantService from '@/api/assistants'
import { MUTATION_TYPES } from '../mutations'
import { Assistant } from '@/models/assistant'

const MUTATION_TYPE = MUTATION_TYPES.ASSISTANT

const state = {
  isLoading: false,
  assistant: new Assistant(),
  assistantFiles: []
}

const mutations = {
  [MUTATION_TYPE.SET_ASSISTANT](state, assistant = new Assistant()) {
    state.assistant = new Assistant(assistant)
  },

  [MUTATION_TYPE.SET_IS_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  },

  [MUTATION_TYPE.SET_FILES_TO_BE_DELETED]: (state, filesToBeDeleted) => {
    state.assistantFiles = filesToBeDeleted
  },

  [MUTATION_TYPE.ADD_FILE_TO_BE_DELETED]: (state, fileToBeDeleted) => {
    state.assistantFiles.push(fileToBeDeleted)
  }
}
const actions = {
  async fetchById({ commit }, id) {
    commit(MUTATION_TYPE.SET_IS_LOADING, true)
    try {
      const assistant = await AssistantService.fetchById(id)
      commit(MUTATION_TYPE.SET_ASSISTANT, assistant)
      return Promise.resolve(null)
    } catch (error) {
      throw Promise.reject(error)
    } finally {
      commit(MUTATION_TYPE.SET_IS_LOADING, false)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
