<template>
  <div class="div-center">
    <div class="inner-div">
      <h1 class="title-container">{{ $t('updatedResultSendList.result') }}</h1>

      <div class="margin-bottom">
        <h2 v-if="UpdatedUser.length"><span class="number">{{ UpdatedUser.length }}</span>{{ $t('updatedResultSendList.existinglist') }}</h2>
        <p v-for="contact in UpdatedUser" :key="contact.id" class="subtitle">
          <span class="dot" /> {{ contact.email }}
        </p>
      </div>

      <div>
        <h2 v-if="newCreatedUser.length"><span class="number">{{ newCreatedUser.length }}</span>{{ $t('updatedResultSendList.newlist') }}</h2>
        <p v-for="contact in newCreatedUser" :key="contact.id" class="subtitle">
          <span class="dot" /> {{ contact.email }}
        </p>
      </div>

    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import UpdateSendListService from '@/api/updateSendList'
import { MUTATION_TYPES } from '@/store/mutations'
import { ROUTE_PATH } from '@/router'
const { mapState: mapAppState, mapMutations: mapAppMutations } = createNamespacedHelpers('app')

export default {
  name: 'UpdatedResult',
  data() {
    return {
      newCreatedUser: [],
      UpdatedUser: [],
      currentDate: new Date().getDate()
    }
  },
  computed: {
    ...mapAppState({
      updatedSendListID: (state) => state.updatedSendListID,
      updatedSendList: (state) => state.updatedSendList
    })
  },
  async created() {
    if (this.updatedSendListID === 0) {
      this.$router.push(ROUTE_PATH.CUSTOMERS)
    }
    const response = await UpdateSendListService.getContactsByList(this.updatedSendListID)

    const filterData = this.handleFilterData(response)

    this.splitcontacts(filterData)
  },
  destroyed() {
    this.setSendListID(0)
    this.setSendList([])
  },
  methods: {
    ...mapAppMutations({
      setSendListID: MUTATION_TYPES.APP.SET_UPDATED_SEND_LIST_ID,
      setSendList: MUTATION_TYPES.APP.SET_UPDATED_SEND_LIST
    }),
    splitcontacts(response) {
      this.newCreatedUser = response.filter((val) => {
        return this.getDate(val.createdAt) === this.currentDate || this.getDate(val.createdAt) === this.getDate(val.modifiedAt)
      })
      this.UpdatedUser = response.filter((val) => {
        return this.getDate(val.createdAt) !== this.currentDate && this.getDate(val.modifiedAt) === this.currentDate
      })
    },
    getDate(currentDate) {
      return new Date(currentDate).getDate()
    },
    handleFilterData(response) {
      return response.filter((val) => {
        return this.updatedSendList.find((value) => value.EMail === val.email)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.div-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  margin: 10px;
  min-height: 90vh;
}

.inner-div {
    width: 90%;
    min-height: 80vh;
}

.margin-bottom {
    margin-bottom: 70px ;
}

p {
    margin-left: 30px;
}
.number {
    margin-right: 10px;
    color: #007bff;
    margin-right: 5px;
}
.dot {
  height: 8px;
  width: 8px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

</style>
