import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('customer')
export default {
  async mounted() {
    this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
    const { id } = this.$router.history.current.params
    try {
      this.allHistories = await this.fetchChatHistory(id)
      this.filteredHistories = this.filterUniqueSessionIds(this.allHistories)
    } finally {
      this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
    }
  },
  methods: {
    ...mapActions(['fetchChatHistory']),
    hanldeShowHistory(event) {
      this.isHistoryDialog = true
      this.customerData = this.allHistories
      this.sessionId = event.row.data.SessionID
    },
    handleDialogClose() {
      this.isHistoryDialog = false
    },
    hanldeLinkToAssistantDefaults(event) {
      this.$router.push(`/chat-bot/assistant-defaults?id=${event.row.data.AssistantDefaultsID}`)
    },
    filterUniqueSessionIds(array) {
      const sessionIdsSet = new Set()
      const uniqueObjects = []
      array.forEach(obj => {
        if (Object.prototype.hasOwnProperty.call(obj, 'SessionID')) {
          if (!sessionIdsSet.has(obj.SessionID)) {
            uniqueObjects.push(obj)
            sessionIdsSet.add(obj.SessionID)
          }
        }
      })
      return uniqueObjects
    }

  }
}
