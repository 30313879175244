import {
  createResource,
  deleteResource,
  fectchResource
} from '@/utils/httpRequests'

export default class AuthorsByMediaService {
  static create({ MediaID, AuthorsID, SiteID }) {
    return createResource({
      url: `${process.env.VUE_APP_ODATA_API}/AuthorsByMedia`,
      data: {
        AuthorsID,
        MediaID,
        SiteID
      }
    })
  }

  static getById({ id }) {
    return fectchResource(
      `${process.env.VUE_APP_ODATA_API}/AuthorsByMedia?$filter=AuthorsID eq ${id}`
    )
  }

  static delete(id) {
    return deleteResource(
      `${process.env.VUE_APP_ODATA_API}/AuthorsByMedia(${id})`
    )
  }
}
