export class AttributeSelection {
  constructor({
    logo = '',
    site = '',
    signature = '',
    sso = false,
    contactPersonPosition = '',
    utskick = false,
    utskick2 = '',
    shortSignature = ''
  }) {
    this.logo = logo
    this.site = site
    this.signature = signature
    this.sso = sso
    this.shortSignature = shortSignature
    this.contactPersonPosition = contactPersonPosition
    this.utskick = utskick
    this.utskick2 = utskick2
  }

  initialize(customersAccount) {
    const {
      Logo,
      UseSSO,
      Signature,
      SitePortal,
      NewManagerProgram,
      ContactPersonPosition,
      ShortSignature
    } = customersAccount
    this.logo = Logo
    this.site = SitePortal
    this.signature = Signature
    this.sso = UseSSO
    this.shortSignature = ShortSignature
    this.contactPersonPosition = ContactPersonPosition
    this.utskick2 = NewManagerProgram
  }

  reset() {
    this.logo = ''
    this.site = ''
    this.signature = ''
    this.sso = false
    this.utskick2 = ''
    this.contactPersonPosition = ''
    this.shortSignature = ''
  }

  createBrevoPayload({
    customerDetails,
    sendListId,
    includeUtskick2,
    updatedDate
  }) {
    return {
      email: customerDetails.EMail,
      listIds: [sendListId],
      attributes: {
        FIRSTNAME: customerDetails.FirstName,
        LASTNAME: customerDetails.LastName,
        LOGO: this.logo,
        SITE: this.site,
        SSO: this.sso,
        ACCOUNT: customerDetails.Account,
        SUBACCOUNT: customerDetails.SubAccount,
        LASTLOGINDATE: customerDetails.LastLoginDate,
        EXT_ID: customerDetails.ExtID,
        KUND: customerDetails.Company,
        ...(this.shortSignature && {
          SHORTSIGNATUR: this.shortSignature
        }),
        ...(this.contactPersonPosition && {
          BEFATTNING: this.contactPersonPosition
        }),
        ...(this.utskick ? { UTSKICK: 'Emutskick' } : {}),
        ...(includeUtskick2 ? { UTSKICK2: this.utskick2 } : {}),
        CUSTOMERS_ADDEDDATE: customerDetails.AddedDate,
        SIGNATUR: this.signature,
        PASSWORD: customerDetails.PassWord,
        UID: customerDetails.UID,
        LASTUPDATE: updatedDate
      }
    }
  }
}
