<template>
  <ODataTable
    :columns="columns"
    :add-edit-columns="addEditColumns"
    resource="CoWorkerCourseAssigned"
    :total-label="$t('assignedCoWorkersCourses.total')"
    :file-name="$t('assignedCoWorkersCourses.fileName')"
    :add-row-button-label="$t('assignedCoWorkersCourses.add')"
    :title="$t('assignedCoWorkersCourses.title')"
    @rowInit="handleRowInit"
    @editorPreparing="handleEditorPreparing"
  />
</template>
<script>
import odataTableEventsMixin from '/src/mixin/odataTableEventsMixin'
import { columns, addEditColumns, disabledColumns, dateColumns } from './config'

export default {
  name: 'CoWorkersCourseAssigned',
  mixins: [odataTableEventsMixin],
  data() {
    return {
      columns,
      addEditColumns,
      disabledColumns,
      dateColumns
    }
  }
}
</script>
