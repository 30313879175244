<template>
  <div class="app-container">
    <el-container>
      <el-header>
        <el-row type="flex">
          <el-col class="left-align">
            <div class="heading">
              {{ `${$t(`${testSection}.title`)}` }}
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-main class="dx-viewport">
        <add-edit-questions-and-their-answers :is-editing="isEditing" @nextClick="goToNextStep" />
      </el-main>
    </el-container>
  </div>
</template>
<script>
import AddEditQuestionsAndTheirAnswers from './addEditQuestionsAndTheirAnswers.vue'
import { createNamespacedHelpers } from 'vuex'
const { mapActions, mapState } = createNamespacedHelpers('test')
export default {
  name: 'CreateTest',
  components: {
    AddEditQuestionsAndTheirAnswers
  },
  computed: {
    isEditing() {
      return !!this.$router.history.current.params.id
    },
    testSection() {
      return this.isEditing ? 'test.edit' : 'test.create'
    },
    ...mapState({
      test: (state) => state.test
    })

  },
  destroyed() {
    this.test.clear()
  },
  methods: {
    ...mapActions(['createTest', 'updateQuestionsAndTheirAnswers']),
    async goToNextStep() {
      try {
        this.isEditing ? await this.updateQuestionsAndTheirAnswers() : await this.createTest()
      } catch (error) {
        this.$message({ type: 'error', message: error.message })
      }
    }
  }
}
</script>
