import Store from '@/models/odataStore'
import NonODataStore from '@/models/NonOdataStore'
export const mediaIdField = 'Media'
export const uploadFileField = 'UploadFile'
export const ASSISTANT_TOOL_TYPES = Object.freeze({
  RETRIEVAL: 'retrieval',
  CODE_INTERPRETER: 'code_interpreter',
  FUNCTION: 'function'
})
export const createColumns = ({ medias, selectedAssistants, mode, accept }) => {
  const modalStore = new NonODataStore({ resource: 'models' })
  const infoColumns = [
    {
      field: 'name',
      label: 'assistants.column.name',
      width: '100%'
    },
    {
      field: 'instructions',
      label: 'assistants.column.instructions',
      width: '100%'
    },
    {
      field: 'description',
      label: 'assistants.column.description',
      width: '100%'
    },
    {
      field: 'model',
      label: 'assistants.column.model',
      width: '100%',
      required: true,
      requiredMessage: 'assistants.requiredMessage.model',
      lookup: {
        store: modalStore,
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'id',
      displayExpr: 'id'
    },
    {
      field: 'Media',
      label: 'media',
      width: '100%',
      lookup: {
        store: new Store({ resource: 'Media' }),
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'ID',
      displayExpr: 'Title',
      calculateCellValue: row => {
        return medias.find(media => media.assistantId === row.id)?.value
      }
    },
    {
      field: 'file_ids',
      label: 'File Ids',
      width: '100%',
      calculateCellValue: data => {
        return data?.file_ids?.length ? JSON.stringify(data.file_ids) : ''
      }
    }
  ]

  const toolsColumns = [
    {
      field: 'tools',
      label: 'assistants.tools.title',
      width: '100%',
      calculateCellValue: data => {
        if (data.tools?.payload) {
          if (!data.tools.payload.length) {
            return ''
          }
          return JSON.stringify(data.tools.payload)
        } else {
          if (data.tools?.length) {
            return JSON.stringify(data.tools)
          }
          return ''
        }
      }
    }
  ]

  const metaColumns = [
    {
      field: 'metadata',
      label: 'assistants.metaContents.title',
      width: '100%',
      calculateCellValue: data => {
        if (data.metadata?.payload) {
          if (!Object.values(data.metadata.payload).length) {
            return ''
          }
          return JSON.stringify(data.metadata.payload)
        } else {
          if (data.metadata) {
            return JSON.stringify(data.metadata)
          }
          return ''
        }
      }
    }
  ]

  const fileColumns = [
    {
      field: uploadFileField,
      label: 'Upload File',
      width: '100%',
      editCellTemplate: 'file-item',
      type: 'assistant',
      accept,
      // isDisabled,
      selectedAssistants,
      mode,
      visible: false
    }
  ]

  const addEdit = [
    ...infoColumns,
    ...fileColumns,
    ...toolsColumns,
    ...metaColumns
  ]

  const columns = {
    addEdit,
    infoColumns,
    toolsColumns,
    metaColumns,
    fileColumns,
    all: [
      {
        field: 'id',
        label: 'assistants.column.id',
        width: '100%'
      },
      ...addEdit
    ]
  }
  return columns
}
