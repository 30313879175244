<template>
  <div class="app-container">
    <el-dialog
      v-if="isHistoryDialog"
      title="Chat History"
      :visible.sync="isHistoryDialog"
      @close="handleDialogClose"
    >
      <chatHistory
        v-if="isHistoryDialog"
        :assistant="assistant"
        :customer-data="customerData"
        :session-id="sessionId"
      />
    </el-dialog>
    <ODataTable
      ref="oDataTableComponentRef"
      :columns="columns"
      :add-edit-columns="columns"
      :total-label="$t('customers.chatHistory.total')"
      :file-name="$t('customers.chatHistory.fileName')"
      :title="$t('customers.chatHistory.title')"
      :is-static-data-source="true"
      :static-data="filteredHistories"
      :should-show-add-button="false"
      :should-show-edit-button="false"
      :should-show-delete-button="false"
    >
      <template #custom-buttons>
        <DxButton :icon="chatHistoryIconUrl" :on-click="hanldeShowHistory" />
        <DxButton icon="link" :on-click="hanldeLinkToAssistantDefaults" />
      </template>
    </ODataTable>
  </div>
</template>

<script>

import { columns } from './config'
import ODataTable from '@/components/ODataTable'
import chatHistory from '@/components/ChatHistory'
import { DxButton } from 'devextreme-vue/data-grid'
import mixin from './mixin'
export default {
  name: 'UserChatHistory',
  components: {
    ODataTable,
    chatHistory,
    DxButton
  },
  mixins: [mixin],
  data() {
    return {
      allHistories: [],
      filteredHistories: [],
      columns,
      isHistoryDialog: false,
      assistant: {},
      customerData: [],
      sessionId: '',
      chatHistoryIconUrl: '/assets/icons/chat-history.svg'
    }
  }
}
</script>
<style>
div[aria-label='Chat History'] {
  width:400px !important;
}

.dx-icon.dx-link-icon {
  height: 15px !important;
  position: relative;
  top: 5px;
}
</style>

