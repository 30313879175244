import ODataTable from '../../components/ODataTable'
import { columns, addEditColumns } from './config'
import { DxButton } from 'devextreme-vue/data-grid'

import Detail from './details/index'
import { TEST_TYPE } from '@/models/test'
import { MUTATION_TYPES } from '@/store/mutations'
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(
  'reflection'
)
const { mapState: mapUserState } = createNamespacedHelpers('user')
const REVIEW_URL = '/follow-up/reflections/review'
import ReportFilters from './filters.vue'
export default {
  name: 'Reflection',
  components: {
    ODataTable,
    DxButton,
    Detail,
    ReportFilters
  },
  data() {
    return {
      columns,
      addEditColumns,
      questionType: TEST_TYPE.REFLECTION,
      selectedReflection: null,
      showDetails: false,
      isReportsFilterDialogOpen: false,
      defaultType: 'primary',
      filter: []
    }
  },
  computed: {
    ...mapUserState({
      canReview: state => state.user.canAccess(REVIEW_URL)
    }),
    ...mapState({
      selectedReflectionIds: state => state.reflectionIds,

      filters: state => state.filters
    }),
    reviewModeEnabled: {
      get: function() {
        return this.filters.reviewModeEnabled
      },

      set: function(reviewModeEnabled) {
        this.clearReflectionIds()

        this.filters.reviewModeEnabled = reviewModeEnabled
      }
    },
    aliasResource() {
      if (!this.filters.account) {
        return ''
      }
      return `tTestCustomerAnswerPortal/Default.GetFromAccount(sAccount='${this.filters.account}')`
    }
  },
  async created() {
    await this.handlerReportsFilter()
  },
  methods: {
    ...mapActions(['clearReflectionIds', 'fetchCustomersByAccount']),
    ...mapMutations({
      reflectionIds: MUTATION_TYPES.REFLECTION.SET_REFLECTION_IDS
    }),

    handleRowInit(event) {
      event.data.DateAdded = new Date().toISOString()
      event.data.LastUpdate = new Date().toISOString()
    },

    handleRowUpdating(event) {
      event.newData.LastUpdate = new Date().toISOString()
    },

    handleViewClick(event) {
      this.selectedReflection = event.row.data

      this.showDetails = true
    },
    handleDetailsDialogClose() {
      this.selectedReflection = null

      this.showDetails = false
    },
    handleReportsDetailsDialogOpen() {
      this.isReportsFilterDialogOpen = true
    },
    handleReportsDetailsDialogClose() {
      this.isReportsFilterDialogOpen = false
    },

    isApproved(event) {
      return event.row.data.IsApproved
    },

    isRejected(event) {
      return event.row.data.IsApproved === false
    },

    hasNotBeenReviewed(event) {
      return event.row.data.IsApproved === null
    },
    redirectToReviewPage() {
      this.$router.push(REVIEW_URL)
    },
    handleSelectionChange({ selectedRowsData }) {
      this.reflectionIds(selectedRowsData)
    },
    async handlerReportsFilter() {
      this.isReportsFilterDialogOpen = false

      this.filter = this.filters.filters
    },
    handlerCloseReportFilter() {
      this.isReportsFilterDialogOpen = false
      this.handlerReportsFilter()
    },
    handleFilterApply() {
      this.filters.isApplied = true
      this.handlerReportsFilter()
    }
  }
}
