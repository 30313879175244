<template>
  <div class="app-container">
    <el-container>
      <el-main class="py-none">
        <el-row>
          <el-col :sm="18" :xs="24">
            <div v-for="(courseCampaign, index) in courseCampaigns" :key="index" class="m-border-dark bg-gray-light">
              <div class="bg-gray-dark m-border-dark px-3 bottom-plane">
                <el-row type="flex" class="center py-3">
                  <el-col
                    :span="4"
                    class="m-title text-white"
                  >{{
                    $t("courseCampaign.overview.details.email") + " " + (index + 1)
                  }}.
                  </el-col>
                  <el-col :span="20" />
                </el-row>
              </div>

              <div class="px-3">
                <el-row type="flex" class="center py-3">
                  <el-col
                    :span="4"
                    class="m-title"
                  >{{ $t("courseCampaign.overview.details.name") }} :</el-col>
                  <el-col :span="20">{{ courseCampaign.name || null }} </el-col>
                </el-row>
                <el-row type="flex" class="center py-3">
                  <el-col
                    :span="4"
                    class="m-title"
                  >{{ $t("courseCampaign.overview.details.recipients") }} :</el-col>
                  <el-col :span="20">{{ courseCampaign.recipients || null }} </el-col>
                </el-row>
                <el-row type="flex" class="center py-3">
                  <el-col
                    :span="4"
                    class="m-title"
                  >{{ $t("courseCampaign.overview.details.ePostmail") }} :</el-col>
                  <el-col
                    :span="20"
                  >{{ getTemplate(courseCampaign.templateId) }}
                  </el-col>
                </el-row>
                <el-row type="flex" class="center py-3">
                  <el-col
                    :span="4"
                    class="m-title"
                  >{{ $t("courseCampaign.overview.details.senderName") }} :</el-col>
                  <el-col :span="20">{{ courseCampaign.sender.name || null }} </el-col>
                </el-row>
                <el-row type="flex" class="center py-3">
                  <el-col
                    :span="4"
                    class="m-title"
                  >{{
                    $t("courseCampaign.overview.details.senderAddress")
                  }} :
                  </el-col>
                  <el-col :span="20">{{ courseCampaign.sender.email || null }} </el-col>
                </el-row>
                <el-row type="flex" class="center py-3">
                  <el-col
                    :span="4"
                    class="m-title"
                  >{{ $t("courseCampaign.overview.details.subject") }} :</el-col>
                  <el-col :span="20">{{ courseCampaign.subject || null }} </el-col>
                </el-row>
                <el-row type="flex" class="center py-3">
                  <el-col
                    :span="4"
                    class="m-title"
                  >{{ $t("courseCampaign.overview.details.previewText") }} :</el-col>
                  <el-col :span="20">{{ courseCampaign.previewText || null }} </el-col>
                </el-row>
                <el-row type="flex" class="center py-3">
                  <el-col
                    :span="4"
                    class="m-title"
                  >{{ $t("courseCampaign.overview.details.introText") }} :</el-col>
                  <el-col :span="20">{{ courseCampaign.introText || null }} </el-col>
                </el-row>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import { MUTATION_TYPES } from '@/store/mutations'
import { hasKey } from '@/utils/validate'
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapState, mapMutations } = createNamespacedHelpers('courseCampaign')

export default {
  name: 'Overview',
  data() {
    return {
      emailTemplates: []
    }
  },
  computed: {
    ...mapState({
      courseCampaigns: state => state.courseCampaign.courseCampaigns,
      miscellaneous: state => state.miscellaneous
    }),
    ...mapGetters(['name'])
  },

  watch: {},
  created() {
    if (hasKey(this.miscellaneous, 'emailTemplates')) {
      this.emailTemplates = this.miscellaneous?.emailTemplates
    }
  },
  methods: {
    ...mapMutations({
      setCourseCampaign: MUTATION_TYPES.COURSE_CAMPAIGN.SET_COURSE_CAMPAIGN
    }),
    getTemplate(templateId) {
      if (templateId) {
        const emailTemplate = this.emailTemplates.find(template => template.id === templateId)
        return emailTemplate?.name ?? null
      }
      return null
    }
  }
}
</script>

<style>
.fw-900 {
  font-weight: 900;
}

.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-none {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.px-3 {
  padding-left: 12px;
  padding-right: 12px;
}

.my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.m-border {
  border: 1px solid #cfd0d1;
  border-radius: 5px;
}

.m-border-dark {
  border: 1px solid #304156;
  border-radius: 5px;
}

.bg-gray-light {
  background-color: #f1f1f1;
  margin-bottom: 12px;
}

.m-title {
  font-size: 14px;
  font-weight: 700;
  color: #606266;
}

.bg-gray-dark {
  background-color: #304156;
}

.bottom-plane {
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
}

.text-white {
  color: #fff
}
</style>
