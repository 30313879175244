import { login, logout, getInfo, verify } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'
import i18n from '@/i18n'
import { MUTATION_TYPES as BASE_MUTATION_TYPES } from '../../mutations'
import { User } from '@/models/user'
import MenuService from '@/api/menus'
import { Message } from 'element-ui'
import router from '@/router'
import { sendForgotPasswordLink } from '@/api/user'

const getDefaultState = () => {
  return {
    token: getToken(),
    user: new User(),
    isLoading: false
  }
}

const ERROR_MESSAGES = {
  LOGIN_ERROR: 'Kontrollera användarnamn och lösenord.'
}

const MUTATION_TYPES = BASE_MUTATION_TYPES.USER

const state = getDefaultState()

const mutations = {
  [MUTATION_TYPES.RESET_STATE]: state => {
    Object.assign(state, getDefaultState())
  },

  [MUTATION_TYPES.SET_TOKEN]: (state, token) => {
    state.token = token
  },

  [MUTATION_TYPES.SET_USER]: (state, user) => {
    state.user = new User(user)
  },

  [MUTATION_TYPES.SET_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password })
        .then(response => {
          if (response === ERROR_MESSAGES.LOGIN_ERROR) {
            throw new Error(i18n.t('user.loginError'))
          }

          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  async verify({ commit, dispatch }, userInfo) {
    const { username, password, logInCode } = userInfo
    try {
      const response = await verify({
        username: username.trim(),
        password: password,
        loginCode: logInCode
      })
      commit(MUTATION_TYPES.SET_TOKEN, response)
      setToken(response)
      dispatch('app/fetchMenus', null, { root: true })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject()
    }
  },

  // get user info
  async getInfo({ commit }) {
    try {
      const response = await getInfo()
      if (!response) {
        throw new Error('Verification failed, please Login again.')
      }

      const [menus, { value: disabledMenus }] = await Promise.all([
        MenuService.fetchByUserId(response.ID),

        MenuService.fetchDisabledByUserId(response.ID)
      ])

      response.menus = menus

      response.disabledMenus = disabledMenus
      commit(MUTATION_TYPES.SET_USER, response)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          removeToken() // must remove  token  first
          resetRouter()
          commit(MUTATION_TYPES.RESET_STATE)

          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  async forgotPassword({ commit }, email) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      await sendForgotPasswordLink(email)
      Message({
        message: i18n.t('forgotPassword.sentToEmail'),
        type: 'success'
      })
      commit(MUTATION_TYPES.SET_LOADING, false)
      router.push('/')
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPES.SET_LOADING, false)
      return Promise.reject(error)
    }
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit(MUTATION_TYPES.RESET_STATE)

      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
