<template>
  <div class="column-flex">
    <el-input
      v-model="pastedDownloadables"
      :rows="10"
      type="textarea"
      @input="handleChange"
    />
    <el-button
      type="primary"
      class="margin-left-md self-start"
      :loading="isLoading"
      :disabled="!pastedDownloadables.length"
      @click="handleImportClick"
    >{{
      $t("manageDownloadables.importCsv.organizeDownloadables")
    }}</el-button>
  </div>
</template>
<script>
import { ImportedDownloadables } from '@/models/importedDownloadables'
import { nextClickEvent } from './config'
import { createNamespacedHelpers } from 'vuex'
import { MUTATION_TYPES } from '@/store/mutations'
const {
  mapMutations: mapDownloadableMutations
} = createNamespacedHelpers('importedDownloadables')
export default {
  name: 'ImportUsers',
  data() {
    return {
      pastedDownloadables: '',
      isLoading: false
    }
  },
  methods: {
    ...mapDownloadableMutations({
      setTransformToHtmlState: MUTATION_TYPES.IMPORTED_DOWNLOADBALES.SET_TRANSFORM_TO_HTML_STATE,
      setDownloadables: MUTATION_TYPES.IMPORTED_DOWNLOADBALES.SET_DOWNLOADABLES
    }),
    handleImportClick() {
      this.$emit(nextClickEvent)
      this.setTransformToHtmlState(false)
    },
    handleChange() {
      this.isLoading = true
      const editedDownloadablesData = this.pastedDownloadables
      if (!editedDownloadablesData) {
        this.setDownloadables([])
        return
      }
      const importedDownloadables = ImportedDownloadables.createFromFile(editedDownloadablesData)
      this.setDownloadables(importedDownloadables)
      this.isLoading = false
    }
  }
}
</script>

  <style lang="scss" src="./style.scss" scoped />
