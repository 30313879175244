var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      !_vm.hasParent
        ? _c(
            "el-header",
            { attrs: { height: "auto" } },
            [
              _c(
                "el-row",
                { attrs: { type: "flex" } },
                [
                  _c("el-col", { staticClass: "left-align" }, [
                    _c("span", { staticClass: "heading" }, [
                      _vm._v(_vm._s(_vm.title))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-main",
        { staticClass: "dx-viewport" },
        [
          _vm._t("custom-filters"),
          _c(
            "DxDataGrid",
            {
              ref: "oDataTableRef",
              class: {
                "dx-with-expand": _vm.shouldRenderMasterDetail,
                "dx-without-expand": !_vm.shouldRenderMasterDetail
              },
              attrs: {
                "data-source": _vm.showData ? _vm.dataSource : null,
                "show-borders": true,
                "allow-column-resizing": _vm.allowColumnResizing,
                "remote-operations": _vm.isCustomDataSource || "auto"
              },
              on: {
                "init-new-row": _vm.handleRowInit,
                "row-inserted": _vm.handleRowInserted,
                "row-updated": _vm.handleRowUpdated,
                "row-removed": _vm.handleRowRemoved,
                "toolbar-preparing": _vm.handleToolbarPreparing,
                "editor-preparing": _vm.handleEditorPreparing,
                "row-expanded": _vm.rowExpanded,
                "row-collapsed": _vm.handelRowCollapsed,
                "selection-changed": _vm.handleSelectionChange,
                "content-ready": _vm.handleContentReady,
                "cell-click": _vm.handleCellClick,
                "row-updating": _vm.handleRowUpdating,
                "editing-start": _vm.handleEditingStart,
                "cell-prepared": _vm.handleCellPrepared,
                "edit-canceling": _vm.handleEditingCancelling,
                "row-inserting": _vm.handleRowInserting,
                saved: _vm.handleRowSaved,
                saving: _vm.handleRowSaving,
                exporting: _vm.handleExporting,
                "data-error-occurred": _vm.handleDataErrorOccurred
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "file-item",
                    fn: function(ref) {
                      var data = ref.data
                      return [
                        _c("FileUploader", {
                          ref: "dxFileUploaderRef",
                          attrs: { field: data },
                          on: {
                            fileToBeDeleted: _vm.handleFileToBeDeleted,
                            fileUploaded: _vm.handleFileUploaded
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "checkbox-item",
                    fn: function(ref) {
                      var data = ref.data
                      return [_c("checkbox-item", { attrs: { field: data } })]
                    }
                  },
                  {
                    key: "custom-toolbar-before",
                    fn: function() {
                      return [_vm._t("custom-toolbar-before")]
                    },
                    proxy: true
                  },
                  {
                    key: "custom-toggle-before",
                    fn: function() {
                      return [_vm._t("custom-toggle-before")]
                    },
                    proxy: true
                  },
                  {
                    key: "add",
                    fn: function() {
                      return [
                        _vm.shouldShowAddButton
                          ? _c("ButtonWithText", {
                              attrs: {
                                icon: "add",
                                text: _vm.addRowButtonLabel,
                                type: "default",
                                "styling-mode": "outlined"
                              },
                              on: { click: _vm.addRow }
                            })
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "clear-filters",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          [
                            _vm.hasFilters
                              ? _c("ButtonWithText", {
                                  attrs: {
                                    type: "default",
                                    "styling-mode": "outlined",
                                    icon: "close",
                                    text: _vm.$t("clear")
                                  },
                                  on: { click: _vm.handleFiltersClear }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "total",
                    fn: function() {
                      return [
                        _c("span", { staticClass: "total" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.totalLabel + ": " + _vm.total) +
                              " "
                          )
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "custom-toolbar-after",
                    fn: function() {
                      return [_vm._t("custom-toolbar-after")]
                    },
                    proxy: true
                  },
                  {
                    key: "custom-button-template",
                    fn: function(ref) {
                      var data = ref.data
                      return [_vm._t("toggle-button", null, { data: data })]
                    }
                  },
                  {
                    key: "detail",
                    fn: function(ref) {
                      var data = ref.data
                      return [_vm._t("details", null, { data: data.row.data })]
                    }
                  }
                ],
                null,
                true
              )
            },
            [
              _c(
                "DxEditing",
                {
                  attrs: {
                    "allow-updating":
                      !_vm.isCustomEdit && _vm.shouldShowEditButton,
                    "allow-deleting": _vm.shouldShowDeleteButton,
                    "confirm-delete": _vm.shouldShowDeleteButton,
                    "use-icons": true,
                    mode: "popup"
                  }
                },
                [
                  _vm.customDeleteMessage
                    ? _c("DxTexts", {
                        attrs: {
                          "confirm-delete-message": _vm.customDeleteMessage
                        }
                      })
                    : _vm._e(),
                  !_vm.isCustomForm
                    ? _c(
                        "DxForm",
                        { ref: "dxFormRef" },
                        _vm._l(_vm.addEditColumns, function(column) {
                          return _c(
                            "DxSimpleItem",
                            {
                              key: "add_edit_" + column.field,
                              ref: "dxSimpleItem",
                              refInFor: true,
                              attrs: {
                                "data-field": column.field,
                                "col-span": column.colSpan || 0,
                                "help-text": _vm.$t(column.helpText),
                                "editor-options":
                                  column.editorOptions || _vm.editorOptions,
                                "editor-type": column.editorName
                              }
                            },
                            [
                              _vm.isFieldEmail(column.field)
                                ? _c("DxEmailRule", {
                                    attrs: {
                                      message: _vm.$t(
                                        "forgotPassword.invalidEmailError"
                                      )
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.isCustomForm
                    ? _c(
                        "DxForm",
                        {
                          attrs: {
                            "col-count": _vm.columnCount,
                            template: "ss"
                          }
                        },
                        [_vm._t("custom-form")],
                        2
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("DxColumn", { attrs: { type: "selection", width: 50 } }),
              _c(
                "DxColumn",
                { attrs: { type: "buttons", width: _vm.actionButtonsWidth } },
                [
                  _vm.shouldShowEditButton
                    ? _c("DxButton", {
                        attrs: {
                          name: "edit",
                          visible: _vm.isEditDeleteButtonVisible
                        }
                      })
                    : _vm._e(),
                  _vm.isCustomEdit
                    ? _c("DxButton", {
                        attrs: { icon: "edit", "on-click": _vm.handleEditClick }
                      })
                    : _vm._e(),
                  _vm._t("custom-buttons"),
                  _vm.shouldShowDeleteButton
                    ? _c("DxButton", {
                        attrs: {
                          name: "delete",
                          visible: _vm.isEditDeleteButtonVisible
                        }
                      })
                    : _vm._e()
                ],
                2
              ),
              _vm._l(_vm.dataSource.columns, function(column) {
                return _c(
                  "DxColumn",
                  {
                    key: column.field,
                    attrs: {
                      "data-field": column.field,
                      width: column.width,
                      "min-width": column.minWidth,
                      alignment: column.alignment || "left",
                      "editor-options": {
                        onOpened: _vm.handleLookupPopupOpen,
                        disabledIds: column.disabledIds,
                        includeFileNameOnly: !!column.includeFileNameOnly,
                        folder: column.type,
                        accept: column.accept,
                        prop: column.prop,
                        cdnUrl: column.cdnUrl,
                        shouldUseFolderForPreview:
                          column.shouldUseFolderForPreview,
                        showAsTextBox: column.showAsTextBox,
                        // isDisabled: column.isDisabled,
                        selectedAssistants: column.selectedAssistants,
                        mode: column.mode,
                        shouldShowUploadedImagePreview:
                          column.shouldShowUploadedImagePreview
                      },
                      "sort-index": 1,
                      "sort-order": column.sort,
                      "edit-cell-template": column.editCellTemplate,
                      "data-type": column.dataType,
                      caption: column.label
                        ? _vm.$t(column.label)
                        : column.field,
                      format: column.format,
                      "calculate-cell-value": column.calculateCellValue,
                      "set-cell-value": column.setCellValue,
                      "allow-adding": false,
                      "allow-filtering": column.allowFiltering,
                      visible: column.visible,
                      "calculate-filter-expression":
                        column.calculateFilterExpression
                    }
                  },
                  [
                    column.lookup
                      ? _c("DxLookup", {
                          attrs: {
                            "data-source": column.lookup,
                            "value-expr": column.valueExpr || "value",
                            "display-expr": column.displayExpr || "label"
                          }
                        })
                      : _vm._e(),
                    column.required
                      ? _c("DxRequiredRule", {
                          attrs: { message: _vm.$t(column.requiredMessage) }
                        })
                      : _vm._e()
                  ],
                  1
                )
              }),
              _c("DxMasterDetail", {
                attrs: {
                  enabled: _vm.shouldRenderMasterDetail,
                  template: "detail"
                }
              }),
              _c("DxExport", {
                attrs: {
                  "file-name": _vm.fileName,
                  enabled: true,
                  "allow-export-selected-data": true,
                  formats: _vm.exportFormats
                }
              }),
              _c("DxFilterRow", { attrs: { visible: _vm.lookupFilter } }),
              _c("DxSelection", { attrs: { mode: "multiple" } }),
              _c("DxStateStoring", {
                attrs: {
                  enabled: _vm.isStateStored,
                  type: "localStorage",
                  "storage-key": "storage"
                }
              }),
              _c("DxPager", {
                attrs: {
                  "display-mode": "compact",
                  visible: true,
                  "show-page-size-selector": true,
                  "allowed-page-sizes": _vm.dataSource.allowedPageSizes,
                  "show-navigation-buttons": true
                }
              })
            ],
            2
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }