<template>
  <ODataTable
    ref="oDataTableComponentRef"
    :columns="columns"
    :add-edit-columns="addEditColumns"
    resource="Tag"
    :total-label="$t('tags.total')"
    :file-name="$t('tags.fileName')"
    :add-row-button-label="$t('tags.add')"
    :title="$t('tags.title')"
    :should-show-delete-button="false"
    @rowInit="handleRowInit"
    @editorPreparing="handleEditorPreparing"
    @rowInserted="handleRowInserted"
  >
    <template #custom-buttons>
      <DxButton icon="trash" :on-click="handleDelete" />
    </template>
  </ODataTable>
</template>

<script>
import ODataTable from '../../components/ODataTable'
import { columns, addEditColumns, dateAddedField } from './config'
import { DxButton } from 'devextreme-vue/data-grid'
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('tags')
import { Message } from 'element-ui'
import { confirm } from 'devextreme/ui/dialog'

export default {
  name: 'Tags',
  components: {
    ODataTable,
    DxButton
  },
  data() {
    return {
      columns,
      addEditColumns,
      tagIds: [],
      staticData: []
    }
  },
  methods: {
    ...mapActions(['delete', 'areUsersAssociatedWithTag']),
    handleRowInit(event) {
      event.data.AddedDate = new Date().toISOString()
    },
    handleEditorPreparing(event) {
      if (event.isOnForm) {
        event.editorOptions.disabled = [dateAddedField].includes(
          event.dataField
        )
      }
    },
    async handleDelete(event) {
      const haveUsersAssociatedWithTag = await this.areUsersAssociatedWithTag(event.row.data.ID)
      const result = await confirm(this.$t(haveUsersAssociatedWithTag ? 'tags.toggleConfirm' : 'toggleConfirm'))
      if (result) {
        this.handleToggleConfirm({ tagId: event.row.data.ID, isTagInUse: haveUsersAssociatedWithTag })
      }
    },
    async handleToggleConfirm({ tagId, isTagInUse }) {
      try {
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
        await this.delete({ tagId, isTagInUse })
        await this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.refresh()
        Message({
          message: this.$t('tag.deleted'),
          type: 'success'
        })
      } finally {
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
      }
    },
    handleRowInserted(event) {
      if (!event.data.ID) {
        this.$message({
          message: this.$t('validateTagName'),
          type: 'error'
        })
      }
    }
  }
}
</script>
