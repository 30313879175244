import { ChatQuestionByMediaFilters } from '@/models/chatQuestionByMediaFilters'
import ChatQuestionByMediaService from '@/api/chatQuestionsByMedia'
import { PaginatedResponse } from '@/models/paginatedResponse'
import { Message } from 'element-ui'

const state = {
  filters: new ChatQuestionByMediaFilters({})
}

const actions = {
  async fetchAll() {
    try {
      const response = await ChatQuestionByMediaService.getAll()
      const paginatedResponse = new PaginatedResponse(response)
      while (paginatedResponse.hasMore) {
        await paginatedResponse.next()
      }
      return Promise.resolve(paginatedResponse.items)
    } catch (error) {
      Message({
        type: 'error',
        message: error.message
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  actions
}
