export const requiredColumns = ['AuthorName']
export const idColumnField = 'ID'
const toggleColumns = ['IsActive']
const fileTypeColumns = ['MainImage']
export const addEditColumns = [
  ...requiredColumns,
  'Description',
  ...fileTypeColumns,
  ...toggleColumns
].map(field => ({
  field,
  width: 280,
  minWidth: 150,
  ...(requiredColumns.includes(field) && {
    required: true,
    requiredMessage: 'authors.column.authorName.requiredMessage'
  }),
  ...(toggleColumns.includes(field) && {
    dataType: 'boolean',
    alignment: 'center'
  }),
  ...(fileTypeColumns.includes(field) && {
    editCellTemplate: 'file-item',
    type: 'category'
  })
}))

export const columns = [
  { field: idColumnField, width: 280, minWidth: 150 },
  ...addEditColumns
]
