<template>
  <ODataTable
    :columns="columns.all"
    :add-edit-columns="columns.addEdit"
    :custom-delete-message="$t('assistants.tools.confirmDeleteMessage')"
    :total-label="$t('assistants.tools.total')"
    :title="$t('assistants.tools.title')"
    :is-static-data-source="true"
    :static-data="tools"
    :add-row-button-label="$t('assistants.tools.create')"
    :is-custom-form="true"
    :should-show-add-button="canAdd"
    :column-count="2"
    @editorPreparing="handleEditorPreparing"
  >
    <template #custom-form>
      <DxSimpleItem v-for="column in columns.types" :key="`add_edit_${column.field}`" :data-field="column.field" />
      <DxGroupItem v-if="isFunctionType" column="function" :caption="$t('assistants.tools.functionDetails')">
        <DxSimpleItem
          v-for="column in columns.functions"
          :key="`add_edit_${column.field}`"
          :data-field="column.field"
          :editor-type="column.editorName"
          :editor-options="column.editorOptions"
        />
      </DxGroupItem>
    </template>
  </ODataTable>
</template>
<script>
import ODataTable from '@/components/ODataTable'
import { columns } from './config'
import { AssistantToolsStore } from './store'
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations } = createNamespacedHelpers('assistant')
import { MUTATION_TYPES } from '@/store/mutations'
import { DxSimpleItem, DxGroupItem } from 'devextreme-vue/form'
import DxTextArea from 'devextreme-vue/text-area'

export default {
  name: 'AssistantsTools',
  components: {
    ODataTable,
    DxSimpleItem,
    DxGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    DxTextArea
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      columns,
      isFunctionType: false
    }
  },
  computed: {
    ...mapState({
      assistantTools: state => state.assistant.tools.assistantTools,
      canAdd: state => state.assistant.tools.canAdd
    }),
    tools() {
      return new AssistantToolsStore(this.assistantTools)
    }
  },
  created() {
    this.initialise(this.data)
  },
  methods: {
    ...mapMutations({
      initialise: MUTATION_TYPES.ASSISTANT.SET_ASSISTANT
    }),
    handleEditorPreparing(event) {
      if (event.isOnForm) {
        if (event.dataField === 'type') {
          this.isFunctionType = event.editorOptions.value === 'function'

          event.editorOptions.onValueChanged = (e) => {
            if (e.value) {
              event.setValue(e.value)
              this.isFunctionType = e.value === 'function'
            }
          }
        }
      }
    }
  }
}
</script>

