import { fectchResource } from '@/utils/httpRequests'
import ArrayStore from 'devextreme/data/array_store'
import { Deferred } from 'devextreme/core/utils/deferred'

const headers = {
  Accept: 'application/json',
  'api-key': process.env.VUE_APP_BREVO_API_KEY
}

export default class BrevoDataStore extends ArrayStore {
  constructor({
    resource,
    dataPath = 'folders',
    baseUrl = process.env.VUE_APP_BREVO_API_BASE_URL
  }) {
    super({
      key: 'id'
    })

    this.resource = resource
    this.baseUrl = baseUrl
    this.dataPath = dataPath
    this.response = []
  }
  load() {
    const promise = fectchResource(`${this.baseUrl}/${this.resource}`, {
      headers
    })
    return this.toPromise(promise)
  }

  toPromise(promise) {
    const deffered = Deferred()
    promise
      .then(item => {
        this.response = item?.[this.dataPath]
        return deffered.resolve(item?.[this.dataPath])
      })
      .catch(error => {
        deffered.reject(error)
      })

    return deffered.promise()
  }
}
