<template>
  <div class="login-container">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">

      <el-row class="title-container">
        <el-col :span="24">
          <h3 class="title">{{ $t('login') }}</h3>
        </el-col>
      </el-row>

      <el-form-item prop="username">
        <span class="svg-container">
          <svg-icon icon-class="user" />
        </span>
        <el-input
          ref="username"
          v-model="loginForm.username"
          :placeholder="$t('username')"
          name="username"
          type="text"
          tabindex="1"
          auto-complete="on"
        />
      </el-form-item>

      <el-form-item prop="password">
        <span class="svg-container">
          <svg-icon icon-class="password" />
        </span>
        <el-input
          :key="passwordType"
          ref="password"
          v-model="loginForm.password"
          :type="passwordType"
          :placeholder="$t('password')"
          name="password"
          tabindex="2"
          auto-complete="on"
          @keyup.enter.native="handleLogin"
        />
        <span class="show-pwd" @click="showPwd">
          <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
        </span>
      </el-form-item>

      <el-form-item v-if="showInputCode" prop="loginCode">
        <span class="svg-container">
          <svg-icon icon-class="password" />
        </span>
        <el-input
          ref="loginCode"
          v-model="loginForm.logInCode"
          :placeholder="$t('code')"
          name="loginCode"
          type="text"
          tabindex="1"
          @keyup.enter.native="handleVerify"
        />
      </el-form-item>

      <el-button v-if="!showInputCode" :loading="loading" type="primary" @click.native.prevent="handleLogin">{{ $t('login') }}</el-button>
      <el-button v-if="showInputCode" :loading="loading" type="primary" @click.native.prevent="handleVerify">{{ $t('Verify') }}</el-button>
      <el-button type="text" @click="handleForgotPassword">{{ $t('forgotPassword.link') }}</el-button>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    const validateUsername = (rule, value, callback) => {
      callback()
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('The password can not be less than 6 digits'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        username: 'dev@codinova.com',
        password: '',
        loginCode: ''
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      showInputCode: false
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  methods: {
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            await this.$store.dispatch('user/login', this.loginForm)
            this.showInputCode = true
            this.loading = false
            this.$message({ message: this.$t('user.codeSent'), type: 'success' })
          } catch (error) {
            this.loading = false
            this.$message({ message: this.$t('errors.invalidCredentials'), type: 'error' })
          }
        } else {
          return false
        }
      })
    },
    handleVerify() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            await this.$store.dispatch('user/verify', this.loginForm)
            this.$router.push(this.redirect || '/')
            this.loading = false
          } catch (error) {
            this.loading = false
            this.$message({ message: this.$t('errors.invalidCredentials'), type: 'error' })
          }
        } else {
          return false
        }
      })
    },
    handleForgotPassword() {
      this.$router.push('/forgot-password')
    }
  }
}
</script>

<style lang="scss" src="./index.scss">
</style>
<style lang="scss" src="./style.scss" scoped>
</style>
