import { nanoid } from './nanoid'

const generateFilename = file => {
  let fileName, extension
  if (file.name) {
    [fileName, extension] = file.name.split('.')
  } else {
    [fileName, extension] = file.split('.')
  }
  const uniqueSuffix = nanoid()
  return `${fileName}_${uniqueSuffix}.${extension}`
}

export default generateFilename

export const FOLDER = {
  SHARED: 'shared'
}

export const MAX_ALLOWED_FILES_FOR_DOWNLOAD = 10
