<template>
  <ODataTable
    :key="filter"
    ref="oDataTableComponentRef"
    :columns="columns"
    :add-edit-columns="addEdit"
    resource="CustomersAccount"
    :total-label="$t('customersAccount.total')"
    :file-name="$t('customersAccount.fileName')"
    :add-row-button-label="$t('customersAccount.add')"
    :title="$t('customersAccount.title')"
    :should-show-delete-button="false"
    :show-custom-toolbar-before-button="true"
    :filter="filter"
    @rowInit="handleRowInit"
    @rowUpdating="handleRowUpdating"
    @editorPreparing="handleEditorPreparing"
  >
    <template #custom-toolbar-before>
      <el-switch
        v-model="showActive"
        class="margin"
        inactive-text="Show active"
        @change="handleFilter"
      />
    </template>
    <template #custom-buttons>
      <DxButton icon="trash" :on-click="handleDelete" />
    </template>
  </ODataTable>
</template>
<script>
import ODataTable from '../../components/ODataTable'
import { columns, addEdit, toggleColumn, disabledColumns, readOnlyColumns } from './config'
import { DxButton } from 'devextreme-vue/data-grid'
import CustomersAccountService from '@/api/customersAccount'
import { confirm } from 'devextreme/ui/dialog'
import odataTableEventsMixin from '@/mixin/odataTableEventsMixin'

export default {
  name: 'CustomersAccount',
  components: {
    ODataTable,
    DxButton
  },
  mixins: [odataTableEventsMixin],
  data() {
    return {
      columns,
      addEdit,
      disabledColumns,
      readOnlyColumns,
      showActive: true,
      filter: []
    }
  },
  created() {
    this.handleFilter()
  },
  methods: {
    async handleDelete(event) {
      const response = await confirm(this.$t('deleteWarningMessage'))
      if (response) {
        try {
          this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
          await CustomersAccountService.delete(event.row.key)
          this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.refresh()
        } finally {
          this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
          this.$message({
            message: this.$t('deleteSuccess'),
            type: 'success'
          })
        }
      }
    },
    handleFilter() {
      this.filter = this.showActive ? [[`${toggleColumn}`, '=', true]] : []
    },
    handleRowUpdating(event) {
      event.newData.LastUpdate = new Date().toISOString()
    }
  }
}
</script>

<style>
.margin {
margin-left: 30px;
}
.el-switch__label {
  color: #409EFF;
}
.el-switch__label.is-active {
    color: black;
}
</style>
