<template>
  <div class="table-container">
    <el-table
      v-loading="isLoading"
      :data="list"
      :element-loading-text="$t('loading')"
      border
      fit
      size="medium"
      highlight-current-row
      height="75vh"
      @row-dblclick="editContentPart"
    >
      <!-- Column NAME -->
      <el-table-column
        :label="$t('name')"
        sortable
        sort-by="Name"
      >
        <el-table-column>
          <!-- eslint-disable -->
        <template slot="header" slot-scope="scope">
          <!-- eslint-enable -->
            <el-input
              v-model="filters.name"
              class="search-box"
              clearable
              size="mini"
              :placeholder="$t('search')"
            />
          </template>
          <template slot-scope="scope">
            {{ scope.row.Name }}
          </template>
        </el-table-column>
      </el-table-column>

      <!-- Column POSITION -->
      <el-table-column
        :label="$t('contentPart.position')"
        width="180"
        align="center"
        sortable
        sort-by="Position"
      >
        <el-table-column width="180">
          <!-- eslint-disable -->
        <template slot="header" slot-scope="scope">
          <!-- eslint-enable -->
            <content-part-select
              :placeholder="`${$t('search')} ${$t('contentPart.position')}`"
              :options="positions"
              show-all
              :selected.sync="filters.position"
              prop-name="Position"
              prop-value="Position"
              size="mini"
            />
          </template>
          <template slot-scope="scope">
            {{ scope.row.Position }}
          </template>
        </el-table-column>
      </el-table-column>

      <!-- Column TAG -->
      <el-table-column
        :label="$t('contentPart.tag')"
        width="180"
        align="center"
        sortable
        sort-by="Tag"
      >
        <el-table-column width="180">
          <!-- eslint-disable -->
        <template slot="header" slot-scope="scope">
          <!-- eslint-enable -->
            <content-part-select
              :placeholder="`${$t('search')} ${$t('contentPart.tag')}`"
              :options="tags"
              show-all
              :selected.sync="filters.tag"
              prop-name="Tag"
              prop-value="Tag"
              size="mini"
            />
          </template>
          <template slot-scope="scope">
            {{ scope.row.Tag }}
          </template>
        </el-table-column>
      </el-table-column>

      <!-- Column PRIORITY -->
      <el-table-column
        :label="$t('contentPart.priority')"
        width="100"
        align="center"
        sortable
        sort-by="SortOrder"
      >
        <el-table-column width="100">
          <template slot-scope="scope">
            {{ scope.row.SortOrder }}
          </template>
        </el-table-column>
      </el-table-column>

      <!-- Column TEMPLATE -->
      <el-table-column
        align="center"
        :label="$t('contentPart.template')"
        width="180"
        sortable
        sort-by="ContentPartTemplateID"
      >
        <el-table-column width="180">
          <!-- eslint-disable -->
        <template slot="header" slot-scope="scope">
          <!-- eslint-enable -->
            <content-part-select
              :placeholder="`${$t('search')} ${$t('contentPart.template')}`"
              :options="contentPartTemplates"
              show-all
              :selected.sync="filters.template"
              size="mini"
            />
          </template>
          <template slot-scope="scope">
            {{ templateName(scope.row.ContentPartTemplateID) }}
          </template>
        </el-table-column>
      </el-table-column>

      <!-- Column Actions -->
      <el-table-column :label="$t('actions')" width="130" align="center">
        <el-table-column width="130" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-document-copy"
              @click="cloneContentPart(scope.row)"
            />
            <el-button type="text" @click="handlePreview(scope.row)">
              <i class="el-icon-view text-success" />
            </el-button>
            <el-button
              type="text"
              icon="el-icon-edit-outline"
              @click="editContentPart(scope.row)"
            />
            <delete-row-button
              :selected-row="scope.row.ID"
              delete-action="contentPart/deleteContentPartRow"
            />
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import ContentPartSelect from '@/components/ContentPartSelect'
import DeleteRowButton from '@/components/DeleteRowButton'

const contentPart = 'contentPart'

export default {
  name: 'ContentPartTable',
  components: {
    ContentPartSelect,
    DeleteRowButton
  },
  props: {
    filters: {
      type: Object,
      default() {
        return {
          template: '',
          name: '',
          tag: '',
          position: ''
        }
      }
    },
    list: {
      type: Array,
      default() {
        return []
      }
    },
    editContentPart: {
      type: Function,
      default() {
        return true
      }
    },
    cloneContentPart: {
      type: Function,
      default() {
        return true
      }
    },
    handlePreview: {
      type: Function,
      default() {
        return true
      }
    }
  },
  computed: {
    ...mapGetters([
      'contentPartList',
      'contentPartTemplates',
      'templateName'
    ]),
    ...mapState(contentPart, ['isLoading', 'tags', 'positions'])
  }
}
</script>

<style lang='scss' scoped>
$success: #67c23a;

.search-box {
  width: 100%
}
.text-success {
  color: $success
}
.table-container {
  ::v-deep .el-table--border, .el-table--group {
    border: none;
  }

  ::v-deep .el-table--border::after, .el-table--group::after {
    width: 0px
  }

  ::v-deep .el-table thead.is-group th {
    background: white;
  }

  ::v-deep .el-table--border th, .el-table__fixed-right-patch {
    border-right: none;
    // border-bottom: none;
  }

  ::v-deep .el-table--border td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: none;
  }
}
@media screen and (min-width: 540px) {
  .search-box {
    width: 100%;
  }
}

</style>
