var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("saveItemDialogHeading"),
            visible: _vm.isCreateDialogOpen,
            width: "30%",
            "before-close": _vm.handleClose,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.isCreateDialogOpen = $event
            }
          }
        },
        [
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleChange } },
                [_vm._v(_vm._s(_vm.$t("save")))]
              ),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v(_vm._s(_vm.$t("cancel")))
              ])
            ],
            1
          )
        ]
      ),
      _c(
        "el-select",
        {
          attrs: {
            "allow-create": _vm.allowCreate,
            "default-first-option": "",
            filterable: "",
            placeholder: _vm.placeholder,
            size: _vm.size,
            disabled: _vm.isGlobalSiteSelectorDisabled
          },
          model: {
            value: _vm.selectedTemplate,
            callback: function($$v) {
              _vm.selectedTemplate = $$v
            },
            expression: "selectedTemplate"
          }
        },
        [
          _vm.showAll
            ? _c("el-option", { attrs: { value: "", label: "All" } })
            : _vm._e(),
          _vm._l(_vm.options, function(option) {
            return _c("el-option", {
              key: option.ID,
              attrs: {
                label: option[_vm.propName],
                value: option[_vm.propValue]
              }
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }