const dateFields = ['DateChanged']
export const columns = [
  'ID',
  'AdminID',
  'FirstName',
  'LastName',
  'ColumnName',
  'OldValue',
  'NewValue',
  ...dateFields
].map(field => ({
  field,
  width: '100%',
  ...(dateFields.includes(field) && { dataType: 'date', sort: 'desc' })
}))
