export class ChatQuestionByMediaFilters {
  constructor({ selectedCategory = '', selectedEpisode = '' } = {}) {
    this.selectedCategory = selectedCategory
    this.selectedEpisode = selectedEpisode
  }

  clearFilters() {
    this.selectedCategory = ''
    this.selectedEpisode = ''
  }
}
