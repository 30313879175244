export const dateColumns = ['CurrentTime']
export const rowKey = 'rownum'
export const columns = [
  'Account',
  'SubAccount',
  'Address',
  'CustomerID',
  'CategoriesID',
  'MediaID',
  'HasViewed',
  'IsDone',
  'IsDone2',
  'PercentViewed',
  'IsAssigned',
  'SiteID',
  'IsAdded',
  'AppID',
  'LastUpdate',
  'ReactionCount',
  'WasUseFul',
  'ReflectionCount',
  'SharedReflectionCount',
  'EMail',
  'FirstName',
  'LastName',
  'FullAccount',
  'Title',
  ...dateColumns,
  rowKey
].map(field => ({
  field,
  width: 100,
  ...(dateColumns.includes(field) && {
    dataType: 'date'
  })
}))
