var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("content-part-select", {
    staticClass: "select-width",
    attrs: {
      placeholder: _vm.$t("contentPart.site"),
      options: _vm.contentPartSites,
      selected: _vm.site,
      size: "mini"
    },
    on: { change: _vm.handleChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }