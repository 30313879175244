import { Question } from './question'
import { Section } from './testSection'
import { ROUTE_PATH } from '@/router'

export const TEST_TYPE = {
  MCQ: 'mcq',
  REFLECTION: 'reflection'
}

export class Test {
  constructor({
    type = TEST_TYPE.MCQ,
    siteId,
    programId,
    episodeId,
    shortTitle = '',
    sections = [],
    questions = [{}],
    createdAt = new Date(),
    isActive = true
  } = {}) {
    this.type = type
    this.siteId = siteId
    this.programId = programId
    this.episodeId = episodeId
    this.sections = Section.fromList(sections)
    this.questions = Question.fromList(questions)
    this.createdAt = createdAt
    this.isActive = isActive
    this.shortTitle = shortTitle
  }
  get canDeleteQuestion() {
    return this.questions.length > 1
  }
  addQuestion() {
    this.questions.push(
      new Question({
        isFreeText: this.isFreeText
      })
    )
    this.setQuestionsOrder()
  }

  removeQuestion(indexToBeDeleted) {
    const questionToBeDeleted = this.questions.find(
      (question, index) => index === indexToBeDeleted
    )
    if (questionToBeDeleted.id) {
      questionToBeDeleted.updatePartial({ isActive: false })
    } else {
      this.questions = this.questions.filter(
        (question, index) => index !== indexToBeDeleted
      )
    }
    this.setQuestionsOrder()
  }
  get haveProvidedQuestionsAndMedia() {
    return (
      this.siteId &&
      this.programId &&
      this.episodeId &&
      this.haveProvidedQuestions &&
      this.haveProvidedAnswers
    )
  }
  get haveProvidedQuestions() {
    return this.questions.every(question => question.isProvided)
  }
  get haveProvidedAnswers() {
    return this.questions.every(question => question.haveProvidedAnswers)
  }
  get allQuestionsHaveAnswers() {
    return this.questions.every(question => question.correctAnswerIndex !== -1)
  }

  setCorrectAnswerForQuestion(questionIndex, answerIndex) {
    this.questions = this.questions.map((question, index) => {
      if (index === questionIndex) {
        question.markCorrectAnswer(answerIndex)
      }
      return question
    })
  }

  updatePartial(updateParams) {
    Object.assign(this, updateParams)
    if (updateParams.id) {
      this.setTestId(updateParams.id)
    }
  }

  setTestId(id) {
    this.sections.forEach(section =>
      section.updatePartial({ testId: id, mediaId: this.episodeId })
    )

    this.questions.forEach(question => question.updatePartial({ testId: id }))
  }

  setSectionId(id) {
    this.sections.forEach(section => section.updatePartial({ id }))

    this.questions.forEach(question =>
      question.updatePartial({ testSectionId: id })
    )
  }

  clearContext() {
    this.siteId = ''
    this.programId = ''
    this.episodeId = ''
  }
  clear() {
    this.questions = [new Question({})]
    this.sections = []
  }

  get isFreeText() {
    return this.type === TEST_TYPE.REFLECTION
  }
  get payload() {
    return {
      DateAdded: this.createdAt,
      IsActive: this.isActive,
      SitesID: this.siteId,
      Name: `Kunskapstest - ${this.shortTitle}`
    }
  }
  setQuestions(questions = []) {
    this.questions = Question.fromApiList(questions)
  }
  setAnswers(answers = []) {
    this.questions.forEach(question => {
      const answersForCurrentQuestion = answers.filter(answer =>
        question.compare(answer.tTestQuestionID)
      )
      question.setAnswers(answersForCurrentQuestion)
    })
  }
  get questionIds() {
    return this.questions.map(question => question.id)
  }

  setType(type) {
    this.type = type

    this.questions.forEach(question => {
      question.setFreeText(this.isFreeText)
    })
  }

  setTypeByRoutePath(path) {
    const type =
      path === ROUTE_PATH.KNOWLEDGE_TEST ? TEST_TYPE.MCQ : TEST_TYPE.REFLECTION
    this.setType(type)
  }

  get isMCQ() {
    return this.type === TEST_TYPE.MCQ
  }

  get translationSection() {
    return this.isMCQ ? 'knowledgeTest' : 'reflectionQuestions'
  }

  get baseFilter() {
    return ['bIsFreetext', '=', !this.isMCQ]
  }

  createFilters(sectionIds) {
    let filters = sectionIds.reduce(
      (query, id, index) => [
        ...query,
        ...(index ? ['or'] : []),
        ['tTestSectionID', '=', id]
      ],
      []
    )
    if (!filters.length) {
      filters = [
        ['tTestSectionID', '=', this.episodeId],
        'and',
        ['tTestSectionID', '=', this.siteId]
      ]
    }

    return [filters, 'and', this.baseFilter]
  }

  setQuestionsOrder() {
    this.questions

      .filter(question => question.isActive)

      .forEach((question, index) =>
        question.updatePartial({ order: index + 1 })
      )
  }
}
