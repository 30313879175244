import { LookupStore } from '@/models/lookupStore'
import store from '@/store'
import { MUTATION_TYPES } from '@/store/mutations'
export const FOLDER_NAME_FIELD = 'FolderName'
export const PATH = 'FilePath'
export const FileName = 'FileName'
const DOWNLOADABLE = store.getters.downloadable

const addEdit = [
  {
    field: 'MediaID',
    width: '100%',
    lookup: {
      store: new LookupStore({ resource: 'Media' }),
      paginate: true,
      pageSize: 50
    },
    required: true,
    requiredMessage: 'downloadable.requiredMessage.media',
    valueExpr: 'ID',
    displayExpr: 'Title',
    label: 'downloadable.column.media'
  },
  {
    field: 'Description',
    width: '100%'
  },
  {
    field: FOLDER_NAME_FIELD,
    width: '100%',
    visible: false,
    editorOptions: {},
    helpText: 'downloadable.helpText.folderName',
    setCellValue: (rowData, value) => {
      rowData.FolderName = value

      store.commit(
        `downloadable/${MUTATION_TYPES.DOWNLOADABLE.SET_DOWNLOADABLE}`,
        { folder: value.length ? value : '' }
      )
      return rowData
    }
  },
  {
    field: FileName,
    width: '100%',
    editCellTemplate: 'file-item',
    accept: '*',
    type: DOWNLOADABLE.folder,
    mode: 'downloadable'
  }
]

export const columns = {
  addEdit,
  all: [
    {
      field: 'ID',
      width: '100%'
    },
    ...addEdit
  ]
}
