var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "column-flex" },
    [
      _c("el-col", { attrs: { span: 24 } }, [
        _c("div", { staticClass: "title-container" }, [
          _c("p", [_vm._v(_vm._s(_vm.filteredDownloadables.length))]),
          _c("p", [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "manageDownloadables.generateReport.updatedDownloadables"
                )
              )
            )
          ])
        ])
      ]),
      _c(
        "el-col",
        { attrs: { span: 24 } },
        [
          _c(
            "el-row",
            _vm._l(_vm.filteredDownloadables, function(downloadable) {
              return _c(
                "el-col",
                { key: downloadable[_vm.idColumn], attrs: { span: 24 } },
                [
                  _c("p", { staticClass: "subtitle" }, [
                    _vm._v(_vm._s(downloadable[_vm.idColumn]))
                  ])
                ]
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }