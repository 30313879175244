var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("ODataTable", {
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns,
          "add-edit-columns": _vm.columns,
          "total-label": _vm.$t("customers.histories.total"),
          "file-name": _vm.$t("customers.histories.fileName"),
          title: _vm.$t("customers.histories.title"),
          "is-static-data-source": true,
          "static-data": _vm.histories,
          "should-show-add-button": false,
          "should-show-edit-button": false,
          "should-show-delete-button": false
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }