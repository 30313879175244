<template>
  <div class="app-container">
    <el-container>
      <div class="heading"><span>{{ $t('contentBlocks') }}</span></div>
      <!-- Table -->
      <el-main>
        <content-part-table
          :list="filteredList"
          :edit-content-part="editContentPart"
          :filters.sync="filters"
          :handle-preview="handlePreview"
          :clone-content-part="cloneContentPart"
        />
      </el-main>

      <!-- Footer for table -->
      <el-footer height="5vh" class="center-align">
        <el-row type="flex" class="row-bg">
          <el-col class="left-align">
            <el-button size="mini" icon="el-icon-plus" @click="createContentPart" />
            <el-button
              :disabled="isLoading"
              :loading="isLoading"
              size="mini"
              icon="el-icon-refresh"
              @click="refreshTable"
            />
          </el-col>
          <el-col>
            <el-select v-model="dataCount" size="mini" class="select-width">
              <el-option
                v-for="value in dataCountValues"
                :key="value"
                :label="value"
                :value="value"
              /></el-select>
          </el-col>
          <el-col>
            <el-pagination
              class="right-align"
              :page-size="dataCount"
              :total="totalFilteredItems"
              :page-count="pagerCount"
              layout="total,prev,pager,next"
              :current-page.sync="current"
              @current-change="handleCurrentPageChange"
            /></el-col>
        </el-row>
      </el-footer>
    </el-container>
    <content-part-dialog-box :visible.sync="dialogVisible" :item="contentPartItem" :dialog-type="dialogType" />
    <template-preview :row="selectedPreview" :visible.sync="templatePreviewDialogVisible" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import ContentPartTable from '@/components/ContentPartTable'
import ContentPartDialogBox from '@/components/ContentPartDialogBox'
import TemplatePreview from '@/components/TemplatePreview'
import { DIALOG_TYPES } from '@/components/ContentPartDialogBox/config'

const contentPart = 'contentPart'

export default {
  name: 'ContentPart',
  components: {
    ContentPartTable,
    ContentPartDialogBox,
    TemplatePreview
  },
  data() {
    return {
      dataCount: 10,
      dataCountValues: [5, 10, 25, 50, 100],
      current: 1,
      pagerCount: 6,
      filters: {
        name: '',
        tag: '',
        position: '',
        template: ''
      },
      totalFilteredItems: 0,
      selectedPreview: {},
      dialogVisible: false,
      templatePreviewDialogVisible: false,
      dialogType: DIALOG_TYPES.CREATE,
      contentPartItem: {}
    }
  },
  computed: {
    ...mapGetters([
      'contentPartList',
      'contentPartRow',
      'templateName',
      'totalContentParts'
    ]),
    ...mapState(contentPart, ['isLoading', 'totalItems', 'tags', 'positions']),
    filteredList() {
      // Filtered list for table
      const filters = [
        {
          name: 'Name',
          isText: true,
          value: this.filters.name
        },
        {
          name: 'Position',
          isText: false,
          value: this.filters.position
        },
        {
          name: 'Tag',
          isText: false,
          value: this.filters.tag
        },
        {
          name: 'ContentPartTemplateID',
          isText: false,
          value: this.filters.template
        }
      ]
      let list = this.contentPartList(filters)

      this.updatePagination(list.length)
      const startIndex = this.dataCount * (this.current - 1)
      const endIndex = startIndex + this.dataCount
      list = list.slice(startIndex, endIndex)
      return list
    }
  },
  created() {
    if (!this.totalItems) { return this.fetchData() }
    if (!this.tags?.length) { this.setTags() }
    if (!this.positions?.length) { this.setPositions() }
  },
  methods: {
    ...mapActions(contentPart, ['setContentPart', 'setTemplates', 'setTags', 'setPositions', 'toggleLoading']),
    fetchData() {
      this.setTemplates()
      this.setTags()
      this.setPositions()
      this.setContentPart()
    },
    handleCurrentPageChange(val) {
      this.current = val
    },
    updatePagination(val) {
      this.totalFilteredItems = val
    },
    refreshTable() {
      this.toggleLoading()
      this.fetchData()
    },
    createContentPart() {
      this.dialogType = DIALOG_TYPES.CREATE
      this.contentPartItem = {}
      this.dialogVisible = true
    },
    cloneContentPart({ ID, ...row }) {
      this.dialogType = DIALOG_TYPES.CLONE
      this.contentPartItem = { ...row, Name: `${row.Name} copy (${this.totalContentParts(row.Name)})` }
      this.dialogVisible = true
    },
    editContentPart(row) {
      this.dialogType = DIALOG_TYPES.EDIT
      this.contentPartItem = this.contentPartRow(row.ID)
      this.dialogVisible = true
    },
    handlePreview(row) {
      this.templatePreviewDialogVisible = true
      this.selectedPreview = row
    }
  }
}
</script>

<style lang='scss' scoped>
$success: #67c23a;

.center-align {
  text-align: center;
}
.right-align {
  display: flex;
  justify-content: flex-end;
}
.left-align {
  display: flex;
  justify-content: flex-start;
}
.select-width {
  width: 5em;
}
.heading {
  font-weight: bold;
  font-size: 1.3em;
  padding: 0em 1em;
}
</style>
