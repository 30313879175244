import { DateUtils } from '@/utils/date'

export const disabledColumns = ['DateAdded']
export const categoryField = 'CategoryID'
export const createColumns = ({ categories, medias }) => {
  const addEdit = [
    {
      field: categoryField,
      width: 100,
      label: 'category',
      lookup: [{ label: 'Empty', value: null }, ...categories],
      calculateCellValue: event => {
        if (!event.CategoryID) {
          return ''
        }
        return event.CategoryID
      }
    },
    {
      field: 'MediaID',
      width: 100,
      label: 'disabledMediaAccounts.column.mediaId',
      lookup: [{ label: 'Empty', value: null }, ...medias],
      calculateCellValue: event => {
        if (!event.MediaID) {
          return ''
        }
        return event.MediaID
      }
    },
    {
      field: 'modelId',
      width: 100,
      label: 'modelId'
    },
    {
      field: 'DeploymentName',
      width: 100,
      label: 'deploymentName'
    },
    {
      field: 'Prompt',
      width: 100,
      editorName: 'dxTextArea',
      colSpan: 2,
      editorOptions: {
        minHeight: '550px',
        autoResizeEnabled: true
      }
    },
    {
      field: 'Version',
      width: 100
    },
    {
      field: disabledColumns[0],
      width: 100,
      label: 'assistantDefaults.dateAdded',
      dataType: 'date',
      format: DateUtils.odataDateFormat
    },
    {
      field: 'IsActive',
      width: 100,
      label: 'assistantDefaults.isActive',
      dataType: 'boolean'
    },
    {
      field: 'PresencePenalty',
      width: 100,
      label: 'presencePenalty',
      dataType: 'number'
    },
    {
      field: 'FrequencyPenalty',
      width: 100,
      label: 'frequencyPenalty',
      dataType: 'number'
    },
    {
      field: 'MaxTokens',
      width: 100,
      label: 'maxTokens',
      dataType: 'number'
    },
    {
      field: 'Temperature',
      width: 100,
      dataType: 'number'
    },
    {
      field: 'TopP',
      width: 100,
      dataType: 'number'
    },
    {
      field: 'minRelevance',
      width: 100,
      label: 'minRelevance',
      dataType: 'number'
    },
    {
      field: 'DocumentLimit',
      width: 100,
      label: 'documentLimit',
      dataType: 'number'
    },
    {
      field: 'DocumentStore',
      width: 100,
      label: 'documentStore'
    },
    {
      field: 'StartChatText',
      width: 100,
      label: 'startChatText'
    }
  ]
  return {
    addEdit,
    all: [
      {
        field: 'ID',
        width: 100
      },
      ...addEdit
    ]
  }
}
