import { createNamespacedHelpers } from 'vuex'
import { TransactionItemList } from '@/models/transaction'
import i18n from '@/i18n'
import { DxTooltip } from 'devextreme-vue/tooltip'
import { parseFilters } from '@/models/odataStoreConfig'

const {
  mapState: mapAccountsState,
  mapActions: mapAccountsActions
} = createNamespacedHelpers('user/accounts')

const {
  mapState: mapMediaReportsState,
  mapActions: mapMediaReportsActions
} = createNamespacedHelpers('mediaReportsV2')

const {
  mapState: mapContentPartState,
  mapActions: mapContentPartActions
} = createNamespacedHelpers('contentPart')

const {
  mapState: mapAppState,
  mapActions: mapAppActions
} = createNamespacedHelpers('app')

const {
  mapActions: mapTagActions,
  mapState: mapTagState
} = createNamespacedHelpers('tags')

const {
  mapState: mapSharedSiteState,
  mapActions: mapSharedSiteActions
} = createNamespacedHelpers('shared/site')

const {
  mapState: mapSharedCategoryState,
  mapActions: mapSharedCategoryActions
} = createNamespacedHelpers('shared/category')

const {
  mapState: mapSharedMediaState,
  mapActions: mapSharedMediaActions
} = createNamespacedHelpers('shared/media')

export default {
  name: 'MediaReportsFilterV2',
  components: {
    DxTooltip
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      episodes: [],
      startDatePickerOptions: {
        disabledDate: time => {
          return this.endDate ? time.getTime() > this.endDate.getTime() : false
        }
      },
      endDatePickerOptions: {
        disabledDate: time => {
          return this.startDate
            ? time.getTime() < this.startDate.getTime()
            : false
        }
      },
      mediaIds: []
    }
  },
  computed: {
    ...mapAccountsState({
      accounts: state => state.accounts,
      areAccountsLoading: state => state.isLoading
    }),
    ...mapMediaReportsState({
      customerIds: state => state.filters.customerIds,
      areUserTagsLoading: state => state.isLoading,
      filters: state => state.filters
    }),
    ...mapContentPartState({
      areCategoriesLoading: state => state.dialogBoxConfig.isCategoryLoading,
      areEpisodesLoading: function(state) {
        return (
          !!this.filters.selectedCategory &&
          state.dialogBoxConfig.isMediaLoading
        )
      }
    }),
    ...mapAppState({
      allCategories: state => state.categories,
      allMedias: state => state.medias
    }),
    ...mapTagState({
      tags: state => state.tags
    }),
    ...mapSharedSiteState({
      sites: state => state.sites.map(site => site.toOption)
    }),
    ...mapSharedCategoryState({
      categoriesForSite: state => state.categories
    }),
    ...mapSharedMediaState({
      mediasForCategoryAndSite: state => state.medias
    }),
    subAccounts() {
      const subAccounts = this.filters.getAccounts(this.accounts)
      return subAccounts
    },
    isSubAccountTypeDisabled() {
      return !this.subAccounts.length
    },
    filtersMedia() {
      const filtersMedia = this.filters.parse()
      return filtersMedia
    },
    categories() {
      return this.filters.siteId ? this.categoriesForSite : this.allCategories
    }
  },
  watch: {
    'filters.account': {
      async handler() {
        this.filters.subAccount = null
      }
    },
    'filters.categoryID': {
      async handler(newCategory) {
        this.filters.clearEpisode()
        if (newCategory) {
          if (this.filters.siteId) {
            await this.fetchMediasBySiteAndCategory(newCategory)
            this.mediaIds = this.mediasForCategoryAndSite.map(
              media => media.ID
            )
          } else {
            this.mediaIds = await this.fetchMediasByCategoryId(newCategory)
          }

          this.filters.mediaIds = this.mediaIds.filter(ID => ID !== null)

          this.episodes = this.filters.getFilteredEpisodes({
            mediasForCategoryAndSite: this.mediasForCategoryAndSite,
            allMedias: this.allMedias
          })
        } else {
          this.filters.mediaIds = []
          this.episodes = this.allMedias
        }
      }
    },
    'filters.startDate': {
      handler(newStartDate) {
        if (
          this.filters.endDate &&
          newStartDate &&
          newStartDate.getTime() > this.filters.endDate.getTime()
        ) {
          this.filters.endDate = newStartDate
        }
      }
    },
    'filters.episodeID': {
      async handler(newEpisodeId) {
        if (newEpisodeId) {
          this.filters.mediaIds = []
        } else if (this.filters.categoryID) {
          this.mediaIds = await this.fetchMediasByCategoryId(
            this.filters.categoryID
          )
          this.filters.mediaIds = this.mediaIds.filter(ID => ID !== null)
        }
      }
    },
    'filters.siteId': {
      async handler(newSite) {
        this.filters.clearCategoryAndEpisode()
        if (newSite) {
          await this.fetchCategoriesBySite()
        }
      }
    }
  },
  async created() {
    await Promise.all([this.fetchAccounts(), this.fetchSites()])
    this.setDialogBoxConfig({
      isCategoryLoading: true
    })
    await this.fetchCategories()
    this.setDialogBoxConfig({
      isCategoryLoading: false
    })
    await Promise.all([this.fetchMedias(), this.fetchAll()])
    this.episodes = this.allMedias
    if (this.filters.categoryID) {
      this.mediaIds = await this.fetchMediasByCategoryId(
        this.filters.categoryID
      )
    }
  },
  mounted() {
    this.startDate = this.filters.startDate
    this.endDate = this.filters.endDate
  },
  methods: {
    ...mapAccountsActions(['fetchAccounts']),
    ...mapContentPartActions(['setDialogBoxConfig']),
    ...mapAppActions([
      'fetchCategories',
      'fetchMediasByCategoryId',
      'fetchMedias'
    ]),
    ...mapTagActions(['fetchAll']),
    ...mapMediaReportsActions(['fetchUsersByTagId', 'fetchMediaReport']),
    ...mapSharedSiteActions({
      fetchSites: 'fetchAll'
    }),
    ...mapSharedCategoryActions(['fetchCategoriesBySite']),
    ...mapSharedMediaActions(['fetchMediasBySiteAndCategory']),

    async filterMediaReports() {
      this.$emit('clearFilteredData')
      this.$emit('clearFilter')
      this.$emit('panelLoading', true)
      let data = []

      if (this.filters.tagId) {
        await this.fetchUsersByTagId(this.filters.tagId)
        const customersByTag = this.filters.customerIds
        const transactionItems = new TransactionItemList(customersByTag, 100)
        while (transactionItems.hasMore) {
          const customersPerPage = transactionItems.next()
          this.filters.customerIds = customersPerPage
          data = [
            ...data,
            ...(await this.fetchMediaReport(parseFilters(this.filters.parse())))
          ]
        }
      } else {
        this.filters.customerIds = []
        this.$emit('filter', this.filters.parse())
      }
      this.$emit('panelLoading', false)
    },
    reset() {
      this.filters.clearFilters()
    },
    handleStartDateChange(newStartDate) {
      this.filters.setStartDate(newStartDate)
    },
    handleEndDateChange(newEndDate) {
      this.filters.setEndDate(newEndDate)
    },
    handleInfoIconClick() {
      this.$message({
        type: 'info',
        message: i18n.t('mediaReportsV2.siteInfo'),
        duration: 0,
        showClose: true
      })
    }
  }
}
