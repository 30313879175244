var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        [
          _c("el-col", { staticClass: "padding-x-lg", attrs: { span: 14 } }, [
            _c("h1", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("courseCampaign.create.title")))
            ])
          ])
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "padding-x-lg",
          attrs: { type: "flex", justify: "space-between", align: "center" }
        },
        [
          _c(
            "el-col",
            [
              _c(
                "el-breadcrumb",
                { attrs: { "separator-class": "el-icon-arrow-right" } },
                _vm._l(_vm.steps, function(step, index) {
                  return _c(
                    "el-breadcrumb-item",
                    {
                      key: step.value,
                      class: { "selected-step": _vm.isActiveStep(step.value) }
                    },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: {
                            underline: false,
                            disabled: _vm.lastTraversedStep < index
                          },
                          on: {
                            click: function($event) {
                              return _vm.navigateToStep(step.value)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(step.label) + " ")]
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("el-col", [
            _c(
              "div",
              { staticClass: "p-3 d-flex flex-end" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "justify-center",
                    attrs: { type: "primary", loading: _vm.isLoading },
                    on: { click: _vm.handleNextClick }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "courseCampaign." + _vm.activeStep + ".buttonLabel"
                          )
                        ) +
                        " "
                    )
                  ]
                ),
                _vm.activeStep === "choosePrograms" ||
                _vm.activeStep === "overview"
                  ? _c(
                      "el-button",
                      {
                        staticClass: "justify-center",
                        attrs: { type: "primary" },
                        on: { click: _vm.handlePrevClick }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "courseCampaign." +
                                  _vm.activeStep +
                                  ".buttonLabel2"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(_vm.activeStepComponent, { ref: "formRef", tag: "component" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }