import ContentPartService from '@/api/contentPart'
import { MUTATION_TYPES as BASE_MUTATION_TYPES } from '@/store/mutations'
import { Message } from 'element-ui'
import { Media } from '@/models/media'

const MUTATION_TYPES = BASE_MUTATION_TYPES.SHARED.MEDIA

const initialState = () => ({
  medias: []
})

const mutations = {
  [MUTATION_TYPES.SET_MEDIAS]: (state, medias) => {
    state.medias = medias
  }
}

const actions = {
  async fetchMediasBySiteAndCategory({ commit, rootState }, category) {
    try {
      if (!category) {
        return
      }
      const medias = await ContentPartService.fetchMediaForCategory({
        category,
        siteID: rootState.mediaReportsV2.filters.siteId
      })

      commit(
        MUTATION_TYPES.SET_MEDIAS,
        medias?.Media?.map(media => new Media(media)) || []
      )
      return Promise.resolve()
    } catch (error) {
      Message({
        type: 'error',
        message: error.message
      })
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions
}
