import Store from '@/models/odataStore'

export const createColumns = ({ ID, selectedEpisodeID, oDataTableData }) => {
  const ChatQuestionResourceKey = ID ? `chatQuestion(${ID})` : 'ChatQuestion'
  const ChatQuestionStore = new Store({ resource: ChatQuestionResourceKey })

  const mediaStoreFilter = oDataTableData.length
    ? oDataTableData.join(' or ')
    : []
  const mediaResourceKey = mediaStoreFilter.length
    ? `Media?$filter=${mediaStoreFilter}`
    : `Media`
  const mediaStore = new Store({ resource: mediaResourceKey })

  const filter = selectedEpisodeID.length ? selectedEpisodeID.join(' or ') : ''

  const mediaByCategoryResourceKey = selectedEpisodeID.length
    ? `Media?$filter=${filter}`
    : `Media`

  const mediaByCategoryStore = new Store({
    resource: mediaByCategoryResourceKey
  })
  const categoryStore = new Store({ resource: 'Categories' })

  const dropDownColumns = [
    {
      field: 'Name',
      width: '100%',
      label: 'Category',
      dataType: 'number',
      lookup: {
        store: categoryStore,
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'ID',
      displayExpr: 'Name'
    },
    {
      field: 'MediaID',
      width: '100%',
      label: 'MediaID',
      dataType: 'number',
      lookup: {
        store: mediaByCategoryStore,
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'ID',
      displayExpr: 'Title'
    }
  ]

  const addEdit = [
    {
      field: 'ChatQuestionID',
      width: '100%',
      label: 'ChatQuestionID',
      dataType: 'number',
      visible: false,
      lookup: {
        store: ChatQuestionStore,
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'ID',
      displayExpr: 'QuestionText'
    },
    {
      field: 'MediaID',
      width: '100%',
      label: 'MediaID',
      dataType: 'number',
      lookup: {
        store: mediaStore,
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'ID',
      displayExpr: 'Title'
    }
  ]
  return {
    addEdit,
    dropDownColumns,
    all: [
      {
        field: 'ID',
        width: '100%'
      },
      ...addEdit
    ]
  }
}
