export function objectToQueryParams(obj) {
  const params = []
  Object.keys(obj)
    .forEach(function(key) {
      if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
        return
      }
      if (Array.isArray(obj[key])) {
        return params.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key].join(',')))
      }
      params.push(encodeURIComponent(key) + '=' + `${obj[key]}`)
    })
  return `?${params.join('&')}`
}
