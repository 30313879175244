var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chat-assistant-container" }, [
    _c(
      "div",
      { staticClass: "chat-assistants-grid" },
      _vm._l(_vm.assistants, function(assistant, index) {
        return _c(
          "div",
          { key: assistant.ref, staticClass: "assistant-item" },
          [
            _c("div", [
              _c("span", { staticClass: "assistant-name" }, [
                _vm._v(_vm._s(assistant.value[index].name))
              ])
            ]),
            _c(
              "el-select",
              {
                ref: assistant.ref,
                refInFor: true,
                staticClass: "threadDropDown",
                attrs: {
                  value: _vm.selectedThreadId[assistant.ref],
                  filterable: "",
                  placeholder: _vm.$t("selectThread"),
                  size: "large",
                  "no-data-text": _vm.$t("noThreads")
                },
                on: {
                  change: function($event) {
                    return _vm.handleChange(assistant.ref, $event)
                  }
                }
              },
              _vm._l(
                _vm.threads[parseInt(assistant.ref.replace(/\D/g, ""), 10) - 1],
                function(item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item }
                  })
                }
              ),
              1
            ),
            _c("deep-chat", {
              ref: assistant.ref,
              refInFor: true,
              staticClass: "deep-chat-bot",
              attrs: {
                directConnection: _vm.directConnection(
                  assistant.value[index].id
                ),
                initialMessages: _vm.initialMessages[assistant.ref],
                demo: "true",
                stream: "true",
                gifs: '{"button": {"position": "dropup-menu"}}',
                audio: '{"button": {"position": "dropup-menu"}}',
                camera: '{"button": {"position": "dropup-menu"}}',
                "mixed-files": '{"button": {"position": "inside-left"}}',
                microphone: '{"button": {"position": "outside-right"}}'
              },
              on: {
                "new-message": function($event) {
                  return _vm.handleNewMessage(assistant.ref, $event)
                },
                render: _vm.onComponentRender
              }
            })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }