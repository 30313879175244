import Store from '@/models/odataStore'

export const createColumns = ({ ID, selectedEpisodeID = [] }) => {
  const mediaByCategoryResourceKey = selectedEpisodeID.length
    ? `Media?$filter=ID in (${selectedEpisodeID})`
    : `Media`

  const mediaByCategoryStore = new Store({
    resource: mediaByCategoryResourceKey
  })

  const categoryStore = new Store({ resource: 'Categories' })

  const dropDownColumns = [
    {
      field: 'Category',
      width: '100%',
      label: 'Category',
      dataType: 'number',
      lookup: {
        store: categoryStore,
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'ID',
      displayExpr: 'Name'
    },
    {
      field: 'MediaID',
      width: '100%',
      label: 'MediaID',
      dataType: 'number',
      lookup: {
        store: mediaByCategoryStore,
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'ID',
      displayExpr: 'Title'
    },
    {
      field: 'SiteID',
      width: '100%',
      label: 'SiteID',
      lookup: {
        store: new Store({ resource: 'Sites' }),
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'ID',
      displayExpr: 'SiteName'
    }
  ]

  const addEdit = [
    ...dropDownColumns.filter(column => column.field !== 'Category')
  ]
  return {
    addEdit,
    dropDownColumns,
    all: [
      {
        field: 'ID',
        width: '100%'
      },
      ...addEdit
    ]
  }
}
