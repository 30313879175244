export class AssistantTool {
  constructor(tool = {}) {
    Object.assign(this, tool)
    if (this.isFunctionType) {
      Object.assign(this, tool.function)
      this.parameters =
        tool.parameters ?? JSON.stringify(tool.function?.parameters, null, 2)
    }
  }

  get isFunctionType() {
    return this.type === 'function'
  }

  get payload() {
    const {
      // eslint-disable-next-line no-unused-vars
      index,
      name = '',
      description = '',
      parameters = '',
      ...attributes
    } = this
    return {
      ...attributes,
      ...(this.isFunctionType && {
        function: {
          name,
          description,
          parameters: AssistantTool.parse(parameters)
        }
      })
    }
  }

  updatePartial(params) {
    Object.assign(this, params)
  }

  static parse(parameters) {
    try {
      return JSON.parse(parameters)
    } catch (error) {
      return parameters
    }
  }

  static fromList(tools = []) {
    if (tools.length) {
      return tools.map(
        (tool, index) => new this({ ...tool, index: index + 1 })
      )
    }
  }
}
