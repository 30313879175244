var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("ODataTable", {
        attrs: {
          columns: _vm.columns,
          "add-edit-columns": _vm.addEditColumns,
          resource: "UserMedia",
          "total-label": _vm.$t("userMedia.total"),
          "file-name": _vm.$t("userMedia.fileName"),
          "should-show-add-button": false,
          title: _vm.$t("userMedia.title")
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }