import Store from '@/models/odataStore'
export const createColumns = () => {
  const addEdit = [
    {
      field: 'MediaID',
      width: '100%',
      label: 'disabledMediaAccounts.column.mediaId',
      lookup: {
        store: new Store({ resource: 'Media' }),
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'ID',
      displayExpr: 'Title'
    },
    {
      field: 'SiteID',
      width: '100%',
      label: 'disabledMediaAccounts.column.siteId',
      lookup: {
        store: new Store({ resource: 'Sites' }),
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'ID',
      displayExpr: 'SiteName'
    },
    {
      field: 'Account',
      width: '100%'
    },
    {
      field: 'FullAccount',
      width: '100%'
    }
  ]
  return {
    addEdit,
    all: [
      {
        field: 'ID',
        width: '100%',
        visible: false
      },
      ...addEdit
    ]
  }
}
