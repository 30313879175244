var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ]
    },
    [
      !_vm.isLoading
        ? _c(
            "div",
            [
              _c("SubCategories", {
                ref: "subCategoriesRef",
                class: { hide: !_vm.hasSubCategories },
                attrs: {
                  category: _vm.data,
                  "should-show-add-button": _vm.shouldShowAddButton,
                  "should-show-edit-button": _vm.shouldShowEditButton,
                  "should-show-delete-button": _vm.shouldShowDeleteButton,
                  "should-show-toggle-button": _vm.shouldShowToggleButton,
                  "should-show-title": _vm.shouldShowTitle,
                  segment: _vm.sectionForSubPrograms
                },
                on: {
                  rowInserted: _vm.handleSubCategoryAdded,
                  rowRemoved: _vm.handleSubCategoryRemoved
                }
              }),
              !_vm.hasSubCategories && !_vm.shouldShowToggleButton
                ? _c("DxButton", {
                    staticClass: "margin-left-md",
                    attrs: {
                      icon: "add",
                      text: _vm.$t("subCategories.add"),
                      type: "default",
                      "styling-mode": "outlined"
                    },
                    on: { click: _vm.handleAddSubCategoryClick }
                  })
                : _vm._e(),
              !_vm.hasSubCategories
                ? _c("medias-by-category", {
                    ref: "mediasRef",
                    attrs: {
                      "category-id": _vm.data.ID,
                      "site-id": _vm.data.SiteID,
                      title: "medias.title",
                      "add-row-button-label": "medias.add",
                      "should-show-add-button": _vm.shouldShowAddButton,
                      "should-show-edit-button": _vm.shouldShowEditButton,
                      "should-show-delete-button": _vm.shouldShowDeleteButton,
                      "should-show-toggle-button": _vm.shouldShowToggleButton,
                      "should-show-title": _vm.shouldShowTitle
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "toggle-button",
                          fn: function(ref) {
                            var item = ref.data
                            return [
                              _c("el-switch", {
                                attrs: {
                                  value: _vm.isMediaActive(
                                    item.row.data.MediaID
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.toggleActivate(
                                      item.row.data.MediaID
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      74436342
                    )
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }