import { Customer } from './customer'
export class Customers {
  customers;
  constructor(customers = []) {
    this.customers = customers
  }

  get Ids() {
    return this.customers.map(customer => customer.ID)
  }

  get total() {
    return this.customers.length
  }

  createFilterQuery() {
    return this.Ids.map(id => ['ID', '=', id]).reduce(
      (filterQuery, item, index) => [
        ...filterQuery,
        ...(index ? ['or'] : []),
        item
      ],
      []
    )
  }

  updateCustomerValue({ ID, data }) {
    const customerIndex = this.customers.findIndex(
      customer => customer.ID === ID
    )

    if (customerIndex > -1) {
      this.customers[customerIndex] = {
        ...this.customers[customerIndex],
        ...data
      }
    }
  }

  static fromList(customers = []) {
    return new Customers(customers?.map(customer => new Customer(customer)))
  }
  clear() {
    this.customers = []
  }
}
