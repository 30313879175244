var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chat-assistant-container" }, [
    _c("div", { staticClass: "chat-assistants-grid" }, [
      _c(
        "div",
        { staticClass: "assistant-item" },
        [
          _c("deep-chat", {
            ref: _vm.assistants[0],
            style: _vm.chatBotStyle,
            attrs: {
              demo: "true",
              stream: "true",
              textInput: _vm.textInputStyle,
              gifs: '{"button": {"position": "dropup-menu"}}',
              audio: '{"button": {"position": "dropup-menu"}}',
              camera: '{"button": {"position": "dropup-menu"}}',
              "mixed-files": '{"button": {"position": "inside-left"}}',
              microphone: '{"button": {"position": "outside-right"}}'
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }