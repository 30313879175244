import Store from '@/models/odataStore'
import { DateUtils } from '@/utils/date'
export const dateAddedField = 'DateAdded'
export const createColumns = () => {
  const dateColumns = [
    {
      field: dateAddedField,
      width: '100%',
      dataType: 'date',
      format: DateUtils.odataDateFormat,
      sort: 'desc'
    }
  ]
  const addEdit = [
    {
      field: 'CategoryID',
      width: '100%',
      label: 'disabledCategoryAccounts.column.categoryID',
      lookup: {
        store: new Store({ resource: 'Categories' }),
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'ID',
      displayExpr: 'Name'
    },
    {
      field: 'SiteID',
      width: '100%',
      label: 'disabledCategoryAccounts.column.siteId',
      lookup: {
        store: new Store({ resource: 'Sites' }),
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'ID',
      displayExpr: 'SiteName'
    },
    {
      field: 'Account',
      width: '100%'
    },
    {
      field: 'FullAccount',
      width: '100%'
    },
    ...dateColumns
  ]
  return {
    addEdit,
    all: [
      {
        field: 'ID',
        width: '100%',
        visible: false
      },
      ...addEdit
    ]
  }
}
