var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isPanelLoading,
          expression: "isPanelLoading"
        }
      ],
      staticClass: "container"
    },
    [
      _c("ODataTable", {
        key: _vm.resource + JSON.stringify(_vm.filter),
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns,
          resource: _vm.resource,
          "total-label": _vm.$t("invertedMediaReports.total"),
          "file-name": _vm.$t("invertedMediaReports.fileName"),
          title: _vm.$t("invertedMediaReports.title"),
          "should-show-add-button": false,
          "should-show-edit-button": false,
          "should-show-delete-button": false,
          "show-data": _vm.showData,
          filter: _vm.filter,
          method: "post"
        },
        on: { clearFilters: _vm.handleFiltersClear },
        scopedSlots: _vm._u([
          {
            key: "custom-filters",
            fn: function() {
              return [
                _c("media-reports-filters", {
                  ref: "mediaReportFiltersRef",
                  on: { filter: _vm.filterMediaReports }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }