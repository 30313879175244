var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ODataTable", {
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns.all,
          "add-edit-columns": _vm.columns.addEdit,
          resource: "tDisabledMediaException",
          "total-label": _vm.$t("disabledMediaExceptions.total"),
          "file-name": _vm.$t("disabledMediaExceptions.fileName"),
          "add-row-button-label": _vm.$t("disabledMediaExceptions.add"),
          title: _vm.$t("disabledMediaExceptions.title")
        },
        on: {
          rowInit: _vm.handleRowInit,
          editorPreparing: _vm.handleEditorPreparing
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }