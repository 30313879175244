import { Assistant } from './assistant'

export class Assistants {
  constructor(assistants = []) {
    this.assistants = Assistant.fromList(assistants)
  }

  set(assistants = []) {
    this.assistants = Assistant.fromList(assistants)
  }

  add({ assistant, index }) {
    this.assistants.splice(index + 1, 0, new Assistant(assistant))
  }

  update(assistantToUpdate) {
    const index = this.assistants.findIndex(assistant =>
      assistant.isEqual(assistantToUpdate.id)
    )
    if (index > -1) {
      this.assistants[index].updatePartial(assistantToUpdate)
    }
  }

  remove(id) {
    this.assistants = this.assistants.filter(
      assistant => !assistant.isEqual(id)
    )
  }
}
