<template>
  <el-container class="app-container container-width">
    <el-main>
      <el-col :span="24">
        <el-form ref="form" label-width="120px" size="mini" label-position="top">
          <el-form-item :label="$t('tag.title')" />
          <el-form-item :label="$t('tag.selectTagTypeTitle')">
            <el-select
              v-model="selectedTagType"
              filterable
              :placeholder="$t('tag.selectTagType')"
            >
              <el-option
                v-for="tagType in tagTypes"
                :key="tagType.value"
                :label="tagType.label"
                :value="tagType.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-if="isExistingTagTypeSelected" :label="$t('tag.selectTagTitle')">
            <el-select
              v-model="selectedTagID"
              class="add-margin"
              :loading="isLoading"
              filterable
              :placeholder="$t('tag.selectTag')"
            >
              <el-option
                v-for="tag in tags"
                :key="tag.ID"
                :label="tag.TagName"
                :value="tag.ID"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-if="isNewTagTypeSelected" :label="$t('tag.selectTagTitle')" size="small">
            <el-input
              v-model="newTag"
              :placeholder="$t('name')"
            />
          </el-form-item>
        </el-form>
        <el-col v-if="selectedTagType" :md="6" :sm="4" class="action-btn">
          <el-button
            type="primary"
            :disabled="isSaveButtonDisabled"
            :loading="isLoading"
            @click="handleAssignClick"
          >
            {{ $t("tag.assign") }}
          </el-button>
          <el-progress
            v-if="isLoading"
            :text-inside="true"
            :stroke-width="10"
            :percentage="percentage"
          />
        </el-col>
      </el-col>
    </el-main>
  </el-container>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import { TAG_TYPE } from './config'
const { mapActions, mapState } = createNamespacedHelpers('tags')
export default {
  name: 'TagUsers',
  props: {
    users: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      newTag: '',
      selectedTagType: '',
      selectedTagID: '',
      tagToAssign: null,
      percentage: 0,
      tagTypes: Object.values(TAG_TYPE).map((value) => ({
        label: this.$t(`tag.${value}`),
        value
      }))
    }
  },
  computed: {
    ...mapState({
      tags: (state) => state.tags,
      isLoading: (state) => state.isLoading
    }),
    isNewTagTypeSelected() {
      return this.selectedTagType === TAG_TYPE.NEW
    },
    isExistingTagTypeSelected() {
      return this.selectedTagType === TAG_TYPE.EXISTING
    },
    isSaveButtonDisabled() {
      if (this.isNewTagTypeSelected) {
        return !this.newTag
      }
      if (this.isExistingTagTypeSelected) {
        return !this.selectedTagID
      }
      return this.isLoading
    }
  },
  async created() {
    await this.fetchAll()
  },
  methods: {
    ...mapActions(['fetchAll', 'create', 'assignTagToCustomers']),
    handleTagAssignProgressChange(percentage) {
      this.percentage = percentage
    },
    async handleAssignClick() {
      if (this.isNewTagTypeSelected) {
        this.tagToAssign = await this.create({ TagName: this.newTag, IsActive: true })
      } else {
        this.tagToAssign = this.tags.find((tag) =>
          tag.isEqual(Number(this.selectedTagID))
        )
      }
      if (this.tagToAssign.ID) {
        await this.assignTagToCustomers({
          data: this.tagToAssign.createParamsForCustomersAssign(this.users),
          onTagAssignProgressChangeCb:
          this.handleTagAssignProgressChange.bind(this)
        })
        this.$emit('tagAssigned')
        this.percentage = 0
      } else {
        this.$message({
          type: 'error',
          message: this.$t('validateTagName')
        })
      }
    }
  }
}
</script>
<style lang="scss">
.action-btn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.el-progress-bar__innerText {
  display: block;
  font-size: 11px;
}
.container-width {
  width: 50vw
}
</style>
