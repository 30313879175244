<template>
  <div class="container-app">
    <ODataTable
      ref="oDataTableComponentRef"
      :columns="columns.all"
      :add-edit-columns="columns.addEdit"
      resource="MetaContent"
      :total-label="$t('sources.total')"
      :file-name="$t('sources.fileName')"
      :add-row-button-label="$t('sources.add')"
      :title="$t('sources.title')"
      :is-custom-form="true"
      @rowInit="handleRowInit"
      @editorPreparing="handleEditorPreparing"
      @rowInserted="handleFormClose"
      @editingStart="handleEditingStart"
      @editingCancelling="handleFormClose"
    >
      <template #custom-form>
        <DxTabbedItem>
          <DxTab :title="$t('info.title')">
            <DxSimpleItem
              v-for="column in columns.info"
              :key="`add_edit_${column.field}`"
              :data-field="column.field"
            />
          </DxTab>
          <DxTab :title="$t('description.title')">
            <DxSimpleItem
              v-for="column in columns.description"
              :key="`add_edit_${column.field}`"
              :data-field="column.field"
              editor-type="dxTextArea"
            />
          </DxTab>
        </DxTabbedItem>
      </template>
    </ODataTable>
  </div>
</template>

<script>
import ODataTable from '../../components/ODataTable'
import { createColumns, categoryIdField, disabledColumns } from './config'
import { createNamespacedHelpers } from 'vuex'
import { DxSimpleItem, DxTabbedItem, DxTab } from 'devextreme-vue/form'
import DxTextArea from 'devextreme-vue/text-area'
const { mapState: mapAppState, mapActions: mapAppActions } = createNamespacedHelpers('app')
const { mapState: mapUserState } = createNamespacedHelpers('user')
export default {
  name: 'Sources',
  components: {
    ODataTable,
    DxSimpleItem,
    DxTabbedItem,
    DxTab,
    // eslint-disable-next-line vue/no-unused-components
    DxTextArea
  },
  data() {
    return {
      isOnForm: false,
      selectedCategoryId: null,
      mediaIdsForSelectedCategory: []
    }
  },
  computed: {
    ...mapAppState({
      allMedias: state => state.medias.map(media => media.option),
      categories: (state) => state.categories.map(category => category.option),
      selectedMediaIds: state => state.selectedMediaIds
    }),
    ...mapUserState({
      loggedInUser: (state) => state.user.ID
    }),
    columns() {
      return createColumns({
        categories: this.categories,
        medias: this.medias
      })
    },
    medias() {
      return this.isOnForm && this.selectedCategoryId ? this.allMedias.filter(media => this.mediaIdsForSelectedCategory.includes(media.value)) : this.allMedias
    }
  },
  async mounted() {
    this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
    await Promise.all([this.fetchCategories(), this.fetchMedias()])
    this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
  },
  methods: {
    ...mapAppActions(['fetchMedias', 'fetchCategories', 'fetchMediasByCategoryId']),
    handleRowInit(event) {
      event.data.AddedDate = new Date().toISOString()
      event.data.LastVisited = new Date().toISOString()
      event.data.AddedByAdminID = this.loggedInUser
    },
    handleEditorPreparing(event) {
      if (event.isOnForm) {
        event.editorOptions.disabled = disabledColumns.includes(event.dataField)
        if (event.dataField === categoryIdField) {
          event.editorOptions.onValueChanged = (e) => {
            if (e.value) {
              event.setValue(e.value)
              this.handleCategorySelect(e.value)
            }
          }
        }
      }
    },
    handleFormClose() {
      this.isOnForm = false
      this.selectedCategoryId = null
      this.mediaIdsForSelectedCategory = []
    },
    handleEditingStart(event) {
      if (event.data.CategoryID) {
        this.handleCategorySelect(event.data.CategoryID)
      }
    },
    async handleCategorySelect(categoryId) {
      this.mediaIdsForSelectedCategory = await this.fetchMediasByCategoryId(categoryId)
      this.isOnForm = true
      this.selectedCategoryId = categoryId
    }
  }
}
</script>
<style lang="scss">
body:has(.container-app) {
  .dx-item-content.dx-box-item-content {
    display: flex !important;
    flex-direction: column !important;
  }
}
::v-deep .dx-row.dx-data-row.dx-row-lines.dx-edit-row {
    display: none !important
  }
</style>

