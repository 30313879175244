import generateFilename from '@/utils/filename'
import { MEDIAS } from '@/views/medias/config'
import { SITES } from '@/views/sites/config'
import store from '@/store'

const urlRegex = /^(https?|ftp):\/\/([^\s/$.?#].[^\s]*)$/

const downloadable = store.getters.downloadable
const MODE = 'downloadable'

export class FileManager {
  constructor(field) {
    this.field = field
    Object.assign(this, {
      filename: '',
      filePath: '',
      fileUrl: this.getFileUrl(),
      linkUrl: this.getLinkUrl(),
      fileToBeUploaded: {},
      newlyUploadedFile: null,
      uploadedFiles: this.getUploadedFiles(),
      keyResponseFromUploadApi: '',
      showImageFile: false,
      showVideoFile: false,
      showDocumentFile: false,
      isLoading: false,
      showDownloadIcon: false,
      showRemoveIcon: false,
      fileNames: {}
    })
  }

  get customData() {
    if (this.isTypeAssistant) {
      return {
        purpose: 'assistants'
      }
    }
    return {
      filename: this.filename
    }
  }
  get isValidUrl() {
    return !!this.fileUrl || this.field.value
  }
  get accept() {
    return this.field.column.editorOptions.accept || 'image/*'
  }
  get isVideoType() {
    return this.accept.includes('video')
  }
  get isImageType() {
    return this.accept.startsWith('image/')
  }

  get isDownloadableType() {
    return this.field.column.editorOptions.mode === MODE
  }

  get uploadUrl() {
    if (this.isTypeAssistant) {
      return `${process.env.VUE_APP_CHAT_FORM_URL}/files`
    }
    if (this.isDownloadableType) {
      this.field.column.editorOptions.folder = downloadable.pathWithFolder
    }
    return `${process.env.VUE_APP_CONTENTPART_API}/content/sitefile?type=${this.field.column.editorOptions.folder}`
  }
  get maxFileSize() {
    return Math.floor(this.allowedMaxFileSize / 10 ** 6)
  }
  get allowedFileType() {
    return String(this.allowedFileExtensions)
      .split(',')
      .join(' , ')
  }
  get cdnUrl() {
    return (
      this.field.column.editorOptions.cdnUrl || process.env.VUE_APP_IMAGE_URL
    )
  }
  get isTypeAssistant() {
    return this.field.column.editorOptions.folder === 'assistant'
  }
  get allowedMaxFileSize() {
    if (this.isTypeAssistant) {
      return parseInt(process.env.VUE_APP_MAX_ASSISTANT_FILE_UPLOAD_SIZE)
    }
    return parseInt(process.env.VUE_APP_MAX_FILE_SIZE)
  }
  get uploadedFilesLength() {
    return this.uploadedFiles?.length
  }
  get allowedFileExtensions() {
    return this.field.column.editorOptions.accept
      ? []
      : ['.jpg', '.jpeg', '.png', '.gif', '.svg']
  }

  get isAssistantCreated() {
    return !this.isEditMode && this.isTypeAssistant
  }

  get isEditMode() {
    return this.field.column.editorOptions.mode === 'edit'
  }

  get folderNameAvailable() {
    return !downloadable.folder
  }

  get isFileUploaderDisabled() {
    if (this.isTypeAssistant) {
      return !this.isEditMode && this.isTypeAssistant
    }

    return false
  }

  get updatedFileName() {
    if (this.isDownloadableType) {
      const fileName = this.field.value.split('/')
      const updatedFileName = fileName[fileName.length - 1]
      return updatedFileName
    }
    return this.field.value
  }

  get hidePreview() {
    if (this.isDownloadableType) {
      return downloadable.hidePreview
    }
    return false
  }

  get showUploadFile() {
    return !this.isTypeAssistant && !this.hidePreview
  }

  get shouldAllowMultipleUploads() {
    return false
  }
  get isSiteType() {
    return this.field.column.editorOptions.folder === SITES.FOLDER
  }

  get isFileTypeImage() {
    const imageRegex = /\.(jpg|jpeg|png|gif|svg)$/i
    return imageRegex.test(this.field.value)
  }

  get isFileTypeVideo() {
    const videoRegex = /\.(mp4|avi|flv|wmv|mov|mkv|webm)$/i
    return videoRegex.test(this.field.value)
  }

  get isValidFileType() {
    const fileRegex = /\.(txt|json|md|csv|html|js|css|xml|py|java|rb|php|c|cpp|tex|ts|pdf|doc|docx|pptx|tar|xlsx|zip|ppt|odt|ods|odp)$/i
    return fileRegex.test(this.field.value)
  }
  get downloadFileParams() {
    let fileName = this.field.value
    if (this.isSiteType) {
      const parts = this.field.value.split('/')
      fileName = parts[parts.length - 1]
    }

    return { fileUrl: this.fileUrl.replace('img.iknow', 'iknow'), fileName }
  }

  get folderRoute() {
    const [value, folderName] = this.field.value.split('_')
    return value === downloadable.default
      ? `${value}/${folderName}`
      : downloadable.olderPath
  }

  createFileUrl() {
    const VUE_APP_DOWNLOADABLE_FILE_BASE_URL_V2 = `${process.env.VUE_APP_DOWNLOADABLE_FILE_BASE_URL}${downloadable.pathWithFolder}`
    return `${VUE_APP_DOWNLOADABLE_FILE_BASE_URL_V2}/${this.fileToBeUploaded.name}`
  }

  initialise() {
    if (this.field.value && typeof this.field.value === 'string') {
      this.showDownloadIcon = true
      this.showRemoveIcon = true
      this.filename = this.field.value
      if (this.isDownloadableType) {
        this.filename = this.field?.value?.split('/').pop()
        if (this.field.value.split('.')[0] === downloadable.olderPath) {
          this.showDocumentFile = false
        }
        if (!downloadable.fileUploaded) {
          this.fileUrl = `${process.env.VUE_APP_DOWNLOADABLE_FILE_BASE_URL}/${this.field.value}`
          this.filePath = `${process.env.VUE_APP_DOWNLOADABLE_FILE_PATH_BASE_URL}/${this.field.value}`
        } else {
          this.fileUrl = downloadable.fileUrl
          this.filePath = downloadable.filePath
        }
      }
    }
    this.showImageFile = this.isFileTypeImage
    this.showVideoFile = this.isFileTypeVideo
    this.showDocumentFile = this.isValidFileType
  }

  setFiles(file) {
    const uploadFile = Array.isArray(file) ? file[0] : file
    this.setFileToBeUploaded(uploadFile)
  }

  setFileToBeUploaded(file) {
    this.filename = generateFilename(file)
    this.fileToBeUploaded = new File([file], this.filename, {
      type: file.type
    })
  }

  reset() {
    this.fileUrl = ''
    this.field.setValue(this.fileUrl)
    this.showImageFile = false
    this.showVideoFile = false
    this.showDocumentFile = false
    this.filePath = ''
    this.showDownloadIcon = false
    this.showRemoveIcon = false
  }

  setFieldValue(value) {
    this.reset()
    this.linkUrl = value
    this.field.setValue(value)
  }

  getUploadedFileUrl() {
    const baseUploadedFileUrl = `${this.cdnUrl}${this.keyResponseFromUploadApi}`
    if (this.field.column.editorOptions.shouldUseFolderForPreview) {
      return `${this.cdnUrl}${this.field.column.editorOptions.folder}/${this.fileToBeUploaded.name}`
    }
    if (this.field.column.editorOptions.folder === MEDIAS.FOLDER) {
      return baseUploadedFileUrl.replace('images', 'img')
    }
    return baseUploadedFileUrl
  }

  setPreview() {
    if (this.field.value) {
      this.showDownloadIcon = true
      this.showRemoveIcon = true
    }

    this.showImageFile = this.isFileTypeImage
    this.showVideoFile = this.isFileTypeVideo
    this.showDocumentFile = this.isValidFileType
  }

  upload(onUploadSuccess = () => null) {
    this.fileUrl = this.getUploadedFileUrl()
    if (this.isDownloadableType) {
      this.filePath = this.generateFilePath()
      this.fileUrl = this.createFileUrl()
    }
    this.field.setValue(this.fileToBeUploaded?.name)
    if (this.field.column.editorOptions.folder === SITES.FOLDER) {
      this.field.setValue(this.keyResponseFromUploadApi)
    }
    if (this.isTypeAssistant) {
      onUploadSuccess({
        fileToBeUploaded: this.fileToBeUploaded,
        newlyUploadedFile: this.newlyUploadedFile
      })
    }
    this.setPreview()
  }

  deleteFile(file) {
    this.uploadedFiles = this.uploadedFiles.filter(
      uploadedFile => uploadedFile.filename !== file.filename
    )
  }

  getFileUrl = () => {
    const field = this.field
    const image = field?.value || ''
    const baseURL =
      field.column.editorOptions.cdnUrl || process.env.VUE_APP_IMAGE_URL

    if (
      field.column.editorOptions.shouldShowUploadedImagePreview &&
      urlRegex.test(image)
    ) {
      return ''
    }
    if (!image || image.includes(baseURL)) {
      return image
    }
    let folder = `images/${field.column.editorOptions.folder}`
    if (field.column.editorOptions.folder === MEDIAS.FOLDER) {
      folder = folder.replace('images', 'img')
    }
    if (field.column.editorOptions.shouldUseFolderForPreview) {
      return `${baseURL}/${field.column.editorOptions.folder}/${image}`
    }
    if (image.includes(folder)) {
      return `${baseURL}/${image}`
    }
    return `${baseURL}/${folder}/${image}`
  };
  getLinkUrl = () => {
    if (!urlRegex.test(this.field.value)) {
      return ''
    }
    return this.field.value
  };

  getUploadedFiles = () => {
    return this.field.column.editorOptions.selectedAssistants
  };

  generateFilePath() {
    const key = this.keyResponseFromUploadApi
    const uploadedFileStringFromApi = 'Uploaded file:'
    const startIndex = key.indexOf(uploadedFileStringFromApi)
    let filePath = key
      .substring(startIndex + uploadedFileStringFromApi.length)
      .trim()
    const filePathRegex = /^(.*?)(?: \(\d+ bytes\))$/
    const match = filePath.match(filePathRegex)
    filePath = match ? match[1] : null
    return filePath
  }
}
