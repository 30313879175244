var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tags" },
    [
      _vm.filters.siteSearch
        ? _c(
            "el-tag",
            {
              staticClass: "tag",
              attrs: { closable: "", type: "info", size: "small" },
              on: {
                close: function($event) {
                  return _vm.setFilters({ siteSearch: "" })
                }
              }
            },
            [
              _vm._v(
                " Site: " + _vm._s(_vm.siteName(_vm.filters.siteSearch)) + " "
              )
            ]
          )
        : _vm._e(),
      _vm.filters.positionSearch
        ? _c(
            "el-tag",
            {
              staticClass: "tag",
              attrs: { closable: "", type: "info", size: "small" },
              on: {
                close: function($event) {
                  return _vm.setFilters({ positionSearch: "" })
                }
              }
            },
            [_vm._v(" Position: " + _vm._s(_vm.filters.positionSearch) + " ")]
          )
        : _vm._e(),
      _vm.filters.tagSearch
        ? _c(
            "el-tag",
            {
              staticClass: "tag",
              attrs: { closable: "", type: "info", size: "small" },
              on: {
                close: function($event) {
                  return _vm.setFilters({ tagSearch: "" })
                }
              }
            },
            [_vm._v(" Tag: " + _vm._s(_vm.filters.tagSearch) + " ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }