var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-flex" },
    [
      _c("el-input", {
        attrs: { rows: 10, type: "textarea", placeholder: "Please input" },
        on: { input: _vm.handleChange },
        model: {
          value: _vm.pastedUsers,
          callback: function($$v) {
            _vm.pastedUsers = $$v
          },
          expression: "pastedUsers"
        }
      }),
      _c(
        "el-button",
        {
          staticClass: "margin-left-md self-start",
          attrs: {
            type: "primary",
            loading: _vm.isLoading,
            disabled: _vm.isLoading
          },
          on: { click: _vm.handleImportClick }
        },
        [
          _vm._v(
            _vm._s(
              _vm.$t(
                _vm.isDeactiveImportType
                  ? "manageUsers.deactivateUser.stepLabel"
                  : "manageUsers.importCsv.organizeUsers"
              )
            )
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }