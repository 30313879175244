var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-assistant-container" },
    [
      _vm.isComparisionDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("assistants.compare"),
                visible: _vm.isComparisionDialog
              },
              on: {
                "update:visible": function($event) {
                  _vm.isComparisionDialog = $event
                },
                close: _vm.handleDialogClose
              }
            },
            [
              _vm.isComparisionDialog
                ? _c("compareAssistants", {
                    attrs: { "selected-assistants": _vm.selectedRowsData }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.isHistoryDialog
        ? _c(
            "el-dialog",
            {
              attrs: { title: "Chat History", visible: _vm.isHistoryDialog },
              on: {
                "update:visible": function($event) {
                  _vm.isHistoryDialog = $event
                },
                close: _vm.handleDialogClose
              }
            },
            [
              _vm.isHistoryDialog
                ? _c("chatHistory", {
                    attrs: { assistant: _vm.selectedAssistant }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "vue-final-modal",
        {
          attrs: {
            resize: true,
            styles: _vm.styleModal,
            "content-style": _vm.contentStyleModal,
            "lock-scroll": false,
            "hide-overlay": false,
            "click-to-close": false,
            "esc-to-close": true,
            "z-index-base": 1000000000,
            "z-index": false,
            "focus-retain": true,
            "fit-parent": true,
            drag: true,
            "drag-selector": ".el-dialog__header",
            "keep-changed-style": false,
            "resize-directions": ["t", "tr", "r", "br", "b", "bl", "l", "tl"]
          },
          model: {
            value: _vm.isDialogOpen,
            callback: function($$v) {
              _vm.isDialogOpen = $$v
            },
            expression: "isDialogOpen"
          }
        },
        [
          _c("div", { staticClass: "el-dialog__header" }, [
            _c("span", { staticClass: "el-dialog__title" }, [
              _vm._v("Chat Assistant")
            ]),
            _c(
              "button",
              {
                staticClass: "el-dialog__headerbtn",
                attrs: { type: "button", "aria-label": "Close" }
              },
              [
                _c("i", {
                  staticClass: "el-dialog__close el-icon el-icon-close",
                  on: { click: _vm.handleDialogClose }
                })
              ]
            )
          ]),
          _vm.isDialogOpen
            ? _c("chatAssistant", {
                attrs: {
                  assistant: _vm.selectedAssistant,
                  "is-dialog-open": _vm.isDialogOpen
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("ODataTable", {
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns.all,
          "add-edit-columns": _vm.columns.addEdit,
          "action-buttons-width": 150,
          "custom-delete-message": _vm.$t("assistants.confirmDeleteMessage"),
          "total-label": _vm.$t("assistants.total"),
          title: _vm.$t("assistants.title"),
          "is-static-data-source": true,
          "is-custom-form": true,
          "static-data": _vm.assistants,
          "add-row-button-label": _vm.$t("assistants.create"),
          "should-render-master-detail": true
        },
        on: {
          rowInit: _vm.handleRowInit,
          rowInserted: _vm.handleRowInserted,
          rowRemoved: _vm.handleRowRemoved,
          rowUpdated: _vm.handleRowUpdated,
          rowInserting: _vm.handleRowInserting,
          rowUpdating: _vm.handleRowUpdating,
          editorPreparing: _vm.handleEditorPreparing,
          editingCancelling: _vm.handleEditingCancel,
          editingStart: _vm.handleEditingStart,
          fileToBeDeleted: _vm.handleFileToBeDeleted,
          fileUploaded: _vm.handleFileUploaded,
          rowSaved: _vm.handleRowSaved,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "custom-buttons",
            fn: function() {
              return [
                _c("DxButton", {
                  attrs: {
                    icon: "comment",
                    "on-click": _vm.handleChatAssistantClick
                  }
                }),
                _c("DxButton", {
                  attrs: {
                    icon: _vm.chatHistoryIconUrl,
                    "on-click": _vm.handleViewHistory
                  }
                }),
                _c("DxButton", {
                  attrs: {
                    icon: "copy",
                    "on-click": _vm.handleAssistantCloneClick
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "custom-toolbar-after",
            fn: function() {
              return [
                _vm.selectedRowsData.length > 1
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleCompareAssistants }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("assistants.compare")) + " ")]
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "custom-form",
            fn: function() {
              return [
                _c(
                  "DxTabbedItem",
                  [
                    _c(
                      "DxTab",
                      { attrs: { title: _vm.$t("assistants.tab.info.label") } },
                      _vm._l(_vm.columns.infoColumns, function(column) {
                        return _c("DxSimpleItem", {
                          key: "add_edit_" + column.field,
                          attrs: { "data-field": column.field }
                        })
                      }),
                      1
                    ),
                    _c(
                      "DxTab",
                      { attrs: { title: _vm.$t("assistants.tools.title") } },
                      _vm._l(_vm.columns.toolsColumns, function(column) {
                        return _c("DxSimpleItem", {
                          key: "add_edit_" + column.field,
                          attrs: {
                            "data-field": column.field,
                            "editor-type": "dxTextArea",
                            "editor-options": { height: "200px" }
                          }
                        })
                      }),
                      1
                    ),
                    _c(
                      "DxTab",
                      {
                        attrs: {
                          title: _vm.$t("assistants.metaContents.title")
                        }
                      },
                      _vm._l(_vm.columns.metaColumns, function(column) {
                        return _c("DxSimpleItem", {
                          key: "add_edit_" + column.field,
                          attrs: {
                            "data-field": column.field,
                            "editor-type": "dxTextArea",
                            "editor-options": { height: "200px" }
                          }
                        })
                      }),
                      1
                    ),
                    _c(
                      "DxTab",
                      {
                        attrs: { title: _vm.$t("assistants.fileUpload.title") }
                      },
                      _vm._l(_vm.columns.fileColumns, function(column) {
                        return _c("DxSimpleItem", {
                          key: "add_edit_" + column.field,
                          attrs: { "data-field": column.field }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "details",
            fn: function(ref) {
              var data = ref.data
              return [
                _c(
                  "DxTabPanel",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "tools-tab",
                          fn: function() {
                            return [
                              _c("AssistantsTools", { attrs: { data: data } })
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "meta-contents-tab",
                          fn: function() {
                            return [
                              _c("AssistantMetaContents", {
                                attrs: { data: data }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("DxItem", {
                      attrs: {
                        title: _vm.$t("assistants.tools.title"),
                        template: "tools-tab"
                      }
                    }),
                    _c("DxItem", {
                      attrs: {
                        title: _vm.$t("assistants.metaContents.title"),
                        template: "meta-contents-tab"
                      }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }