import store from '@/store'
import Store from '@/models/odataStore'
const superAdminFields = ['AdminLevel']
const selectedAdminFields = ['ExtID']
export const dateColumns = ['LastLoginDate', 'LastUpdate', 'AddedDate']
const siteIdColumn = 'SiteID'

const maskedPasswordField = 'PassWord'

export const visibleEditColumns = [
  'RealCustomer',
  'NoDeactivation',
  'EMail',
  'FirstName',
  'LastName',
  'Account',
  'SubAccount',
  'Company',
  maskedPasswordField,
  'SecurePassword',
  siteIdColumn,
  'Reference',
  'Note',
  'Note2',
  'Address',
  'Postal_Address',
  'Postal_Code',
  'DeactivationDate',
  ...dateColumns
].map(field => ({
  field,
  width: 110,
  alignment: 'center',
  ...(field === siteIdColumn && {
    label: 'site',
    lookup: {
      store: new Store({ resource: 'Sites' }),
      paginate: true,
      pageSize: 50
    },
    valueExpr: 'ID',
    displayExpr: 'SiteName'
  }),
  ...(field === maskedPasswordField && {
    calculateCellValue: event => {
      if (event.SecurePassword) {
        return '*********'
      }
      return event.PassWord
    }
  })
}))

export const expandableEditColumns = [
  'bAccessAll',
  'LoggedIn',
  'IsAdmin',
  'MobileNo',
  'UID',
  'AltURL',
  'LoginCode',
  'AltEmail',
  'UserName',
  'Token',
  'AltID',
  ...selectedAdminFields,
  ...(store.state.user.user.isSuperAdmin ? superAdminFields : [])
].map(field => ({
  field,
  width: 110,
  alignment: 'center'
}))

export const addEditColumns = [...visibleEditColumns, ...expandableEditColumns]

export const columns = [
  { field: 'ID', width: 110, alignment: 'center' },
  ...addEditColumns
]

export const tableName = 'Customers'

export const LOG_ACTION = Object.freeze({
  EDIT: 'EDIT',
  AUTOLOGIN: 'AUTOLOGIN',
  VIEW: 'VIEW'
})
