var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ODataTable", {
    key: _vm.selectedSiteOnPage,
    ref: "oDataTableComponentRef",
    attrs: {
      "alias-resource":
        _vm.shouldShowAddButton && _vm.selectedSiteOnPage ? "Categories" : "",
      columns: _vm.columns.all,
      "add-edit-columns": _vm.columns.addEdit,
      resource: "Categories",
      "total-label": _vm.$t(_vm.section + ".total"),
      "file-name": _vm.$t(_vm.section + ".fileName"),
      "add-row-button-label": _vm.$t(_vm.section + ".add"),
      title: _vm.$t(_vm.section + ".title"),
      "should-render-master-detail": true,
      filter: _vm.filter,
      "should-show-add-button": _vm.shouldShowAddButton,
      "should-show-edit-button": _vm.shouldShowEditButton,
      "should-show-delete-button": _vm.shouldShowDeleteButton
    },
    on: {
      rowInit: _vm.handleRowInit,
      editorPreparing: _vm.handleEditorPreparing,
      clearFilters: _vm.handleFiltersClear,
      contentReady: _vm.handleContentReady
    },
    scopedSlots: _vm._u(
      [
        {
          key: "toggle-button",
          fn: function(ref) {
            var data = ref.data
            return [_vm._t("toggle-button", null, { data: data })]
          }
        },
        _vm.shouldShowToggleButton
          ? {
              key: "custom-buttons",
              fn: function() {
                return [
                  _c("DxButton", {
                    attrs: { template: "custom-button-template" }
                  })
                ]
              },
              proxy: true
            }
          : null,
        {
          key: "details",
          fn: function(ref) {
            var data = ref.data
            return [
              _c("Detail", {
                attrs: {
                  data: data,
                  "should-show-add-button": _vm.shouldShowAddButton,
                  "should-show-edit-button": _vm.shouldShowEditButton,
                  "should-show-delete-button": _vm.shouldShowDeleteButton,
                  "should-show-toggle-button": _vm.shouldShowToggleButton,
                  "should-show-title": _vm.shouldShowTitle,
                  "section-for-sub-programs": _vm.sectionForSubPrograms
                },
                on: {
                  subCategoryAdded: _vm.handleSubCategoryAddedOrRemoved,
                  subCategoryRemoved: _vm.handleSubCategoryAddedOrRemoved
                }
              })
            ]
          }
        },
        !_vm.shouldShowSiteDropdown
          ? {
              key: "custom-toolbar-before",
              fn: function() {
                return [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        value: _vm.selectedValue,
                        "allow-create": false,
                        "default-first-option": "",
                        filterable: "",
                        placeholder: _vm.$t("select")
                      },
                      on: { change: _vm.handleChange }
                    },
                    _vm._l(_vm.options, function(option) {
                      return _c("el-option", {
                        key: option.value,
                        attrs: { label: option.label, value: option.value }
                      })
                    }),
                    1
                  )
                ]
              },
              proxy: true
            }
          : null,
        _vm.shouldShowSiteDropdown
          ? {
              key: "custom-toolbar-after",
              fn: function() {
                return [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        value: _vm.selectedSiteOnPage,
                        "allow-create": false,
                        "default-first-option": "",
                        filterable: "",
                        placeholder: _vm.$t("contentPart.site")
                      },
                      on: { change: _vm.handleValueChange }
                    },
                    _vm._l(_vm.sites, function(site) {
                      return _c("el-option", {
                        key: site.value,
                        attrs: { label: site.label, value: site.value }
                      })
                    }),
                    1
                  )
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }