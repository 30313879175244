import { AssistantMetaContents } from './assistantMetaContents'
import { AssistantTools } from './assistantTools'

export class Assistant {
  constructor({
    tools = [],
    id,
    instructions = '',
    name = '',
    model = '',
    description = '',
    metadata,
    file_ids
  } = {}) {
    Object.assign(this, {
      instructions,
      name,
      model,
      description,
      id,
      file_ids
    })
    this.tools = new AssistantTools(tools)
    this.metadata = new AssistantMetaContents(metadata)
  }

  get payload() {
    return {
      instructions: this.instructions || '',
      name: this.name || '',
      tools: this.tools.payload,
      model: this.model,
      description: this.description || '',
      metadata: this.metadata.payload,
      file_ids: this.file_ids
    }
  }

  get patchPayload() {
    return {
      payload: this.payload,
      id: this.id
    }
  }

  get option() {
    return {
      label: this.name,
      value: this.id
    }
  }

  isEqual(id) {
    return id === this.id
  }

  updatePartial(updateParams = {}) {
    Object.assign(this, { ...updateParams })
  }

  static fromList(assistants = []) {
    return assistants.map(assistant => new this(assistant))
  }
}
