<template>
  <div class="app-container py-none">
    <el-container>
      <el-main>
        <el-form
          ref="formRef"
          label-position="top"
          size="small"
          :model="formModal"
          :rules="rules"
        >
          <el-row class="m-container">
            <el-col
              v-for="(campaign, index) in formModal.campaignData"
              :key="index"
              :xs="24"
              :sm="16"
              class="column m-border bg-gray-light"
            >
              <el-row type="flex" class="center">
                <el-col :span="8" class="m-title">{{ $t("name") }} :</el-col>
                <el-col :span="16">
                  <el-row type="flex" class="center gap-md">
                    <span>{{ campaign.name }} :</span>
                    <span class="primary">{{
                      courseCampaign.formattedAddedDate
                    }}</span>
                  </el-row>
                </el-col>
              </el-row>

              <el-row type="flex" class="center">
                <el-col
                  :span="8"
                  class="m-title"
                >{{ $t("courseCampaign.specifyDetails.account") }} :</el-col>
                <el-col :span="16" class="primary">
                  <el-form-item
                    prop="customerAccount"
                  >
                    <el-select
                      v-model="campaign.customerAccount"
                      filterable
                      :placeholder="
                        $t('courseCampaign.choosePrograms.accountPlaceholder')
                      "
                      size="medium"
                      @change="$event => handleCustomerAccount($event, index)"
                    >
                      <el-option
                        v-for="item in customerAccounts"
                        :key="item.ID"
                        :label="item.Account"
                        :value="item.ID"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row type="flex" class="center">
                <el-col
                  :span="8"
                  class="m-title"
                >{{
                  $t("courseCampaign.specifyDetails.totalContacts")
                }}
                  :</el-col>
                <el-col :span="16" class="primary">
                  <div v-loading="isLoading" class="loader-box">
                    {{ campaign.recipients }}
                  </div>
                </el-col>
              </el-row>

              <el-row type="flex" class="center">
                <el-col
                  :span="8"
                  class="m-title"
                >{{ $t("courseCampaign.specifyDetails.sendList") }} :</el-col>
                <el-col :span="16" class="primary">
                  <el-form-item
                    :prop="`campaignData.${index}.sendListId`"
                  >
                    <el-select
                      v-model="campaign.sendListId"
                      class="send-list"
                      filterable
                      :loading="!sendLists.length"
                      :placeholder="$t('updateSendList.selectSendList')"
                      size="medium"
                      @change="sendListId => handleRecipients(sendListId, index)"
                    >
                      <el-option
                        v-for="item in sendLists"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row type="flex" class="center">
                <el-col
                  :span="8"
                  class="m-title"
                >{{
                  $t("courseCampaign.specifyDetails.campaignDefaults")
                }}
                  :</el-col>
                <el-col :span="16" class="primary">
                  <el-select
                    v-model="campaign.campaignDefault"
                    class="send-list"
                    filterable
                    :loading="!campaignDefaultList"
                    :placeholder="
                      $t('courseCampaign.specifyDetails.campaignDefaults')
                    "
                    size="medium"
                    @change="$event => handleCampaignDefault($event, index)"
                  >
                    <el-option
                      v-for="item in campaignDefaultList"
                      :key="item.ID"
                      :label="item.CampaignName"
                      :value="item.ID"
                    />
                  </el-select>
                </el-col>
              </el-row>

              <el-row type="flex" class="center">
                <el-col
                  :span="8"
                  class="m-title"
                >{{ $t("courseCampaign.specifyDetails.email") }} :</el-col>
                <el-col :span="16" class="primary">
                  <span class="m-title">{{ index+1 }}.</span>
                </el-col>
              </el-row>

              <el-row type="flex">
                <el-checkbox v-model="campaign.specifyReceiver">{{
                  $t("courseCampaign.specifyDetails.specifyToField")
                }}</el-checkbox>
              </el-row>

              <el-row
                class="mx-title"
              >{{ $t("courseCampaign.specifyDetails.receiver") }} :</el-row>
              <el-row :gutter="15">
                <el-col :span="24">
                  <el-form-item :model="campaign.sender">
                    <el-row>
                      <el-col :span="12" class="paddingField">
                        <el-form-item
                          :label="$t('courseCampaign.specifyDetails.details.name')"
                          :prop="`campaignData.${index}.sender.name`"
                          class="mb-none"
                        >
                          <el-input
                            v-model="campaign.sender.name"
                            :placeholder="
                              $t('courseCampaign.specifyDetails.details.name')
                            "
                          />
                        </el-form-item>
                      </el-col>
                      <el-col :span="12" class="paddingFieldl">
                        <el-form-item
                          :label="$t('courseCampaign.specifyDetails.details.email')"
                          :prop="`campaignData.${index}.sender.email`"
                          class="mb-none"
                        >
                          <el-input
                            v-model="campaign.sender.email"
                            type="email"
                            :placeholder="
                              $t('courseCampaign.specifyDetails.details.email')
                            "
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row
                class="m-title"
              >{{ $t("courseCampaign.specifyDetails.content") }} :
              </el-row>

              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item
                    :label="$t('courseCampaign.specifyDetails.details.subject')"
                    :prop="`campaignData.${index}.subject`"
                    class="mb-none"
                  >
                    <el-input
                      v-model="campaign.subject"
                      :placeholder="
                        $t('courseCampaign.specifyDetails.details.subject')
                      "
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    :label="
                      $t('courseCampaign.specifyDetails.details.previewText')
                    "
                    prop="previewText"
                    class="mb-none"
                  >
                    <el-input
                      v-model="campaign.previewText"
                      size="medium"
                      :placeholder="
                        $t('courseCampaign.specifyDetails.details.previewText')
                      "
                    />
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="15">
                <el-col :span="24">
                  <el-form-item
                    :label="
                      $t('courseCampaign.specifyDetails.details.ePostmail')
                    "
                    :prop="`campaignData.${index}.templateId`"
                    class="mb-none"
                  >
                    <el-select
                      v-model="campaign.templateId"
                      class="send-list"
                      filterable
                      :loading="!emailTemplates.length"
                      :placeholder="
                        $t('courseCampaign.specifyDetails.details.ePostmail')
                      "
                      size="medium"
                    >
                      <el-option
                        v-for="item in emailTemplates"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="15">
                <el-col :span="24">
                  <el-form-item
                    :label="
                      $t('courseCampaign.specifyDetails.details.introText')
                    "
                    prop="introText"
                    class="mb-none"
                  >
                    <el-input
                      v-model="campaign.introText"
                      :rows="4"
                      type="textarea"
                      :placeholder="
                        $t('courseCampaign.specifyDetails.details.introText')
                      "
                    />
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row class="action-box" :gutter="15">
                <el-col :span="12">
                  <div class="campaign-action-btns">
                    <el-tooltip
                      v-if="index === formModal.campaignData.length - 1"
                      class="box-item"
                      effect="dark"
                      content="Add new email"
                      placement="top-start"
                    >
                      <el-button
                        type="primary"
                        class="justify-center"
                        size="medium"
                        @click="addNewCampaign"
                      >
                        <i class="el-icon-plus fw-900" />
                      </el-button>
                    </el-tooltip>

                    <el-tooltip
                      class="box-item"
                      effect="dark"
                      content="Delete email"
                      placement="top-start"
                    >
                      <el-button
                        type="danger"
                        class="justify-center"
                        size="medium"
                        :disabled="formModal.campaignData.length === 1"
                        @click="() => deleteCampaign(index)"
                      >
                        <i class="el-icon-delete fw-900" />
                      </el-button>
                    </el-tooltip>
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import BrevoService from '@/api/brevo'
import CampaignDefaultService from '@/api/campaignDefault'
import CustomersAccountService from '@/api/customersAccount'
import UpdateSendListService from '@/api/updateSendList'
import { MUTATION_TYPES } from '@/store/mutations'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { validators } from './config'
import { hasKey } from '@/utils/validate'
const { mapState, mapMutations } = createNamespacedHelpers('courseCampaign')

export default {
  name: 'SpecifyDetails',
  data() {
    return {
      specifyReceiver: true,

      emailTemplates: [],
      customerAccounts: [],
      sendLists: [],
      campaignDefaultList: [],

      isLoading: false,
      formModal: {
        campaignData: []
      },
      rules: {},
      lists: [
        'emailTemplates',
        'customerAccounts',
        'sendLists',
        'campaignDefaultList'
      ]
    }
  },
  computed: {
    ...mapState({
      total: state => state.selectedCustomers.total,
      courseCampaign: state => state.courseCampaign,
      miscellaneous: state => state.miscellaneous
    }),
    ...mapGetters(['name'])
  },
  watch: {
    'formModal.campaignData': {
      handler(data) {
        this.setCourseCampaign({
          courseCampaigns: data
        })
        this.updateValidationRules()
      },
      deep: true
    }
  },
  created() {
    this.addNewCampaign()
    this.updateValidationRules()

    for (const list of this.lists) {
      const shouldFetchList = !hasKey(this.miscellaneous, list) || !this.miscellaneous?.[list].length
      if (shouldFetchList) {
        const listToFetch = this.listToFetch()
        listToFetch?.[list]()
      } else {
        Object.assign(this[list], this.miscellaneous?.[list])
      }
    }

    const isDataAlreadyExists = this.courseCampaign.courseCampaigns.length
    if (isDataAlreadyExists) {
      this.formModal.campaignData = [...this.courseCampaign.courseCampaigns]
    }
  },
  methods: {
    ...mapMutations({
      setCourseCampaign: MUTATION_TYPES.COURSE_CAMPAIGN.SET_COURSE_CAMPAIGN,
      setMiscellaneous: MUTATION_TYPES.COURSE_CAMPAIGN.SET_MISCELLANEOUS
    }),
    listToFetch() {
      return {
        emailTemplates: this.handleFetchEmailTemplates,
        customerAccounts: this.handleFetchCustomerAccounts,
        sendLists: this.handleFetchSendList,
        campaignDefaultList: this.handleFetchCampaignDefaultList
      }
    },
    updateValidationRules() {
      const rules = {}
      this.formModal.campaignData.forEach((_, index) => {
        rules[`campaignData.${index}.subject`] = [
          { validator: validators.validateSubject, trigger: 'change' }
        ]
        rules[`campaignData.${index}.templateId`] = [
          { validator: validators.validateTemplate, trigger: 'change' }
        ]
        rules[`campaignData.${index}.sender.name`] = [
          { validator: validators.validateSenderName, trigger: 'change' }
        ]
        rules[`campaignData.${index}.sender.email`] = [
          { validator: validators.validateSenderEmail, trigger: 'change' }
        ]
        rules[`campaignData.${index}.sendListId`] = [
          { validator: validators.validateSendList, trigger: 'change' }
        ]
      })
      this.rules = rules
    },
    async handleFetchCustomerAccounts() {
      const customerAccounts = await CustomersAccountService.getAllCustomerAccount()
      this.setMiscellaneous({ customerAccounts: customerAccounts?.value ?? [] })
      this.customerAccounts = customerAccounts?.value ?? []
    },
    async handleFetchSendList() {
      this.sendLists = await UpdateSendListService.fetchAllSendLists()
      this.setMiscellaneous({ sendLists: this.sendLists ?? [] })
    },
    async handleFetchCampaignDefaultList() {
      const courseCampaigns = await CampaignDefaultService.fetchCourseCampaigns()
      this.setMiscellaneous({ campaignDefaultList: courseCampaigns?.value ?? [] })
      this.campaignDefaultList = courseCampaigns?.value
    },
    async handleFetchContactCount(sendListId, index) {
      this.isLoading = true
      const contacts = await BrevoService.fetchContactsOfSendList({ id: sendListId, options: { sort: 'desc' }})
      this.formModal.campaignData[index].recipients = contacts?.count
      this.isLoading = false
    },
    async handleFetchEmailTemplates() {
      const emailTemplates = await BrevoService.fetchEmailTemplates({ sort: 'desc' })
      this.setMiscellaneous({ emailTemplates: emailTemplates?.templates ?? [] })
      this.emailTemplates = emailTemplates?.templates ?? []
    },
    handleCampaignDefault(campaignDefaultID, index) {
      const campaignDefault = this.campaignDefaultList.find(
        campaign => campaign.ID === campaignDefaultID
      )
      const { Subject, PreviewText } = campaignDefault
      this.formModal.campaignData[index].subject = Subject
      this.formModal.campaignData[index].previewText = PreviewText
    },
    handleCustomerAccount(customerAccountID, index) {
      const customerAccount = this.customerAccounts.find(
        account => account.ID === customerAccountID
      )
      const { SenderName, SenderEmail } = customerAccount

      this.formModal.campaignData[index].sender.name = SenderName
      this.formModal.campaignData[index].sender.email = SenderEmail
    },
    addNewCampaign() {
      const totalRecords = this.formModal.campaignData.length

      const campaignTemplateData = this.courseCampaign.createCampaignTemplate()

      const campaignTemplate = {
        ...campaignTemplateData,
        name: this.courseCampaign.name + ' ' + (totalRecords + 1)
      }

      this.formModal.campaignData = [...this.formModal.campaignData, campaignTemplate]
    },
    deleteCampaign(index) {
      if (index > 0) {
        this.formModal.campaignData = this.formModal.campaignData.filter((_, campaignIndex) => campaignIndex !== index)
      }
    },
    handleRecipients(sendListId, index) {
      if (sendListId) {
        this.handleFetchContactCount(sendListId, index)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fw-900 {
  font-weight: 900;
}
.loader-box {
  width: 50px !important;
}
.py-none {
  padding-top: 0%;
  padding-bottom: 0%;
}

.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.m-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.m-title {
  font-size: 14px;
  font-weight: 700;
  color: #606266;
}

.mx-title {
  font-size: 16px;
  font-weight: 700;
  color: #606266;
}

.m-border {
  border: 1px solid #cfd0d1;
  border-radius: 5px;
  padding: 12px;
}

.bg-gray-light {
  background-color: #f1f1f1
}

.mb-none {
  margin-bottom: 0px;
}

.paddingField {
  padding-right: 7.5px;
}

.paddingFieldl {
  padding-left: 7.5px;
}

.campaign-action-btns {
  display: flex;
  align-items: center;
}

.action-box {
  margin-top: 20px;
}
</style>
