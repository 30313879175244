import ODataTable from '../../components/ODataTable'
import { columns, addEditColumns, toggleColumns } from './config'
import { DxButton } from 'devextreme-vue/data-grid'
import { createNamespacedHelpers } from 'vuex'
import { Reflection } from '@/models/reflection'

import Detail from './details'
import { TransactionItemList } from '@/models/transaction'
import { DxProgressBar } from 'devextreme-vue/progress-bar'
const { mapState, mapActions } = createNamespacedHelpers('reflection')
const { mapState: mapUserState } = createNamespacedHelpers('user')
const {
  mapState: mapCustomerState,
  mapActions: mapCustomerActions
} = createNamespacedHelpers('customer')
export default {
  name: 'ReviewReflection',
  components: {
    ODataTable,
    DxButton,
    Detail,
    DxProgressBar
  },
  data() {
    return {
      approveAll: false,
      rejectAll: false,
      columns,
      addEditColumns,
      isApproved: null,
      selectedReflections: [],
      selectedReflection: null,
      showDetails: false,
      comment: '',
      dialogVisible: false,
      selectedRowID: null,
      isBulk: false,
      isLoading: false,
      percentage: 0
    }
  },
  computed: {
    ...mapState({
      reflectionIds: state => state.reflectionIds
    }),
    ...mapUserState({
      loggedInUser: state => state.user.ID
    }),

    filter() {
      if (this.reflectionIds.length) {
        return [['ID', 'in', `(${this.reflectionIds.join(',')})`]]
      }
      return []
    },
    ...mapCustomerState({
      selectedCustomer: state => state.customer
    }),

    isBulkApprovalLoading() {
      return this.isLoading && this.isApproved && this.isBulk
    },

    isBulkRejectionLoading() {
      return this.isLoading && !this.isApproved && this.isBulk
    }
  },
  mounted() {
    if (!this.reflectionIds.length) {
      this.$router.push(`/follow-up/reflections`)
    }
  },
  destroyed() {
    this.clearReflectionIds()
  },
  methods: {
    ...mapActions(['clearReflectionIds']),
    ...mapCustomerActions(['fetchCustomer']),

    handleRowInit(event) {
      event.data.DateAdded = new Date().toISOString()
      event.data.LastUpdate = new Date().toISOString()
    },

    handleRowUpdating(event) {
      event.newData.LastUpdate = new Date().toISOString()
    },

    handleRefresh() {
      this.isBulk = false

      this.isApproved = null

      this.selectedRowID = null

      this.comment = ''
    },
    async handleApprovalOrReject() {
      await this.$refs.oDataTableComponentRef.dataSource.store.update(
        this.selectedRowID,
        Reflection.createApprovalParams({
          isApproved: this.isApproved,

          adminId: this.loggedInUser,

          adminComment: this.comment
        })
      )

      await this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.refresh()
      this.handleRefresh()
    },

    handleApprove(event) {
      this.dialogVisible = true

      this.selectedRowID = event.row.data.ID
      const isApproved = this.hasNotBeenReviewed(event)
        ? true
        : !event.row.data.IsApproved

      this.isApproved = isApproved

      this.isBulk = false
    },

    handleRejected(event) {
      this.dialogVisible = true

      this.selectedRowID = event.row.data.ID
      const isApproved = this.hasNotBeenReviewed(event)
        ? false
        : !event.row.data.IsApproved

      this.isApproved = isApproved

      this.isBulk = false
    },

    isPendingOrRejected(event) {
      return !event.row.data.IsApproved
    },

    isPendingOrApproved(event) {
      return event.row.data.IsApproved !== false
    },

    hasNotBeenReviewed(event) {
      return event.row.data.IsApproved === null
    },
    async handleBulkApprovalOrRejection() {
      const reflectionsToApproveOrReject = this.selectedReflections.filter(
        selectedReflection => selectedReflection.IsApproved !== this.isApproved
      )
      const params = Reflection.createApprovalParams({
        isApproved: this.isApproved,

        adminId: this.loggedInUser,

        adminComment: this.comment
      })

      this.isLoading = true
      const transactionItems = new TransactionItemList(
        reflectionsToApproveOrReject,
        1
      )
      while (transactionItems.hasMore) {
        const items = transactionItems.next()
        try {
          await this.$refs.oDataTableComponentRef.dataSource.store.update(
            items[0].ID,
            params
          )

          this.percentage = transactionItems.percentage
        } catch (error) {
          this.$message({ type: error, message: error.message })
        }
      }

      this.isLoading = false

      reflectionsToApproveOrReject.forEach(reflection => {
        Object.assign(reflection, params)
      })
      this.handleRefresh()
    },

    handleApproveAll() {
      this.dialogVisible = true

      this.isApproved = true

      this.isBulk = true

      this.percentage = 0
    },
    handleRejectAll() {
      this.dialogVisible = true

      this.isApproved = false

      this.isBulk = true

      this.percentage = 0
    },
    handleSelectionChange({ selectedRowsData }) {
      this.selectedReflections = selectedRowsData
    },
    handleClose() {
      this.dialogVisible = false
    },
    async handleSaveClick() {
      this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()

      this.dialogVisible = false
      try {
        if (this.isBulk) {
          await this.handleBulkApprovalOrRejection()
        } else {
          await this.handleApprovalOrReject()
        }
      } finally {
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
      }
    },

    async handleViewClick(event) {
      this.selectedReflection = event.row.data

      await this.fetchCustomer(event.row.data.ApprovedByAdminID)

      this.showDetails = true
    },
    handleDetailsDialogClose() {
      this.selectedReflection = null

      this.showDetails = false
    },

    isApprovedOrRejected(event) {
      return event.row.data.IsApproved !== null
    },
    handleEditingStart() {
      this.addEditColumns = this.addEditColumns.filter(
        column => !toggleColumns.includes(column.field)
      )
    }
  }
}
