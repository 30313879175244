<template>
  <ODataTable
    ref="oDataTableComponentRef"
    :columns="columns"
    :add-edit-columns="columns"
    resource="Sites"
    :total-label="$t('sites.total')"
    :file-name="$t('sites.fileName')"
    :add-row-button-label="$t('sites.add')"
    :title="$t('sites.title')"
    :should-show-delete-button="false"
    :should-render-master-detail="true"
    :export-formats="exportFormats"
    @rowInit="handleRowInit"
    @rowInserted="handleRowInserted"
    @editorPreparing="handleEditorPreparing"
    @rowUpdating="handleRowUpdating"
    @rowExpanded="handleRowExpanded"
  >
    <template #details="{ data }">
      <Detail :data="data" />
    </template>
  </ODataTable>
</template>
<script>
import ODataTable from '../../components/ODataTable'
import { createColumns, disabledColumns } from './config'
import { createNamespacedHelpers } from 'vuex'
import Detail from '../../views/sites/detail'
const { mapActions, mapState, mapMutations: mapAppMutations } = createNamespacedHelpers('app')
const { mapState: mapContentPartState, mapMutations } = createNamespacedHelpers('contentPart')
import { MUTATION_TYPE } from '@/store/modules/contentPart'
import { MUTATION_TYPES } from '@/store/mutations'
import { Site } from '@/models/site'
import { EXPORT_FORMAT } from '@/utils/exportFormat'

export default {
  name: 'Sites',
  components: {
    ODataTable,
    Detail
  },
  data() {
    return {
      exportFormats: [EXPORT_FORMAT.CSV]
    }
  },
  computed: {
    ...mapState({
      nextAvailableSiteId: state => state.nextAvailableSiteId,
      siteID: state => state.siteID
    }),
    ...mapContentPartState({
      sites: state => state.sites
    }),
    columns() {
      return createColumns(
        this.siteID
      )
    }
  },
  async mounted() {
    await this.fetchNextSiteId()
    this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
    try {
      await Promise.all([this.fetchDisabledCategories(), this.fetchDisabledMedias()])
    } finally {
      this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
    }
  },
  destroyed() {
    this.setSelectedSite('')
  },
  methods: {
    ...mapActions(['fetchNextAvailableSiteId', 'fetchDisabledCategories', 'fetchDisabledMedias']),
    ...mapMutations({
      setContentPart: MUTATION_TYPE.SET_CONTENT_PART
    }),
    ...mapAppMutations({
      setSelectedSite: MUTATION_TYPES.APP.SET_SELECTED_SITE
    }),
    handleRowInit(event) {
      event.data.SiteID = this.nextAvailableSiteId
      event.data.ID = this.nextAvailableSiteId
    },
    async handleRowInserted(event) {
      await this.fetchNextSiteId()
      if (event.data.IsActive) {
        const sites = [...this.sites]
        const insertedSite = new Site(event.data)
        sites.push(insertedSite)
        this.setContentPart({ sites })
      }
    },
    handleEditorPreparing(event) {
      if (event.isOnForm) {
        event.editorOptions.disabled = disabledColumns.includes(
          event.dataField
        )
      }
    },
    async fetchNextSiteId() {
      this?.$refs?.oDataTableComponentRef?.$refs?.oDataTableRef?.instance?.beginCustomLoading()
      try {
        await this.fetchNextAvailableSiteId()
      } finally {
        this?.$refs?.oDataTableComponentRef?.$refs?.oDataTableRef?.instance?.endCustomLoading()
      }
    },
    handleRowUpdating(event) {
      const sites = [...this.sites]
      const updatedSite = new Site({
        ...event.oldData,
        ...event.newData
      })
      const existingSiteIndex = sites.findIndex(site => site.ID === event.key)

      if (existingSiteIndex > -1) {
        sites[existingSiteIndex].updatePartial(updatedSite)
      }
      if (typeof event.newData.IsActive === 'boolean') {
        existingSiteIndex > -1 ? sites.splice(existingSiteIndex, 1) : sites.push(updatedSite)
      }

      this.setContentPart({ sites })
    },
    handleRowExpanded(event) {
      this.setSelectedSite(event.key)
    }
  }
}
</script>
