<template>
  <ODataTable
    ref="oDataTableComponentRef"
    :columns="columns.all"
    :add-edit-columns="columns.addEdit"
    resource="tDisabledCategoryAccount"
    :total-label="$t('disabledCategoryAccounts.total')"
    :file-name="$t('disabledCategoryAccounts.fileName')"
    :add-row-button-label="$t('disabledCategoryAccounts.add')"
    :title="$t('disabledCategoryAccounts.title')"
    @rowInit="handleRowInit"
    @editorPreparing="handleEditorPreparing"
  />
</template>

<script>
import ODataTable from '../../components/ODataTable'
import { createColumns, dateAddedField } from './config'

export default {
  name: 'DisabledCategoryAccount',
  components: {
    ODataTable
  },
  computed: {
    columns() {
      return createColumns()
    }
  },
  methods: {
    handleRowInit(event) {
      event.data.DateAdded = new Date().toISOString()
    },
    handleEditorPreparing(event) {
      if (event.isOnForm) {
        event.editorOptions.disabled = dateAddedField.includes(event.dataField)
      }
    }
  }
}
</script>
