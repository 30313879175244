var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.selectedAnswersToKnowledgeTest
        ? _c(
            "el-dialog",
            {
              attrs: { visible: _vm.showDetails },
              on: {
                "update:visible": function($event) {
                  _vm.showDetails = $event
                },
                close: _vm.handleDetailsDialogClose
              }
            },
            [
              _c("Detail", {
                attrs: {
                  detail: Object.assign({}, _vm.selectedAnswersToKnowledgeTest)
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("ODataTable", {
        key: _vm.questionType,
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns,
          "add-edit-columns": _vm.addEditColumns,
          resource: "tTestCustomerAnswerPortal",
          "total-label": _vm.$t("answersToKnowledgeTest.total"),
          "should-show-edit-button": false,
          "file-name": _vm.$t("answersToKnowledgeTest.fileName"),
          "should-show-add-button": false,
          "add-row-button-label": _vm.$t("answersToKnowledgeTest.add"),
          title: _vm.$t("answersToKnowledgeTest.title"),
          filter: _vm.filter
        },
        on: { rowInit: _vm.handleRowInit, rowUpdating: _vm.handleRowUpdating },
        scopedSlots: _vm._u([
          {
            key: "custom-buttons",
            fn: function() {
              return [
                _c("DxButton", {
                  attrs: { icon: "info", "on-click": _vm.handleViewClick }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }