export class Section {
  constructor({
    isActive = true,
    testId = '',
    sectionName = '',
    mediaId = '',
    id = ''
  } = {}) {
    this.isActive = isActive
    this.testId = testId
    this.mediaId = mediaId
    this.id = id
    this.sectionName = sectionName
  }
  static fromList(sections = []) {
    return sections.map(section => new Section(section))
  }

  updatePartial(updateParams) {
    Object.assign(this, updateParams)
  }

  static get filterColumns() {
    return ['ID', 'MediaID']
  }

  get payload() {
    return {
      tTestID: this.testId,
      MediaID: this.mediaId,
      IsActive: this.isActive,
      SectionName: this.sectionName
    }
  }
}
