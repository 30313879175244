export class Account {
  Account;

  Licenses;

  NoAccounts;

  SubAccount;

  hasNoParent;

  isCustomAccount;

  subAccounts;

  value;
  constructor(account = {}) {
    Object.assign(this, account)
  }

  get option() {
    return {
      title: this.displayName,
      subscribedContacts: this.NoAccounts || 0,
      licenses: this.Licenses || 0,
      value: this.optionValue
    }
  }

  get allSubAccounts() {
    return [
      ...this.subAccounts

        .map(subAccount => subAccount.SubAccount)

        .filter(subAccount => !!subAccount),
      ''
    ]
  }

  get optionValue() {
    if (this.isCustomAccount) {
      return this.value
    }

    if (this.hasNoParent) {
      return this.Account
    }

    return this.SubAccount || this.Account
  }

  get displayName() {
    return this.hasNoParent ? this.Account : this.SubAccount || this.Account
  }

  static trim(accounts = []) {
    return accounts.map(account => ({
      ...account,

      Account: account.Account?.trim(),

      SubAccount: account.SubAccount?.trim()
    }))
  }

  static filterParentAccounts(accounts = []) {
    return accounts.filter(account => !account.SubAccount)
  }

  static filterSubAccounts(accounts = []) {
    return accounts.filter(account => !!account.SubAccount)
  }

  static getSubAccountsWithNoParentAccounts({
    subAccounts = [],
    accounts = []
  }) {
    return subAccounts.filter(
      subAccount =>
        !accounts.find(account => account.Account === subAccount.Account)
    )
  }

  static filterSubAccountsForAccount({ subAccounts = [], account }) {
    return subAccounts.filter(
      subAccount => account.Account === subAccount.Account
    )
  }

  static getUniqueAccounts(accounts = []) {
    return accounts.reduce(
      (uniqueAccounts, account) => [
        ...uniqueAccounts,
        ...(uniqueAccounts.find(
          uniqueAccount => uniqueAccount.Account === account.Account
        )
          ? []
          : [account])
      ],
      []
    )
  }

  static getUniqueSubAccounts(subAccounts = []) {
    return subAccounts.reduce(
      (uniqueSubAccounts, subAccount) => [
        ...uniqueSubAccounts,
        ...(uniqueSubAccounts.find(
          uniqueSubAccount =>
            uniqueSubAccount.SubAccount === subAccount.SubAccount
        )
          ? []
          : [subAccount])
      ],
      []
    )
  }

  static groupAccountsAndSubAccounts(accountsAndSubAccounts = []) {
    const trimmedAccounts = this.trim(accountsAndSubAccounts)

    const accounts = this.filterParentAccounts(trimmedAccounts)

    const subAccounts = this.filterSubAccounts(trimmedAccounts)

    const subAccountsWithNoParentAccounts = this.getSubAccountsWithNoParentAccounts(
      { accounts, subAccounts }
    ).map(account => ({ ...account, hasNoParent: true }))

    const uniqueAccounts = this.getUniqueAccounts([
      ...accounts,
      ...subAccountsWithNoParentAccounts
    ])

    const accountsWithSubAccounts = uniqueAccounts.map(account => {
      const subAccountsForCurrentAccount = this.filterSubAccountsForAccount({
        subAccounts,
        account
      })

      const emptyNoAccounts = account.NoAccounts
      const totalSubscribedAccounts =
        emptyNoAccounts +
        subAccountsForCurrentAccount.reduce(
          (totalSubscribedAccounts, subAccountForCurrentAccount) => {
            return (
              totalSubscribedAccounts + subAccountForCurrentAccount.NoAccounts
            )
          },
          0
        )

      account.NoAccounts = totalSubscribedAccounts
      return {
        ...account,
        subAccounts: this.fromList(
          this.getUniqueSubAccounts([
            ...(subAccountsForCurrentAccount.length
              ? [
                {
                  SubAccount: 'All',
                  NoAccounts: totalSubscribedAccounts,

                  Licenses: account.Licenses,
                  isCustomAccount: true,
                  value: null
                },
                {
                  SubAccount: 'Empty (None)',
                  NoAccounts: emptyNoAccounts,
                  isCustomAccount: true,
                  value: ''
                }
              ]
              : []),
            ...subAccountsForCurrentAccount
          ])
        )
      }
    })

    return this.fromList(accountsWithSubAccounts)
  }

  static sort(accounts = []) {
    return accounts.sort((firstAccount, secondAccount) =>
      firstAccount.option.title.toLowerCase() >
      secondAccount.option.title.toLowerCase()
        ? 1
        : -1
    )
  }

  static fromList(accounts = []) {
    return this.sort(accounts.map(account => new Account(account)))
  }

  static isFullAccountMatch(subAccount) {
    return subAccount === null
  }
}
