var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    { staticClass: "container" },
    [
      _c("ODataTable", {
        key: _vm.filter,
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns,
          resource: "viewMediaReportDone",
          "total-label": _vm.$t("mediaReports.total"),
          "file-name": _vm.$t("mediaReports.fileName"),
          title: _vm.$t("mediaReports.title"),
          "should-show-add-button": false,
          "should-show-edit-button": false,
          "should-show-delete-button": false,
          "show-data": _vm.showData,
          "row-key": _vm.rowKey,
          filter: _vm.filter
        },
        on: { clearFilters: _vm.handleFiltersClear },
        scopedSlots: _vm._u([
          {
            key: "custom-filters",
            fn: function() {
              return [
                _c("media-reports-filters", {
                  ref: "mediaReportFiltersRef",
                  on: { filter: _vm.filterMediaReports }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }