var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ODataTable", {
    attrs: {
      columns: _vm.columns,
      "add-edit-columns": _vm.addEditColumns,
      resource: "CoWorkerCourseAssigned",
      "total-label": _vm.$t("assignedCoWorkersCourses.total"),
      "file-name": _vm.$t("assignedCoWorkersCourses.fileName"),
      "add-row-button-label": _vm.$t("assignedCoWorkersCourses.add"),
      title: _vm.$t("assignedCoWorkersCourses.title")
    },
    on: {
      rowInit: _vm.handleRowInit,
      editorPreparing: _vm.handleEditorPreparing
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }