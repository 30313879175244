import MenuService from '@/api/menus'
import i18n from '@/i18n'
import { User } from '@/models/user'
import { Message } from 'element-ui'
import { MUTATION_TYPES as BASE_MUTATION_TYPES } from '../mutations'

const MUTATION_TYPES = BASE_MUTATION_TYPES.ADMIN

const state = {
  admin: null,
  isLoading: false
}

const mutations = {
  [MUTATION_TYPES.SET_ADMIN]: (state, admin) => {
    state.admin = new User(admin)
  },

  [MUTATION_TYPES.RESET_ADMIN]: state => {
    state.admin = null
  },

  [MUTATION_TYPES.SET_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  }
}

const actions = {
  async setAdmin({ commit }, admin) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      const [menus, { value: disabledMenus }] = await Promise.all([
        MenuService.fetchByUserId(admin.ID),
        MenuService.fetchDisabledByUserId(admin.ID)
      ])
      commit(MUTATION_TYPES.SET_ADMIN, {
        ...admin,
        menus,
        disabledMenus
      })
      return Promise.resolve()
    } catch (error) {
      Message({
        message: error.message,
        type: 'error'
      })
      return Promise.reject()
    } finally {
      commit(MUTATION_TYPES.SET_LOADING, false)
    }
  },
  async savePermissions(
    { commit, state, rootState, dispatch },
    selectedIds = []
  ) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      const { disabled, enabled } = state.admin.createMenuParams(selectedIds)
      if (disabled.length || enabled.length) {
        await Promise.all([
          ...disabled.map(data => MenuService.disable(data)),

          ...enabled.map(id => MenuService.enable(id))
        ])
        if (state.admin.ID === rootState.user.user.ID) {
          await dispatch('user/getInfo', null, { root: true })
        }

        Message({
          message: i18n.t('admins.permissionSaved'),
          type: 'success'
        })
      }
      return Promise.resolve()
    } catch (error) {
      Message({
        message: error.message,
        type: 'error'
      })
      return Promise.reject()
    } finally {
      commit(MUTATION_TYPES.SET_LOADING, false)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
