var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { staticClass: "container" },
    [
      _c(
        "el-row",
        { attrs: { type: "flex", gutter: 10 } },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("mediaReports.dateRange") } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 6 } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      placeholder:
                        "" + _vm.$t("mediaReports.startDatePlaceholder"),
                      "picker-options": _vm.startDatePickerOptions,
                      format: _vm.filters.dateFormat
                    },
                    on: { change: _vm.handleStartDateChange },
                    model: {
                      value: _vm.startDate,
                      callback: function($$v) {
                        _vm.startDate = $$v
                      },
                      expression: "startDate"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 6 } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      placeholder:
                        "" + _vm.$t("mediaReports.endDatePlaceholder"),
                      "picker-options": _vm.endDatePickerOptions,
                      format: _vm.filters.dateFormat
                    },
                    on: { change: _vm.handleEndDateChange },
                    model: {
                      value: _vm.endDate,
                      callback: function($$v) {
                        _vm.endDate = $$v
                      },
                      expression: "endDate"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { type: "flex", gutter: 10 } },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("mediaReports.customer") } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 6 } },
                [
                  _c("el-row", [_vm._v(_vm._s(_vm.$t("selectAccount")))]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder:
                          _vm.$t("select") +
                          " " +
                          _vm.$t("manageUsers.selectType.account"),
                        loading: _vm.areAccountsLoading
                      },
                      model: {
                        value: _vm.filters.account,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "account", $$v)
                        },
                        expression: "filters.account"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("all"), value: "" }
                      }),
                      _vm._l(_vm.accounts, function(item) {
                        return _c(
                          "el-option",
                          {
                            key: item.option.value,
                            attrs: {
                              label: item.option.title,
                              value: item.option.value
                            }
                          },
                          [_vm._v(" " + _vm._s(item.option.title) + " ")]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 6 } },
                [
                  _c("el-row", [_vm._v(_vm._s(_vm.$t("selectSubAccount")))]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: _vm.$t("selectSubAccount"),
                        loading: _vm.areAccountsLoading,
                        disabled: _vm.isSubAccountTypeDisabled
                      },
                      model: {
                        value: _vm.filters.subAccount,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "subAccount", $$v)
                        },
                        expression: "filters.subAccount"
                      }
                    },
                    _vm._l(_vm.subAccounts, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.option.value,
                          attrs: {
                            label: item.option.title,
                            value: item.option.value
                          }
                        },
                        [_vm._v(" " + _vm._s(item.option.title) + " ")]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 6 } },
                [
                  _c("el-row", [_vm._v(_vm._s(_vm.$t("selectTag")))]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder:
                          _vm.$t("select") + " " + _vm.$t("tag.selectTag"),
                        loading: _vm.areUserTagsLoading
                      },
                      model: {
                        value: _vm.filters.tagId,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "tagId", $$v)
                        },
                        expression: "filters.tagId"
                      }
                    },
                    [
                      _vm.tags.length
                        ? _c("el-option", {
                            attrs: { label: _vm.$t("all"), value: "" }
                          })
                        : _vm._e(),
                      _vm._l(_vm.tags, function(item) {
                        return _c(
                          "el-option",
                          {
                            key: item.ID,
                            attrs: { label: item.TagName, value: item.ID }
                          },
                          [_vm._v(" " + _vm._s(item.TagName) + " ")]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { type: "flex", gutter: 10 } },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("mediaReports.educations") } },
            [
              _c("el-col", { attrs: { xs: 24, sm: 6 } }, [
                _c(
                  "div",
                  { staticClass: "info-icon-container" },
                  [
                    _c("el-row", [_vm._v(_vm._s(_vm.$t("selectSite")))]),
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          placeholder: _vm.$t("select") + " " + _vm.$t("site")
                        },
                        model: {
                          value: _vm.filters.siteId,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "siteId", $$v)
                          },
                          expression: "filters.siteId"
                        }
                      },
                      [
                        _vm.sites.length
                          ? _c("el-option", {
                              attrs: { label: _vm.$t("all"), value: "" }
                            })
                          : _vm._e(),
                        _vm._l(_vm.sites, function(site) {
                          return _c("el-option", {
                            key: site.value,
                            attrs: { label: site.label, value: site.value }
                          })
                        })
                      ],
                      2
                    ),
                    _c(
                      "i",
                      { staticClass: "el-icon-info info-icon" },
                      [
                        _c(
                          "DxTooltip",
                          {
                            attrs: {
                              "hide-on-outside-click": false,
                              target: ".el-icon-info.info-icon",
                              "show-event": "mouseenter",
                              "hide-event": "mouseleave"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("mediaReportsV2.siteInfo")) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 6 } },
                [
                  _c("el-row", [_vm._v(_vm._s(_vm.$t("selectCategory")))]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder:
                          _vm.$t("select") + " " + _vm.$t("category"),
                        loading: _vm.areCategoriesLoading
                      },
                      model: {
                        value: _vm.filters.categoryID,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "categoryID", $$v)
                        },
                        expression: "filters.categoryID"
                      }
                    },
                    [
                      _vm.categories.length
                        ? _c("el-option", {
                            attrs: { label: _vm.$t("all"), value: "" }
                          })
                        : _vm._e(),
                      _vm._l(_vm.categories, function(category) {
                        return _c("el-option", {
                          key: category.ID,
                          attrs: { label: category.Name, value: category.ID }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 6 } },
                [
                  _c("el-row", [_vm._v(_vm._s(_vm.$t("selectMedia")))]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: _vm.$t("select") + " " + _vm.$t("media"),
                        loading: _vm.areEpisodesLoading
                      },
                      model: {
                        value: _vm.filters.episodeID,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "episodeID", $$v)
                        },
                        expression: "filters.episodeID"
                      }
                    },
                    [
                      _vm.episodes.length
                        ? _c("el-option", {
                            attrs: { label: _vm.$t("all"), value: "" }
                          })
                        : _vm._e(),
                      _vm._l(_vm.episodes, function(episode) {
                        return _c("el-option", {
                          key: episode.ID,
                          attrs: { label: episode.Title, value: episode.ID }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                disabled: !_vm.filters.isFilterDisabled
              },
              on: { click: _vm.filterMediaReports }
            },
            [_vm._v(_vm._s(_vm.$t("mediaReports.filter")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }