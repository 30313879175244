<template>
  <div class="app-container py-none">
    <el-container>
      <el-main class="py-none">
        <el-form ref="formRef" :model="courseCampaign" label-position="top" size="small" :rules="rules">
          <el-row class="m-container">
            <el-col :xs="24" :sm="15" class="column">
              <el-row type="flex" class="center">
                <el-col :span="4" class="m-title">{{ $t("name") }} :</el-col>
                <el-col :span="20">
                  <el-form-item prop="name">
                    <el-row type="flex" class="center gap-md">
                      <el-col :span="10">
                        <el-select
                          v-model="customerAccount"
                          filterable
                          :placeholder="$t('courseCampaign.choosePrograms.accountPlaceholder')"
                          size="small"
                          @change="handleCourseCampaignName"
                        >
                          <el-option
                            v-for="item in customerAccounts"
                            :key="item.ID"
                            :label="item.Account"
                            :value="item.Account"
                          />
                        </el-select>
                      </el-col>
                      <el-col :span="10">
                        <el-input
                          v-model="campaignName"
                          :placeholder="$t('courseCampaign.choosePrograms.campaignPlaceholder')"
                          size="small"
                          @input="handleCourseCampaignName"
                        />
                      </el-col>
                      <el-col :span="4">
                        <span class="primary">{{
                          courseCampaign.formattedAddedDate
                        }}</span>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" class="center py-2">
                <el-col :span="4" class="m-title">{{ $t("customers.total") }} :</el-col>
                <el-col :span="20" class="primary">{{ total }}</el-col>
              </el-row>
              <el-row type="flex" class="center py-2">
                <el-col :span="4" class="m-title">{{ $t("courseCampaign.filters") }} :</el-col>
                <el-col :span="20" class="primary">{{
                  courseCampaign.filterUsed
                }}</el-col>
              </el-row>
            </el-col>
            <el-col :xs="24" class="column">
              <el-row type="flex" :gutter="20" class="center">
                <el-col :span="4">
                  <el-form-item
                    :label="$t('courseCampaign.portal')"
                    prop="siteId"
                  >
                    <el-select
                      v-model="courseCampaign.siteId"
                      :allow-create="false"
                      class="select-width"
                      :filterable="true"
                      :placeholder="$t('courseCampaign.portal')"
                    >
                      <el-option
                        v-for="site in sites"
                        :key="site.ID"
                        :label="site.Name"
                        :value="site.ID"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item
                    :label="$t('courseCampaign.program')"
                    prop="programId"
                  >
                    <el-select
                      v-model="courseCampaign.programId"
                      :allow-create="false"
                      class="select-width"
                      :filterable="true"
                      :placeholder="$t('courseCampaign.program')"
                      :loading="areProgramsLoading"
                    >
                      <el-option
                        v-for="program in programs"
                        :key="program.ID"
                        :label="program.Name"
                        :value="program.ID"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="16" class="flex-end">
                  <el-form-item
                    :label="$t('courseCampaign.deadline')"
                    prop="deadline"
                  >
                    <el-date-picker
                      v-model="courseCampaign.deadline"
                      type="date"
                      :placeholder="$t('courseCampaign.deadline')"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>

            <el-row type="flex" align="center" justify="space-between">
              <el-col :span="8">
                <el-form-item
                  :label="$t('courseCampaign.episodes')"
                  prop="episodes"
                >
                  <el-table
                    ref="episodeListRef"
                    v-loading="areEpisodesLoading"
                    :data="episodes"
                    class="episode m-border"
                    @select-all="handleSelectionChange"
                    @select="handleSelectionChange"
                  >
                    <el-table-column type="selection" width="55" />
                    <el-table-column
                      prop="Title"
                      :label="$t('courseCampaign.episodes')"
                    />
                  </el-table>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-table
                  ref="selectedEpisodeListRef"
                  :data="courseCampaign.episodes"
                  class="episode m-border"
                  @select-all="handleSelectedEpisodeChange"
                  @select="handleSelectedEpisodeChange"
                >
                  <el-table-column type="selection" width="55" />
                  <el-table-column
                    prop="Title"
                    :label="$t('courseCampaign.selectedEpisodes')"
                  />
                </el-table>
              </el-col>
            </el-row>
          </el-row>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import { MUTATION_TYPES } from '@/store/mutations'
import { createNamespacedHelpers } from 'vuex'
import { validators } from './config'
import CustomersAccountService from '@/api/customersAccount'
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(
  'courseCampaign'
)
const { mapState: mapContentPartState } = createNamespacedHelpers(
  'contentPart'
)

export default {
  name: 'ChoosePrograms',
  data() {
    return {
      rules: {
        programId: [
          { validator: validators.validateProgram, trigger: 'change' }
        ],
        siteId: [{ validator: validators.validateSite, trigger: 'change' }],
        deadline: [
          { validator: validators.validateDeadline, trigger: 'change' }
        ],
        episodes: [{ validator: validators.validateSelectedEpisodes }],
        name: [{ validator: validators.validateName, trigger: 'change' }]
      },
      customerAccounts: [],
      customerAccount: null,
      campaignName: ''
    }
  },
  computed: {
    ...mapState({
      courseCampaign: state => state.courseCampaign,
      total: state => state.selectedCustomers.total,
      programs: state => state.programs,
      episodes: state => state.episodes,
      areProgramsLoading: state => state.areProgramsLoading,
      areEpisodesLoading: state => state.areEpisodesLoading
    }),
    ...mapContentPartState({
      sites: state => state.sites
    })
  },
  watch: {
    'courseCampaign.siteId': {
      handler(siteId) {
        this.setSiteId(siteId)
      },
      deep: true
    },
    'courseCampaign.programId': {
      handler(programId) {
        this.setProgramId(programId)
      },
      deep: true
    }
  },
  mounted() {
    this.setSelectedRows()
  },
  updated() {
    this.setSelectedRows()
  },
  created() {
    this.handleFetchCustomerAccount()
  },
  methods: {
    ...mapMutations({
      setCourseCampaign: MUTATION_TYPES.COURSE_CAMPAIGN.SET_COURSE_CAMPAIGN
    }),
    ...mapActions(['setSiteId', 'setProgramId']),
    handleSelectedEpisodeChange(episodes) {
      this.setCourseCampaign({
        episodes
      })
      this.setSelectedRows()
    },
    handleSelectionChange(newEpisodes) {
      this.setCourseCampaign({
        episodes: this.courseCampaign.getRefreshedEpisodes(newEpisodes)
      })
    },
    setSelectedRows() {
      this.$refs.episodeListRef.clearSelection()
      const matchedEpisodes = this.episodes.filter(
        ({ ID }) =>
          !!this.courseCampaign.episodes.find(episode => episode.ID === ID)
      )
      matchedEpisodes.forEach(episode => {
        this.$refs.episodeListRef.toggleRowSelection(episode, true)
      })
      this.$refs.selectedEpisodeListRef.clearSelection()
      this.courseCampaign.episodes.forEach(episode => {
        this.$refs.selectedEpisodeListRef.toggleRowSelection(episode, true)
      })
    },
    handleCourseCampaignName() {
      this.courseCampaign.name = `${this.customerAccount} ${this.campaignName}`
    },
    async handleFetchCustomerAccount() {
      const customerAccount = await CustomersAccountService.getAllCustomerAccount()
      this.customerAccounts = customerAccount?.value
    }
  }
}
</script>

<style lang="scss" scoped>
.episode {
  width: 100%;
  max-height: 300px;
  overflow: auto;
}

.py-none {
  padding-top: 0%;
  padding-bottom: 0%;
}

.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.m-title {
  font-size: 14px;
  font-weight: 700;
  color: #606266;
}

.m-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.m-border {
  border: 1px solid #cfd0d1;
  border-radius: 5px;
}

</style>
