const pagesTableColumns = [
  {
    label: 'name',
    prop: 'PageName',
    width: 'auto',
    sortable: true,
    sortBy: 'PageName',
    align: ''
  },
  {
    label: 'status',
    prop: 'IsActive',
    width: 'auto',
    sortable: false,
    sortBy: '',
    align: 'center'
  },
  {
    label: 'contentPart.tag',
    prop: 'Tag',
    width: 'auto',
    sortable: true,
    sortBy: 'Tag',
    search: {
      propName: 'Tag',
      propValue: 'Tag'
    },
    align: 'center'
  },
  {
    label: 'contentPart.position',
    prop: 'Position',
    width: 'auto',
    sortable: true,
    sortBy: 'Position',
    search: {
      propName: 'Position',
      propValue: 'Position'
    },
    align: 'center'
  },
  {
    label: 'actions',
    prop: 'Edit',
    width: 'auto',
    sortable: false,
    sortBy: '',
    align: 'center'
  }
]

const PREVIEW_URL = process.env.VUE_APP_CONTENTPARTPAGE_PREVIEW_URL

export { pagesTableColumns, PREVIEW_URL }
