var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "container" },
    [
      _c(
        "el-container",
        { staticClass: "steps-container" },
        [
          _c("h1", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("manageDownloadables.title")))
          ]),
          _c(
            "el-breadcrumb",
            { attrs: { "separator-class": "el-icon-arrow-right" } },
            _vm._l(_vm.steps, function(step, index) {
              return _c(
                "el-breadcrumb-item",
                {
                  key: step.value,
                  class: { "selected-step": _vm.isActiveStep(step.value) }
                },
                [
                  _c(
                    "el-link",
                    {
                      attrs: {
                        underline: false,
                        disabled: _vm.lastTraversedStep < index
                      },
                      on: {
                        click: function($event) {
                          return _vm.navigateToStep(step.value)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(step.label) + " ")]
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-main",
        {
          class: { section: true, "generate-report": _vm.isGenerateReportStep }
        },
        [
          _c(
            "el-row",
            [
              _c("el-col"),
              _c(
                "el-col",
                [
                  _c("h2", { staticClass: "subtitle" }, [
                    _vm._v(_vm._s(_vm.$t(_vm.stepTitle)))
                  ]),
                  _c(_vm.activeStepComponent, {
                    tag: "component",
                    on: { onNextClick: _vm.handleNextClick }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }