<template>
  <div class="column-flex">
    <el-input
      v-model="pastedUsers"
      :rows="10"
      type="textarea"
      placeholder="Please input"
      @input="handleChange"
    />
    <el-button
      type="primary"
      class="margin-left-md self-start"
      :loading="isLoading"
      :disabled="isLoading"
      @click="handleImportClick"
    >{{
      $t(
        isDeactiveImportType
          ? "manageUsers.deactivateUser.stepLabel"
          : "manageUsers.importCsv.organizeUsers"
      )
    }}</el-button>
  </div>
</template>
<script>
import { ImportedUsers } from '@/models/importedUsers'
import { nextClickEvent } from './config'
import { createNamespacedHelpers } from 'vuex'
import { MUTATION_TYPES } from '@/store/mutations'
import { IMPORT_TYPE } from '@/store/modules/user/modules/importedUsers'
const { mapState, mapMutations } =
  createNamespacedHelpers('user/importedUsers')
export default {
  name: 'ImportUsers',
  data() {
    return {
      pastedUsers: ''
    }
  },
  computed: {
    ...mapState({
      isDeactiveImportType: (state) =>
        state.importType === IMPORT_TYPE.DEACTIVATE,
      isLoading: state => state.isLoading,
      users: state => state.importedUsers,
      editedUsersData: state => state.editedUsersData
    })
  },
  created() {
    this.users.setExtIdImportField(false)
  },
  methods: {
    ...mapMutations({
      setImportedUsers: MUTATION_TYPES.USER.IMPORTED_USERS.SET_IMPORTED_USERS,
      setTransformToHtmlState: MUTATION_TYPES.USER.IMPORTED_USERS.SET_TRANSFORM_TO_HTML_STATE,
      setEditedUsersData: MUTATION_TYPES.USER.IMPORTED_USERS.SET_EDITED_USERS_DATA
    }),
    handleImportClick() {
      this.$emit(nextClickEvent)
      this.setTransformToHtmlState(false)
    },
    handleChange() {
      this.setEditedUsersData(this.pastedUsers)
      if (!this.editedUsersData) {
        this.setImportedUsers(new ImportedUsers())
        return
      }
      const importedUsers = ImportedUsers.createFromFile(this.editedUsersData)
      this.setImportedUsers(importedUsers)
    }
  }
}
</script>

<style lang="scss" scoped>
$base-spacing: 5px;
::v-deep .table {
  border-collapse: collapse;
  width: 100%;
}
::v-deep .el-textarea__inner {
  width: 715px !important;
}

::v-deep .td,
::v-deep .th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: $base-spacing + 3;
}
.margin-left-md {
  margin: $base-spacing * 5;
}

.self-start {
  align-self: flex-start;
}

.column-flex {
  display: flex;
  flex-direction: column;
}
</style>
