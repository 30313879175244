export const MUTATION_TYPES = Object.freeze({
  USER: {
    RESET_STATE: 'RESET_STATE',
    SET_TOKEN: 'SET_TOKEN',
    SET_USER: 'SET_USER',
    SET_LOADING: 'SET_LOADING',
    ACCOUNTS: {
      SET_ACCOUNTS: 'SET_ACCOUNTS',
      SET_LOADING: 'SET_LOADING',
      SET_SELECTED_ACCOUNT: 'SET_SELECTED_ACCOUNT',
      SET_SELECTED_SUB_ACCOUNT: 'SET_SELECTED_SUB_ACCOUNT'
    },
    INVERTED_MEDIA_REPORTS: {
      SET_MEDIA_REPORTS: 'SET_MEDIA_REPORTS'
    },
    IMPORTED_USERS: {
      SET_IMPORTED_USERS: 'SET_IMPORTED_USERS',
      SET_TRANSFORM_TO_HTML_STATE: 'SET_TRANSFORM_TO_HTML_STATE',
      MATCH_COLUMN: 'MATCH_COLUMN',
      SET_COLUMNS: 'SET_COLUMNS',
      SET_INVALID_USERS: 'SET_INVALID_USERS',
      SET_EDIT_MODE: 'SET_EDIT_MODE',
      UPDATE_EMAIL: 'UPDATE_EMAIL',
      SET_LOADING: 'SET_LOADING',
      SET_ABSENT_USERS: 'SET_ABSENT_USERS',
      SET_REPORTS: 'SET_REPORTS',
      SET_IMPORT_TYPE: 'SET_IMPORT_TYPE',
      SET_EXISTING_USERS: 'SET_EXISTING_USERS',
      TOGGLE_NO_DEACTIVATION: 'TOGGLE_NO_DEACTIVATION',
      SET_EXTID_SELECTED: 'SET_EXTID_SELECTED',
      SET_EDITED_USERS_DATA: 'SET_EDITED_USERS_DATA'
    }
  },
  CUSTOMER: {
    SET_CUSTOMER: 'SET_CUSTOMER',
    RESET_CUSTOMER: 'RESET_CUSTOMER',
    SET_LOADING: 'SET_LOADING',
    SET_CUSTOMERS: 'SET_CUSTOMERS',
    SET_FILTER: 'SET_FILTER',
    SET_SELECTED_TAG: 'SET_SELECTED_TAG'
  },
  TAGS: {
    SET_TAGS: 'SET_TAGS',
    ADD_TAG: 'ADD_TAG',
    SET_LOADING: 'SET_LOADING'
  },
  DOWNLOADABLE: {
    SET_DOWNLOADABLE: 'SET_DOWNLOADABLE',
    CREATE_CUSTOM_FOLDER_NAME: 'CREATE_CUSTOM_FOLDER_NAME',
    SET_FOLDER_NAME_TO_OLD: 'SET_FOLDER_NAME_TO_OLD',
    DISABLE_FIELDS: 'DISABLE_FIELDS',
    RESET_DOWNLOADABLE: 'RESET_DOWNLOADABLE'
  },
  COURSE_CAMPAIGN: {
    SET_SELECTED_CUSTOMERS: 'SET_SELECTED_CUSTOMERS',
    SET_COURSE_CAMPAIGN: 'SET_COURSE_CAMPAIGN',
    SET_PROGRAMS_LOADING: 'SET_PROGRAMS_LOADING',
    SET_EPISODES_LOADING: 'SET_EPISODES_LOADING',
    SET_LOADING: 'SET_LOADING',
    SET_PROGRAMS: 'SET_PROGRAMS',
    SET_EPISODES: 'SET_EPISODES',
    SET_MISCELLANEOUS: 'SET_MISCELLANEOUS'
  },
  CAMPAIGN_DEFAULT: {
    SET_LOADING: 'SET_LOADING'
  },
  FILES: {
    SET_FILES: 'SET_FILES',
    SET_FILES_LOADING: 'SET_FILES_LOADING',
    SET_SHARED_FILES: 'SET_SHARED_FILES',
    FILTER_SHARED_FILES: 'FILTER_SHARED_FILES',
    ADD_SHARED_FILE: 'ADD_SHARED_FILE'
  },
  TEST: {
    SET_PROGRAMS_LOADING: 'SET_PROGRAMS_LOADING',
    SET_EPISODES_LOADING: 'SET_EPISODES_LOADING',
    SET_PROGRAMS: 'SET_PROGRAMS',
    SET_EPISODES: 'SET_EPISODES',
    SET_LOADING: 'SET_LOADING',
    SET_QUESTIONS: 'SET_QUESTIONS',
    SET_ANSWERS: 'SET_ANSWERS',
    SET_QUESTIONS_AND_ANSWERS_LOADING: 'SET_QUESTIONS_AND_ANSWERS_LOADING',
    SET_TEST_SECTIONS: 'SET_TEST_SECTIONS',
    SET_ACTIVATED_QUESTIONS_COUNT: 'SET_ACTIVATED_QUESTIONS_COUNT'
  },
  APP: {
    TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
    CLOSE_SIDEBAR: 'CLOSE_SIDEBAR',
    TOGGLE_DEVICE: 'TOGGLE_DEVICE',
    SET_SITEID: 'SET_SITEID',
    SET_MEDIAS: 'SET_MEDIAS',
    SET_CATEGORIES: 'SET_CATEGORIES',
    SET_TEST_SECTIONS: 'SET_TEST_SECTIONS',
    SET_NEXT_AVAILABLE_SITE_ID: 'SET_NEXT_AVAILABLE_SITE_ID',
    SET_MENUS: 'SET_MENUS',
    SET_SUBCATEGORIES: 'SET_SUBCATEGORIES',
    SET_MENUS_LOADING: 'SET_MENUS_LOADING',
    TOGGLE_MENU_SELECTION: 'TOGGLE_MENU_SELECTION',
    SET_SELECTED_SITE: 'SET_SELECTED_SITE',
    SET_DISABLED_MEDIAS: 'SET_DISABLED_MEDIAS',
    SET_ASSISTANT_ID: 'SET_ASSISTANT_ID',
    SET_SELECTED_CUSTOMERS: 'SET_SELECTED_CUSTOMERS',
    SET_LOCALE: 'SET_LOCALE',
    SET_UPDATED_SEND_LIST_ID: 'SET_UPDATED_SEND_LIST_ID',
    SET_UPDATED_SEND_LIST: 'SET_UPDATED_SEND_LIST',
    UPDATE_CUSTOMERS: 'UPDATE_CUSTOMERS'
  },
  ADMIN: {
    SET_LOADING: 'SET_LOADING',
    SET_ADMIN: 'SET_ADMIN',
    RESET_ADMIN: 'RESET_ADMIN'
  },
  CHATBOT: {
    PUSH_MESSAGE: 'PUSH_MESSAGE',
    CLEAR_MESSAGES: 'CLEAR_MESSAGES',
    SET_IS_LOADING: 'SET_IS_LOADING'
  },
  ASSISTANTS: {
    SET_IS_LOADING: 'SET_IS_LOADING',
    SET_ASSISTANTS: 'SET_ASSISTANTS',
    SET_ASSISTANT: 'SET_ASSISTANT',
    CREATE_ASSISTANT: 'CREATE_ASSISTANT',
    UPDATE_ASSISTANT: 'UPDATE_ASSISTANT',
    DELETE_ASSISTANT: 'DELETE_ASSISTANT',
    SET_UPLOADED_FILES: 'SET_UPLOADED_FILES',
    ADD_UPLOADED_FILE: 'ADD_UPLOADED_FILE',
    REMOVE_UPLOADED_FILE: 'REMOVE_UPLOADED_FILE'
  },
  ASSISTANT: {
    SET_IS_LOADING: 'SET_IS_LOADING',
    SET_ASSISTANT: 'SET_ASSISTANT',
    SET_FILES_TO_BE_DELETED: 'SET_FILES_TO_BE_DELETED',
    ADD_FILE_TO_BE_DELETED: 'ADD_FILE_TO_BE_DELETED'
  },
  CORS_TESTER: {
    SET_LOADING: 'SET_LOADING'
  },
  REFLECTION: {
    SET_REFLECTION_IDS: 'SET_REFLECTION_IDS',
    SET_CUSTOMER_IDS: 'SET_CUSTOMER_IDS',
    REMOVE_CUSTOMER_IDS: 'REMOVE_CUSTOMER_IDS'
  },
  MEDIA_REPORTS: {
    SET_CUSTOMER_ID: 'SET_CUSTOMER_ID',
    SET_TAGS: 'SET_TAGS',
    SET_LOADING: 'SET_LOADING'
  },
  IMPORTED_DOWNLOADBALES: {
    SET_DOWNLOADABLES: 'SET_DOWNLOADABLES',
    SET_HEADINGS: 'SET_HEADINGS',
    SET_TRANSFORM_TO_HTML_STATE: 'SET_TRANSFORM_TO_HTML_STATE',
    SET_ALL_DOWNLOADABLE_IDS: 'SET_ALL_DOWNLOADABLE_IDS'
  },
  SHARED: {
    SITE: {
      SET_SITES: 'SET_SITES'
    },
    CATEGORY: {
      SET_CATEGORIES: 'SET_CATEGORIES'
    },
    MEDIA: {
      SET_MEDIAS: 'SET_MEDIAS'
    }
  },
  MEDIA_REPORTS_V2: {
    SET_CUSTOMER_ID: 'SET_CUSTOMER_ID',
    SET_TAGS: 'SET_TAGS',
    SET_LOADING: 'SET_LOADING',
    INITIALISE: 'INITIALISE'
  }
})
