<template>
  <el-container>
    <el-main class="dx-viewport">
      <MasterDetail :data="detail" :items="reflectionData">
        <template #custom-item="{ item }">
          <div class="date-container">
            {{ formatDate(item.editorOptions.value) }}
          </div>
        </template>
      </MasterDetail>
    </el-main>
  </el-container>
</template>

<script>
import MasterDetail from '../../../components/masterDetail'
import { DateUtils } from '@/utils/date'
import { detailsColumnsName, REVIEW_REFLECTIONS } from '../config'
import { getFieldLabel } from '../../../utils/reflection'

export default {
  name: 'Detail',
  components: { MasterDetail },
  props: {
    detail: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      reflectionData: [
        ...detailsColumnsName
      ].map((dataField) => ({
        dataField,
        ...(Object.values(REVIEW_REFLECTIONS).includes(dataField) && {
          label: {
            text: this.$t(`reviewReflections.${getFieldLabel({ isApproved: this.detail.IsApproved, dataField })}`)
          }
        }),
        ...(dataField === REVIEW_REFLECTIONS.DATE_APPROVED && {
          template: 'custom-item'
        }),
        ...(dataField === REVIEW_REFLECTIONS.ADMIN_ID && {
          template: 'lookup-value',
          editorOptions: {
            dataSource: this.detail.selectedCustomer,
            displayExpr: 'Name',
            valueExpr: 'ID'
          }
        })
      }))
    }
  },
  methods: {
    formatDate(date) {
      return DateUtils.formatToShortDateTime(date)
    }
  }
}
</script>
