import { fectchResource } from '@/utils/httpRequests'

export class PaginatedResponse {
  items;

  nextLink;
  constructor({ value, '@odata.nextLink': nextLink = '' }) {
    this.items = value
    this.nextLink = nextLink
  }

  get hasMore() {
    return !!this.nextLink
  }

  async next() {
    const nextPage = await fectchResource(this.nextLink)

    this.nextLink = nextPage['@odata.nextLink']

    this.items = [...this.items, ...nextPage.value]
  }
}
