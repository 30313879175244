<template>
  <div class="tags">
    <el-tag
      v-if="filters.siteSearch"
      class="tag"
      closable
      type="info"
      size="small"
      @close="setFilters({ siteSearch: '' })"
    >
      Site: {{ siteName(filters.siteSearch) }}
    </el-tag>
    <el-tag
      v-if="filters.positionSearch"
      class="tag"
      closable
      type="info"
      size="small"
      @close="setFilters({ positionSearch: '' })"
    >
      Position: {{ filters.positionSearch }}
    </el-tag>
    <el-tag
      v-if="filters.tagSearch"
      class="tag"
      closable
      type="info"
      size="small"
      @close="setFilters({ tagSearch: '' })"
    >
      Tag: {{ filters.tagSearch }}
    </el-tag>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

const contentPartBuilderStore = 'contentPartBuilder'

export default {
  name: 'BuilderFilterTags',
  computed: {
    ...mapGetters(['siteName']),
    ...mapState(contentPartBuilderStore, ['filters'])
  },
  methods: {
    ...mapActions(contentPartBuilderStore, ['setFilters'])
  }
}
</script>

<style lang="scss" scoped>
$base-spacing: 5px;
.tags {
  margin: 0px $base-spacing;
}
.tag {
  margin: $base-spacing $base-spacing;
}
</style>
