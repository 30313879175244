import { fectchResource } from '@/utils/httpRequests'
import ArrayStore from 'devextreme/data/array_store'
import { Deferred } from 'devextreme/core/utils/deferred'

export default class NonODataStore extends ArrayStore {
  constructor({ resource, baseUrl = process.env.VUE_APP_CHAT_FORM_URL }) {
    super({
      key: 'id'
    })

    this.resource = resource
    this.baseUrl = baseUrl
  }
  load() {
    const promise = fectchResource(`${this.baseUrl}/${this.resource}`)
    return this.toPromise(promise)
  }

  toPromise(promise) {
    const deffered = Deferred()
    promise
      .then(item => deffered.resolve(item.data))
      .catch(error => {
        deffered.reject(error)
      })

    return deffered.promise()
  }
}
