import { AssistantMetaContent } from './assistantMetaContent'

export class AssistantMetaContents {
  constructor(metaContentMap = {}) {
    if (metaContentMap instanceof AssistantMetaContents) {
      this.assistantMetaContents = AssistantMetaContent.fromMap(
        metaContentMap.payload
      )
    } else {
      this.assistantMetaContents = AssistantMetaContent.fromMap(metaContentMap)
    }
  }

  add(metaContent) {
    this.assistantMetaContents.unshift(new AssistantMetaContent(metaContent))
    this.refresh()
  }

  update(index, metaContent) {
    this.assistantMetaContents[index - 1].updatePartial(metaContent)
  }

  remove(index) {
    this.assistantMetaContents = this.assistantMetaContents.filter(
      (_, currentIndex) => currentIndex !== index - 1
    )
    this.refresh()
  }

  refresh() {
    this.assistantMetaContents.forEach((metaContent, index) => {
      metaContent.updatePartial({ index: index + 1 })
    })
  }

  removeFirst() {
    this.assistantMetaContents.shift()
    this.refresh()
  }

  findByIndex(index) {
    return this.assistantMetaContents.find(
      (_, currentIndex) => currentIndex !== index - 1
    )
  }

  addAtIndex(index, metaContent) {
    this.assistantMetaContents.splice(index - 1, 0, metaContent)
  }

  get payload() {
    return this.assistantMetaContents.reduce(
      (metaContentMap, metaContent) => ({
        ...metaContentMap,
        ...metaContent.payload
      }),
      {}
    )
  }
}
