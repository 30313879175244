import store from '@/store'
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider'
import { Loading } from 'element-ui'
import { createFormData } from '../components/ContentPartDialogBox/config'
import { FOLDER } from '@/utils/filename'
import { File } from '@/models/file'
const type = FOLDER.SHARED
let isUploaded = false

async function handleDownloadClick(event) {
  const loadingInstance = Loading.service()
  try {
    const file = encodeURIComponent(event.name)
    const content = await store.dispatch('files/fetchSharedFileContent', {
      file,
      options: { responseType: 'blob' }
    })
    const blob = new Blob([content], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = event.name
    link.click()
    window.URL.revokeObjectURL(link.href)
  } catch (error) {
    loadingInstance.close()
    this.$message({
      type: 'error',

      message: error.message
    })
  } finally {
    loadingInstance.close()
  }
}

export class FileSystemProvider extends CustomFileSystemProvider {
  $message;
  constructor({ items }) {
    super({
      downloadItems: async items => {
        await Promise.all(items.map(handleDownloadClick))
      },
      getItems() {
        return items.map(item => item.asHierarchy)
      },
      async deleteItem(event) {
        const loadingInstance = Loading.service()
        try {
          const file = event.name
          await store.dispatch('files/removeSharedFile', file)
        } catch (error) {
          this.$message({
            type: 'error',

            message: error.message
          })
        } finally {
          loadingInstance.close()
        }
      },
      async uploadFileChunk(event) {
        if (!isUploaded) {
          const loadingInstance = Loading.service()
          isUploaded = true
          try {
            const data = createFormData(event)
            await store.dispatch('files/upload', {
              data,
              type,
              file: File.createFileParams({
                ...event,
                name: data.get('filename')
              })
            })
            await store.dispatch('files/fetchFiles', type)
          } catch (error) {
            this.$message({
              type: 'error',

              message: error.message
            })
          } finally {
            loadingInstance.close()
          }
        }
      }
    })
  }
}
