import PageService from '@/api/contentPartPages'
import { BUILDER_MODES } from '@/utils/constants'

import Hogan from 'hogan.js'

export const generateBlocksForCreate = blocks => {
  return blocks.map(el => ({
    ContentPartID: el.ID,
    ContentPartPageID: el.ContentPartPageID,
    SortOrder: el.SortOrder,
    IsActive: el?.IsActive || true,
    SiteID: el.SiteID
  }))
}

const generateBlocksForUpdate = (blocks, preview) => {
  const contentPartIDs = preview.map(el => el.ID)

  return blocks.map(el => {
    const index = contentPartIDs.indexOf(el.ContentPartID)
    contentPartIDs[index] = -1
    el.SortOrder = index + 1
    return el
  })
}

const diff = (previews, blocks) => {
  const previewsWithUpdatedSortOrder = previews.map((preview, index) => ({
    ...preview,
    SortOrder: preview.SortOrder || index
  }))

  const deleted = blocks.filter(block => block.deleted).map(el => el.ID)

  const added = previewsWithUpdatedSortOrder.filter(preview => preview.added)
  return { added, deleted }
}

const swapItems = (arr, i, j) => {
  arr[i] = arr.splice(j, 1, arr[i])[0]
}

export const MUTATION_TYPE = {
  SET_PREVIEW_ITEMS: 'SET_PREVIEW_ITEMS',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_CONFIG: 'SET_CONFIG',
  SET_BLOCKS: 'SET_BLOCKS',
  SET_BLOCKS_WITH_CONTENT: 'SET_BLOCKS_WITH_CONTENT',
  SET_FILTERS: 'SET_FILTERS'
}

const state = {
  previewItems: [],
  isLoading: true,
  config: {
    currentPage: 0,
    mode: BUILDER_MODES.CREATE,
    siteID: 0,
    isActive: false,
    currentPageName: ''
  },
  blocks: [],
  blocksWithContent: [],
  filters: {
    siteSearch: '',
    tagSearch: '',
    positionSearch: '',
    nameSearch: ''
  }
}

const mutations = {
  [MUTATION_TYPE.SET_PREVIEW_ITEMS]: (state, payload) => {
    Object.assign(state, { previewItems: payload })
  },

  [MUTATION_TYPE.SET_IS_LOADING]: (state, payload) => {
    Object.assign(state, { isLoading: payload })
  },

  [MUTATION_TYPE.SET_CONFIG]: (state, payload) => {
    Object.assign(state.config, payload)
  },

  [MUTATION_TYPE.SET_BLOCKS]: (state, payload) => {
    Object.assign(state, { blocks: payload })
  },

  [MUTATION_TYPE.SET_BLOCKS_WITH_CONTENT]: (state, payload) => {
    Object.assign(state, { blocksWithContent: payload })
  },

  [MUTATION_TYPE.SET_FILTERS]: (state, payload) => {
    Object.assign(state.filters, payload)
  }
}

const actions = {
  toggleIsLoading({ state, commit }) {
    commit(MUTATION_TYPE.SET_IS_LOADING, !state.isLoading)
  },
  async addPreviewItem({ state, commit, dispatch }, { itemIndex, data }) {
    const list = state.previewItems
    const page = data
    page.ContentPartPageID = state.config.currentPage
    if (page.PayloadData) {
      const { ID } = JSON.parse(page.PayloadData)
      page.MainContent = await dispatch('renderMediaTemplate', {
        media: ID,
        template: page.ContentPartTemplateID
      })
    }
    list.splice(itemIndex, 0, { ...page, added: true })
    commit(MUTATION_TYPE.SET_PREVIEW_ITEMS, list)
  },
  movePreviewItemUp({ state, commit }, index) {
    const list = state.previewItems
    if (index >= 1) swapItems(list, index, index - 1)
    commit(MUTATION_TYPE.SET_PREVIEW_ITEMS, list)
  },
  movePreviewItemDown({ state, commit }, index) {
    const list = state.previewItems
    if (list.length > 1 && index < list.length - 1) {
      swapItems(list, index, index + 1)
    }
    commit(MUTATION_TYPE.SET_PREVIEW_ITEMS, list)
  },
  deletePreviewItem({ state, commit }, index) {
    const list = state.previewItems
    const blocks = state.blocks

    const previewItemToBeDeleted = list[index]

    if (!previewItemToBeDeleted.added) {
      const nonMatchedBlocks = blocks.filter(
        block =>
          block.deleted || block.ContentPartID !== previewItemToBeDeleted.ID
      )
      const matchedBlocks = blocks
        .filter(
          block =>
            block.ContentPartID === previewItemToBeDeleted.ID && !block.deleted
        )

        .map((block, index) => (!index ? { ...block, deleted: true } : block))
      commit(MUTATION_TYPE.SET_BLOCKS, [...nonMatchedBlocks, ...matchedBlocks])
    }

    list.splice(index, 1)
    commit(MUTATION_TYPE.SET_PREVIEW_ITEMS, list)
  },
  updatePreviewItems({ state, commit }, payload) {
    const list = state.previewItems.map(el => {
      if (el.ID === payload.ID) {
        return payload
      } else {
        return el
      }
    })
    commit(MUTATION_TYPE.SET_PREVIEW_ITEMS, list)
  },
  clearPreviewItems({ commit }) {
    commit(MUTATION_TYPE.SET_PREVIEW_ITEMS, [])
  },
  setConfig({ commit }, payload) {
    commit(MUTATION_TYPE.SET_CONFIG, payload)
  },
  async fetchBlockContent({ commit, state }) {
    try {
      const blocks = await PageService.fetchBlockContentsForPage(
        state.config.currentPage
      )

      const sortedBlocks = blocks?.sort(
        (a, b) => a.PageSortOrder - b.PageSortOrder
      )
      commit(MUTATION_TYPE.SET_BLOCKS_WITH_CONTENT, sortedBlocks)
      commit(MUTATION_TYPE.SET_PREVIEW_ITEMS, [...sortedBlocks])
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async fetchBlocks({ commit, state }) {
    try {
      const blocks = await PageService.fetchBlocksForPage(
        state.config.currentPage
      )
      commit(MUTATION_TYPE.SET_BLOCKS, blocks)
      return Promise.resolve(blocks)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async saveBlocks({ state, dispatch }) {
    const preview = state.previewItems
    const blocks = state.blocks

    const { added, deleted } = diff(preview, blocks)
    try {
      if (added.length) {
        await PageService.createBlocks(generateBlocksForCreate(added))
      }

      await Promise.all(deleted.map(ID => PageService.deleteBlock(ID)))

      await dispatch('fetchBlocks')
      if (state.blocks.length) {
        await PageService.updateBlocks(
          generateBlocksForUpdate(state.blocks, preview)
        )
      }
      await dispatch('fetchBlockContent')
    } catch (error) {
      return Promise.reject(error)
    }
  },
  resetPreview({ commit, state }) {
    const preview = state.blocksWithContent
    commit(MUTATION_TYPE.SET_PREVIEW_ITEMS, [...preview])
  },
  setFilters({ commit }, payload) {
    commit(MUTATION_TYPE.SET_FILTERS, payload)
  },
  renderMediaTemplate({ rootState, rootGetters }, payload) {
    const { contentPart } = rootState

    const data = contentPart.medias.find(el => el.ID === payload.media)
    const template = Hogan.compile(
      rootGetters.contentPartTemplate(payload.template).TemplateHTML
    )
    return template.render(data)
  }
}

const getters = {
  isPreviewChanged: state => {
    const preview = state.previewItems
    const blocks = state.blocksWithContent
    return (
      preview.length !== blocks.length ||
      preview.some((value, index) => value.ID !== blocks[index].ID)
    )
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
