import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import i18n from '@/i18n'

const SUCCESS_STATUS = 200
const ERROR_STATUS = 400
const TIMEOUT = 5000

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60 * TIMEOUT // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (config.reqType === 'brevo') {
      config.headers['api-key'] = process.env.VUE_APP_BREVO_API_KEY
    } else if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = config.headers['Authorization']
        ? config.headers['Authorization']
        : `Bearer ${getToken()}`
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    /**
     * For now only checking for HTTP Response Codes as
     * I dont know about the API error response.
     */
    if (response.status === SUCCESS_STATUS && !res.message) {
      return res
    }

    if (response.status === SUCCESS_STATUS && res.message) {
      Message({
        message: res.message || i18n.t('errors.requestError'),
        type: 'error',
        duration: TIMEOUT
      })
      return Promise.reject(
        new Error(res.message || i18n.t('errors.requestError'))
      )
    }

    if (response.status > ERROR_STATUS) {
      Message({
        message: res.message || i18n.t('errors.requestError'),
        type: 'error',
        duration: TIMEOUT
      })

      return Promise.reject(new Error(i18n.t('errors.requestError')))
    }
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export const request = service
