export const createColumns = ({ menus }) => {
  const lookUpField = ['ParentAdminMenuID']
  const toggleColumns = ['IsHidden']
  const addEdit = [
    ...lookUpField,
    'Name',
    'Icon',
    ...toggleColumns,
    'IsActive',
    'URL',
    'SortOrder',
    'NeedSiteID',
    'InfoText'
  ].map(field => ({
    field,
    width: '100%',
    ...(lookUpField.includes(field) && { lookup: menus }),
    ...(toggleColumns.includes(field) && { dataType: 'boolean' })
  }))

  return {
    addEdit,
    all: [
      {
        field: 'ID',
        width: '100%'
      },
      ...addEdit
    ]
  }
}
