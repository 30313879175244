var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ODataTable", {
        key: _vm.selectedSendList,
        ref: "sendListRef",
        attrs: {
          "row-key": "id",
          columns: _vm.columns.all,
          "add-edit-columns": _vm.columns.addEdit,
          "should-show-delete-button": false,
          "is-custom-data-source": true,
          "custom-store-base-url": _vm.customStoreBaseUrl,
          "dynamic-resource-expression": "ContactsFromList",
          resource: _vm.resource,
          "lookup-filter": false,
          title: _vm.$t("contact.title"),
          "total-label": _vm.$t("contact.total"),
          "file-name": _vm.$t("contact.fileName"),
          "should-show-add-button": false,
          "should-show-edit-button": false
        },
        scopedSlots: _vm._u([
          {
            key: "custom-toolbar-before",
            fn: function() {
              return [
                _c(
                  "el-select",
                  {
                    staticClass: "send-list",
                    attrs: {
                      filterable: "",
                      loading: !_vm.sendLists.length,
                      placeholder: "Select Send List",
                      size: "large"
                    },
                    model: {
                      value: _vm.selectedSendList,
                      callback: function($$v) {
                        _vm.selectedSendList = $$v
                      },
                      expression: "selectedSendList"
                    }
                  },
                  _vm._l(_vm.sendLists, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    })
                  }),
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }