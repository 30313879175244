import ContentPartService from '@/api/contentPart'
import { MUTATION_TYPES as BASE_MUTATION_TYPES } from '@/store/mutations'
import { Message } from 'element-ui'
import { Category } from '@/models/category'

const MUTATION_TYPES = BASE_MUTATION_TYPES.SHARED.CATEGORY

const initialState = () => ({
  categories: []
})

const mutations = {
  [MUTATION_TYPES.SET_CATEGORIES]: (state, categories) => {
    state.categories = categories
  }
}

const actions = {
  async fetchCategoriesBySite({ commit, rootState }) {
    try {
      const categories = await ContentPartService.fetchCategories(
        rootState.mediaReportsV2.filters.siteId
      )
      commit(
        MUTATION_TYPES.SET_CATEGORIES,
        categories.map(el => new Category(el.Category))
      )
      return Promise.resolve()
    } catch (error) {
      Message({
        type: 'error',
        message: error.message
      })
      return Promise.reject()
    }
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions
}
