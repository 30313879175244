var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c(
        "el-main",
        { staticClass: "dx-viewport" },
        [
          _vm.detail
            ? _c(
                "DxTabPanel",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "profile-tab",
                        fn: function() {
                          return [
                            _c("MasterDetail", {
                              attrs: {
                                data: _vm.detail,
                                items: _vm.profileItems
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "custom-item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "date-container" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.formatDate(
                                                    item.editorOptions.value
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                557199529
                              )
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "address-tab",
                        fn: function() {
                          return [
                            _c("MasterDetail", {
                              attrs: {
                                data: _vm.detail,
                                items: _vm.addressItems
                              }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "tags-tab",
                        fn: function() {
                          return [
                            _c("MasterDetail", {
                              attrs: { data: _vm.detail, items: _vm.tagItems },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "custom-item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "tag-container" },
                                          _vm._l(
                                            item.editorOptions.value,
                                            function(tag, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass: "tag"
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(tag) + " "
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2988839174
                              )
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1355459211
                  )
                },
                [
                  _c("DxItem", {
                    attrs: { title: _vm.$t("profile"), template: "profile-tab" }
                  }),
                  _c("DxItem", {
                    attrs: {
                      title: _vm.$t("organisation"),
                      template: "address-tab"
                    }
                  }),
                  _c("DxItem", {
                    attrs: { title: _vm.$t("tags.title"), template: "tags-tab" }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }