var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ODataTable", {
    ref: "oDataTableComponentRef",
    attrs: {
      columns: _vm.columns.all,
      "add-edit-columns": _vm.columns.addEdit,
      resource: "CampaignDefaults",
      "action-buttons-width": 90,
      "total-label": _vm.$t("campaignDefaults.total"),
      "file-name": _vm.$t("campaignDefaults.fileName"),
      "add-row-button-label": _vm.$t("campaignDefaults.add"),
      title: _vm.$t("campaignDefaults.title")
    },
    on: {
      rowInit: _vm.handleRowInit,
      editorPreparing: _vm.handleEditorPreparing
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }