<template>
  <div>

    <DxPopup
      title="Popup Title"
      :hide-on-outside-click="true"
      :visible="isAddMediaDialogOpen"
    >
      <el-form class="popupAlignment" label-width="80px">
        <div class="manageContent">
          <div>
            <label>{{ $t('AuthorsByMedia.category') }}</label>
            <DxSelectBox
              :search-enabled="true"
              :data-source="columns.dropDownColumns[0].lookup"
              :display-expr="columns.dropDownColumns[0].displayExpr"
              :value-expr="columns.dropDownColumns[0].valueExpr"
              :value="selectedCategory"
              :on-value-changed="handleCategoryChange"
            />
          </div>

          <div class="mediaInput">
            <label>{{ $t('AuthorsByMedia.media') }}</label>
            <DxTagBox
              :multiline="false"
              :data-source="columns.dropDownColumns[1].lookup"
              :value="selectedEpisodes"
              :value-expr="columns.dropDownColumns[1].valueExpr"
              :display-expr="columns.dropDownColumns[1].displayExpr"
              :search-enabled="true"
              :show-selection-controls="true"
              :on-value-changed="onEpisodeValueChange"
            >
              <DxValidator>
                <DxRequiredRule :message="$t('AuthorsByMedia.selectMediaWarning')" />
              </DxValidator>
            </DxTagBox>
          </div>

          <div>
            <label>{{ $t('AuthorsByMedia.site') }}</label>
            <DxSelectBox
              :search-enabled="true"
              :data-source="columns.dropDownColumns[2].lookup"
              :display-expr="columns.dropDownColumns[2].displayExpr"
              :value-expr="columns.dropDownColumns[2].valueExpr"
              :value="selectedSite"
              :on-value-changed="handleSiteChange"
            >
              <DxValidator>
                <DxRequiredRule :message="$t('AuthorsByMedia.selectSiteWarning')" />
              </DxValidator>
            </DxSelectBox>
          </div>
        </div>

        <div class="alignButton">
          <el-button type="primary" :loading="onSaveLoading" @click="handlerChatQuestionMedia">Save</el-button>
          <el-button @click="handleAddMediaDialogClose">Cancel</el-button>
        </div>
      </el-form>
    </DxPopup>

    <ODataTable
      ref="oDataTableComponentRef"
      :columns="columns.all"
      :add-edit-columns="columns.addEdit"
      :filter="filter"
      resource="AuthorsByMedia"
      :action-buttons-width="90"
      :total-label="$t('AuthorsByMedia.total')"
      :file-name="$t('AuthorsByMedia.fileName')"
      :add-row-button-label="$t('AuthorsByMedia.add')"
      :title="$t('AuthorsByMedia.title')"
      :should-show-add-button="false"
      :should-show-edit-button="false"
      @selectionChange="handleSelectionChange"
    >
      <template #custom-toolbar-before>
        <div>
          <el-button :type="defaultType" plain @click="handleAddMediaDialogOpen">{{ $t('AuthorsByMedia.addMedia') }}</el-button>
        </div>
      </template>
      <template #custom-toolbar-after>
        <div>
          <el-button
            v-if="batchDeleteEpisodeIds.length"
            type="danger"
            plain
            @click="handleBatchDelete"
          >
            {{ $t('AuthorsByMedia.batchDelete') }}
          </el-button></div>
      </template>
    </ODataTable>
  </div>
</template>

<script>
import mixin from './mixin'
export default {
  mixins: [mixin]
}
</script>

<style scoped>
 .popupAlignment{
  width: 100%;
  height: 100%;
}
.manageContent{
  height: 90%;
}
.mediaInput{
  margin: 20px 0;
}
.alignButton{
  padding: 10px 5px;
  display: flex;
  justify-content: flex-end;

}
</style>
