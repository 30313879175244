<template>
  <div class="app-container">
    <el-container>
      <el-header height="auto">
        <el-row type="flex">
          <el-col class="left-align">
            <span class="heading">{{ $t('pages') }}</span>
          </el-col>
        </el-row>
      </el-header>
      <el-main class="table-container">
        <el-table
          v-loading="isPagesTableLoading"
          :element-loading-text="$t('loading')"
          :data="filteredPages"
          fit
          height="75vh"
          :highlight-current-row="false"
        >
          <el-table-column
            v-for="column in pagesTableColumns"
            :key="column.prop"
            :prop="column.prop"
            :label="$t(column.label)"
            :width="column.width"
            :sortable="column.sortable"
            :sort-by="column.sortBy"
            :align="column.align"
          >
            <el-table-column
              :width="column.width"
            >
              <!-- eslint-disable -->
              <template slot="header" slot-scope="scope" >
                <!-- eslint-enable -->
                <el-input
                  v-if="column.prop === COLUMN_WITH_FILTER.PAGENAME"
                  v-model="nameSearch"
                  class="search-box"
                  size="mini"
                  :placeholder="$t('search')"
                  clearable
                />
                <content-part-select
                  v-if="column.prop === COLUMN_WITH_FILTER.TAG"
                  :placeholder="`${$t('search')} ${$t(column.label)}`"
                  :options="tags"
                  show-all
                  :selected.sync="tagSearch"
                  :prop-name="column.search.propName"
                  :prop-value="column.search.propValue"
                  size="mini"
                />
                <content-part-select
                  v-if="column.prop === COLUMN_WITH_FILTER.POSITION"
                  :placeholder="`${$t('search')} ${$t(column.label)}`"
                  :options="positions"
                  show-all
                  :selected.sync="positionSearch"
                  :prop-name="column.search.propName"
                  :prop-value="column.search.propValue"
                  size="mini"
                />
                <content-part-select
                  v-if="column.prop === COLUMN_WITH_FILTER.SITE"
                  :placeholder="`${$t('search')} ${$t(column.label)}`"
                  :options="contentPartSites"
                  show-all
                  :selected.sync="siteSearch"
                  :prop-name="column.search.propName"
                  :prop-value="column.search.propValue"
                  size="mini"
                />
              </template>
              <template slot-scope="scope">
                <div v-if="column.prop === ISACTIVE_COLUMN" class="center-align">
                  <el-button v-if="!scope.row[column.prop]" class="badge" type="warning" round>{{ $t('draft') }}</el-button>
                  <el-button v-else class="badge" type="success" round>{{ $t('active') }}</el-button>
                </div>
                <div v-else-if="column.prop === EDIT_COLUMN" class="center-align">
                  <a
                    class="el-icon-view el-button el-button--text text-success"
                    :href="`${PREVIEW_URL}${scope.row.ID}/${siteID}`"
                    target="_blank"
                  />
                  <el-button
                    type="text"
                    icon="el-icon-edit-outline"
                    @click="openEditor(scope.row)"
                  />
                  <delete-row-button
                    :selected-row="scope.row.ID"
                    delete-action="contentPartPages/deletePage"
                  />
                </div>
                <div v-else>
                  {{ scope.row[column.prop] }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer height="5vh" class="center-align">
        <el-row type="flex" class="row-bg">
          <el-col class="left-align">
            <el-button
              class="el-button el-button--mini"
              icon="el-icon-plus"
              @click="openDialog"
            />
            <el-button
              :disabled="isPagesTableLoading"
              :loading="isPagesTableLoading"
              size="mini"
              icon="el-icon-refresh"
              @click="refreshTable"
            />
          </el-col>
          <el-col>
            <el-select v-model="dataCount" size="mini" class="count-select-width">
              <el-option
                v-for="value in dataCountValues"
                :key="value"
                :label="value"
                :value="value"
              />
            </el-select>
          </el-col>
          <el-col>
            <el-pagination
              class="right-align"
              :page-size="dataCount"
              :total="totalItems"
              :page-count="pagerCount"
              layout="total,prev,pager,next"
              :current-page.sync="currentPage"
              @current-change="handleCurrentPageChange"
            />
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
    <page-dialog-box :visible.sync="dialogVisible" :item="selectedPage" :dialog-type="dialogType" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import DeleteRowButton from '@/components/DeleteRowButton'
import PageDialogBox from '@/components/PageDialogBox'
import ContentPartSelect from '@/components/ContentPartSelect'
import { pagesTableColumns, PREVIEW_URL } from './config'

const contentPartStore = 'contentPart'
const contentPartPagesStore = 'contentPartPages'
const appStore = 'app'
const EDIT_COLUMN = 'Edit'
const ISACTIVE_COLUMN = 'IsActive'
const COLUMN_WITH_FILTER = {
  TAG: 'Tag',
  POSITION: 'Position',
  PAGENAME: 'PageName'
}
const DIALOG_TYPES = {
  EDIT: 'edit',
  CREATE: 'create'
}

export default {
  name: 'ContentPartPages',
  components: {
    DeleteRowButton,
    PageDialogBox,
    ContentPartSelect
  },
  data() {
    return {
      pagesTableColumns,
      dataCountValues: [5, 10, 25, 50, 100],
      dataCount: 10,
      totalItems: 0,
      currentPage: 1,
      pagerCount: 6,
      nameSearch: '',
      ISACTIVE_COLUMN,
      EDIT_COLUMN,
      dialogVisible: false,
      selectedPage: {},
      dialogType: DIALOG_TYPES.CREATE,
      tagSearch: '',
      positionSearch: '',
      COLUMN_WITH_FILTER,
      PREVIEW_URL
    }
  },
  computed: {
    ...mapGetters([
      'siteName',
      'contentPartPages'
    ]),
    ...mapState(contentPartPagesStore, ['isPagesTableLoading']),
    ...mapState(contentPartStore, ['tags', 'positions']),
    ...mapState(appStore, ['siteID']),
    filteredPages() {
      const filters = [
        {
          name: 'PageName',
          isText: true,
          value: this.nameSearch
        },
        {
          name: 'Position',
          isText: false,
          value: this.positionSearch
        },
        {
          name: 'Tag',
          isText: false,
          value: this.tagSearch
        }
      ]
      let list = this.contentPartPages(filters).map(this.addSiteName)
      this.updatePagination(list.length)
      const startIndex = this.dataCount * (this.currentPage - 1)
      const endIndex = startIndex + this.dataCount
      list = list.slice(startIndex, endIndex)
      return list
    }
  },
  created() {
    this.fetchContentPartPages()
  },
  methods: {
    ...mapActions(contentPartStore, ['setSites']),
    ...mapActions(contentPartPagesStore, ['fetchPages', 'toggleIsPagesTableLoading']),
    async fetchContentPartPages() {
      if (!this.contentPartPages([]).length) {
        await this.setSites()
        await this.fetchPages()
      } else if (this.isPagesTableLoading) {
        this.toggleIsPagesTableLoading()
      }
    },
    handleCurrentPageChange(val) {
      this.currentPage = val
    },
    updatePagination(val) {
      this.totalItems = val
    },
    async refreshTable() {
      this.toggleIsPagesTableLoading()
      await this.fetchPages()
    },
    addSiteName(el) {
      el.siteName = this.siteName(el.SiteID)
      return el
    },
    openDialog() {
      this.dialogType = DIALOG_TYPES.CREATE
      this.dialogVisible = true
    },
    openEditor(page) {
      this.selectedPage = Object.assign({}, page)
      this.dialogType = DIALOG_TYPES.EDIT
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
$base-spacing: 5px;
$success: #67c23a;

.heading {
  font-weight: bold;
  font-size: 1.3rem;
}
.table-container {
  padding: $base-spacing*2 $base-spacing*4;

  ::v-deep .el-table--border, .el-table--group {
    border: none;
  }

  ::v-deep .el-table--border::after, .el-table--group::after {
    width: 0px
  }

  ::v-deep .el-table thead.is-group th {
    background: white;
  }

  ::v-deep .el-table--border th, .el-table__fixed-right-patch {
    border-right: none;
  }

  ::v-deep .el-table--border td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: none;
  }
}
.center-align {
  text-align: center;
}
.right-align {
  display: flex;
  justify-content: flex-end;
}
.left-align {
  display: flex;
  justify-content: flex-start;
}
.count-select-width {
  width: 5rem;
}
.search-box {
  width: 100%
}
.badge {
  padding: 3px 6px;
}
.text-success {
  color: $success
}
@media screen and (min-width: 540px) {
  .search-box {
    width: 100%;
  }
}

</style>
