<template>
  <el-dialog
    fullscreen
    :visible.sync="dialogVisible"
    @open="handleOpen"
    @close="handleClose"
  >
    <el-container>
      <el-header>
        <el-row type="flex" :gutter="15" class="text-center">
          <el-col>
            <el-radio-group
              v-model="tabs"
              size="mini"
              :disabled="preview.isPreviewDialogLoading"
              @change="handleFrameSizeChange"
            >
              <el-radio-button label="Desktop" />
              <el-radio-button label="Tablet" />
              <el-radio-button label="Mobile" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <el-row class="text-center">
          <el-col v-loading="preview.isPreviewDialogLoading">
            <iframe
              :src="src"
              :width="width"
              frameborder="1"
            />
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

const contentPart = 'contentPart/'
const TAB_VALUES = {
  DESKTOP: 'Desktop',
  TAB: 'Tablet',
  MOBILE: 'Mobile'
}
const FRAME_SIZES = {
  [TAB_VALUES.DESKTOP]: '100%',
  [TAB_VALUES.TAB]: '60%',
  [TAB_VALUES.MOBILE]: '25%'
}

export default {
  name: 'TemplatePreview',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      tabs: TAB_VALUES.DESKTOP,
      width: FRAME_SIZES[TAB_VALUES.DESKTOP],
      src: ''
    }
  },
  computed: {
    ...mapState({ preview: state => state.contentPart.preview }),
    dialogVisible: {
      get: function() {
        return this.visible
      },
      set: function(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    ...mapActions([
      `${contentPart}toggleIsPreviewDialogLoading`,
      `${contentPart}previewContentPart`
    ]),
    handleFrameSizeChange(event) {
      this.width = FRAME_SIZES[event]
    },
    async handleOpen() {
      const payload = {
        html: this.row.MainContent,
        SiteID: this.row.SiteID
      }
      await this[`${contentPart}previewContentPart`](payload)
      this.src = this['preview'].src
    },
    handleClose() {
      this.dialogVisible = false
      this.src = ''
      this.tabs = TAB_VALUES.DESKTOP
      this.width = FRAME_SIZES[TAB_VALUES.DESKTOP]
      this[`${contentPart}toggleIsPreviewDialogLoading`]()
    }
  }
}
</script>

<style scoped>
.text-center {
  text-align: center;
}
iframe {
  border: 1px solid #dcdfe6;
  height: 75vh;
}
</style>
