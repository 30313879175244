<template>
  <div>
    <el-row :gutter="15">
      <el-col :xs="24" :sm="12" :md="8" :lg="8">
        <el-form-item
          :label="$t('contentPart.template')"
          prop="ContentPartTemplateID"
        >
          <el-select
            ref="selectBoxRef"
            v-model="contentPart.ContentPartTemplateID"
            class="select-width"
            size="small"
            @change="checkContentOverride"
          >
            <el-option-group
              v-for="(templates, name) in templatesByTemplateType"
              :key="name"
              :label="name"
            >
              <el-option
                v-for="template in templates"
                :key="template.ID"
                :label="template.Name"
                :value="template.ID"
              />
            </el-option-group>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="8">
        <el-form-item :label="$t('name')" prop="Name">
          <el-input v-model="contentPart.Name" :placeholder="$t('name')" />
        </el-form-item>
      </el-col>

      <el-col :xs="24" :sm="12" :md="8" :lg="8">
        <el-form-item :label="$t('site')" prop="SiteId">
          <el-select
            ref="selectBoxRef"
            v-model="contentPart.SiteID"
            :allow-create="false"
            class="select-width"
            size="small"
            :filterable="true"
          >
            <el-option
              v-for="site in sites"
              :key="site.ID"
              :label="site.Name"
              :value="site.ID"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="15">
      <el-col :xs="24" :sm="12" :md="8" :lg="8">
        <el-form-item :label="$t('contentPart.position')" prop="Position">
          <content-part-select
            :placeholder="`${$t('select')} ${$t('contentPart.position')}`"
            :selected.sync="contentPart.Position"
            :options="positions"
            :allow-create="true"
            prop-value="Position"
            prop-name="Position"
            class="select-width"
            size="small"
          />
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="8">
        <el-form-item :label="$t('contentPart.tag')" prop="Tag">
          <content-part-select
            :placeholder="`${$t('select')} ${$t('contentPart.tag')}`"
            :selected.sync="contentPart.Tag"
            :options="tags"
            :allow-create="true"
            prop-value="Tag"
            prop-name="Tag"
            class="select-width"
            size="small"
          />
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="8">
        <el-form-item :label="$t('contentPart.priority')">
          <el-input
            v-model="contentPart.SortOrder"
            type="Number"
            :placeholder="$t('contentPart.priority')"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="15">
      <el-col
        v-if="dialogBoxConfig.showCategory"
        :xs="24"
        :sm="12"
        :md="8"
        :lg="8"
      >
        <el-form-item :label="$t('category')">
          <el-select
            v-model="selectedCategory"
            :loading="dialogBoxConfig.isCategoryLoading"
            :placeholder="`${$t('select')} ${$t('category')}`"
            filterable
            size="small"
            class="select-width"
            @change="handleCategoryChange"
          >
            <el-option
              v-for="category in categories"
              :key="category.ID"
              :label="category.Name"
              :value="category.ID"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col
        v-if="dialogBoxConfig.showMedia"
        :xs="24"
        :sm="12"
        :md="8"
        :lg="8"
      >
        <el-form-item :label="$t('media')">
          <el-select
            v-model="selectedMedia"
            :loading="dialogBoxConfig.isMediaLoading"
            :placeholder="`${$t('select')} ${$t('media')}`"
            filterable
            size="small"
            class="select-width"
            @change="handleMediaChange"
          >
            <el-option
              v-for="media in medias"
              :key="media.ID"
              :label="media.Title"
              :value="media.ID"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import Hogan from 'hogan.js'
import ContentPartSelect from '@/components/ContentPartSelect'

const contentPartStore = 'contentPart'
import {
  CONTENT_PART_MODEL,
  SELECT_MEDIA_MESSAGE,
  TEMPLATE_TYPES
} from './config'

export default {
  name: 'ContentPartForm',
  components: {
    ContentPartSelect
  },
  props: {
    contentPart: CONTENT_PART_MODEL
  },
  data() {
    return {
      selectedMedia: '',
      selectedCategory: '',
      TEMPLATE_TYPES
    }
  },
  computed: {
    ...mapGetters(['contentPartTemplates', 'contentPartTemplate']),
    ...mapState(contentPartStore, [
      'tags',
      'positions',
      'medias',
      'categories',
      'dialogBoxConfig',
      'sites'
    ]),
    templatesByTemplateType() {
      const list = {
        [TEMPLATE_TYPES.TEXT]: [],
        [TEMPLATE_TYPES.HTML]: [],
        [TEMPLATE_TYPES.MEDIA]: []
      }
      this.contentPartTemplates.forEach((el) => {
        if (el.TemplateType === TEMPLATE_TYPES.HTML) list.HTML.push(el)
        else if (el.TemplateType === TEMPLATE_TYPES.MEDIA) list.MEDIA.push(el)
        else list.TEXT.push(el)
      })
      return list
    }
  },
  methods: {
    ...mapActions(contentPartStore, [
      'setCategories',
      'setMedia',
      'setDialogBoxConfig'
    ]),
    async checkContentOverride(event) {
      try {
        const currentTemplate = this.contentPartTemplate(event)
        if (this.contentPart.MainContent && currentTemplate) {
          await this.$confirm(
            this.$t('contentPart.mainContentOverrideConfirmMessage'),
            this.$t('contentPart.mainContentOverrideConfirmTitle'),
            {
              confirmButtonText: this.$t('ok'),
              cancelButtonText: this.$t('cancel'),
              type: 'warning'
            }
          )
        }
        await this.updateTemplate(currentTemplate)
        this.$refs.selectBoxRef.blur()
      } catch (error) {
        this.$refs.selectBoxRef.blur()
        return Promise.resolve()
      }
    },
    async updateTemplate(currentTemplate) {
      if (currentTemplate) {
        if (currentTemplate.TemplateType === TEMPLATE_TYPES.MEDIA) {
          this.contentPart.MainContent = SELECT_MEDIA_MESSAGE
          this.setDialogBoxConfig({
            showCategory: true,
            disableEditor: true
          })
          if (!this.categories.length) {
            await this.setCategories()
          }
          this.setDialogBoxConfig({
            isCategoryLoading: false,
            isMediaLoading: true
          })
        } else {
          this.setDialogBoxConfig({
            showCategory: false,
            showMedia: false,
            disableEditor: false,
            isCategoryLoading: true,
            isMediaLoading: true
          })
          this.contentPart.MainContent = currentTemplate.TemplateHTML
          this.contentPart.PayloadData = null
        }
      }
    },
    async handleCategoryChange(event) {
      this.setDialogBoxConfig({ showMedia: true })
      this.contentPart.MainContent = SELECT_MEDIA_MESSAGE
      this.selectedMedia = ''
      await this.setMedia(event)
    },
    async handleMediaChange(event) {
      const data = this.medias.find((el) => el.ID === event)
      const template = Hogan.compile(
        this.contentPartTemplate(this.contentPart.ContentPartTemplateID)
          .TemplateHTML
      )
      this.contentPart.MainContent = template.render(data)
      this.contentPart.PayloadData = JSON.stringify({
        Collection: TEMPLATE_TYPES.MEDIA,
        ID: this.selectedMedia
      })
    }
  }
}
</script>
