import { createResource } from '@/utils/httpRequests'

export default class MetaContentService {
  static async create(data) {
    return await createResource({
      url: `${process.env.VUE_APP_ODATA_API}/MetaContent`,
      data
    })
  }
}
