<template>
  <ODataTable
    ref="oDataTableComponentRef"
    :columns="columns"
    :is-static-data-source="true"
    :static-data="mediaReports"
    :total-label="$t('mediaReports.total')"
    :file-name="$t('invertedMediaReports.fileName')"
    :title="$t('invertedMediaReports.title')"
    :should-show-add-button="false"
    :should-show-edit-button="false"
    :should-show-delete-button="false"
  >
    <template #custom-filters>
      <inverted-media-reports-filters @filter="filter" />
    </template>
  </ODataTable>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'

const {
  mapState: mapInvertedMediaReportsState,
  mapActions: mapInvertedMediaReportsActions
} = createNamespacedHelpers('user/invertedMediaReports')

import ODataTable from '@/components/ODataTable'
import InvertedMediaReportsFilters from './filters.vue'

import { columns } from './config'

export default {
  name: 'InvertedMediaReports',
  components: {
    ODataTable,
    InvertedMediaReportsFilters
  },
  data() {
    return {
      columns
    }
  },

  computed: {
    ...mapInvertedMediaReportsState({
      mediaReports: (state) => state.mediaReports
    })
  },

  methods: {
    ...mapInvertedMediaReportsActions(['fetchInvertedMediaReports']),
    async filter(filterParams) {
      this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
      try {
        await this.fetchInvertedMediaReports(filterParams)
      } finally {
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
      }
    }
  }
}
</script>
