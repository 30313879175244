var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            { attrs: { height: "auto" } },
            [
              _c(
                "el-row",
                { attrs: { type: "flex" } },
                [
                  _c("el-col", { staticClass: "left-align" }, [
                    _c("span", { staticClass: "heading" }, [
                      _vm._v(_vm._s(_vm.$t("pages")))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-main",
            { staticClass: "table-container" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.isPagesTableLoading,
                      expression: "isPagesTableLoading"
                    }
                  ],
                  attrs: {
                    "element-loading-text": _vm.$t("loading"),
                    data: _vm.filteredPages,
                    fit: "",
                    height: "75vh",
                    "highlight-current-row": false
                  }
                },
                _vm._l(_vm.pagesTableColumns, function(column) {
                  return _c(
                    "el-table-column",
                    {
                      key: column.prop,
                      attrs: {
                        prop: column.prop,
                        label: _vm.$t(column.label),
                        width: column.width,
                        sortable: column.sortable,
                        "sort-by": column.sortBy,
                        align: column.align
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { width: column.width },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function(scope) {
                                return [
                                  column.prop ===
                                  _vm.COLUMN_WITH_FILTER.PAGENAME
                                    ? _c("el-input", {
                                        staticClass: "search-box",
                                        attrs: {
                                          size: "mini",
                                          placeholder: _vm.$t("search"),
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.nameSearch,
                                          callback: function($$v) {
                                            _vm.nameSearch = $$v
                                          },
                                          expression: "nameSearch"
                                        }
                                      })
                                    : _vm._e(),
                                  column.prop === _vm.COLUMN_WITH_FILTER.TAG
                                    ? _c("content-part-select", {
                                        attrs: {
                                          placeholder:
                                            _vm.$t("search") +
                                            " " +
                                            _vm.$t(column.label),
                                          options: _vm.tags,
                                          "show-all": "",
                                          selected: _vm.tagSearch,
                                          "prop-name": column.search.propName,
                                          "prop-value": column.search.propValue,
                                          size: "mini"
                                        },
                                        on: {
                                          "update:selected": function($event) {
                                            _vm.tagSearch = $event
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  column.prop ===
                                  _vm.COLUMN_WITH_FILTER.POSITION
                                    ? _c("content-part-select", {
                                        attrs: {
                                          placeholder:
                                            _vm.$t("search") +
                                            " " +
                                            _vm.$t(column.label),
                                          options: _vm.positions,
                                          "show-all": "",
                                          selected: _vm.positionSearch,
                                          "prop-name": column.search.propName,
                                          "prop-value": column.search.propValue,
                                          size: "mini"
                                        },
                                        on: {
                                          "update:selected": function($event) {
                                            _vm.positionSearch = $event
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  column.prop === _vm.COLUMN_WITH_FILTER.SITE
                                    ? _c("content-part-select", {
                                        attrs: {
                                          placeholder:
                                            _vm.$t("search") +
                                            " " +
                                            _vm.$t(column.label),
                                          options: _vm.contentPartSites,
                                          "show-all": "",
                                          selected: _vm.siteSearch,
                                          "prop-name": column.search.propName,
                                          "prop-value": column.search.propValue,
                                          size: "mini"
                                        },
                                        on: {
                                          "update:selected": function($event) {
                                            _vm.siteSearch = $event
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  column.prop === _vm.ISACTIVE_COLUMN
                                    ? _c(
                                        "div",
                                        { staticClass: "center-align" },
                                        [
                                          !scope.row[column.prop]
                                            ? _c(
                                                "el-button",
                                                {
                                                  staticClass: "badge",
                                                  attrs: {
                                                    type: "warning",
                                                    round: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("draft"))
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "el-button",
                                                {
                                                  staticClass: "badge",
                                                  attrs: {
                                                    type: "success",
                                                    round: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("active"))
                                                  )
                                                ]
                                              )
                                        ],
                                        1
                                      )
                                    : column.prop === _vm.EDIT_COLUMN
                                    ? _c(
                                        "div",
                                        { staticClass: "center-align" },
                                        [
                                          _c("a", {
                                            staticClass:
                                              "el-icon-view el-button el-button--text text-success",
                                            attrs: {
                                              href:
                                                "" +
                                                _vm.PREVIEW_URL +
                                                scope.row.ID +
                                                "/" +
                                                _vm.siteID,
                                              target: "_blank"
                                            }
                                          }),
                                          _c("el-button", {
                                            attrs: {
                                              type: "text",
                                              icon: "el-icon-edit-outline"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openEditor(scope.row)
                                              }
                                            }
                                          }),
                                          _c("delete-row-button", {
                                            attrs: {
                                              "selected-row": scope.row.ID,
                                              "delete-action":
                                                "contentPartPages/deletePage"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row[column.prop]) +
                                            " "
                                        )
                                      ])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-footer",
            { staticClass: "center-align", attrs: { height: "5vh" } },
            [
              _c(
                "el-row",
                { staticClass: "row-bg", attrs: { type: "flex" } },
                [
                  _c(
                    "el-col",
                    { staticClass: "left-align" },
                    [
                      _c("el-button", {
                        staticClass: "el-button el-button--mini",
                        attrs: { icon: "el-icon-plus" },
                        on: { click: _vm.openDialog }
                      }),
                      _c("el-button", {
                        attrs: {
                          disabled: _vm.isPagesTableLoading,
                          loading: _vm.isPagesTableLoading,
                          size: "mini",
                          icon: "el-icon-refresh"
                        },
                        on: { click: _vm.refreshTable }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "count-select-width",
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.dataCount,
                            callback: function($$v) {
                              _vm.dataCount = $$v
                            },
                            expression: "dataCount"
                          }
                        },
                        _vm._l(_vm.dataCountValues, function(value) {
                          return _c("el-option", {
                            key: value,
                            attrs: { label: value, value: value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c("el-pagination", {
                        staticClass: "right-align",
                        attrs: {
                          "page-size": _vm.dataCount,
                          total: _vm.totalItems,
                          "page-count": _vm.pagerCount,
                          layout: "total,prev,pager,next",
                          "current-page": _vm.currentPage
                        },
                        on: {
                          "update:currentPage": function($event) {
                            _vm.currentPage = $event
                          },
                          "update:current-page": function($event) {
                            _vm.currentPage = $event
                          },
                          "current-change": _vm.handleCurrentPageChange
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("page-dialog-box", {
        attrs: {
          visible: _vm.dialogVisible,
          item: _vm.selectedPage,
          "dialog-type": _vm.dialogType
        },
        on: {
          "update:visible": function($event) {
            _vm.dialogVisible = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }