import PageService from '@/api/contentPartPages'
import { mergeLists } from '@/utils/list'
import { MUTATION_TYPE as CONTENT_PART_MUTATION_TYPE } from './contentPart'
const MUTATION_TYPE = {
  SET_PAGES: 'SET_PAGES',
  SET_IS_PAGES_TABLE_LOADING: 'SET_IS_PAGES_TABLE_LOADING',
  SET_IS_PAGE_DIALOG_LOADING: 'SET_IS_PAGE_DIALOG_LOADING'
}

const state = {
  pages: [],
  isPagesTableLoading: true,
  isPageDialogLoading: true
}

const mutations = {
  [MUTATION_TYPE.SET_PAGES]: (state, payload) => {
    Object.assign(state, { pages: payload })
  },

  [MUTATION_TYPE.SET_IS_PAGES_TABLE_LOADING]: (state, payload) => {
    Object.assign(state, { isPagesTableLoading: payload })
  },

  [MUTATION_TYPE.SET_IS_PAGE_DIALOG_LOADING]: (state, payload) => {
    Object.assign(state, { isPageDialogLoading: payload })
  }
}

const actions = {
  async fetchPages({ commit, rootState, dispatch }) {
    try {
      const SiteID = rootState.app.siteID
      if (SiteID === -1) return Promise.resolve()
      commit(MUTATION_TYPE.SET_IS_PAGES_TABLE_LOADING, true)
      const pages = await PageService.fetchPages({
        SiteID,
        Tag: null,
        Name: null,
        Position: null
      })
      dispatch('setPages', pages)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  setPages({ rootState, commit }, pages) {
    const positions = mergeLists(
      rootState.contentPart.positions,
      pages,
      'Position'
    )
    const tags = mergeLists(rootState.contentPart.tags, pages, 'Tag')
    commit(
      `contentPart/${CONTENT_PART_MUTATION_TYPE.SET_CONTENT_PART}`,
      { positions, tags },
      { root: true }
    )
    commit(MUTATION_TYPE.SET_PAGES, pages)
    commit(MUTATION_TYPE.SET_IS_PAGES_TABLE_LOADING, false)
  },
  toggleIsPagesTableLoading({ commit, state }) {
    commit(
      MUTATION_TYPE.SET_IS_PAGES_TABLE_LOADING,
      !state.isPagesTableLoading
    )
  },
  toggleIsPageDialogLoading({ commit, state }) {
    commit(
      MUTATION_TYPE.SET_IS_PAGE_DIALOG_LOADING,
      !state.isPageDialogLoading
    )
  },
  async deletePage({ dispatch, state }, contentPartPageID) {
    try {
      dispatch('toggleIsPagesTableLoading')
      await PageService.deletePage(contentPartPageID)
      const updatedPages = state.pages.filter(
        page => page.ID !== contentPartPageID
      )
      dispatch('setPages', updatedPages)
      return Promise.resolve()
    } catch (error) {
      dispatch('toggleIsPagesTableLoading')
      return Promise.reject(error)
    }
  },
  async createPage({ dispatch, state }, payload) {
    try {
      const currentPageID = await PageService.createPage(payload)
      dispatch('toggleIsPagesTableLoading')
      const updatedPages = [...state.pages, { ID: currentPageID, ...payload }]
      dispatch('setPages', updatedPages)

      const currentPage = state.pages.find(el => el.ID === currentPageID)
      return Promise.resolve(currentPage)
    } catch (error) {
      dispatch('toggleIsPagesTableLoading')
      return Promise.reject(error)
    }
  },
  async updatePage({ dispatch, state }, payload) {
    try {
      await PageService.updatePage(payload)
      dispatch('toggleIsPagesTableLoading')

      const updatedPages = state.pages.map(page =>
        page.ID === payload.ID ? payload : page
      )
      dispatch('setPages', updatedPages)
      return Promise.resolve()
    } catch (error) {
      dispatch('toggleIsPagesTableLoading')
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
