import {
  createResource,
  deleteResource,
  fectchResource,
  patchResource
} from '@/utils/httpRequests'

export default class ChatQuestionByMediaService {
  static getAll() {
    return fectchResource(
      `${process.env.VUE_APP_ODATA_API}/ChatQuestionByMedia`
    )
  }

  static create({ mediaId, id }) {
    return createResource({
      url: `${process.env.VUE_APP_ODATA_API}/ChatQuestionByMedia`,
      data: {
        ChatQuestionID: id,
        MediaID: mediaId
      }
    })
  }

  static getById({ id }) {
    return fectchResource(
      `${process.env.VUE_APP_ODATA_API}/ChatQuestionByMedia?$filter=ChatQuestionID eq ${id}`
    )
  }

  static delete(id) {
    return deleteResource(
      `${process.env.VUE_APP_ODATA_API}/ChatQuestionByMedia(${id})`
    )
  }

  static update({ mediaId, id }) {
    return patchResource({
      url: `${process.env.VUE_APP_ODATA_API}/ChatQuestionByMedia(${id})`,
      data: {
        MediaID: mediaId || null
      }
    })
  }
}
