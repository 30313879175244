import i18n from '@/i18n'

export const STEP = Object.freeze({
  CHOOSE_PROGRAMS: 'choosePrograms',
  ASSIGNED_PROGRAMS: 'assignedPrograms',
  SPECIFY_DETAILS: 'specifyDetails',
  OVERVIEW: 'overview'
})

const validateName = (rule, value, callback) => {
  if (!value) {
    callback(new Error(i18n.t('errors.validateName')))
  } else {
    callback()
  }
}

const validateSelectedEpisodes = (rule, value, callback) => {
  if (!value.length) {
    callback(new Error(i18n.t('errors.selectEpisode')))
  } else {
    callback()
  }
}

const validateSelect = ({ rule, value, callback, type }) => {
  if (!value) {
    callback(new Error(i18n.t(`errors.select${type}`)))
  } else {
    callback()
  }
}

const validateField = ({ rule, value, callback, type }) => {
  if (!value) {
    callback(new Error(i18n.t(`errors.fill${type}`)))
  } else {
    callback()
  }
}

export const validators = {
  validateSite: (rule, value, callback) =>
    validateSelect({ rule, value, callback, type: 'Site' }),

  validateProgram: (rule, value, callback) =>
    validateSelect({ rule, value, callback, type: 'Program' }),

  validateDeadline: (rule, value, callback) =>
    validateSelect({ rule, value, callback, type: 'Deadline' }),

  validateSubject: (rule, value, callback) =>
    validateField({ rule, value, callback, type: 'Subject' }),

  validateTemplate: (rule, value, callback) =>
    validateSelect({ rule, value, callback, type: 'Template' }),

  validateSendList: (rule, value, callback) =>
    validateSelect({ rule, value, callback, type: 'SendList' }),

  validateName,
  validateSenderEmail: (rule, value, callback) =>
    validateField({ rule, value, callback, type: 'Email' }),
  validateSenderName: (rule, value, callback) =>
    validateField({ rule, value, callback, type: 'Name' }),
  validateSelectedEpisodes
}
