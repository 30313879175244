import FileService from '@/api/file'
import { File } from '@/models/file'
import { MUTATION_TYPES as BASE_MUTATION_TYPES } from '../mutations'

const MUTATION_TYPES = BASE_MUTATION_TYPES.FILES

const state = {
  files: [],
  isLoading: false,
  sharedFiles: []
}

const mutations = {
  [MUTATION_TYPES.SET_FILES]: (state, files) => {
    state.files = files
    state.isLoading = false
  },

  [MUTATION_TYPES.SET_FILES_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  },

  [MUTATION_TYPES.SET_SHARED_FILES]: (state, files) => {
    state.sharedFiles = files
  },

  [MUTATION_TYPES.FILTER_SHARED_FILES]: (state, file) => {
    state.sharedFiles = state.sharedFiles.filter(
      sharedFile => sharedFile.Fullname !== file
    )
  },

  [MUTATION_TYPES.ADD_SHARED_FILE]: (state, file) => {
    state.sharedFiles = [new File(file), ...state.sharedFiles]
  }
}

const actions = {
  async fetchFiles({ commit }, type = '') {
    commit(MUTATION_TYPES.SET_FILES_LOADING, true)
    try {
      const files = await FileService.getAll(type)
      !type
        ? commit(MUTATION_TYPES.SET_FILES, File.fromList(files))
        : commit(MUTATION_TYPES.SET_SHARED_FILES, File.fromList(files))
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPES.SET_FILES_LOADING, false)
      return Promise.reject(error)
    }
  },

  async fetchFileContent(_, { file, options = {}}) {
    try {
      const content = await FileService.getContent(file, options)
      return Promise.resolve(content)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async fetchSharedFileContent(_, { file, options = {}}) {
    try {
      const content = await FileService.getSharedFileContent(file, options)
      return Promise.resolve(content)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async removeSharedFile({ commit }, file) {
    try {
      await FileService.removeFile(file)
      commit(MUTATION_TYPES.FILTER_SHARED_FILES, file)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async upload({ commit }, { data, type, file }) {
    try {
      await FileService.upload({ formdata: data, type })
      commit(MUTATION_TYPES.ADD_SHARED_FILE, file)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
