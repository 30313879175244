import { request } from '@/utils/request'
import { HTTP_METHOD } from '@/utils/constants'

const fectchResource = (url, options = {}) =>
  request({ url, method: HTTP_METHOD.GET, ...options })

const checkResource = ({ url, method, data, headers }) =>
  request({ url, method, data, headers })

const createResource = ({ url, data, reqType = null }) =>
  request({
    url,
    method: HTTP_METHOD.POST,
    data,
    reqType
  })

const patchResource = ({ url, data }) =>
  request({
    url,
    method: HTTP_METHOD.PATCH,
    data
  })

const updateResource = ({ url, data }) =>
  request({
    url,
    method: HTTP_METHOD.PUT,
    data
  })

const deleteResource = url =>
  request({
    url,
    method: HTTP_METHOD.DELETE
  })

export {
  fectchResource,
  createResource,
  patchResource,
  updateResource,
  deleteResource,
  checkResource
}
