<template>
  <div class="app-container">
    <el-row>
      <el-col :span="14" class="padding-x-lg">
        <h1 class="title">{{ $t("courseCampaign.create.title") }}</h1>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      class="padding-x-lg"
      justify="space-between"
      align="center"
    >
      <el-col>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            v-for="(step, index) in steps"
            :key="step.value"
            :class="{ 'selected-step': isActiveStep(step.value) }"
          >
            <el-link
              :underline="false"
              :disabled="lastTraversedStep < index"
              @click="navigateToStep(step.value)"
            >
              {{ step.label }}
            </el-link>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="p-3 d-flex flex-end">
          <el-button
            type="primary"
            class="justify-center"
            :loading="isLoading"
            @click="handleNextClick"
          >
            {{ $t(`courseCampaign.${activeStep}.buttonLabel`) }}
          </el-button>
          <el-button
            v-if="activeStep === 'choosePrograms' || activeStep === 'overview'"
            type="primary"
            class="justify-center"
            @click="handlePrevClick"
          >
            {{ $t(`courseCampaign.${activeStep}.buttonLabel2`) }}
          </el-button>
        </div>
      </el-col>
    </el-row>
    <component :is="activeStepComponent" ref="formRef" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('courseCampaign')
import { STEP } from './config'
import ChoosePrograms from './choosePrograms.vue'
import SpecifyDetails from './specifyDetails.vue'
import AssignedPrograms from './assignedPrograms.vue'
import { ROUTE_PATH } from '@/router'
import OverviewAndSend from './overviewAndSend.vue'

export default {
  name: 'CourseCampaign',
  components: {
    ChoosePrograms,
    AssignedPrograms,
    OverviewAndSend
  },
  data() {
    return {
      percentage: 0,
      activeStep: STEP.CHOOSE_PROGRAMS,
      lastTraversedStep: 0,
      steps: Object.values(STEP).map(value => ({
        label: this.$t(`courseCampaign.${value}.stepLabel`),
        value
      })),
      stepComponent: {
        [STEP.CHOOSE_PROGRAMS]: ChoosePrograms,
        [STEP.ASSIGNED_PROGRAMS]: AssignedPrograms,
        [STEP.SPECIFY_DETAILS]: SpecifyDetails,
        [STEP.OVERVIEW]: OverviewAndSend
      }
    }
  },
  computed: {
    activeStepComponent() {
      return this.stepComponent[this.activeStep]
    },
    currentSelectedStepIndex() {
      return this.steps.findIndex(step => step.value === this.activeStep)
    },
    ...mapState({
      selectedCustomers: state => state.selectedCustomers.Ids,
      isLoading: state => state.isLoading
    }),
    showProgressBar() {
      return this.activeStep === STEP.CHOOSE_PROGRAMS
    }
  },
  created() {
    if (!this.selectedCustomers.length) {
      this.$router.push(ROUTE_PATH.CUSTOMERS)
    }
  },
  mounted() {
    this.$refs.formRef = this.$refs.formRef.$refs.formRef
  },
  updated() {
    this.$refs.formRef = this.$refs.formRef?.$refs?.formRef
  },
  methods: {
    ...mapActions(['createCourseCampaign', 'assignEpisodes']),
    isActiveStep(activeStep) {
      return this.activeStep === activeStep
    },
    handleEpisodeAssignProgressChange(percentage) {
      this.percentage = percentage
    },
    goToNextStep() {
      this.activeStep = this.steps[this.currentSelectedStepIndex + 1].value
      this.lastTraversedStep = this.currentSelectedStepIndex
    },
    async handleNextClick() {
      if (this.$refs.formRef) {
        return this.$refs.formRef.validate(async valid => {
          if (valid) {
            if (this.activeStep === STEP.CHOOSE_PROGRAMS) {
              await this.assignEpisodes(
                this.handleEpisodeAssignProgressChange.bind(this)
              )
              return this.goToNextStep()
            }
            if (this.activeStep === STEP.SPECIFY_DETAILS) {
              this.goToNextStep()
            }
          }
        })
      }
      if (this.activeStep === STEP.OVERVIEW) {
        return this.createCourseCampaign()
      }
      this.goToNextStep()
    },
    async handlePrevClick() {
      if (this.activeStep === STEP.CHOOSE_PROGRAMS) {
        await this.assignEpisodes(
          this.handleEpisodeAssignProgressChange.bind(this)
        )
        this.$message({ type: 'success', message: this.$t('assignSuccess') })
        this.$router.push('/users')
      }
      if (this.activeStep === STEP.OVERVIEW) {
        this.activeStep = this.steps[this.currentSelectedStepIndex - 1].value
        this.lastTraversedStep = this.currentSelectedStepIndex
      }
    },
    navigateToStep(step) {
      this.activeStep = step
    }
  }
}
</script>

<style lang="scss">
.padding-x-lg {
  padding: 0px 30px;
}

.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.my-3 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.p-3 {
  padding: 12px;
}

.d-flex {
  display: flex;
}

.flex-end {
  justify-content: end;
}

.selected-step {
  .el-link {
    color: #409eff !important;
  }
}

.justify-center {
  display: flex;
  justify-content: center;
}

.title {
  font-weight: 400;
}

.steps {
  &-container {
    align-items: center;
  }
}

.action-btn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.el-progress-bar__innerText {
  display: block;
  font-size: 8px;
}
</style>
