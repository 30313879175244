<template>
  <div class="login-container">
    <el-form ref="forgotPasswordForm" :model="forgotPasswordForm" :rules="validationRules" class="login-form" auto-complete="on" label-position="left">

      <el-row class="title-container">
        <el-col :span="24">
          <h3 class="title">{{ $t('forgotPassword.label') }}</h3>
        </el-col>
      </el-row>

      <el-form-item prop="email">
        <span class="svg-container">
          <svg-icon icon-class="user" />
        </span>
        <el-input
          ref="email"
          v-model="forgotPasswordForm.email"
          :placeholder="$t('forgotPassword.email')"
          name="email"
          type="text"
          tabindex="1"
          auto-complete="on"
        />
      </el-form-item>

      <el-button type="primary" :loading="isLoading" @click.native.prevent="handleNextClick">{{ $t('Next') }}</el-button>
    </el-form>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { validateEmail } from './config'
const { mapState: mapUserState, mapActions: mapUserActions } = createNamespacedHelpers('user')
export default {
  name: 'ForgotPassword',
  data() {
    return {
      forgotPasswordForm: {
        email: ''
      },
      validationRules: {
        email: [{ required: true, trigger: 'change', validator: validateEmail }]
      }
    }
  },
  computed: {
    ...mapUserState({
      isLoading: (state) => state.isLoading
    })
  },
  methods: {
    ...mapUserActions(['forgotPassword']),
    async handleNextClick() {
      this.$refs.forgotPasswordForm.validate(async(valid) => {
        if (valid) {
          try {
            await this.forgotPassword(this.forgotPasswordForm.email)
          } catch (error) {
            this.$message({ message: error, type: 'error' })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" src="../login/index.scss">
</style>
<style lang="scss" src="../login/style.scss" scoped>
</style>
