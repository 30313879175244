<template>
  <ODataTable
    ref="oDataTableComponentRef"
    :columns="columns.all"
    :add-edit-columns="columns.addEdit"
    resource="CampaignDefaults"
    :action-buttons-width="90"
    :total-label="$t('campaignDefaults.total')"
    :file-name="$t('campaignDefaults.fileName')"
    :add-row-button-label="$t('campaignDefaults.add')"
    :title="$t('campaignDefaults.title')"
    @rowInit="handleRowInit"
    @editorPreparing="handleEditorPreparing"
  />
</template>

<script>
import ODataTable from '../../components/ODataTable'
import odataTableEventsMixin from '@/mixin/odataTableEventsMixin'
import { createColumns, disabledColumns } from './config'

export default {
  name: 'CampaignDefaults',
  components: {
    ODataTable
  },
  mixins: [odataTableEventsMixin],
  data() {
    return {
      columns: createColumns(),
      disabledColumns
    }
  }
}
</script>
