var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.isHistoryDialog
        ? _c(
            "el-dialog",
            {
              attrs: { title: "Chat History", visible: _vm.isHistoryDialog },
              on: {
                "update:visible": function($event) {
                  _vm.isHistoryDialog = $event
                },
                close: _vm.handleDialogClose
              }
            },
            [
              _vm.isHistoryDialog
                ? _c("chatHistory", {
                    attrs: {
                      assistant: _vm.assistant,
                      "customer-data": _vm.customerData,
                      "session-id": _vm.sessionId
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("ODataTable", {
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns,
          "add-edit-columns": _vm.columns,
          "total-label": _vm.$t("customers.chatHistory.total"),
          "file-name": _vm.$t("customers.chatHistory.fileName"),
          title: _vm.$t("customers.chatHistory.title"),
          "is-static-data-source": true,
          "static-data": _vm.filteredHistories,
          "should-show-add-button": false,
          "should-show-edit-button": false,
          "should-show-delete-button": false
        },
        scopedSlots: _vm._u([
          {
            key: "custom-buttons",
            fn: function() {
              return [
                _c("DxButton", {
                  attrs: {
                    icon: _vm.chatHistoryIconUrl,
                    "on-click": _vm.hanldeShowHistory
                  }
                }),
                _c("DxButton", {
                  attrs: {
                    icon: "link",
                    "on-click": _vm.hanldeLinkToAssistantDefaults
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }