var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-container",
        [
          _c("div", { staticClass: "heading" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("contentBlocks")))])
          ]),
          _c(
            "el-main",
            [
              _c("content-part-table", {
                attrs: {
                  list: _vm.filteredList,
                  "edit-content-part": _vm.editContentPart,
                  filters: _vm.filters,
                  "handle-preview": _vm.handlePreview,
                  "clone-content-part": _vm.cloneContentPart
                },
                on: {
                  "update:filters": function($event) {
                    _vm.filters = $event
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-footer",
            { staticClass: "center-align", attrs: { height: "5vh" } },
            [
              _c(
                "el-row",
                { staticClass: "row-bg", attrs: { type: "flex" } },
                [
                  _c(
                    "el-col",
                    { staticClass: "left-align" },
                    [
                      _c("el-button", {
                        attrs: { size: "mini", icon: "el-icon-plus" },
                        on: { click: _vm.createContentPart }
                      }),
                      _c("el-button", {
                        attrs: {
                          disabled: _vm.isLoading,
                          loading: _vm.isLoading,
                          size: "mini",
                          icon: "el-icon-refresh"
                        },
                        on: { click: _vm.refreshTable }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "select-width",
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.dataCount,
                            callback: function($$v) {
                              _vm.dataCount = $$v
                            },
                            expression: "dataCount"
                          }
                        },
                        _vm._l(_vm.dataCountValues, function(value) {
                          return _c("el-option", {
                            key: value,
                            attrs: { label: value, value: value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c("el-pagination", {
                        staticClass: "right-align",
                        attrs: {
                          "page-size": _vm.dataCount,
                          total: _vm.totalFilteredItems,
                          "page-count": _vm.pagerCount,
                          layout: "total,prev,pager,next",
                          "current-page": _vm.current
                        },
                        on: {
                          "update:currentPage": function($event) {
                            _vm.current = $event
                          },
                          "update:current-page": function($event) {
                            _vm.current = $event
                          },
                          "current-change": _vm.handleCurrentPageChange
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("content-part-dialog-box", {
        attrs: {
          visible: _vm.dialogVisible,
          item: _vm.contentPartItem,
          "dialog-type": _vm.dialogType
        },
        on: {
          "update:visible": function($event) {
            _vm.dialogVisible = $event
          }
        }
      }),
      _c("template-preview", {
        attrs: {
          row: _vm.selectedPreview,
          visible: _vm.templatePreviewDialogVisible
        },
        on: {
          "update:visible": function($event) {
            _vm.templatePreviewDialogVisible = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }