import { createResource } from '@/utils/httpRequests'
import { objectToQueryParams } from '@/utils/objectToQueryParams'

export default class AssistantDefaults {
  static async create(data) {
    return await createResource({
      url: `${process.env.VUE_APP_ODATA_API}/AssistantDefaults`,
      data: {
        ...data,
        DateAdded: new Date().toISOString()
      }
    })
  }

  static fetchStreamData(data) {
    return new EventSource(
      `${process.env.VUE_APP_CHATSK_URL}/chatskstream${objectToQueryParams(data)}`
    )
  }
}
