import {
  createResource,
  fectchResource,
  deleteResource,
  patchResource
} from '@/utils/httpRequests'
export default class EpisodeService {
  static getBySiteIdAndProgramId({ siteId, programId }) {
    return fectchResource(
      `${process.env.VUE_APP_CONTENTPART_API}/categories/medialist/${programId}/${siteId}`
    )
  }

  static assignToCustomers(data) {
    return createResource({
      url: `${process.env.VUE_APP_CONTENTPART_API}/addusermedia`,
      data
    })
  }

  static fetchAll(columns = []) {
    const queryParam = [
      '$top=5000',
      ...(columns.length ? [`$select=${columns.join(',')}`] : [])
    ].join('&')
    return fectchResource(
      `${process.env.VUE_APP_ODATA_API}/Media?${queryParam}`
    )
  }

  static fetchAllDisabled() {
    return fectchResource(`${process.env.VUE_APP_ODATA_API}/tDisabledMedia`)
  }

  static enableMediaForSite(mediaToBeEnabled) {
    return deleteResource(
      `${process.env.VUE_APP_ODATA_API}/tDisabledMedia(${mediaToBeEnabled})`
    )
  }

  static disableMediaForSite(mediaToBeDisabled) {
    return createResource({
      url: `${process.env.VUE_APP_ODATA_API}/tDisabledMedia`,
      data: mediaToBeDisabled
    })
  }

  static fetchMediasByCategoryId(categoryId) {
    return fectchResource(
      `${process.env.VUE_APP_ODATA_API}/MediaByCategory?$filter=CategoryID eq ${categoryId}`
    )
  }
  static fetchMediaDetails(mediaID) {
    return fectchResource(
      `${process.env.VUE_APP_ODATA_API}/media?$filter=ID eq ${mediaID}`
    )
  }

  static updateAssistantId({ mediaId, assistantId }) {
    return patchResource({
      url: `${process.env.VUE_APP_ODATA_API}/Media(${mediaId})`,
      data: {
        AssistantID: assistantId
      }
    })
  }

  static fetchMediasBySite(siteId) {
    return fectchResource(
      `${process.env.VUE_APP_ODATA_API}/Media?$filter=SiteID eq ${siteId}`
    )
  }
}
