import moment from 'moment'
export class DateUtils {
  static shortDateFormat = 'YYYY-MM-DD';
  static odataDateTimeFormat = 'dd MMM yyyy, hh:mm a';
  static odataDateFormat = 'yyyy-MM-dd';

  static shortDateTimeFormat = `${this.shortDateFormat}, LT`;

  static formatToShortDate(date) {
    return moment(date).format(this.shortDateFormat)
  }

  static formatToShortDateTime(date) {
    return moment(date).format(this.shortDateTimeFormat)
  }

  static combineDateAndTime(dateObj, timeObj) {
    const date = new Date(dateObj)
    const time = new Date(timeObj)

    const combined = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
      time.getSeconds(),
      time.getMilliseconds()
    )

    return combined
  }
}
