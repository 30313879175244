var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.field.isOnForm
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.fileManager.isLoading,
                expression: "fileManager.isLoading"
              }
            ]
          },
          [
            _vm.fileManager.showUploadFile
              ? _c(
                  "div",
                  [
                    _vm.fileManager.showImageFile
                      ? _c(
                          "el-image",
                          {
                            staticClass: "thumbnail-image uploaded-image",
                            attrs: {
                              src:
                                _vm.fileManager.fileUrl ||
                                _vm.fileManager.field.value,
                              fit: "cover"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                attrs: { slot: "placeholder" },
                                slot: "placeholder"
                              },
                              [_c("i", { staticClass: "el-icon-loading" })]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error"
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline"
                                })
                              ]
                            )
                          ]
                        )
                      : _vm.fileManager.showVideoFile
                      ? _c(
                          "video",
                          {
                            staticClass: "thumbnail-image uploaded-image",
                            attrs: { controls: "" }
                          },
                          [
                            _c("source", {
                              attrs: {
                                src:
                                  _vm.fileManager.fileUrl ||
                                  _vm.fileManager.field.value
                              }
                            })
                          ]
                        )
                      : _vm.fileManager.showDocumentFile
                      ? _c("iframe", {
                          staticClass: "thumbnail-image uploaded-image",
                          attrs: {
                            src:
                              _vm.fileManager.fileUrl ||
                              _vm.fileManager.field.value
                          }
                        })
                      : _vm._e(),
                    _vm.fileManager.fileUrl
                      ? _c(
                          "el-row",
                          { staticClass: "uploaded-image-name-wrapper" },
                          [
                            _c("div", { staticClass: "dx-fileuploader-file" }, [
                              _c(
                                "div",
                                { staticClass: "dx-fileuploader-file-name" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.fileManager.updatedFileName) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "action-buttons" },
                                [
                                  _vm.fileManager.showDownloadIcon
                                    ? _c("el-button", {
                                        staticClass: "position-circle-icon",
                                        attrs: {
                                          circle: "",
                                          icon: "el-icon-download"
                                        },
                                        on: { click: _vm.handleDownload }
                                      })
                                    : _vm._e(),
                                  _vm.fileManager.showRemoveIcon
                                    ? _c("el-button", {
                                        staticClass: "position-circle-icon",
                                        attrs: {
                                          circle: "",
                                          icon: "el-icon-circle-close"
                                        },
                                        on: { click: _vm.handleClose }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm.fileManager.filePath
                      ? _c(
                          "el-row",
                          { staticClass: "uploaded-image-name-wrapper" },
                          [
                            _c(
                              "el-col",
                              { staticClass: "dx-fileuploader-file" },
                              [
                                _c(
                                  "el-col",
                                  { staticClass: "dx-fileuploader-file-path" },
                                  [_vm._v(_vm._s(_vm.fileManager.filePath))]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "DxFileUploader",
              _vm._b(
                {
                  ref: "fileUploaderRef",
                  attrs: {
                    name: _vm.name,
                    disabled: _vm.fileManager.isFileUploaderDisabled,
                    "select-button-text": _vm.$t(_vm.selectButtonText),
                    accept: _vm.fileManager.accept,
                    multiple: _vm.fileManager.shouldAllowMultipleUploads,
                    "upload-headers": _vm.uploadHeaders,
                    "upload-method": _vm.uploadMethod,
                    "upload-mode": _vm.uploadMode,
                    "upload-url": _vm.fileManager.uploadUrl,
                    "upload-custom-data": _vm.fileManager.customData,
                    "upload-file": _vm.handleUploadFile,
                    "allowed-file-extensions":
                      _vm.fileManager.allowedFileExtensions,
                    "max-file-size": _vm.fileManager.allowedMaxFileSize
                  },
                  on: {
                    "value-changed": _vm.handleChange,
                    uploaded: _vm.handleUploadSuccess,
                    "upload-error": _vm.handleUploadError
                  }
                },
                "DxFileUploader",
                _vm.fileSelection,
                false
              )
            ),
            _c(
              "el-row",
              [
                _vm.fileManager.isImageType && !_vm.fileManager.isTypeAssistant
                  ? _c("el-col", [
                      _c("span", { staticClass: "instruction" }, [
                        _vm._v(
                          _vm._s(
                            "" +
                              _vm.$t("type") +
                              _vm.fileManager.allowedFileType +
                              " "
                          )
                        )
                      ])
                    ])
                  : _vm._e(),
                _c("el-col", [
                  _c("span", { staticClass: "instruction" }, [
                    _vm._v(
                      _vm._s(
                        "" +
                          _vm.$t("fileSize") +
                          _vm.fileManager.maxFileSize +
                          _vm.$t("mb") +
                          " "
                      )
                    )
                  ])
                ])
              ],
              1
            ),
            _vm.fileManager.field.column.editorOptions.showAsTextBox
              ? _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      [
                        _c("DxTextBox", {
                          attrs: {
                            value: _vm.fileManager.linkUrl,
                            "on-value-changed": _vm.handleValueChanged,
                            "value-change-event": "keyup"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.fileManager.isAssistantCreated
              ? _c("el-col", [
                  _c("span", { staticClass: "validation-note" }, [
                    _vm._v(_vm._s(_vm.$t("fileUploderEnableValidation")))
                  ])
                ])
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    !_vm.fileManager.field.isOnForm
      ? _c("div", [_vm._v(" " + _vm._s(_vm.fileManager.field.value) + " ")])
      : _vm._e(),
    _vm.fileManager.uploadedFilesLength
      ? _c("div", { staticClass: "uploaded-files-heading" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("assistantFiles")))])
        ])
      : _vm._e(),
    _vm.fileManager.uploadedFilesLength
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: !_vm.fileManager.uploadedFilesLength,
                expression: " !fileManager.uploadedFilesLength "
              }
            ],
            staticClass: "uploaded-image-name-wrapper container"
          },
          _vm._l(_vm.fileManager.uploadedFiles, function(file) {
            return _c(
              "div",
              { key: file.id, staticClass: "uploaded-assistant-file" },
              [
                _c(
                  "div",
                  { staticClass: "position-relative" },
                  [
                    _c("el-button", {
                      staticClass: "delete-button",
                      attrs: { circle: "", icon: "el-icon-delete" },
                      on: {
                        click: function($event) {
                          return _vm.deleteFile(file)
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-row",
                  {
                    staticClass: "uploaded-file thumbnail-image uploaded-image"
                  },
                  [
                    _c("div", { staticClass: "dx-fileuploader-file" }, [
                      _c("div", { staticClass: "dx-fileuploader-file-name" }, [
                        _vm._v(_vm._s(file.filename))
                      ])
                    ])
                  ]
                )
              ],
              1
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }