<template>
  <Categories
    ref="categoriesRef"
    :create-columns="createColumns"
    :should-show-add-button="false"
    :should-show-edit-button="false"
    :should-show-delete-button="false"
    :should-show-site-dropdown="false"
    :should-show-toggle-button="true"
    :should-show-title="true"
    section="programs"
    section-for-sub-programs="subprograms"
    @filterPrograms="handleShowOfFilteredPrograms"
  >
    <template #toggle-button="{ data:item }">
      <el-switch
        :value="isProgramActive(item.row.data.ID)"
        @change="toggleActivate(item.row.data.ID)"
      />
    </template>
  </Categories>
</template>

<script>
import Categories from '@/views/categories'
import { createColumns } from './config'
import { createNamespacedHelpers } from 'vuex'
import { Category } from '@/models/category'
import { confirm } from 'devextreme/ui/dialog'
const { mapActions: mapAppActions, mapGetters: mapAppGetters } = createNamespacedHelpers('app')
const { mapState: mapContentPartState } = createNamespacedHelpers('contentPart')

export default {
  name: 'Detail',
  components: {
    Categories
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      createColumns: createColumns.bind(this)
    }
  },
  computed: {
    ...mapAppGetters(['findCategoryByIdAndSiteId']),
    ...mapContentPartState({
      sites: state => state.sites
    })
  },
  methods: {
    ...mapAppActions([
      'disableCategoryForSite',
      'enableCategoryForSite'
    ]),
    isProgramActive(id) {
      return !this.findCategoryByIdAndSiteId({ id, siteId: this.data.ID })
    },
    async handleToggleConfirm(id) {
      this.$refs.categoriesRef.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
      try {
        const category = this.findCategoryByIdAndSiteId({
          id,
          siteId: this.data.ID
        })
        await (category
          ? this.enableCategoryForSite(category.ID)
          : this.disableCategoryForSite(
            Category.createDisableParams({ id, siteId: this.data.ID })
          ))
        await this.$refs.categoriesRef.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.refresh()
        this.$message({
          type: 'success',
          message: this.$t(
            category ? 'programs.activate' : 'programs.deactivate'
          )
        })
      } finally {
        this.$refs.categoriesRef.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
      }
    },
    async toggleActivate(id) {
      const result = await confirm(this.$t('sites.toggleConfirm'))
      if (result) {
        await this.handleToggleConfirm(id)
      }
    },
    handleShowOfFilteredPrograms(filterValue) {
      this.$refs.categoriesRef.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.columnOption(this.$t('programs.isProgramActive'), {
        selectedFilterOperation: '=',
        filterValue
      })
    }
  }
}
</script>
