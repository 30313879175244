import ContentPartService from '@/api/contentPart'
import { Site } from '@/models/site'
import { mergeLists } from '@/utils/list'
import { Category } from '@/models/category'
import { Media } from '@/models/media'

export const MUTATION_TYPE = {
  SET_CONTENT_PART: 'SET_CONTENT_PART',
  SET_CONTENT_PART_PREVIEW: 'SET_CONTENT_PART_PREVIEW',
  SET_DIALOGBOX_CONFIG: 'SET_DIALOGBOX_CONFIG'
}

const PREVIEW_URL = process.env.VUE_APP_CONTENTPART_PREVIEW_URL
const FILE_UPLOAD_API = `${process.env.VUE_APP_CONTENTPART_API}/content/cmsfile`

const state = {
  list: [],
  totalItems: 0,
  isLoading: true,
  templates: [],
  sites: [],
  tags: [],
  positions: [],
  categories: [],
  medias: [],
  isContentPartDialogLoading: false,
  fileUploadAPI: FILE_UPLOAD_API,
  preview: {
    src: '',
    guid: '',
    isPreviewDialogLoading: true
  },
  dialogBoxConfig: {
    showCategory: false,
    showMedia: false,
    disableEditor: false,
    isCategoryLoading: true,
    isMediaLoading: true
  }
}

const mutations = {
  [MUTATION_TYPE.SET_CONTENT_PART]: (state, payload = {}) => {
    Object.assign(state, payload)
  },

  [MUTATION_TYPE.SET_CONTENT_PART_PREVIEW]: (state, payload = {}) => {
    Object.assign(state.preview, payload)
  },

  [MUTATION_TYPE.SET_DIALOGBOX_CONFIG]: (state, payload) => {
    Object.assign(state.dialogBoxConfig, payload)
  }
}

function updateContentPartTable(dispatch, updatedList = []) {
  dispatch('setSites', {})
  dispatch('setTemplates', {})
  dispatch('setContentPart', updatedList)
}

const actions = {
  async setContentPart({ commit, rootState, state }, updatedList) {
    try {
      const SiteID = rootState.app.siteID
      if (SiteID === -1) {
        return Promise.resolve()
      }
      commit(MUTATION_TYPE.SET_CONTENT_PART, { isLoading: true })
      const list =
        updatedList ||
        (await ContentPartService.fetchContentPart({
          SiteID,
          Tag: null,
          Name: null,
          Position: null
        }))
      const positions = mergeLists(state.positions, list, 'Position')
      const tags = mergeLists(state.tags, list, 'Tag')
      commit(MUTATION_TYPE.SET_CONTENT_PART, {
        positions,
        tags,
        list,
        totalItems: list.length,
        isLoading: false
      })
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPE.SET_CONTENT_PART, { isLoading: false })
      return Promise.reject(error)
    }
  },
  async setTags({ commit }) {
    try {
      const tags = await ContentPartService.fetchContentPartTags()
      const mergedTagsList = mergeLists(state.list, tags, 'Tag')
      commit(MUTATION_TYPE.SET_CONTENT_PART, { tags: mergedTagsList })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async setPositions({ commit }) {
    try {
      const positions = await ContentPartService.fetchContentPartPositions()
      const mergedPositionsList = mergeLists(state.list, positions, 'Position')
      commit(MUTATION_TYPE.SET_CONTENT_PART, {
        positions: mergedPositionsList
      })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async setTemplates({ commit }) {
    try {
      const templates = await ContentPartService.fetchContentPartTemplate()
      commit(MUTATION_TYPE.SET_CONTENT_PART, { templates })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async setSites({ commit }) {
    try {
      const response = await ContentPartService.fetchContentPartSites()

      const sites = Site.fromList(response)
      commit(MUTATION_TYPE.SET_CONTENT_PART, { sites })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  toggleLoading({ commit, state }) {
    commit(MUTATION_TYPE.SET_CONTENT_PART, { isLoading: !state.isLoading })
  },
  async deleteContentPartRow({ dispatch, state }, ID) {
    try {
      dispatch('toggleLoading')
      await ContentPartService.deleteContentPart(ID)
      const updatedList = state.list.filter(
        contentPart => contentPart.ID !== ID
      )
      updateContentPartTable(dispatch, updatedList)
      return Promise.resolve()
    } catch (error) {
      dispatch('toggleLoading')
      return Promise.reject(error)
    }
  },
  async updateContentPartRow({ dispatch, state }, payload) {
    try {
      dispatch('toggleLoading')
      await ContentPartService.updateContentPart({
        ...payload,
        isActive: true
      })

      const updatedList = state.list.map(contentPart =>
        contentPart.ID === payload.ID ? payload : contentPart
      )

      updateContentPartTable(dispatch, updatedList)
      return Promise.resolve()
    } catch (error) {
      dispatch('toggleLoading')
      return Promise.reject(error)
    }
  },
  async createContentPartRow({ dispatch, state }, payload) {
    try {
      dispatch('toggleLoading')
      const contentPart = await ContentPartService.createContentPart({
        ...payload,
        isActive: true
      })
      const updatedList = [...state.list, contentPart]

      updateContentPartTable(dispatch, updatedList)
      return Promise.resolve()
    } catch (error) {
      dispatch('toggleLoading')
      return Promise.reject(error)
    }
  },

  async uploadFile(store, { onSuccess, onError, ...payload }) {
    try {
      const response = await ContentPartService.createCMSFile(payload)
      onSuccess(response)
      return Promise.resolve(response)
    } catch (error) {
      onError(error)
      return Promise.reject(error)
    }
  },
  toggleContentPartDialogLoading({ commit, state }) {
    commit(MUTATION_TYPE.SET_CONTENT_PART, {
      isContentPartDialogLoading: !state.isContentPartDialogLoading
    })
  },
  toggleIsPreviewDialogLoading({ commit, state }) {
    commit(MUTATION_TYPE.SET_CONTENT_PART_PREVIEW, {
      isPreviewDialogLoading: !state.preview.isPreviewDialogLoading
    })
  },
  async previewContentPart({ commit, dispatch }, payload) {
    try {
      const guid = await ContentPartService.createPreview(payload)
      commit(MUTATION_TYPE.SET_CONTENT_PART_PREVIEW, {
        guid,
        src: `${PREVIEW_URL}${guid}`
      })
      dispatch('toggleIsPreviewDialogLoading')
      return Promise.resolve()
    } catch (error) {
      dispatch('toggleIsPreviewDialogLoading')
      return Promise.reject(error)
    }
  },
  async setCategories({ commit, rootState }) {
    try {
      const categories = await ContentPartService.fetchCategories(
        rootState.app.siteID
      )
      commit(MUTATION_TYPE.SET_CONTENT_PART, {
        categories: categories.map(el => new Category(el.Category))
      })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async setMedia({ commit, rootState }, category) {
    try {
      if (!category) {
        return
      }
      const medias = await ContentPartService.fetchMediaForCategory({
        category,
        siteID: rootState.app.siteID
      })

      commit(MUTATION_TYPE.SET_CONTENT_PART, {
        medias: medias?.Media?.map(media => new Media(media)) || []
      })
      commit(MUTATION_TYPE.SET_DIALOGBOX_CONFIG, { isMediaLoading: false })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  setDialogBoxConfig({ commit }, payload) {
    commit(MUTATION_TYPE.SET_DIALOGBOX_CONFIG, payload)
  }
}

const getters = {
  findSiteById: state => id => state.sites.find(el => el.ID === id)
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
