export class Category {
  ID;

  IsActive;

  Name;

  SiteID;

  SortOrder;
  constructor(category = {}) {
    Object.assign(this, category)
  }

  get option() {
    return {
      label: this.Name,
      value: this.ID
    }
  }

  get asSubCategory() {
    return {
      ID: this.ID,
      CategoriesID: null,
      SiteID: this.SiteID,
      SubCategoryName: this.Name,
      SortOrder: this.SortOrder,
      IsActive: this.IsActive
    }
  }

  isMatched = (filter = []) => {
    const matches = []

    let attribute

    let operator
    filter.forEach(item => {
      if (Array.isArray(item)) {
        matches.push(this.isMatched(item))
      } else {
        if (typeof item === 'string') {
          if (this.asSubCategory[item]) {
            return (attribute = this.asSubCategory[item])
          }
          if (['=', 'contains'].includes(item)) {
            return (operator = item)
          }
        }
        if (attribute && operator) {
          if (operator === '=') {
            return matches.push(attribute === item)
          }

          if (operator === 'contains') {
            return matches.push(
              attribute.toLowerCase().includes(item.toLowerCase())
            )
          }
        }
      }
    })

    return matches.every(match => !!match)
  };

  static get optionColumns() {
    return ['ID', 'Name']
  }

  static fromList(categories = []) {
    return categories.map(category => new Category(category))
  }

  static createDisableParams({ id, siteId }) {
    return {
      CategoryID: id,
      SiteId: siteId,
      DateAdded: new Date().toISOString()
    }
  }
}
