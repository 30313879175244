<template>
  <div>
    <el-dialog
      :title="$t('saveItemDialogHeading')"
      :visible.sync="isCreateDialogOpen"
      width="30%"
      :before-close="handleClose"
      append-to-body
    >
      <span slot="footer">
        <el-button type="primary" @click="handleChange">{{ $t("save") }}</el-button>
        <el-button @click="handleClose">{{ $t("cancel") }}</el-button>
      </span>
    </el-dialog>
    <el-select
      v-model="selectedTemplate"
      :allow-create="allowCreate"
      default-first-option
      filterable
      :placeholder="placeholder"
      :size="size"
      :disabled="isGlobalSiteSelectorDisabled"
    >
      <el-option v-if="showAll" value="" label="All" />
      <el-option
        v-for="option in options"
        :key="option.ID"
        :label="option[propName]"
        :value="option[propValue]"
      />
    </el-select>
  </div>

</template>

<script>
export default {
  name: 'ContentPartSelect',
  props: {
    selected: {
      type: [Number, String],
      default: ''
    },
    allowCreate: {
      type: Boolean,
      default: false
    },
    showAll: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default() {
        return []
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'medium'
    },
    propName: {
      type: String,
      default: 'Name'
    },
    propValue: {
      type: String,
      default: 'ID'
    }
  },
  data() {
    return {
      isCreateDialogOpen: false,
      newTemplate: ''
    }
  },
  computed: {
    selectedTemplate: {
      get: function() {
        return this.selected
      },
      set: function(val) {
        if (this.allowCreate && !this.isExistingOption(val)) {
          this.newTemplate = val
          this.isCreateDialogOpen = true
        } else {
          this.$emit('update:selected', val)
          this.$emit('change', val)
        }
      }
    },
    isGlobalSiteSelectorDisabled() {
      const activeMenu = this.$store.state.user.user.menus.findByUrl(this.$route.fullPath)
      if (activeMenu) {
        return !activeMenu.NeedSiteID
      }
      return false
    }
  },
  methods: {
    handleClose() {
      this.isCreateDialogOpen = false
    },
    handleChange() {
      this.$emit('update:selected', this.newTemplate)
      this.$emit('change', this.newTemplate)
      this.isCreateDialogOpen = false
    },
    isExistingOption(value) {
      return this.options
        .map((option) => option[this.propValue])
        .includes(value)
    }
  }
}
</script>
<style>
.el-select{
  display: inherit;
}

</style>
