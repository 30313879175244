const addEdit = [
  {
    field: 'key',
    label: 'assistants.metaContents.column.key',
    width: '100%',
    required: true,
    requiredMessage: 'assistants.metaContents.requiredMessage.key'
  },
  {
    field: 'value',
    label: 'assistants.metaContents.column.value',
    width: '100%',
    required: true,
    requiredMessage: 'assistants.metaContents.requiredMessage.value'
  }
]
export const columns = {
  addEdit: addEdit,
  all: [
    {
      field: 'index',
      label: 'assistants.metaContents.column.seqNumber',

      width: '100%'
    },
    ...addEdit
  ]
}
