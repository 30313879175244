<template>
  <div class="column-flex">
    <div class="margin-left-md table">
      <el-table :data="usersWithInvalidEmails" height="250">
        <el-table-column
          v-for="heading in headings"
          :key="heading.column"
          :label="heading.column"
          :prop="heading.column"
        >
          <template slot-scope="scope">
            <div v-if="heading.isEmail" class="column">
              <el-input
                v-if="scope.row.isEditModeEnabled"
                class="margin-right-sm"
                placeholder="Enter valid email"
                :value="scope.row[heading.column]"
                clearable
                @input="handleValueChange(scope.row.EMail, $event)"
              />
              <el-button
                v-if="scope.row.isEditModeEnabled"
                circle
                icon="el-icon-check"
                @click="handleSaveClick(scope.row.EMail)"
              />
              <template v-if="!scope.row.isEditModeEnabled">{{
                scope.row[heading.column]
              }}</template>
              <el-button
                v-if="!scope.row.isEditModeEnabled"
                type="primary"
                circle
                icon="el-icon-edit"
                @click="handleEditClick(scope.row.EMail)"
              />
            </div>
            <template v-else>{{ scope.row[heading.column] }}</template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-button
      :loading="isLoading"
      type="primary"
      class="margin-left-md self-start"
      @click="handleGenerateReportClick"
    >{{ $t("manageUsers.matchField.generateReport") }}</el-button>
  </div>
</template>
<script>
import { MUTATION_TYPES } from '@/store/mutations'
import { createNamespacedHelpers } from 'vuex'
import { nextClickEvent } from './config'

const { mapState, mapMutations } =
  createNamespacedHelpers('user/importedUsers')
export default {
  name: 'ValidateEmails',
  computed: {
    ...mapState({
      headings: (state) =>
        state.importedUsers.headings.columnsForEmailValidations,
      usersWithInvalidEmails: (state) =>
        state.usersWithInvalidEmail.importedUsers,
      isLoading: (state) => state.isLoading
    })
  },
  methods: {
    handleGenerateReportClick() {
      this.$emit(nextClickEvent)
    },
    ...mapMutations({
      setEditMode: MUTATION_TYPES.USER.IMPORTED_USERS.SET_EDIT_MODE,
      updateEmail: MUTATION_TYPES.USER.IMPORTED_USERS.UPDATE_EMAIL
    }),
    handleSaveClick(email) {
      this.setEditMode({ email, isEditModeEnabled: false })
    },
    handleEditClick(email) {
      this.setEditMode({ email, isEditModeEnabled: true })
    },
    handleValueChange(email, newEmail) {
      this.updateEmail({ email, newEmail })
    }
  }
}
</script>

<style lang="scss" scoped>
$base-spacing: 5px;
.table {
  width: 70vw;
  overflow: scroll;
}
.margin-left-md {
  margin: $base-spacing * 5;
}

.self-start {
  align-self: flex-start;
}

.column-flex {
  display: flex;
  flex-direction: column;
}
.column {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.margin-right-sm {
  margin-right: $base-spacing;
}
</style>
