import {
  createResource,
  fectchResource,
  patchResource
} from '@/utils/httpRequests'
import buildQuery from 'odata-query'

export default class CustomerService {
  static getById(id) {
    const query = buildQuery({ filter: { ID: { eq: Number(id) }}})
    return fectchResource(`${process.env.VUE_APP_ODATA_API}/customers${query}`)
  }

  static getByAccount({ account, subAccount }) {
    const query = buildQuery({
      filter: {
        Account: { eq: account },
        ...(subAccount ? { SubAccount: { eq: subAccount }} : {})
      }
    })
    return fectchResource(`${process.env.VUE_APP_ODATA_API}/customers${query}`)
  }

  static getImpersonateLink(id) {
    return fectchResource(
      `${process.env.VUE_APP_CONTENTPART_API}/user/implink/${id}`
    )
  }

  static log(data) {
    return createResource({
      url: `${process.env.VUE_APP_CONTENTPART_API}/SaveAction`,
      data
    })
  }

  static fetchTagsByCustomerId(customerId) {
    return fectchResource(
      `${process.env.VUE_APP_ODATA_API}/UserTag?$filter=CustomerID eq ${customerId} and IsActive eq true`
    )
  }

  static fetchAllCustomers() {
    return fectchResource(
      `${process.env.VUE_APP_ODATA_API}/Customers?$top=5000`
    )
  }

  static fetchAllTagsByCustomerId(customerId) {
    return fectchResource(
      `${process.env.VUE_APP_ODATA_API}/UserTag?$filter=CustomerID eq ${customerId}`
    )
  }

  static fetchChatHistory(customerId) {
    return fectchResource(
      `${process.env.VUE_APP_CONTENTPART_API}/assistant/chathistory/${customerId}`
    )
  }

  static updateCustomerByID({ updatedCustomerData, ID }) {
    return patchResource({
      url: `${process.env.VUE_APP_ODATA_API}/Customers(${ID})`,
      data: updatedCustomerData
    })
  }
}
