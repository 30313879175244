import jimp from 'jimp'

export class FileProcessor {
  file;

  constructor(file) {
    this.file = file
  }
  async resizeImage(width = process.env.VUE_APP_IMAGE_THUMBNAIL_WIDTH) {
    const image = await jimp.read(this.file)
    await image.resize(Number(width), jimp.AUTO, jimp.RESIZE_BEZIER)
    return image.getBase64Async(image.getMIME())
  }
}
