export const createColumns = function({ sites }) {
  const addEdit = [
    {
      field: 'SiteID',
      width: '100%',
      lookup: sites,
      label: 'sites.column.siteName.label'
    },
    {
      field: 'Name',
      width: '100%'
    },
    {
      field: 'SortOrder',
      width: '100%'
    },
    {
      label: 'programs.isProgramActive',
      width: '100%',

      calculateCellValue: event => {
        return this.isProgramActive(event.ID)
      },

      calculateFilterExpression: filterValue => {
        const selector = data => {
          const isActive = this.isProgramActive(data.ID)
          return filterValue ? isActive : !isActive
        }
        return [selector, '=', true]
      }
    }
  ]

  return {
    addEdit,
    all: [
      {
        field: 'ID',
        width: '100%'
      },
      ...addEdit
    ]
  }
}
