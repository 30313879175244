<template>
  <ODataTable
    ref="oDataTableComponentRef"
    :key="selectedSiteOnPage"
    :alias-resource="
      shouldShowAddButton && selectedSiteOnPage ? 'Categories' : ''
    "
    :columns="columns.all"
    :add-edit-columns="columns.addEdit"
    resource="Categories"
    :total-label="$t(`${section}.total`)"
    :file-name="$t(`${section}.fileName`)"
    :add-row-button-label="$t(`${section}.add`)"
    :title="$t(`${section}.title`)"
    :should-render-master-detail="true"
    :filter="filter"
    :should-show-add-button="shouldShowAddButton"
    :should-show-edit-button="shouldShowEditButton"
    :should-show-delete-button="shouldShowDeleteButton"
    @rowInit="handleRowInit"
    @editorPreparing="handleEditorPreparing"
    @clearFilters="handleFiltersClear"
    @contentReady="handleContentReady"
  >
    <template #toggle-button="{ data }">
      <slot name="toggle-button" :data="data" />
    </template>
    <template v-if="shouldShowToggleButton" #custom-buttons>
      <DxButton template="custom-button-template" />
    </template>
    <template #details="{ data }">
      <Detail
        :data="data"
        :should-show-add-button="shouldShowAddButton"
        :should-show-edit-button="shouldShowEditButton"
        :should-show-delete-button="shouldShowDeleteButton"
        :should-show-toggle-button="shouldShowToggleButton"
        :should-show-title="shouldShowTitle"
        :section-for-sub-programs="sectionForSubPrograms"
        @subCategoryAdded="handleSubCategoryAddedOrRemoved"
        @subCategoryRemoved="handleSubCategoryAddedOrRemoved"
      />
    </template>
    <template v-if="!shouldShowSiteDropdown" #custom-toolbar-before>
      <el-select
        :value="selectedValue"
        :allow-create="false"
        default-first-option
        filterable
        :placeholder="$t('select')"
        @change="handleChange"
      >
        <el-option
          v-for="option in options"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        />
      </el-select>
    </template>
    <template v-if="shouldShowSiteDropdown" #custom-toolbar-after>
      <el-select
        :value="selectedSiteOnPage"
        :allow-create="false"
        default-first-option
        filterable
        :placeholder="$t('contentPart.site')"
        @change="handleValueChange"
      >
        <el-option
          v-for="site in sites"
          :key="site.value"
          :label="site.label"
          :value="site.value"
        />
      </el-select>
    </template>
  </ODataTable>
</template>
<script>
import ODataTable from '../../components/ODataTable'
import { createNamespacedHelpers } from 'vuex'
import Detail from './detail/index.vue'
import { createColumns, addedDateField, optionsToSelect } from './config'
import { DxButton } from 'devextreme-vue/data-grid'
import { MUTATION_TYPES } from '@/store/mutations'
import { OptionList } from '@/models/optionList'
import i18n from '@/i18n'
const {
  mapActions,
  mapMutations: mapAppMutations,
  mapState: mapAppState
} = createNamespacedHelpers('app')
const { mapState: mapContentPartState } =
  createNamespacedHelpers('contentPart')

const getTransalatedOptions = () => {
  const options = optionsToSelect.map((option) => {
    return {
      label: i18n.t(`${option.label}`),
      value: option.value
    }
  })

  return options
}

export default {
  name: 'Categories',
  components: {
    ODataTable,
    Detail,
    DxButton
  },
  props: {
    createColumns: {
      type: Function,
      default: createColumns
    },
    shouldShowAddButton: {
      type: Boolean,
      default: true
    },
    shouldShowEditButton: {
      type: Boolean,
      default: true
    },
    shouldShowDeleteButton: {
      type: Boolean,
      default: true
    },
    section: {
      type: String,
      default: 'categories'
    },
    shouldShowSiteDropdown: {
      type: Boolean,
      default: true
    },
    shouldShowToggleButton: {
      type: Boolean,
      default: false
    },
    shouldShowTitle: {
      type: Boolean,
      default: false
    },
    sectionForSubPrograms: {
      type: String,
      default: 'subCategories'
    }
  },
  data() {
    return {
      addedDateField,
      showActivePrograms: false,
      options: new OptionList(getTransalatedOptions()).options,
      selectedValue: null
    }
  },
  computed: {
    ...mapContentPartState({
      sites: (state) => [
        {
          label: 'All',
          value: ''
        },
        ...state.sites.map((site) => site.toOption)
      ]
    }),
    ...mapAppState({
      selectedSiteOnPage: (state) => state.selectedSiteOnPage,
      medias: state => state.medias,
      locale: state => state.locale
    }),
    columns() {
      return this.createColumns({
        sites: this.sites
          .filter((site) => site.value)
          .filter((site) =>
            this.selectedSiteOnPage
              ? this.siteIdsToBeFiltered.includes(site.value)
              : true
          )
      })
    },
    filter() {
      return this.selectedSiteOnPage
        ? [
          ['SiteId', '=', this.selectedSiteOnPage],
          'or',
          ['SiteId', '=', parseInt(process.env.VUE_APP_GLOBAL_SITE_ID)]
        ]
        : []
    },
    siteIdsToBeFiltered() {
      return [
        this.selectedSiteOnPage,
        parseInt(process.env.VUE_APP_GLOBAL_SITE_ID)
      ]
    }
  },
  watch: {
    locale() {
      this.options = new OptionList(getTransalatedOptions()).options
    }
  },
  async created() {
    if (!this.medias.length) {
      await this.fetchMedias()
    }
  },
  methods: {
    ...mapActions(['fetchMedias']),
    ...mapAppMutations({
      setSelectedSite: MUTATION_TYPES.APP.SET_SELECTED_SITE
    }),
    handleRowInit(event) {
      event.data.AddedDate = new Date().toISOString()
    },
    handleEditorPreparing(event) {
      if (event.isOnForm) {
        event.editorOptions.disabled = [addedDateField].includes(
          event.dataField
        )
      }
    },
    handleValueChange(site) {
      this.setSelectedSite(site)
    },
    handleFiltersClear() {
      if (this.shouldShowAddButton) {
        this.setSelectedSite('')
      }
    },
    handleSubCategoryAddedOrRemoved() {
      this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.refresh()
    },
    handleChange(value) {
      this.selectedValue = value
      this.$emit('filterPrograms', this.selectedValue)
    },
    handleContentReady(event) {
      const filters = event.component.getCombinedFilter()
      if (!filters) {
        this.selectedValue = null
        return
      }
      if (filters?.[0].columnIndex === 6) {
        this.selectedValue = filters.filterValue
      }
    }
  }
}
</script>

