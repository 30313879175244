<template>
  <el-container class="container">
    <el-container class="steps-container">
      <h1 class="title">{{ $t("manageDownloadables.title") }}</h1>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          v-for="(step, index) in steps"
          :key="step.value"
          :class="{ 'selected-step': isActiveStep(step.value) }"
        >
          <el-link
            :underline="false"
            :disabled="lastTraversedStep < index"
            @click="navigateToStep(step.value)"
          >
            {{ step.label }}
          </el-link>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </el-container>

    <el-main :class="{ section: true, 'generate-report': isGenerateReportStep }">
      <el-row>
        <el-col />
        <el-col>
          <h2 class="subtitle">{{ $t(stepTitle) }}</h2>
          <component :is="activeStepComponent" @onNextClick="handleNextClick" />
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import ImportDownloadables from './importDownloadables.vue'
import MatchFields from './matchFields.vue'
import GenerateReport from './generateReport.vue'
import { STEP } from './config'
export default {
  name: 'ManageDownloadables',
  components: {
    ImportDownloadables,
    MatchFields,
    GenerateReport
  },
  data() {
    return {
      activeStep: STEP.IMPORT_CSV,
      lastTraversedStep: 0,
      headings: [],
      stepComponent: {
        [STEP.IMPORT_CSV]: ImportDownloadables,
        [STEP.MATCH_COLUMN]: MatchFields,
        [STEP.GENERATE_REPORT]: GenerateReport
      }
    }
  },
  computed: {
    activeStepComponent() {
      return this.stepComponent[this.activeStep]
    },
    isGenerateReportStep() {
      return this.activeStep === STEP.GENERATE_REPORT
    },
    stepTitle() {
      return `manageDownloadables.${this.activeStep}.title${''}`
    },
    selectedStepIndex() {
      return this.steps.findIndex((step) => step.value === this.activeStep)
    },
    steps() {
      return Object.values(STEP).map(value =>
        ({
          label: this.$t(`manageDownloadables.${value}.stepLabel`),
          value
        }))
    }
  },
  watch: {
    '$route.meta.activeStep': {
      handler: function(activeStep) {
        if (activeStep) {
          this.activeStep = activeStep
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    isActiveStep(activeStep) {
      return this.activeStep === activeStep
    },
    handleNextClick(step) {
      if (step === STEP.GENERATE_REPORT) {
        this.activeStep = STEP.GENERATE_REPORT
        return
      }
      this.lastTraversedStep = this.selectedStepIndex
      this.activeStep = this.steps[this.selectedStepIndex + 1].value
    },
    navigateToStep(step) {
      this.activeStep = step
    }
  }
}
</script>

  <style lang="scss" src="./style.scss" scoped/>
