<template>
  <div class="app-container">
    <el-dialog
      :title="$t('goals.filter.title')"
      :visible.sync="isGoalsFilterDialogOpen"
      :close-on-click-modal="false"
      width="60%"
      @close="handleGoalsDetailsDialogClose"
    >
      <GoalFilters
        :sites="sites"
        :medias="medias"
        @filter="handleFilterApply"
        @closeFilter="handlerCloseGoalFilter"
      />
    </el-dialog>
    <el-dialog
      :title="$t('goals.goalText')"
      :visible.sync="isGoalsTextDialogOpen"
      :close-on-click-modal="false"
      width="30%"
      @close="handleGoalTextDetailsDialogOpenClose"
    >
      <p>{{ goalText }}</p>
    </el-dialog>
    <ODataTable
      :key="filter"
      ref="oDataTableComponentRef"
      :columns="columns.all"
      :add-edit-columns="columns.addEdit"
      :total-label="$t('goals.total')"
      :file-name="$t('goals.fileName')"
      :add-row-button-label="$t('goals.add')"
      :title="$t('goals.title')"
      resource="Goal"
      :filter="filter"
      :should-show-edit-button="false"
      :should-show-add-button="false"
      :should-show-delete-button="false"
      :action-buttons-width="70"
    >
      <template #custom-buttons>
        <DxButton icon="info" :hint="$t('goals.goalText')" :on-click="handleGoalTextDetailsDialogOpenClose" />
      </template>

      <template #custom-toolbar-before>
        <el-button
          :type="defaultType"
          plain
          @click="handleGoalsDetailsDialogOpen"
        >{{ $t("report") }}</el-button>
      </template>

      <template #custom-toggle-before>
        <el-switch
          v-model="hideGoalText"
          class="margin"
          :inactive-text="$t('goals.hideBlank')"
          @change="handleGoalTextFilter"
        />
      </template>
    </ODataTable>
  </div>
</template>
<script>
import { createColumns } from './config'
import ODataTable from '../../components/ODataTable'
import GoalFilters from '@/views/Goals/filters'
import { createNamespacedHelpers } from 'vuex'
import { DxButton } from 'devextreme-vue/data-grid'
const { mapState } = createNamespacedHelpers(
  'goals'
)
const { mapState: mapUserState } = createNamespacedHelpers(
  'user'
)
const {
  mapState: mapAppState,
  mapActions: mapAppActions
} = createNamespacedHelpers('app')
const {
  mapState: mapSharedSiteState,
  mapActions: mapSharedSiteActions
} = createNamespacedHelpers('shared/site')

export default {
  name: 'Goals',
  components: {
    ODataTable,
    GoalFilters,
    DxButton
  },
  data() {
    return {
      isGoalsFilterDialogOpen: false,
      isGoalsTextDialogOpen: false,
      defaultType: 'primary',
      filter: [],
      goalText: '',
      hideGoalText: false
    }
  },
  computed: {
    ...mapState({
      filters: state => state.filters
    }),
    ...mapUserState({
      loggedInUser: state => state.user.ID
    }),
    ...mapAppState({
      medias: (state) => state.medias
    }),
    ...mapSharedSiteState({
      sites: state => state.sites.map(site => site)
    }),
    columns() {
      return createColumns({ medias: this.medias, sites: this.sites })
    }
  },

  created() {
    this.fetchMedias()
    this.fetchSites()
  },

  methods: {
    ...mapAppActions([
      'fetchMedias'
    ]),
    ...mapSharedSiteActions({
      fetchSites: 'fetchAll'
    }),
    handleGoalsDetailsDialogOpen() {
      this.isGoalsFilterDialogOpen = true
    },
    handleGoalFilter() {
      return this.filters.getFilter()
    },
    handleGoalsDetailsDialogClose() {
      this.isGoalsFilterDialogOpen = false
    },
    handleGoalTextDetailsDialogOpenClose(event) {
      this.goalText = event.row.data.GoalText
      this.isGoalsTextDialogOpen = !this.isGoalsTextDialogOpen
    },
    async handleFilterApply(event) {
      this.filter = this.handleGoalFilter()
      this.handleGoalsDetailsDialogClose()
    },
    handlerCloseGoalFilter() {
      this.filters.clearFilters()
      this.filter = this.handleGoalFilter()
    },
    handleGoalTextFilter() {
      this.filter = this.filter.reduce((acc, item, index, arr) => {
        if (Array.isArray(item) && item[0] === 'GoalText' && item[1] === 'ne' && item[2] === "''") {
          if (index > 0 && arr[index - 1] === 'and') {
            return acc.slice(0, -1)
          }
          return acc
        }
        return [...acc, item]
      }, [])

      if (this.hideGoalText && this.filter.length) {
        this.filter = [...this.filter, 'and', ['GoalText', 'ne', "''"]]
      } else if (this.hideGoalText) {
        this.filter = [...this.filter, ['GoalText', 'ne', "''"]]
      }
    }

  }
}
</script>
<style scoped>
::v-deep .el-dialog__body {
  padding: 10px 20px !important;
}
.margin {
  margin-left: 2vw;
}
</style>
