import {
  fectchResource,
  createResource,
  updateResource,
  deleteResource
} from '@/utils/httpRequests'
import { request } from '@/utils/request'
import { HTTP_METHOD } from '@/utils/constants'

const baseUrlContentPart = `${process.env.VUE_APP_CONTENTPART_API}/content/contentpart`
const baseUrlPreview = `${process.env.VUE_APP_CONTENTPART_API}/content/preview`

class ContentPartService {
  static fetchContentPart = (
    options = { SiteID: 1, Tag: null, Name: null, Position: null }
  ) =>
    fectchResource(
      `${baseUrlContentPart}/${options.SiteID}/${options.Tag}/${options.Name}/${options.Position}`
    );

  static fetchContentPartTags = () =>
    fectchResource(
      `${process.env.VUE_APP_CONTENTPART_API}/content/contentparttag`
    );

  static fetchContentPartPositions = () =>
    fectchResource(
      `${process.env.VUE_APP_CONTENTPART_API}/content/contentpartposition`
    );

  static fetchContentPartTemplate = () =>
    fectchResource(
      `${process.env.VUE_APP_CONTENTPART_API}/content/contentparttemplate`
    );

  static fetchContentPartSites = () =>
    fectchResource(`${process.env.VUE_APP_CONTENTPART_API}/sites`);

  static updateContentPart = data =>
    updateResource({ url: baseUrlContentPart, data });

  static createContentPart = data =>
    createResource({ url: baseUrlContentPart, data });

  static deleteContentPart = ID =>
    deleteResource(`${baseUrlContentPart}?contentPartID=${ID}`);

  static createPreview = data => createResource({ url: baseUrlPreview, data });

  static createCMSFile = ({ data, onUploadProgress }) =>
    request({
      url: `${process.env.VUE_APP_CONTENTPART_API}/content/cmsfile`,
      method: HTTP_METHOD.POST,
      headers: { 'Content-Type': `multipart/form-data` },
      data,
      onUploadProgress,
      timeout: 0
    });

  static fetchCategories = siteID =>
    fectchResource(
      `${process.env.VUE_APP_CONTENTPART_API}/categories/${siteID}/true`
    );

  static fetchMediaForCategory = ({ category, siteID }) =>
    fectchResource(
      `${process.env.VUE_APP_CONTENTPART_API}/categories/content/${category}/${siteID}`
    );
}

export default ContentPartService
