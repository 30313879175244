export class Media {
  ID;

  Title;
  constructor(media = {}) {
    Object.assign(this, media)
  }

  get option() {
    return {
      label: this.Title,
      value: this.ID,
      assistantId: this.AssistantID
    }
  }

  static get optionColumns() {
    return ['ID', 'Title']
  }

  static get optionAndIdColumns() {
    return ['ID', 'Title', 'AssistantID']
  }

  static get authorItems() {
    return [
      'Author',
      'AuthorID',
      'DownloadFileName',
      'DownloadFileUID',
      'FileName_flash',
      'FileName_high'
    ]
  }

  static get mediaItems() {
    return [
      'MediaGUID',
      'MoreInformationLink',
      'MediaPlayerTime',
      'MedImage',
      'MainCategoryID',
      'Length'
    ]
  }

  static get fileItems() {
    return [
      'ShortTitle',
      'FileName_low',
      'FileName_modem',
      'ID',
      'demoClipLength',
      'SmallImage'
    ]
  }

  static get siteItems() {
    return ['SiteID', 'Title', 'Width', 'demoClipLength', 'ppcat', 'Width']
  }

  static fromList(medias = []) {
    return medias.map(media => new Media(media))
  }

  static createDisableParams({ id, siteId }) {
    return {
      MediaID: id,
      SiteID: siteId
    }
  }
}
