var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.selectedCustomer
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.showDetails,
                title: _vm.selectedCustomerName
              },
              on: {
                "update:visible": function($event) {
                  _vm.showDetails = $event
                },
                close: _vm.handleDetailsDialogClose
              }
            },
            [
              _c("Details", {
                attrs: {
                  detail: Object.assign({}, _vm.selectedCustomer, {
                    assignedTags: _vm.assignedTags,
                    sites: _vm.sites
                  })
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.isTagDialogOpen },
          on: {
            "update:visible": function($event) {
              _vm.isTagDialogOpen = $event
            },
            close: _vm.handleTagDialogClose
          }
        },
        [
          _c("TagUsers", {
            attrs: { users: _vm.selectedCustomers },
            on: { tagAssigned: _vm.handleTagDialogClose }
          })
        ],
        1
      ),
      _c("ODataTable", {
        key: _vm.selectedTag,
        ref: "customersRef",
        attrs: {
          columns: _vm.columns,
          "add-edit-columns": _vm.addEditColumns,
          "should-show-delete-button": false,
          resource: "customers",
          "is-custom-form": true,
          "total-label": _vm.$t("customers.total"),
          "file-name": _vm.$t("customers.fileName"),
          title: _vm.$t("customers.title"),
          "should-show-add-button": false,
          "action-buttons-width": 180,
          "alias-resource": _vm.aliasResource,
          "is-state-stored": _vm.isStateStored
        },
        on: {
          rowInit: _vm.handleRowInit,
          editorPreparing: _vm.handleEditorPreparing,
          selectionChange: _vm.handleSelectionChange,
          clearFilters: _vm.handleFiltersClear,
          contentReady: _vm.handleContentReady
        },
        scopedSlots: _vm._u([
          {
            key: "custom-form",
            fn: function() {
              return [
                _vm._l(_vm.visibleEditColumns, function(column) {
                  return _c("DxSimpleItem", {
                    key: "add_edit_" + column.field,
                    attrs: { "data-field": column.field, visible: true }
                  })
                }),
                _c("DxButtonItem", {
                  attrs: {
                    "button-options": _vm.dxButtonOptions.getButtonOptions()
                  }
                }),
                _c(
                  "DxGroupItem",
                  { attrs: { visible: _vm.dxButtonOptions._visible } },
                  _vm._l(_vm.expandableEditColumns, function(column) {
                    return _c("DxSimpleItem", {
                      key: "add_edit_" + column.field,
                      attrs: { "data-field": column.field }
                    })
                  }),
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "custom-toolbar-before",
            fn: function() {
              return [
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      "allow-create": "",
                      "default-first-option": "",
                      placeholder: "Choose Tag"
                    },
                    model: {
                      value: _vm.selectedTag,
                      callback: function($$v) {
                        _vm.selectedTag = $$v
                      },
                      expression: "selectedTag"
                    }
                  },
                  _vm._l(_vm.tags, function(tag) {
                    return _c("el-option", {
                      key: tag.ID,
                      attrs: { label: tag.TagName, value: tag.ID }
                    })
                  }),
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "custom-toolbar-after",
            fn: function() {
              return [
                _c(
                  "div",
                  [
                    _vm.selectedCustomers.length && _vm.canCreate
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.handleUpdateSendList }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("customers.updateSendList")) +
                                " "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.selectedCustomers.length && _vm.canCreate
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.handleCourseCampaignClick }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("courseCampaign.create.title")) +
                                " "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.selectedCustomers.length
                      ? _c(
                          "el-button",
                          { on: { click: _vm.handleAssignTagClick } },
                          [_vm._v(" " + _vm._s(_vm.$t("tag.title")) + " ")]
                        )
                      : _vm._e(),
                    _vm.selectedCustomers.length
                      ? _c(
                          "el-button",
                          { on: { click: _vm.handleUnAssignTagClick } },
                          [_vm._v(" " + _vm._s(_vm.$t("tag.unAssign")) + " ")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "custom-buttons",
            fn: function() {
              return [
                _c("DxButton", {
                  attrs: { icon: "info", "on-click": _vm.handleViewClick }
                }),
                _c("DxButton", {
                  attrs: {
                    icon: "user",
                    "on-click": _vm.handleImpersonateClick
                  }
                }),
                _vm.canView
                  ? _c("DxButton", {
                      attrs: {
                        icon: "revert",
                        "on-click": _vm.navigateToHistory
                      }
                    })
                  : _vm._e(),
                _c("DxButton", {
                  attrs: {
                    icon: "trash",
                    "on-click": _vm.handleSoftDeleteCustomer
                  }
                }),
                _c("DxButton", {
                  attrs: {
                    icon: "comment",
                    "on-click": _vm.handleShowChatHistory
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }