var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "locale-switcher" },
    [
      _c(
        "el-select",
        {
          staticClass: "select-box",
          attrs: { size: "mini" },
          on: { change: _vm.switchLocale },
          model: {
            value: _vm.selectedLocale,
            callback: function($$v) {
              _vm.selectedLocale = $$v
            },
            expression: "selectedLocale"
          }
        },
        _vm._l(_vm.locales, function(locale) {
          return _c("el-option", {
            key: locale.value,
            attrs: { value: locale.value, label: locale.label }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }