<template>
  <div class="locale-switcher">
    <el-select v-model="selectedLocale" size="mini" class="select-box" @change="switchLocale">
      <el-option
        v-for="locale in locales"
        :key="locale.value"
        :value="locale.value"
        :label="locale.label"
      />
    </el-select>
  </div>
</template>

<script>
import { MUTATION_TYPES } from '@/store/mutations'
import { createNamespacedHelpers } from 'vuex'
const { VUE_APP_I18N_LOCALE = 'sv' } = process.env
const { mapMutations: mapAppMutations } = createNamespacedHelpers('app')

export default {
  data() {
    return {
      selectedLocale: localStorage.getItem('locale') || VUE_APP_I18N_LOCALE,
      locales: [
        {
          label: 'English',
          value: 'en'
        },
        {
          label: 'Swedish',
          value: 'sv'
        }
      ]
    }
  },
  methods: {
    ...mapAppMutations({
      setLocale: MUTATION_TYPES.APP.SET_LOCALE
    }),
    switchLocale(event) {
      localStorage.setItem('locale', event)
      this.$i18n.locale = event
      this.setLocale(event)
    }
  }
}
</script>

<style scoped>
.select-box {
  width: 100%;
}
</style>
