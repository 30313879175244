import { fectchResource, createResource } from '@/utils/httpRequests'
import store from '@/store'

export default class FileService {
  static getAll(type = '') {
    return fectchResource(
      `${process.env.VUE_APP_CONTENTPART_API}/content/getcontents/${type}`
    )
  }

  static getContent(id, options = {}) {
    return fectchResource(
      `${process.env.VUE_APP_CONTENTPART_API}/content/getcontent?id=${id}`,
      options
    )
  }

  static getSharedFileContent(id, options = {}) {
    return fectchResource(
      `${process.env.VUE_APP_CONTENTPART_API}/content/getcontent/${id}/shared`,
      options
    )
  }

  static removeFile(id) {
    return fectchResource(
      `${process.env.VUE_APP_CONTENTPART_API}/content/setcontent/${id}/delete`
    )
  }
  static async upload({ formdata, type }) {
    return await createResource({
      url: `${process.env.VUE_APP_CONTENTPART_API}/content/sitefile?type=${type}`,
      data: formdata
    })
  }

  static async fileUploaderUpload({ type, data }) {
    let url = `${process.env.VUE_APP_CONTENTPART_API}/content/sitefile?type=${type}`
    if (type === store.getters.downloadable.pathWithFolder) {
      url = `${process.env.VUE_APP_CONTENTPART_API}/content/sitefilemeta?type=${type}`
    }

    return await createResource({ url, data })
  }
}
