var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Categories", {
    ref: "categoriesRef",
    attrs: {
      "create-columns": _vm.createColumns,
      "should-show-add-button": false,
      "should-show-edit-button": false,
      "should-show-delete-button": false,
      "should-show-site-dropdown": false,
      "should-show-toggle-button": true,
      "should-show-title": true,
      section: "programs",
      "section-for-sub-programs": "subprograms"
    },
    on: { filterPrograms: _vm.handleShowOfFilteredPrograms },
    scopedSlots: _vm._u([
      {
        key: "toggle-button",
        fn: function(ref) {
          var item = ref.data
          return [
            _c("el-switch", {
              attrs: { value: _vm.isProgramActive(item.row.data.ID) },
              on: {
                change: function($event) {
                  return _vm.toggleActivate(item.row.data.ID)
                }
              }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }