import CustomStore from 'devextreme/data/custom_store'
import { CustomStoreConfig } from './customStoreConfig'

export default class CustomDataStore extends CustomStore {
  constructor(option) {
    const customConfig = new CustomStoreConfig(option)
    super(customConfig)
    this.customConfig = customConfig
  }
}
