var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DxPopup",
        {
          attrs: {
            title: "Popup Title",
            "hide-on-outside-click": true,
            visible: _vm.isAddListDialogOpen
          }
        },
        [
          _c("div", { staticClass: "contentHeight" }, [
            _c(
              "div",
              { staticClass: "margin-bottom-sm flex-label-column" },
              [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("updateSendList.listName")))
                ]),
                _c(
                  "DxTextBox",
                  {
                    attrs: { placeholder: "Enter full name here..." },
                    model: {
                      value: _vm.popupListValue,
                      callback: function($$v) {
                        _vm.popupListValue = $$v
                      },
                      expression: "popupListValue"
                    }
                  },
                  [
                    _c(
                      "DxValidator",
                      [
                        _c("DxRequiredRule", {
                          attrs: {
                            message: _vm.$t("updateSendList.listNameAlert")
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "margin-bottom-sm" },
              [
                _c("el-checkbox", {
                  attrs: {
                    label: _vm.$t("updateSendList.checkbox"),
                    size: "mini"
                  },
                  nativeOn: {
                    input: function($event) {
                      return _vm.handleCheckbox.apply(null, arguments)
                    }
                  },
                  model: {
                    value: _vm.disabledExistingFolderField,
                    callback: function($$v) {
                      _vm.disabledExistingFolderField = $$v
                    },
                    expression: "disabledExistingFolderField"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "twoColumn" }, [
              _c(
                "div",
                { staticClass: "flex-label-column" },
                [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("updateSendList.folderName")))
                  ]),
                  _c(
                    "DxTextBox",
                    {
                      attrs: {
                        disabled: !_vm.disabledExistingFolderField,
                        placeholder: "Enter folder name here..."
                      },
                      model: {
                        value: _vm.popupFolderName,
                        callback: function($$v) {
                          _vm.popupFolderName = $$v
                        },
                        expression: "popupFolderName"
                      }
                    },
                    [
                      _c(
                        "DxValidator",
                        [
                          _vm.disabledExistingFolderField
                            ? _c("DxRequiredRule", {
                                attrs: {
                                  message: _vm.$t("updateSendList.folderCreate")
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex-label-column" },
                [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("updateSendList.selectFolder")))
                  ]),
                  _c(
                    "DxSelectBox",
                    {
                      attrs: {
                        disabled: _vm.disabledExistingFolderField,
                        "search-enabled": true,
                        "data-source": _vm.columns.addListColumns[0].lookup,
                        "display-expr":
                          _vm.columns.addListColumns[0].displayExpr,
                        "value-expr": _vm.columns.addListColumns[0].valueExpr
                      },
                      model: {
                        value: _vm.selectExistingFolderName,
                        callback: function($$v) {
                          _vm.selectExistingFolderName = $$v
                        },
                        expression: "selectExistingFolderName"
                      }
                    },
                    [
                      _c(
                        "DxValidator",
                        [
                          !_vm.disabledExistingFolderField
                            ? _c("DxRequiredRule", {
                                attrs: {
                                  message: _vm.$t("updateSendList.folderCreate")
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "alignButton" },
            [
              _c("DxButton", {
                attrs: { name: "save", text: "save" },
                on: { Click: _vm.handleFormDataSave }
              }),
              _c("DxButton", {
                attrs: { name: "cancel", text: "cancel" },
                on: { Click: _vm.handleFormClose }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "el-header",
        { attrs: { height: "auto" } },
        [
          _c(
            "el-row",
            { attrs: { type: "flex" } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("span", { staticClass: "heading" }, [
                  _vm._v(_vm._s(_vm.$t("updateSendList.title")))
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-main",
        { staticClass: "fields-section" },
        [
          _c(
            "el-form",
            {
              ref: "updateUserRef",
              attrs: { model: _vm.attributeFields, rules: _vm.rules }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 30 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "margin-bottom-sm",
                      attrs: { xs: 24, sm: 24, md: 6 }
                    },
                    [
                      _c(
                        "el-col",
                        { staticClass: "margin-bottom-md" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "margin-bottom-sm",
                              attrs: { disabled: true },
                              on: { click: _vm.handlePopUp }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("updateSendList.createSendList")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "margin-bottom-md" },
                        [
                          _c("el-row", { staticClass: "margin-bottom-sm" }, [
                            _vm._v(
                              _vm._s(_vm.$t("updateSendList.selectSendList"))
                            )
                          ]),
                          _c(
                            "el-select",
                            {
                              staticClass: "send-list",
                              attrs: {
                                filterable: "",
                                loading: !_vm.sendLists.length,
                                placeholder: _vm.$t(
                                  "updateSendList.selectSendList"
                                ),
                                size: "large"
                              },
                              model: {
                                value: _vm.sendListId,
                                callback: function($$v) {
                                  _vm.sendListId = $$v
                                },
                                expression: "sendListId"
                              }
                            },
                            _vm._l(_vm.sendLists, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          ),
                          !_vm.sendListId
                            ? _c(
                                "div",
                                { staticClass: "instruction margin-bottom-md" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("updateSendList.sendListSelect")
                                    )
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "margin-bottom-md" },
                        [
                          _c("el-row", { staticClass: "margin-bottom-sm" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("updateSendList.selectCustomerAccount")
                              )
                            )
                          ]),
                          _c(
                            "el-select",
                            {
                              staticClass: "send-list",
                              attrs: {
                                filterable: "",
                                loading: !_vm.customersAccounts.length,
                                placeholder: _vm.$t(
                                  "updateSendList.selectCustomerAccount"
                                ),
                                size: "large"
                              },
                              model: {
                                value: _vm.selectedCustomerAccount,
                                callback: function($$v) {
                                  _vm.selectedCustomerAccount = $$v
                                },
                                expression: "selectedCustomerAccount"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: "Select customers Accounts",
                                  value: null
                                }
                              }),
                              _vm._l(_vm.customersAccounts, function(account) {
                                return _c("el-option", {
                                  key: account.ID,
                                  attrs: {
                                    label: account.Account,
                                    value: account.ID
                                  }
                                })
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "margin-bottom-sm",
                      attrs: { xs: 24, sm: 24, md: 12 }
                    },
                    [
                      _c(
                        "el-col",
                        { staticClass: "label-add-attributes" },
                        [
                          _c("el-row", [
                            _vm._v(_vm._s(_vm.$t("addAttributes")))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "margin-bottom-md" },
                        [
                          _c("el-row", { staticClass: "margin-bottom-sm" }, [
                            _vm._v(_vm._s(_vm.$t("logo")))
                          ]),
                          _c("el-input", {
                            staticClass: "margin-bottom-sm",
                            attrs: {
                              name: "logo",
                              placeholder: _vm.$t("logo")
                            },
                            model: {
                              value: _vm.attributeFields.logo,
                              callback: function($$v) {
                                _vm.$set(_vm.attributeFields, "logo", $$v)
                              },
                              expression: "attributeFields.logo"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "margin-bottom-md" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("site"), prop: "site" } },
                            [
                              _c("el-input", {
                                staticClass: "margin-bottom-sm",
                                attrs: {
                                  name: "site",
                                  placeholder: _vm.$t("site")
                                },
                                model: {
                                  value: _vm.attributeFields.site,
                                  callback: function($$v) {
                                    _vm.$set(_vm.attributeFields, "site", $$v)
                                  },
                                  expression: "attributeFields.site"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "margin-bottom-md" },
                        [
                          _c("el-checkbox", {
                            attrs: {
                              name: "sso",
                              label: _vm.$t("sso"),
                              size: "mini",
                              border: true
                            },
                            model: {
                              value: _vm.attributeFields.sso,
                              callback: function($$v) {
                                _vm.$set(_vm.attributeFields, "sso", $$v)
                              },
                              expression: "attributeFields.sso"
                            }
                          }),
                          _c("el-checkbox", {
                            attrs: {
                              name: "utskick",
                              label: _vm.$t("updateSendList.utskick"),
                              size: "mini",
                              border: true
                            },
                            model: {
                              value: _vm.attributeFields.utskick,
                              callback: function($$v) {
                                _vm.$set(_vm.attributeFields, "utskick", $$v)
                              },
                              expression: "attributeFields.utskick"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "margin-bottom-md" },
                        [
                          _c(
                            "el-row",
                            { staticClass: "label-check-box" },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("updateSendList.utskick2"))
                                )
                              ]),
                              _c("el-checkbox", {
                                staticClass: "label-add-attributes-checkbox",
                                model: {
                                  value: _vm.includeUtskick2,
                                  callback: function($$v) {
                                    _vm.includeUtskick2 = $$v
                                  },
                                  expression: "includeUtskick2"
                                }
                              })
                            ],
                            1
                          ),
                          _c("el-input", {
                            attrs: {
                              disabled: !_vm.includeUtskick2,
                              name: "utskick2",
                              placeholder: _vm.$t("updateSendList.utskick2")
                            },
                            model: {
                              value: _vm.attributeFields.utskick2,
                              callback: function($$v) {
                                _vm.$set(_vm.attributeFields, "utskick2", $$v)
                              },
                              expression: "attributeFields.utskick2"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "margin-bottom-md" },
                        [
                          _c("el-row", { staticClass: "margin-bottom-sm" }, [
                            _vm._v(_vm._s(_vm.$t("signature")))
                          ]),
                          _c("el-input", {
                            staticClass: "textarea",
                            attrs: {
                              name: "signature",
                              type: "textarea",
                              autosize: { minRows: 3, maxRows: 5 },
                              placeholder: _vm.$t("signature")
                            },
                            model: {
                              value: _vm.attributeFields.signature,
                              callback: function($$v) {
                                _vm.$set(_vm.attributeFields, "signature", $$v)
                              },
                              expression: "attributeFields.signature"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "margin-bottom-md" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("updateSendList.shortSignature"),
                                prop: "signature"
                              }
                            },
                            [
                              _c("el-input", {
                                staticClass: "textarea",
                                attrs: {
                                  name: "shortSignature",
                                  type: "textarea",
                                  autosize: { minRows: 3, maxRows: 5 },
                                  placeholder: _vm.$t(
                                    "updateSendList.contactPerson"
                                  )
                                },
                                model: {
                                  value: _vm.attributeFields.shortSignature,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.attributeFields,
                                      "shortSignature",
                                      $$v
                                    )
                                  },
                                  expression: "attributeFields.shortSignature"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "margin-bottom-md" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "updateSendList.contactPersonPosition"
                                ),
                                prop: "contactPersonPosition"
                              }
                            },
                            [
                              _c("el-input", {
                                staticClass: "textarea",
                                attrs: {
                                  name: "contactPersonPosition",
                                  type: "textarea",
                                  autosize: { minRows: 3, maxRows: 5 },
                                  placeholder: _vm.$t(
                                    "updateSendList.contactPersonPosition"
                                  )
                                },
                                model: {
                                  value:
                                    _vm.attributeFields.contactPersonPosition,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.attributeFields,
                                      "contactPersonPosition",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "attributeFields.contactPersonPosition"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "center-div",
                      attrs: { xs: 24, sm: 24, md: 4 }
                    },
                    [
                      _c("div", { staticClass: "current-date" }, [
                        _c("div", { staticClass: "margin-bottom-sm" }, [
                          _c("span", [_vm._v(_vm._s(_vm.$t(_vm.label)))])
                        ]),
                        _c("div", [_vm._v(" " + _vm._s(_vm.currentDate) + " ")])
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: !_vm.sendListId,
                            loading: _vm.loadingButton
                          },
                          on: { click: _vm.handleUpdate }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }