import { Columns } from './columns'

export class DownloadableColumns extends Columns {
  static generateParams(columns, headings) {
    const columnsToBeUpdated = headings.filterByValueAndNonIdField()

    return {
      ...(columnsToBeUpdated.includes('MediaId') && {
        MediaID: parseInt(columns[headings.findLabelByValue('MediaId')])
      }),
      ...(columnsToBeUpdated.includes('FileName') && {
        FileName: columns[headings.findLabelByValue('FileName')]
      }),
      ...(columnsToBeUpdated.includes('Description') && {
        Description: columns[headings.findLabelByValue('Description')]
      })
    }
  }

  static setColumnValues(headings, value, index) {
    const existingHeading = headings.findLabelByValue(value)
    if (existingHeading) {
      const existingColumnLabel = headings.findByLabel(existingHeading)
      existingColumnLabel.value = ''
    }

    if (headings.options[index]) {
      headings.options[index].value = value
    }
  }
}
