export const grapesJsConfig = {
  container: '#main-container',
  height: '900px',
  width: '100%',
  canvas: {
    styles: [
      '/app.effaba9.css',
      '/template-styles.css',
      'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
      '/editor-styles.css'
    ]
  },
  blockManager: {
    custom: true
  }
}
