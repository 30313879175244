import Store from '@/models/odataStore'
export const idField = 'ID'
export const dataAddedColumn = 'DataAdded'
const guidField = 'MediaGUID'
export const ishiddenField = 'HideEpisode'
export const deactivateAtDateField = 'DeactivateAtDate'
export const disabledColumns = [idField, dataAddedColumn, guidField]
const toggleColumns = ['bAllowExtPlay']
const nameColumns = ['Title']
const fileTypeColumns = ['MedImage']
const siteIdColumn = 'SiteID'
const categoryIdColumn = 'MainCategoryID'
export const MEDIAS = {
  FOLDER: 'content'
}
export const assistantIdField = 'AssistantID'

export const createColumns = ({ assistants }) => {
  const infoColumns = [
    ...nameColumns,
    'ShortTitle',
    categoryIdColumn,
    assistantIdField,
    'Author',
    'AuthorID',
    'Length',
    siteIdColumn,
    'ShortDescription',
    'LongDescription',
    ...fileTypeColumns,
    'FileName_flash',
    'demoClipStart',
    'demoClipLength',
    'IsActive',
    dataAddedColumn
  ].map(field => ({
    field,
    width: 110,
    ...(nameColumns.includes(field) && {
      required: true,
      requiredMessage: 'medias.column.title.requiredMessage'
    }),
    ...(fileTypeColumns.includes(field) && {
      editCellTemplate: 'file-item',
      includeFileNameOnly: true,
      type: MEDIAS.FOLDER
    }),
    ...(field === dataAddedColumn && {
      dataType: 'date'
    }),
    ...(field === assistantIdField && {
      label: 'medias.column.AssistantID',
      visible: false,
      lookup: assistants
    }),
    ...(field === siteIdColumn && {
      label: 'site',
      lookup: {
        store: new Store({ resource: 'Sites' }),
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'ID',
      displayExpr: 'SiteName'
    }),
    ...(field === categoryIdColumn && {
      label: 'mainCategory',
      lookup: {
        store: new Store({ resource: 'Categories' }),
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'ID',
      displayExpr: 'Name'
    })
  }))

  const hiddenInfoColumns = [
    'KeyWords',
    'SearchColumn',
    'Summary',
    'SummaryFilename',
    'FileName_sound',
    guidField,
    'MoreInformation',
    'MoreInformationLink',
    ...toggleColumns,
    ishiddenField,
    deactivateAtDateField
  ].map(field => ({
    field,
    width: 110,
    ...(field === ishiddenField && {
      label: 'isHidden',
      dataType: 'boolean'
    }),
    ...(field === deactivateAtDateField && {
      dataType: 'date'
    })
  }))

  const metaColumns = [
    {
      field: 'RawText',
      width: '100%'
    },
    {
      field: 'Transcription',
      width: '100%'
    }
  ]

  const columnsForAdd = [...infoColumns, ...metaColumns, ...hiddenInfoColumns]
  const columns = {
    infoColumns,
    metaColumns,
    hiddenInfoColumns,
    allColumns: [
      {
        field: idField,
        label: 'MediaID',
        width: 110,
        sort: 'desc'
      },
      ...columnsForAdd
    ]
  }
  return columns
}
