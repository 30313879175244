class Downloadable {
  constructor(downloadable) {
    this.default = 'media'
    this.base = 'media'
    this.olderPath = 'arbetsmiljo/avsnitt1'
    this.folder = 'media'
    this.disableFolderField = false
    this.folderName = ''
    this.id = 0
    this.selectedFile = undefined
    this.fileUploaded = false
    this.fileUploadedFolderName = ''
    this.filePath = ''
    this.fileUrl = ''
    this.hidePreview = false

    Object.assign(this, downloadable)
  }

  get oldFolderPath() {
    return this.olderPath.split('/')[1]
  }

  get pathWithFolder() {
    return `/${this.folder}`
  }

  get fileNameUpdate() {
    if (this.fileUploadedFolderName && !this.selectedFile.length) {
      return `${this.fileUploadedFolderName}/`
    }
    return this.folder ? `${this.folder}/` : `${this.folder}`
  }

  get updateFile() {
    const hasFileSelected = Array.isArray(this.selectedFile)
      ? this.selectedFile.length > 0
      : typeof this.selectedFile === 'string' &&
        this.selectedFile.trim() !== ''

    return this.id !== 0 && hasFileSelected
  }

  updatePartial(params = {}) {
    Object.assign(this, params)
  }

  createCustomFolderName() {
    return this.folder
      .split('/')
      .slice(1)
      .join('_')
  }

  setFolderNameToOld() {
    this.updatePartial({ folderName: this.oldFolderPath })
  }

  disableFields() {
    this.updatePartial({ disableFolderField: true })
  }

  reset() {
    this.default = 'media'
    this.BASE = 'media'
    this.olderPath = 'arbetsmiljo/avsnitt1'
    this.folder = 'media'
    this.disableFolderField = false
    this.folderName = ''
    this.id = 0
    this.selectedFile = undefined
    this.fileUploaded = false
    this.filePath = ''
    this.fileUrl = ''
    this.hidePreview = false
  }
}

export default Downloadable
