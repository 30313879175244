import { fectchResource, patchResource } from '@/utils/httpRequests'

export default class ExternalMediaService {
  static getAll() {
    return fectchResource(`${process.env.VUE_APP_ODATA_API}/ExternalMedia`)
  }

  static update(data, id) {
    return patchResource({
      url: `${process.env.VUE_APP_ODATA_API}/ExternalMedia(${id})`,
      data
    })
  }
}
