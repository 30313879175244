<template>
  <div class="column-flex">
    <span class="margin-left-md">
      <el-checkbox v-model="isNoDeactivationEnabled" class="no-deactivation">{{
        $t("manageUsers.deactivateUser.noDeactivation")
      }}</el-checkbox>
      {{ $t("manageUsers.deactivateUser.absentUsersCount") }}
      {{ `: ${absentUsers.length}` }}
    </span>
    <div class="margin-left-md table">
      <el-table
        ref="deactivateCustomers"
        :data="absentUsers"
        height="250"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          v-for="column in headings"
          :key="column.value"
          :label="column.label"
          :prop="column.value"
          :width="150"
          :show-overflow-tooltip="true"
          class="overflow-wrap"
        />
      </el-table>
    </div>
    <div class="action-btn">
      <el-button
        :disabled="!selectedUsers.length"
        :loading="isLoading && hasDeactivateUserBeenClicked"
        type="primary"
        class="margin-left-md self-start"
        @click="handleDeactivateClick"
      >{{ $t("manageUsers.deactivateUser.stepLabel") }}</el-button>
      <el-button
        :loading="isLoading && !hasDeactivateUserBeenClicked"
        type="primary"
        class="margin-left-md self-start"
        @click="handleGenerateReportClick"
      >{{ $t("manageUsers.matchField.generateReport") }}</el-button>
    </div>
  </div>
</template>
<script>
import { nextClickEvent } from './config'
import { createNamespacedHelpers } from 'vuex'
import { MUTATION_TYPES } from '@/store/mutations'

const { mapState, mapMutations } =
  createNamespacedHelpers('user/importedUsers')
export default {
  name: 'DeactivateUser',

  data() {
    return {
      selectedUsers: [],
      hasDeactivateUserBeenClicked: false
    }
  },
  computed: {
    ...mapState({
      absentUsers: (state) => state.absentUsers.importedUsers,
      headings: (state) => state.absentUsers.headings.absentUsersSortedColumns(),
      isLoading: (state) => state.isLoading,
      defaultSelectedUsers: (state) =>
        state.absentUsers.filterSelectedUserForDeactivation(
          state.isNoDeactivationEnabled
        )
    }),
    isNoDeactivationEnabled: {
      get: function() {
        return this.$store.state.user.importedUsers.isNoDeactivationEnabled
      },
      set: function() {
        this.toggleNoDeactivation()
        this.selectDefaultSelectedUsers()
      }
    }
  },
  mounted() {
    this.selectDefaultSelectedUsers()
  },
  methods: {
    ...mapMutations({
      toggleNoDeactivation:
        MUTATION_TYPES.USER.IMPORTED_USERS.TOGGLE_NO_DEACTIVATION
    }),
    handleGenerateReportClick() {
      this.hasDeactivateUserBeenClicked = false
      this.$emit(nextClickEvent)
    },
    handleSelectionChange(val) {
      this.selectedUsers = val
    },
    handleDeactivateClick() {
      this.hasDeactivateUserBeenClicked = true
      this.$emit(nextClickEvent, this.selectedUsers)
    },

    selectDefaultSelectedUsers() {
      this.$refs.deactivateCustomers.clearSelection()
      this.defaultSelectedUsers.forEach((defaultSelectedUser) =>
        this.$refs.deactivateCustomers.toggleRowSelection(defaultSelectedUser)
      )
    }
  }
}
</script>

<style lang="scss" scoped>
$base-spacing: 5px;
.table {
  width: 70vw;
  overflow: scroll;
}
.margin-left-md {
  margin: $base-spacing * 5;
}

.self-start {
  align-self: flex-start;
}
.action-btn {
  display: flex;
  align-items: center;
}

.column-flex {
  display: flex;
  flex-direction: column;
}
.no-deactivation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
