export const mergeLists = (oldList, newList, refField) => {
  const newDistinctItems = new Set(
    newList.map(item => item[refField]).filter(pos => pos)
  )

  const existingItems = oldList.map(i => i[refField])
  const newUniqueItems = Array.from(newDistinctItems).filter(
    pos => existingItems.indexOf(pos) === -1
  )
  return [
    ...oldList,
    ...newUniqueItems.map(item => ({ ID: item, [refField]: item }))
  ]
}

export const compareFilters = ({ targetFilters = [], sourceFilters = [] }) =>
  targetFilters.length === sourceFilters.length &&
  sourceFilters?.every((filter, index) =>
    Array.isArray(filter)
      ? compareFilters({
        sourceFilters: filter,
        targetFilters: targetFilters?.[index]
      })
      : filter === targetFilters?.[index]
  )

export const findLookUpValue = ({
  items,
  compareField,
  returnField,
  compareValue
}) => {
  return items.find(item => item[compareField] === compareValue)?.[returnField]
}
