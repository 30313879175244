export class Tag {
  ID;

  IsActive;

  TagName;

  constructor(tag) {
    Object.assign(this, tag)
  }

  get option() {
    return {
      label: this.TagName,
      value: this.ID
    }
  }

  isEqual(ID) {
    return this.ID === ID
  }

  createParamsForCustomersAssign(customerIds = []) {
    return customerIds.map(CustomerID => ({
      CustomerID,
      TagID: this.ID,
      IsActive: this.IsActive
    }))
  }

  static fromList(tags = []) {
    return tags.map(tag => new Tag(tag))
  }
}
