var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("UserDetail", {
        attrs: {
          "selected-customer": _vm.selectedCustomer,
          "assigned-tags": _vm.assignedTags,
          sites: _vm.sites,
          "show-details": _vm.showDetails
        },
        on: { detailsDialogClose: _vm.handleDetailsDialogClose }
      }),
      _c(
        "el-header",
        [
          _c(
            "el-row",
            { attrs: { type: "flex" } },
            [
              _c("el-col", { staticClass: "left-align" }, [
                _c("span", { staticClass: "heading" }, [
                  _vm._v(_vm._s(_vm.$t("shared.title")))
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "DxFileManager",
        {
          attrs: {
            "file-system-provider": _vm.fileSystemProvider,
            "on-selection-changed": _vm.handleSelectionChanged
          },
          scopedSlots: _vm._u([
            {
              key: "uploaded-user",
              fn: function() {
                return [
                  _vm.showUploadUserIcon
                    ? _c("DxButton", {
                        attrs: {
                          icon: "card",
                          text: "UploadedUser",
                          "on-click": _vm.openPopupWithCustomerDetails
                        }
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("DxPermissions", {
            attrs: {
              download: _vm.showDownloadButton,
              delete: true,
              upload: true
            }
          }),
          _c(
            "DxToolbar",
            [
              _c("DxItem", { attrs: { name: "showNavPane", visible: true } }),
              _c("DxItem", { attrs: { name: "separator" } }),
              _c("DxItem", { attrs: { name: "upload" } }),
              _c("DxFileSelectionItem", {
                attrs: { name: "showNavPane", visible: true }
              }),
              _c("DxFileSelectionItem", { attrs: { name: "separator" } }),
              _c("DxFileSelectionItem", { attrs: { name: "download" } }),
              _c("DxFileSelectionItem", { attrs: { name: "separator" } }),
              _c("DxFileSelectionItem", { attrs: { name: "delete" } }),
              _c("DxFileSelectionItem", {
                attrs: { name: "card", template: "uploaded-user" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }