var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ODataTable", {
    ref: "oDataTableComponentRef",
    attrs: {
      columns: _vm.columns.all,
      "add-edit-columns": _vm.columns.addEdit,
      resource: "tDisabledMediaAccount",
      "total-label": _vm.$t("disabledMediaAccounts.total"),
      "file-name": _vm.$t("disabledMediaAccounts.fileName"),
      "add-row-button-label": _vm.$t("disabledMediaAccounts.add"),
      title: _vm.$t("disabledMediaAccounts.title")
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }