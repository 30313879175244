import { DateUtils } from '@/utils/date'
export const dateAddedField = 'AddedDate'
export const dateColumns = [
  {
    field: dateAddedField,
    width: '100%',
    dataType: 'date',
    format: DateUtils.odataDateFormat,
    sort: 'desc',
    label: 'tags.column.addedDate.label'
  }
]
const tagNameField = 'TagName'
const toggleField = 'IsActive'
export const addEditColumns = [
  ...[tagNameField, toggleField].map(field => ({
    field,
    width: '100%',
    ...(field === tagNameField && {
      label: 'tags.column.tagName.label',
      required: true,
      requiredMessage: 'tags.column.tagName.requiredMessage'
    }),
    ...(field === toggleField && {
      label: 'tags.column.isActive.label'
    })
  })),
  ...dateColumns
]

export const columns = [{ field: 'ID', width: '100%' }, ...addEditColumns]
