/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor', 'dev@codinova.com']
  return valid_map.indexOf(str.trim()) >= 0
}

export function hasKey(object, key) {
  if (object && typeof object === 'object') {
    return Object.prototype.hasOwnProperty.call(object, key)
  }
  throw new Error('Not a valid object.')
}
