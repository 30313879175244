<template>
  <ODataTable
    ref="oDataTableComponentRef"
    :columns="columns.all"
    :add-edit-columns="columns.addEdit"
    resource="MediaByCategory"
    :total-label="$t('mediasByCategory.total')"
    :file-name="$t('mediasByCategory.fileName')"
    :add-row-button-label="$t(addRowButtonLabel)"
    :title="$t(title)"
    :filter="filter"
    :has-parent="!shouldShowTitle"
    :should-show-add-button="shouldShowAddButton"
    :should-show-edit-button="shouldShowEditButton"
    :should-show-delete-button="shouldShowDeleteButton"
    @rowInit="handleRowInit"
  >
    <template #toggle-button="{ data }">
      <slot name="toggle-button" :data="data" />
    </template>
    <template v-if="shouldShowToggleButton" #custom-buttons>
      <DxButton template="custom-button-template" />
    </template>
  </ODataTable>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const {
  mapGetters: mapAppGetters
} = createNamespacedHelpers('app')
const {
  mapState: mapContentPartState
} = createNamespacedHelpers('contentPart')
import ODataTable from '../../components/ODataTable'
import { DxButton } from 'devextreme-vue/data-grid'
import { createColumns } from './config'

export default {
  name: 'MediaByCategory',
  components: {
    ODataTable,
    DxButton
  },
  props: {
    title: {
      type: String,
      default: 'mediasByCategory.title'
    },
    addRowButtonLabel: {
      type: String,
      default: 'mediasByCategory.add'
    },
    categoryId: {
      type: Number,
      default: null
    },
    siteId: {
      type: Number,
      default: null
    },
    parentCategoryId: {
      type: Number,
      default: null
    },
    shouldShowToggleButton: {
      type: Boolean,
      default: false
    },
    shouldShowAddButton: {
      type: Boolean,
      default: true
    },
    shouldShowEditButton: {
      type: Boolean,
      default: true
    },
    shouldShowDeleteButton: {
      type: Boolean,
      default: true
    },
    shouldShowTitle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapAppGetters(['findMediaById']),
    ...mapContentPartState({
      sites: state => state.sites.map(site => site.toOption)
    }),
    columns() {
      return createColumns({
        medias: this.medias,
        sites: this.sites,
        siteId: this.siteId
      })
    },
    filter() {
      return !this.parentCategoryId ? ['CategoryID', '=', this.categoryId] : ['SubCategoryID', '=', this.categoryId]
    }
  },
  methods: {
    handleRowInit(event) {
      if (this.categoryId) {
        event.data.CategoryID = this.categoryId
        if (this.parentCategoryId) {
          event.data.SubCategoryID = this.categoryId
          event.data.CategoryID = this.parentCategoryId
        }
      }
    }
  }
}
</script>

