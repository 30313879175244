import router from './router'
import store from './store'
import { Message } from 'element-ui'

import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
import i18n from '@/i18n'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/forgot-password'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  const title = i18n.t(to.meta.title)

  document.title = getPageTitle(title)

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      const hasGetUserInfo = store.getters.name
      if (hasGetUserInfo) {
        next()
      } else {
        try {
          // get user info
          await store.dispatch('user/getInfo')

          next()
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')

          Message.error(error || 'Has Error')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

const whitelistedRoutes = [
  '/manage/admins',
  '/manage/menus',
  '/404',
  '/login',
  '/forgot-password'
]
router.beforeEach((to, from, next) => {
  let path = to.path

  Object.keys(to.params).forEach(key => {
    path = path.replace(to.params[key], `:${key}`)
  })

  if (whitelistedRoutes.includes(path)) {
    return next()
  }
  if (store.state.user.user.canAccess(path)) {
    return next()
  }

  return next('/404')
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
