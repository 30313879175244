import { Answer } from './answer'
import { TEST_TYPE } from './test'

const defaultAnswers = [
  { order: 1, isCorrect: true },
  { order: 2 },
  { order: 3 }
]

export class Question {
  answers;

  correctAnswerIndex;

  createdAt;

  id;

  isActive;

  isFreeText;

  name;

  order;

  testId;

  testSectionId;
  constructor({
    name = '',
    answers = defaultAnswers,
    createdAt = new Date(),
    isActive = true,
    testId = '',
    testSectionId = '',
    id = '',
    correctAnswerIndex = 0,
    isFreeText = false,
    order = 0
  }) {
    this.name = name

    this.answers = isFreeText ? [] : Answer.fromList(answers)
    this.createdAt = createdAt
    this.isActive = isActive
    this.testId = testId
    this.testSectionId = testSectionId
    this.id = id
    this.correctAnswerIndex = correctAnswerIndex
    this.isFreeText = isFreeText
    this.order = order
  }

  get isProvided() {
    return !!this.name
  }

  get haveProvidedAnswers() {
    return this.answers.every(answer => answer.isProvided)
  }

  static fromList(questions = []) {
    return Question.sortQuestions(
      questions.map(question => new Question(question))
    )
  }

  markCorrectAnswer(indexToBeUpdated) {
    this.correctAnswerIndex = indexToBeUpdated

    this.answers = this.answers.map((answer, index) => {
      answer.setCorrect(indexToBeUpdated === index)
      return answer
    })
  }

  updatePartial(updateParams) {
    Object.assign(this, updateParams)
  }

  setQuestionIdForAnswers(id) {
    this.id = id

    this.answers.forEach(answer => answer.updatePartial({ questionId: id }))
  }
  get payload() {
    return {
      Name: this.name,
      DateAdded: this.createdAt,
      bCorrectAnswerRequired: !this.isFreeText,
      IsActive: this.isActive,
      bIsFreetext: this.isFreeText,
      tTestID: this.testId,
      tTestSectionID: this.testSectionId,
      SortOrder: this.order
    }
  }

  static fromApi(question) {
    return new Question({
      id: question.ID,
      name: question.Name,
      testId: question.tTestID,
      testSectionId: question.tTestSectionID,
      isActive: question.IsActive,
      createdAt: question.DateAdded,
      isFreeText: question.bIsFreetext,
      order: question.SortOrder
    })
  }
  static fromApiList(questions = []) {
    return Question.sortQuestions(
      questions.map(question => this.fromApi(question))
    )
  }

  setAnswers(answers = []) {
    this.answers = Answer.fromApiList(answers).sort(
      (firstAnswer, secondAnswer) => firstAnswer.order - secondAnswer.order
    )
    this.correctAnswerIndex = this.answers.findIndex(
      answer => answer.isCorrect
    )
  }

  compare(id) {
    return this.id === id
  }

  isCorrectAnswerIndex(index) {
    return this.correctAnswerIndex === index
  }

  setCorrectAnswerIndexAfterSort(sourceIndex, targetIndex) {
    if (this.isCorrectAnswerIndex(sourceIndex)) {
      this.correctAnswerIndex = targetIndex
    } else if (this.isCorrectAnswerIndex(targetIndex)) {
      this.correctAnswerIndex = sourceIndex
    }

    this.answers.forEach((answer, index) => {
      answer.updatePartial({
        order: index + 1,
        isCorrect: this.isCorrectAnswerIndex(index)
      })
    })
  }

  sortAnswers(sourceIndex, targetIndex) {
    const sortedAnswers = [...this.answers]
    sortedAnswers[targetIndex] = this.answers[sourceIndex]
    sortedAnswers[sourceIndex] = this.answers[targetIndex]
    this.answers = sortedAnswers
    this.setCorrectAnswerIndexAfterSort(sourceIndex, targetIndex)
  }

  checkIfDownSortAllowedForIndex(index) {
    return index !== this.answers.length - 1
  }

  setFreeText(isFreeText) {
    this.isFreeText = isFreeText
    if (this.isFreeText) {
      this.answers = []
    } else {
      this.answers = Answer.fromList(defaultAnswers)
    }
  }

  static get types() {
    return [
      {
        value: '',
        label: 'test.create.chooseType.type.all'
      },
      ...Object.values(TEST_TYPE).map(value => ({
        value,
        label: `test.create.chooseType.type.${value}`
      }))
    ]
  }

  static getFiltersByQuestionType(questionType) {
    if (this.isMcqType(questionType)) {
      return ['AnswerText', '=', null]
    }
    if (this.isReflectionType(questionType)) {
      return ['AnswerText', 'ne', null]
    }

    return []
  }

  static isMcqType(questionType) {
    return questionType === TEST_TYPE.MCQ
  }

  static isReflectionType(questionType) {
    return questionType === TEST_TYPE.REFLECTION
  }

  static sortQuestions(questions) {
    return questions.sort(
      (question, nextQuestion) => question.order - nextQuestion.order
    )
  }
}
