var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.selectedReflection
        ? _c(
            "el-dialog",
            {
              attrs: { visible: _vm.showDetails },
              on: {
                "update:visible": function($event) {
                  _vm.showDetails = $event
                },
                close: _vm.handleDetailsDialogClose
              }
            },
            [
              _c("Detail", {
                attrs: { detail: Object.assign({}, _vm.selectedReflection) }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("reflections.reportFilter"),
            visible: _vm.isReportsFilterDialogOpen,
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              _vm.isReportsFilterDialogOpen = $event
            },
            close: _vm.handleReportsDetailsDialogClose
          }
        },
        [
          _c("ReportFilters", {
            on: {
              filter: _vm.handleFilterApply,
              closeFilter: _vm.handlerCloseReportFilter
            }
          })
        ],
        1
      ),
      _c("ODataTable", {
        key: _vm.filter,
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns,
          "add-edit-columns": _vm.addEditColumns,
          "alias-resource": _vm.aliasResource,
          resource: "tTestCustomerAnswerPortal",
          "total-label": _vm.$t("reflections.total"),
          "should-show-edit-button": false,
          "should-show-add-button": false,
          "file-name": _vm.$t("reflections.fileName"),
          "add-row-button-label": _vm.$t("reflections.add"),
          title: _vm.$t("reflections.title"),
          "action-buttons-width": 100,
          filter: _vm.filter
        },
        on: {
          selectionChange: _vm.handleSelectionChange,
          rowInit: _vm.handleRowInit,
          rowUpdating: _vm.handleRowUpdating
        },
        scopedSlots: _vm._u([
          {
            key: "custom-buttons",
            fn: function() {
              return [
                _c("DxButton", {
                  attrs: { icon: "check", visible: _vm.isApproved }
                }),
                _c("DxButton", {
                  attrs: { icon: "remove", visible: _vm.isRejected }
                }),
                _c("DxButton", {
                  attrs: { icon: "help", visible: _vm.hasNotBeenReviewed }
                }),
                _c("DxButton", {
                  attrs: { icon: "info", "on-click": _vm.handleViewClick }
                })
              ]
            },
            proxy: true
          },
          {
            key: "custom-toolbar-before",
            fn: function() {
              return [
                _c(
                  "div",
                  [
                    _c("el-switch", {
                      attrs: { "active-text": _vm.$t("showOnlyShared") },
                      on: { change: _vm.handlerReportsFilter },
                      model: {
                        value: _vm.reviewModeEnabled,
                        callback: function($$v) {
                          _vm.reviewModeEnabled = $$v
                        },
                        expression: "reviewModeEnabled"
                      }
                    }),
                    _vm.filters.reviewModeEnabled &&
                    _vm.selectedReflectionIds.length &&
                    _vm.canReview
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: _vm.defaultType, plain: "" },
                            on: { click: _vm.redirectToReviewPage }
                          },
                          [_vm._v(_vm._s(_vm.$t("review")))]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: _vm.defaultType, plain: "" },
                        on: { click: _vm.handleReportsDetailsDialogOpen }
                      },
                      [_vm._v(_vm._s(_vm.$t("report")))]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }