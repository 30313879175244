import i18n from '@/i18n'
import { Message } from 'element-ui'
import { Column } from './column'
import { ImportedDownloadables } from './importedDownloadables'

export class DownloadableColumn extends Column {
  static isIdOrReferenceField(column) {
    return ['ID', 'MediaId'].includes(column)
  }

  static isDescription(column) {
    return column === 'Description'
  }

  static isFileName(column) {
    return column === 'FileName'
  }

  static idColumn(headings) {
    return headings.findLabelByValue('ID')
  }

  static isAnyColumnSelected(value) {
    return (
      this.isIdOrReferenceField(value) ||
      this.isDescription(value) ||
      this.isFileName(value)
    )
  }

  static handleFieldSelect(column, value, importedDownloadables) {
    if (this.isIdOrReferenceField(value)) {
      if (!ImportedDownloadables.validateMedia(column, importedDownloadables)) {
        Message({
          dangerouslyUseHTMLString: true,
          message: `<p class="text-error">${i18n.t(
            'manageDownloadables.matchField.idValidation'
          )}</p>`
        })
        return false
      }
      return true
    }
    return true
  }
}
