var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ODataTable", {
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns.lists,
          "add-edit-columns": _vm.columns.addEdit,
          "order-by": _vm.orderBy,
          resource: "debug",
          "action-buttons-width": 100,
          "total-label": _vm.$t("debug.total"),
          "file-name": _vm.$t("debug.fileName"),
          title: _vm.$t("debug.title"),
          "should-show-add-button": false,
          "should-show-edit-button": false
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }