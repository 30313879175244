<template>
  <ODataTable
    ref="oDataTableComponentRef"
    :columns="columns.all"
    :add-edit-columns="columns.addEdit"
    resource="tDisabledMediaAccount"
    :total-label="$t('disabledMediaAccounts.total')"
    :file-name="$t('disabledMediaAccounts.fileName')"
    :add-row-button-label="$t('disabledMediaAccounts.add')"
    :title="$t('disabledMediaAccounts.title')"
  />
</template>

<script>
import ODataTable from '../../components/ODataTable'
import { createColumns } from './config'

export default {
  name: 'DisabledMediaAccounts',
  components: {
    ODataTable
  },
  computed: {
    columns() {
      return createColumns()
    }
  }
}
</script>
