<template>
  <div class="app-container">
    <el-dialog
      v-if="selectedCustomer"
      :visible.sync="showDetails"
      :title="selectedCustomerName"
      @close="handleDetailsDialogClose"
    >
      <Details :detail="{ ...selectedCustomer, assignedTags, sites }" />
    </el-dialog>
    <el-dialog :visible.sync="isTagDialogOpen" @close="handleTagDialogClose">
      <TagUsers
        :users="selectedCustomers"
        @tagAssigned="handleTagDialogClose"
      />
    </el-dialog>
    <ODataTable
      :key="selectedTag"
      ref="customersRef"
      :columns="columns"
      :add-edit-columns="addEditColumns"
      :should-show-delete-button="false"
      resource="customers"
      :is-custom-form="true"
      :total-label="$t('customers.total')"
      :file-name="$t('customers.fileName')"
      :title="$t('customers.title')"
      :should-show-add-button="false"
      :action-buttons-width="180"
      :alias-resource="aliasResource"
      :is-state-stored="isStateStored"
      @rowInit="handleRowInit"
      @editorPreparing="handleEditorPreparing"
      @selectionChange="handleSelectionChange"
      @clearFilters="handleFiltersClear"
      @contentReady="handleContentReady"
    >
      <template #custom-form>
        <DxSimpleItem
          v-for="column in visibleEditColumns"
          :key="`add_edit_${column.field}`"
          :data-field="column.field"
          :visible="true"
        />
        <DxButtonItem
          :button-options="dxButtonOptions.getButtonOptions()"
        />
        <DxGroupItem :visible="dxButtonOptions._visible">
          <DxSimpleItem
            v-for="column in expandableEditColumns"
            :key="`add_edit_${column.field}`"
            :data-field="column.field"
          />
        </DxGroupItem>
      </template>
      <template #custom-toolbar-before>
        <el-select
          v-model="selectedTag"
          filterable
          allow-create
          default-first-option
          placeholder="Choose Tag"
        >
          <el-option
            v-for="tag in tags"
            :key="tag.ID"
            :label="tag.TagName"
            :value="tag.ID"
          />
        </el-select>
      </template>
      <template #custom-toolbar-after>
        <div>
          <el-button
            v-if="selectedCustomers.length && canCreate"
            type="primary"
            @click="handleUpdateSendList"
          >
            {{ $t("customers.updateSendList") }}
          </el-button>
          <el-button
            v-if="selectedCustomers.length && canCreate"
            type="primary"
            @click="handleCourseCampaignClick"
          >
            {{ $t("courseCampaign.create.title") }}
          </el-button>
          <el-button
            v-if="selectedCustomers.length"
            @click="handleAssignTagClick"
          >
            {{ $t("tag.title") }}
          </el-button>
          <el-button
            v-if="selectedCustomers.length"
            @click="handleUnAssignTagClick"
          >
            {{ $t("tag.unAssign") }}
          </el-button>
        </div>
      </template>
      <template #custom-buttons>
        <DxButton icon="info" :on-click="handleViewClick" />
        <DxButton icon="user" :on-click="handleImpersonateClick" />
        <DxButton v-if="canView" icon="revert" :on-click="navigateToHistory" />
        <DxButton icon="trash" :on-click="handleSoftDeleteCustomer" />
        <DxButton icon="comment" :on-click="handleShowChatHistory" />
      </template>
    </ODataTable>
  </div>
</template>
<script>
const campaignRoute = '/users/course-campaign/new'
const historyRoutePath = '/users/customers/:id/histories'
const updateSendListRoute = 'users/update-send-list'
import { createNamespacedHelpers } from 'vuex'
import Details from './detail'
const { mapState, mapMutations } = createNamespacedHelpers('courseCampaign')
const { mapActions: mapCustomerActions, mapState: mapCustomerState, mapMutations: mapCustomerMutations } =
  createNamespacedHelpers('customer')
import { MUTATION_TYPES } from '@/store/mutations'
import { DxSimpleItem, DxButtonItem, DxGroupItem } from 'devextreme-vue/form'
import { columns, addEditColumns, LOG_ACTION, tableName, visibleEditColumns, expandableEditColumns, dateColumns } from './config'
import { DxButton } from 'devextreme-vue/data-grid'
import TagUsers from '../../components/tagUsers/index.vue'
import ODataTable from '../../components/ODataTable'
import odataTableEventsMixin from '@/mixin/odataTableEventsMixin'
import CustomerService from '@/api/customer'
import { Customer } from '@/models/customer'
import { DxButtonItemOptions } from '@/models/DxButtonItemOptions'
const { mapActions: mapTagActions, mapState: mapTagState } =
  createNamespacedHelpers('tags')
const { mapState: mapUserState } =
  createNamespacedHelpers('user')
const { mapState: mapContentPartState } =
  createNamespacedHelpers('contentPart')
const { mapMutations: mapAppMutations } =
  createNamespacedHelpers('app')
import { confirm } from 'devextreme/ui/dialog'

export default {
  name: 'Customers',
  components: {
    ODataTable,
    DxButton,
    Details,
    TagUsers,
    DxSimpleItem,
    DxButtonItem,
    DxGroupItem
  },
  mixins: [odataTableEventsMixin],
  data() {
    return {
      showDetails: false,
      selectedCustomer: null,
      isTagDialogOpen: false,
      columns,
      addEditColumns,
      visibleEditColumns,
      expandableEditColumns,
      disabledColumns: dateColumns,
      isStateStored: true,
      selectedRowsData: [],
      areTagsAssignedToAnySelectedCustomer: false,
      dxButtonOptions: new DxButtonItemOptions()
    }
  },
  computed: {
    ...mapState({
      selectedCustomers: (state) => state.selectedCustomers.Ids,
      customers: (state) => state.selectedCustomers
    }),
    ...mapTagState({
      tags: (state) => state.tags.filter(tag => tag.ID)
    }),
    selectedCustomerName() {
      return [this.selectedCustomer?.FirstName, this.selectedCustomer?.LastName]
        .filter((item) => !!item)
        .join(' ')
    },
    filters() {
      return this.$refs.customersRef?.$refs?.oDataTableRef?.filters
    },
    aliasResource() {
      if (!this.selectedTag) {
        return ''
      }
      return `UserTag/GetCustomerForUserTagID(tagID=%5B${this.selectedTag}%5D)`
    },
    ...mapCustomerState({
      assignedTagIds: (state) => state.assignedTagIds,
      filter: state => state.filter,
      selectedTagForFilter: state => state.selectedTag
    }),
    assignedTags() {
      return this.tags
        .filter((tag) => this.assignedTagIds.includes(tag.ID))
        .map((tag) => tag.TagName)
    },
    ...mapUserState({
      canCreate: state => state.user.canAccess(campaignRoute),
      canView: state => state.user.canAccess(historyRoutePath),
      canDelete: state => state.user.canDeleteCustomer
    }),
    ...mapContentPartState({
      sites: state => state.sites
    }),
    selectedTag: {
      get() {
        return this.selectedTagForFilter
      },
      set(newlySelectedTag) {
        this.setSelectedTag(newlySelectedTag)
        this.isStateStored = true
      }
    }
  },
  async created() {
    await this.fetchAll()
    const customerId = this.$router.currentRoute.query.id
    if (customerId) {
      const customersTableRef =
        this.$refs.customersRef.$refs.oDataTableRef.instance
      customersTableRef.beginCustomLoading()
      const { value } = await CustomerService.getById(Number(customerId))
      customersTableRef.endCustomLoading()
      this.handleViewClick({ row: { data: value[0] }})
    }
  },
  mounted() {
    this.customers.clear()
    if (this.filter?.length) {
      this.$refs.customersRef.$refs.oDataTableRef.instance.filter(this.filter)
    }
    this.setCourseCampaign({
      name: '',
      dateAdded: new Date().toISOString(),
      deadline: '',
      siteId: '',
      programId: '',
      senderName: '',
      senderEmail: '',
      subject: '',
      preview: '',
      episodes: [],
      filterUsed: '',
      emailTemplateId: '',
      customerAccount: '',
      totalRecipient: 0,
      sendList: '',
      campaignDefault: '',
      introText: '',
      scheduledDate: null,
      scheduledTime: null,
      courseCampaigns: []
    })
  },
  destroyed() {
    if (this.$router.history.current.path !== campaignRoute) {
      this.customers.clear()
    }
  },
  methods: {
    ...mapTagActions(['fetchAll']),
    ...mapMutations({
      setSelectedCustomers:
        MUTATION_TYPES.COURSE_CAMPAIGN.SET_SELECTED_CUSTOMERS,
      setCourseCampaign: MUTATION_TYPES.COURSE_CAMPAIGN.SET_COURSE_CAMPAIGN
    }),
    ...mapCustomerActions(['fetchAndOpenImpersonateLink', 'logAction']),
    ...mapCustomerMutations({
      setFilter: MUTATION_TYPES.CUSTOMER.SET_FILTER,
      setSelectedTag: MUTATION_TYPES.CUSTOMER.SET_SELECTED_TAG
    }),
    ...mapAppMutations({
      setCustomers: MUTATION_TYPES.APP.SET_SELECTED_CUSTOMERS
    }),
    async handleViewClick(event) {
      await this.logEvent({
        customerId: event.row.data.ID,
        action: LOG_ACTION.VIEW
      })
      this.selectedCustomer = new Customer(event.row.data)
      this.showDetails = true
    },
    async handleImpersonateClick(event) {
      const customerId = event.row.data.ID
      await this.logEvent({
        customerId,
        action: LOG_ACTION.AUTOLOGIN
      })
      this.fetchAndOpenImpersonateLink(customerId)
    },
    navigateToHistory(event) {
      const customerId = event.row.data.ID
      this.$router.push(`users/customers/${customerId}/histories`)
    },
    logEdit(event) {
      const customerId = event.key
      this.logEvent({
        customerId,
        action: LOG_ACTION.EDIT
      })
    },
    async logEvent({ customerId: ObjectID, action: Action }) {
      await this.logAction({
        TableName: tableName,
        ObjectID,
        Action,
        DateViewed: new Date()
      })
    },
    handleSelectionChange({ selectedRowsData }) {
      this.selectedRowsData = selectedRowsData
      this.setSelectedCustomers(this.selectedRowsData)
    },
    handleCourseCampaignClick() {
      this.setCourseCampaign({ filterUsed: this.filters })
      this.$router.push(campaignRoute)
    },
    handleAssignTagClick() {
      this.isTagDialogOpen = true
    },
    async handleUnAssignTagClick() {
      if (this.selectedTag) {
        this.setCustomers(this.selectedRowsData)
        this.$router.push('/tags-by-customers')
      } else {
        this.$message({
          type: 'error',
          message: this.$t('tagNotSelected')
        })
      }
    },
    handleTagDialogClose() {
      this.isTagDialogOpen = false
    },
    handleDetailsDialogClose() {
      this.selectedCustomer = null
      this.showDetails = false
    },
    handleFiltersClear() {
      localStorage.removeItem('storage')
      this.setSelectedTag('')
      this.setFilter([])
    },
    handleContentReady(event) {
      const filter = event.component.getCombinedFilter()
      this.setFilter(filter)
    },
    handleShowChatHistory(event) {
      const customerId = event.row.data.ID
      this.$router.push(`users/customers/${customerId}/chat-history-table`)
    },
    handleUpdateSendList() {
      this.$router.push(updateSendListRoute)
      this.setCustomers(this.selectedRowsData)
    },
    async handleSoftDeleteCustomer(event) {
      if (this.canDelete) {
        const confirmDelete = await confirm(`${this.$t('customers.delete')}<br/>${this.$t('customers.deleteWarning')}`)
        if (confirmDelete) {
          this.$refs.customersRef.$refs.oDataTableRef.instance.beginCustomLoading()
          try {
            const customerId = event.row.data.ID
            await this.$refs.customersRef.dataSource.store.remove(customerId)
            await this.$refs.customersRef.$refs.oDataTableRef.instance.refresh()
            this.$message({
              message: this.$t('deleteSuccess'),
              type: 'success'
            })
          } catch (error) {
            this.$message({
              type: 'error',
              message: error.message
            })
          } finally {
            this.$refs.customersRef.$refs.oDataTableRef.instance.endCustomLoading()
          }
        }
      } else {
        this.$message({
          message: this.$t('customers.noPermission'),
          type: 'error'
        })
      }
    }
  }
}
</script>
<style lang="scss">
.heading {
  font-weight: bold;
  font-size: 1.3rem;
}
.right-align {
  display: flex;
  justify-content: flex-end;
}
.left-align {
  display: flex;
  justify-content: flex-start;
}
.dx-datagrid .dx-link {
  &:first-child {
    color: rgb(103, 194, 58);
  }
  &:nth-child(2) {
    color: #e6a23c;
  }
  &:last-child {
    color: #6ad9d6;
  }
}
.align-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.el-dialog__body {
  padding: 0;
}
</style>
