<template>
  <DxTabPanel v-if="selectedAdmin">
    <DxItem :title="$t('menus.name')" template="menu-tab" />
    <template #menu-tab>
      <MenuManager :menus="groupedMenus" />
    </template>
  </DxTabPanel>
</template>

<script>
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel'
import MenuManager from '../../../../components/MenuManager'
export default {
  name: 'Detail',
  components: { DxTabPanel, DxItem, MenuManager },
  props: {
    selectedAdmin: {
      type: Object,
      default: () => {}
    },
    menus: {
      type: [Object, Array],
      default: () => []
    }
  },
  computed: {
    groupedMenus() {
      return this.menus.group()
    }
  }
}
</script>
