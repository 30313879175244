<template>
  <div v-loading="isLoading">
    <el-dialog
      v-if="selectedAdmin"
      v-loading="isLoading"
      :visible.sync="showDetails"
      :title="selectedAdmin.name"
      fullscreen
      @close="handleDetailsDialogClose"
    >
      <Details :selected-admin="selectedAdmin" :menus="menus" />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlePermissionSave">Save</el-button>
        <el-button @click="handleDetailsDialogClose">Cancel</el-button>
      </span>
    </el-dialog>
    <ODataTable
      ref="oDataTableComponentRef"
      :columns="columns"
      :add-edit-columns="addEditColumns"
      :total-label="$t('admins.total')"
      :file-name="$t('admins.fileName')"
      :add-row-button-label="$t('admins.add')"
      :title="$t('admins.title')"
      resource="customers"
      :filter="['IsAdmin', '=', true]"
      :should-show-add-button="false"
      :should-show-edit-button="false"
      :should-show-delete-button="false"
    >
      <template #custom-buttons>
        <DxButton icon="preferences" :on-click="handleManageClick" />
      </template>
    </ODataTable>
  </div>
</template>

<script>
import ODataTable from '../../../components/ODataTable'
import { columns, addEditColumns } from './config'
import { DxButton } from 'devextreme-vue/data-grid'
import { createNamespacedHelpers } from 'vuex'
import Details from './detail'
import { MUTATION_TYPES } from '@/store/mutations'
const { mapState } = createNamespacedHelpers('app')
const {
  mapState: mapAdminState,
  mapActions: mapAdminActions,
  mapMutations: mapAdminMutations
} = createNamespacedHelpers('admin')

export default {
  name: 'ManageAdmins',
  components: {
    ODataTable,
    DxButton,
    Details
  },
  data() {
    return {
      columns,
      addEditColumns,
      showDetails: false
    }
  },
  computed: {
    ...mapState({
      menus: (state) => state.menus
    }),
    ...mapAdminState({
      selectedAdmin: (state) => state.admin,
      isLoading: (state) => state.isLoading
    })
  },
  methods: {
    ...mapAdminActions(['setAdmin', 'savePermissions']),
    ...mapAdminMutations({
      resetAdmin: MUTATION_TYPES.ADMIN.RESET_ADMIN
    }),
    async handleManageClick(event) {
      this.showDetails = true
      await this.setAdmin(event.row.data)
      this.menus.markIdsSelected(this.selectedAdmin.menus.ids)
    },
    handleDetailsDialogClose() {
      this.resetAdmin()
      this.menus.clear()
      this.showDetails = false
    },
    async handlePermissionSave() {
      await this.savePermissions(this.menus.selectedIds)
      this.handleDetailsDialogClose()
    }
  }
}
</script>

<style lang="scss">
$base-spacing: 5px;
.menu-container.dx-template-wrapper.dx-item-content.dx-multiview-item-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: $base-spacing * 2;
}
</style>
