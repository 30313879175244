export class Reflection {
  static createApprovalParams({ isApproved, adminId, adminComment }) {
    return {
      IsApproved: isApproved,
      ApprovedByAdminID: adminId,
      ApprovalComment: adminComment,
      DateApproved: new Date().toISOString(),
      ...(!isApproved && { bIsShared: false })
    }
  }
}
