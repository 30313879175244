import ODataTable from '@/components/ODataTable'

export default {
  components: {
    ODataTable
  },
  methods: {
    handleRowInit(event) {
      const columns = event.component
        .state()
        .columns.filter(({ dataField }) => dataField)
        .map(({ dataField }) => dataField)

      const dateColumns = [
        'DateAdded',
        'AddedDate',
        'LastUpdate',
        'LastLoginDate',
        'CreatedAtDate'
      ]

      dateColumns.forEach(dateColumn => {
        if (columns.includes(dateColumn)) {
          event.data[dateColumn] = new Date().toISOString()
        }
      })
    },

    handleEditorPreparing(event) {
      if (event.isOnForm) {
        event.editorOptions.disabled = this.disabledColumns.includes(
          event.dataField
        )

        event.editorOptions.readOnly = this.readOnlyColumns.includes(
          event.dataField
        )
      }

      if (this.dateColumns?.includes(event.dataField)) {
        event.editorOptions.onValueChanged = e => {
          event.setValue(e.value.toISOString())
        }
      }
    }
  }
}
