import { CategoryService } from '@/api/category'
import EpisodeService from '@/api/episode'
import MenuService from '@/api/menus'
import SiteService from '@/api/site'
import { SubCategoryService } from '@/api/subCategory'
import { Category } from '@/models/category'
import { Customers } from '@/models/customers'
import { Media } from '@/models/media'
import { PaginatedResponse } from '@/models/paginatedResponse'
import { Menu } from '@/models/section'
import { MenuList } from '@/models/sectionList'

import Cookies from 'js-cookie'
import { MUTATION_TYPES } from '../mutations'

const MUTATION_TYPE = MUTATION_TYPES.APP

const state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus')
      ? !!+Cookies.get('sidebarStatus')
      : true,
    withoutAnimation: false
  },
  siteID: -1,
  device: 'desktop',
  categories: [],
  medias: [],
  nextAvailableSiteId: '',
  menus: new MenuList(),
  areMenusLoading: false,
  selectedSiteOnPage: '',
  disabledMedias: [],
  selectedCustomers: new Customers(),
  locale: '',
  updatedSendListID: 0,
  updatedSendList: []
}

const mutations = {
  [MUTATION_TYPE.TOGGLE_SIDEBAR]: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
  },

  [MUTATION_TYPE.CLOSE_SIDEBAR]: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },

  [MUTATION_TYPE.TOGGLE_DEVICE]: (state, device) => {
    state.device = device
  },

  [MUTATION_TYPE.SET_SITEID]: (state, siteID) => {
    state.siteID = siteID
    Cookies.set('siteID', siteID)
  },

  [MUTATION_TYPE.SET_CATEGORIES]: (state, categories) => {
    state.categories = Category.fromList(categories)
  },

  [MUTATION_TYPE.SET_MEDIAS]: (state, medias) => {
    state.medias = Media.fromList(medias)
  },

  [MUTATION_TYPE.SET_NEXT_AVAILABLE_SITE_ID]: (state, nextAvailableSiteId) => {
    state.nextAvailableSiteId = nextAvailableSiteId
  },

  [MUTATION_TYPE.SET_MENUS]: (state, menus) => {
    state.menus = Menu.fromList(menus)
    state.areMenusLoading = false
  },

  [MUTATION_TYPE.TOGGLE_MENU_SELECTION]: (state, id) => {
    state.menus.toggleSelection(id)
  },

  [MUTATION_TYPE.SET_MENUS_LOADING]: (state, areMenusLoading) => {
    state.areMenusLoading = areMenusLoading
  },

  [MUTATION_TYPE.SET_SELECTED_SITE]: (state, selectedSiteOnPage) => {
    state.selectedSiteOnPage = selectedSiteOnPage
  },

  [MUTATION_TYPE.SET_DISABLED_MEDIAS]: (state, disabledMedias) => {
    state.disabledMedias = disabledMedias
  },

  [MUTATION_TYPE.SET_ASSISTANT_ID]: (state, { mediaId, assistantId }) => {
    state.medias.find(media => media.ID === mediaId).AssistantID = assistantId
  },

  [MUTATION_TYPE.SET_SELECTED_CUSTOMERS]: (state, customers) => {
    state.selectedCustomers = Customers.fromList(customers)
  },

  [MUTATION_TYPE.SET_LOCALE]: (state, locale) => {
    state.locale = locale
  },

  [MUTATION_TYPE.SET_UPDATED_SEND_LIST_ID]: (state, ListID) => {
    state.updatedSendListID = ListID
  },
  [MUTATION_TYPE.SET_UPDATED_SEND_LIST]: (state, List) => {
    state.updatedSendList = List
  },
  [MUTATION_TYPE.UPDATE_CUSTOMERS]: (state, { ID, data }) => {
    state.selectedCustomers.updateCustomerValue({ ID, data })
  }
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setSiteID({ commit, dispatch }, siteID) {
    commit(MUTATION_TYPE.SET_SITEID, siteID)
  },
  setSiteIDFromStorage({ commit, dispatch }) {
    const siteID = parseInt(Cookies.get('siteID'))
    if (siteID) {
      commit(MUTATION_TYPE.SET_SITEID, siteID)
      dispatch('updateContentForSiteID')
      return true
    } else {
      return false
    }
  },
  updateContentForSiteID({ dispatch }) {
    dispatch('contentPart/setContentPart', null, { root: true })
    dispatch('contentPartPages/fetchPages', null, { root: true })
  },
  async fetchCategories({ commit }, columns = Category.optionColumns) {
    try {
      const response = await CategoryService.fetchAll(columns)
      const paginatedResponse = new PaginatedResponse(response)
      while (paginatedResponse.hasMore) {
        await paginatedResponse.next()
      }
      commit(MUTATION_TYPE.SET_CATEGORIES, paginatedResponse.items)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async fetchDisabledCategories({ commit }) {
    try {
      const response = await CategoryService.fetchAllDisabled()
      const paginatedResponse = new PaginatedResponse(response)
      while (paginatedResponse.hasMore) {
        await paginatedResponse.next()
      }
      commit(MUTATION_TYPE.SET_CATEGORIES, paginatedResponse.items)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async disableCategoryForSite({ commit, state }, data) {
    try {
      const response = await CategoryService.disableCategoryForSite(data)
      commit(MUTATION_TYPE.SET_CATEGORIES, [...state.categories, response])
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async enableCategoryForSite({ commit, state }, id) {
    try {
      await CategoryService.enableCategoryForSite(id)
      const updatedCategories = state.categories.filter(
        category => category.ID !== id
      )
      commit(MUTATION_TYPE.SET_CATEGORIES, updatedCategories)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async fetchMedias({ commit }, columns = Media.optionColumns) {
    try {
      const response = await EpisodeService.fetchAll(columns)
      const paginatedResponse = new PaginatedResponse(response)
      while (paginatedResponse.hasMore) {
        await paginatedResponse.next()
      }
      commit(MUTATION_TYPE.SET_MEDIAS, paginatedResponse.items)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async fetchDisabledMedias({ commit }) {
    try {
      const response = await EpisodeService.fetchAllDisabled()
      const paginatedResponse = new PaginatedResponse(response)
      while (paginatedResponse.hasMore) {
        await paginatedResponse.next()
      }
      commit(MUTATION_TYPE.SET_DISABLED_MEDIAS, paginatedResponse.items)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async enableMediaForSite({ commit }, id) {
    try {
      await EpisodeService.enableMediaForSite(id)
      const updatedDisabledMedias = state.disabledMedias.filter(
        media => media.ID !== id
      )
      commit(MUTATION_TYPE.SET_DISABLED_MEDIAS, updatedDisabledMedias)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async disableMediaForSite({ commit }, data) {
    try {
      const response = await EpisodeService.disableMediaForSite(data)
      commit(MUTATION_TYPE.SET_DISABLED_MEDIAS, [
        ...state.disabledMedias,
        response
      ])
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async fetchNextAvailableSiteId({ commit }) {
    try {
      const siteId = await SiteService.getNewSiteId()
      commit(MUTATION_TYPE.SET_NEXT_AVAILABLE_SITE_ID, siteId)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async fetchMenus({ commit }) {
    commit(MUTATION_TYPE.SET_MENUS_LOADING, true)
    try {
      const response = await MenuService.fetchAll()
      const paginatedResponse = new PaginatedResponse(response)
      while (paginatedResponse.hasMore) {
        await paginatedResponse.next()
      }
      commit(MUTATION_TYPE.SET_MENUS, paginatedResponse.items)
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPE.SET_MENUS_LOADING, false)
      return Promise.reject(error)
    }
  },
  async checkSubCategoriesForParentCategory({ commit }, categoryId) {
    try {
      const response = await SubCategoryService.fetchByParentCategoryId(
        categoryId
      )

      return Promise.resolve(!!response.value.length)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async fetchMediasByCategoryId(_, categoryId) {
    try {
      const response = await EpisodeService.fetchMediasByCategoryId(categoryId)

      return Promise.resolve(response.value.map(media => media.MediaID))
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async updateAssistantId({ commit }, { mediaId, assistantId }) {
    try {
      await EpisodeService.updateAssistantId({ mediaId, assistantId })
      commit(MUTATION_TYPE.SET_ASSISTANT_ID, { mediaId, assistantId })
      return
    } catch (error) {
      throw new Error(error)
    }
  }
}

const getters = {
  findMediaById: state => ID => state.medias.find(el => el.ID === ID),

  filterMediasByIds: state => ids =>
    state.medias.filter(el => ids.includes(el.ID)),

  findCategoryByIdAndSiteId: state => ({ id, siteId }) =>
    state.categories.find(
      category => category.CategoryID === id && category.SiteId === siteId
    ),

  findMediaByIdAndSiteId: state => ({ id, siteId }) =>
    state.disabledMedias.find(
      media => media.MediaID === id && media.SiteID === siteId
    )
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
