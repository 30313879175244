var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isPanelLoading,
          expression: "isPanelLoading"
        }
      ],
      staticClass: "container"
    },
    [
      _c("ODataTable", {
        key: _vm.filter,
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns,
          "total-label": _vm.$t("mediaReportsV2.total"),
          "file-name": _vm.$t("mediaReportsV2.fileName"),
          title: _vm.$t(_vm.translationSection + ".title"),
          "should-show-add-button": false,
          "should-show-edit-button": false,
          "should-show-delete-button": false,
          resource: "viewMediaReportDone",
          "show-data": _vm.showData,
          filter: _vm.filter,
          "row-key": "CustomerID"
        },
        on: { clearFilters: _vm.handleFiltersClear },
        scopedSlots: _vm._u([
          {
            key: "custom-filters",
            fn: function() {
              return [
                _c("media-reports-filters", {
                  ref: "mediaReportFiltersRef",
                  on: {
                    filter: _vm.filterMediaReports,
                    panelLoading: _vm.handlePanelLoading,
                    clearFilteredData: _vm.handleClearFilteredData
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }