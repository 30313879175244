var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ODataTable", {
    ref: "oDataTableComponentRef",
    attrs: {
      columns: _vm.columns,
      resource: "tTestQuestion",
      "total-label": _vm.$t("test.total"),
      "file-name": _vm.$t(_vm.test.translationSection + ".fileName"),
      "should-show-add-button": _vm.canAdd,
      "add-row-button-label": _vm.$t("test.create.title"),
      title: _vm.$t(_vm.test.translationSection + ".title"),
      "is-custom-add": true,
      "show-data": _vm.showData,
      "should-show-delete-button": false,
      "should-show-edit-button": false,
      "action-buttons-width": 100
    },
    on: {
      addClick: _vm.handleCreateTest,
      edit: _vm.navigateToEdit,
      clearFilters: _vm.handleClearFilter,
      cellPrepared: _vm.handleCellPrepared
    },
    scopedSlots: _vm._u([
      {
        key: "custom-filters",
        fn: function() {
          return [
            _c("test-filters", {
              ref: "testFilterRef",
              on: { filter: _vm.applyFilter }
            })
          ]
        },
        proxy: true
      },
      {
        key: "toggle-button",
        fn: function(ref) {
          var data = ref.data
          return [
            _c("el-switch", {
              attrs: { value: data.row.data.IsActive },
              on: {
                change: function($event) {
                  return _vm.toggleActivate(data.row.data)
                }
              }
            })
          ]
        }
      },
      {
        key: "custom-buttons",
        fn: function() {
          return [
            _vm.canEdit
              ? _c("DxButton", {
                  attrs: { icon: "edit", "on-click": _vm.navigateToEdit }
                })
              : _vm._e(),
            _c("DxButton", { attrs: { template: "custom-button-template" } })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }