var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-row",
            { staticClass: "container", attrs: { type: "flex" } },
            [
              _c(
                "el-col",
                { staticClass: "col", attrs: { xs: 8, sm: 6 } },
                [
                  _c(
                    "el-row",
                    { attrs: { type: "flex", align: "middle" } },
                    [
                      _c("el-col", { staticClass: "header" }, [
                        _c("span", { staticClass: "heading" }, [
                          _vm._v("Content Part")
                        ])
                      ]),
                      _c(
                        "el-col",
                        { staticClass: "text-end header", attrs: { span: 4 } },
                        [_c("builder-filters")],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-divider", { staticClass: "divider" }),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        [
                          _c("el-input", {
                            staticClass: "search",
                            attrs: {
                              size: "mini",
                              clearable: "",
                              "suffix-icon": "el-icon-search",
                              placeholder: _vm.$t("search")
                            },
                            on: {
                              input: function($event) {
                                return _vm.setFilters({ nameSearch: $event })
                              }
                            },
                            model: {
                              value: _vm.nameSearch,
                              callback: function($$v) {
                                _vm.nameSearch = $$v
                              },
                              expression: "nameSearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [_c("el-col", [_c("builder-filter-tags")], 1)],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "content-part", attrs: { type: "flex" } },
                    [
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.isLoading,
                              expression: "isLoading"
                            }
                          ]
                        },
                        _vm._l(_vm.contentPartListForBuilder, function(
                          contentPart
                        ) {
                          return _c(
                            "el-col",
                            {
                              key: contentPart.ID,
                              attrs: {
                                id: contentPart.ID,
                                draggable: "",
                                span: 12
                              },
                              nativeOn: {
                                dragstart: function($event) {
                                  return _vm.handleDragStart(
                                    contentPart.ID,
                                    $event
                                  )
                                },
                                dragend: function($event) {
                                  return _vm.handleDragEnd.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [
                              _c("builder-content-tiles", {
                                attrs: { data: contentPart }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "col", attrs: { xs: 16, sm: 18 } },
                [
                  _c(
                    "el-row",
                    { attrs: { type: "flex", align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "header", attrs: { xs: 24, sm: 8 } },
                        [
                          _c("span", { staticClass: "heading truncate-text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.config.currentPageName || _vm.$t("builder")
                              )
                            )
                          ]),
                          _vm.config.isActive
                            ? _c(
                                "span",
                                { staticClass: "header-button badge success" },
                                [_vm._v(_vm._s(_vm.$t("active")))]
                              )
                            : _c(
                                "span",
                                { staticClass: "header-button badge warning" },
                                [_vm._v(_vm._s(_vm.$t("draft")))]
                              )
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "text-end header header-items",
                          attrs: { xs: 24, sm: 16 }
                        },
                        [
                          _c("el-checkbox", { staticClass: "header-button" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("contentPart.showOnlyWhenLoggedIn")
                                ) +
                                " "
                            )
                          ]),
                          _vm.isPreviewChanged
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "header-button new-tab",
                                  attrs: { size: "small" },
                                  on: { click: _vm.saveAndPreview }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("save") +
                                          " & " +
                                          _vm.$t("preview")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _c(
                                "el-button",
                                {
                                  staticClass: "header-button new-tab",
                                  attrs: { size: "small" },
                                  on: { click: _vm.showPreview }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("preview")) + " ")]
                              ),
                          _c(
                            "el-dropdown",
                            {
                              staticClass: "header-dropdown",
                              attrs: {
                                trigger: "click",
                                size: "mini",
                                "split-button": "",
                                type: "danger"
                              },
                              on: { click: _vm.clearPreviewItems }
                            },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("clear")) + " "),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown"
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            type: "text",
                                            size: "medium"
                                          },
                                          on: { click: _vm.resetPreview }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("reset")) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.config.isActive
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading.fullscreen.lock",
                                      value: _vm.isBuilderLoading,
                                      expression: "isBuilderLoading",
                                      modifiers: {
                                        fullscreen: true,
                                        lock: true
                                      }
                                    }
                                  ],
                                  staticClass: "header-button",
                                  attrs: {
                                    "element-loading-text": _vm.$t(
                                      "builderMessage"
                                    ),
                                    type: "warning",
                                    size: "small"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.savePreview(false)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("editPageMakeInactiveButton")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading.fullscreen.lock",
                                      value: _vm.isBuilderLoading,
                                      expression: "isBuilderLoading",
                                      modifiers: {
                                        fullscreen: true,
                                        lock: true
                                      }
                                    }
                                  ],
                                  staticClass: "header-button",
                                  attrs: {
                                    "element-loading-text": _vm.$t(
                                      "builderMessage"
                                    ),
                                    type: "success",
                                    size: "small"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.savePreview(true)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("editPageMakeActiveButton")
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading.fullscreen.lock",
                                  value: _vm.isBuilderLoading,
                                  expression: "isBuilderLoading",
                                  modifiers: { fullscreen: true, lock: true }
                                }
                              ],
                              staticClass: "header-button",
                              attrs: {
                                disabled: !_vm.isPreviewChanged,
                                "element-loading-text": _vm.$t(
                                  "builderMessage"
                                ),
                                type: "primary",
                                size: "small"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.savePreview(_vm.config.isActive)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [_c("el-divider", { staticClass: "divider" })],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "builder",
                      on: { drop: _vm.handleDrop, dragover: _vm.handleOver }
                    },
                    [
                      !_vm.previewItems.length
                        ? _c(
                            "el-row",
                            {
                              staticClass: "builder",
                              attrs: {
                                type: "flex",
                                align: "middle",
                                justify: "center"
                              }
                            },
                            [
                              _c("el-col", { staticClass: "text-center" }, [
                                _c("h5", { staticClass: "text-grey" }, [
                                  _vm._v(_vm._s(_vm.$t("dropHere")))
                                ])
                              ])
                            ],
                            1
                          )
                        : _c(
                            "el-row",
                            { attrs: { type: "flex", align: "middle" } },
                            [
                              _c(
                                "el-col",
                                _vm._l(_vm.previewItems, function(
                                  previewItem,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _vm.showOptions
                                        ? _c(
                                            "el-row",
                                            {
                                              attrs: {
                                                justify: "center",
                                                type: "flex"
                                              }
                                            },
                                            _vm._l(_vm.actions, function(
                                              action
                                            ) {
                                              return _c(
                                                "el-col",
                                                {
                                                  key: action.icon,
                                                  attrs: { span: 1 }
                                                },
                                                [
                                                  _c("el-button", {
                                                    staticClass:
                                                      "action-buttons",
                                                    attrs: {
                                                      type: action.type,
                                                      icon: action.icon
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return action.handler(
                                                          index
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-row",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.isEditorLoading,
                                              expression: "isEditorLoading"
                                            }
                                          ],
                                          staticClass: "editor overlay"
                                        },
                                        [
                                          _c("editor", {
                                            attrs: {
                                              "api-key": _vm.apiKey,
                                              init: _vm.tinyMCEConfig
                                            },
                                            on: {
                                              onInit: _vm.handleEditorActivate
                                            },
                                            model: {
                                              value: previewItem.MainContent,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  previewItem,
                                                  "MainContent",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "previewItem.MainContent"
                                            }
                                          }),
                                          _c("div", {
                                            staticClass: "child",
                                            class: {
                                              "highlight-item":
                                                _vm.dropItemIndex === index
                                            },
                                            attrs: { "data-item-index": index },
                                            on: {
                                              click: _vm.toggleOptionsVisibility
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ],
                            1
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("content-part-dialog-box", {
        attrs: {
          visible: _vm.dialogVisible,
          item: _vm.contentPartBlock,
          "dialog-type": "edit"
        },
        on: {
          "update:visible": function($event) {
            _vm.dialogVisible = $event
          },
          "update:item": function($event) {
            _vm.contentPartBlock = $event
          },
          update: function($event) {
            return _vm.updatePreviewItems($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }