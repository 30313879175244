var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("medias-by-category", {
    ref: "mediasRef",
    attrs: {
      "category-id": _vm.data.ID,
      "site-id": _vm.data.SiteID,
      "parent-category-id": _vm.data.CategoriesID,
      title: "medias.title",
      "add-row-button-label": "medias.add",
      "should-show-add-button": _vm.shouldShowAddButton,
      "should-show-edit-button": _vm.shouldShowEditButton,
      "should-show-delete-button": _vm.shouldShowDeleteButton,
      "should-show-toggle-button": _vm.shouldShowToggleButton,
      "should-show-title": _vm.shouldShowTitle
    },
    scopedSlots: _vm._u(
      [
        {
          key: "toggle-button",
          fn: function(ref) {
            var item = ref.data
            return [
              _c("el-switch", {
                attrs: { value: _vm.isMediaActive(item.row.data.MediaID) },
                on: {
                  change: function($event) {
                    return _vm.toggleActivate(item.row.data.MediaID)
                  }
                }
              })
            ]
          }
        },
        _vm.shouldShowToggleButton
          ? {
              key: "custom-buttons",
              fn: function() {
                return [
                  _c("DxButton", {
                    attrs: { template: "custom-button-template" }
                  })
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }