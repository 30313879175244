var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { fullscreen: "", visible: _vm.dialogVisible },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        open: _vm.handleOpen,
        close: _vm.handleClose
      }
    },
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            [
              _c(
                "el-row",
                {
                  staticClass: "text-center",
                  attrs: { type: "flex", gutter: 15 }
                },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: {
                            size: "mini",
                            disabled: _vm.preview.isPreviewDialogLoading
                          },
                          on: { change: _vm.handleFrameSizeChange },
                          model: {
                            value: _vm.tabs,
                            callback: function($$v) {
                              _vm.tabs = $$v
                            },
                            expression: "tabs"
                          }
                        },
                        [
                          _c("el-radio-button", {
                            attrs: { label: "Desktop" }
                          }),
                          _c("el-radio-button", { attrs: { label: "Tablet" } }),
                          _c("el-radio-button", { attrs: { label: "Mobile" } })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-main",
            [
              _c(
                "el-row",
                { staticClass: "text-center" },
                [
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.preview.isPreviewDialogLoading,
                          expression: "preview.isPreviewDialogLoading"
                        }
                      ]
                    },
                    [
                      _c("iframe", {
                        attrs: {
                          src: _vm.src,
                          width: _vm.width,
                          frameborder: "1"
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }