import TagService from '@/api/tag'
import i18n from '@/i18n'
import { Tag } from '@/models/tag'
import { TransactionItemList } from '@/models/transaction'
import { Message } from 'element-ui'
import { MUTATION_TYPES as BASE_MUTATION_TYPES } from '../mutations'
import { PaginatedResponse } from '@/models/paginatedResponse'

const MUTATION_TYPES = BASE_MUTATION_TYPES.TAGS

const state = {
  tags: [],
  isLoading: false
}

const mutations = {
  [MUTATION_TYPES.SET_TAGS]: (state, tags = []) => {
    state.tags = Tag.fromList(tags)
    state.isLoading = false
  },

  [MUTATION_TYPES.SET_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  },

  [MUTATION_TYPES.ADD_TAG]: (state, tag) => {
    state.tags = [...state.tags, new Tag(tag)]
    state.isLoading = false
  }
}

const actions = {
  async fetchAll({ commit }) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    commit(MUTATION_TYPES.SET_TAGS, [])
    try {
      const response = await TagService.getAll()
      const paginatedResponse = new PaginatedResponse(response)
      while (paginatedResponse.hasMore) {
        await paginatedResponse.next()
      }
      commit(MUTATION_TYPES.SET_TAGS, paginatedResponse.items)
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPES.SET_LOADING, false)
      return Promise.reject(error)
    }
  },

  async create({ commit }, newTag) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      const tag = await TagService.create(newTag)
      commit(MUTATION_TYPES.ADD_TAG, tag)
      return Promise.resolve(new Tag(tag))
    } catch (error) {
      commit(MUTATION_TYPES.SET_LOADING, false)
      return Promise.reject(error)
    }
  },
  async assignTagToCustomers(
    { commit },
    { data, onTagAssignProgressChangeCb }
  ) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      const transactionItems = new TransactionItemList(data, 1)
      while (transactionItems.hasMore) {
        const items = transactionItems.next()
        onTagAssignProgressChangeCb(transactionItems.percentage)
        await TagService.assign(items[0])
      }
      commit(MUTATION_TYPES.SET_LOADING, false)

      Message({
        message: i18n.t('tag.assigned'),
        type: 'success'
      })
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPES.SET_LOADING, false)
      return Promise.reject(error)
    }
  },
  async delete(_, { tagId, isTagInUse }) {
    try {
      await TagService.delete({ tagId, isTagInUse })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async areUsersAssociatedWithTag({ commit }, tagId) {
    try {
      const response = await TagService.fetchUsersByTagId(tagId)

      return Promise.resolve(!!response.value.length)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
