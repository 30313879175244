var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "app-container container-width" },
    [
      _c(
        "el-main",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    "label-width": "120px",
                    size: "mini",
                    "label-position": "top"
                  }
                },
                [
                  _c("el-form-item", { attrs: { label: _vm.$t("tag.title") } }),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("tag.selectTagTypeTitle") } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: _vm.$t("tag.selectTagType")
                          },
                          model: {
                            value: _vm.selectedTagType,
                            callback: function($$v) {
                              _vm.selectedTagType = $$v
                            },
                            expression: "selectedTagType"
                          }
                        },
                        _vm._l(_vm.tagTypes, function(tagType) {
                          return _c("el-option", {
                            key: tagType.value,
                            attrs: {
                              label: tagType.label,
                              value: tagType.value
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isExistingTagTypeSelected
                    ? _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("tag.selectTagTitle") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "add-margin",
                              attrs: {
                                loading: _vm.isLoading,
                                filterable: "",
                                placeholder: _vm.$t("tag.selectTag")
                              },
                              model: {
                                value: _vm.selectedTagID,
                                callback: function($$v) {
                                  _vm.selectedTagID = $$v
                                },
                                expression: "selectedTagID"
                              }
                            },
                            _vm._l(_vm.tags, function(tag) {
                              return _c("el-option", {
                                key: tag.ID,
                                attrs: { label: tag.TagName, value: tag.ID }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isNewTagTypeSelected
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("tag.selectTagTitle"),
                            size: "small"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: _vm.$t("name") },
                            model: {
                              value: _vm.newTag,
                              callback: function($$v) {
                                _vm.newTag = $$v
                              },
                              expression: "newTag"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.selectedTagType
                ? _c(
                    "el-col",
                    { staticClass: "action-btn", attrs: { md: 6, sm: 4 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: _vm.isSaveButtonDisabled,
                            loading: _vm.isLoading
                          },
                          on: { click: _vm.handleAssignClick }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("tag.assign")) + " ")]
                      ),
                      _vm.isLoading
                        ? _c("el-progress", {
                            attrs: {
                              "text-inside": true,
                              "stroke-width": 10,
                              percentage: _vm.percentage
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }