import {
  createResource,
  fectchResource,
  patchResource
} from '@/utils/httpRequests'
export default class TestQuestionService {
  static createTestQuestion(data) {
    return createResource({
      url: `${process.env.VUE_APP_ODATA_API}/tTestQuestion`,
      data
    })
  }
  static fetchBySectionId({ id, isFreeText }) {
    return fectchResource(
      `${process.env.VUE_APP_ODATA_API}/tTestQuestion?$filter=tTestSectionID eq ${id} and IsActive eq true and bIsFreetext eq ${isFreeText}`
    )
  }
  static updateById({ id, data }) {
    return patchResource({
      url: `${process.env.VUE_APP_ODATA_API}/tTestQuestion(${id})`,
      data
    })
  }
}
