var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ODataTable", {
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns.all,
          "add-edit-columns": _vm.columns.addEdit,
          resource: "ChatQuestion",
          "action-buttons-width": 90,
          "total-label": _vm.$t("chatQuestions.total"),
          "file-name": _vm.$t("chatQuestions.fileName"),
          "add-row-button-label": _vm.$t("chatQuestions.add"),
          title: _vm.$t("chatQuestions.title"),
          "should-render-master-detail": true
        },
        on: {
          rowInit: _vm.handleRowInit,
          rowRemoved: _vm.handleRowRemoved,
          editorPreparing: _vm.handleEditorPreparing
        },
        scopedSlots: _vm._u([
          {
            key: "details",
            fn: function(ref) {
              var data = ref.data
              return [_c("chatQuestionsByMedia", { attrs: { data: data } })]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }