<template>
  <ODataTable
    ref="oDataTableComponentRef"
    :columns="columns.all"
    :add-edit-columns="columns.addEdit"
    resource="SubCategory"
    :total-label="$t(`${segment}.total`)"
    :file-name="$t(`${segment}.fileName`)"
    :add-row-button-label="$t(`${segment}.add`)"
    :title="$t(`${segment}.title`)"
    :filter="['CategoriesID', '=', category.ID]"
    :should-render-master-detail="true"
    :has-parent="!shouldShowTitle"
    :should-show-add-button="shouldShowAddButton"
    :should-show-edit-button="shouldShowEditButton"
    :should-show-delete-button="shouldShowDeleteButton"
    :should-show-toggle-button="shouldShowToggleButton"
    :on-loaded="handleSubCategoriesLoad"
    :is-edit-delete-button-visible="isEditDeleteButtonVisible"
    @rowInit="handleRowInit"
    @rowInserted="handleRowInserted"
    @rowRemoved="handleRowRemoved"
    @editorPreparing="handleEditorPreparing"
  >
    <template #details="{ data }">
      <Detail
        :data="data"
        :should-show-add-button="shouldShowAddButton"
        :should-show-edit-button="shouldShowEditButton"
        :should-show-delete-button="shouldShowDeleteButton"
        :should-show-toggle-button="shouldShowToggleButton"
        :should-show-title="shouldShowTitle"
      />
    </template>
    <template #toggle-button="{ data }">
      <el-switch
        :value="isSubProgramActive(data.row.data.ID)"
        @change="toggleActivate(data.row.data.ID)"
      />
    </template>
    <template v-if="shouldShowToggleButton" #custom-buttons>
      <DxButton template="custom-button-template" :visible="isEditDeleteButtonVisible" />
    </template>
  </ODataTable>
</template>
<script>
import ODataTable from '../../components/ODataTable'
import { createNamespacedHelpers } from 'vuex'
import { createColumns } from './config'
import { DxButton } from 'devextreme-vue/data-grid'
import { confirm } from 'devextreme/ui/dialog'
import { Category } from '@/models/category'
const { mapState: mapContentPartState } =
  createNamespacedHelpers('contentPart')
const {
  mapState: mapAppState,
  mapActions: mapAppActions,
  mapGetters: mapAppGetters
} = createNamespacedHelpers('app')
import Detail from './detail/index.vue'

export default {
  name: 'SubCategories',
  components: {
    ODataTable,
    Detail,
    DxButton
  },
  props: {
    category: {
      type: Object,
      default: null
    },
    shouldShowAddButton: {
      type: Boolean,
      default: true
    },
    shouldShowEditButton: {
      type: Boolean,
      default: true
    },
    shouldShowDeleteButton: {
      type: Boolean,
      default: true
    },
    shouldShowToggleButton: {
      type: Boolean,
      default: false
    },
    shouldShowTitle: {
      type: Boolean,
      default: false
    },
    segment: {
      type: String,
      default: 'subCategories'
    }
  },
  computed: {
    ...mapAppGetters(['findCategoryByIdAndSiteId']),
    ...mapAppState({
      selectedSiteOnPage: (state) => state.selectedSiteOnPage
    }),
    ...mapContentPartState({
      sites: (state) => state.sites.map((site) => site.toOption)
    }),
    columns() {
      return createColumns({ sites: this.sites })
    }
  },
  methods: {
    handleRowInit(event) {
      event.data.CategoriesID = this.category.ID
    },
    ...mapAppActions(['disableCategoryForSite', 'enableCategoryForSite']),
    isSubProgramActive(id) {
      return !this.findCategoryByIdAndSiteId({
        id,
        siteId: this.selectedSiteOnPage
      })
    },
    async handleToggleConfirm(id) {
      this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
      try {
        const category = this.findCategoryByIdAndSiteId({
          id,
          siteId: this.selectedSiteOnPage
        })
        await (category
          ? this.enableCategoryForSite(category.ID)
          : this.disableCategoryForSite(
            Category.createDisableParams({
              id,
              siteId: this.selectedSiteOnPage
            })
          ))
        await this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.refresh()
        this.$message({
          type: 'success',
          message: this.$t(
            category ? 'programs.activate' : 'programs.deactivate'
          )
        })
      } finally {
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
      }
    },
    async toggleActivate(id) {
      const result = await confirm(this.$t('sites.toggleConfirm'))
      if (result) {
        await this.handleToggleConfirm(id)
      }
    },
    handleRowInserted() {
      this.$emit('rowInserted')
    },
    handleRowRemoved() {
      if (!this.$refs.oDataTableComponentRef.total) {
        this.$emit('rowRemoved')
      }
    },
    handleSubCategoriesLoad(subCategories = [], options) {
      const categoryInstance = new Category(this.category)
      if (categoryInstance.isMatched(options.filter)) {
        subCategories.unshift(categoryInstance.asSubCategory)
      }
    },
    isEditDeleteButtonVisible({ row }) {
      return row.key !== this.category.ID
    },
    handleEditorPreparing(event) {
      if (event.command === 'select' && event.row) {
        event.cancel = !this.isEditDeleteButtonVisible(event)
      }
    }
  }
}
</script>

