<template>
  <div>
    <DxPopup title="Popup Title" :hide-on-outside-click="true" :visible="isAddListDialogOpen">
      <div class="contentHeight">
        <div class="margin-bottom-sm flex-label-column">
          <label>{{ $t('updateSendList.listName') }}</label>
          <DxTextBox v-model="popupListValue" placeholder="Enter full name here...">
            <DxValidator>
              <DxRequiredRule :message="$t('updateSendList.listNameAlert')" />
            </DxValidator>
          </DxTextBox>
        </div>

        <div class="margin-bottom-sm">
          <el-checkbox
            v-model="disabledExistingFolderField"
            :label="$t('updateSendList.checkbox')"
            size="mini"
            @input.native="handleCheckbox"
          />
        </div>

        <div class="twoColumn">
          <div class="flex-label-column">
            <label>{{ $t('updateSendList.folderName') }}</label>
            <DxTextBox
              v-model="popupFolderName"
              :disabled="!disabledExistingFolderField"
              placeholder="Enter folder name here..."
            >
              <DxValidator>
                <DxRequiredRule v-if="disabledExistingFolderField" :message="$t('updateSendList.folderCreate')" />
              </DxValidator>
            </DxTextBox>
          </div>

          <div class="flex-label-column">
            <label>{{ $t('updateSendList.selectFolder') }}</label>
            <DxSelectBox
              v-model="selectExistingFolderName"
              :disabled="disabledExistingFolderField"
              :search-enabled="true"
              :data-source="columns.addListColumns[0].lookup"
              :display-expr="columns.addListColumns[0].displayExpr"
              :value-expr="columns.addListColumns[0].valueExpr"
            >
              <DxValidator>
                <DxRequiredRule v-if="!disabledExistingFolderField" :message="$t('updateSendList.folderCreate')" />
              </DxValidator>
            </DxSelectBox>
          </div>
        </div>
      </div>

      <div class="alignButton">
        <DxButton name="save" text="save" @Click="handleFormDataSave" />
        <DxButton name="cancel" text="cancel" @Click="handleFormClose" />
      </div>

    </DxPopup>

    <el-header height="auto">
      <el-row type="flex">
        <el-col :span="24">
          <span class="heading">{{ $t('updateSendList.title') }}</span>
        </el-col>
      </el-row>
    </el-header>

    <el-main class="fields-section">
      <el-form ref="updateUserRef" :model="attributeFields" :rules="rules">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="24" :md="6" class="margin-bottom-sm">
            <el-col class="margin-bottom-md">
              <el-button class="margin-bottom-sm" :disabled="true" @click="handlePopUp">
                {{ $t("updateSendList.createSendList") }}
              </el-button>
            </el-col>
            <el-col class="margin-bottom-md">
              <el-row class="margin-bottom-sm">{{ $t('updateSendList.selectSendList') }}</el-row>
              <el-select
                v-model="sendListId"
                class="send-list"
                filterable
                :loading="!sendLists.length"
                :placeholder="$t('updateSendList.selectSendList')"
                size="large"
              >
                <el-option v-for="item in sendLists" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
              <div v-if="!sendListId" class="instruction margin-bottom-md">{{ $t('updateSendList.sendListSelect') }}</div>
            </el-col>

            <el-col class="margin-bottom-md">
              <el-row class="margin-bottom-sm">{{ $t('updateSendList.selectCustomerAccount') }}</el-row>
              <el-select
                v-model="selectedCustomerAccount"
                class="send-list"
                filterable
                :loading="!customersAccounts.length"
                :placeholder="$t('updateSendList.selectCustomerAccount')"
                size="large"
              >
                <el-option label="Select customers Accounts" :value="null" />
                <el-option v-for="account in customersAccounts" :key="account.ID" :label="account.Account" :value="account.ID" />
              </el-select>
            </el-col>
          </el-col>

          <el-col :xs="24" :sm="24" :md="12" class="margin-bottom-sm">
            <el-col class="label-add-attributes">
              <el-row>{{ $t('addAttributes') }}</el-row>
            </el-col>
            <el-col class="margin-bottom-md">
              <el-row class="margin-bottom-sm">{{ $t('logo') }}</el-row>
              <el-input v-model="attributeFields.logo" name="logo" :placeholder="$t('logo')" class="margin-bottom-sm" />
            </el-col>

            <el-col class="margin-bottom-md">
              <el-form-item :label="$t('site')" prop="site">
                <el-input v-model="attributeFields.site" name="site" :placeholder="$t('site')" class="margin-bottom-sm" />
              </el-form-item>
            </el-col>

            <el-col class="margin-bottom-md">
              <el-checkbox v-model="attributeFields.sso" name="sso" :label="$t('sso')" size="mini" :border="true" />
              <el-checkbox v-model="attributeFields.utskick" name="utskick" :label="$t('updateSendList.utskick')" size="mini" :border="true" />
            </el-col>

            <el-col class="margin-bottom-md">
              <el-row class="label-check-box">
                <span>{{ $t('updateSendList.utskick2') }}</span>
                <el-checkbox v-model="includeUtskick2" class="label-add-attributes-checkbox" />
              </el-row>
              <el-input
                v-model="attributeFields.utskick2"
                :disabled="!includeUtskick2"
                name="utskick2"
                :placeholder="$t('updateSendList.utskick2')"
              />
            </el-col>

            <el-col class="margin-bottom-md">
              <el-row class="margin-bottom-sm">{{ $t('signature') }}</el-row>
              <el-input
                v-model="attributeFields.signature"
                name="signature"
                class="textarea"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 5 }"
                :placeholder="$t('signature')"
              />
            </el-col>

            <el-col class="margin-bottom-md">
              <el-form-item :label="$t('updateSendList.shortSignature')" prop="signature">
                <el-input
                  v-model="attributeFields.shortSignature"
                  name="shortSignature"
                  class="textarea"
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 5 }"
                  :placeholder="$t('updateSendList.contactPerson')"
                />
              </el-form-item>
            </el-col>

            <el-col class="margin-bottom-md">
              <el-form-item :label="$t('updateSendList.contactPersonPosition')" prop="contactPersonPosition">
                <el-input
                  v-model="attributeFields.contactPersonPosition"
                  name="contactPersonPosition"
                  class="textarea"
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 5 }"
                  :placeholder="$t('updateSendList.contactPersonPosition')"
                />
              </el-form-item>
            </el-col>
          </el-col>

          <el-col :xs="24" :sm="24" :md="4" class="center-div">
            <div class="current-date">
              <div class="margin-bottom-sm">
                <span>{{ $t(label) }}</span>
              </div>
              <div>
                {{ currentDate }}
              </div>
            </div>
            <el-button type="primary" :disabled="!sendListId" :loading="loadingButton" @click="handleUpdate">
              {{ $t("update") }}
            </el-button>

          </el-col>
        </el-row>
      </el-form>
    </el-main>
  </div>
</template>

<script>

import { DxPopup } from 'devextreme-vue/popup'
import { DxButton, DxTextBox } from 'devextreme-vue'
import { DxSelectBox } from 'devextreme-vue/select-box'
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator'
import mixin from './mixin'

export default {
  name: 'AttributeSelection',
  components: {
    DxPopup,
    DxTextBox,
    DxSelectBox,
    DxButton,
    DxValidator,
    DxRequiredRule
  },
  mixins: [mixin]
}
</script>

<style lang="scss">
$base-spacings: 5px;
$margin-20: $base-spacings * 4;
$margin-10: $base-spacings * 2;

 .DxSelectBoxCss{
  border: 1px solid grey !important;
  border-radius: 5px;
  height: 3rem;
  background-color: white !important;
 }
.center-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 65vh;
  margin-left: $margin-20;

  @media (max-width: 900px) {
    flex-direction: row;
    min-height: 10vh;
    justify-content: space-around;
  }
}

.margin-bottom-sm {
  margin-bottom: $margin-10;
}

.margin-bottom-md {
  margin-bottom: $margin-20;
}

.label-check-box {
  display: flex;
}
.instruction {
  margin-top: $margin-20;
  font-size: 15px;
  color: red;
}
.label-add-attributes {
  margin-bottom: $margin-20;
  font-weight: bold;
  font-size: 1.3rem;

  @media (max-width: 900px) {
    margin-top: $margin-20;
  }
}

.label-add-attributes-checkbox {
  margin-left: 15px;
}

</style>
