var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ODataTable", {
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns.all,
          "add-edit-columns": _vm.columns.addEdit,
          resource: "tDisabledCategory",
          "total-label": _vm.$t("disabledCategory.total"),
          "file-name": _vm.$t("disabledCategory.fileName"),
          "add-row-button-label": _vm.$t("disabledCategory.add"),
          title: _vm.$t("disabledCategory.title"),
          "should-show-add-button": true,
          "should-show-delete-button": false,
          "should-show-edit-button": false
        },
        on: {
          rowInit: _vm.handleRowInit,
          editorPreparing: _vm.handleEditorPreparing
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }