var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ODataTable", {
    ref: "oDataTableComponentRef",
    attrs: {
      columns: _vm.columns,
      "add-edit-columns": _vm.addEditColumns,
      "is-static-data-source": true,
      "static-data": _vm.filteredTags,
      "total-label": _vm.$t("tagsByCustomers.total"),
      "file-name": _vm.$t("tagsByCustomers.fileName"),
      "add-row-button-label": _vm.$t("tagsByCustomers.add"),
      title: _vm.$t("tagsByCustomers.title"),
      "should-show-delete-button": false,
      "should-show-add-button": false,
      "should-show-edit-button": false
    },
    on: { selectionChange: _vm.handleSelectionChange },
    scopedSlots: _vm._u([
      {
        key: "custom-buttons",
        fn: function() {
          return [
            _c("DxButton", {
              attrs: { icon: "remove", "on-click": _vm.handleUnAssign }
            })
          ]
        },
        proxy: true
      },
      {
        key: "custom-toolbar-before",
        fn: function() {
          return [
            _vm.selectedRowsData.length
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.handleTagBatchUnAssign }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("unassign")) + " ")]
                )
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "custom-toolbar-after",
        fn: function() {
          return [
            _vm.showProgress
              ? _c("DxProgressBar", {
                  attrs: {
                    min: 0,
                    max: 100,
                    value: _vm.percentage,
                    width: "100%"
                  }
                })
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }