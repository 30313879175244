import { MUTATION_TYPES as BASE_MUTATION_TYPES } from '../mutations'
import Downloadable from '@/models/downloadable'

const MUTATION_TYPES = BASE_MUTATION_TYPES.DOWNLOADABLE

const state = {
  downloadable: new Downloadable()
}

const mutations = {
  [MUTATION_TYPES.SET_DOWNLOADABLE]: (state, updateParams) => {
    state.downloadable.updatePartial(updateParams)
  },

  [MUTATION_TYPES.SET_FOLDER_NAME_TO_OLD]: state => {
    state.downloadable.setFolderNameToOld()
  },

  [MUTATION_TYPES.DISABLE_FIELDS]: state => {
    state.downloadable.disableFields()
  },

  [MUTATION_TYPES.RESET_DOWNLOADABLE]: state => {
    state.downloadable.reset()
  }
}

const actions = {
  setSelectedFiles({ commit }, { selectedFile }) {
    commit(MUTATION_TYPES.SET_DOWNLOADABLE, { selectedFile })
  },
  setDownloadableStates({ commit }, updateParams) {
    commit(MUTATION_TYPES.SET_DOWNLOADABLE, updateParams)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
