var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: "50%",
        "before-close": _vm.handleDialogClose
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        opened: _vm.handleDialogOpen
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "pageFormRef",
          attrs: {
            model: _vm.page,
            rules: _vm.rules,
            "label-position": "top",
            size: "small"
          }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 15 } },
            [
              _c(
                "el-col",
                { attrs: { sm: 24, md: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("name"), prop: "PageName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.page.PageName,
                          callback: function($$v) {
                            _vm.$set(_vm.page, "PageName", $$v)
                          },
                          expression: "page.PageName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { sm: 24, md: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("site"), prop: "SiteId" } },
                    [
                      _c("content-part-select", {
                        staticClass: "select-options",
                        attrs: {
                          placeholder:
                            _vm.$t("select") + " " + _vm.$t("contentPart.site"),
                          selected: _vm.page.SiteID,
                          options: _vm.sites,
                          "allow-create": false,
                          size: "small",
                          "prop-value": "ID",
                          "prop-name": "Name"
                        },
                        on: {
                          "update:selected": function($event) {
                            return _vm.$set(_vm.page, "SiteID", $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 15 } },
            [
              _c(
                "el-col",
                { attrs: { sm: 24, md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("contentPart.position"),
                        prop: "Position"
                      }
                    },
                    [
                      _c("content-part-select", {
                        staticClass: "select-options",
                        attrs: {
                          placeholder:
                            _vm.$t("select") +
                            " " +
                            _vm.$t("contentPart.position"),
                          selected: _vm.page.Position,
                          options: _vm.positions,
                          "allow-create": true,
                          size: "small",
                          "prop-value": "Position",
                          "prop-name": "Position"
                        },
                        on: {
                          "update:selected": function($event) {
                            return _vm.$set(_vm.page, "Position", $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { sm: 24, md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("contentPart.tag"), prop: "Tag" }
                    },
                    [
                      _c("content-part-select", {
                        staticClass: "select-options",
                        attrs: {
                          placeholder:
                            _vm.$t("select") + " " + _vm.$t("contentPart.tag"),
                          selected: _vm.page.Tag,
                          options: _vm.tags,
                          "allow-create": true,
                          size: "small",
                          "prop-value": "Tag",
                          "prop-name": "Tag"
                        },
                        on: {
                          "update:selected": function($event) {
                            return _vm.$set(_vm.page, "Tag", $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleDialogClose } }, [
            _vm._v(_vm._s(_vm.$t("cancel")))
          ]),
          _vm.canEdit
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.onSubmit(true, _vm.page.IsActive)
                    }
                  }
                },
                [_vm._v(_vm._s("" + _vm.$t("editPage")))]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading.fullscreen.lock",
                  value: _vm.isPageDialogLoading,
                  expression: "isPageDialogLoading",
                  modifiers: { fullscreen: true, lock: true }
                }
              ],
              attrs: { type: "primary", disabled: _vm.isSaveDisabled },
              on: {
                click: function($event) {
                  return _vm.onSubmit(false, _vm.page.IsActive)
                }
              }
            },
            [_vm._v(_vm._s("" + _vm.$t("save")))]
          ),
          _vm.dialogType === _vm.DIALOG_TYPES.EDIT && !_vm.page.IsActive
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.fullscreen.lock",
                      value: _vm.isPageDialogLoading,
                      expression: "isPageDialogLoading",
                      modifiers: { fullscreen: true, lock: true }
                    }
                  ],
                  attrs: { type: "success" },
                  on: {
                    click: function($event) {
                      return _vm.onSubmit(false, true)
                    }
                  }
                },
                [_vm._v(_vm._s("" + _vm.$t("editPageMakeActiveButton")))]
              )
            : _vm._e(),
          _vm.dialogType === _vm.DIALOG_TYPES.EDIT && _vm.page.IsActive
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.fullscreen.lock",
                      value: _vm.isPageDialogLoading,
                      expression: "isPageDialogLoading",
                      modifiers: { fullscreen: true, lock: true }
                    }
                  ],
                  attrs: { type: "warning" },
                  on: {
                    click: function($event) {
                      return _vm.onSubmit()
                    }
                  }
                },
                [_vm._v(_vm._s("" + _vm.$t("editPageMakeInactiveButton")))]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }