import { DateUtils } from '@/utils/date'

export const dateColumns = [
  {
    field: 'DateAdded',
    width: 110,
    dataType: 'date',
    format: DateUtils.odataDateFormat
  },
  {
    field: 'LastUpdate',
    width: 110,
    dataType: 'date',
    format: DateUtils.odataDateFormat,
    sort: 'desc'
  },
  {
    field: 'DateApproved',
    width: 110,
    dataType: 'date',
    format: DateUtils.odataDateFormat
  }
]

const customersIdField = 'CustomersID'
const toggleColumns = ['bIsShared', 'IsApproved']

export const dateColumnsName = dateColumns.map(field => field.field)

export const addEditColumns = [
  customersIdField,
  'tTestID',
  'tTestQuestionID',
  'tTestQuestionAnswerID',
  'AnswerText',
  'tTestGroupdID',
  'AppID',
  'MediaID',
  'ApprovedByAdminID',
  'ApprovalComment',
  ...toggleColumns
].map(field => ({
  field,
  width: 100,
  ...(field === customersIdField && {
    required: true,
    requiredMessage: 'reflections.column.customersId.requiredMessage'
  }),
  ...(toggleColumns.includes(field) && { dataType: 'boolean' })
}))

export const detailsColumns = [...addEditColumns, ...dateColumns].map(
  field => field.field
)

export const columns = [
  { field: 'ID', width: 110, sort: 'desc' },
  ...addEditColumns,
  ...dateColumns
]
