import Store from '@/models/odataStore'
export const addEditColumns = [
  { field: 'AppID' },
  {
    field: 'CategoriesID',
    label: 'category',
    lookup: {
      store: new Store({ resource: 'Categories' }),
      paginate: true,
      pageSize: 50
    },
    valueExpr: 'ID',
    displayExpr: 'Name'
  },
  { field: 'Completed' },
  { field: 'CustomerID' },
  { field: 'Downloaded' },
  { field: 'EnteredDate', sort: 'desc' },
  { field: 'ExpiryDate' },
  { field: 'HasViewed' },
  { field: 'IsAccess', dataType: 'boolean' },
  { field: 'IsAdded' },
  { field: 'IsAssigned' },
  { field: 'IsDone' },
  { field: 'IsDoneAt' },
  { field: 'IsRemoved' },
  {
    field: 'MediaID',
    label: 'media',
    lookup: {
      store: new Store({ resource: 'Media' }),
      paginate: true,
      pageSize: 50
    },
    valueExpr: 'ID',
    displayExpr: 'Title'
  },
  { field: 'PartlyUsed' },
  { field: 'Priority' },
  { field: 'RemovedAtDate', dataType: 'date' },
  {
    field: 'SiteID',
    label: 'site',
    lookup: {
      store: new Store({ resource: 'Sites' }),
      paginate: true,
      pageSize: 50
    },
    valueExpr: 'ID',
    displayExpr: 'SiteName'
  },
  { field: 'SortOrder' },
  { field: 'SubCategoryID' },
  { field: 'TestComplete' },
  { field: 'UsedDate', dataType: 'date' },
  { field: 'bCompletionRequired' }
].map(column => ({ ...column, width: 150 }))

export const columns = [...addEditColumns, { field: 'ID', width: 150 }]
