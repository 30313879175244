var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _vm._v(" " + _vm._s(_vm.name) + " "),
                _c("div", { staticClass: "user-avatar" }, [
                  _c("i", { staticClass: "el-icon-user" })
                ])
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(" " + _vm._s(_vm.$t("home")) + " ")
                      ])
                    ],
                    1
                  ),
                  _vm.user.isSuperAdmin
                    ? _c(
                        "el-dropdown",
                        {
                          staticClass: "menu-container",
                          attrs: { trigger: "click", placement: "left-start" }
                        },
                        [
                          _c("el-dropdown-item", [
                            _vm._v(" " + _vm._s(_vm.$t("manage")) + " ")
                          ]),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: "/manage/menus" } },
                                [
                                  _c("el-dropdown-item", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("menus.name")) + " "
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "router-link",
                                { attrs: { to: "/manage/admins" } },
                                [
                                  _c("el-dropdown-item", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("admins.name")) + " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.logout.apply(null, arguments)
                        }
                      }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("logout")))])]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-menu select-width" },
        [_c("locale-switcher")],
        1
      ),
      _c(
        "div",
        { staticClass: "right-menu select-width" },
        [_c("site-switcher", { on: { change: _vm.updateContentForSiteID } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }