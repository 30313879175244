var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DxForm", {
    staticClass: "address-form form-container",
    attrs: {
      "form-data": _vm.data,
      items: _vm.items,
      "col-count": 3,
      "customize-item": _vm.customizeItem
    },
    scopedSlots: _vm._u(
      [
        {
          key: "form-item",
          fn: function(ref) {
            var item = ref.data
            return [
              _c("span", [_vm._v(" " + _vm._s(item.editorOptions.value) + " ")])
            ]
          }
        },
        {
          key: "custom-item",
          fn: function(ref) {
            var item = ref.data
            return [_vm._t("custom-item", null, { item: item })]
          }
        },
        {
          key: "lookup-value",
          fn: function(ref) {
            var item = ref.data
            return [
              _c("span", [_vm._v(" " + _vm._s(_vm.getLookUpValue(item)) + " ")])
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }