import AssistantDefaults from '@/api/assistantDefaults'
const sessionIdField = 'sessionid'
export default {
  methods: {
    async setChat(chatElementRef) {
      chatElementRef.request = {
        handler: async(body, signals) => {
          const messages = chatElementRef.getMessages()
          const userMessages = messages.filter(
            message => message.role === 'user'
          )
          const userMessage = userMessages[userMessages?.length - 1]?.text
          const data = {
            userMessage,
            customerID: this.loggedInUser,
            assistantDefaultsID: this.assistantDefault.ID,
            mediaID: this.assistantDefault.MediaID,
            categoryID: this.assistantDefault.CategoryID,
            uid: this.user.UID,
            sessionID: this.sessionID
          }

          const source = AssistantDefaults.fetchStreamData(data)

          source.onerror = () => {
            signals.onClose()
            source.close()
          }

          source.onmessage = event => {
            if (event.data.includes(sessionIdField)) {
              this.sessionID = event.data.split('=')[1]
              source.close()
              signals.onClose()
            } else {
              signals.onResponse({ text: event.data })
            }
          }
        }
      }
    }
  }
}
