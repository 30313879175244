import AssistantService from '@/api/assistants'
import { Message } from 'element-ui'

export default {
  computed: {
    directConnection() {
      return `{
            "openAI": {
              "key": "${process.env.VUE_APP_OPENAI_API_KEY}",
              "assistant": {"assistant_id": "${this.assistant.id}"}
            }
          }`
    },
    assistantThreads() {
      if (this?.assistant?.metadata?.payload?.threadIds?.length) {
        return JSON.parse(this?.assistant?.metadata?.payload?.threadIds)
      }
      if (this.sessionId) {
        return [this.sessionId]
      }
      return []
    },
    threads() {
      return this.assistantThreads.map((thread, index) => {
        return {
          id: index + 1,
          value: thread
        }
      })
    }
  },
  mounted() {
    if (this.sessionId) {
      const filteredData = this.customerData.filter(
        data => data.SessionID === this.sessionId
      )
      this.setHistory(filteredData)
    }
  },
  methods: {
    handleChange(newThreadId) {
      this.selectedThreadId = newThreadId
      this.setMessages()
    },
    handleNewMessage(event) {
      event.target._activeService.sessionId = this.selectedThreadId
    },
    setHistory(filteredData) {
      const response = []

      for (const data of filteredData) {
        response.push({
          text: data.UserMessage,
          role: 'user'
        })
        response.push({
          text: data.BotResponse,
          role: 'ai'
        })
      }
      this.initialMessages = JSON.stringify(response)
    },
    async setMessages() {
      const messagesToShow = await this.retrieveMessages()
      let response = messagesToShow?.data?.reverse()
      if (response?.length) {
        response = response.map((data, i) => {
          if (i % 2 !== 0) {
            return {
              text: `${data.content[0].text.value}`,
              role: 'ai'
            }
          }
          return {
            text: `${data.content[0].text.value}`,
            role: 'user'
          }
        })
        this.initialMessages = JSON.stringify(response)
      }
    },
    async retrieveMessages() {
      try {
        const response = await AssistantService.retrieveThreadMessages(
          this.selectedThreadId
        )
        return response
      } catch (error) {
        Message({
          type: 'error',
          message: error.message
        })
      }
      return []
    }
  }
}
