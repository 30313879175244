import AccountService from '@/api/account'
import { Account } from '@/models/account'
import { MUTATION_TYPES as BASE_MUTATION_TYPES } from '../../../mutations'

const MUTATION_TYPES = BASE_MUTATION_TYPES.USER.ACCOUNTS

const initialState = () => ({
  accounts: [],
  selectedAccount: '',
  selectedSubAccount: '',
  isLoading: false,
  selectedAccountInfo: null
})

const mutations = {
  [MUTATION_TYPES.SET_ACCOUNTS]: (state, accounts = []) => {
    state.accounts = Account.groupAccountsAndSubAccounts(accounts)
    state.isLoading = false
  },

  [MUTATION_TYPES.SET_LOADING]: (state, isLoading = false) => {
    state.isLoading = isLoading
  },

  [MUTATION_TYPES.SET_SELECTED_ACCOUNT]: (state, selectedAccount = '') => {
    state.selectedAccount = selectedAccount
    state.selectedAccountInfo = state.accounts.find(
      account => account.Account === selectedAccount
    )
  },
  [MUTATION_TYPES.SET_SELECTED_SUB_ACCOUNT]: (
    state,
    selectedSubAccount = ''
  ) => {
    state.selectedSubAccount = selectedSubAccount
    if (selectedSubAccount) {
      const subAccounts = state.selectedAccountInfo.subAccounts
      state.selectedAccountInfo = state.selectedAccountInfo.subAccounts.find(
        subAccount => subAccount.SubAccount === selectedSubAccount
      )
      state.selectedAccountInfo.subAccounts = subAccounts
    } else {
      state.selectedAccountInfo = state.accounts.find(
        account => account.Account === state.selectedAccount
      )
    }
  }
}

const actions = {
  async fetchAccounts({ commit }) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      const accounts = await AccountService.getAll()
      commit(MUTATION_TYPES.SET_ACCOUNTS, accounts)
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPES.SET_LOADING, false)
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions
}
