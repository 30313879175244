<template>
  <div class="app-container">
    <UserDetail
      :selected-customer="selectedCustomer"
      :assigned-tags="assignedTags"
      :sites="sites"
      :show-details="showDetails"
      @detailsDialogClose="handleDetailsDialogClose"
    />
    <el-header>
      <el-row type="flex">
        <el-col class="left-align">
          <span class="heading">{{ $t('shared.title') }}</span>
        </el-col>
      </el-row>
    </el-header>
    <DxFileManager :file-system-provider="fileSystemProvider" :on-selection-changed="handleSelectionChanged">
      <DxPermissions :download="showDownloadButton" :delete="true" :upload="true" />
      <DxToolbar>
        <DxItem name="showNavPane" :visible="true" />
        <DxItem name="separator" />
        <DxItem name="upload" />
        <DxFileSelectionItem name="showNavPane" :visible="true" />
        <DxFileSelectionItem name="separator" />
        <DxFileSelectionItem name="download" />
        <DxFileSelectionItem name="separator" />
        <DxFileSelectionItem name="delete" />
        <DxFileSelectionItem name="card" template="uploaded-user" />
      </DxToolbar>
      <template #uploaded-user>
        <DxButton v-if="showUploadUserIcon" icon="card" text="UploadedUser" :on-click="openPopupWithCustomerDetails" />
      </template>
    </DxFileManager>
  </div>
</template>
<script>
import {
  DxFileManager,
  DxPermissions,
  DxToolbar,
  DxItem,
  DxFileSelectionItem
} from 'devextreme-vue/file-manager'
import { DxButton } from 'devextreme-vue/button'
import { createNamespacedHelpers } from 'vuex'
import { FileSystemProvider } from '@/models/fileSystemProvider'
import { FOLDER } from '@/utils/filename'
const { mapState, mapActions } = createNamespacedHelpers('files')
const type = FOLDER.SHARED
import { MAX_ALLOWED_FILES_FOR_DOWNLOAD } from '@/utils/filename'
import UserDetail from '@/components/UserDetail'
import { LOG_ACTION, tableName } from '@/views/customers/config'
import CustomerService from '@/api/customer'
import { Customer } from '@/models/customer'
const { mapActions: mapSharedSiteActions, mapState: mapSharedSiteState } =
  createNamespacedHelpers('shared/site')
const { mapActions: mapTagActions, mapState: mapTagState } =
  createNamespacedHelpers('tags')
const { mapState: mapCustomerState, mapActions: mapCustomerActions } = createNamespacedHelpers('customer')

export default {
  name: 'Download',
  components: {
    DxFileManager,
    DxPermissions,
    DxToolbar,
    DxItem,
    DxFileSelectionItem,
    DxButton,
    UserDetail
  },
  data() {
    return {
      showDownloadButton: true,
      showUploadUserIcon: false,
      uploadedUserId: null,
      selectedCustomer: null,
      showDetails: false
    }
  },
  computed: {
    ...mapState({
      sharedFiles: state => state.sharedFiles
    }),
    ...mapSharedSiteState({
      sites: state => state.sites
    }),
    ...mapTagState({
      tags: (state) => state.tags
    }),
    fileSystemProvider() {
      return new FileSystemProvider({
        items: this.sharedFiles
      })
    },
    ...mapCustomerState({
      assignedTagIds: (state) => state.assignedTagIds
    }),
    assignedTags() {
      return this.tags.filter((tag) => this.assignedTagIds.includes(tag.ID)).map((tag) => tag.TagName)
    }
  },
  async mounted() {
    await this.fetchFiles(type)
    if (!this.tags.length) {
      await this.fetchAllTags()
    }
    if (!this.sites.length) {
      await this.fetchSites()
    }
  },
  methods: {
    ...mapActions(['fetchFiles']),
    ...mapSharedSiteActions({
      fetchSites: 'fetchAll'
    }),
    ...mapTagActions({
      fetchAllTags: 'fetchAll'
    }),
    ...mapCustomerActions(['logAction']),
    handleSelectionChanged(event) {
      this.showDownloadButton = event.selectedItemKeys.length <= MAX_ALLOWED_FILES_FOR_DOWNLOAD
      if (event?.selectedItems?.dataItem?.uploadedUserId) {
        this.showUploadUserIcon = true
        this.uploadedUserId = event?.selectedItems?.dataItem?.uploadedUserId
      } else {
        this.showUploadUserIcon = false
      }
    },
    async openPopupWithCustomerDetails() {
      const { value } = await CustomerService.getById(Number(this.uploadedUserId))

      await this.logAction({
        TableName: tableName,
        ObjectID: this.uploadedUserId,
        Action: LOG_ACTION.VIEW,
        DateViewed: new Date()
      })
      this.selectedCustomer = new Customer(value[0])
      this.showDetails = true
    },
    handleDetailsDialogClose() {
      this.selectedCustomer = null
      this.showDetails = false
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .dx-button-mode-contained {
  max-width: 180px !important;
  margin-left: 20px;
  background-color: #F7F7F7;
  box-shadow: none;

  &:hover {
    background-color: #E4E4E4;
  }
}
</style>
