var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AttributeSelection", {
        attrs: { "selected-users": _vm.allUserData }
      }),
      _c("ODataTable", {
        ref: "sendListRef",
        attrs: {
          columns: _vm.columns,
          "add-edit-columns": _vm.addEditColumns,
          "should-show-delete-button": false,
          "total-label": _vm.$t("updateSendList.total"),
          "file-name": _vm.$t("updateSendList.fileName"),
          "should-show-add-button": false,
          "is-static-data-source": true,
          "static-data": _vm.staticData
        },
        on: {
          selectionChange: _vm.handleSelectionChange,
          rowSaving: _vm.handlesaving
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }