var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DxCheckBox", {
    ref: "checkBoxRef",
    attrs: {
      value: _vm.field.value,
      disabled: _vm.disabled,
      "read-only": !_vm.field.isOnForm
    },
    on: { "value-changed": _vm.handleChange },
    nativeOn: {
      click: function($event) {
        return _vm.handleClick.apply(null, arguments)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }