<template>
  <div class="app-container">
    <el-header v-if="!hasParent" height="auto">
      <el-row type="flex">
        <el-col class="left-align">
          <span class="heading">{{ title }}</span>
        </el-col>
      </el-row>
    </el-header>
    <el-main class="dx-viewport">
      <slot name="custom-filters" />
      <DxDataGrid
        ref="oDataTableRef"
        :data-source="showData ? dataSource : null"
        :show-borders="true"
        :class="{
          'dx-with-expand': shouldRenderMasterDetail,
          'dx-without-expand': !shouldRenderMasterDetail
        }"
        :allow-column-resizing="allowColumnResizing"
        :remote-operations="isCustomDataSource || 'auto'"
        @init-new-row="handleRowInit"
        @row-inserted="handleRowInserted"
        @row-updated="handleRowUpdated"
        @row-removed="handleRowRemoved"
        @toolbar-preparing="handleToolbarPreparing"
        @editor-preparing="handleEditorPreparing"
        @row-expanded="rowExpanded"
        @row-collapsed="handelRowCollapsed"
        @selection-changed="handleSelectionChange"
        @content-ready="handleContentReady"
        @cell-click="handleCellClick"
        @row-updating="handleRowUpdating"
        @editing-start="handleEditingStart"
        @cell-prepared="handleCellPrepared"
        @edit-canceling="handleEditingCancelling"
        @row-inserting="handleRowInserting"
        @saved="handleRowSaved"
        @saving="handleRowSaving"
        @exporting="handleExporting"
        @data-error-occurred="handleDataErrorOccurred"
      >
        <DxEditing
          :allow-updating="!isCustomEdit && shouldShowEditButton"
          :allow-deleting="shouldShowDeleteButton"
          :confirm-delete="shouldShowDeleteButton"
          :use-icons="true"
          mode="popup"
        >
          <DxTexts
            v-if="customDeleteMessage"
            :confirm-delete-message="customDeleteMessage"
          />
          <DxForm v-if="!isCustomForm" ref="dxFormRef">
            <DxSimpleItem
              v-for="column in addEditColumns"
              ref="dxSimpleItem"
              :key="`add_edit_${column.field}`"
              :data-field="column.field"
              :col-span="column.colSpan || 0"
              :help-text="$t(column.helpText)"
              :editor-options="column.editorOptions || editorOptions"
              :editor-type="column.editorName"
            >
              <DxEmailRule v-if="isFieldEmail(column.field)" :message="$t('forgotPassword.invalidEmailError')" />
            </DxSimpleItem>
          </DxForm>

          <DxForm
            v-if="isCustomForm"
            :col-count="columnCount"
            template="ss"
          >
            <slot name="custom-form" />
          </DxForm>
        </DxEditing>
        <template #file-item="{ data }">
          <FileUploader ref="dxFileUploaderRef" :field="data" @fileToBeDeleted="handleFileToBeDeleted" @fileUploaded="handleFileUploaded" />
        </template>
        <template #checkbox-item="{ data }">
          <checkbox-item :field="data" />
        </template>
        <template #custom-toolbar-before>
          <slot name="custom-toolbar-before" />
        </template>
        <template #custom-toggle-before>
          <slot name="custom-toggle-before" />
        </template>
        <template #add>
          <ButtonWithText
            v-if="shouldShowAddButton"
            icon="add"
            :text="addRowButtonLabel"
            type="default"
            styling-mode="outlined"
            @click="addRow"
          />
        </template>
        <template #clear-filters>
          <div>
            <ButtonWithText
              v-if="hasFilters"
              type="default"
              styling-mode="outlined"
              icon="close"
              :text="$t('clear')"
              @click="handleFiltersClear"
            />
          </div>
        </template>
        <template #total>
          <span class="total">
            {{ `${totalLabel}: ${total}` }}
          </span>
        </template>
        <template #custom-toolbar-after>
          <slot name="custom-toolbar-after" />
        </template>
        <template #custom-button-template="{ data }">
          <slot name="toggle-button" :data="data" />
        </template>
        <DxColumn type="selection" :width="50" />

        <DxColumn type="buttons" :width="actionButtonsWidth">
          <DxButton
            v-if="shouldShowEditButton"
            name="edit"
            :visible="isEditDeleteButtonVisible"
          />
          <DxButton
            v-if="isCustomEdit"
            icon="edit"
            :on-click="handleEditClick"
          />
          <slot name="custom-buttons" />
          <DxButton
            v-if="shouldShowDeleteButton"
            name="delete"
            :visible="isEditDeleteButtonVisible"
          />
        </DxColumn>
        <DxColumn
          v-for="column in dataSource.columns"
          :key="column.field"
          :data-field="column.field"
          :width="column.width"
          :min-width="column.minWidth"
          :alignment="column.alignment || 'left'"
          :editor-options="{
            onOpened: handleLookupPopupOpen,
            disabledIds: column.disabledIds,
            includeFileNameOnly: !!column.includeFileNameOnly,
            folder: column.type,
            accept: column.accept,
            prop: column.prop,
            cdnUrl: column.cdnUrl,
            shouldUseFolderForPreview: column.shouldUseFolderForPreview,
            showAsTextBox: column.showAsTextBox,
            // isDisabled: column.isDisabled,
            selectedAssistants: column.selectedAssistants,
            mode: column.mode,
            shouldShowUploadedImagePreview:
              column.shouldShowUploadedImagePreview
          }"
          :sort-index="1"
          :sort-order="column.sort"
          :edit-cell-template="column.editCellTemplate"
          :data-type="column.dataType"
          :caption="column.label ? $t(column.label) : column.field"
          :format="column.format"
          :calculate-cell-value="column.calculateCellValue"
          :set-cell-value="column.setCellValue"
          :allow-adding="false"
          :allow-filtering="column.allowFiltering"
          :visible="column.visible"
          :calculate-filter-expression="column.calculateFilterExpression"
        >
          <DxLookup
            v-if="column.lookup"
            :data-source="column.lookup"
            :value-expr="column.valueExpr || 'value'"
            :display-expr="column.displayExpr || 'label'"
          />
          <DxRequiredRule
            v-if="column.required"
            :message="$t(column.requiredMessage)"
          />
        </DxColumn>

        <DxMasterDetail :enabled="shouldRenderMasterDetail" template="detail" />
        <template #detail="{ data }">
          <slot name="details" :data="data.row.data" />
        </template>
        <DxExport
          :file-name="fileName"
          :enabled="true"
          :allow-export-selected-data="true"
          :formats="exportFormats"
        />

        <DxFilterRow :visible="lookupFilter" />
        <DxSelection mode="multiple" />
        <DxStateStoring :enabled="isStateStored" type="localStorage" storage-key="storage" />
        <DxPager
          display-mode="compact"
          :visible="true"
          :show-page-size-selector="true"
          :allowed-page-sizes="dataSource.allowedPageSizes"
          :show-navigation-buttons="true"
        />
      </DxDataGrid>
    </el-main>
  </div>
</template>
<script>
import mixin from './mixin'
export default {
  mixins: [mixin]
}
</script>
<style lang="scss" src="./index.scss"></style>
