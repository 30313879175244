import BrevoDataStore from '@/models/BrevoDataStore'
import { DateUtils } from '@/utils/date'

export const toggleColumn = 'IsActive'
export const disabledColumns = ['AddedDate', 'LastUpdate']

export const readOnlyColumns = ['SenderEmail']

const NoteOptions = [
  { option: 'Number of licenses' },
  { option: 'Number of users' }
]

const dropDownColumns = ['Note', 'SenderName']

const BrevoStore = new BrevoDataStore({
  resource: 'senders',
  dataPath: 'senders'
})

const dropDownOptions = {
  Note: { options: NoteOptions, valueExpr: 'option', displayExpr: 'option' },
  SenderName: {
    options: BrevoStore,
    valueExpr: 'name',
    displayExpr: 'name'
  }
}

const disabledColumnsTypeAndFormat = {
  AddedDate: {
    dataType: 'date',
    format: DateUtils.odataDateFormat,
    editorOptions: {}
  },
  LastUpdate: {
    dataType: 'date',
    format: DateUtils.odataDateFormat,
    editorOptions: {}
  }
}

const readOnlyColumnsTypeAndFormat = {
  SenderEmail: { dataType: 'text', format: null, editorOptions: {}}
}

const labelChange = [
  {
    field: 'SitePortal',
    label: 'site'
  },
  {
    field: 'Organization',
    label: 'Organization'
  },
  {
    field: 'ShortSignature',
    label: 'ShortSignature'
  },
  {
    field: 'ContactPersonPosition',
    label: 'ContactPersonPosition'
  },
  {
    field: 'Signature',
    label: 'signature'
  }
]

export const addEdit = [
  'Account',
  toggleColumn,
  'Licenses',
  'SubAccount',
  'Organization',
  'ShortSignature',
  'ContactPersonPosition',
  'SitePortal',
  'UseSSO',
  'Logo',
  'Signature',
  'NewManagerProgram',
  ...dropDownColumns,
  ...readOnlyColumns,
  ...disabledColumns,
  'ActiveUsers'
].map(field => ({
  field,
  width: 100,
  ...((field === toggleColumn || field === 'UseSSO') && {
    dataType: 'boolean',
    alignment: 'center'
  }),
  ...(disabledColumns.includes(field) && disabledColumnsTypeAndFormat[field]),
  ...(readOnlyColumns.includes(field) && readOnlyColumnsTypeAndFormat[field]),
  ...field,
  ...(dropDownColumns.includes(field) && {
    lookup: {
      store: dropDownOptions[field]?.options,
      paginate: true,
      pageSize: 50
    },
    editorOptions: {},
    setCellValue: (data, value) => {
      if (field === 'SenderName') {
        const senders = BrevoStore?.response ?? []
        const senderFound = senders.find(sender => sender.name === value)
        data['SenderEmail'] = senderFound?.email ?? ''
        data['SenderName'] = senderFound?.name ?? ''
      } else {
        data[field] = value
      }
    },
    valueExpr: dropDownOptions[field]?.valueExpr,
    displayExpr: dropDownOptions[field]?.displayExpr
  }),
  ...labelChange.find(value => value.field === field)
}))

export const columns = [
  {
    field: 'ID',
    width: '100%'
  },
  ...addEdit
]
