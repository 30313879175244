import Store from '@/models/odataStore'
export const disabledColumns = ['DateAdded']
const siteIdColumn = 'SiteID'
const customerIdColumn = 'CustomersID'
const sortOrderColumn = 'SortOrder'
const coWorkerCourseIDColumn = {
  field: 'CoWorkerCourseID',
  width: '100%',
  label: 'assignedCoWorkersCourses.column.coWorkerCourseID',
  lookup: {
    store: new Store({ resource: 'CoWorkerCourse' }),
    paginate: true,
    pageSize: 50
  },
  valueExpr: 'ID',
  displayExpr: 'Name',
  calculateCellValue: event => {
    if (!event.CoWorkerCourseID) {
      return null
    }
    return event.CoWorkerCourseID
  }
}

export const dateColumns = ['ExpiryDate']

export const columnsWithNoLookup = [
  customerIdColumn,
  siteIdColumn,
  ...disabledColumns,
  ...dateColumns,
  sortOrderColumn
].map(field => ({
  field,
  width: '100%',
  ...(dateColumns.includes(field) && {
    dataType: 'date',
    label: 'assignedCoWorkersCourses.column.expiryDate'
  }),
  ...(disabledColumns.includes(field) && {
    label: 'assignedCoWorkersCourses.column.dateAdded'
  }),
  ...(field === customerIdColumn && {
    calculateCellValue: event => {
      if (!event.CustomersID) {
        return null
      }
      return event.CustomersID
    },
    label: 'customerId'
  }),
  ...(field === siteIdColumn && {
    label: 'site',
    lookup: {
      store: new Store({ resource: 'Sites' }),
      paginate: true,
      pageSize: 50
    },
    valueExpr: 'ID',
    displayExpr: 'SiteName'
  }),
  ...(field === sortOrderColumn && {
    label: 'sortOrder'
  })
}))

export const addEditColumns = [coWorkerCourseIDColumn, ...columnsWithNoLookup]
export const columns = [{ field: 'ID', width: '100%' }, ...addEditColumns]
