<template>
  <div class="app-container">
    <el-dialog
      v-if="selectedReflection"
      :visible.sync="showDetails"
      @close="handleDetailsDialogClose"
    >
      <Detail :detail="{...selectedReflection}" />
    </el-dialog>
    <el-dialog
      :title="$t('reflections.reportFilter')"
      :visible.sync="isReportsFilterDialogOpen"
      width="60%"
      @close="handleReportsDetailsDialogClose"
    >
      <ReportFilters @filter="handleFilterApply" @closeFilter="handlerCloseReportFilter" />
    </el-dialog>
    <ODataTable
      ref="oDataTableComponentRef"
      :key="filter"
      :columns="columns"
      :add-edit-columns="addEditColumns"
      :alias-resource="aliasResource"
      resource="tTestCustomerAnswerPortal"
      :total-label="$t('reflections.total')"
      :should-show-edit-button="false"
      :should-show-add-button="false"
      :file-name="$t('reflections.fileName')"
      :add-row-button-label="$t('reflections.add')"
      :title="$t('reflections.title')"
      :action-buttons-width="100"
      :filter="filter"
      @selectionChange="handleSelectionChange"
      @rowInit="handleRowInit"
      @rowUpdating="handleRowUpdating"
    >
      <template #custom-buttons>
        <DxButton icon="check" :visible="isApproved" />
        <DxButton icon="remove" :visible="isRejected" />
        <DxButton icon="help" :visible="hasNotBeenReviewed" />
        <DxButton icon="info" :on-click="handleViewClick" />
      </template>
      <template #custom-toolbar-before>
        <div>
          <el-switch v-model="reviewModeEnabled" :active-text="$t('showOnlyShared')" @change="handlerReportsFilter" />
          <el-button v-if="filters.reviewModeEnabled && selectedReflectionIds.length && canReview" :type="defaultType" plain @click="redirectToReviewPage">{{ $t('review') }}</el-button>
          <el-button :type="defaultType" plain @click="handleReportsDetailsDialogOpen">{{ $t('report') }}</el-button>
        </div>
      </template>
    </ODataTable>
  </div>
</template>
<script>
import mixin from './mixin'
export default {
  mixins: [mixin]
}
</script>
<style scoped>
::v-deep .dx-icon-remove::before {
  color: red !important;
}
::v-deep .el-button--primary.is-plain {
  margin: 10px
}
::v-deep .dx-icon-help::before {
  color: rgb(0, 128, 122) !important
}
.el-date-editor.el-input{
  width: 100%;
}
</style>
