import { AssistantTool } from './assistantTool'

export class AssistantTools {
  maxNoOfTools = 127;

  constructor(tools = []) {
    this.assistantTools = AssistantTool.fromList(
      tools instanceof AssistantTools ? tools.assistantTools : tools
    )
  }

  get canAdd() {
    if (this.assistantTools?.length) {
      return this.assistantTools.length <= this.maxNoOfTools
    }
    return true
  }

  add(tool) {
    if (this.assistantTools?.length) {
      this.assistantTools.unshift(new AssistantTool(tool))
      this.refresh()
    }
  }

  removeFirst() {
    this.assistantTools.shift()
    this.refresh()
  }

  findByIndex(index) {
    return this.assistantTools.find(
      (_, currentIndex) => currentIndex !== index - 1
    )
  }

  addAtIndex(index, tool) {
    this.assistantTools.splice(index - 1, 0, tool)
  }

  update(index, tool) {
    this.assistantTools[index - 1].updatePartial(tool)
  }

  remove(index) {
    this.assistantTools = this.assistantTools.filter(
      (_, currentIndex) => currentIndex !== index - 1
    )
    this.refresh()
  }

  refresh() {
    this.assistantTools.forEach((tool, index) => {
      tool.updatePartial({ index: index + 1 })
    })
  }

  get payload() {
    return this.assistantTools?.map(tool => tool.payload)
  }
}
