import { TOOL_TYPES } from '@/utils/openAi'
const types = [
  {
    field: 'type',
    label: 'assistants.tools.column.type',
    width: '100%',
    lookup: TOOL_TYPES,
    required: true,
    requiredMessage: 'assistants.tools.requiredMessage.type'
  }
]

const functions = [
  {
    field: 'name',
    label: 'assistants.tools.column.name',
    width: '100%',
    required: true,
    requiredMessage: 'assistants.tools.requiredMessage.name'
  },

  {
    field: 'description',
    label: 'assistants.tools.column.description',
    width: '100%'
  },
  {
    field: 'parameters',
    label: 'assistants.tools.column.parameters',
    width: '100%',
    editorName: 'dxTextArea',
    required: true,
    requiredMessage: 'assistants.tools.requiredMessage.parameters',
    editorOptions: {
      height: 200,
      encodeHtml: true,
      placeholder: JSON.stringify(
        {
          type: 'object',
          properties: {}
        },
        null,
        2
      )
    }
  }
]

const addEdit = [...types, ...functions]
export const columns = {
  addEdit: addEdit.map(column => ({
    ...column,
    visible: column.field === 'type'
  })),
  types,
  functions,
  all: [
    {
      field: 'index',
      label: 'assistants.tools.column.seqNumber',

      width: '100%'
    },
    ...addEdit
  ]
}
