var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ODataTable", {
        key: _vm.filter,
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns.all,
          "add-edit-columns": _vm.columns.addEdit,
          resource: "ExternalMedia",
          "total-label": _vm.$t("downloadable.total"),
          "file-name": _vm.$t("downloadable.fileName"),
          "add-row-button-label": _vm.$t("downloadable.add"),
          title: _vm.$t("downloadable.title"),
          filter: _vm.filter
        },
        on: {
          editingStart: _vm.handleEditingStart,
          editorPreparing: _vm.handleEditorPreparing,
          editingCancelling: _vm.setDefaultValue,
          rowInserting: _vm.handleRowInserting,
          rowSaving: _vm.handleSaving,
          rowUpdating: _vm.handleUpdating,
          rowSaved: _vm.setDefaultValue
        },
        scopedSlots: _vm._u([
          {
            key: "custom-toolbar-after",
            fn: function() {
              return [
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      placeholder: _vm.$t("select") + " " + _vm.$t("category")
                    },
                    model: {
                      value: _vm.categoryId,
                      callback: function($$v) {
                        _vm.categoryId = $$v
                      },
                      expression: "categoryId"
                    }
                  },
                  [
                    _vm.categories.length
                      ? _c("el-option", { attrs: { label: "All", value: "" } })
                      : _vm._e(),
                    _vm._l(_vm.categories, function(category) {
                      return _c("el-option", {
                        key: category.value,
                        attrs: { label: category.label, value: category.value }
                      })
                    })
                  ],
                  2
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }