<template>
  <div>
    <ODataTable
      ref="oDataTableComponentRef"
      :columns="columns.lists"
      :add-edit-columns="columns.addEdit"
      :order-by="orderBy"
      resource="debug"
      :action-buttons-width="100"
      :total-label="$t('debug.total')"
      :file-name="$t('debug.fileName')"
      :title="$t('debug.title')"
      :should-show-add-button="false"
      :should-show-edit-button="false"
    />
  </div>
</template>

<script>
import ODataTable from '../../components/ODataTable'
import globalConfig from '../../columnsConfig/globalConfig.json'

export default {
  name: 'Debug',
  components: {
    ODataTable
  },
  data() {
    return {
      orderBy: 'ID desc'
    }
  },
  computed: {
    columns() {
      return globalConfig.debug
    }
  }
}
</script>
