import { GoalFilters } from '@/models/goalFilters'

const state = {
  filters: new GoalFilters({})
}

export default {
  namespaced: true,
  state
}
