import { OptionList } from './optionList'

export class DownloadableOptionList extends OptionList {
  filterByValueAndNonIdField() {
    return this.options
      .filter(heading => heading.value && heading.value !== 'ID')
      .map(heading => heading.value)
  }

  get selectedColumnsCount() {
    return this.options.filter(option => option.value)?.length
  }

  get isDisabled() {
    const isIdColumnSelected = this.findLabelByValue('ID')
    const areMultipleColumnsSelected = this.selectedColumnsCount > 1

    return !(isIdColumnSelected && areMultipleColumnsSelected)
  }
}
