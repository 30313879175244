import CustomerService from '@/api/customer'
import { Customer } from '@/models/customer'
import { MUTATION_TYPES as BASE_MUTATION_TYPES } from '../mutations'
import { PaginatedResponse } from '@/models/paginatedResponse'
import { Message } from 'element-ui'

const MUTATION_TYPES = BASE_MUTATION_TYPES.CUSTOMER

const state = {
  customer: null,
  isLoading: false,
  assignedTagIds: [],
  customers: [],
  filter: [],
  selectedTag: null
}

const mutations = {
  [MUTATION_TYPES.SET_CUSTOMER]: (state, customer) => {
    state.customer = new Customer(customer)
    state.isLoading = false
  },

  [MUTATION_TYPES.SET_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  },

  [MUTATION_TYPES.RESET_CUSTOMER]: state => {
    state.customer = null
  },

  [MUTATION_TYPES.SET_CUSTOMERS]: (state, customers) => {
    state.customers = Customer.fromList(customers)
  },

  [MUTATION_TYPES.SET_FILTER]: (state, filter) => {
    state.filter = filter
  },

  [MUTATION_TYPES.SET_SELECTED_TAG]: (state, tag) => {
    state.selectedTag = tag
  }
}

const actions = {
  async fetchCustomer({ commit }, id) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    commit(MUTATION_TYPES.RESET_CUSTOMER)
    try {
      const { value: customers } = await CustomerService.getById(id)
      commit(MUTATION_TYPES.SET_CUSTOMER, customers[0])
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPES.SET_LOADING, false)
      return Promise.reject(error)
    }
  },
  async fetchAndOpenImpersonateLink({ commit }, id) {
    try {
      commit(MUTATION_TYPES.SET_LOADING, true)

      const { URL } = await CustomerService.getImpersonateLink(id)
      commit(MUTATION_TYPES.SET_LOADING, false)
      window.open(URL, '_blank', 'location=yes')
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPES.SET_LOADING, false)
      return Promise.reject(error)
    }
  },
  async logAction({ commit }, data) {
    try {
      commit(MUTATION_TYPES.SET_LOADING, true)
      await CustomerService.log(data)
      const { ObjectID: customerId } = data
      const {
        value: assignedTags
      } = await CustomerService.fetchTagsByCustomerId(customerId)

      state.assignedTagIds = assignedTags.map(assignedTag => assignedTag.TagID)
      commit(MUTATION_TYPES.SET_LOADING, false)
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPES.SET_LOADING, false)
      return Promise.reject(error)
    }
  },
  async fetchAllCustomers({ commit }) {
    try {
      const response = await CustomerService.fetchAllCustomers()
      const paginatedResponse = new PaginatedResponse(response)
      while (paginatedResponse.hasMore) {
        await paginatedResponse.next()
      }
      commit(MUTATION_TYPES.SET_CUSTOMERS, paginatedResponse.items)
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPES.SET_LOADING, false)
      return Promise.reject(error)
    }
  },
  async fetchChatHistory(_, customerId) {
    try {
      const response = await CustomerService.fetchChatHistory(customerId)
      return Promise.resolve(response)
    } catch (error) {
      Message({
        type: 'error',
        message: error.message
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
