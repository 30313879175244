import { DateUtils } from '@/utils/date'
export const dateColumns = [
  {
    field: 'DateAdded',
    width: 110,
    dataType: 'date',
    format: DateUtils.odataDateFormat
  },
  {
    field: 'LastUpdate',
    width: 110,
    dataType: 'date',
    format: DateUtils.odataDateFormat,
    sort: 'desc'
  }
]

const customersIdField = 'CustomersID'

export const dateColumnsName = dateColumns.map(field => field.field)

export const addEditColumns = [
  customersIdField,
  'tTestID',
  'tTestQuestionID',
  'tTestQuestionAnswerID',
  'tTestGroupdID',
  'AppID',
  'MediaID',
  'AnswerText',
  'bIsShared'
].map(field => ({
  field,
  width: 100,
  ...(field === customersIdField && {
    required: true,
    requiredMessage: 'reflections.column.customersId.requiredMessage'
  })
}))

export const detailsColumns = [...addEditColumns, ...dateColumns].map(
  field => field.field
)

export const columns = [
  { field: 'ID', width: 110 },
  ...addEditColumns,
  ...dateColumns
]
