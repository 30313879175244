import { createNamespacedHelpers } from 'vuex'
import { MUTATION_TYPE } from '@/store/modules/contentPart'

const {
  mapState: mapAccountsState,
  mapActions: mapAccountsActions
} = createNamespacedHelpers('user/accounts')

const {
  mapState: mapMediaReportsState,
  mapActions: mapMediaReportsActions
} = createNamespacedHelpers('mediaReports')

const {
  mapState: mapContentPartState,
  mapActions: mapContentPartActions,
  mapMutations: mapContentPartMutations
} = createNamespacedHelpers('contentPart')

export default {
  name: 'MediaReportsFilters',
  data() {
    return {
      startDate: '',
      endDate: '',
      endDatePickerOptions: {
        disabledDate: time => {
          return this.startDate
            ? time.getTime() < this.startDate.getTime()
            : false
        }
      }
    }
  },

  computed: {
    ...mapAccountsState({
      accounts: state => state.accounts,

      areAccountsLoading: state => state.isLoading
    }),
    ...mapMediaReportsState({
      tags: state => state.tags,

      customerIds: state => state.filters.customerIds,

      areUserTagsLoading: state => state.isLoading,

      filters: state => state.filters
    }),
    ...mapContentPartState({
      categories: state => state.categories,

      areCategoriesLoading: state => state.dialogBoxConfig.isCategoryLoading,

      episodes: state => state.medias,
      areEpisodesLoading: function(state) {
        return (
          !!this.filters.selectedCategory &&
          state.dialogBoxConfig.isMediaLoading
        )
      }
    }),

    subAccounts() {
      const subAccounts = this.filters.getAccounts(this.accounts)
      return subAccounts
    },
    isSubAccountTypeDisabled() {
      return !this.subAccounts.length
    },

    filtersMedia() {
      const filtersMedia = this.filters.getFilter()
      return filtersMedia
    }
  },
  watch: {
    'filters.account': {
      handler() {
        this.handleAccountOrSubAccountSelect()
      }
    },
    'filters.subAccount': {
      handler() {
        this.handleAccountOrSubAccountSelect()
      }
    },
    'filters.categoryID': {
      handler(newCategory) {
        this.setContentPart({ medias: [] })
        if (newCategory) {
          this.filters.clearEpisode()

          this.setMedia(newCategory)
        }
      }
    },
    'filters.startDate': {
      handler(newStartDate) {
        if (
          this.filters.endDate &&
          newStartDate &&
          newStartDate.getTime() > this.filters.endDate.getTime()
        ) {
          this.filters.endDate = newStartDate
        }
      }
    }
  },
  async created() {
    await this.fetchAccounts()

    this.setDialogBoxConfig({
      isCategoryLoading: true
    })

    await this.setCategories()

    this.setDialogBoxConfig({
      isCategoryLoading: false
    })
  },
  mounted() {
    this.startDate = this.filters.startDate

    this.endDate = this.filters.endDate
  },
  destroyed() {
    this.filters.clearFiltersExceptDate()
  },
  methods: {
    ...mapContentPartMutations({
      setContentPart: MUTATION_TYPE.SET_CONTENT_PART
    }),
    ...mapAccountsActions(['fetchAccounts']),
    ...mapMediaReportsActions([
      'fetchUsersTagId',
      'fetchTagsByAccountAndSubAccount'
    ]),
    ...mapContentPartActions([
      'setCategories',
      'setDialogBoxConfig',
      'setMedia'
    ]),
    async filterMediaReports() {
      if (this.filters.tagId) {
        await this.fetchUsersTagId(this.filters.tagId)
      }

      this.$emit('filter', this.filtersMedia)
    },
    reset() {
      this.filters.clearFilters()
    },
    async handleAccountOrSubAccountSelect() {
      if (this.filters.account || this.filters.subAccount) {
        await this.fetchTagsByAccountAndSubAccount({
          account: this.filters.account,

          subAccount: this.filters.subAccount
        })
      }
    },

    handleStartDateChange(newStartDate) {
      this.filters.setStartDate(newStartDate)
    },

    handleEndDateChange(newEndDate) {
      this.filters.setEndDate(newEndDate)
    }
  }
}
