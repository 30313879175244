export class ChatAssistant {
  static createParams(data) {
    return {
      AssistantID: data.assistantId,
      UserQuery: data.userQuery,
      AssistantResponse: data.assistantResponse,
      AddedDate: new Date().toISOString(),
      ThreadID: data.threadId,
      CustomerID: data.customerId
    }
  }
}
