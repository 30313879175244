<template>
  <div class="app-container">
    <ODataTable
      ref="oDataTableComponentRef"
      :columns="columns"
      :add-edit-columns="columns"
      :total-label="$t('customers.histories.total')"
      :file-name="$t('customers.histories.fileName')"
      :title="$t('customers.histories.title')"
      :is-static-data-source="true"
      :static-data="histories"
      :should-show-add-button="false"
      :should-show-edit-button="false"
      :should-show-delete-button="false"
    />
  </div>
</template>

<script>

import HistoryService from '@/api/editHistory'
import { columns } from './config'
import ODataTable from '@/components/ODataTable'
export default {
  name: 'UpdateHistories',
  components: {
    ODataTable
  },
  data() {
    return {
      histories: [],
      columns
    }
  },
  async mounted() {
    this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
    const { id } = this.$router.history.current.params
    try {
      this.histories = await HistoryService.fetchByCustomerId(id)
    } finally {
      this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
    }
  }
}

</script>

