export class MediaReportsFilters {
  constructor({
    customerIds = [],
    tagId = '',
    account = '',
    subAccount = '',
    siteId = '',
    categoryID = '',
    episodeID = '',
    startDate = null,
    endDate = null,
    dateFormat = 'dd/MM/yyyy'
  } = {}) {
    this.customerIds = customerIds
    this.tagId = tagId
    this.account = account
    this.subAccount = subAccount
    this.siteId = siteId
    this.categoryID = categoryID
    this.episodeID = episodeID
    this.startDate = startDate
    this.endDate = endDate
    this.dateFormat = dateFormat
  }

  getAccounts(accounts = []) {
    return (
      accounts.find(account => account.Account === this.account)?.subAccounts ||
      []
    )
  }
  getFilter() {
    const mediaReportsFilter = [
      ...(this.account ? [['Account', '=', `'${this.account}'`]] : []),
      ...(this.subAccount ? [['SubAccount', '=', `'${this.subAccount}'`]] : []),
      ...(this.customerIds?.length
        ? [['CustomerID', 'in', `(${this.customerIds.join(',')})`]]
        : []),
      ...(this.categoryID ? [['CategoriesID', '=', this.categoryID]] : []),
      ...(this.episodeID ? [['MediaID', '=', this.episodeID]] : []),
      ...(this.startDate && this.endDate
        ? [
          ['LastUpdate', 'ge', this.startDate?.toISOString()],
          ['LastUpdate', 'le', this.endDate?.toISOString()]
        ]
        : [])
    ].reduce(
      (filter, element, index) => [
        ...filter,
        ...(index ? ['and'] : []),
        element
      ],
      []
    )
    return mediaReportsFilter
  }

  clearFilters() {
    this.account = ''
    this.subAccount = ''
    this.categoryID = ''
    this.episodeID = ''
    this.startDate = ''
    this.endDate = ''
    this.siteId = ''
  }

  clearFiltersExceptDate() {
    this.account = ''
    this.subAccount = ''
    this.categoryID = ''
    this.episodeID = ''
  }

  clearEpisode() {
    this.episodeID = ''
  }

  updatePartial(updateParams) {
    this.customerIds = updateParams.map(customer => customer.ID)
  }

  setStartDate(newStartDate) {
    this.startDate = newStartDate
  }

  setEndDate(newEndDate) {
    this.endDate = newEndDate
  }
}
