<template>
  <ODataTable
    ref="oDataTableComponentRef"
    :columns="columns"
    :add-edit-columns="addEditColumns"
    :is-static-data-source="true"
    :static-data="filteredTags"
    :total-label="$t('tagsByCustomers.total')"
    :file-name="$t('tagsByCustomers.fileName')"
    :add-row-button-label="$t('tagsByCustomers.add')"
    :title="$t('tagsByCustomers.title')"
    :should-show-delete-button="false"
    :should-show-add-button="false"
    :should-show-edit-button="false"
    @selectionChange="handleSelectionChange"
  >
    <template #custom-buttons>
      <DxButton icon="remove" :on-click="handleUnAssign" />
    </template>
    <template #custom-toolbar-before>
      <el-button
        v-if="selectedRowsData.length"
        type="primary"
        @click="handleTagBatchUnAssign"
      >
        {{ $t("unassign") }}
      </el-button>
    </template>
    <template #custom-toolbar-after>
      <DxProgressBar
        v-if="showProgress"
        :min="0"
        :max="100"
        :value="percentage"
        width="100%"
      />
    </template>
  </ODataTable>
</template>

<script>
import ODataTable from '../../components/ODataTable'
import { columns, addEditColumns } from '@/views/Tags/config.js'
import { DxButton } from 'devextreme-vue/data-grid'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapTagState, mapActions } = createNamespacedHelpers('tags')
const { mapState: mapAppState, mapMutations: mapAppMutations } = createNamespacedHelpers('app')
import { Message } from 'element-ui'
import { confirm } from 'devextreme/ui/dialog'
import CustomerService from '@/api/customer'
import TagService from '@/api/tag'
import { MUTATION_TYPES } from '@/store/mutations'
import { DxProgressBar } from 'devextreme-vue/progress-bar'

export default {
  name: 'Tags',
  components: {
    ODataTable,
    DxButton,
    DxProgressBar
  },
  data() {
    return {
      columns,
      addEditColumns,
      selectedRowsData: [],
      tagIds: [],
      staticData: [],
      showProgress: false,
      percentage: 0
    }
  },
  computed: {
    areMultipleTagsSelected() {
      return this.selectedRowsData.length > 1
    },
    ...mapAppState({
      selectedCustomers: (state) => state.selectedCustomers.Ids
    }),
    filteredTags() {
      return this.staticData
    },
    ...mapTagState({
      tags: state => state.tags
    })
  },
  async mounted() {
      this?.$refs?.oDataTableComponentRef?.$refs?.oDataTableRef?.instance?.beginCustomLoading()
      try {
        const fetchTagPromises = this.selectedCustomers.map(async(customerId) => {
          const { value: assignedTags } = await CustomerService.fetchAllTagsByCustomerId(customerId)
          const assignedTagIds = assignedTags.map(tag => tag.TagID)
          this.tagIds = Array.from(new Set([...this.tagIds, ...assignedTagIds]))
        })

        await Promise.all(fetchTagPromises)

        if (this.tagIds.length) {
          const { value } = await TagService.getByTags(this.tagIds)
          this.staticData = value
        }

        if (!this.staticData.length) {
          this.$router.push('/users')
        }
      } catch (error) {
        this.$message({
          type: 'error',
          message: error.message
        })
      } finally {
      this?.$refs?.oDataTableComponentRef?.$refs?.oDataTableRef?.instance?.endCustomLoading()
      }
  },
  methods: {
    ...mapActions(['delete', 'areUsersAssociatedWithTag', 'fetchAll']),
    ...mapAppMutations({
      setSelectedCustomers: MUTATION_TYPES.APP.SET_SELECTED_CUSTOMERS
    }),
    async handleUnAssign(event) {
      const isConfirmed = await confirm(this.$t('toggleConfirm'))
      if (isConfirmed) {
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
        try {
          await this.handleToggleConfirm(event.row.data.ID)
          this.staticData = this.staticData.filter(data => data.ID !== event.row.data.ID)
          this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
          Message({
            message: this.$t('tag.unassigned'),
            type: 'success'
          })
          await this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.refresh()
        } finally {
          if (!this.staticData.length) {
            this.$router.push('/users')
          }
        }
      }
    },
    async handleToggleConfirm(tagId) {
      try {
        const { value: customers } = await TagService.fetchUsersByTagId(tagId)
        const customerIds = customers.map(customer => customer.ID).filter(customerId => this.selectedCustomers.includes(customerId))
        await TagService.unassignTagForUsers({ customerIds, tagId })
      } catch (error) {
        if (error.message) {
          Message({
            message: error.message,
            type: 'error'
          })
        }
      }
    },
    handleSelectionChange({ selectedRowsData }) {
      this.selectedRowsData = selectedRowsData
    },
    async handleTagBatchUnAssign() {
      const isConfirmed = await confirm(this.$t('toggleConfirm'))
      if (isConfirmed) {
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
        this.showProgress = true
        try {
          for await (const [index, tag] of this.selectedRowsData.entries()) {
            await this.handleToggleConfirm(tag.ID)
            this.percentage = Math.round(((index + 1) * 100) / this.selectedRowsData.length)
          }
          this.selectedTagIds = this.selectedRowsData.map(tag => tag.ID)
          this.staticData = this.staticData.filter(data => !this.selectedTagIds.includes(data.ID))
          this.showProgress = false
          this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
          Message({
            message: this.$t(this.areMultipleTagsSelected ? 'tag.batchUnassigned' : 'tag.unassigned'),
            type: 'success'
          })
          await this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.refresh()
        } finally {
          if (!this.staticData.length) {
            this.$router.push('/users')
          }
        }
      }
    }
  }
}
</script>
