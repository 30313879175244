var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c(
        "el-main",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.areQuestionsAndTheirAnswersLoading,
              expression: "areQuestionsAndTheirAnswersLoading"
            }
          ],
          staticClass: "el-main-container"
        },
        [
          _c(
            "el-row",
            { staticClass: "margin-y-sm" },
            [
              _c(
                "el-col",
                { staticClass: "right-align", attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "medium",
                        loading: _vm.isLoading,
                        disabled: !(_vm.isEditing
                          ? _vm.test.haveProvidedQuestions
                          : _vm.test.haveProvidedQuestionsAndMedia)
                      },
                      on: { click: _vm.handleNextClick }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.isEditing
                            ? _vm.$t("test.edit.save")
                            : _vm.$t("test.create.chooseType.next")
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          !_vm.isEditing
            ? _c(
                "el-row",
                { staticClass: "margin-y-sm" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t(
                              "test.create.createQuestions.sitePlaceholder"
                            )
                          },
                          model: {
                            value: _vm.test.siteId,
                            callback: function($$v) {
                              _vm.$set(_vm.test, "siteId", $$v)
                            },
                            expression: "test.siteId"
                          }
                        },
                        _vm._l(_vm.sites, function(site) {
                          return _c("el-option", {
                            key: site.ID,
                            attrs: { label: site.Name, value: site.ID }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "margin-left-sm",
                          attrs: {
                            "collapse-tags": "",
                            placeholder: _vm.$t(
                              "test.create.createQuestions.programPlaceholder"
                            ),
                            loading: _vm.areProgramsLoading
                          },
                          model: {
                            value: _vm.test.programId,
                            callback: function($$v) {
                              _vm.$set(_vm.test, "programId", $$v)
                            },
                            expression: "test.programId"
                          }
                        },
                        _vm._l(_vm.programs, function(program) {
                          return _c("el-option", {
                            key: program.ID,
                            attrs: { label: program.Name, value: program.ID }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "margin-left-sm",
                          attrs: {
                            placeholder: _vm.$t(
                              "test.create.createQuestions.episodePlaceholder"
                            )
                          },
                          model: {
                            value: _vm.test.episodeId,
                            callback: function($$v) {
                              _vm.$set(_vm.test, "episodeId", $$v)
                            },
                            expression: "test.episodeId"
                          }
                        },
                        _vm._l(_vm.episodes, function(episode) {
                          return _c("el-option", {
                            key: episode.ID,
                            attrs: { label: episode.Title, value: episode.ID }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { staticClass: "margin-y-md" },
            [
              _c(
                "vuedraggable",
                {
                  on: { start: _vm.handleDragStart, end: _vm.handleDragEnd },
                  model: {
                    value: _vm.test.questions,
                    callback: function($$v) {
                      _vm.$set(_vm.test, "questions", $$v)
                    },
                    expression: "test.questions"
                  }
                },
                _vm._l(_vm.test.questions, function(question, questionIndex) {
                  return _c(
                    "el-col",
                    {
                      key: questionIndex,
                      attrs: { span: _vm.test.canDeleteQuestion ? 23 : 24 }
                    },
                    [
                      _c(
                        "el-row",
                        {
                          staticClass: "center",
                          attrs: {
                            type: "flex",
                            gutter: 20,
                            justify: "space-between"
                          }
                        },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("span", { staticClass: "question-color" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "test.create.specifyAnswers.question"
                                  ) +
                                    " " +
                                    (questionIndex + 1)
                                ) + ":"
                              )
                            ])
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "el-collapse",
                                {
                                  on: { change: _vm.handleCollapse },
                                  model: {
                                    value: _vm.expandedQuestions,
                                    callback: function($$v) {
                                      _vm.expandedQuestions = $$v
                                    },
                                    expression: "expandedQuestions"
                                  }
                                },
                                [
                                  _c(
                                    "el-collapse-item",
                                    {
                                      attrs: {
                                        name: questionIndex,
                                        disabled: question.isFreeText
                                      }
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "title" },
                                        [
                                          _c("el-input", {
                                            staticClass:
                                              "question-input-width-size",
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "test.create.createQuestions.questionPlaceholder"
                                              )
                                            },
                                            nativeOn: {
                                              click: function($event) {
                                                return _vm.handleCollapse.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                              keyup: function($event) {
                                                return _vm.handleCollapse.apply(
                                                  null,
                                                  arguments
                                                )
                                              }
                                            },
                                            model: {
                                              value: question.name,
                                              callback: function($$v) {
                                                _vm.$set(question, "name", $$v)
                                              },
                                              expression: "question.name"
                                            }
                                          }),
                                          _c("DxButton", {
                                            staticClass: "drag-button",
                                            class: {
                                              "is-dragging": _vm.isDragging
                                            },
                                            attrs: { icon: "dragvertical" }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._l(question.answers, function(
                                        answer,
                                        answerIndex
                                      ) {
                                        return _c(
                                          "el-row",
                                          {
                                            key:
                                              questionIndex + "_" + answerIndex,
                                            staticClass:
                                              "margin-y-sm margin-left-md",
                                            attrs: { type: "flex", gutter: 20 }
                                          },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 2 } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "test.create.specifyAnswers.correct"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c("el-radio", {
                                                  staticClass:
                                                    "margin-y-sm column correct-radio-label",
                                                  attrs: {
                                                    label: answerIndex,
                                                    size: "medium"
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.markCorrectAnswer(
                                                        questionIndex,
                                                        answerIndex
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      question.correctAnswerIndex,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        question,
                                                        "correctAnswerIndex",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "question.correctAnswerIndex"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 2 } },
                                              [
                                                _c("el-row", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "test.create.specifyAnswers.order"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _c(
                                                  "el-row",
                                                  {
                                                    staticClass: "margin-y-sm",
                                                    attrs: { gutter: 15 }
                                                  },
                                                  [
                                                    _c(
                                                      "el-col",
                                                      [
                                                        answerIndex
                                                          ? _c("el-button", {
                                                              staticClass:
                                                                "arrow-button",
                                                              attrs: {
                                                                size: "small",
                                                                icon:
                                                                  "el-icon-arrow-up"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.handleUpArrowClick(
                                                                    questionIndex,
                                                                    answerIndex
                                                                  )
                                                                }
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-col",
                                                      {
                                                        staticClass:
                                                          "margin-y-sm"
                                                      },
                                                      [
                                                        question.checkIfDownSortAllowedForIndex(
                                                          answerIndex
                                                        )
                                                          ? _c("el-button", {
                                                              staticClass:
                                                                "arrow-button",
                                                              attrs: {
                                                                size: "small",
                                                                icon:
                                                                  "el-icon-arrow-down"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.handleDownArrowClick(
                                                                    questionIndex,
                                                                    answerIndex
                                                                  )
                                                                }
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 20 } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "test.create.specifyAnswers.response"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c("el-input", {
                                                  staticClass: "margin-y-sm",
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "test.create.specifyAnswers.response"
                                                    ),
                                                    type: "textarea",
                                                    rows: 1
                                                  },
                                                  model: {
                                                    value: answer.response,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        answer,
                                                        "response",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "answer.response"
                                                  }
                                                }),
                                                _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-col",
                                                      {
                                                        staticClass:
                                                          "font-weight",
                                                        attrs: { span: 24 }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "test.create.specifyAnswers.feedback"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 24 } },
                                                      [
                                                        _c("el-input", {
                                                          staticClass:
                                                            "margin-top-xs margin-bottom-sm",
                                                          attrs: {
                                                            placeholder: _vm.$t(
                                                              "test.create.specifyAnswers.feedback"
                                                            ),
                                                            type: "textarea",
                                                            rows: 1
                                                          },
                                                          model: {
                                                            value:
                                                              answer.feedback,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                answer,
                                                                "feedback",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "answer.feedback"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm.isEditing
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-col",
                                                              {
                                                                staticClass:
                                                                  "font-weight",
                                                                attrs: {
                                                                  span: 24
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "test.edit.specifyAnswers.toggle.label"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 24
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "el-switch",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        answer.isActive
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        answer.isActive,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          answer,
                                                                          "isActive",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "answer.isActive"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "align-self-start",
                              attrs: { span: 1 }
                            },
                            [
                              _c(
                                "el-row",
                                {
                                  staticClass: "actions-container",
                                  attrs: {
                                    type: "flex",
                                    gutter: 10,
                                    justify: "center",
                                    align: "center"
                                  }
                                },
                                [
                                  _vm.test.canDeleteQuestion && !_vm.isEditing
                                    ? _c("el-col", [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          on: {
                                            click: function($event) {
                                              return _vm.handleRemoveQuestion(
                                                questionIndex
                                              )
                                            }
                                          }
                                        })
                                      ])
                                    : _vm._e(),
                                  _vm.isEditing
                                    ? _c(
                                        "el-col",
                                        [
                                          _c("el-switch", {
                                            attrs: { value: question.isActive },
                                            model: {
                                              value: question.isActive,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  question,
                                                  "isActive",
                                                  $$v
                                                )
                                              },
                                              expression: "question.isActive"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              ),
              !_vm.isEditing
                ? _c(
                    "el-button",
                    {
                      staticClass: "margin-y-md",
                      attrs: { type: "primary", size: "medium" },
                      on: { click: _vm.handleQuestionAdd }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("test.create.createQuestions.addMore"))
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }