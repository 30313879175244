import { MediaReportFilterV2 } from '@/models/mediaReportsFilterV2'
export class InvertedMediaReportFiltersV2 extends MediaReportFilterV2 {
  constructor({
    customerIds = [],
    tagId = '',
    account = '',
    subAccount = '',
    siteId = null,
    categoryID = null,
    episodeID = null,
    startDate = null,
    endDate = null,
    dateFormat = 'dd/MM/yyyy',
    mediaIds = []
  } = {}) {
    super({
      customerIds,
      tagId,
      account,
      subAccount,
      siteId,
      categoryID,
      episodeID,
      startDate,
      endDate,
      dateFormat,
      mediaIds
    })
  }

  clearFiltersV2() {
    this.account = ''
    this.subAccount = ''
    this.categoryID = null
    this.episodeID = null
    this.startDate = null
    this.endDate = null
    this.siteId = null
    this.mediaIds = []
  }

  get isFilterDisabled() {
    return (
      this.startDate &&
      this.endDate &&
      this.account &&
      (this.mediaIds.length || this.episodeID)
    )
  }

  resource() {
    if (
      this.startDate &&
      this.endDate &&
      this.account &&
      (this.mediaIds.length || this.episodeID)
    ) {
      const mediaID = this.mediaIds.length ? this.mediaIds : this.episodeID
      return `customers/iKnowAPI.Models.Customers/Default.GetCustomersWithoutViews(mediaID=[${mediaID}], startDate='${this.startDate?.toISOString()}', endDate='${this.endDate?.toISOString()}', account='${
        this.account
      }')`
    }

    return ''
  }

  parse() {
    const baseFilter = [
      ...(this.customerIds?.length && this.tagId
        ? [[`ID in (${this.customerIds.map(item => `'${item}'`).join(',')})`]]
        : []),

      ...(this.subAccount ? [['SubAccount', '=', `'${this.subAccount}'`]] : [])
    ].reduce(
      (filter, element, index) => [
        ...filter,
        ...(index ? ['and'] : []),
        element
      ],
      []
    )

    return baseFilter
  }
}
