const dateFields = ['AddedDate']
const hiddenColumns = ['AssistantDefaultsID']
export const columns = [
  'MediaID',
  'CategoryID',
  ...hiddenColumns,
  ...dateFields,
  'SessionID'
].map(field => ({
  field,
  width: '100%',
  ...(dateFields.includes(field) && { dataType: 'date', sort: 'desc' }),
  ...(hiddenColumns.includes(field) && { visible: false })
}))
