<template>
  <div class="app-container">
    <el-header class="main-title">{{ $t("corsTester.title") }}</el-header>
    <el-form ref="formRef" :model="corsTester" :rules="rules">
      <el-form-item :label="$t('corsTester.urlLabel')" prop="url">
        <el-input v-model="corsTester.url" />
      </el-form-item>

      <el-form-item
        v-if="corsTester.isBodyVisible"
        :label="$t('corsTester.bodyLabel')"
      >
        <el-input v-model="corsTester.body" :rows="5" type="textarea" />
      </el-form-item>

      <el-select
        v-model="corsTester.method"
        :placeholder="$t('corsTester.methodLabel')"
      >
        <el-option
          v-for="option in methodOptions"
          :key="option.value"
          :label="option"
          :value="option"
        />
      </el-select>

      <el-form-item :label="$t('corsTester.bearerTokenLabel')">
        <el-input v-model="corsTester.token" :rows="10" type="textarea" />
      </el-form-item>
      <el-button type="primary" :loading="loading" @click="handleFormSubmit">{{
        $t("corsTester.testButtonText")
      }}</el-button>
    </el-form>
  </div>
</template>

<script>
import { validateUrl } from './config'
import { HTTP_METHOD } from '@/utils/constants'
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('corsTester')

export default {
  name: 'CorsTester',
  data() {
    return {
      methodOptions: Object.values(HTTP_METHOD),
      rules: {
        url: [{ validator: validateUrl, trigger: 'change' }]
      }
    }
  },
  computed: {
    ...mapState({
      corsTester: state => state.corsTester,
      loading: state => state.loading
    })
  },
  methods: {
    ...mapActions(['checkAction']),
    async handleFormSubmit() {
      this.$refs.formRef.validate(async valid => {
        if (valid) {
          this.checkAction()
        }
      })
    }
  }
}
</script>
<style scoped>
.main-title {
  font-size: 20px;
  padding: 0px 40px;
}

.el-form {
  padding: 0px 40px;
}
</style>
