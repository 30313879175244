<template>
  <div class="navbar">
    <hamburger
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb class="breadcrumb-container" />
    <div class="right-menu">
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          {{ name }}
          <div class="user-avatar">
            <i class="el-icon-user" />
          </div>
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <router-link to="/">
            <el-dropdown-item>
              {{ $t("home") }}
            </el-dropdown-item>
          </router-link>
          <el-dropdown
            v-if="user.isSuperAdmin"
            class="menu-container"
            trigger="click"
            placement="left-start"
          >
            <el-dropdown-item>
              {{ $t("manage") }}
            </el-dropdown-item>
            <el-dropdown-menu slot="dropdown">
              <router-link to="/manage/menus">
                <el-dropdown-item>
                  {{ $t("menus.name") }}
                </el-dropdown-item>
              </router-link>
              <router-link to="/manage/admins">
                <el-dropdown-item>
                  {{ $t("admins.name") }}
                </el-dropdown-item>
              </router-link>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown-item divided @click.native="logout">
            <span>{{ $t("logout") }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="right-menu select-width"><locale-switcher /></div>
    <div class="right-menu select-width">
      <site-switcher @change="updateContentForSiteID" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import LocaleSwitcher from '@/components/LocaleSwitcher'
import SiteSwitcher from '@/components/SiteSwitcher'

export default {
  components: {
    Breadcrumb,
    Hamburger,
    LocaleSwitcher,
    SiteSwitcher
  },
  computed: {
    ...mapGetters(['sidebar', 'name']),
    ...mapState('user', ['user'])
  },
  methods: {
    ...mapActions('app', ['updateContentForSiteID']),
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }
  }
}
</script>

<style lang="scss" scoped>
$base-spacing: 5px;

.navbar {
  height: $base-spacing * 10;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: $base-spacing * 10;
    padding: 0px $base-spacing * 2;

    &.select-width {
      width: 10rem;
    }

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: $base-spacing;

      .avatar-wrapper {
        position: relative;
        display: flex;
        gap: $base-spacing;
        align-items: center;
        cursor: pointer;
        .user-avatar {
          width: $base-spacing * 8;
          height: $base-spacing * 8;
          background: #6ad9d6;
          border-radius: $base-spacing * 5;
          font-size: 1.8rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>
