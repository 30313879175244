import { DateUtils } from '@/utils/date'
export const addedDateField = 'AddedDate'
export const optionsToSelect = [
  {
    label: 'all',
    value: null
  },
  {
    label: 'active',
    value: true
  },
  {
    label: 'inactive',
    value: false
  }
]
export const createColumns = ({ sites }) => {
  const dateColumns = [
    {
      field: addedDateField,
      width: 110,
      dataType: 'date',
      format: DateUtils.odataDateFormat,
      sort: 'desc'
    }
  ]

  const dropDownColumns = [
    {
      field: 'SiteID',
      width: 110,
      lookup: sites,
      required: true,
      requiredMessage: 'categories.column.siteId.requiredMessage'
    }
  ]

  const nameColumns = [
    {
      field: 'Name',
      width: 110,
      required: true,
      requiredMessage: 'categories.column.name.requiredMessage'
    }
  ]

  const fileTypeColumns = ['MainImage']

  const addEditColumns = [
    'CategoryHeader',
    'Description',
    'CategoryAuthor',
    'SortOrder',
    'CategoryDescription',
    ...fileTypeColumns,
    'BulletPoints',
    'MediaCount',
    'IsActive'
  ].map(field => ({
    field,
    width: 110,
    ...(fileTypeColumns.includes(field) && {
      editCellTemplate: 'file-item',
      type: 'category'
    })
  }))

  const addEdit = [
    ...dropDownColumns,
    ...dateColumns,
    ...nameColumns,
    ...addEditColumns
  ]

  return {
    addEdit,
    all: [{ field: 'ID', width: 110 }, ...addEdit]
  }
}
