<template>
  <DxCheckBox
    ref="checkBoxRef"
    :value="field.value"
    :disabled="disabled"
    :read-only="!field.isOnForm"
    @click.native="handleClick"
    @value-changed="handleChange"
  />
</template>
<script>
import { confirm } from 'devextreme/ui/dialog'
import { DxCheckBox } from 'devextreme-vue/check-box'
import { SOURCES } from '@/views/sources/config'
export default {
  name: 'CheckboxItem',
  components: {
    DxCheckBox
  },
  props: {
    field: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    disabled() {
      return !this.field.isOnForm || this.field.column.editorOptions.disabledIds?.includes(this.field.key)
    }
  },
  methods: {
    async  handleClick(event) {
      if (this.field.column.editorOptions.folder !== SOURCES.FOLDER) {
        const result = await confirm(this.$t('sites.toggleConfirm'))
        if (result) {
          this.$refs.checkBoxRef.instance.option('value', !this.field.value)
          this.field.setValue(!this.field.value)
        }
      } else {
        this.$refs.checkBoxRef.instance.option('value', !this.field.value)
        this.field.setValue(!this.field.value ? 1 : 0)
      }
    },
    handleChange(e) {
      if (e.event && !e.event.prevented) {
        e.event.prevented = true
        e.component.option('value', e.previousValue)
      }
    }
  }
}
</script>
<style>
.dx-popup-title.dx-toolbar {
  display: none;
}
</style>
