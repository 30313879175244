<template>
  <el-radio-group v-model="selectedImportType" class="column-flex">
    <el-radio class="radio-btn" :label="importOptions.COMPLETE">
      {{ $t("manageUsers.selectType.selectAccount") }}

      <p class="option-subtitle">
        {{ $t("manageUsers.selectType.selectAccountSubtitle") }}
      </p>
      <el-row type="flex" align="center" class="option-field">
        <el-col :span="12">
          <p>{{ $t("manageUsers.selectType.account") }}</p>
          <el-select
            v-model="selectedAccount"
            filterable
            placeholder="Select"
            :loading="isLoading"
            :disabled="isAccountTypeDisabled"
          >
            <el-option
              v-for="item in accounts"
              :key="item.option.value"
              :label="item.option.title"
              :value="item.option.value"
            >
              <div class="select-type-option">
                <span>{{ item.option.title }}</span>
                <span class="select-option-subtitle">{{
                  `${item.option.subscribedContacts} ${$t(
                    "manageUsers.selectType.subscribedContacts"
                  )}${
                    item.option.licenses
                      ? ` (${item.option.licenses} ${$t(
                        "manageUsers.selectType.licenses"
                      )})`
                      : ""
                  }`
                }}</span>
              </div>
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
          <p>{{ $t("manageUsers.selectType.subAccount") }}</p>

          <el-select
            v-model="selectedSubAccount"
            filterable
            placeholder="Select"
            :loading="isLoading"
            :disabled="isSubAccountTypeDisabled"
          >
            <el-option
              v-for="item in subAccounts"
              :key="item.option.value"
              :label="item.option.title"
              :value="item.option.value"
            >
              <div class="select-type-option">
                <span>{{ item.option.title }}</span>
                <span class="select-option-subtitle">{{
                  `${item.option.subscribedContacts} ${$t(
                    "manageUsers.selectType.subscribedContacts"
                  )}${
                    item.option.licenses
                      ? ` (${item.option.licenses} ${$t(
                        "manageUsers.selectType.licenses"
                      )})`
                      : ""
                  }`
                }}</span>
              </div>
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </el-radio>
    <el-radio class="radio-btn" :label="importOptions.MIXED">
      {{ $t("manageUsers.selectType.incompleteListTitle") }}
      <p class="option-subtitle">
        {{ $t("manageUsers.selectType.incompleteListSubtitle") }}
      </p>
    </el-radio>
    <el-radio class="radio-btn" :label="importOptions.DEACTIVATE">{{
      $t("manageUsers.selectType.deactivateAccount")
    }}</el-radio>
    <el-button
      type="primary"
      class="radio-btn self-start"
      :disabled="isImportButtonDisabled"
      @click="handleImportClick"
    >{{ $t("manageUsers.selectType.import") }}</el-button>
  </el-radio-group>
</template>
<script>
import { MUTATION_TYPES } from '@/store/mutations'
import { createNamespacedHelpers } from 'vuex'
import { nextClickEvent } from './config'
import { IMPORT_TYPE } from '@/store/modules/user/modules/importedUsers'

const { mapState, mapMutations, mapActions } =
  createNamespacedHelpers('user/accounts')

const { mapActions: mapImportUsersActions } =
  createNamespacedHelpers('user/importedUsers')

export default {
  name: 'SelectAccount',

  data() {
    return {
      importOptions: IMPORT_TYPE
    }
  },
  computed: {
    ...mapState({
      accounts: (state) => state.accounts,
      isLoading: (state) => state.isLoading
    }),
    selectedAccount: {
      get: function() {
        return this.$store.state.user.accounts.selectedAccount
      },
      set: function(newValue) {
        this.setSelectedAccount(newValue)
        this.setSelectedSubAccount('')
      }
    },
    selectedSubAccount: {
      get: function() {
        return this.$store.state.user.accounts.selectedSubAccount
      },
      set: function(newValue) {
        this.setSelectedSubAccount(newValue)
      }
    },
    selectedImportType: {
      get: function() {
        return this.$store.state.user.importedUsers.importType
      },
      set: function(newValue) {
        this.changeImportType(newValue)
      }
    },
    isAccountTypeDisabled() {
      return this.selectedImportType !== IMPORT_TYPE.COMPLETE
    },
    isSubAccountTypeDisabled() {
      return !this.subAccounts.length
    },
    isMixedImportUpdateDisabled() {
      return this.selectedImportType !== IMPORT_TYPE.MIXED
    },
    isImportButtonDisabled() {
      return (
        this.selectedImportType === IMPORT_TYPE.COMPLETE &&
        !this.selectedAccount
      )
    },
    subAccounts() {
      return (
        this.accounts.find(
          (account) => account.Account === this.selectedAccount
        )?.subAccounts || []
      )
    }
  },
  async created() {
    await this.fetchAccounts()
  },
  methods: {
    handleImportClick() {
      this.$emit(nextClickEvent)
    },
    ...mapActions(['fetchAccounts']),
    ...mapImportUsersActions(['changeImportType']),
    ...mapMutations({
      setSelectedAccount: MUTATION_TYPES.USER.ACCOUNTS.SET_SELECTED_ACCOUNT,
      setSelectedSubAccount:
        MUTATION_TYPES.USER.ACCOUNTS.SET_SELECTED_SUB_ACCOUNT
    })
  }
}
</script>

<style lang="scss">
$base-spacing: 5px;

.el-radio__input.is-checked + .el-radio__label {
  color: inherit;
  font-weight: bold;
}

.el-select-dropdown__item {
  height: auto;
}

.radio-btn {
  margin: $base-spacing * 5;
}

.self-start {
  align-self: flex-start;
}

.column-flex {
  display: flex;
  flex-direction: column;
}

.option-field {
  margin-left: $base-spacing * 8;
  margin-top: $base-spacing;
  gap:$base-spacing;
}

.option-subtitle {
  font-weight: 300;
  margin-left: $base-spacing * 6;
}

.select-option-subtitle {
  color: #8492a6;
  font-size: $base-spacing * 2 + 3;
}

.select-type-option {
  display: flex;
  flex-direction: column;
}
</style>
