<template>
  <div>

    <DxPopup
      title="Popup Title"
      :hide-on-outside-click="true"
      :visible="isAddMediaDialogOpen"
    >
      <el-form class="popupAlignment" label-width="80px">
        <div class="manageContent">
          <div>
            <label>{{ $t('ChatQuestionsByMedia.category') }}</label>
            <DxSelectBox
              :search-enabled="true"
              :data-source="columns.dropDownColumns[0].lookup"
              :display-expr="columns.dropDownColumns[0].displayExpr"
              :value-expr="columns.dropDownColumns[0].valueExpr"
              :value="selectedCategory"
              :on-value-changed="handleCategoryChange"
            />
          </div>

          <div class="mediaInput">
            <label>{{ $t('ChatQuestionsByMedia.media') }}</label>
            <DxTagBox
              :multiline="false"
              :data-source="columns.dropDownColumns[1].lookup"
              :value="selectedEpisodes"
              :value-expr="columns.dropDownColumns[1].valueExpr"
              :display-expr="columns.dropDownColumns[1].displayExpr"
              :search-enabled="true"
              :show-selection-controls="true"
              :on-value-changed="onEpisodeValueChange"
            />
          </div>
        </div>

        <div class="alignButton">
          <el-button type="primary" @click="handlerChatQuestionMedia">Save</el-button>
          <el-button @click="handleAddMediaDialogClose">Cancel</el-button>
        </div>
      </el-form>
    </DxPopup>

    <ODataTable
      ref="oDataTableComponentRef"
      :columns="columns.all"
      :add-edit-columns="columns.addEdit"
      resource="chatquestionbymedia"
      :action-buttons-width="90"
      :filter="filter"
      :total-label="$t('ChatQuestionsByMedia.total')"
      :file-name="$t('ChatQuestionsByMedia.fileName')"
      :should-show-add-button="false"
      :should-show-edit-button="false"
      :on-loaded="handleODataTableLoad"
      @selectionChange="handleSelectionChange"
    >
      <template #custom-toolbar-before>
        <div>
          <el-button :type="defaultType" plain @click="handleAddMediaDialogOpen">{{ $t('ChatQuestionsByMedia.addMedia') }}</el-button>
        </div>
      </template>
      <template #custom-toolbar-after>
        <div>
          <el-button
            v-if="batchDeleteEpisodesID.length"
            type="danger"
            plain
            @click="handleBatchDelete"
          >
            {{ $t("ChatQuestionsByMedia.batchDelete") }}
          </el-button></div>
      </template>
    </ODataTable>
  </div>
</template>

<script>

import ODataTable from '../../components/ODataTable'
import { createColumns } from './config'
import odataTableEventsMixin from '/src/mixin/odataTableEventsMixin'
import ChatQuestionByMediaService from '@/api/chatQuestionsByMedia'
import { DxSelectBox } from 'devextreme-vue/select-box'
import { DxTagBox } from 'devextreme-vue/tag-box'
import { DxPopup } from 'devextreme-vue/popup'
import EpisodeService from '@/api/episode'
import { confirm } from 'devextreme/ui/dialog'

export default {
  name: 'ChatQuestionsByMedia',
  components: {
    ODataTable,
    DxSelectBox,
    DxTagBox,
    DxPopup
  },
  mixins: [odataTableEventsMixin],
  props: {
    data: {
      type: Object,
      default: () => undefined
    }
  },
  data() {
    return {
      filter: [],
      selectedCategory: null,
      selectedEpisodeID: [],
      selectedEpisodes: [],
      isAddMediaDialogOpen: false,
      oDataTableData: [],
      batchDeleteEpisodesID: []
    }
  },
  computed: {
    columns() {
      if (this.data && this.data.ID) {
        return createColumns({ ID: this.data.ID, selectedEpisodeID: this.selectedEpisodeID, oDataTableData: this.oDataTableData })
      }
      return createColumns({ selectedEpisodeID: this.selectedEpisodeID, oDataTableData: this.oDataTableData })
    },
    ChatQuestionID() {
      return this.data && this.data.ID ? this.data.ID : undefined
    }
  },
  watch: {
    async selectedCategory(newVal) {
      if (newVal !== null) {
        const { value } = await EpisodeService.fetchMediasByCategoryId(newVal)

        this.selectedEpisodeID = value.filter((val) => {
          return val.MediaID !== null
        }).map((val) => {
          return `ID eq ${val.MediaID}`
        })
      } else {
        this.selectedEpisodeID = []
      }
    }
  },
  created() {
    if (this.data) {
      this.filter = ['ChatQuestionID', 'eq', `${this.data.ID}`]
    }
  },
  methods: {
    async handlerChatQuestionMedia() {
      try {
        if (this.selectedEpisodes.length && this.ChatQuestionID) {
          this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
          await Promise.all(this.selectedEpisodes.map((mediaId) => ChatQuestionByMediaService.create({ mediaId, id: this.ChatQuestionID })))
        }
        this.isAddMediaDialogOpen = false
        this.selectedCategory = null
        await this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.refresh()
      } catch (error) {
        this.$message({
          message: error.message,
          type: 'error'
        })
      } finally {
        this.selectedEpisodes = []
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
      }
    },
    handleCategoryChange(selectedCategoryValue) {
      this.selectedEpisodes = []
      this.selectedCategory = selectedCategoryValue.value
    },
    onEpisodeValueChange(select) {
      this.selectedEpisodes = select.value
    },
    handleAddMediaDialogOpen() {
      this.isAddMediaDialogOpen = true
    },
    handleAddMediaDialogClose() {
      this.isAddMediaDialogOpen = false
      this.selectedCategory = null
      this.selectedEpisodeID = []
    },
    handleODataTableLoad(data = []) {
      if (data.length) this.oDataTableData = data.map((val) => `ID eq ${val.MediaID}`)
    },
    handleSelectionChange({ selectedRowKeys }) {
      this.batchDeleteEpisodesID = selectedRowKeys
    },
    async handleBatchDelete() {
      const result = await confirm(this.$t('ChatQuestionsByMedia.confirmation'))
      if (result && this.batchDeleteEpisodesID) {
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
        await Promise.all(this.batchDeleteEpisodesID.map((ID) => ChatQuestionByMediaService.delete(ID)))
        await this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.refresh()
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
      }
    }
  }
}
</script>

<style scoped>
 .popupAlignment{
  width: 100%;
  height: 100%;
}
.manageContent{
  height: 90%;
}
.mediaInput{
  margin-top: 20px;
}
.alignButton{
  padding: 10px 5px;
  display: flex;
  justify-content: flex-end;

}
</style>
