<template>
  <el-dialog
    :key="dialogVisible"
    :title="title"
    :visible.sync="dialogVisible"
    width="75%"
    :before-close="handleDialogClose"
    @opened="handleDialogOpen"
  >
    <el-form
      ref="formRef"
      :model="contentPart"
      :rules="rules"
      label-position="top"
      size="small"
    >
      <content-part-form :content-part.sync="contentPart" />

      <el-row>
        <el-form-item
          v-loading="isEditorLoading"
          :element-loading-text="editorLoadingMessage"
          :label="$t('contentPart.mainContent')"
          prop="MainContent"
        >
          <editor
            v-model="contentPart.MainContent"
            :api-key="TINYMCE_API_KEY"
            :init="tinyMCEConfig"
            :disabled="dialogBoxConfig.disableEditor"
            @onInit="handleEditorActive"
            @onDrop="handleDrop"
          />
        </el-form-item>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="handleDialogClose">{{ $t("cancel") }}</el-button>
      <el-button
        v-loading.fullscreen.lock="isContentPartDialogLoading"
        type="primary"
        @click="onSubmit('formRef')"
      >{{ $t("save") }}</el-button>
    </span>

  </el-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import Editor from '@tinymce/tinymce-vue'
import html2canvas from 'html2canvas'
import ContentPartForm from '@/components/ContentPartForm'

import {
  DIALOG_TYPES,
  CONTENT_PART_MODEL,
  validators,
  tinyMCEConfig,
  TINYMCE_API_KEY,
  IMAGE_CONTENT_URL,
  createFormData,
  parseDataURLtoFile
} from './config'
import { FileProcessor } from '@/models/fileProcessor'

const contentPartStore = 'contentPart'
const appStore = 'app'
const UPLOAD_SUCCESS_PERCENTAGE = 100

const computeUploadPrecent = (event) =>
  Math.ceil((event.loaded / event.total) * 100)

export default {
  name: 'ContentPartDialogBox',
  components: {
    Editor,
    ContentPartForm
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    item: CONTENT_PART_MODEL,
    dialogType: {
      type: String,
      default: DIALOG_TYPES.CREATE
    }
  },
  data() {
    return {
      contentPart: this.item,
      tinyMCEConfig,
      TINYMCE_API_KEY,
      isEditorLoading: true,
      editorLoadingMessage: this.$t('editorLoading'),
      uploadTargetElement: {},
      rules: {
        Name: [{ validator: validators.validateName, trigger: 'blur' }],
        MainContent: [
          { validator: validators.validateMainContent, trigger: 'blur' }
        ],
        ContentPartTemplateID: [
          { validator: validators.validateTemplate, trigger: 'change' }
        ],
        Position: [
          { validator: validators.validatePosition, trigger: 'change' }
        ],
        Tag: [{ validator: validators.validateTag, trigger: 'change' }]
      }
    }
  },
  computed: {
    ...mapGetters(['contentPartTemplates']),
    ...mapState(contentPartStore, [
      'isContentPartDialogLoading',
      'fileUploadAPI',
      'dialogBoxConfig'
    ]),
    ...mapState(appStore, ['siteID']),
    dialogVisible: {
      get: function() {
        return this.visible
      },
      set: function(val) {
        this.$emit('update:visible', val)
      }
    },
    title() {
      return `${this.$t(this.dialogType)} Content Part`
    }
  },
  watch: {
    item(val) {
      this.contentPart = Object.assign({}, val)
    },
    'contentPart.MainContent'(nextValue, currentValue) {
      const containsParaTag = nextValue.includes('<p>')
      const containsDivAlready = this.contentPart.MainContent.includes('<div class="home-banner">')

      if ((!currentValue && nextValue) || (currentValue && nextValue && containsParaTag)) {
        if (!containsDivAlready) {
          this.contentPart.MainContent = this.contentPart.MainContent
            .replace(/<img/g, '<div class="home-banner"><img class="w-100"')
            .replace(/width="[^"]*"/g, '')
            .replace(/height="[^"]*"/g, '')

          if (containsParaTag) {
            this.contentPart.MainContent = this.contentPart.MainContent
              .replace('<p>', '')
              .replace('</p>', '')
          }
        }
      }
    }
  },
  created() {
    if (!this.contentPartTemplates.length) {
      this.setTemplates()
    }
    this.setDialogBoxConfig({
      showCategory: false,
      showMedia: false,
      disableEditor: false,
      isCategoryLoading: true,
      isMediaLoading: true
    })
  },
  methods: {
    ...mapActions(contentPartStore, [
      'createContentPartRow',
      'updateContentPartRow',
      'toggleContentPartDialogLoading',
      'uploadFile',
      'setTemplates',
      'setDialogBoxConfig'
    ]),
    handleDialogOpen() {
      this.editorLoadingMessage = `${this.$t('editorLoading')}...`
      this.dialogVisible = true
      if (this.dialogType === DIALOG_TYPES.CREATE) {
        this.$refs['formRef'].resetFields()
        this.contentPart = {
          MainContent: '',
          Name: ''
        }
      }
    },
    handleDialogClose() {
      this.dialogVisible = false
      this.setDialogBoxConfig({
        showCategory: false,
        showMedia: false,
        disableEditor: false,
        isCategoryLoading: true,
        isMediaLoading: true
      })
    },
    handleEditorActive(event) {
      this.isEditorLoading = false
    },
    async onSubmit(formName) {
      const wrapper = document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('tinymce')
      this.toggleContentPartDialogLoading()
      const canvas = await html2canvas(wrapper, {
        allowTaint: false,
        useCORS: true
      })
      const image = canvas.toDataURL()
      const fileProcessor = new FileProcessor(image)
      const resizedImageDataURL = await fileProcessor.resizeImage()
      const filename = `${this.contentPart.Name.toLowerCase().replace(/ /g, '_')}.png`
      const resizedImageAsFile = parseDataURLtoFile(resizedImageDataURL, filename)
      const data = createFormData(resizedImageAsFile)
      const uploadConfig = {
        data,
        onError: this.handleUploadError,
        onUploadProgress: this.handleUploadProgress,
        onSuccess: () => {}
      }
      const uploadFilePath = await this.uploadFile(uploadConfig)
      const uploadedFileURL = `${IMAGE_CONTENT_URL}${uploadFilePath}`
      this.contentPart.PreviewImage = uploadedFileURL
      const dialogTypeAction = {
        [DIALOG_TYPES.CREATE]: this.createContentPart,
        [DIALOG_TYPES.CLONE]: this.createContentPart,
        [DIALOG_TYPES.EDIT]: this.updateContentPart
      }

      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          dialogTypeAction[this.dialogType]()
        } else {
          this.toggleContentPartDialogLoading()
        }
      })
    },
    async updateContentPart() {
      await this.updateContentPartRow(this.contentPart)
      this.dialogVisible = false
      this.toggleContentPartDialogLoading()
      this.$emit('update', this.contentPart)
      this.$message({ type: 'success', message: this.$t('updateSuccess') })
    },
    async createContentPart() {
      this.contentPart.SiteID = this.siteID
      await this.createContentPartRow(this.contentPart)
      this.dialogVisible = false
      this.toggleContentPartDialogLoading()
      this.$emit('update', this.contentPart)
      this.$message({ type: 'success', message: this.$t(this.dialogType === 'clone' ? 'cloneSuccess' : 'createSuccess') })
    },
    handleDrop(event) {
      event.preventDefault()
      this.editorLoadingMessage = this.$t('uploadMessageStart')
      this.isEditorLoading = true
      const data = createFormData(event.dataTransfer.files[0])
      this.uploadTargetElement = event.target
      const uploadConfig = {
        data,
        onError: this.handleUploadError,
        onUploadProgress: this.handleUploadProgress
      }
      if (event.target.tagName === 'IMG') {
        uploadConfig.onSuccess = this.handleImageUploadSuccess
        return this.uploadFile(uploadConfig)
      } else if (event.target.tagName === 'VIDEO') {
        uploadConfig.onSuccess = this.handleVideoUploadSuccess
        return this.uploadFile(uploadConfig)
      } else {
        this.isEditorLoading = false
        this.editorLoadingMessage = `${this.$t('editorLoading')}...`
        this.uploadTargetElement = null
        this.$message({
          message: this.$t('errors.uploadDrop'),
          type: 'error'
        })
      }
    },
    handleUploadProgress(event) {
      const percentage = computeUploadPrecent(event)
      if (percentage === UPLOAD_SUCCESS_PERCENTAGE) {
        this.editorLoadingMessage = this.$t('uploadMessageFinish')
      } else {
        this.editorLoadingMessage = `${this.$t(
          'uploadMessageProgress'
        )} ${percentage}%`
      }
    },
    handleImageUploadSuccess(event) {
      window.tinymce.activeEditor.dom.setAttrib(
        this.uploadTargetElement,
        'src',
        `${IMAGE_CONTENT_URL}${event}`
      )
      this.isEditorLoading = false
      this.editorLoadingMessage = `${this.$t('editorLoading')}...`
    },
    handleVideoUploadSuccess(event) {
      window.tinymce.activeEditor.dom.setAttrib(
        this.uploadTargetElement,
        'src',
        `${IMAGE_CONTENT_URL}${event}`
      )
      window.tinymce.activeEditor.dom.setAttrib(
        this.uploadTargetElement,
        'autoplay',
        'true'
      )
      this.contentPart.MainContent =
        window.tinymce.activeEditor.dom.getOuterHTML(this.uploadTargetElement)
      this.isEditorLoading = false
      this.editorLoadingMessage = `${this.$t('editorLoading')}...`
    },
    handleUploadError(event) {
      this.$message({
        message: this.$t('errors.upload'),
        type: 'error'
      })
    }
  }
}
</script>

<style>
.tox.tox-tinymce-aux {
  z-index: 3000;
}
.select-width {
  width: 100%;
}
</style>
