var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedAdmin
    ? _c(
        "DxTabPanel",
        {
          scopedSlots: _vm._u(
            [
              {
                key: "menu-tab",
                fn: function() {
                  return [
                    _c("MenuManager", { attrs: { menus: _vm.groupedMenus } })
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            1464149747
          )
        },
        [
          _c("DxItem", {
            attrs: { title: _vm.$t("menus.name"), template: "menu-tab" }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }