<template>
  <div>
    <UserDetail
      :selected-customer="selectedCustomer"
      :assigned-tags="assignedTags"
      :sites="sites"
      :show-details="showDetails"
      @detailsDialogClose="handleDetailsDialogClose"
    />
    <ODataTable
      ref="oDataTableComponentRef"
      :columns="columns"
      :add-edit-columns="columns"
      :total-label="$t('download.total')"
      :file-name="$t('download.fileName')"
      :title="$t('download.title')"
      :is-static-data-source="true"
      :static-data="filteredFiles"
      :should-show-delete-button="false"
      :should-show-edit-button="false"
      :should-show-select-box="false"
      :should-show-add-button="false"
      :action-buttons-width="100"
    >
      <template #custom-buttons>
        <DxButton icon="download" :on-click="handleDownloadClick" />
        <DxButton icon="info" :on-click="showUploadedUserDetail" :visible="hasUploadedUser" />
        <DxButton icon="card" :on-click="showDownloadedUserDetail" :visible="hasDownloadedUser" />
      </template>
      <template #custom-toolbar-before>
        <DxCheckBox
          :text="$t('download.checkboxTitle')"
          :value="showDownloadedOnly"
          @value-changed="toggleDownloadedOnly"
        />
      </template>
    </ODataTable>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import ODataTable from '../../components/ODataTable'
import { DxButton } from 'devextreme-vue/data-grid'
import { DateUtils } from '@/utils/date'
import { DxCheckBox } from 'devextreme-vue/check-box'
import CustomerService from '@/api/customer'
import { Customer } from '@/models/customer'
import UserDetail from '@/components/UserDetail'
import { LOG_ACTION, tableName } from '@/views/customers/config'
const { mapState, mapActions } = createNamespacedHelpers('files')
import { mapGetters } from 'vuex'
const customerRoute = '/users'
const sortedField = 'LastAccessTime'
const dateFields = ['CreationTime', sortedField, 'UploadedDate', 'DownloadedDate']
const { mapState: mapUserState } = createNamespacedHelpers('user')
const { mapState: mapCustomerState, mapActions: mapCustomerActions } = createNamespacedHelpers('customer')
const { mapState: mapContentPartState } = createNamespacedHelpers('contentPart')
const { mapActions: mapTagActions, mapState: mapTagState } =
  createNamespacedHelpers('tags')

export default {
  name: 'Download',
  components: {
    ODataTable,
    DxButton,
    DxCheckBox,
    UserDetail
  },
  data() {
    return {
      selectedCustomer: null,
      showDetails: false,
      columns: [
        {
          field: 'Fullname',
          label: 'File Name'
        },
        {
          field: 'sizeInBytes',
          label: 'Size (Bytes)'
        },
        {
          field: 'UploadedUserID',
          label: 'Uploaded User ID'
        },
        {
          field: 'DownloadedAdminID',
          label: 'Downloaded Admin ID'
        },
        ...dateFields.map((field) => ({ field, dataType: 'date', format: DateUtils.odataDateTimeFormat, ...(field === sortedField && { sort: 'desc' }) }))
      ],
      showDownloadedOnly: false
    }
  },
  computed: {
    ...mapState({
      files: (state) => state.files,
      isLoading: (state) => state.isLoading
    }),
    ...mapGetters(['files']),
    filteredFiles() {
      return this.files(this.showDownloadedOnly)
    },
    ...mapUserState({
      canView: state => state.user.canAccess(customerRoute)
    }),
    ...mapTagState({
      tags: (state) => state.tags
    }),
    ...mapContentPartState({
      sites: state => state.sites
    }),
    ...mapCustomerState({
      assignedTagIds: (state) => state.assignedTagIds
    }),
    assignedTags() {
      return this.tags.filter((tag) => this.assignedTagIds.includes(tag.ID)).map((tag) => tag.TagName)
    }
  },
  async mounted() {
    this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
    try {
      await this.fetchFiles()
      if (!this.tags.length) {
        await this.fetchAllTags()
      }
    } finally {
      this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
    }
  },
  methods: {
    ...mapActions(['fetchFiles', 'fetchFileContent']),
    ...mapTagActions({
      fetchAllTags: 'fetchAll'
    }),
    ...mapCustomerActions(['fetchAndOpenImpersonateLink', 'logAction']),
    async handleDownloadClick(event) {
      event.component.option('disabled', true)
      this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
      try {
        const file = encodeURIComponent(event.row.data.Fullname)
        const content = await this.fetchFileContent({
          file,
          options: { responseType: 'blob' }
        })
        const blob = new Blob([content], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = event.row.data.Fullname
        link.click()
        window.URL.revokeObjectURL(link.href)
      } finally {
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
        event.component.option('disabled', false)
      }
    },
    toggleDownloadedOnly() {
      this.showDownloadedOnly = !this.showDownloadedOnly
    },
    async showUploadedUserDetail(event) {
      await this.showUserDetail(event.row.data.UploadedUserID)
    },
    async showDownloadedUserDetail(event) {
      this.showUserDetail(event.row.data.DownloadedAdminID)
    },
    hasUploadedUser(event) {
      return event.row.data.UploadedUserID && this.canView
    },
    hasDownloadedUser(event) {
      return event.row.data.DownloadedAdminID && this.canView
    },
    async showUserDetail(customerId) {
      const { value } = await CustomerService.getById(Number(customerId))

      await this.logAction({
        TableName: tableName,
        ObjectID: customerId,
        Action: LOG_ACTION.VIEW,
        DateViewed: new Date()
      })
      this.selectedCustomer = new Customer(value[0])
      this.showDetails = true
    },
    handleDetailsDialogClose() {
      this.selectedCustomer = null
      this.showDetails = false
    }
  }
}
</script>
<style lang="scss" scoped>

::v-deep .dx-icon-download::before {
  color: #67c23a !important;
}
::v-deep .dx-icon-info::before {
  color: #03a9f4 !important;
}
::v-deep .dx-icon-card::before {
  color: #e6a23c !important;
}

</style>
