var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ODataTable", {
    ref: "oDataTableComponentRef",
    attrs: {
      columns: _vm.columns.all,
      "add-edit-columns": _vm.columns.addEdit,
      resource: "AdminMenu",
      "total-label": _vm.$t("menus.total"),
      "file-name": _vm.$t("menus.fileName"),
      "add-row-button-label": _vm.$t("menus.add"),
      title: _vm.$t("menus.title")
    },
    on: { rowSaved: _vm.handleRowSaved }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }