<template>
  <el-popover
    placement="bottom"
    title="Filters"
    width="200"
    trigger="click"
  >
    <div>
      <el-row class="filter-item">
        <span class="label">{{ $t('contentPart.position') }}</span>
        <content-part-select
          :placeholder="`${$t('search')} ${$t('contentPart.position')}`"
          :options="positions"
          show-all
          :selected.sync="filters.positionSearch"
          size="mini"
          prop-name="Position"
          prop-value="Position"
          @change="setFilters({ positionSearch: $event })"
        />
      </el-row>
      <el-row class="filter-item">
        <span class="label">{{ $t('contentPart.tag') }}</span>
        <content-part-select
          :placeholder="`${$t('search')} ${$t('contentPart.tag')}`"
          :options="tags"
          show-all
          :selected.sync="filters.tagSearch"
          size="mini"
          prop-name="Tag"
          prop-value="Tag"
          @change="setFilters({ tagSearch: $event })"
        />
      </el-row>
    </div>
    <el-button slot="reference" type="text" class="filter-button" icon="el-icon-s-operation" />
  </el-popover>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ContentPartSelect from '@/components/ContentPartSelect'

const contentPartStore = 'contentPart/'
const contentPartBuilderStore = 'contentPartBuilder/'

export default {
  name: 'BuilderFilters',
  components: {
    ContentPartSelect
  },
  computed: {
    ...mapState(contentPartStore, ['tags', 'positions']),
    ...mapState(contentPartBuilderStore, ['filters'])
  },
  methods: {
    ...mapActions(contentPartBuilderStore, ['setFilters'])
  }
}
</script>

<style lang="scss" scoped>
$base-spacing: 5px;

.label {
  font-weight: bold;
}
.filter-item {
  margin: $base-spacing*3 0px;
}
.filter-button {
  font-size: 1.6rem;
  padding: 0px;
}
</style>
