<template>
  <div>
    <div v-if="field.isOnForm" v-loading="fileManager.isLoading">
      <div v-if="fileManager.showUploadFile">
        <el-image
          v-if="fileManager.showImageFile"
          :src="fileManager.fileUrl || fileManager.field.value"
          fit="cover"
          class="thumbnail-image uploaded-image"
        >
          <div slot="placeholder">
            <i class="el-icon-loading" />
          </div>
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline" />
          </div>
        </el-image>
        <video v-else-if="fileManager.showVideoFile" class="thumbnail-image uploaded-image" controls>
          <source :src="fileManager.fileUrl || fileManager.field.value">
        </video>
        <iframe
          v-else-if="fileManager.showDocumentFile"
          :src="fileManager.fileUrl || fileManager.field.value"
          class="thumbnail-image uploaded-image"
        />
        <el-row v-if="fileManager.fileUrl" class="uploaded-image-name-wrapper">
          <div class="dx-fileuploader-file">
            <div class="dx-fileuploader-file-name">
              {{ fileManager.updatedFileName }}
            </div>
            <div class="action-buttons">
              <el-button
                v-if="fileManager.showDownloadIcon"
                class="position-circle-icon"
                circle
                icon="el-icon-download"
                @click="handleDownload"
              />
              <el-button
                v-if="fileManager.showRemoveIcon"
                class="position-circle-icon"
                circle
                icon="el-icon-circle-close"
                @click="handleClose"
              />
            </div>
          </div>
        </el-row>
        <el-row v-if="fileManager.filePath" class="uploaded-image-name-wrapper">
          <el-col class="dx-fileuploader-file">
            <el-col class="dx-fileuploader-file-path">{{ fileManager.filePath }}</el-col>
          </el-col>
        </el-row>
      </div>
      <DxFileUploader
        ref="fileUploaderRef"
        :name="name"
        :disabled="fileManager.isFileUploaderDisabled"
        :select-button-text="$t(selectButtonText)"
        :accept="fileManager.accept"
        :multiple="fileManager.shouldAllowMultipleUploads"
        :upload-headers="uploadHeaders"
        :upload-method="uploadMethod"
        :upload-mode="uploadMode"
        :upload-url="fileManager.uploadUrl"
        :upload-custom-data="fileManager.customData"
        :upload-file="handleUploadFile"
        :allowed-file-extensions="fileManager.allowedFileExtensions"
        :max-file-size="fileManager.allowedMaxFileSize"
        v-bind="fileSelection"
        @value-changed="handleChange"
        @uploaded="handleUploadSuccess"
        @upload-error="handleUploadError"
      />
      <el-row>
        <el-col v-if=" fileManager.isImageType && !fileManager.isTypeAssistant ">
          <span class="instruction">{{ `${ $t('type') }${ fileManager.allowedFileType } ` }}</span>
        </el-col>
        <el-col>
          <span class="instruction">{{ `${ $t('fileSize') }${ fileManager.maxFileSize }${ $t('mb') } ` }}</span>
        </el-col>
      </el-row>
      <el-row v-if=" fileManager.field.column.editorOptions.showAsTextBox ">
        <el-col>
          <DxTextBox :value=" fileManager.linkUrl " :on-value-changed=" handleValueChanged " value-change-event="keyup" />
        </el-col>
      </el-row>
      <el-col v-if="fileManager.isAssistantCreated">
        <span class="validation-note">{{ $t('fileUploderEnableValidation') }}</span>
      </el-col>
    </div>
    <div v-if=" !fileManager.field.isOnForm ">
      {{ fileManager.field.value }}
    </div>
    <div v-if=" fileManager.uploadedFilesLength " class="uploaded-files-heading">
      <span>{{ $t('assistantFiles') }}</span>
    </div>
    <div
      v-if=" fileManager.uploadedFilesLength "
      v-loading=" !fileManager.uploadedFilesLength "
      class="uploaded-image-name-wrapper container"
    >
      <div v-for=" file in fileManager.uploadedFiles " :key=" file.id " class="uploaded-assistant-file">
        <div class="position-relative">
          <el-button class="delete-button" circle icon="el-icon-delete" @click="deleteFile(file)" />
        </div>
        <el-row class="uploaded-file thumbnail-image uploaded-image">
          <div class="dx-fileuploader-file">
            <div class="dx-fileuploader-file-name">{{ file.filename }}</div>
          </div>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { DxFileUploader } from 'devextreme-vue/file-uploader'
import { getToken } from '@/utils/auth'
import { DxTextBox } from 'devextreme-vue/text-box'
import AssistantService from '@/api/assistants'
import FileService from '@/api/file'
import { Message } from 'element-ui'
import { downloadFile } from '@/utils/downloadFile'
import { FileManager } from '@/models/fileUploader'
import { MUTATION_TYPES } from '@/store/mutations'
import { createNamespacedHelpers } from 'vuex'
import store from '@/store'
const { mapMutations: mapDownloadableMutations, mapActions: mapDownloadableActions } = createNamespacedHelpers('downloadable')

export default {
  name: 'FileUploader',
  components: {
    DxFileUploader,
    DxTextBox
  },
  props: {
    name: {
      type: String,
      default: 'file'
    },
    selectButtonText: {
      type: String,
      default: 'select'
    },
    uploadMethod: {
      type: String,
      default: 'POST'
    },
    uploadMode: {
      type: String,
      default: 'useButtons'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    field: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      fileManager: new FileManager(this.field),
      uploadByFileUploader: false
    }
  },
  computed: {
    uploadHeaders() {
      return {
        Authorization: `Bearer ${getToken()}`
      }
    },

    fileSelection() {
      return this.fileManager.isDownloadableType ? { value: this.selectedFiles } : {}
    },

    selectedFiles() {
      const selectedFile = store.getters.downloadable.selectedFile
      return selectedFile || []
    }
  },
  created() {
    this.fileManager.initialise()
  },

  methods: {
    ...mapDownloadableMutations({
      updateDownloadable: MUTATION_TYPES.DOWNLOADABLE.SET_DOWNLOADABLE
    }),
    ...mapDownloadableActions([
      'setSelectedFiles',
      'setDownloadableStates'
    ]),

    handleChange(event) {
      if (event.value.length) {
        this.fileManager.setFiles(event.value)
      }
      if (this.fileManager.isDownloadableType) {
        this.setSelectedFiles({ selectedFile: event.value })
      }
    },

    toggleUploadByFileUploader() {
      this.uploadByFileUploader = false
    },

    handleUploadSuccess() {
      if (!this.fileManager.isTypeAssistant) {
        this.setFileUploadContainerDisplay()
      }
      const onUploadSuccess = (params) => {
        this.$emit('fileUploaded', params)
      }
      this.fileManager.upload(onUploadSuccess)
      this.uploadByFileUploader = true
      if (this.fileManager.isDownloadableType) {
        this.setDownloadableStates({ fileUploaded: true, filePath: this.fileManager.filePath, fileUrl: this.fileManager.fileUrl, fileUploadedFolderName: store.getters.downloadable.folder })
      }
    },
    setFileUploadContainerDisplay(ref) {
      const isImageUploaded = document.querySelector('.action-buttons')
      if (isImageUploaded !== undefined) {
        const fileUploadContainer = document.querySelector('.dx-fileuploader-show-file-list .dx-fileuploader-files-container')
        if (fileUploadContainer !== null) {
          fileUploadContainer.style.display = 'none'
          this.$refs.fileUploaderRef.instance.reset()
        }
      }
    },
    handleValueChanged(event) {
      if (event.value) {
        this.fileManager.setFieldValue(event.value)
      }
    },

    async handleUploadFile(file, method) {
      this.fileManager.setFiles(file)
      const data = new FormData()
      data.set('file', this.fileManager.fileToBeUploaded)
      if (this.fileManager.isTypeAssistant) {
        data.set('purpose', 'assistants')
        this.fileManager.newlyUploadedFile = await AssistantService.uploadFileOpenAi(data)
        return this.fileManager.newlyUploadedFile
      }
      data.set('filename', this.fileManager.fileToBeUploaded.name)
      this.fileManager.keyResponseFromUploadApi = await FileService.fileUploaderUpload({ type: this.field.column.editorOptions.folder, data })
      return this.fileManager.keyResponseFromUploadApi
    },
    deleteFile(file) {
      this.fileManager.deleteFile(file)
      this.$emit('fileToBeDeleted', file)
    },
    handleUploadError(event) {
      if (event?.error?.response?.data?.error?.message) {
        Message({
          dangerouslyUseHTMLString: true,
          message: event.error.response.data.error.message,
          type: 'error'
        })
      }
    },

    async uploadFile() {
      if (store.getters.downloadable.selectedFile.length) {
        try {
          const file = store.getters.downloadable.selectedFile
          this.fileManager.uploadUrl
          if (!this.fileManager.uploadByFileUploader) {
            await Promise.all([
              this.handleUploadFile(file),
              this.handleUploadSuccess()
            ])
          }
        } catch (error) {
          Message({
            dangerouslyUseHTMLString: true,
            message: this.$t('errors.upload'),
            type: 'error'
          })
        }
      }
    },

    async handleDownload() {
      this.fileManager.isLoading = true
      try {
        await downloadFile(this.fileManager.downloadFileParams)
      } catch (error) {
        Message({
          type: 'error',
          message: error.message
        })
      } finally {
        this.fileManager.isLoading = false
      }
    },
    handleClose() {
      this.fileManager.reset()
      this.updateDownloadable({ disableFolderField: false })
      this.$refs.fileUploaderRef.instance.reset()
    }
  }
}
</script>
<style lang="scss" scoped>
$image-max-width: 300px;

.position-relative {
  max-width: $image-max-width;
  position: relative;
}

.position-circle-icon {
  position: relative;
  padding: 6px;
  z-index: 1
}

.delete-button {
  position: absolute;
  right: -16px;
  top: -16px;
  z-index: 1
}

.uploaded-image-name-wrapper {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1);
  padding: 5px 8px;
  margin-top: 12px;
}

.uploaded-image {
  max-width: $image-max-width;
  object-fit: contain;
}

.dx-fileuploader-button[aria-label="Upload"] {
  display: none;
}

.thumbnail-image {
  height: 100%;
  width: 100%;

  ::v-deep .image-slot {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

::v-deep .dx-fileuploader-wrapper {
  padding: 7px 0 !important;
}

::v-deep .dx-fileuploader-input-wrapper {
  border: 1px dashed transparent;
}

.instruction {
  font-weight: bold !important;
}

.uploaded-file {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1);
  padding: 5px 8px;
  margin-top: 12px;
}

.uploaded-image-name-wrapper.container {
  height: 250px;
  overflow-y: scroll;
}

.uploaded-files-heading {
  color: rgba(117, 117, 117, .87);
  font-weight: 700;
  font-size: 12px;
  margin-top: 36px;
}

.validation-note {
  position: relative;
  top: 10px;
  color: red;
}

.uploaded-file.thumbnail-image.uploaded-image.el-row {
  display: flex;
}

.uploaded-assistant-file {
  margin-top: 15px;
}

::v-deep .dx-fileuploader-file-name {
  text-overflow: unset;
  white-space: normal;
}

.dx-fileuploader-file {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dx-fileuploader-file-name {
  word-break: break-all;
  display: flex;
  align-items: center;
}

::v-deep .dx-widget.dx-button.dx-button-mode-contained.dx-button-default.dx-button-has-text.dx-fileuploader-button.dx-fileuploader-upload-button {
  display: none
}

::v-deep  .upload-btn-hide {
  .dx-fileuploader-wrapper .dx-fileuploader-container .dx-fileuploader-content .dx-fileuploader-files-container .dx-fileuploader-file-container .dx-fileuploader-button-container .dx-fileuploader-upload-button {
display: none;
  }
}

.dx-fileuploader-file-path {
  text-overflow: unset;
  white-space: normal;
  word-break: break-all;
  font-weight: 500;
}
</style>
