<template>
  <medias-by-category
    ref="mediasRef"
    :category-id="data.ID"
    :site-id="data.SiteID"
    :parent-category-id="data.CategoriesID"
    title="medias.title"
    add-row-button-label="medias.add"
    :should-show-add-button="shouldShowAddButton"
    :should-show-edit-button="shouldShowEditButton"
    :should-show-delete-button="shouldShowDeleteButton"
    :should-show-toggle-button="shouldShowToggleButton"
    :should-show-title="shouldShowTitle"
  >
    <template #toggle-button="{ data:item }">
      <el-switch
        :value="isMediaActive(item.row.data.MediaID)"
        @change="toggleActivate(item.row.data.MediaID)"
      />
    </template>
    <template v-if="shouldShowToggleButton" #custom-buttons>
      <DxButton template="custom-button-template" />
    </template>
  </medias-by-category>
</template>

<script>
import MediasByCategory from '../../mediasByCategory/index.vue'
import { createNamespacedHelpers } from 'vuex'
import { Media } from '@/models/media'
import { confirm } from 'devextreme/ui/dialog'
import { DxButton } from 'devextreme-vue/data-grid'
const {
  mapActions: mapAppActions,
  mapGetters: mapAppGetters,
  mapState: mapAppState
} = createNamespacedHelpers('app')

export default {
  name: 'Detail',
  components: {
    MediasByCategory,
    DxButton
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    shouldShowAddButton: {
      type: Boolean,
      default: true
    },
    shouldShowEditButton: {
      type: Boolean,
      default: true
    },
    shouldShowDeleteButton: {
      type: Boolean,
      default: true
    },
    shouldShowToggleButton: {
      type: Boolean,
      default: false
    },
    shouldShowTitle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapAppGetters(['findMediaByIdAndSiteId']),
    ...mapAppState({
      selectedSiteOnPage: (state) => state.selectedSiteOnPage
    })
  },
  methods: {
    ...mapAppActions(['disableMediaForSite', 'enableMediaForSite']),
    isMediaActive(id) {
      return !this.findMediaByIdAndSiteId({
        id,
        siteId: this.selectedSiteOnPage
      })
    },
    async handleToggleConfirm(id) {
      this.$refs.mediasRef.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
      try {
        const media = this.findMediaByIdAndSiteId({
          id,
          siteId: this.selectedSiteOnPage
        })
        await (media
          ? this.enableMediaForSite(media.ID)
          : this.disableMediaForSite(
            Media.createDisableParams({ id, siteId: this.selectedSiteOnPage })
          ))
        await this.$refs.mediasRef.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.refresh()
        this.$message({
          type: 'success',
          message: this.$t(media ? 'medias.activate' : 'medias.deactivate')
        })
      } finally {
        this.$refs.mediasRef.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
      }
    },
    async toggleActivate(id) {
      const result = await confirm(this.$t('sites.toggleConfirm'))
      if (result) {
        await this.handleToggleConfirm(id)
      }
    }
  }
}
</script>
