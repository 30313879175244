var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vue-final-modal",
    {
      ref: "myForm",
      attrs: {
        resize: true,
        styles: _vm.styleModal,
        "content-style": _vm.contentStyleModal,
        "lock-scroll": false,
        "hide-overlay": false,
        "click-to-close": false,
        "esc-to-close": true,
        "z-index-base": 1000000000,
        "z-index": false,
        "focus-retain": true,
        "fit-parent": true,
        drag: true,
        "drag-selector": ".el-dialog__header",
        "keep-changed-style": false,
        "resize-directions": ["t", "tr", "r", "br", "b", "bl", "l", "tl"]
      },
      on: { "resize:move": _vm.handleResize },
      model: {
        value: _vm.isDialogOpen,
        callback: function($$v) {
          _vm.isDialogOpen = $$v
        },
        expression: "isDialogOpen"
      }
    },
    [
      _c("div", { staticClass: "el-dialog__header" }, [
        _c("span", { staticClass: "el-dialog__title" }, [
          _vm._v(_vm._s(_vm.$t("chatInterface")))
        ]),
        _c(
          "button",
          {
            staticClass: "el-dialog__headerbtn",
            attrs: { type: "button", "aria-label": "Close" }
          },
          [
            _c("i", {
              staticClass: "el-dialog__close el-icon el-icon-close",
              on: { click: _vm.handleDialogClose }
            })
          ]
        )
      ]),
      _vm._t("ChatAssistantDefaults", null, {
        width: _vm.modalWidth,
        height: _vm.modalHeight
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }