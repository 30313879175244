var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ]
    },
    [
      _vm.selectedAdmin
        ? _c(
            "el-dialog",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading"
                }
              ],
              attrs: {
                visible: _vm.showDetails,
                title: _vm.selectedAdmin.name,
                fullscreen: ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.showDetails = $event
                },
                close: _vm.handleDetailsDialogClose
              }
            },
            [
              _c("Details", {
                attrs: { "selected-admin": _vm.selectedAdmin, menus: _vm.menus }
              }),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlePermissionSave }
                    },
                    [_vm._v("Save")]
                  ),
                  _c(
                    "el-button",
                    { on: { click: _vm.handleDetailsDialogClose } },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("ODataTable", {
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns,
          "add-edit-columns": _vm.addEditColumns,
          "total-label": _vm.$t("admins.total"),
          "file-name": _vm.$t("admins.fileName"),
          "add-row-button-label": _vm.$t("admins.add"),
          title: _vm.$t("admins.title"),
          resource: "customers",
          filter: ["IsAdmin", "=", true],
          "should-show-add-button": false,
          "should-show-edit-button": false,
          "should-show-delete-button": false
        },
        scopedSlots: _vm._u([
          {
            key: "custom-buttons",
            fn: function() {
              return [
                _c("DxButton", {
                  attrs: {
                    icon: "preferences",
                    "on-click": _vm.handleManageClick
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }