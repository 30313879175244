var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ODataTable", {
    ref: "oDataTableComponentRef",
    attrs: {
      columns: _vm.columns.all,
      "add-edit-columns": _vm.columns.addEdit,
      resource: "SubCategory",
      "total-label": _vm.$t(_vm.segment + ".total"),
      "file-name": _vm.$t(_vm.segment + ".fileName"),
      "add-row-button-label": _vm.$t(_vm.segment + ".add"),
      title: _vm.$t(_vm.segment + ".title"),
      filter: ["CategoriesID", "=", _vm.category.ID],
      "should-render-master-detail": true,
      "has-parent": !_vm.shouldShowTitle,
      "should-show-add-button": _vm.shouldShowAddButton,
      "should-show-edit-button": _vm.shouldShowEditButton,
      "should-show-delete-button": _vm.shouldShowDeleteButton,
      "should-show-toggle-button": _vm.shouldShowToggleButton,
      "on-loaded": _vm.handleSubCategoriesLoad,
      "is-edit-delete-button-visible": _vm.isEditDeleteButtonVisible
    },
    on: {
      rowInit: _vm.handleRowInit,
      rowInserted: _vm.handleRowInserted,
      rowRemoved: _vm.handleRowRemoved,
      editorPreparing: _vm.handleEditorPreparing
    },
    scopedSlots: _vm._u(
      [
        {
          key: "details",
          fn: function(ref) {
            var data = ref.data
            return [
              _c("Detail", {
                attrs: {
                  data: data,
                  "should-show-add-button": _vm.shouldShowAddButton,
                  "should-show-edit-button": _vm.shouldShowEditButton,
                  "should-show-delete-button": _vm.shouldShowDeleteButton,
                  "should-show-toggle-button": _vm.shouldShowToggleButton,
                  "should-show-title": _vm.shouldShowTitle
                }
              })
            ]
          }
        },
        {
          key: "toggle-button",
          fn: function(ref) {
            var data = ref.data
            return [
              _c("el-switch", {
                attrs: { value: _vm.isSubProgramActive(data.row.data.ID) },
                on: {
                  change: function($event) {
                    return _vm.toggleActivate(data.row.data.ID)
                  }
                }
              })
            ]
          }
        },
        _vm.shouldShowToggleButton
          ? {
              key: "custom-buttons",
              fn: function() {
                return [
                  _c("DxButton", {
                    attrs: {
                      template: "custom-button-template",
                      visible: _vm.isEditDeleteButtonVisible
                    }
                  })
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }