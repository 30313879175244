import { Test } from '@/models/test'
import i18n from '@/i18n'
import EpisodeService from '@/api/episode'
import ProgramService from '@/api/program'
import TestService from '@/api/test'
import TestSectionService from '@/api/TestSectionService'
import TestQuestionService from '@/api/TestQuestionService'
import TestQuestionAnswerService from '@/api/TestQuestionAnswerService'
import { PaginatedResponse } from '@/models/paginatedResponse'
import { Section } from '@/models/testSection'
import { MUTATION_TYPES as BASE_MUTATION_TYPES } from '../mutations'
import router from '@/router'
import { Message } from 'element-ui'
import { ROUTE_PATH } from '@/router/index'

const MUTATION_TYPES = BASE_MUTATION_TYPES.TEST

const initialState = () => ({
  test: new Test(),
  areProgramsLoading: false,
  areEpisodesLoading: false,
  programs: [],
  episodes: [],
  isLoading: false,
  areQuestionsAndTheirAnswersLoading: false,
  testSections: [],
  activatedQuestionsCount: 0
})

const mutations = {
  [MUTATION_TYPES.SET_PROGRAMS_LOADING]: (state, areProgramsLoading) => {
    state.areProgramsLoading = areProgramsLoading
  },

  [MUTATION_TYPES.SET_EPISODES_LOADING]: (state, areEpisodesLoading) => {
    state.areEpisodesLoading = areEpisodesLoading
  },

  [MUTATION_TYPES.SET_PROGRAMS]: (state, programs) => {
    state.programs = programs
    state.areProgramsLoading = false
  },

  [MUTATION_TYPES.SET_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  },

  [MUTATION_TYPES.SET_EPISODES]: (state, episodes) => {
    state.episodes = episodes
    state.areEpisodesLoading = false
  },

  [MUTATION_TYPES.SET_QUESTIONS]: (state, questions) => {
    state.test.setQuestions(questions)
    state.test.setQuestionsOrder()
    state.isLoading = false
  },

  [MUTATION_TYPES.SET_ANSWERS]: (state, answers) => {
    state.test.setAnswers(answers)
    state.isLoading = false
  },

  [MUTATION_TYPES.SET_QUESTIONS_AND_ANSWERS_LOADING]: (state, isLoading) => {
    state.areQuestionsAndTheirAnswersLoading = isLoading
  },

  [MUTATION_TYPES.SET_TEST_SECTIONS]: (state, testSections) => {
    state.testSections = testSections
  },
  [MUTATION_TYPES.SET_ACTIVATED_QUESTIONS_COUNT]: (
    state,

    activatedQuestions
  ) => {
    state.activatedQuestionsCount = activatedQuestions
  }
}
const actions = {
  setSiteId({ commit, dispatch }, siteId) {
    commit(MUTATION_TYPES.SET_PROGRAMS, [])
    dispatch('fetchPrograms')
  },
  setProgramId({ commit, dispatch }, programId) {
    commit(MUTATION_TYPES.SET_EPISODES, [])
    dispatch('fetchEpisodes')
  },
  async fetchPrograms({ commit, state }) {
    commit(MUTATION_TYPES.SET_EPISODES, [])
    commit(MUTATION_TYPES.SET_PROGRAMS_LOADING, true)
    try {
      const programs = await ProgramService.getBySiteId(state.test.siteId)
      commit(
        MUTATION_TYPES.SET_PROGRAMS,

        programs.map(program => program.Category)
      )
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPES.SET_PROGRAMS_LOADING, false)
      return Promise.reject(error)
    }
  },
  async fetchEpisodes({ commit, state }) {
    commit(MUTATION_TYPES.SET_EPISODES_LOADING, true)
    try {
      const { Media } = await EpisodeService.getBySiteIdAndProgramId(
        state.test
      )
      commit(MUTATION_TYPES.SET_EPISODES, Media)
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPES.SET_EPISODES_LOADING, false)
      return Promise.reject(error)
    }
  },
  async createTest({ commit, state }) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      state.test.updatePartial({
        sections: Section.fromList([{ mediaId: state.test.episodeId }])
      })
      await Promise.all(
        state.test.sections.map(async section => {
          const {
            value: testSectionsForSelectedMedia
          } = await TestSectionService.fetchByMediaId(section.mediaId)
          let testSectionId = null
          let testId = null
          if (testSectionsForSelectedMedia.length) {
            const testsResponse = await TestService.fetchBySiteId(
              state.test.siteId
            )
            const testsPaginatedResponse = new PaginatedResponse(testsResponse)
            while (testsPaginatedResponse.hasMore) {
              await testsPaginatedResponse.next()
            }

            const testIds = testsPaginatedResponse.items.map(test => test.ID)
            if (testIds.length) {
              testId = testIds[0]
            }
            if (!testIds.length) {
              const createdTest = await TestService.create(state.test.payload)

              testId = createdTest.ID
              testIds.push(testId)
            }
            let selectedTestSection = testSectionsForSelectedMedia.find(
              section => testIds.includes(section.tTestID)
            )
            if (!selectedTestSection) {
              const mediaDetails = await EpisodeService.fetchMediaDetails(
                state.test.episodeId
              )
              const mediaShortTitle = mediaDetails.value[0].ShortTitle
              state.test.updatePartial({ shortTitle: mediaShortTitle })
              section.updatePartial({ sectionName: mediaShortTitle })

              const createdTest = await TestService.create(state.test.payload)
              testId = createdTest.ID
              state.test.updatePartial({ id: testId })
              selectedTestSection = await TestSectionService.createTestSection(
                section.payload
              )
            }
            testSectionId = selectedTestSection.ID
            testId = selectedTestSection.tTestID
            state.test.updatePartial({ id: testId })
          }
          if (!testSectionsForSelectedMedia.length) {
            const {
              value: testsForSelectedSite
            } = await TestService.fetchBySiteId(state.test.siteId)
            if (testsForSelectedSite.length) {
              testId = testsForSelectedSite[0].ID
            }
            if (!testsForSelectedSite.length) {
              const createdTest = await TestService.create(state.test.payload)

              testId = createdTest.ID
            }
            state.test.updatePartial({ id: testId })
            const createTestSection = await TestSectionService.createTestSection(
              section.payload
            )

            testSectionId = createTestSection.ID
          }
          state.test.setSectionId(testSectionId)
          const {
            value: activatedQuestions
          } = await TestQuestionService.fetchBySectionId({
            id: testSectionId,
            isFreeText: state.test.isFreeText
          })
          commit(
            MUTATION_TYPES.SET_ACTIVATED_QUESTIONS_COUNT,
            activatedQuestions.length
          )
          return Promise.resolve(null)
        })
      )

      for await (const question of state.test.questions) {
        const createdQuestion = await TestQuestionService.createTestQuestion({
          ...question.payload,
          SortOrder: ++state.activatedQuestionsCount
        })

        question.setQuestionIdForAnswers(createdQuestion.ID)
        for (const answer of question.answers) {
          await TestQuestionAnswerService.createTestQuestionAnswer(
            answer.payload
          )
        }
      }
      commit(MUTATION_TYPES.SET_LOADING, false)

      Message({
        message: i18n.t('test.create.success'),
        type: 'success'
      })

      if (router.history.current.fullPath.includes(ROUTE_PATH.KNOWLEDGE_TEST)) {
        router.push(ROUTE_PATH.KNOWLEDGE_TEST)
      } else {
        router.push(ROUTE_PATH.REFLECTION_QUESTIONS)
      }
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPES.SET_LOADING, false)
      return Promise.reject(error)
    }
  },

  async fetchTestSections({ commit, dispatch, state }) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      const response = await TestSectionService.fetchByMediaId(
        state.test.episodeId
      )
      const paginatedResponse = new PaginatedResponse(response)
      while (paginatedResponse.hasMore) {
        await paginatedResponse.next()
      }

      const testsResponse = await TestService.fetchBySiteId(state.test.siteId)
      const testsPaginatedResponse = new PaginatedResponse(testsResponse)
      while (testsPaginatedResponse.hasMore) {
        await testsPaginatedResponse.next()
      }

      const testIds = testsPaginatedResponse.items.map(test => test.ID)

      const sections = paginatedResponse.items.filter(section =>
        testIds.includes(section.tTestID)
      )
      commit(MUTATION_TYPES.SET_TEST_SECTIONS, sections)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(MUTATION_TYPES.SET_LOADING, false)
    }
  },

  async updateQuestionsAndTheirAnswers({ commit, state }) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      state.test.setQuestionsOrder()

      const updatePromises = state.test.questions.map(question => {
        const questionUpdatePromise = TestQuestionService.updateById({
          id: question.id,
          data: question.payload
        })

        const answersUpdatePromises = question.answers.map(answer =>
          TestQuestionAnswerService.updateById({
            id: answer.id,
            data: answer.payload
          })
        )
        return Promise.all([questionUpdatePromise, ...answersUpdatePromises])
      })
      await Promise.all(updatePromises)
      commit(MUTATION_TYPES.SET_LOADING, false)

      Message({
        message: i18n.t('test.edit.success'),
        type: 'success'
      })

      if (router.history.current.fullPath.includes(ROUTE_PATH.KNOWLEDGE_TEST)) {
        router.push(ROUTE_PATH.KNOWLEDGE_TEST)
      } else {
        router.push(ROUTE_PATH.REFLECTION_QUESTIONS)
      }
    } catch (error) {
      commit(MUTATION_TYPES.SET_LOADING, false)
      return Promise.reject(error)
    }
  },
  async fetchQuestionsAndTheirAnswersBySectionId(
    { commit, dispatch, state },
    { id, isFreeText }
  ) {
    commit(MUTATION_TYPES.SET_QUESTIONS_AND_ANSWERS_LOADING, true)
    try {
      const { value: questions } = await TestQuestionService.fetchBySectionId({
        id,
        isFreeText
      })
      commit(MUTATION_TYPES.SET_QUESTIONS, questions)
      await dispatch('fetchAnswers')
      commit(MUTATION_TYPES.SET_QUESTIONS_AND_ANSWERS_LOADING, false)
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPES.SET_QUESTIONS_AND_ANSWERS_LOADING, false)
      return Promise.reject(error)
    }
  },

  async fetchAnswers({ commit, state }) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      const response = await TestQuestionAnswerService.fetchByQuestionIds(
        state.test.questionIds
      )
      const paginatedResponse = new PaginatedResponse(response)
      while (paginatedResponse.hasMore) {
        await paginatedResponse.next()
      }
      commit(MUTATION_TYPES.SET_ANSWERS, paginatedResponse.items)
      commit(MUTATION_TYPES.SET_LOADING, false)
    } catch (error) {
      commit(MUTATION_TYPES.SET_LOADING, false)
      return Promise.reject(error)
    }
  }
}

const getters = {
  findEpisodeById: state => id => state.episodes.find(el => el.ID === id),

  findProgramById: state => id => state.programs.find(el => el.ID === id),

  filterTestSectionsByMediaId: state => mediaId =>
    state.testSections.filter(el => el.MediaID === mediaId)
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
