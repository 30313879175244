import { createResource } from '@/utils/httpRequests'

export class ChatAssistantService {
  static createCopy(data) {
    return createResource({
      url: `${process.env.VUE_APP_CONTENTPART_API}/assistantchat`,
      data
    })
  }
}
