<template>
  <div class="container">
    <div class="steps-container">
      <h1 class="title">{{ $t("manageUsers.title") }}</h1>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          v-for="(step, index) in steps"
          :key="step.value"
          :class="{ 'selected-step': isActiveStep(step.value) }"
        >
          <el-link
            :underline="false"
            :disabled="lastTraversedStep < index"
            @click="navigateToStep(step.value)"
          >
            {{ step.label }}
          </el-link>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div :class="{ section: true, 'generate-report': isGenerateReportStep }">
      <div />
      <div>
        <h2
          v-if="!isAssignTagStep"
          class="subtitle "
          :class="{ 'no-bottom-margin': isDeactiveUserStep }"
        >
          {{ $t(stepTitle) }}
        </h2>
        <h4 v-if="isDeactiveUserStep" class="step-subtitle">
          {{ $t(`manageUsers.${activeStep}.subtitle`) }}
        </h4>
        <component
          :is="activeStepComponent"
          :users="customerIds"
          @onNextClick="handleNextClick"
          @onInvalidEmailFound="handleInvalidEmail"
        />
      </div>
      <div class="action-buttons self-start">
        <el-button
          v-if="isGenerateReportStep"
          type="primary"
          @click="handleCourseCampaignClick"
        >{{ $t("manageUsers.createCampaign") }}</el-button>
        <el-button
          v-if="isGenerateReportStep"
          type="primary"
          :disabled="disabledUpdateSendList"
          @click="handleUpdateSendListClick"
        >{{ $t("manageUsers.updateSendList") }}</el-button>
        <el-button
          v-if="isMixedTypeImport && isGenerateReportStep "
          type="primary"
          @click="handleNextClick"
        >{{ $t("manageUsers.assignTag.stepLabel") }}</el-button>
        <el-button
          v-if="isGenerateReportStep"
          icon="el-icon-download"
          :disabled="!newlyAddedUsers.length"
          @click="exportNewUsersToExcel"
        >{{ $t("manageUsers.generateReport.export") }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import * as xlsx from 'xlsx'
const { mapState, mapActions } = createNamespacedHelpers('user/importedUsers')
const { mapState: mapCourseCampaignState, mapMutations: mapCourseCampaignMutations } = createNamespacedHelpers('courseCampaign')
import { mapGetters } from 'vuex'
import SelectAccount from './selectAccount.vue'
import ImportUsers from './importUsers.vue'
import MatchFields from './matchFields.vue'
import ValidateEmails from './validateEmails.vue'
import GenerateReport from './generateReport.vue'
import DeactivateUser from './deactivateUser.vue'
import TagUsers from '../../components/tagUsers/index.vue'
import { STEP } from './config'
import { IMPORT_TYPE } from '@/store/modules/user/modules/importedUsers'
import { MUTATION_TYPES } from '@/store/mutations'
import { ROUTE_PATH } from '@/router'
import { Report } from '@/models/report'
const { mapMutations: mapAppMutations } =
  createNamespacedHelpers('app')
export default {
  name: 'ManageUsers',
  components: {
    SelectAccount,
    ImportUsers,
    MatchFields,
    ValidateEmails,
    GenerateReport,
    DeactivateUser,
    TagUsers
  },
  data() {
    return {
      activeStep: STEP.SELECT_TYPE,
      lastTraversedStep: 0,
      stepComponent: {
        [STEP.SELECT_TYPE]: SelectAccount,
        [STEP.IMPORT_CSV]: ImportUsers,
        [STEP.MATCH_COLUMN]: MatchFields,
        [STEP.VALIDATE_EMAILS]: ValidateEmails,
        [STEP.GENERATE_REPORT]: GenerateReport,
        [STEP.DEACTIVATE_USER]: DeactivateUser,
        [STEP.ASSIGN_TAG]: TagUsers
      },
      customerIdsToAssignCourseCampaign: [],
      disabledUpdateSendListValue: 'newCustomers',
      newUsers: []
    }
  },
  computed: {
    ...mapCourseCampaignState({
      selectedCustomers: (state) => state.selectedCustomers.Ids,
      customers: (state) => state.selectedCustomers
    }),
    ...mapGetters(['customerIds']),
    ...mapState({
      importType: (state) => state.importType,
      usersWithInvalidEmails: (state) => state.usersWithInvalidEmail,
      reports: (state) => state.reports
    }),
    isMixedTypeImport() {
      return this.importType === IMPORT_TYPE.MIXED
    },

    activeStepComponent() {
      return this.stepComponent[this.activeStep]
    },
    currentSelectedStepIndex() {
      return this.steps.findIndex((step) => step.value === this.activeStep)
    },
    isDeactiveUserStep() {
      return this.activeStep === STEP.DEACTIVATE_USER
    },

    isValidateEmailStep() {
      return this.activeStep === STEP.VALIDATE_EMAILS
    },

    isGenerateReportStep() {
      return this.activeStep === STEP.GENERATE_REPORT
    },
    isAssignTagStep() {
      return this.activeStep === STEP.ASSIGN_TAG
    },
    newlyAddedUsers() {
      const newlyAddedUsersReport = this.reports.find(
        (report) => report.isNewImport
      )

      return newlyAddedUsersReport
        ? newlyAddedUsersReport.users
        : []
    },
    stepTitle() {
      return `manageUsers.${this.activeStep}.title${
        this.isValidateEmailStep
          ? `.${this.usersWithInvalidEmails.titleType}`
          : ''
      }`
    },
    shouldGenerateReport() {
      return (
        [
          STEP.MATCH_COLUMN,
          STEP.DEACTIVATE_USER,
          STEP.VALIDATE_EMAILS
        ].includes(this.activeStep) ||
        (this.activeStep === STEP.IMPORT_CSV &&
          this.importType === IMPORT_TYPE.DEACTIVATE)
      )
    },

    shouldMatchCustomers() {
      return [STEP.MATCH_COLUMN, STEP.VALIDATE_EMAILS].includes(
        this.activeStep
      )
    },
    steps() {
      return Object.values(STEP).filter(step =>
        this.isMixedTypeImport ? step : step !== STEP.ASSIGN_TAG).map(value =>
        ({
          label: this.$t(`manageUsers.${value}.stepLabel`),
          value
        }))
    },
    disabledUpdateSendList() {
      return !this.newUsers.length
    }
  },
  watch: {
    '$route.meta.activeStep': {
      handler: function(activeStep) {
        if (activeStep) {
          this.activeStep = activeStep
        }
      },
      deep: true,
      immediate: true
    },
    reports(val) {
      this.newUsers = val.filter((val) => val.status === this.disabledUpdateSendListValue)
    }
  },
  methods: {
    ...mapActions(['matchCustomers', 'generateReport', 'debugApi']),
    ...mapAppMutations({
      setCustomers: MUTATION_TYPES.APP.SET_SELECTED_CUSTOMERS
    }),
    isActiveStep(activeStep) {
      return this.activeStep === activeStep
    },
    handleNewAndUpdatedCustomerSelection() {
      this.customerIdsToAssignCourseCampaign = Report.getUsersToAssignCourseCampaignFromReports(this.reports)
    },

    ...mapCourseCampaignMutations({
      setSelectedCustomers:
        MUTATION_TYPES.COURSE_CAMPAIGN.SET_SELECTED_CUSTOMERS
    }),
    async handleNextClick(deactivatedUsers = []) {
      if (this.shouldGenerateReport) {
        this.activeStep = await this.handleCustomerMatch(deactivatedUsers)
      } else {
        this.activeStep = this.steps[this.currentSelectedStepIndex + 1].value
        this.lastTraversedStep = this.currentSelectedStepIndex
      }
    },
    navigateToStep(step) {
      this.activeStep = step
    },
    handleInvalidEmail() {
      this.activeStep = STEP.VALIDATE_EMAILS
    },
    async handleCustomerMatch(deactivatedUsers = []) {
      if (this.shouldMatchCustomers) {
        await this.debugApi()
        const shouldNavigateToDeactiveStep = await this.matchCustomers()
        if (shouldNavigateToDeactiveStep) {
          return STEP.DEACTIVATE_USER
        }
      }
      await this.generateReport(deactivatedUsers)
      this.handleNewAndUpdatedCustomerSelection()
      return STEP.GENERATE_REPORT
    },
    exportNewUsersToExcel() {
      const workbook = xlsx.utils.book_new()
      xlsx.utils.book_append_sheet(
        workbook,
        xlsx.utils.json_to_sheet(this.newlyAddedUsers),
        'sample'
      )
      xlsx.writeFile(workbook, 'result.xlsx')
    },
    handleCourseCampaignClick() {
      this.setSelectedCustomers(this.customerIdsToAssignCourseCampaign)
      this.$router.push(ROUTE_PATH.CREATE_CAMPAIGN)
    },
    handleUpdateSendListClick() {
      if (!this.disabledUpdateSendList) {
        this.setCustomers(Report.getUpdateSendListUsers(this.newUsers))
        this.$router.push(ROUTE_PATH.SENDLIST)
      }
    }
  }
}
</script>

<style lang="scss">
$base-spacing: 5px;
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtitle {
  @extend .title;
  margin-left: $base-spacing * 4;
}

.container {
  margin: $base-spacing * 6;
}
.selected-step {
  .el-link {
    color: #409eff !important;
  }
}
.title {
  font-weight: 400;
}
.step-subtitle {
  @extend .title;
  font-size: 0.8rem;
  margin-left: $base-spacing * 4;
  margin-top: $base-spacing;
}
.no-bottom-margin {
  margin-bottom: 0;
}
.steps {
  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.generate-report {
  flex-direction: row;
  justify-content: space-between;
}
.action-buttons {
  display: flex;
  margin-right: $base-spacing * 10;
}
.el-icon-close:before {
  color: #F56C6C !important;
}
.el-icon-error:before {
  display: none !important;
}
</style>
