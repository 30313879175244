var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-container" },
    _vm._l(_vm.menus, function(menu) {
      return _c("div", { key: menu.ID }, [
        _c(
          "span",
          [
            _c("DxCheckBox", {
              on: {
                "value-changed": function($event) {
                  return _vm.handleValueChanged(menu.ID)
                }
              },
              model: {
                value: menu.value,
                callback: function($$v) {
                  _vm.$set(menu, "value", $$v)
                },
                expression: "menu.value"
              }
            }),
            _vm._v(" " + _vm._s(menu.Name) + " ")
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "sub-menu-container" },
          [_c("MenuManager", { attrs: { menus: menu.children } })],
          1
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }