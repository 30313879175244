<template>
  <div class="app-container">
    <el-dialog
      v-if="selectedAnswersToKnowledgeTest"
      :visible.sync="showDetails"
      @close="handleDetailsDialogClose"
    >
      <Detail :detail="{...selectedAnswersToKnowledgeTest}" />
    </el-dialog>
    <ODataTable
      ref="oDataTableComponentRef"
      :key="questionType"
      :columns="columns"
      :add-edit-columns="addEditColumns"
      resource="tTestCustomerAnswerPortal"
      :total-label="$t('answersToKnowledgeTest.total')"
      :should-show-edit-button="false"
      :file-name="$t('answersToKnowledgeTest.fileName')"
      :should-show-add-button="false"
      :add-row-button-label="$t('answersToKnowledgeTest.add')"
      :title="$t('answersToKnowledgeTest.title')"
      :filter="filter"
      @rowInit="handleRowInit"
      @rowUpdating="handleRowUpdating"
    >
      <template #custom-buttons>
        <DxButton icon="info" :on-click="handleViewClick" />
      </template>
    </ODataTable>
  </div>
</template>
<script>
import ODataTable from '../../components/ODataTable'
import { columns, addEditColumns } from './config'
import { TEST_TYPE } from '@/models/test'
import { Question } from '@/models/question'
import { DxButton } from 'devextreme-vue/data-grid'
import Detail from './details/index'
export default {
  name: 'AnswersKnowledgeTest',
  components: {
    ODataTable,
    DxButton,
    Detail
  },
  data() {
    return {
      columns,
      addEditColumns,
      questionType: TEST_TYPE.MCQ,
      selectedAnswersToKnowledgeTest: null,
      showDetails: false
    }
  },
  computed: {
    filter() {
      return Question.getFiltersByQuestionType(this.questionType)
    }
  },
  methods: {
    handleRowInit(event) {
      event.data.DateAdded = new Date().toISOString()
      event.data.LastUpdate = new Date().toISOString()
    },
    handleRowUpdating(event) {
      event.newData.LastUpdate = new Date().toISOString()
    },
    handleViewClick(event) {
      this.selectedAnswersToKnowledgeTest = event.row.data
      this.showDetails = true
    },
    handleDetailsDialogClose() {
      this.selectedAnswersToKnowledgeTest = null
      this.showDetails = false
    }
  }
}
</script>
