import i18n from '@/i18n'
import BrevoDataStore from '@/models/BrevoDataStore'

let validationValue

export const setValidationValue = value => {
  validationValue = value
}

const validateTextField = ({ rule, value, callback, type }) => {
  if (!value && validationValue) {
    callback(new Error(i18n.t(`errors.fill${type}`)))
  } else {
    callback()
  }
}

export const validators = {
  validateSite: (rule, value, callback) =>
    validateTextField({ rule, value, callback, type: 'Site' }),
  validateSignature: (rule, value, callback) =>
    validateTextField({ rule, value, callback, type: 'Signature' }),
  validateContactPersonPosition: (rule, value, callback) =>
    validateTextField({ rule, value, callback, type: 'ContactPersonPosition' })
}

export const createColumns = () => {
  const addListColumns = [
    {
      field: 'MediaID',
      width: '100%',
      lookup: {
        store: new BrevoDataStore({ resource: 'contacts/folders' }),
        paginate: true,
        pageSize: 50
      },
      valueExpr: 'id',
      displayExpr: 'name'
    }
  ]

  return {
    addListColumns
  }
}
