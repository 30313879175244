var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    { staticClass: "container" },
    [
      _c(
        "el-row",
        { attrs: { type: "flex", gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 6 } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  align: "right",
                  "start-placeholder":
                    "" + _vm.$t("mediaReports.startDatePlaceholder"),
                  "end-placeholder":
                    "" + _vm.$t("mediaReports.endDatePlaceholder"),
                  format: _vm.dateFormat
                },
                model: {
                  value: _vm.dateRange,
                  callback: function($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 6 } },
            [
              _c("NumberInput", {
                attrs: {
                  value: _vm.selectedFilters.startCustomerID,
                  placeholder: "" + _vm.$t("invertedMediaReports.startCustomer")
                },
                on: { change: _vm.handleStartCustomerChange }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 6 } },
            [
              _c("NumberInput", {
                attrs: {
                  value: _vm.selectedFilters.endCustomerID,
                  placeholder: "" + _vm.$t("invertedMediaReports.endCustomer")
                },
                on: { change: _vm.handleEndCustomerChange }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { type: "flex", gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 6 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder:
                      "" + _vm.$t("invertedMediaReports.startCategory"),
                    loading: _vm.areCategoriesLoading
                  },
                  model: {
                    value: _vm.selectedFilters.startCategoryID,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedFilters, "startCategoryID", $$v)
                    },
                    expression: "selectedFilters.startCategoryID"
                  }
                },
                _vm._l(_vm.categories, function(category) {
                  return _c("el-option", {
                    key: category.ID,
                    attrs: { label: category.Name, value: category.ID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 6 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder:
                      "" + _vm.$t("invertedMediaReports.endCategory"),
                    loading: _vm.areCategoriesLoading
                  },
                  model: {
                    value: _vm.selectedFilters.endCategoryID,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedFilters, "endCategoryID", $$v)
                    },
                    expression: "selectedFilters.endCategoryID"
                  }
                },
                _vm._l(_vm.endCategories, function(category) {
                  return _c("el-option", {
                    key: category.ID,
                    attrs: { label: category.Name, value: category.ID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 6 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: "" + _vm.$t("invertedMediaReports.startMedia"),
                    loading: _vm.areEpisodesLoading
                  },
                  model: {
                    value: _vm.selectedFilters.startMediaID,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedFilters, "startMediaID", $$v)
                    },
                    expression: "selectedFilters.startMediaID"
                  }
                },
                _vm._l(_vm.episodes, function(episode) {
                  return _c("el-option", {
                    key: episode.ID,
                    attrs: { label: episode.Title, value: episode.ID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 6 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: "" + _vm.$t("invertedMediaReports.endMedia"),
                    loading: _vm.areEpisodesLoading
                  },
                  model: {
                    value: _vm.selectedFilters.endMediaID,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedFilters, "endMediaID", $$v)
                    },
                    expression: "selectedFilters.endMediaID"
                  }
                },
                _vm._l(_vm.endMedias, function(episode) {
                  return _c("el-option", {
                    key: episode.ID,
                    attrs: { label: episode.Title, value: episode.ID }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.isFilterDisabled },
              on: { click: _vm.filter }
            },
            [_vm._v(_vm._s(_vm.$t("mediaReports.filter")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }