export const columns = [
  { dataField: 'ID' },
  { dataField: 'Name' },
  { dataField: 'DateAdded', sort: 'desc' },
  { dataField: 'IsActive' },
  { dataField: 'bIsFreetext', visible: false },
  { dataField: 'bCorrectAnswerRequired' },
  { dataField: 'tTestSectionID' }
].map(field => {
  return {
    field: field.dataField,
    sort: field?.sort,
    visible: field?.visible,
    width: '100%'
  }
})
