import AssistantService from '@/api/assistants'
import { MUTATION_TYPES } from '../mutations'
import { Assistants } from '@/models/assistants'
import { AssistantPaginatedResponse } from '@/models/assistantsPaginatedResponse'
import { Message } from 'element-ui'

const MUTATION_TYPE = MUTATION_TYPES.ASSISTANTS

const state = {
  isLoading: false,
  assistants: new Assistants([]),
  uploadedFiles: []
}

const mutations = {
  [MUTATION_TYPE.SET_ASSISTANTS](state, assistants = []) {
    state.assistants.set(assistants)
  },

  [MUTATION_TYPE.CREATE_ASSISTANT](state, { assistant, index }) {
    state.assistants.add({ assistant, index })
  },

  [MUTATION_TYPE.UPDATE_ASSISTANT](state, assistant) {
    state.assistants.update(assistant)
  },

  [MUTATION_TYPE.DELETE_ASSISTANT](state, id) {
    state.assistants.remove(id)
  },

  [MUTATION_TYPE.SET_IS_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  },

  [MUTATION_TYPE.SET_UPLOADED_FILES]: (state, files) => {
    state.uploadedFiles = files
  },

  [MUTATION_TYPE.ADD_UPLOADED_FILE]: (state, file) => {
    state.uploadedFiles = [file, ...state.uploadedFiles]
  },

  [MUTATION_TYPE.REMOVE_UPLOADED_FILE]: (state, fileId) => {
    state.uploadedFiles = state.uploadedFiles.filter(
      file => file?.id !== fileId
    )
  }
}
const actions = {
  async fetchAll({ commit }) {
    commit(MUTATION_TYPE.SET_IS_LOADING, true)
    try {
      const response = await AssistantService.fetchAll()
      const paginatedResponse = new AssistantPaginatedResponse({
        ...response,
        fetchNext: AssistantService.fetchAll
      })
      while (paginatedResponse.hasMore) {
        await paginatedResponse.next()
      }
      commit(MUTATION_TYPE.SET_ASSISTANTS, paginatedResponse.items)
      return Promise.resolve(null)
    } catch (error) {
      Message({
        type: 'error',
        message: error.message
      })
      throw Promise.reject(error)
    } finally {
      commit(MUTATION_TYPE.SET_IS_LOADING, false)
    }
  },

  async delete({ commit }, assistantId) {
    commit(MUTATION_TYPE.SET_IS_LOADING, true)
    try {
      const response = await AssistantService.delete(assistantId)
      commit(MUTATION_TYPE.DELETE_ASSISTANT, response.id)
      return Promise.resolve(null)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(MUTATION_TYPE.SET_IS_LOADING, false)
    }
  },

  async create({ commit, rootState }, index) {
    commit(MUTATION_TYPE.SET_IS_LOADING, true)
    try {
      const response = await AssistantService.create(
        rootState.assistant.assistant.payload
      )
      commit(MUTATION_TYPE.CREATE_ASSISTANT, { assistant: response, index })
      return Promise.resolve(null)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(MUTATION_TYPE.SET_IS_LOADING, false)
    }
  },

  async update({ commit, rootState }) {
    commit(MUTATION_TYPE.SET_IS_LOADING, true)
    try {
      const response = await AssistantService.update(
        rootState.assistant.assistant.patchPayload
      )
      commit(MUTATION_TYPE.UPDATE_ASSISTANT, response)

      return Promise.resolve(null)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(MUTATION_TYPE.SET_IS_LOADING, false)
    }
  },

  async fetchAllUploadedFiles({ commit }) {
    commit(MUTATION_TYPE.SET_IS_LOADING, true)
    try {
      const { data } = await AssistantService.fetchAllUploadedFiles()
      commit(MUTATION_TYPE.SET_UPLOADED_FILES, data)

      return Promise.resolve(null)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(MUTATION_TYPE.SET_IS_LOADING, false)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
