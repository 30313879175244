import AssistantDefaults from '@/api/assistantDefaults'
import { Message } from 'element-ui'

const actions = {
  async clone(_, data) {
    try {
      await AssistantDefaults.create(data)
      return Promise.resolve(null)
    } catch (error) {
      Message({
        type: 'error',
        message: error.message
      })
    }
  }
}

export default {
  namespaced: true,
  actions
}
