import {
  createResource,
  fectchResource,
  deleteResource
} from '@/utils/httpRequests'
export default class TagService {
  static getAll() {
    return fectchResource(
      `${process.env.VUE_APP_ODATA_API}/Tag?$orderby=${encodeURIComponent(
        'TagName asc'
      )}`
    )
  }

  static create(data) {
    return createResource({
      url: `${process.env.VUE_APP_ODATA_API}/Tag`,
      data
    })
  }

  static assign(data) {
    return createResource({
      url: `${process.env.VUE_APP_ODATA_API}/UserTag`,
      data
    })
  }

  static delete({ tagId, isTagInUse }) {
    return deleteResource(
      `${process.env.VUE_APP_ODATA_API}/tag?id=${tagId}&bOverride=${isTagInUse}`
    )
  }

  static fetchUsersByTagId(tagId) {
    return fectchResource(
      `${process.env.VUE_APP_ODATA_API}/UserTag/GetCustomerForUserTagID(tagID=%5B${tagId}%5D)`
    )
  }

  static getByAccountAndSubAccount({ account, subAccount = '' }) {
    const params = [account, subAccount].filter(item => !!item).join('/')
    return fectchResource(
      `${process.env.VUE_APP_CONTENTPART_API}/Admin/GetTagForAccount/${params}`
    )
  }

  static async getByTags(tagIds) {
    const filterString = tagIds?.join(',')
    return await fectchResource(
      `${process.env.VUE_APP_ODATA_API}/Tag?$filter=ID in (${filterString})`
    )
  }

  static unassignTagForUsers({ customerIds, tagId }) {
    const baseUrl = `${process.env.VUE_APP_CONTENTPART_API}/Admin/DeleteTagForUsers`
    const queries = [
      ...customerIds.map(id => `customerid=${id}`),
      `id=${tagId}`,
      `Override=true`
    ]

    return deleteResource(`${baseUrl}?${queries.join('&')}`)
  }
}
