export class AssistantMetaContent {
  constructor(metaContent = {}) {
    Object.assign(this, metaContent)
  }

  get payload() {
    return {
      [this.key]: this.value
    }
  }

  updatePartial(params) {
    Object.assign(this, params)
  }

  static fromMap(metaContentMap = {}) {
    return Object.entries(metaContentMap).map(
      ([key, value], index) =>
        new this({
          key,
          value,
          index: index + 1
        })
    )
  }
}
