var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("goals.filter.title"),
            visible: _vm.isGoalsFilterDialogOpen,
            "close-on-click-modal": false,
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              _vm.isGoalsFilterDialogOpen = $event
            },
            close: _vm.handleGoalsDetailsDialogClose
          }
        },
        [
          _c("GoalFilters", {
            attrs: { sites: _vm.sites, medias: _vm.medias },
            on: {
              filter: _vm.handleFilterApply,
              closeFilter: _vm.handlerCloseGoalFilter
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("goals.goalText"),
            visible: _vm.isGoalsTextDialogOpen,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.isGoalsTextDialogOpen = $event
            },
            close: _vm.handleGoalTextDetailsDialogOpenClose
          }
        },
        [_c("p", [_vm._v(_vm._s(_vm.goalText))])]
      ),
      _c("ODataTable", {
        key: _vm.filter,
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns.all,
          "add-edit-columns": _vm.columns.addEdit,
          "total-label": _vm.$t("goals.total"),
          "file-name": _vm.$t("goals.fileName"),
          "add-row-button-label": _vm.$t("goals.add"),
          title: _vm.$t("goals.title"),
          resource: "Goal",
          filter: _vm.filter,
          "should-show-edit-button": false,
          "should-show-add-button": false,
          "should-show-delete-button": false,
          "action-buttons-width": 70
        },
        scopedSlots: _vm._u([
          {
            key: "custom-buttons",
            fn: function() {
              return [
                _c("DxButton", {
                  attrs: {
                    icon: "info",
                    hint: _vm.$t("goals.goalText"),
                    "on-click": _vm.handleGoalTextDetailsDialogOpenClose
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "custom-toolbar-before",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: _vm.defaultType, plain: "" },
                    on: { click: _vm.handleGoalsDetailsDialogOpen }
                  },
                  [_vm._v(_vm._s(_vm.$t("report")))]
                )
              ]
            },
            proxy: true
          },
          {
            key: "custom-toggle-before",
            fn: function() {
              return [
                _c("el-switch", {
                  staticClass: "margin",
                  attrs: { "inactive-text": _vm.$t("goals.hideBlank") },
                  on: { change: _vm.handleGoalTextFilter },
                  model: {
                    value: _vm.hideGoalText,
                    callback: function($$v) {
                      _vm.hideGoalText = $$v
                    },
                    expression: "hideGoalText"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }