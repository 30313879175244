const addedDateField = 'AddedDate'
const lastVisitedField = 'LastVisited'
const addedByAdminIdField = 'AddedByAdminID'
export const categoryIdField = 'CategoryID'
export const disabledColumns = [
  addedDateField,
  lastVisitedField,
  addedByAdminIdField
]
export const SOURCES = {
  FOLDER: 'meta'
}
export const createColumns = ({ categories, medias }) => {
  const info = [
    {
      field: categoryIdField,
      width: 150,
      minWidth: 100,
      label: 'mediasByCategory.column.categoryID',
      lookup: categories
    },
    {
      field: 'MediaID',
      width: 150,
      minWidth: 100,
      label: 'Media',
      lookup: medias
    },
    {
      field: 'Name',
      width: 150,
      minWidth: 100
    },
    {
      field: 'LinkURL',
      width: 150,
      minWidth: 100,
      label: 'link',
      editCellTemplate: 'file-item',
      type: SOURCES.FOLDER,
      cdnUrl: process.env.VUE_APP_CONNECT_CDN_URL,
      shouldUseFolderForPreview: true,
      showAsTextBox: true,
      shouldShowUploadedImagePreview: true
    },
    {
      field: addedDateField,
      width: 150,
      minWidth: 100
    },
    {
      field: lastVisitedField,
      width: 150,
      minWidth: 100
    },
    {
      field: 'IsLive',
      width: 150,
      minWidth: 100,
      dataType: 'boolean'
    },
    {
      field: 'IsActive',
      width: 150,
      minWidth: 100,
      dataType: 'boolean',
      editCellTemplate: 'checkbox-item',
      type: SOURCES.FOLDER
    },
    {
      field: addedByAdminIdField,
      width: 150,
      minWidth: 100
    },
    {
      field: 'IsLocalFile',
      label: 'isLocalFile',
      width: 150,
      minWidth: 100
    },
    {
      field: 'LocalFilePath',
      label: 'localFilePath',
      width: 150,
      minWidth: 100
    }
  ]
  const description = [
    {
      field: 'Description',
      width: 150,
      minWidth: 100
    },
    {
      field: 'ContentText',
      width: 150,
      minWidth: 100
    }
  ]

  const addEdit = [...info, ...description]

  return {
    addEdit,
    info,
    description,
    all: [
      {
        field: 'ID',
        width: 150,
        minWidth: 100,
        visible: false
      },
      ...addEdit
    ]
  }
}
