import { getToken } from '@/utils/auth'
import { customEncodeURIComponent } from '@/utils/customEncodeURIComponent'

const datetimeRegex = /datetime'(.*?)'/g

const substringRegex = /substringof\((.*?)\)/g

const replaceDatetimeWithOffset = (filterQuery = '') => {
  const datetimeQueries = filterQuery.match(datetimeRegex)
  if (!datetimeQueries) {
    return filterQuery
  }
  return datetimeQueries.reduce(
    (filterQuery, datetimeQuery) =>
      filterQuery.replace(
        datetimeQuery,
        new Date(datetimeQuery.split("'")[1]).toISOString()
      ),
    filterQuery
  )
}

const replaceSubstringWithContains = (filterQuery = '') => {
  const substringQueries = filterQuery.match(substringRegex)

  if (!substringQueries) {
    return filterQuery
  }

  const keyValueRegex = /'(.*?)'|,(.*?)\)/g

  const fieldRegex = /,|\)/g

  return substringQueries.reduce((filterQuery, substringQuery) => {
    const [value, key] = substringQuery.match(keyValueRegex)
    return filterQuery.replace(
      substringQuery,
      `contains(${key.replace(fieldRegex, '')} ,${value})`
    )
  }, filterQuery)
}

const createPayload = params =>
  Object.entries(params).reduce(
    (payload, [key, value]) => ({
      ...payload,
      [key]: value instanceof Date ? new Date(value).toISOString() : value
    }),
    {}
  )

export const parseFilters = (filter = []) => {
  let parsedFilter = ''
  filter.forEach(item => {
    if (Array.isArray(item)) {
      parsedFilter = `${parsedFilter}(${parseFilters(item).trim()})`
    } else {
      parsedFilter = `${parsedFilter} ${
        typeof item === 'string' ? item.replace('=', 'eq') : item
      } `
    }
  })

  return parsedFilter
}

export class StoreConfig {
  constructor({
    resource,
    key = 'ID',
    groupBy,
    aliasResource = '',
    filter = [],
    onLoaded,
    method = '',
    orderBy = ''
  } = {}) {
    Object.assign(this, {
      type: 'odata',
      key,
      filterToLower: false,
      ...(onLoaded && { onLoaded }),
      filter: filter ?? [],
      groupBy,
      aliasResource,
      resource,
      method,
      orderBy
    })

    this.beforeSend = this.beforeSend.bind(this)
  }

  get url() {
    return `${process.env.VUE_APP_ODATA_API}/${this.aliasResource ||
      this.resource}`
  }

  filterByIds(ids) {
    this.filter = ['ID', 'in', `(${ids.join(', ')})`]
  }

  async beforeSend(xhr) {
    xhr.headers.Authorization = `Bearer ${getToken()}`

    const removeSelectFields = ['FolderName', 'FilePath']
    const removePayloadFields = ['FolderName', 'FilePath']

    /* eslint-disable */

    let {
      $inlinecount,
      $filter,
      $select,
      $orderby,
      ...restParams
    } = xhr.params;
    /* eslint-enable */

    if (xhr.payload) {
      removePayloadFields.forEach(val => {
        delete xhr.payload[val]
      })
    }

    if ($select) {
      const selectedField = $select
      $select = selectedField
        .split(',')
        .filter(val => !removeSelectFields.includes(val))
        .join(',')
    }

    if ($filter) {
      $filter = replaceDatetimeWithOffset(
        replaceSubstringWithContains($filter)
      )
    }

    if (this.filter?.length) {
      const parsedFilter = parseFilters(this.filter)
      if (
        !xhr.url.includes(encodeURIComponent(parsedFilter)) &&
        !xhr.url.includes(customEncodeURIComponent(parsedFilter))
      ) {
        $filter = $filter
          ? `((${parsedFilter}) and (${$filter}))`
          : parsedFilter
      }
    }

    if (xhr.method !== 'get' && this.aliasResource) {
      xhr.url = xhr.url.replace(this.aliasResource, this.resource)
    }

    if (this.method) {
      xhr.method = this.method
    }

    if (xhr.method === 'MERGE') {
      xhr.method = 'PATCH'
      xhr.payload = createPayload(xhr.payload)
    }

    const isCountApiCallWithGroupBy =
      !restParams.$select && this.groupBy && Object.keys(xhr.params)?.length

    xhr.params = {
      ...restParams,
      $select,
      ...(!xhr.url.includes('$count') && { $count: true }),
      ...(!isCountApiCallWithGroupBy &&
        $filter && {
        $filter
      }),
      ...(isCountApiCallWithGroupBy && {
        $apply: `${$filter ? `filter(${$filter})/` : ''}groupby((${
          this.groupBy
        }))`
      }),
      ...((this.orderBy || $orderby) && {
        $orderby: `${$orderby || this.orderBy}`
      })
    }
  }
}
