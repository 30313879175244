import { Option } from './option'

export class OptionList {
  constructor(options = []) {
    this.options = options
  }

  findLabelByValue(value) {
    return this.options.find(option => option.value === value)?.label
  }

  findByLabel(label) {
    return this.options.find(option => option.label === label)
  }

  static fromList(options = []) {
    return new OptionList(options.map(option => new Option(option)))
  }
}
