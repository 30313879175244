<template>
  <el-card
    class="thumbnail"
    shadow="hover"
    draggable
    @dragstart.native="handleDragStart"
  >
    <el-image :src="data.PreviewImage" fit="cover" lazy class="thumbnail-image">
      <div slot="placeholder" class="image-slot">
        <i class="el-icon-loading" />
      </div>
      <div slot="error" class="image-slot">
        <i class="el-icon-picture-outline" />
      </div>
    </el-image>
    <div class="thumbnail-title">
      <span class="truncate-text thumbnail-text">{{ data.Name }}</span>
    </div>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BuilderContentTiles',
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters(['siteName'])
  },
  methods: {
    handleDragStart() {
      this.$emit('dragstart', this.data.MainContent)
    }
  }
}
</script>

<style lang="scss" scoped>
$base-spacing: 5px;

.body {
  padding: $base-spacing*3;
}

.description {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: $base-spacing;
  line-height: $base-spacing*3;
}

.thumbnail {
  cursor: all-scroll;
  margin: $base-spacing*2;

  ::v-deep .el-card__body {
    padding: 0px;
  }
  .thumbnail-image {
    height: 100px;
    width: 100%;
    ::v-deep .image-slot {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
  }
  }
  .thumbnail-title {
    position: relative;
    display: flex;
    align-self: center;
    align-items: center;
    background: black;
    opacity: 0.4;

    .thumbnail-text {
      color: white;
      padding: $base-spacing;
    }
  }
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
