<template>
  <div :class="classObj" class="app-wrapper">
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <sidebar class="sidebar-container" />
    <div class="main-container">
      <div :class="{'fixed-header':fixedHeader}">
        <navbar />
      </div>
      <app-main />
    </div>
    <el-dialog
      :title="$t('errors.selectSite')"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :show-close="false"
    >
      <div>
        <site-switcher />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">{{ $t('continue') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { Navbar, Sidebar, AppMain } from './components'
import SiteSwitcher from '@/components/SiteSwitcher'
import ResizeMixin from './mixin/ResizeHandler'

const contentPartStore = 'contentPart'
const appStore = 'app'

export default {
  name: 'Layout',
  components: {
    Navbar,
    Sidebar,
    AppMain,
    SiteSwitcher
  },
  mixins: [ResizeMixin],
  data() {
    return {
      dialogVisible: false
    }
  },
  computed: {
    ...mapState('app', ['siteID']),
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    }
  },
  async created() {
    await this.setSites()
    if (!(await this.setSiteIDFromStorage())) {
      this.dialogVisible = true
    }
  },
  methods: {
    ...mapActions(appStore, ['setSiteIDFromStorage', 'updateContentForSiteID']),
    ...mapActions(contentPartStore, ['setSites']),
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },
    handleClose() {
      if (this.siteID === -1) {
        return this.$message({
          type: 'error',
          message: this.$t('errors.selectSiteContinue')
        })
      } else {
        this.updateContentForSiteID()
        this.dialogVisible = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/styles/mixin.scss";
  @import "~@/styles/variables.scss";

  .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;
    &.mobile.openSidebar{
      position: fixed;
      top: 0;
    }
  }
  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px)
  }

  .mobile .fixed-header {
    width: 100%;
  }
</style>
