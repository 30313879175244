import CourseCampaignService from '@/api/courseCampaign'
import EpisodeService from '@/api/episode'
import ProgramService from '@/api/program'
import i18n from '@/i18n'
import { CourseCampaign } from '@/models/courseCampaign'
import { Customers } from '@/models/customers'
import { TransactionItemList } from '@/models/transaction'
import router from '@/router'
import { Message } from 'element-ui'
import { MUTATION_TYPES as BASE_MUTATION_TYPES } from '../mutations'

const MUTATION_TYPES = BASE_MUTATION_TYPES.COURSE_CAMPAIGN

const state = {
  courseCampaign: new CourseCampaign(),
  selectedCustomers: new Customers([]),
  areProgramsLoading: false,
  areEpisodesLoading: false,
  programs: [],
  episodes: [],
  isLoading: false,
  miscellaneous: {}
}

const mutations = {
  [MUTATION_TYPES.SET_SELECTED_CUSTOMERS]: (state, customers) => {
    state.selectedCustomers = new Customers(customers)
  },

  [MUTATION_TYPES.SET_COURSE_CAMPAIGN]: (state, updateParams) => {
    state.courseCampaign.updatePartial(updateParams)
  },

  [MUTATION_TYPES.SET_PROGRAMS_LOADING]: (state, areProgramsLoading) => {
    state.areProgramsLoading = areProgramsLoading
  },

  [MUTATION_TYPES.SET_EPISODES_LOADING]: (state, areEpisodesLoading) => {
    state.areEpisodesLoading = areEpisodesLoading
  },

  [MUTATION_TYPES.SET_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  },

  [MUTATION_TYPES.SET_PROGRAMS]: (state, programs) => {
    state.programs = programs
    state.areProgramsLoading = false
  },

  [MUTATION_TYPES.SET_EPISODES]: (state, episodes) => {
    state.episodes = episodes
    state.areEpisodesLoading = false
  },

  [MUTATION_TYPES.SET_MISCELLANEOUS]: (state, misc) => {
    Object.assign(state.miscellaneous, misc)
  }
}

const actions = {
  setSiteId({ commit, dispatch }, siteId) {
    commit(MUTATION_TYPES.SET_PROGRAMS, [])
    commit(MUTATION_TYPES.SET_COURSE_CAMPAIGN, { siteId })
    dispatch('fetchPrograms')
  },
  setProgramId({ commit, dispatch }, programId) {
    commit(MUTATION_TYPES.SET_EPISODES, [])
    commit(MUTATION_TYPES.SET_COURSE_CAMPAIGN, { programId })
    dispatch('fetchEpisodes')
  },
  async fetchPrograms({ commit, state }) {
    commit(MUTATION_TYPES.SET_EPISODES, [])
    commit(MUTATION_TYPES.SET_PROGRAMS_LOADING, true)
    try {
      const programs = await ProgramService.getBySiteId(
        state.courseCampaign.siteId
      )
      commit(
        MUTATION_TYPES.SET_PROGRAMS,

        programs.map(program => program.Category)
      )
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPES.SET_PROGRAMS_LOADING, false)
      return Promise.reject(error)
    }
  },
  async fetchEpisodes({ commit, state }) {
    commit(MUTATION_TYPES.SET_EPISODES_LOADING, true)
    try {
      const { Media } = await EpisodeService.getBySiteIdAndProgramId(
        state.courseCampaign
      )
      commit(MUTATION_TYPES.SET_EPISODES, Media)
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPES.SET_EPISODES_LOADING, false)
      return Promise.reject(error)
    }
  },
  async assignEpisodes({ commit, state }, onEpisodesAssignProgressChangeCb) {
    try {
      commit(MUTATION_TYPES.SET_LOADING, true)
      const assignEpisodes = state.courseCampaign.createAssignEpisodesParams(
        state.selectedCustomers.customers
      )
      const transactionItems = new TransactionItemList(assignEpisodes)
      while (transactionItems.hasMore) {
        const items = transactionItems.next()
        onEpisodesAssignProgressChangeCb(transactionItems.percentage)
        await EpisodeService.assignToCustomers(items)
      }
      commit(MUTATION_TYPES.SET_LOADING, false)
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPES.SET_LOADING, false)
      return Promise.reject(error)
    }
  },

  async createCourseCampaign({ commit, state }) {
    let failedCampaignsList = []
    try {
      commit(MUTATION_TYPES.SET_LOADING, true)

      const courseCampaigns = state.courseCampaign.createCourseCampaign() ?? []

      for (let i = 0; i < courseCampaigns.length; i++) {
        try {
          await CourseCampaignService.create(courseCampaigns[i])
        } catch (error) {
          failedCampaignsList.push(i)
          Message({
            message:
              ` ${courseCampaigns[i].name}: ${error.response.data.message}` ||
              'Something went wrong.',
            type: 'error'
          })
        }
      }

      commit(MUTATION_TYPES.SET_LOADING, false)
      if (!failedCampaignsList.length) {
        Message({
          message: i18n.t('courseCampaign.create.success'),
          type: 'success'
        })
        router.push('/users')
      } else {
        state.courseCampaign.handleFailedCampaigns(failedCampaignsList)
        setTimeout(() => {
          Message({
            message: `${i18n.t('courseCampaign.create.failed')} for ${
              failedCampaignsList.length
            } campaigns`,
            type: 'error',
            offset: 20,
            duration: 3000
          })
          failedCampaignsList = []
        }, 3000)
      }
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPES.SET_LOADING, false)
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
