<template>
  <el-main class="container">
    <el-row type="flex" :gutter="40">
      <el-col :xs="24" :sm="6">
        <el-select
          v-model="test.siteId"
          :filterable="true"
          :placeholder="`${$t('test.chooseSite')}`"
        >
          <el-option
            v-for="(site, index) in sites"
            :key="index"
            :label="site.Name"
            :value="site.ID"
          />
        </el-select>
      </el-col>
      <el-col :xs="24" :sm="10">
        <el-select
          v-model="test.programId"
          :filterable="true"
          :placeholder="`${$t('test.chooseProgram')}`"
          :loading="areProgramsLoading"
        >
          <el-option
            v-for="(program, index) in programs"
            :key="index"
            :label="program.Name"
            :value="program.ID"
          />
        </el-select>
      </el-col>
      <el-col :xs="24" :sm="10">
        <el-select
          v-model="test.episodeId"
          :filterable="true"
          :placeholder="`${$t('test.episode')}`"
          :loading="areEpisodesLoading"
          @change="filter"
        >
          <el-option
            v-for="(episode, index) in episodes"
            :key="index"
            :label="episode.Title"
            :value="episode.ID"
          />
        </el-select>
      </el-col>
    </el-row>
  </el-main>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('contentPart')
const {
  mapState: mapTestState,
  mapActions: mapTestActions,
  mapGetters: mapTestGetters
} = createNamespacedHelpers('test')
const { mapActions: mapAppActions } = createNamespacedHelpers('app')

export default {
  name: 'TestFilters',
  computed: {
    ...mapTestGetters(['filterTestSectionsByMediaId']),
    ...mapState(['sites']),
    ...mapTestState({
      test: (state) => state.test,
      episodes: (state) => state.episodes,
      programs: (state) => state.programs,
      areProgramsLoading: (state) => state.areProgramsLoading,
      areEpisodesLoading: (state) => state.areEpisodesLoading
    })
  },
  watch: {
    'test.siteId': {
      handler(siteId) {
        if (siteId) {
          this.setSiteId(siteId)
          this.test.programId = ''
          this.test.episodeId = ''
        }
      },
      deep: true
    },
    'test.programId': {
      handler(programId) {
        if (programId) {
          this.setProgramId(programId)
          this.test.episodeId = ''
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapAppActions(['setEpisodeId']),
    ...mapTestActions(['setSiteId', 'setProgramId']),
    async filter() {
      this.$emit('filter')
    },
    createFilters() {
      const sectionIds = this.filterTestSectionsByMediaId(
        this.test.episodeId
      ).map((section) => section.ID)

      return this.test.createFilters(sectionIds)
    }
  }
}
</script>
