import InvertedMediaReportService from '@/api/invertedMediaReport'
import { MUTATION_TYPES as BASE_MUTATION_TYPES } from '../../../mutations'

const MUTATION_TYPES = BASE_MUTATION_TYPES.USER.INVERTED_MEDIA_REPORTS

const initialState = () => ({
  mediaReports: []
})

const mutations = {
  [MUTATION_TYPES.SET_MEDIA_REPORTS]: (state, mediaReports = []) => {
    state.mediaReports = mediaReports
  }
}

const actions = {
  async fetchInvertedMediaReports({ commit }, params) {
    try {
      const mediaReports = await InvertedMediaReportService.getAll(params)
      commit(MUTATION_TYPES.SET_MEDIA_REPORTS, mediaReports)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions
}
