<template>
  <ODataTable
    ref="oDataTableComponentRef"
    :columns="columns"
    resource="tTestQuestion"
    :total-label="$t('test.total')"
    :file-name="$t(`${test.translationSection}.fileName`)"
    :should-show-add-button="canAdd"
    :add-row-button-label="$t('test.create.title')"
    :title="$t(`${test.translationSection}.title`)"
    :is-custom-add="true"
    :show-data="showData"
    :should-show-delete-button="false"
    :should-show-edit-button="false"
    :action-buttons-width="100"
    @addClick="handleCreateTest"
    @edit="navigateToEdit"
    @clearFilters="handleClearFilter"
    @cellPrepared="handleCellPrepared"
  >
    <template #custom-filters>
      <test-filters ref="testFilterRef" @filter="applyFilter" />
    </template>
    <template #toggle-button="{ data }">
      <el-switch
        :value="data.row.data.IsActive"
        @change="toggleActivate(data.row.data)"
      />
    </template>
    <template #custom-buttons>
      <DxButton v-if="canEdit" icon="edit" :on-click="navigateToEdit" />
      <DxButton template="custom-button-template" />
    </template>
  </ODataTable>
</template>
<script>
import { columns } from './config'
import { createNamespacedHelpers } from 'vuex'
import { DxButton } from 'devextreme-vue/data-grid'
import { confirm } from 'devextreme/ui/dialog'
const { mapActions, mapState } = createNamespacedHelpers('test')
import ODataTable from '@/components/ODataTable'
import TestFilters from './filters.vue'
import router from '@/router/index'
const { mapState: mapUserState } = createNamespacedHelpers('user')

export default {
  name: 'Test',
  components: {
    ODataTable,
    TestFilters,
    DxButton
  },
  data() {
    return {
      columns,
      showData: false
    }
  },
  computed: {
    ...mapState({
      test: (state) => state.test
    }),
    ...mapUserState({
      canAdd: state => state.user.canAccess(`${router.history.current.fullPath}/new`),
      canEdit: state => state.user.canAccess(`${router.history.current.fullPath}/:id`)
    })
  },
  created() {
    this.test.setTypeByRoutePath(router.history.current.fullPath)
  },
  async mounted() {
    this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
    this.showData = false
    if (this.test.episodeId) {
      await this.applyFilter()
    }
    this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
  },
  methods: {
    ...mapActions(['fetchTestSections']),
    handleCreateTest() {
      this.$router.push(`${router.history.current.fullPath}/new`)
    },
    async applyFilter() {
      this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
      try {
        await this.fetchTestSections()
        const filters = this.$refs.testFilterRef.createFilters()
        this.showData = true
        this.$refs.oDataTableComponentRef.dataSource.filter = filters
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.filter(
          filters
        )
      } finally {
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
      }
    },
    handleClearFilter() {
      this.test.clear()
      this.test.clearContext()
      this.showData = false
    },
    navigateToEdit(event) {
      this.$router.push(`${router.history.current.fullPath}/${event.row.data.tTestSectionID}`)
    },
    async handleToggleConfirm(question) {
      this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
      try {
        await this.$refs.oDataTableComponentRef.dataSource.store.update(question.ID, { IsActive: !question.IsActive })
        await this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.refresh()
        this.$message({
          type: 'success',
          message: this.$t(!question.IsActive ? 'test.edit.updateQuestions.activated' : 'test.edit.updateQuestions.deactivated')
        })
      } catch (error) {
        this.$message({
          type: 'error',
          message: error.message
        })
      } finally {
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
      }
    },
    async toggleActivate(question) {
      const result = await confirm(this.$t('sites.toggleConfirm'))
      if (result) {
        await this.handleToggleConfirm(question)
      }
    },
    handleCellPrepared(event) {
      if (!event?.data?.IsActive && event?.rowType === 'data') {
        event?.cellElement?.firstElementChild?.classList.add('dx-button-disabled')
      }
    }
  }
}
</script>

