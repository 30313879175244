import {
  fectchResource,
  createResource,
  deleteResource
} from '@/utils/httpRequests'

export class CategoryService {
  static fetchAll(columns = []) {
    const queryParam = [
      '$top=5000',
      ...(columns.length ? [`$select=${columns.join(',')}`] : [])
    ].join('&')
    return fectchResource(
      `${process.env.VUE_APP_ODATA_API}/Categories?${queryParam}`
    )
  }

  static fetchMediaIdsForCategoryAndSite({ categoryId, siteId }) {
    return fectchResource(
      `${process.env.VUE_APP_CONTENTPART_API}/categories/medialist/${categoryId}/${siteId}`
    )
  }
  static fetchAllDisabled() {
    return fectchResource(
      `${process.env.VUE_APP_ODATA_API}/tDisabledCategory?$top=5000`
    )
  }

  static enableCategoryForSite(id) {
    return deleteResource(
      `${process.env.VUE_APP_ODATA_API}/tDisabledCategory(${id})`
    )
  }

  static disableCategoryForSite(categoryToBeAdded) {
    return createResource({
      url: `${process.env.VUE_APP_ODATA_API}/tDisabledCategory`,
      data: categoryToBeAdded
    })
  }
}
