<template>
  <div class="app-assistant-container">
    <el-dialog
      v-if="isComparisionDialog"
      :title="$t('assistants.compare')"
      :visible.sync="isComparisionDialog"
      @close="handleDialogClose"
    >
      <compareAssistants
        v-if="isComparisionDialog"
        :selected-assistants="selectedRowsData"
      />
    </el-dialog>
    <el-dialog
      v-if="isHistoryDialog"
      title="Chat History"
      :visible.sync="isHistoryDialog"
      @close="handleDialogClose"
    >
      <chatHistory
        v-if="isHistoryDialog"
        :assistant="selectedAssistant"
      />
    </el-dialog>
    <vue-final-modal
      v-model="isDialogOpen"
      :resize="true"
      :styles="styleModal"
      :content-style="contentStyleModal"
      :lock-scroll="false"
      :hide-overlay="false"
      :click-to-close="false"
      :esc-to-close="true"
      :z-index-base="1000000000"
      :z-index="false"
      :focus-retain="true"
      :fit-parent="true"
      :drag="true"
      drag-selector=".el-dialog__header"
      :keep-changed-style="false"
      :resize-directions="['t', 'tr', 'r', 'br', 'b', 'bl', 'l', 'tl']"
    >
      <div class="el-dialog__header">
        <span class="el-dialog__title">Chat Assistant</span><button type="button" aria-label="Close" class="el-dialog__headerbtn">
          <i
            class="el-dialog__close el-icon el-icon-close"
            @click="handleDialogClose"
          />
        </button>
      </div>
      <chatAssistant
        v-if="isDialogOpen"
        :assistant="selectedAssistant"
        :is-dialog-open="isDialogOpen"
      />
    </vue-final-modal>
    <ODataTable
      ref="oDataTableComponentRef"
      :columns="columns.all"
      :add-edit-columns="columns.addEdit"
      :action-buttons-width="150"
      :custom-delete-message="$t('assistants.confirmDeleteMessage')"
      :total-label="$t('assistants.total')"
      :title="$t('assistants.title')"
      :is-static-data-source="true"
      :is-custom-form="true"
      :static-data="assistants"
      :add-row-button-label="$t('assistants.create')"
      :should-render-master-detail="true"
      @rowInit="handleRowInit"
      @rowInserted="handleRowInserted"
      @rowRemoved="handleRowRemoved"
      @rowUpdated="handleRowUpdated"
      @rowInserting="handleRowInserting"
      @rowUpdating="handleRowUpdating"
      @editorPreparing="handleEditorPreparing"
      @editingCancelling="handleEditingCancel"
      @editingStart="handleEditingStart"
      @fileToBeDeleted="handleFileToBeDeleted"
      @fileUploaded="handleFileUploaded"
      @rowSaved="handleRowSaved"
      @selectionChange="handleSelectionChange"
    >
      <template #custom-buttons>
        <DxButton icon="comment" :on-click="handleChatAssistantClick" />
        <DxButton :icon="chatHistoryIconUrl" :on-click="handleViewHistory" />
        <DxButton icon="copy" :on-click="handleAssistantCloneClick" />
      </template>
      <template #custom-toolbar-after>
        <el-button
          v-if="selectedRowsData.length > 1"
          type="primary"
          @click="handleCompareAssistants"
        >
          {{ $t('assistants.compare') }}
        </el-button>
      </template>
      <template #custom-form>
        <DxTabbedItem>
          <DxTab :title="$t('assistants.tab.info.label')">
            <DxSimpleItem
              v-for="column in columns.infoColumns"
              :key="`add_edit_${column.field}`"
              :data-field="column.field"
            />
          </DxTab>
          <DxTab :title="$t('assistants.tools.title')">
            <DxSimpleItem
              v-for="column in columns.toolsColumns"
              :key="`add_edit_${column.field}`"
              :data-field="column.field"
              editor-type="dxTextArea"
              :editor-options="{ height: '200px' }"
            />
          </DxTab>
          <DxTab :title="$t('assistants.metaContents.title')">
            <DxSimpleItem
              v-for="column in columns.metaColumns"
              :key="`add_edit_${column.field}`"
              :data-field="column.field"
              editor-type="dxTextArea"
              :editor-options="{ height: '200px' }"
            />
          </DxTab>
          <DxTab :title="$t('assistants.fileUpload.title')">
            <DxSimpleItem
              v-for="column in columns.fileColumns"
              :key="`add_edit_${column.field}`"
              :data-field="column.field"
            />
          </DxTab>
        </DxTabbedItem>
      </template>
      <template #details="{ data }">
        <DxTabPanel>
          <DxItem :title="$t('assistants.tools.title')" template="tools-tab" />
          <template #tools-tab>
            <AssistantsTools :data="data" />
          </template>
          <DxItem
            :title="$t('assistants.metaContents.title')"
            template="meta-contents-tab"
          />
          <template #meta-contents-tab>
            <AssistantMetaContents :data="data" />
          </template>
        </DxTabPanel>
      </template>
    </ODataTable>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import ODataTable from '@/components/ODataTable'
import AssistantsTools from './tools/index.vue'
import AssistantMetaContents from './metaContents/index.vue'
import chatAssistant from '@/components/ChatAssistant'
import chatHistory from '@/components/ChatHistory'
import compareAssistants from '@/components/CompareAssistants'
import {
  createColumns,
  mediaIdField
} from './config'
import { AssistantStore } from './store'
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel'
import { DxButton } from 'devextreme-vue/data-grid'
import { Media } from '@/models/media'
import { DxSimpleItem, DxTabbedItem, DxTab } from 'devextreme-vue/form'
import {
  defaultToolsData,
  defaultMetaDataValue
} from '@/utils/openAi'
import DxTextArea from 'devextreme-vue/text-area'
import AssistantService from '@/api/assistants'
import MetaContentService from '@/api/metacontent'
import FileService from '@/api/file'
const {
  mapState: mapAssistantsState,
  mapActions: mapAssistantsActions,
  mapMutations: mapAssistantsMutations
} = createNamespacedHelpers('assistants')
const {
  mapState: mapAssistantState,
  mapMutations: mapAssistantMutations
} = createNamespacedHelpers('assistant')
const {
  mapState: mapAppState,
  mapActions: mapAppActions
} = createNamespacedHelpers('app')
const { mapState: mapUserState } = createNamespacedHelpers('user')
import { MUTATION_TYPES } from '@/store/mutations'
import { VueFinalModal } from 'vue-final-modal'

export default {
  name: 'Assistants',
  components: {
    ODataTable,
    DxTabPanel,
    DxItem,
    AssistantsTools,
    AssistantMetaContents,
    chatAssistant,
    DxButton,
    DxSimpleItem,
    DxTabbedItem,
    DxTab,
    // eslint-disable-next-line vue/no-unused-components
    DxTextArea,
    VueFinalModal,
    chatHistory,
    compareAssistants
  },
  data() {
    return {
      isDialogOpen: false,
      selectedAssistantId: null,
      selectedAssistants: [],
      fileUploaded: null,
      mode: '',
      accept: '*',
      isHistoryDialog: false,
      selectedRowsData: [],
      isComparisionDialog: false,
      chatHistoryIconUrl: '/assets/icons/chat-history.svg'
    }
  },
  computed: {
    ...mapAssistantsState({
      assistants: state => new AssistantStore(state.assistants.assistants),
      isLoading: state => state.isLoading,
      uploadedFiles: state => state.uploadedFiles
    }),
    ...mapAssistantState({
      assistantFiles: state => state.assistantFiles
    }),
    ...mapUserState({
      loggedInUser: state => state.user.ID
    }),
    ...mapAppState({
      medias: state => state.medias
    }),
    columns() {
      return createColumns({
        medias: this.medias.map(media => media.option),
        selectedAssistants: this.selectedAssistants,
        mode: this.mode,
        accept: this.accept,
        selectedAssistantId: this.selectedAssistantId
      })
    },
    styleModal() {
      return {
        display: 'flex',
        'justify-content': 'center',
        height: '100vh',
        'align-items': 'center'
      }
    },
    contentStyleModal() {
      return {
        padding: '20px !important',
        border: '2px solid #e2e8f0',
        'border-radius': '0.25rem',
        background: '#fff',
        color: '#606266',
        'font-size': '14px',
        'word-break': 'break-all',
        'box-sizing': 'border-box',
        overflow: 'scroll',
        position: 'relative',
        'max-width': '100vw',
        'min-height': '524px',
        'max-height': '100vh',
        'min-width': '360px'
      }
    }
  },
  watch: {
    isLoading(value) {
      if (value) {
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
      } else if (!value) {
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
      }
    }
  },
  async mounted() {
    await Promise.all([
      this.fetchAll(),
      this.fetchMedias(Media.optionAndIdColumns)
    ])
  },
  methods: {
    ...mapAssistantsActions(['fetchAll', 'fetchAllUploadedFiles']),
    ...mapAppActions(['fetchMedias', 'updateAssistantId']),
    ...mapAssistantMutations({
      setFilesToBeDeleted: MUTATION_TYPES.ASSISTANT.SET_FILES_TO_BE_DELETED,
      addFileToBeDeleted: MUTATION_TYPES.ASSISTANT.ADD_FILE_TO_BE_DELETED
    }),
    ...mapAssistantsMutations({
      addUploadedFile: MUTATION_TYPES.ASSISTANTS.ADD_UPLOADED_FILE,
      removeUploadedFile: MUTATION_TYPES.ASSISTANTS.REMOVE_UPLOADED_FILE
    }),
    handleFileUploaded({ fileToBeUploaded, newlyUploadedFile }) {
      this.fileUploaded = fileToBeUploaded
      this.addUploadedFile(newlyUploadedFile)
    },
    handleChatAssistantClick(event) {
      this.selectedAssistantId = event.row.data.id
      this.selectedAssistant = event.row.data
      this.isDialogOpen = true
    },
    handleViewHistory(event) {
      this.isHistoryDialog = true
      this.selectedAssistant = event.row.data
    },
    async handleDialogClose() {
      this.isDialogOpen = false
      this.isHistoryDialog = false
      this.selectedAssistantId = null
      this.selectedAssistant = null
      await this.fetchAll()
    },
    async handleRowInserted(event) {
      if (
        event.data?.Media &&
        !this.medias.find(media => media.ID === event.data.Media)?.AssistantID
      ) {
        await this.updateAssistantId({
          mediaId: event.data.Media,
          assistantId: this.assistants._array[0].id
        })
      }
    },
    handleRowInit() {
      this.mode = 'create'
      this.selectedAssistants = []
    },
    async handleRowRemoved(event) {
      if (event.data?.Media) {
        const mediaToRemoveAssistant = this.medias.find(
          media => media.AssistantID === event.data.id
        )
        if (mediaToRemoveAssistant.ID) {
          await this.updateAssistantId({
            mediaId: mediaToRemoveAssistant.ID,
            assistantId: null
          })
        }
      }
    },
    async handleAssistantCloneClick(event) {
      const result = await this.$confirm(
        this.$t('noOverridingMediaAssistantId'),
        this.$t('assistants.confirmDuplicateMessage'),
        {
          confirmButtonText: this.$t('ok'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }
      )
      if (result) {
        await this.assistants.insert(event.row)
      }
    },
    handleRowInserting(event) {
      this.handleDataParsing(event.data)
    },
    handleRowUpdating(event) {
      this.handleDataParsing(event.newData)
    },
    handleDataParsing(data) {
      if (data.metadata) {
        data.metadata = JSON.parse(data.metadata)
      }
      if (data.tools) {
        data.tools = JSON.parse(data.tools)
      }
    },
    async handleRowSaved(event) {
      if (!event.changes.length && this.assistantFiles.length) {
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
        try {
          for (const file of this.assistantFiles) {
            await AssistantService.deleteAssistantFile({
              assistantId: this.selectedAssistantId,
              fileId: file.id
            })
          }
          this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.refresh()
        } catch (error) {
          this.$message({
            message: error.message,
            type: 'error'
          })
        } finally {
          this.setFilesToBeDeleted([])
          await this.fetchAll()
          this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
          this.$message({
            message: this.$t('updateSuccess'),
            type: 'success'
          })
        }
      }
      this.selectedAssistantId = null
    },
    async handleEditorPreparing(event) {
      if (event.isOnForm) {
        if (event.dataField === mediaIdField) {
          event.editorOptions.value = this.medias.find(
            media => media.AssistantID === event.row.data.id
          )?.ID
        }
        if (!event.value) {
          if (event.dataField === 'tools') {
            event.editorOptions.value = JSON.stringify(defaultToolsData)
          }
          if (event.dataField === 'metadata') {
            event.editorOptions.value = JSON.stringify(defaultMetaDataValue)
          }
        }
      }
    },
    async handleEditingCancel(event) {
      if (event.changes[0]?.data.UploadFile) {
        const fileId = this.uploadedFiles.find(
          file => file.filename === event.changes[0].data.UploadFile
        ).id
        if (fileId) {
          await AssistantService.deleteUploadedFile(fileId)
          if (this.uploadedFiles.length) {
            this.removeUploadedFile(fileId)
          }
        }
      }
      if (this.assistantFiles.length) {
        this.setFilesToBeDeleted([])
      }
      this.selectedAssistantId = null
    },
    handleFileToBeDeleted(fileToBeDeleted) {
      this.addFileToBeDeleted(fileToBeDeleted)
    },
    async handleEditingStart(event) {
      this.mode = 'edit'
      try {
        this.selectedAssistantId = event.data.id

        const { data } = await AssistantService.fetchAllFilesForAssistant(
          event.data.id
        )
        if (!this.uploadedFiles.length) {
          await this.fetchAllUploadedFiles()
        }
        const fileNames = data.map(assistant => {
          return {
            filename: this.uploadedFiles.find(file => file.id === assistant.id)
              ?.filename,
            id: assistant.id
          }
        })
        this.selectedAssistants = fileNames
      } catch (error) {
        this.$message({
          type: 'error',
          message: error.message
        })
      }
    },
    handleSelectionChange({ selectedRowsData }) {
      this.selectedRowsData = selectedRowsData
    },
    handleCompareAssistants() {
      this.isComparisionDialog = true
    },
    async handleRowUpdated(event) {
      this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
      try {
        if (this.assistantFiles.length) {
          for (const file of this.assistantFiles) {
            await AssistantService.deleteAssistantFile({
              assistantId: event.key,
              fileId: file.id
            })
          }
        }
        if (
          event.data?.Media &&
          !this.medias.find(media => media.ID === event.data.Media)?.AssistantID
        ) {
          await this.updateAssistantId({
            mediaId: event.data.Media,
            assistantId: event.key
          })
        }
        if (event.data.UploadFile) {
          this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.refresh()
          if (this.uploadedFiles[0].id) {
            const paramsData = {
              file_id: this.uploadedFiles[0].id
            }
            await AssistantService.createAssistantFile(paramsData, event.key)
            const formdata = new FormData()
            const newfile = new File(
              [this.fileUploaded],
              this.fileUploaded.name,
              { type: this.fileUploaded.type }
            )
            formdata.set('file', newfile)
            formdata.set('filename', newfile.name)
            const type = '/files/assistant/'
            let response = await FileService.upload({ formdata, type })
            response = response.replace('/images', '')
            const metaParamsData = {
              IsLocalFile: true,
              LinkURL: `https://connect.iknow.se${response}`,
              LocalFilePath: '/files/assistant/',
              AddedByAdminID: this.loggedInUser,
              AddedDate: new Date().toISOString(),
              LastVisited: new Date().toISOString()
            }
            await MetaContentService.create(metaParamsData)
            this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.refresh()
          }
        }
      } catch (error) {
        this.$message({
          type: 'error',
          message: error.message
        })
      } finally {
        await this.fetchAll()
        this.selectedAssistantId = null
        this.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
      }
    }
  }
}
</script>
<style lang="scss">
.app-assistant-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.el-dialog {
  max-width: 710px;
  min-width: 340px;
}

.el-dialog__body {
  padding: 30px 20px !important;
}

@media (max-width: 750px) {
  .el-dialog {
    width: 360px;
  }
}

.el-message-box {
  width: auto;
}

.vfm--inset[data-v-1cd72822] {
  position: relative;
}

.el-dialog__header {
  padding: 20px 20px 10px 20px;
  cursor: grab;
}

.dx-icon.dx-link-icon {
  height: 15px !important;
  position: relative;
  top: 5px;
}

div[aria-label='Chat History'] {
  width:400px !important;
}
</style>
