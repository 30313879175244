<template>
  <div :class="{ 'has-logo': showLogo }">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar v-loading="isLoading" wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
import { SUPER_ADMIN_ONLY_ACCESS_PATH } from '../../../router/index.js'

export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapGetters(['sidebar']),
    routes() {
      return this.canManageAdmins(this.$store.state.user.user.menus.toMenus())
    },
    isLoading() {
      return this.$store.state.app.areMenusLoading
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  methods: {
    canManageAdmins(routes) {
      if (this.$store.state.user.user.isSuperAdmin) {
        return routes
      } else {
        return this.filterRoutesForNonSuperAdmins(routes)
      }
    },
    filterRoutesForNonSuperAdmins(routes) {
      return routes.filter((route) => {
        if (!SUPER_ADMIN_ONLY_ACCESS_PATH.find((val) => val.path === route.path)) {
          return route
        }
      })
    }
  }
}
</script>
