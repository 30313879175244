var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      staticClass: "thumbnail",
      attrs: { shadow: "hover", draggable: "" },
      nativeOn: {
        dragstart: function($event) {
          return _vm.handleDragStart.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "el-image",
        {
          staticClass: "thumbnail-image",
          attrs: { src: _vm.data.PreviewImage, fit: "cover", lazy: "" }
        },
        [
          _c(
            "div",
            {
              staticClass: "image-slot",
              attrs: { slot: "placeholder" },
              slot: "placeholder"
            },
            [_c("i", { staticClass: "el-icon-loading" })]
          ),
          _c(
            "div",
            {
              staticClass: "image-slot",
              attrs: { slot: "error" },
              slot: "error"
            },
            [_c("i", { staticClass: "el-icon-picture-outline" })]
          )
        ]
      ),
      _c("div", { staticClass: "thumbnail-title" }, [
        _c("span", { staticClass: "truncate-text thumbnail-text" }, [
          _vm._v(_vm._s(_vm.data.Name))
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }