var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          attrs: {
            model: _vm.loginForm,
            rules: _vm.loginRules,
            "auto-complete": "on",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-row",
            { staticClass: "title-container" },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("login")))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "username" } },
            [
              _c(
                "span",
                { staticClass: "svg-container" },
                [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                1
              ),
              _c("el-input", {
                ref: "username",
                attrs: {
                  placeholder: _vm.$t("username"),
                  name: "username",
                  type: "text",
                  tabindex: "1",
                  "auto-complete": "on"
                },
                model: {
                  value: _vm.loginForm.username,
                  callback: function($$v) {
                    _vm.$set(_vm.loginForm, "username", $$v)
                  },
                  expression: "loginForm.username"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c(
                "span",
                { staticClass: "svg-container" },
                [_c("svg-icon", { attrs: { "icon-class": "password" } })],
                1
              ),
              _c("el-input", {
                key: _vm.passwordType,
                ref: "password",
                attrs: {
                  type: _vm.passwordType,
                  placeholder: _vm.$t("password"),
                  name: "password",
                  tabindex: "2",
                  "auto-complete": "on"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleLogin.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.loginForm.password,
                  callback: function($$v) {
                    _vm.$set(_vm.loginForm, "password", $$v)
                  },
                  expression: "loginForm.password"
                }
              }),
              _c(
                "span",
                { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                [
                  _c("svg-icon", {
                    attrs: {
                      "icon-class":
                        _vm.passwordType === "password" ? "eye" : "eye-open"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.showInputCode
            ? _c(
                "el-form-item",
                { attrs: { prop: "loginCode" } },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svg-icon", { attrs: { "icon-class": "password" } })],
                    1
                  ),
                  _c("el-input", {
                    ref: "loginCode",
                    attrs: {
                      placeholder: _vm.$t("code"),
                      name: "loginCode",
                      type: "text",
                      tabindex: "1"
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleVerify.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.loginForm.logInCode,
                      callback: function($$v) {
                        _vm.$set(_vm.loginForm, "logInCode", $$v)
                      },
                      expression: "loginForm.logInCode"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.showInputCode
            ? _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.handleLogin.apply(null, arguments)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("login")))]
              )
            : _vm._e(),
          _vm.showInputCode
            ? _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.handleVerify.apply(null, arguments)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Verify")))]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { type: "text" },
              on: { click: _vm.handleForgotPassword }
            },
            [_vm._v(_vm._s(_vm.$t("forgotPassword.link")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }