<template>
  <div class="column-flex">
    <div>
      <template v-for="(report, index) in reports">
        <div :key="index">
          <div class="title-container">
            <p>{{ report.totalEmails }}</p>
            <p>{{ $t(`manageUsers.generateReport.${report.status}`) }}</p>
          </div>
          <div v-if="!isDeactivatedImport">
            <p v-for="user in report.users" :key="userKey(user)" class="subtitle">
              {{ importedUsers.isCheckForImportingExtIdSelected ? `${user.EMail} - ${user.ExtID}`: user.EMail }}
            </p>
          </div>
          <div v-else>
            <div v-if="!importedUsers.isCheckForImportingExtIdSelected">
              <p v-for="email in report.emails" :key="email" class="subtitle">
                {{ email }}
              </p>
            </div>
            <div v-else>
              <p v-for="extId in report.extIds" :key="extId" class="subtitle">
                {{ extId }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import { IMPORT_TYPE } from '@/store/modules/user/modules/importedUsers'
const { mapState } = createNamespacedHelpers('user/importedUsers')
export default {
  name: 'GenerateReport',
  props: {
    data: {
      type: Object,
      default() {
        return null
      }
    }
  },
  computed: {
    ...mapState({
      reports: (state) => state.reports,
      importedUsers: state => state.importedUsers,
      importType: state => state.importType
    }),
    isDeactivatedImport() {
      return this.importType === IMPORT_TYPE.DEACTIVATE
    }
  },
  methods: {
    userKey(user) {
      return this.importedUsers.isCheckForImportingExtIdSelected ? user.ExtID : user.EMail
    }
  }
}
</script>

<style lang="scss" scoped>
$base-spacing: 5px;

.column-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: $base-spacing * 4;
}
.subtitle {
  margin-left: $base-spacing * 5;
  font-size: 0.8rem;
}
.title-container {
  display: flex;
  align-items: center;
  p {
    font-size: 1.2rem;
  }
  p:last-child {
    margin-left: $base-spacing;
  }
  p:nth-child(odd) {
    color: #007bff;
  }
}
</style>
