var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container py-none" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            { staticClass: "py-none" },
            [
              _c(
                "el-row",
                { staticClass: "m-container" },
                [
                  _c(
                    "el-col",
                    { staticClass: "column", attrs: { xs: 24, sm: 12 } },
                    [
                      _c(
                        "el-row",
                        { staticClass: "center", attrs: { type: "flex" } },
                        [
                          _c(
                            "el-col",
                            { staticClass: "m-title", attrs: { span: 4 } },
                            [_vm._v(_vm._s(_vm.$t("name")) + " :")]
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "el-row",
                                {
                                  staticClass: "center gap-md",
                                  attrs: { type: "flex" }
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.courseCampaign.name) + " :"
                                    )
                                  ]),
                                  _c("span", { staticClass: "primary" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.courseCampaign.formattedAddedDate
                                      )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "center", attrs: { type: "flex" } },
                        [
                          _c(
                            "el-col",
                            { staticClass: "m-title", attrs: { span: 4 } },
                            [_vm._v(_vm._s(_vm.$t("customers.total")) + " :")]
                          ),
                          _c(
                            "el-col",
                            { staticClass: "primary", attrs: { span: 20 } },
                            [_vm._v(_vm._s(_vm.total))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "center", attrs: { type: "flex" } },
                        [
                          _c(
                            "el-col",
                            { staticClass: "m-title", attrs: { span: 4 } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("courseCampaign.filters")) + " :"
                              )
                            ]
                          ),
                          _c(
                            "el-col",
                            { staticClass: "primary", attrs: { span: 20 } },
                            [_vm._v(_vm._s(_vm.courseCampaign.filterUsed))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticClass: "my-2",
                      attrs: { type: "flex", justify: "space-between" }
                    },
                    [
                      _c(
                        "el-col",
                        { staticClass: "dx-viewport", attrs: { span: 10 } },
                        [
                          _c(
                            "el-table",
                            {
                              staticClass: "m-border",
                              attrs: { data: _vm.customers, "max-height": 340 }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  "show-overflow-tooltip": "",
                                  prop: "EMail",
                                  label: "Email"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "FirstName",
                                  label: "First Name"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "LastName", label: "Last Name" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-table",
                            {
                              staticClass: "episode m-border",
                              attrs: { data: _vm.courseCampaign.episodes }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "Title",
                                  label: _vm.$t("courseCampaign.episodes")
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }