var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedCustomer
    ? _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isDialogOpen,
            title: _vm.selectedCustomerFullName
          },
          on: {
            "update:visible": function($event) {
              _vm.isDialogOpen = $event
            },
            close: _vm.handleDetailsDialogClose
          }
        },
        [
          _c("Details", {
            attrs: {
              detail: Object.assign({}, _vm.selectedCustomer, {
                assignedTags: _vm.assignedTags,
                sites: _vm.sites
              })
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }