var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DxPopup",
        {
          attrs: {
            title: "Popup Title",
            "hide-on-outside-click": true,
            visible: _vm.isAddMediaDialogOpen
          }
        },
        [
          _c(
            "el-form",
            { staticClass: "popupAlignment", attrs: { "label-width": "80px" } },
            [
              _c("div", { staticClass: "manageContent" }, [
                _c(
                  "div",
                  [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("AuthorsByMedia.category")))
                    ]),
                    _c("DxSelectBox", {
                      attrs: {
                        "search-enabled": true,
                        "data-source": _vm.columns.dropDownColumns[0].lookup,
                        "display-expr":
                          _vm.columns.dropDownColumns[0].displayExpr,
                        "value-expr": _vm.columns.dropDownColumns[0].valueExpr,
                        value: _vm.selectedCategory,
                        "on-value-changed": _vm.handleCategoryChange
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mediaInput" },
                  [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("AuthorsByMedia.media")))
                    ]),
                    _c(
                      "DxTagBox",
                      {
                        attrs: {
                          multiline: false,
                          "data-source": _vm.columns.dropDownColumns[1].lookup,
                          value: _vm.selectedEpisodes,
                          "value-expr":
                            _vm.columns.dropDownColumns[1].valueExpr,
                          "display-expr":
                            _vm.columns.dropDownColumns[1].displayExpr,
                          "search-enabled": true,
                          "show-selection-controls": true,
                          "on-value-changed": _vm.onEpisodeValueChange
                        }
                      },
                      [
                        _c(
                          "DxValidator",
                          [
                            _c("DxRequiredRule", {
                              attrs: {
                                message: _vm.$t(
                                  "AuthorsByMedia.selectMediaWarning"
                                )
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("AuthorsByMedia.site")))
                    ]),
                    _c(
                      "DxSelectBox",
                      {
                        attrs: {
                          "search-enabled": true,
                          "data-source": _vm.columns.dropDownColumns[2].lookup,
                          "display-expr":
                            _vm.columns.dropDownColumns[2].displayExpr,
                          "value-expr":
                            _vm.columns.dropDownColumns[2].valueExpr,
                          value: _vm.selectedSite,
                          "on-value-changed": _vm.handleSiteChange
                        }
                      },
                      [
                        _c(
                          "DxValidator",
                          [
                            _c("DxRequiredRule", {
                              attrs: {
                                message: _vm.$t(
                                  "AuthorsByMedia.selectSiteWarning"
                                )
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "alignButton" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.onSaveLoading },
                      on: { click: _vm.handlerChatQuestionMedia }
                    },
                    [_vm._v("Save")]
                  ),
                  _c(
                    "el-button",
                    { on: { click: _vm.handleAddMediaDialogClose } },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c("ODataTable", {
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns.all,
          "add-edit-columns": _vm.columns.addEdit,
          filter: _vm.filter,
          resource: "AuthorsByMedia",
          "action-buttons-width": 90,
          "total-label": _vm.$t("AuthorsByMedia.total"),
          "file-name": _vm.$t("AuthorsByMedia.fileName"),
          "add-row-button-label": _vm.$t("AuthorsByMedia.add"),
          title: _vm.$t("AuthorsByMedia.title"),
          "should-show-add-button": false,
          "should-show-edit-button": false
        },
        on: { selectionChange: _vm.handleSelectionChange },
        scopedSlots: _vm._u([
          {
            key: "custom-toolbar-before",
            fn: function() {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: _vm.defaultType, plain: "" },
                        on: { click: _vm.handleAddMediaDialogOpen }
                      },
                      [_vm._v(_vm._s(_vm.$t("AuthorsByMedia.addMedia")))]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "custom-toolbar-after",
            fn: function() {
              return [
                _c(
                  "div",
                  [
                    _vm.batchDeleteEpisodeIds.length
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", plain: "" },
                            on: { click: _vm.handleBatchDelete }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("AuthorsByMedia.batchDelete")) +
                                " "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }