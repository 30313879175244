<template>
  <div v-loading="isLoading">
    <div v-if="!isLoading">
      <SubCategories
        ref="subCategoriesRef"
        :class="{'hide': !hasSubCategories}"
        :category="data"
        :should-show-add-button="shouldShowAddButton"
        :should-show-edit-button="shouldShowEditButton"
        :should-show-delete-button="shouldShowDeleteButton"
        :should-show-toggle-button="shouldShowToggleButton"
        :should-show-title="shouldShowTitle"
        :segment="sectionForSubPrograms"
        @rowInserted="handleSubCategoryAdded"
        @rowRemoved="handleSubCategoryRemoved"
      />
      <DxButton
        v-if="!hasSubCategories && !shouldShowToggleButton"
        class="margin-left-md"
        icon="add"
        :text="$t('subCategories.add')"
        type="default"
        styling-mode="outlined"
        @click="handleAddSubCategoryClick"
      />
      <medias-by-category
        v-if="!hasSubCategories"
        ref="mediasRef"
        :category-id="data.ID"
        :site-id="data.SiteID"
        title="medias.title"
        add-row-button-label="medias.add"
        :should-show-add-button="shouldShowAddButton"
        :should-show-edit-button="shouldShowEditButton"
        :should-show-delete-button="shouldShowDeleteButton"
        :should-show-toggle-button="shouldShowToggleButton"
        :should-show-title="shouldShowTitle"
      >
        <template #toggle-button="{ data:item }">
          <el-switch
            :value="isMediaActive(item.row.data.MediaID)"
            @change="toggleActivate(item.row.data.MediaID)"
          />
        </template>
      </medias-by-category>
    </div>
  </div>
</template>
<script>
import SubCategories from '@/views/SubCategories'
import MediasByCategory from '../../mediasByCategory/index.vue'
import { createNamespacedHelpers } from 'vuex'
import { Media } from '@/models/media'
import { confirm } from 'devextreme/ui/dialog'
import { DxButton } from 'devextreme-vue/button'
const { mapActions: mapAppActions, mapGetters: mapAppGetters, mapState: mapAppState } = createNamespacedHelpers('app')

export default {
  name: 'Detail',
  components: {
    SubCategories,
    MediasByCategory,
    DxButton
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    shouldShowAddButton: {
      type: Boolean,
      default: true
    },
    shouldShowEditButton: {
      type: Boolean,
      default: true
    },
    shouldShowDeleteButton: {
      type: Boolean,
      default: true
    },
    shouldShowToggleButton: {
      type: Boolean,
      default: false
    },
    shouldShowTitle: {
      type: Boolean,
      default: false
    },
    sectionForSubPrograms: {
      type: String,
      default: 'subCategories'
    }
  },
  data() {
    return {
      isLoading: false,
      hasSubCategories: false
    }
  },
  computed: {
    ...mapAppGetters(['findMediaByIdAndSiteId']),
    ...mapAppState({
      selectedSiteOnPage: state => state.selectedSiteOnPage
    })
  },
  async mounted() {
    this.isLoading = true
    try {
      this.hasSubCategories = await this.checkSubCategoriesForParentCategory(this.data.ID)
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapAppActions([
      'checkSubCategoriesForParentCategory',
      'disableMediaForSite',
      'enableMediaForSite'
    ]),
    isMediaActive(id) {
      return !this.findMediaByIdAndSiteId({ id, siteId: this.selectedSiteOnPage })
    },
    async handleToggleConfirm(id) {
      this.$refs.mediasRef.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.beginCustomLoading()
      try {
        const media = this.findMediaByIdAndSiteId({
          id,
          siteId: this.selectedSiteOnPage
        })
        await (media
          ? this.enableMediaForSite(media.ID)
          : this.disableMediaForSite(
            Media.createDisableParams({ id, siteId: this.selectedSiteOnPage })
          ))
        await this.$refs.mediasRef.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.refresh()
        this.$message({
          type: 'success',
          message: this.$t(
            media ? 'medias.activate' : 'medias.deactivate'
          )
        })
      } finally {
        this.$refs.mediasRef.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.endCustomLoading()
      }
    },
    async toggleActivate(id) {
      const result = await confirm(this.$t('sites.toggleConfirm'))
      if (result) {
        await this.handleToggleConfirm(id)
      }
    },
    handleAddSubCategoryClick() {
      this.$refs.subCategoriesRef.$refs.oDataTableComponentRef.$refs.oDataTableRef.instance.addRow()
    },
    handleSubCategoryAdded() {
      this.hasSubCategories = true
      this.$emit('subCategoryAdded')
    },
    handleSubCategoryRemoved() {
      this.hasSubCategories = false
      this.$emit('subCategoryRemoved')
    }
  }
}
</script>

<style lang="scss" scoped>
$base-spacing: 5px;
::v-deep .el-loading-spinner{
  width: 60%;
}
.hide{
  opacity: 0;
  position: absolute;
  top: 0;
}
.margin-left-md{
  margin-left: $base-spacing * 9;
}
</style>
