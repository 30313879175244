import { Column } from './column'

export class Columns {
  columns;
  constructor(columns = []) {
    this.columns = columns.map(column => new Column(column))
  }

  toOptions() {
    return this.columns.map(({ column: value }, index) =>
      typeof value === 'string'
        ? {
          label: value,
          value: value === Column.createColumnName(index) ? '' : value
        }
        : value
    )
  }

  absentUsersSortedColumns() {
    const absentUsersColumnsOrder = [
      'DeactivationDateOFFSET',
      'NoDeactivation',
      'ID',
      'FirstName',
      'LastName',
      'EMail',
      'Account',
      'Company',
      'Address'
    ]

    const absentUserColumns = this.columns.map(({ column: value }, index) =>
      typeof value === 'string'
        ? {
          label: value,
          value: value === Column.createColumnName(index) ? '' : value
        }
        : value
    )

    const sortedColumns = absentUserColumns.sort((a, b) => {
      const aIndex = absentUsersColumnsOrder.indexOf(a.value)
      const bIndex = absentUsersColumnsOrder.indexOf(b.value)

      if (aIndex === -1) return 1
      if (bIndex === -1) return -1

      return aIndex - bIndex
    })

    return sortedColumns
  }

  has(field) {
    return this.columns.find(column => column.isEqual(field))
  }

  updatePartial({ field, ...attributes }) {
    this.columns.forEach(column => {
      if (column.isEqual(field)) {
        column.updatePartial(attributes)
      }
    })
  }

  createODataFilterParams() {
    return this.columns

      .filter(column => column.hasSearchTerm)
      .reduce(
        (oDataFilterAttributes, column) => ({
          ...oDataFilterAttributes,
          ...column.createODataFilterParams()
        }),
        {}
      )
  }

  get columnsForEmailValidations() {
    return new Columns(['FirstName', 'LastName', 'EMail']).columns
  }

  areRequiredColumnsMatched({
    includeSubAccount = false,
    includeExtId = false
  } = {}) {
    return this.createRequiredFields({
      includeSubAccount,
      includeExtId
    }).every(field => field.isValid)
  }

  createRequiredFields({
    includeSubAccount = false,
    includeExtId = false
  } = {}) {
    return [
      {
        field: 'E-Mail',
        isValid: this.has('EMail'),
        value: 'selectEmailColumn'
      },
      {
        field: 'Password',
        isValid: this.has('PassWord'),
        value: 'selectPasswordColumn'
      },
      {
        field: 'Account',
        isValid: this.has('Account'),
        value: 'selectAccountColumn'
      },
      {
        field: 'SiteID',
        isValid: this.has('SiteID'),
        value: 'selectSiteColumn'
      },
      {
        field: 'Company',
        isValid: this.has('Company'),
        value: 'selectCompanyColumn'
      },
      ...(includeSubAccount
        ? [
          {
            field: 'SubAccount',
            isValid: this.has('SubAccount'),
            value: 'selectSubAccountColumn'
          }
        ]
        : []),
      ...(includeExtId
        ? [
          {
            field: 'ExtID',
            isValid: this.has('ExtID'),
            value: 'selectExtIdColumn'
          }
        ]
        : [])
    ]
  }
}
