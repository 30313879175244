import { createResource } from '@/utils/httpRequests'

export default class DebugService {
  static async debug(data) {
    return createResource({
      url: `${process.env.VUE_APP_ODATA_API}/Debug`,
      data
    })
  }
}
