var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.selectedReflection
        ? _c(
            "el-dialog",
            {
              attrs: { visible: _vm.showDetails },
              on: {
                "update:visible": function($event) {
                  _vm.showDetails = $event
                },
                close: _vm.handleDetailsDialogClose
              }
            },
            [
              _c("Detail", {
                attrs: {
                  detail: Object.assign({}, _vm.selectedReflection, {
                    selectedCustomer: [].concat(_vm.selectedCustomer)
                  })
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("span", { staticClass: "margin-bottom" }, [
            _vm._v(_vm._s(_vm.$t("reviewReflections.AdminComment")))
          ]),
          _c("el-input", {
            attrs: {
              type: "textarea",
              rows: 2,
              placeholder: "Please Enter comment"
            },
            model: {
              value: _vm.comment,
              callback: function($$v) {
                _vm.comment = $$v
              },
              expression: "comment"
            }
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("reviewReflections.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSaveClick }
                },
                [_vm._v(_vm._s(_vm.$t("reviewReflections.save")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ODataTable", {
        key: _vm.filter,
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns,
          "add-edit-columns": _vm.addEditColumns,
          resource: "tTestCustomerAnswerPortal",
          "total-label": _vm.$t("reviewReflections.total"),
          "should-show-edit-button": true,
          "should-show-add-button": false,
          "should-show-delete-button": false,
          "file-name": _vm.$t("reviewReflections.fileName"),
          "add-row-button-label": _vm.$t("reviewReflections.add"),
          title: _vm.$t("reviewReflections.title"),
          filter: _vm.filter,
          "action-buttons-width": 100
        },
        on: {
          rowInit: _vm.handleRowInit,
          rowUpdating: _vm.handleRowUpdating,
          selectionChange: _vm.handleSelectionChange,
          editingStart: _vm.handleEditingStart
        },
        scopedSlots: _vm._u([
          {
            key: "custom-buttons",
            fn: function() {
              return [
                _c("DxButton", {
                  attrs: {
                    icon: "check",
                    "on-click": _vm.handleApprove,
                    visible: _vm.isPendingOrApproved
                  }
                }),
                _c("DxButton", {
                  attrs: {
                    icon: "remove",
                    "on-click": _vm.handleRejected,
                    visible: _vm.isPendingOrRejected
                  }
                }),
                _c("DxButton", {
                  attrs: {
                    icon: "info",
                    "on-click": _vm.handleViewClick,
                    visible: _vm.isApprovedOrRejected
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "custom-toolbar-before",
            fn: function() {
              return [
                _c(
                  "div",
                  [
                    _vm.selectedReflections.length
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              loading: _vm.isBulkApprovalLoading,
                              type: "primary"
                            },
                            on: { click: _vm.handleApproveAll },
                            model: {
                              value: _vm.approveAll,
                              callback: function($$v) {
                                _vm.approveAll = $$v
                              },
                              expression: "approveAll"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("reviewReflections.approveAll"))
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.selectedReflections.length
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              loading: _vm.isBulkRejectionLoading,
                              type: "danger"
                            },
                            on: { click: _vm.handleRejectAll },
                            model: {
                              value: _vm.rejectAll,
                              callback: function($$v) {
                                _vm.rejectAll = $$v
                              },
                              expression: "rejectAll"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("reviewReflections.rejectAll"))
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "custom-toolbar-after",
            fn: function() {
              return [
                _vm.isLoading
                  ? _c("DxProgressBar", {
                      attrs: {
                        min: 0,
                        max: 100,
                        value: _vm.percentage,
                        width: "100%"
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }