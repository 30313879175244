const tinyMCEConfig = {
  content_css: [
    '/app.effaba9.css',
    '/template-styles.css',
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    '/editor-styles.css'
  ],
  menubar: false,
  toolbar: false,
  readonly: true,
  statusbar: false,
  plugins: 'autoresize'
}

const TINYMCE_API_KEY = process.env.VUE_APP_TINYMCE_API_KEY

const PREVIEW_URL = process.env.VUE_APP_CONTENTPARTPAGE_PREVIEW_URL

export { tinyMCEConfig, TINYMCE_API_KEY, PREVIEW_URL }
