import i18n from '@/i18n'

const PAGE_MODEL = {
  ID: {
    type: Number,
    default: 0
  },
  PageName: {
    type: String,
    default: ''
  },
  IsActive: {
    type: Boolean,
    default: false
  },
  Tag: {
    type: String,
    default: ''
  },
  SortOrder: {
    type: Number,
    default: 0
  },
  Position: {
    type: String,
    default: ''
  },
  SiteID: {
    type: [Number, String],
    default: ''
  },
  AddedDate: {
    type: String,
    default: Date.now()
  },
  AdminUserID: {
    type: String,
    default: ''
  },
  PublishDate: {
    type: String,
    default: Date.now()
  },
  UnPublishDate: {
    type: String,
    default: Date.now()
  }
}

const DIALOG_TYPES = {
  EDIT: 'edit',
  CREATE: 'create'
}

const validateName = (rule, value, callback) => {
  if (!value) {
    callback(new Error(i18n.t('errors.inputName')))
  } else if (value.length < 3) {
    callback(new Error(i18n.t('errors.minNameLength')))
  } else {
    callback()
  }
}

const validateSelect = ({ rule, value, callback, type }) => {
  if (!value) {
    callback(new Error(i18n.t(`errors.select${type}`)))
  } else {
    callback()
  }
}

const validators = {
  validatePosition: (rule, value, callback) =>
    validateSelect({ rule, value, callback, type: 'Position' }),

  validateTag: (rule, value, callback) =>
    validateSelect({ rule, value, callback, type: 'Tag' }),

  validateSite: (rule, value, callback) =>
    validateSelect({ rule, value, callback, type: 'SiteID' }),
  validateName
}

export { DIALOG_TYPES, PAGE_MODEL, validators }
