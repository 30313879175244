import Store from '@/models/odataStore'
import { DateUtils } from '@/utils/date'

export const toggleColumns = ['bIsShared', 'IsApproved']
const answerTextField = 'AnswerText'
const lookUpFields = ['tTestQuestionID']
export const REVIEW_REFLECTIONS = {
  DATE_APPROVED: 'DateApproved',
  APPROVAL_COMMENT: 'ApprovalComment',
  ADMIN_ID: 'ApprovedByAdminID'
}

const detailsColumns = [
  { field: REVIEW_REFLECTIONS.APPROVAL_COMMENT },
  { field: REVIEW_REFLECTIONS.ADMIN_ID },
  {
    field: REVIEW_REFLECTIONS.DATE_APPROVED,
    dataType: 'date',
    format: DateUtils.odataDateFormat,
    visible: false
  }
]

export const detailsColumnsName = detailsColumns.map(field => field.field)

export const addEditColumns = [
  ...lookUpFields,
  answerTextField,
  ...toggleColumns
].map(field => ({
  field,
  width: '100%',
  visible: false,
  ...(toggleColumns.includes(field) && { dataType: 'boolean' }),
  ...(field === answerTextField && {
    visible: true,
    editorName: 'dxTextArea',
    colSpan: 2,
    editorOptions: {
      minHeight: '550px',
      autoResizeEnabled: true
    }
  }),
  ...(lookUpFields.includes(field) && {
    visible: true,
    label: 'reviewReflections.questionText',
    colSpan: 2,
    lookup: {
      store: new Store({ resource: 'tTestQuestion' }),
      paginate: true,
      pageSize: 50
    },
    valueExpr: 'ID',
    displayExpr: 'Name',
    calculateCellValue: event => {
      if (!event.tTestQuestionID) {
        return ''
      }
      return event.tTestQuestionID
    }
  })
}))

export const columns = [
  { field: 'ID', width: 110, visible: false, sort: 'desc' },
  ...addEditColumns,
  ...detailsColumns
]
