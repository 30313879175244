import { createResource, fectchResource } from '@/utils/httpRequests'
export default class TestSectionService {
  static createTestSection(data) {
    return createResource({
      url: `${process.env.VUE_APP_ODATA_API}/tTestSection`,
      data
    })
  }

  static fetchByMediaId(mediaId) {
    const queryParam = ['$top=5000', `$filter=MediaID eq ${mediaId}`].join('&')
    return fectchResource(
      `${process.env.VUE_APP_ODATA_API}/tTestSection?${queryParam}`
    )
  }
}
