<template>
  <el-main class="container">
    <el-row type="flex" :gutter="40">
      <el-col :xs="24" :sm="6">
        <label class="required-label">{{ $t('site') }}</label>
        <el-select v-model="filters.selectedSite" filterable>
          <el-option label="All" value="" />
          <el-option
            v-for="item in sites"
            :key="item.ID"
            :label="item.Name"
            :value="item.ID"
          />
        </el-select>
      </el-col>
      <el-col :xs="24" :sm="6">
        <label>{{ $t('media') }}</label>
        <el-select
          v-model="filters.selectedEpisode"
          filterable
          :placeholder="`${$t('select')} ${$t('media')}`"
          :loading="areEpisodesLoading"
        >
          <el-option v-if="episodes.length" :label="mediaLabel" value="" />
          <el-option
            v-for="episode in episodes"
            :key="episode.ID"
            :label="episode.Title"
            :value="episode.ID"
          />
        </el-select>
      </el-col>
    </el-row>
    <el-row type="flex" :gutter="40" justify="end">
      <el-col :sm="6" class="flex-end">
        <el-button type="primary" :disabled="!filters.hasFilter" @click="handleGoalsFilter">{{ $t('goals.filter.apply') }}</el-button>
        <el-button type="danger" @click="handleClearFilters">{{ $t('goals.filter.clear') }}</el-button>
      </el-col>
    </el-row>

  </el-main>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import EpisodeService from '@/api/episode'

const {
  mapState: mapGoalsState
} = createNamespacedHelpers('goals')

export default {
  name: 'GoalFilters',
  components: {},
  props: {
    sites: {
      type: Array,
      default() {
        return []
      }
    },
    medias: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      isLoading: true,
      episodes: [],
      mediaLabel: 'All',
      areEpisodesLoading: false
    }
  },
  computed: {
    ...mapGoalsState({
      filters: (state) => state.filters
    })
  },
  watch: {
    'filters.selectedSite': {
      async handler(newVal) {
        if (newVal) {
          this.areEpisodesLoading = true
          const { value } = await EpisodeService.fetchMediasBySite(newVal)
          this.episodes = value
          if (!this.episodes.length) {
            this.mediaLabel = ''
            this.filters.selectedEpisode = null
          } else {
            this.filters.selectedEpisode = ''
            this.mediaLabel = 'All'
          }
          this.areEpisodesLoading = false
        } else {
          this.episodes = this.medias
          this.filters.selectedEpisode = ''
          this.mediaLabel = 'All'
        }
      }
    }
  },
  async created() {
    this.filters.clearFilters()
    this.isLoading = false
  },
  mounted() {
    this.episodes = this.medias
  },
  methods: {
    handleGoalsFilter() {
      this.$emit('filter')
    },
    handleClearFilters() {
      this.$emit('closeFilter')
    }
  }
}
</script>
  <style scoped>
  .el-date-editor.el-input{
    width: 100%;
  }
  .flex-end{
    display: flex !important;
    justify-content: flex-end !important;
  }
  .required-label::before {
  content: ' *';
  color: red;
  margin-right: 2px;
}
.el-select {
  margin: 5px
}
  </style>
