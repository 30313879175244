var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "el-form",
        {
          ref: "forgotPasswordForm",
          staticClass: "login-form",
          attrs: {
            model: _vm.forgotPasswordForm,
            rules: _vm.validationRules,
            "auto-complete": "on",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-row",
            { staticClass: "title-container" },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("forgotPassword.label")))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "email" } },
            [
              _c(
                "span",
                { staticClass: "svg-container" },
                [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                1
              ),
              _c("el-input", {
                ref: "email",
                attrs: {
                  placeholder: _vm.$t("forgotPassword.email"),
                  name: "email",
                  type: "text",
                  tabindex: "1",
                  "auto-complete": "on"
                },
                model: {
                  value: _vm.forgotPasswordForm.email,
                  callback: function($$v) {
                    _vm.$set(_vm.forgotPasswordForm, "email", $$v)
                  },
                  expression: "forgotPasswordForm.email"
                }
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.isLoading },
              nativeOn: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.handleNextClick.apply(null, arguments)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("Next")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }