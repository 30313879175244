const HTTP_METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch',
  OPTIONS: 'options'
}

const BUILDER_MODES = {
  EDIT: 'edit',
  CREATE: 'create'
}

const FOLDERS = {
  MEDIA: '/media'
}

export { HTTP_METHOD, BUILDER_MODES, FOLDERS }
