var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-app" },
    [
      _c("ODataTable", {
        ref: "oDataTableComponentRef",
        attrs: {
          columns: _vm.columns.all,
          "add-edit-columns": _vm.columns.addEdit,
          resource: "MetaContent",
          "total-label": _vm.$t("sources.total"),
          "file-name": _vm.$t("sources.fileName"),
          "add-row-button-label": _vm.$t("sources.add"),
          title: _vm.$t("sources.title"),
          "is-custom-form": true
        },
        on: {
          rowInit: _vm.handleRowInit,
          editorPreparing: _vm.handleEditorPreparing,
          rowInserted: _vm.handleFormClose,
          editingStart: _vm.handleEditingStart,
          editingCancelling: _vm.handleFormClose
        },
        scopedSlots: _vm._u([
          {
            key: "custom-form",
            fn: function() {
              return [
                _c(
                  "DxTabbedItem",
                  [
                    _c(
                      "DxTab",
                      { attrs: { title: _vm.$t("info.title") } },
                      _vm._l(_vm.columns.info, function(column) {
                        return _c("DxSimpleItem", {
                          key: "add_edit_" + column.field,
                          attrs: { "data-field": column.field }
                        })
                      }),
                      1
                    ),
                    _c(
                      "DxTab",
                      { attrs: { title: _vm.$t("description.title") } },
                      _vm._l(_vm.columns.description, function(column) {
                        return _c("DxSimpleItem", {
                          key: "add_edit_" + column.field,
                          attrs: {
                            "data-field": column.field,
                            "editor-type": "dxTextArea"
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }