import { DateUtils } from '@/utils/date'
import UpdateSendListService from '@/api/updateSendList'
import { Message } from 'element-ui'
import { createColumns, setValidationValue, validators } from './config'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { MUTATION_TYPES } from '@/store/mutations'
const { mapMutations: mapAppMutations } = createNamespacedHelpers('app')
import i18n from '@/i18n'
import CustomersAccountService from '@/api/customersAccount'
import { AttributeSelection } from '@/models/attributeSelection'

export default {
  props: {
    selectedUsers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectExistingFolderName: null,
      popupListValue: null,
      popupFolderName: null,
      disabledExistingFolderField: true,
      isAddListDialogOpen: false,
      sendLists: [],
      sendListId: null,
      attributeFields: new AttributeSelection({}),
      includeUtskick2: false,
      mapUserData: [],
      currentDate: DateUtils.formatToShortDate(new Date()),
      label: 'updatedDate',
      loadingButton: false,
      customersAccounts: [],
      selectedCustomerAccount: null,
      rules: {}
    }
  },
  computed: {
    columns() {
      return createColumns()
    },
    ...mapGetters(['siteUrl'])
  },
  watch: {
    'attributeFields.utskick': {
      handler(value) {
        setValidationValue(value)
      }
    },
    selectedUsers(selectedUsers) {
      if (selectedUsers.length && !this.mapUserData.length) {
        this.mapUserData = [...selectedUsers]
      }
    },
    selectedCustomerAccount(newSelectedCustomerAccountId) {
      if (newSelectedCustomerAccountId) {
        const customersAccount = this.customersAccounts.find(
          val => val.ID === newSelectedCustomerAccountId
        )
        this.attributeFields.initialize(customersAccount)
      } else {
        this.attributeFields.reset()
      }
    }
  },
  created() {
    this.handleFetchSendLists()
    this.handleFetchCustomersAccounts()
    this.rules = {
      site: [{ validator: validators.validateSite, trigger: 'blur' }],
      signature: [{ validator: validators.validateSignature, trigger: 'blur' }],
      contactPersonPosition: [
        { validator: validators.validateContactPersonPosition, trigger: 'blur' }
      ]
    }
  },
  methods: {
    ...mapAppMutations({
      setValue: MUTATION_TYPES.APP.SET_UPDATED_SEND_LIST_ID,
      setSendList: MUTATION_TYPES.APP.SET_UPDATED_SEND_LIST
    }),
    handlePopUp() {
      this.isAddListDialogOpen = true
    },
    handleFormClose() {
      this.isAddListDialogOpen = false
      this.disabledExistingFolderField = true
      this.selectExistingFolderName = null
      this.popupListValue = null
      this.popupFolderName = null
    },
    async handleFetchSendLists() {
      this.sendLists = await UpdateSendListService.fetchAllSendLists()
    },

    async handleFetchCustomersAccounts() {
      const { value } = await CustomersAccountService.getAllCustomerAccount()
      this.customersAccounts = value
    },

    handleCheckbox() {
      this.popupFolderName = null
      this.selectExistingFolderName = null
    },

    async handleFormDataSave() {
      try {
        if (this.popupListValue && this.popupFolderName) {
          const folderId = await UpdateSendListService.CreateFolder(
            this.popupFolderName
          )
          await UpdateSendListService.createList({
            name: this.popupListValue,
            folderId
          })
        } else if (this.popupListValue && this.selectExistingFolderName) {
          await UpdateSendListService.createList({
            name: this.popupListValue,
            folderId: this.selectExistingFolderName
          })
        } else {
          this.popupListValue = this.popupListValue || ''
          this.popupFolderName = ''
          this.selectExistingFolderName = ''
          return ''
        }
        this.handleFormClose()
        this.handleFetchSendLists()
        Message({
          message: i18n.t('updateSendList.message.createList'),
          type: 'success'
        })
      } catch (error) {
        Message({
          dangerouslyUseHTMLString: true,
          message: i18n.t('updateSendList.message.errorList'),
          type: 'error'
        })
      }
    },

    async handleUpdate() {
      this.$refs['updateUserRef'].validate(async valid => {
        if (this.sendListId && valid) {
          this.loadingButton = true
          const selectedUserIds = this.selectedUsers.map(user => user.ID)
          const allowedData = this.mapUserData
            .filter(mapUser => selectedUserIds.includes(mapUser.ID))
            .map(mapUser => ({
              ...mapUser,
              EMail: mapUser.EMail.toLowerCase()
            }))

          if (allowedData.length) {
            const contacts = allowedData.map(customerDetails =>
              this.attributeFields.createBrevoPayload({
                customerDetails,
                sendListId: this.sendListId,
                includeUtskick2: this.includeUtskick2,
                updatedDate: this.currentDate
              })
            )
            try {
              this.setValue(this.sendListId)
              this.setSendList(allowedData)
              await UpdateSendListService.contactBatchUpdate(contacts)
              Message({
                message: i18n.t('updateSendList.message.createUpdateList'),
                type: 'success'
              })
              this.loadingButton = false
              this.$router.push('/users/updated-result')
            } catch (error) {
              if (error?.response?.data) {
                const str = error.response.data.message
                const numbers = str.match(/\d+/g).map(Number)
                this.handleApiCalls(contacts, numbers)
              }
            }
          } else {
            Message({
              message: i18n.t('updateSendList.message.selectCustomers'),
              type: 'error'
            })
            this.loadingButton = false
          }
        }
      })
    },

    async handleApiCalls(contacts = [], numbers = []) {
      const updateApiCall = []
      const newApiCall = []

      contacts.forEach((val, index) =>
        numbers.includes(index) ? newApiCall.push(val) : updateApiCall.push(val)
      )

      try {
        if (updateApiCall.length) {
          await UpdateSendListService.contactBatchUpdate(updateApiCall)
        }
        if (newApiCall.length) {
          await Promise.all(
            newApiCall.map(contact =>
              UpdateSendListService.addContactsToList(contact)
            )
          )
        }
        Message({
          message: i18n.t('updateSendList.message.createUpdateList'),
          type: 'success'
        })
        this.loadingButton = false
        this.$router.push('/users/updated-result')
      } catch (error) {
        Message({
          dangerouslyUseHTMLString: true,
          message: `${error.message}`,
          type: 'error'
        })
        this.loadingButton = false
      }
    }
  }
}
