<template>
  <el-input
    :value="value"
    :placeholder="placeholder"
    @input="handleValueChange"
  />
</template>

<script>
export default {
  name: 'NumberInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleValueChange(event) {
      this.$emit('change', event.replace(/[^0-9]/g, ''))
    }
  }
}
</script>
