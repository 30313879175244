import Store from './odataStore'

export class LookupStore extends Store {
  createQuery(options) {
    if (options.filter) {
      const value = options.filter[0][2]
      options.filter = [
        ...options.filter,
        ...(!Number.isNaN(Number(value))
          ? ['or', ['ID', '=', Number(value)]]
          : [])
      ]
    }
    return super.createQuery(options)
  }

  filterByIds(ids) {
    this.config.filterByIds(ids)
  }
}
