<template>
  <div class="app-container">
    <ODataTable
      :columns="columns"
      :add-edit-columns="addEditColumns"
      resource="UserMedia"
      :total-label="$t('userMedia.total')"
      :file-name="$t('userMedia.fileName')"
      :should-show-add-button="false"
      :title="$t('userMedia.title')"
    />
  </div>
</template>
<script>

import ODataTable from '../../components/ODataTable'
import { columns, addEditColumns } from './config'

export default {
  name: 'UserMedia',
  components: {
    ODataTable
  },

  data() {
    return {
      columns,
      addEditColumns
    }
  }
}
</script>
<style lang="scss">
.heading {
  font-weight: bold;
  font-size: 1.3rem;
}

.left-align {
  display: flex;
  justify-content: flex-start;
}
.dx-datagrid .dx-link {
  &:first-child {
    color: rgb(103, 194, 58);
  }
  &:nth-child(2) {
    color: #e6a23c;
  }
}
</style>
