import { ImportedUsers } from '@/models/importedUsers'
import { MUTATION_TYPES as BASE_MUTATION_TYPES } from '../../../mutations'
import AccountService from '@/api/account'
import { Columns } from '@/models/columns'
import { Report } from '@/models/report'
import { Message } from 'element-ui'
import DebugService from '@/api/debug'

const MUTATION_TYPES = BASE_MUTATION_TYPES.USER.IMPORTED_USERS

const hiddenColumns = ['RealCustomer']

export const IMPORT_TYPE = Object.freeze({
  COMPLETE: 'COMPLETE',
  MIXED: 'MIXED',
  DEACTIVATE: 'DEACTIVATE'
})

const initialState = () => ({
  importedUsers: new ImportedUsers(),
  columns: new Columns([]),
  isLoading: false,
  usersWithInvalidEmail: new ImportedUsers(),
  absentUsers: new ImportedUsers(),
  existingUsers: new ImportedUsers(),
  reports: [],
  importType: IMPORT_TYPE.COMPLETE,
  isNoDeactivationEnabled: true,
  editedUsersData: ''
})

const mutations = {
  [MUTATION_TYPES.SET_IMPORTED_USERS]: (
    state,
    importedUsers = new ImportedUsers()
  ) => {
    state.importedUsers = importedUsers
  },
  [MUTATION_TYPES.SET_TRANSFORM_TO_HTML_STATE]: (
    state,

    shouldTransformToHtml
  ) => {
    state.importedUsers.shouldTransformToHtml = shouldTransformToHtml
  },

  [MUTATION_TYPES.SET_IMPORT_TYPE]: (state, importType) => {
    state.importType = importType
  },

  [MUTATION_TYPES.SET_ABSENT_USERS]: (state, absentUsers) => {
    state.absentUsers = new ImportedUsers(
      absentUsers.map(absentUser => ({
        ...absentUser,
        NoDeactivation: Number(absentUser.NoDeactivation)
      }))
    )
    state.isLoading = false
  },

  [MUTATION_TYPES.SET_EXISTING_USERS]: (state, existingUsers) => {
    state.existingUsers = new ImportedUsers(existingUsers)
    state.isLoading = false
  },

  [MUTATION_TYPES.MATCH_COLUMN]: (state, { column, value }) => {
    state.importedUsers.updateColumn({ column, value })
  },

  [MUTATION_TYPES.SET_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  },

  [MUTATION_TYPES.SET_COLUMNS]: (state, sampleUser) => {
    state.isLoading = false
    state.columns = new Columns(
      Object.keys(sampleUser).filter(column => !hiddenColumns.includes(column))
    )
  },

  [MUTATION_TYPES.SET_REPORTS]: (state, reports) => {
    state.isLoading = false
    state.reports = reports
  },

  [MUTATION_TYPES.SET_INVALID_USERS]: state => {
    state.usersWithInvalidEmail = new ImportedUsers(
      state.importedUsers.usersWithInvalidEmails.map(user => ({
        ...user,
        isEditModeEnabled: false
      }))
    )
  },

  [MUTATION_TYPES.SET_EDIT_MODE]: (state, { email, isEditModeEnabled }) => {
    state.usersWithInvalidEmail.updatePartial({
      email,
      overrideAttributes: { isEditModeEnabled }
    })
  },

  [MUTATION_TYPES.UPDATE_EMAIL]: (state, { email, newEmail }) => {
    state.usersWithInvalidEmail.updatePartial({
      email,
      overrideAttributes: { EMail: newEmail.trim() }
    })
    state.importedUsers.updatePartial({
      email,
      overrideAttributes: { EMail: newEmail.trim() }
    })
  },

  [MUTATION_TYPES.TOGGLE_NO_DEACTIVATION]: state => {
    state.isNoDeactivationEnabled = !state.isNoDeactivationEnabled
  },
  [MUTATION_TYPES.SET_EDITED_USERS_DATA]: (state, data) => {
    state.editedUsersData = data
  }
}

const actions = {
  async fetchColumns({ commit }) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      const user = await AccountService.getSampleCustomer()
      commit(MUTATION_TYPES.SET_COLUMNS, user)
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPES.SET_LOADING, false)
      return Promise.reject(error)
    }
  },
  changeImportType({ commit, state }, importType) {
    if (state.importType === IMPORT_TYPE.COMPLETE) {
      commit(
        `user/accounts/${BASE_MUTATION_TYPES.USER.ACCOUNTS.SET_SELECTED_ACCOUNT}`,
        null,
        { root: true }
      )
      commit(
        `user/accounts/${BASE_MUTATION_TYPES.USER.ACCOUNTS.SET_SELECTED_SUB_ACCOUNT}`,
        '',
        { root: true }
      )
    }
    commit(MUTATION_TYPES.SET_IMPORT_TYPE, importType)
  },
  async matchCustomers({ commit, state, rootState }) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      const {
        selectedAccountInfo,
        selectedSubAccount
      } = rootState.user.accounts
      const params = state.importedUsers.createCustomerMatchParms(
        selectedAccountInfo
      )
      const result = await AccountService.matchCustomers({
        data: params,
        matchType: AccountService.getAccountMatchType({
          account: selectedAccountInfo,
          subAccount: selectedSubAccount,
          isExtId: state.importedUsers.isCheckForImportingExtIdSelected
        })
      })
      if (!selectedAccountInfo) {
        commit(MUTATION_TYPES.SET_EXISTING_USERS, [
          ...result.updatedCustomers,

          ...result.inactiveCustomers
        ])
        return Promise.resolve(false)
      }

      commit(MUTATION_TYPES.SET_ABSENT_USERS, result.existingCustomers)

      return Promise.resolve(!!result.existingCustomers.length)
    } catch (error) {
      commit(MUTATION_TYPES.SET_LOADING, false)
      Message({
        type: 'error',
        message: error.message
      })
      return Promise.reject()
    }
  },

  async generateReport({ commit, state, rootState }, deactivatedUsers = []) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      const isDeactivateImportType =
        state.importType === IMPORT_TYPE.DEACTIVATE
      const { selectedAccountInfo } = rootState.user.accounts
      const { existingUsers, isNoDeactivationEnabled } = state
      const params = state.importedUsers.createGenerateReportParams({
        selectedAccountInfo,
        deactivatedUsers,
        isDeactivateImportType,
        existingUsers,
        isNoDeactivationEnabled
      })

      const notUpdatedUsers = params.filter(user => {
        return !user.ForceUpdate && state.existingUsers.compare(user)
      })

      const setUidToParams = params.map(user => {
        return {
          ...user,
          UID: user.UID ? user.UID : null
        }
      })

      const result = await (isDeactivateImportType
        ? AccountService.deactivateUser(
          params,
          !state.importedUsers.isValidEmail
        )
        : AccountService.updateUser(
          setUidToParams,
          state.importedUsers.isCheckForImportingExtIdSelected
        ))
      if (result.Error) {
        commit(MUTATION_TYPES.SET_LOADING, false)
        const errorMessage = JSON.stringify(result, null, 2)

        Message({
          dangerouslyUseHTMLString: true,
          duration: 0,
          showClose: true,
          message: `<pre>${errorMessage}</pre>`,
          type: 'error'
        })
        return Promise.reject(result)
      }
      if (!isDeactivateImportType) {
        result.notUpdatedUsers = notUpdatedUsers
      }
      commit(MUTATION_TYPES.SET_REPORTS, Report.generate(result))
      return Promise.resolve()
    } catch (error) {
      commit(MUTATION_TYPES.SET_LOADING, false)
      Message({
        type: 'error',
        message: error.message
      })
      return Promise.reject()
    }
  },
  async debugApi({ state }) {
    try {
      await DebugService.debug(
        state.importedUsers.generateDebugParams(state.editedUsersData)
      )
    } catch (error) {
      Message({
        type: 'error',
        message: error.message
      })
    }
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions
}
