export class TransactionItemList {
  items;
  currentPage = 0;
  totalPages = 0;
  itemsPerPage = 0;
  constructor(items = [], itemsPerPage = process.env.VUE_APP_BATCH_ITEMS_SIZE) {
    this.items = items
    this.itemsPerPage = itemsPerPage
    this.totalPages = Math.ceil(this.items.length / this.itemsPerPage)
  }

  get hasMore() {
    return this.currentPage < this.totalPages
  }

  get percentage() {
    return Math.round((this.currentPage * 100) / this.totalPages)
  }

  createStartAndEndIndex(index) {
    return [index * this.itemsPerPage, (index + 1) * this.itemsPerPage]
  }

  next() {
    const nextItems = this.items.slice(
      ...this.createStartAndEndIndex(this.currentPage)
    )
    this.currentPage = this.currentPage + 1
    return nextItems
  }
}
