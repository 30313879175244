import {
  createResource,
  fectchResource,
  patchResource
} from '@/utils/httpRequests'
export default class TestQuestionAnswerService {
  static createTestQuestionAnswer(data) {
    return createResource({
      url: `${process.env.VUE_APP_ODATA_API}/tTestQuestionAnswer`,
      data
    })
  }
  static fetchByQuestionIds(ids = []) {
    return fectchResource(
      `${
        process.env.VUE_APP_ODATA_API
      }/tTestQuestionAnswer?$filter=tTestQuestionID in (${ids.join(', ')})`
    )
  }
  static updateById({ id, data }) {
    return patchResource({
      url: `${process.env.VUE_APP_ODATA_API}/tTestQuestionAnswer(${id})`,
      data
    })
  }
}
