import { Site } from '@/models/site'
import { fectchResource } from '@/utils/httpRequests'
import { PaginatedResponse } from '@/models/paginatedResponse'
export default class SiteService {
  static async getNewSiteId() {
    const queryParams = ['$orderby=ID asc'].join('&')
    const response = await fectchResource(
      `${process.env.VUE_APP_ODATA_API}/Sites?${queryParams}`
    )

    const paginatedResponse = new PaginatedResponse(response)
    let newSiteId = Site.findMissingId(paginatedResponse.items)
    while (paginatedResponse.hasMore && !newSiteId) {
      await paginatedResponse.next()
      newSiteId = Site.findMissingId(paginatedResponse.items)
    }

    return (
      newSiteId ||
      paginatedResponse.items[paginatedResponse.items.length - 1]?.ID + 1
    )
  }

  static getAll() {
    return fectchResource(`${process.env.VUE_APP_ODATA_API}/Sites`)
  }
}
