var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    { staticClass: "container" },
    [
      _c(
        "el-row",
        { attrs: { type: "flex", gutter: 40 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 6 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: true,
                    placeholder: "" + _vm.$t("test.chooseSite")
                  },
                  model: {
                    value: _vm.test.siteId,
                    callback: function($$v) {
                      _vm.$set(_vm.test, "siteId", $$v)
                    },
                    expression: "test.siteId"
                  }
                },
                _vm._l(_vm.sites, function(site, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: site.Name, value: site.ID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 10 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: true,
                    placeholder: "" + _vm.$t("test.chooseProgram"),
                    loading: _vm.areProgramsLoading
                  },
                  model: {
                    value: _vm.test.programId,
                    callback: function($$v) {
                      _vm.$set(_vm.test, "programId", $$v)
                    },
                    expression: "test.programId"
                  }
                },
                _vm._l(_vm.programs, function(program, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: program.Name, value: program.ID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 10 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: true,
                    placeholder: "" + _vm.$t("test.episode"),
                    loading: _vm.areEpisodesLoading
                  },
                  on: { change: _vm.filter },
                  model: {
                    value: _vm.test.episodeId,
                    callback: function($$v) {
                      _vm.$set(_vm.test, "episodeId", $$v)
                    },
                    expression: "test.episodeId"
                  }
                },
                _vm._l(_vm.episodes, function(episode, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: episode.Title, value: episode.ID }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }